export var SchemeColorValues;
(function (SchemeColorValues) {
  SchemeColorValues[SchemeColorValues["Accent1"] = 0] = "Accent1";
  SchemeColorValues[SchemeColorValues["Accent2"] = 1] = "Accent2";
  SchemeColorValues[SchemeColorValues["Accent3"] = 2] = "Accent3";
  SchemeColorValues[SchemeColorValues["Accent4"] = 3] = "Accent4";
  SchemeColorValues[SchemeColorValues["Accent5"] = 4] = "Accent5";
  SchemeColorValues[SchemeColorValues["Accent6"] = 5] = "Accent6";
  SchemeColorValues[SchemeColorValues["Background1"] = 6] = "Background1";
  SchemeColorValues[SchemeColorValues["Background2"] = 7] = "Background2";
  SchemeColorValues[SchemeColorValues["Dark1"] = 8] = "Dark1";
  SchemeColorValues[SchemeColorValues["Dark2"] = 9] = "Dark2";
  SchemeColorValues[SchemeColorValues["FollowedHyperlink"] = 10] = "FollowedHyperlink";
  SchemeColorValues[SchemeColorValues["Hyperlink"] = 11] = "Hyperlink";
  SchemeColorValues[SchemeColorValues["Light1"] = 12] = "Light1";
  SchemeColorValues[SchemeColorValues["Light2"] = 13] = "Light2";
  SchemeColorValues[SchemeColorValues["Style"] = 14] = "Style";
  SchemeColorValues[SchemeColorValues["Text1"] = 15] = "Text1";
  SchemeColorValues[SchemeColorValues["Text2"] = 16] = "Text2";
  SchemeColorValues[SchemeColorValues["Empty"] = 17] = "Empty";
})(SchemeColorValues || (SchemeColorValues = {}));
export var SystemColorValues;
(function (SystemColorValues) {
  SystemColorValues[SystemColorValues["Sc3dDkShadow"] = 20] = "Sc3dDkShadow";
  SystemColorValues[SystemColorValues["Sc3dLight"] = 19] = "Sc3dLight";
  SystemColorValues[SystemColorValues["ScActiveBorder"] = 21] = "ScActiveBorder";
  SystemColorValues[SystemColorValues["ScActiveCaption"] = 6] = "ScActiveCaption";
  SystemColorValues[SystemColorValues["ScAppWorkspace"] = 24] = "ScAppWorkspace";
  SystemColorValues[SystemColorValues["ScBackground"] = 23] = "ScBackground";
  SystemColorValues[SystemColorValues["ScBtnFace"] = 0] = "ScBtnFace";
  SystemColorValues[SystemColorValues["ScBtnHighlight"] = 7] = "ScBtnHighlight";
  SystemColorValues[SystemColorValues["ScBtnShadow"] = 8] = "ScBtnShadow";
  SystemColorValues[SystemColorValues["ScBtnText"] = 9] = "ScBtnText";
  SystemColorValues[SystemColorValues["ScCaptionText"] = 5] = "ScCaptionText";
  SystemColorValues[SystemColorValues["ScGradientActiveCaption"] = -2] = "ScGradientActiveCaption";
  SystemColorValues[SystemColorValues["ScGradientInactiveCaption"] = -3] = "ScGradientInactiveCaption";
  SystemColorValues[SystemColorValues["ScGrayText"] = 10] = "ScGrayText";
  SystemColorValues[SystemColorValues["ScHighlight"] = 3] = "ScHighlight";
  SystemColorValues[SystemColorValues["ScHighlightText"] = 4] = "ScHighlightText";
  SystemColorValues[SystemColorValues["ScHotLight"] = -4] = "ScHotLight";
  SystemColorValues[SystemColorValues["ScInactiveBorder"] = 22] = "ScInactiveBorder";
  SystemColorValues[SystemColorValues["ScInactiveCaption"] = 11] = "ScInactiveCaption";
  SystemColorValues[SystemColorValues["ScInactiveCaptionText"] = 12] = "ScInactiveCaptionText";
  SystemColorValues[SystemColorValues["ScInfoBk"] = 13] = "ScInfoBk";
  SystemColorValues[SystemColorValues["ScInfoText"] = 14] = "ScInfoText";
  SystemColorValues[SystemColorValues["ScMenu"] = 2] = "ScMenu";
  SystemColorValues[SystemColorValues["ScMenuBar"] = -5] = "ScMenuBar";
  SystemColorValues[SystemColorValues["ScMenuHighlight"] = -6] = "ScMenuHighlight";
  SystemColorValues[SystemColorValues["ScMenuText"] = 15] = "ScMenuText";
  SystemColorValues[SystemColorValues["ScScrollBar"] = 16] = "ScScrollBar";
  SystemColorValues[SystemColorValues["ScWindow"] = 17] = "ScWindow";
  SystemColorValues[SystemColorValues["ScWindowFrame"] = 18] = "ScWindowFrame";
  SystemColorValues[SystemColorValues["ScWindowText"] = 1] = "ScWindowText";
  SystemColorValues[SystemColorValues["Empty"] = -1] = "Empty";
})(SystemColorValues || (SystemColorValues = {}));
export var XlFontSchemeStyles;
(function (XlFontSchemeStyles) {
  XlFontSchemeStyles[XlFontSchemeStyles["None"] = 0] = "None";
  XlFontSchemeStyles[XlFontSchemeStyles["Minor"] = 1] = "Minor";
  XlFontSchemeStyles[XlFontSchemeStyles["Major"] = 2] = "Major";
})(XlFontSchemeStyles || (XlFontSchemeStyles = {}));
export var StyleMatrixElementType;
(function (StyleMatrixElementType) {
  StyleMatrixElementType[StyleMatrixElementType["None"] = 0] = "None";
  StyleMatrixElementType[StyleMatrixElementType["Subtle"] = 1] = "Subtle";
  StyleMatrixElementType[StyleMatrixElementType["Moderate"] = 2] = "Moderate";
  StyleMatrixElementType[StyleMatrixElementType["Intense"] = 3] = "Intense";
})(StyleMatrixElementType || (StyleMatrixElementType = {}));