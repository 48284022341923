export var JSONTableHeightUnitProperty;
(function (JSONTableHeightUnitProperty) {
  JSONTableHeightUnitProperty[JSONTableHeightUnitProperty["Type"] = 0] = "Type";
  JSONTableHeightUnitProperty[JSONTableHeightUnitProperty["Value"] = 1] = "Value";
})(JSONTableHeightUnitProperty || (JSONTableHeightUnitProperty = {}));
export var JSONTableWidthUnitProperty;
(function (JSONTableWidthUnitProperty) {
  JSONTableWidthUnitProperty[JSONTableWidthUnitProperty["Type"] = 0] = "Type";
  JSONTableWidthUnitProperty[JSONTableWidthUnitProperty["Value"] = 1] = "Value";
})(JSONTableWidthUnitProperty || (JSONTableWidthUnitProperty = {}));
export var JSONTableCellMarginsProperty;
(function (JSONTableCellMarginsProperty) {
  JSONTableCellMarginsProperty[JSONTableCellMarginsProperty["Top"] = 0] = "Top";
  JSONTableCellMarginsProperty[JSONTableCellMarginsProperty["Left"] = 1] = "Left";
  JSONTableCellMarginsProperty[JSONTableCellMarginsProperty["Right"] = 2] = "Right";
  JSONTableCellMarginsProperty[JSONTableCellMarginsProperty["Bottom"] = 3] = "Bottom";
})(JSONTableCellMarginsProperty || (JSONTableCellMarginsProperty = {}));
export var JSONTableBordersProperty;
(function (JSONTableBordersProperty) {
  JSONTableBordersProperty[JSONTableBordersProperty["TopBorder"] = 0] = "TopBorder";
  JSONTableBordersProperty[JSONTableBordersProperty["LeftBorder"] = 1] = "LeftBorder";
  JSONTableBordersProperty[JSONTableBordersProperty["RightBorder"] = 2] = "RightBorder";
  JSONTableBordersProperty[JSONTableBordersProperty["BottomBorder"] = 3] = "BottomBorder";
  JSONTableBordersProperty[JSONTableBordersProperty["InsideHorizontalBorder"] = 4] = "InsideHorizontalBorder";
  JSONTableBordersProperty[JSONTableBordersProperty["InsideVerticalBorder"] = 5] = "InsideVerticalBorder";
})(JSONTableBordersProperty || (JSONTableBordersProperty = {}));
export var JSONTableCellBordersProperty;
(function (JSONTableCellBordersProperty) {
  JSONTableCellBordersProperty[JSONTableCellBordersProperty["TopBorder"] = 0] = "TopBorder";
  JSONTableCellBordersProperty[JSONTableCellBordersProperty["LeftBorder"] = 1] = "LeftBorder";
  JSONTableCellBordersProperty[JSONTableCellBordersProperty["RightBorder"] = 2] = "RightBorder";
  JSONTableCellBordersProperty[JSONTableCellBordersProperty["BottomBorder"] = 3] = "BottomBorder";
  JSONTableCellBordersProperty[JSONTableCellBordersProperty["TopLeftDiagonalBorder"] = 4] = "TopLeftDiagonalBorder";
  JSONTableCellBordersProperty[JSONTableCellBordersProperty["TopRightDiagonalBorder"] = 5] = "TopRightDiagonalBorder";
})(JSONTableCellBordersProperty || (JSONTableCellBordersProperty = {}));
export var JSONTableConditionalStyleProperty;
(function (JSONTableConditionalStyleProperty) {
  JSONTableConditionalStyleProperty[JSONTableConditionalStyleProperty["TableProperties"] = 0] = "TableProperties";
  JSONTableConditionalStyleProperty[JSONTableConditionalStyleProperty["TableRowPropertiesIndex"] = 1] = "TableRowPropertiesIndex";
  JSONTableConditionalStyleProperty[JSONTableConditionalStyleProperty["TableCellPropertiesIndex"] = 2] = "TableCellPropertiesIndex";
  JSONTableConditionalStyleProperty[JSONTableConditionalStyleProperty["MaskedParagraphPropertiesCacheIndex"] = 3] = "MaskedParagraphPropertiesCacheIndex";
  JSONTableConditionalStyleProperty[JSONTableConditionalStyleProperty["MaskedCharacterPropertiesCacheIndex"] = 4] = "MaskedCharacterPropertiesCacheIndex";
  JSONTableConditionalStyleProperty[JSONTableConditionalStyleProperty["Tabs"] = 5] = "Tabs";
})(JSONTableConditionalStyleProperty || (JSONTableConditionalStyleProperty = {}));