"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Browser = void 0;
var Browser = function () {
  function Browser() {}
  Browser.IdentUserAgent = function (userAgent, ignoreDocumentMode) {
    if (ignoreDocumentMode === void 0) {
      ignoreDocumentMode = false;
    }
    var browserTypesOrderedList = ['Mozilla', 'IE', 'Firefox', 'Netscape', 'Safari', 'Chrome', 'Opera', 'Opera10', 'Edge'];
    var defaultBrowserType = 'IE';
    var defaultPlatform = 'Win';
    var defaultVersions = {
      Safari: 2,
      Chrome: 0.1,
      Mozilla: 1.9,
      Netscape: 8,
      Firefox: 2,
      Opera: 9,
      IE: 6,
      Edge: 12
    };
    if (!userAgent || userAgent.length === 0) {
      Browser.fillUserAgentInfo(browserTypesOrderedList, defaultBrowserType, defaultVersions[defaultBrowserType], defaultPlatform);
      return;
    }
    userAgent = userAgent.toLowerCase();
    Browser.indentPlatformMajorVersion(userAgent);
    try {
      var platformIdentStrings = {
        'Windows': 'Win',
        'Macintosh': 'Mac',
        'Mac OS': 'Mac',
        'Mac_PowerPC': 'Mac',
        'cpu os': 'MacMobile',
        'cpu iphone os': 'MacMobile',
        'Android': 'Android',
        '!Windows Phone': 'WinPhone',
        '!WPDesktop': 'WinPhone',
        '!ZuneWP': 'WinPhone'
      };
      var optSlashOrSpace = '(?:/|\\s*)?';
      var versionString = '(\\d+)(?:\\.((?:\\d+?[1-9])|\\d)0*?)?';
      var optVersion = '(?:' + versionString + ')?';
      var patterns = {
        Safari: 'applewebkit(?:.*?(?:version/' + versionString + '[\\.\\w\\d]*?(?:\\s+mobile/\\S*)?\\s+safari))?',
        Chrome: '(?:chrome|crios)(?!frame)' + optSlashOrSpace + optVersion,
        Mozilla: 'mozilla(?:.*rv:' + optVersion + '.*Gecko)?',
        Netscape: '(?:netscape|navigator)\\d*/?\\s*' + optVersion,
        Firefox: 'firefox' + optSlashOrSpace + optVersion,
        Opera: '(?:opera|\\sopr)' + optSlashOrSpace + optVersion,
        Opera10: 'opera.*\\s*version' + optSlashOrSpace + optVersion,
        IE: 'msie\\s*' + optVersion,
        Edge: 'edge' + optSlashOrSpace + optVersion
      };
      var browserType = null;
      var version = -1;
      for (var i = 0; i < browserTypesOrderedList.length; i++) {
        var browserTypeCandidate = browserTypesOrderedList[i];
        var regExp = new RegExp(patterns[browserTypeCandidate], 'i');
        var matches = regExp.exec(userAgent);
        if (matches && matches.index >= 0) {
          if (browserType === 'IE' && version >= 11 && browserTypeCandidate === 'Safari') continue;
          browserType = browserTypeCandidate;
          if (browserType === 'Opera10') browserType = 'Opera';
          var tridentPattern = 'trident' + optSlashOrSpace + optVersion;
          version = Browser.GetBrowserVersion(userAgent, matches, tridentPattern, Browser.getIECompatibleVersionString());
          if (browserType === 'Mozilla' && version >= 11) browserType = 'IE';
        }
      }
      if (!browserType) browserType = defaultBrowserType;
      var browserVersionDetected = version !== -1;
      if (!browserVersionDetected) version = defaultVersions[browserType];
      var platform = null;
      var minOccurenceIndex = Number.MAX_VALUE;
      for (var identStr in platformIdentStrings) {
        if (!Object.prototype.hasOwnProperty.call(platformIdentStrings, identStr)) continue;
        var importantIdent = identStr.substr(0, 1) === '!';
        var occurenceIndex = userAgent.indexOf((importantIdent ? identStr.substr(1) : identStr).toLowerCase());
        if (occurenceIndex >= 0 && (occurenceIndex < minOccurenceIndex || importantIdent)) {
          minOccurenceIndex = importantIdent ? 0 : occurenceIndex;
          platform = platformIdentStrings[identStr];
        }
      }
      var samsungPattern = 'SM-[A-Z]';
      var m = userAgent.toUpperCase().match(samsungPattern);
      var isSamsungAndroidDevice = m && m.length > 0;
      if (platform === 'WinPhone' && version < 9) version = Math.floor(Browser.getVersionFromTrident(userAgent, 'trident' + optSlashOrSpace + optVersion));
      if (!ignoreDocumentMode && browserType === 'IE' && version > 7 && document.documentMode < version) version = document.documentMode;
      if (platform === 'WinPhone') version = Math.max(9, version);
      if (!platform) platform = defaultPlatform;
      if (platform === platformIdentStrings['cpu os'] && !browserVersionDetected) version = 4;
      Browser.fillUserAgentInfo(browserTypesOrderedList, browserType, version, platform, isSamsungAndroidDevice);
    } catch (e) {
      Browser.fillUserAgentInfo(browserTypesOrderedList, defaultBrowserType, defaultVersions[defaultBrowserType], defaultPlatform);
    }
  };
  Browser.GetBrowserVersion = function (userAgent, matches, tridentPattern, ieCompatibleVersionString) {
    var version = Browser.getVersionFromMatches(matches);
    if (ieCompatibleVersionString) {
      var versionFromTrident = Browser.getVersionFromTrident(userAgent, tridentPattern);
      if (ieCompatibleVersionString === 'edge' || parseInt(ieCompatibleVersionString) === versionFromTrident) return versionFromTrident;
    }
    return version;
  };
  Browser.getIECompatibleVersionString = function () {
    if (document.compatible) {
      for (var i = 0; i < document.compatible.length; i++) {
        if (document.compatible[i].userAgent === 'IE' && document.compatible[i].version) return document.compatible[i].version.toLowerCase();
      }
    }
    return '';
  };
  Browser.isTouchEnabled = function () {
    return Browser.hasTouchStart() || Browser.hasMaxTouchPoints() || Browser.hasMsMaxTouchPoints();
  };
  Browser.hasTouchStart = function () {
    return 'ontouchstart' in window;
  };
  Browser.hasMaxTouchPoints = function () {
    return navigator['maxTouchPoints'] > 0;
  };
  Browser.hasMsMaxTouchPoints = function () {
    return navigator['msMaxTouchPoints'] > 0;
  };
  Browser.hasNavigator = function () {
    return typeof navigator !== 'undefined';
  };
  Browser.fillUserAgentInfo = function (browserTypesOrderedList, browserType, version, platform, isSamsungAndroidDevice) {
    if (isSamsungAndroidDevice === void 0) {
      isSamsungAndroidDevice = false;
    }
    for (var i = 0; i < browserTypesOrderedList.length; i++) {
      var type = browserTypesOrderedList[i];
      Browser[type] = type === browserType;
    }
    Browser.Version = Math.floor(10.0 * version) / 10.0;
    Browser.MajorVersion = Math.floor(Browser.Version);
    Browser.WindowsPlatform = platform === 'Win' || platform === 'WinPhone';
    Browser.MacOSMobilePlatform = platform === 'MacMobile' || platform === 'Mac' && Browser.isTouchEnabled();
    Browser.MacOSPlatform = platform === 'Mac' && !Browser.MacOSMobilePlatform;
    Browser.AndroidMobilePlatform = platform === 'Android';
    Browser.WindowsPhonePlatform = platform === 'WinPhone';
    Browser.WebKitFamily = Browser.Safari || Browser.Chrome || Browser.Opera && Browser.MajorVersion >= 15;
    Browser.NetscapeFamily = Browser.Netscape || Browser.Mozilla || Browser.Firefox;
    Browser.WebKitTouchUI = Browser.MacOSMobilePlatform || Browser.AndroidMobilePlatform;
    var isIETouchUI = Browser.IE && Browser.MajorVersion > 9 && Browser.WindowsPlatform && Browser.UserAgent.toLowerCase().indexOf('touch') >= 0;
    Browser.MSTouchUI = isIETouchUI || Browser.Edge && !!window.navigator.maxTouchPoints;
    Browser.TouchUI = Browser.WebKitTouchUI || Browser.MSTouchUI;
    Browser.MobileUI = Browser.WebKitTouchUI || Browser.WindowsPhonePlatform;
    Browser.AndroidDefaultBrowser = Browser.AndroidMobilePlatform && !Browser.Chrome;
    Browser.AndroidChromeBrowser = Browser.AndroidMobilePlatform && Browser.Chrome;
    if (isSamsungAndroidDevice) Browser.SamsungAndroidDevice = isSamsungAndroidDevice;
    if (Browser.MSTouchUI) {
      var isARMArchitecture = Browser.UserAgent.toLowerCase().indexOf('arm;') > -1;
      Browser.VirtualKeyboardSupported = isARMArchitecture || Browser.WindowsPhonePlatform;
    } else Browser.VirtualKeyboardSupported = Browser.WebKitTouchUI;
    Browser.fillDocumentElementBrowserTypeClassNames(browserTypesOrderedList);
  };
  Browser.indentPlatformMajorVersion = function (userAgent) {
    var regex = /(?:(?:windows nt|macintosh|mac os|cpu os|cpu iphone os|android|windows phone|linux) )(\d+)(?:[-0-9_.])*/;
    var matches = regex.exec(userAgent);
    if (matches) Browser.PlaformMajorVersion = matches[1];
  };
  Browser.getVersionFromMatches = function (matches) {
    var result = -1;
    var versionStr = '';
    if (matches) {
      if (matches[1]) {
        versionStr += matches[1];
        if (matches[2]) versionStr += '.' + matches[2];
      }
      if (versionStr !== '') {
        result = parseFloat(versionStr);
        if (isNaN(result)) result = -1;
      }
    }
    return result;
  };
  Browser.getVersionFromTrident = function (userAgent, tridentPattern) {
    var tridentDiffFromVersion = 4;
    var matches = new RegExp(tridentPattern, 'i').exec(userAgent);
    return Browser.getVersionFromMatches(matches) + tridentDiffFromVersion;
  };
  Browser.fillDocumentElementBrowserTypeClassNames = function (browserTypesOrderedList) {
    var documentElementClassName = '';
    var browserTypeslist = browserTypesOrderedList.concat(['WindowsPlatform', 'MacOSPlatform', 'MacOSMobilePlatform', 'AndroidMobilePlatform', 'WindowsPhonePlatform', 'WebKitFamily', 'WebKitTouchUI', 'MSTouchUI', 'TouchUI', 'AndroidDefaultBrowser']);
    for (var i = 0; i < browserTypeslist.length; i++) {
      var type = browserTypeslist[i];
      if (Browser[type]) documentElementClassName += 'dx' + type + ' ';
    }
    documentElementClassName += 'dxBrowserVersion-' + Browser.MajorVersion;
    if (typeof document !== 'undefined' && document && document.documentElement) {
      if (document.documentElement.className !== '') documentElementClassName = ' ' + documentElementClassName;
      document.documentElement.className += documentElementClassName;
      Browser.Info = documentElementClassName;
    }
  };
  Browser.getUserAgent = function () {
    return Browser.hasNavigator() && navigator.userAgent ? navigator.userAgent.toLowerCase() : '';
  };
  Browser.UserAgent = Browser.getUserAgent();
  Browser._foo = Browser.IdentUserAgent(Browser.UserAgent);
  return Browser;
}();
exports.Browser = Browser;