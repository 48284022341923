export var DxtThemeCssClasses;
(function (DxtThemeCssClasses) {
  DxtThemeCssClasses["AccentAsBackgroundColor"] = "dx-theme-accent-as-background-color";
  DxtThemeCssClasses["AccentAsBorderColor"] = "dx-theme-accent-as-border-color";
  DxtThemeCssClasses["AccentAsTextColor"] = "dx-theme-accent-as-text-color";
  DxtThemeCssClasses["BackgroundColor"] = "dx-theme-background-color";
  DxtThemeCssClasses["BackgroundColorAsBorderColor"] = "dx-theme-background-color-as-border-color";
  DxtThemeCssClasses["BackgroundColorAsTextColor"] = "dx-theme-background-color-as-text-color";
  DxtThemeCssClasses["BorderColor"] = "dx-theme-border-color";
  DxtThemeCssClasses["BorderColorAsBackgroundColor"] = "dx-theme-border-color-as-background-color";
  DxtThemeCssClasses["BorderColorAsTextColor"] = "dx-theme-border-color-as-text-color ";
  DxtThemeCssClasses["TextColor"] = "dx-theme-text-color";
  DxtThemeCssClasses["TextColorAsBackgroundColor"] = "dx-theme-text-color-as-background-color";
  DxtThemeCssClasses["TextColorAsBorderColor"] = "dx-theme-text-color-as-border-color";
})(DxtThemeCssClasses || (DxtThemeCssClasses = {}));