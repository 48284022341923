import { FixedInterval } from "@devexpress/utils/lib/intervals/fixed";
import { HtmlMimeType } from "@devexpress/utils/lib/utils/mime-type";
import { RichUtils } from "../../../model/rich-utils";
import { HtmlExporter } from "./html-export";
export class HtmlDocumentExporter {
  get modelManipulator() {
    return this.exportModelOptions.modelManager.modelManipulator;
  }
  constructor(exportModelOptions, options) {
    this.exportModelOptions = exportModelOptions;
    this.options = options;
  }
  exportToBlob(callback) {
    this.modelManipulator.picture.loader.ensureAllPicturesLoaded(this.options.ensurePictureLoadedTimeout, _loaded => {
      callback(new Blob([this.exportAsString()], {
        type: HtmlMimeType
      }));
    });
  }
  exportToBase64(callback) {
    this.modelManipulator.picture.loader.ensureAllPicturesLoaded(this.options.ensurePictureLoadedTimeout, _loaded => {
      callback(btoa(this.exportAsString()));
    });
  }
  exportAsString() {
    const model = this.modelManipulator.model;
    const subDocument = model.mainSubDocument;
    const interval = new FixedInterval(0, model.mainSubDocument.getDocumentEndPosition());
    const guidLabel = RichUtils.getCopyPasteGuidLabel({
      sguid: this.sessionGuid,
      cguid: this.clientGuid
    });
    const exporter = new HtmlExporter(this.exportModelOptions);
    return exporter.getHtmlElementsByInterval(model, subDocument, interval, guidLabel).getHtmlString(true);
  }
}