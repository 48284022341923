import { DocumentFormat } from '../../../common/document-format';
import { DocumentExporterOptions } from '../../../common/formats/options';
import { DocxExportOptions } from '../../formats/docx/export/docx-export-options';
import { DocxExporter } from '../../formats/docx/export/exporter';
import { RtfExporter } from '../../../common/formats/rtf/export/exporter';
import { RtfDocumentExporterOptions } from '../../../common/formats/rtf/export/rtf-document-exporter-options';
import { TxtExporter } from '../../../common/formats/txt/txt-exporter';
import { HtmlDocumentExporter } from '../../../common/formats/html/export/html-document-exporter';
export function exportModelToBase64(exportModelOptions, callback) {
  getExporter(exportModelOptions).exportToBase64(callback);
}
export function exportModelToBlob(exportModelOptions, callback) {
  getExporter(exportModelOptions).exportToBlob(callback);
}
function getExporter(exportModelOptions) {
  const {
    modelManipulator
  } = exportModelOptions.modelManager;
  switch (exportModelOptions.documentFormat) {
    case DocumentFormat.OpenXml:
      return new DocxExporter(modelManipulator, new DocxExportOptions());
    case DocumentFormat.Rtf:
      return new RtfExporter(modelManipulator, new RtfDocumentExporterOptions());
    case DocumentFormat.PlainText:
      return new TxtExporter(modelManipulator, new DocumentExporterOptions());
    case DocumentFormat.Html:
      return new HtmlDocumentExporter(exportModelOptions, new DocumentExporterOptions());
    default:
      console.log('Unsupported format');
      return null;
  }
}