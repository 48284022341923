import { Component, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import notify from 'devextreme/ui/notify';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { ApiService } from 'src/app/share/services/api.service';
import { ConfigService } from 'src/app/share/services/config.service';

export interface IPassword {
  id: number;
  altPasswort: string;
  passwort: string;
  passwortConfirm: string;
}

@Component({
  selector: 'app-editPasswordPopup',
  templateUrl: './editPasswordPopup.component.html',
  styleUrls: ['./editPasswordPopup.component.scss'],
  standalone: false,
})
export class EditPasswordPopupComponent {
  @ViewChild('editPasswordPopup', { static: false }) editPasswordPopup: DxPopupComponent;
  @ViewChild('editDataForm', { static: false }) editDataForm: DxFormComponent;

  popupVisible: boolean;
  dataSource: IPassword;
  msg: string;
  isLoading: boolean = false;

  constructor(private apiService: ApiService, public configService: ConfigService) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.passwordComparison = this.passwordComparison.bind(this);
    this.open = this.open.bind(this);
  }

  open(userID: number) {
    this.dataSource = { id: userID, altPasswort: '', passwort: '', passwortConfirm: '' };
    this.popupVisible = true;
    this.msg = '';
  }

  passwordComparison() {
    return this.dataSource?.passwort;
  }

  onClickSave() {
    const validationFromResult = this.editDataForm.instance.validate();

    if (validationFromResult.isValid) {
      this.isLoading = true;
      this.apiService.post<any>(RouteKeys.Option.Benutzer.set_password, this.dataSource).subscribe({
        next: (response) => {
          this.popupVisible = false;

          notify(
            {
              message: 'Erfolgreich gespeichert.',
            },
            'success'
          );
          this.isLoading = false;
        },
        error: (err) => {
          if (err.status === 400) {
            this.msg = 'Das aktuelle Passwort ist nicht korrekt!';
          } else {
            notify(
              {
                message: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an Ihren Administrator!',
              },
              'error'
            );
          }
          this.isLoading = false;
          throw err;
        },
      });
    } else {
      this.msg = 'Bitte überprüfen Sie die eingegebenen Daten!';
      this.isLoading = false;
    }
  }

  onClickClose() {
    this.msg = '';
    this.popupVisible = false;
  }
}
