"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DomUtils = void 0;
var browser_1 = require("../browser");
var common_1 = require("./common");
var math_1 = require("./math");
var string_1 = require("./string");
var DomUtils = function () {
  function DomUtils() {}
  DomUtils.clearInnerHtml = function (element) {
    while (element.firstChild) element.removeChild(element.firstChild);
  };
  DomUtils.setStylePosition = function (style, point) {
    style.left = math_1.MathUtils.round(point.x, 3) + 'px';
    style.top = math_1.MathUtils.round(point.y, 3) + 'px';
  };
  DomUtils.setStyleSize = function (style, size) {
    style.width = math_1.MathUtils.round(size.width, 3) + 'px';
    style.height = math_1.MathUtils.round(size.height, 3) + 'px';
  };
  DomUtils.setStyleSizeAndPosition = function (style, rectangle) {
    DomUtils.setStylePosition(style, rectangle);
    DomUtils.setStyleSize(style, rectangle);
  };
  DomUtils.hideNode = function (node) {
    if (node) {
      var parentNode = node.parentNode;
      if (parentNode) parentNode.removeChild(node);
    }
  };
  DomUtils.isHTMLElementNode = function (node) {
    return node.nodeType === Node.ELEMENT_NODE;
  };
  DomUtils.isTextNode = function (node) {
    return node.nodeType === Node.TEXT_NODE;
  };
  DomUtils.isElementNode = function (node) {
    return node.nodeType === Node.ELEMENT_NODE;
  };
  DomUtils.isHTMLTableRowElement = function (element) {
    return element.tagName === 'TR';
  };
  DomUtils.isItParent = function (parentElement, element) {
    if (!parentElement || !element) return false;
    while (element) {
      if (element === parentElement) return true;
      if (element.tagName === 'BODY') return false;
      element = element.parentNode;
    }
    return false;
  };
  DomUtils.getParentByTagName = function (element, tagName) {
    tagName = tagName.toUpperCase();
    while (element) {
      if (element.tagName === 'BODY') return null;
      if (element.tagName === tagName) return element;
      element = element.parentNode;
    }
    return null;
  };
  DomUtils.getDocumentScrollTop = function () {
    var isScrollBodyIE = browser_1.Browser.IE && DomUtils.getCurrentStyle(document.body).overflow === 'hidden' && document.body.scrollTop > 0;
    if (browser_1.Browser.WebKitFamily || browser_1.Browser.Edge || isScrollBodyIE) {
      if (browser_1.Browser.MacOSMobilePlatform) return window.pageYOffset;
      if (browser_1.Browser.WebKitFamily) return document.documentElement.scrollTop || document.body.scrollTop;
      return document.body.scrollTop;
    } else return document.documentElement.scrollTop;
  };
  DomUtils.getDocumentScrollLeft = function () {
    var isScrollBodyIE = browser_1.Browser.IE && DomUtils.getCurrentStyle(document.body).overflow === 'hidden' && document.body.scrollLeft > 0;
    if (browser_1.Browser.Edge || isScrollBodyIE) return document.body ? document.body.scrollLeft : document.documentElement.scrollLeft;
    if (browser_1.Browser.WebKitFamily) return document.documentElement.scrollLeft || document.body.scrollLeft;
    return document.documentElement.scrollLeft;
  };
  DomUtils.getCurrentStyle = function (element) {
    if (element.currentStyle) return element.currentStyle;else if (document.defaultView && document.defaultView.getComputedStyle) {
      var result = document.defaultView.getComputedStyle(element, null);
      if (!result && browser_1.Browser.Firefox && window.frameElement) {
        var changes = [];
        var curElement = window.frameElement;
        while (!(result = document.defaultView.getComputedStyle(element, null))) {
          changes.push([curElement, curElement.style.display]);
          curElement.style.setProperty('display', 'block', 'important');
          curElement = curElement.tagName === 'BODY' ? curElement.ownerDocument.defaultView.frameElement : curElement.parentNode;
        }
        result = cloneObject(result);
        for (var ch = void 0, i = 0; ch = changes[i]; i++) ch[0].style.display = ch[1];
        document.body.offsetWidth;
      }
      return result;
    }
    return window.getComputedStyle(element, null);
  };
  DomUtils.setFocus = function (element) {
    function focusCore() {
      try {
        element.focus();
        if (browser_1.Browser.IE && document.activeElement !== element) element.focus();
      } catch (e) {}
    }
    if (browser_1.Browser.MacOSMobilePlatform) focusCore();else {
      setTimeout(function () {
        focusCore();
      }, 100);
    }
  };
  DomUtils.hasClassName = function (element, className) {
    try {
      var classNames = className.split(' ');
      var classList = element.classList;
      if (classList) {
        for (var i = classNames.length - 1; i >= 0; i--) {
          if (!classList.contains(classNames[i])) return false;
        }
      } else {
        var elementClassName = element.getAttribute && element.getAttribute('class');
        if (!elementClassName) return false;
        var elementClasses = elementClassName.split(' ');
        for (var i = classNames.length - 1; i >= 0; i--) {
          if (elementClasses.indexOf(classNames[i]) < 0) return false;
        }
      }
      return true;
    } catch (e) {
      return false;
    }
  };
  DomUtils.addClassName = function (element, className) {
    if (!DomUtils.hasClassName(element, className)) {
      var elementClassName = element.getAttribute && element.getAttribute('class');
      element.setAttribute('class', elementClassName === '' ? className : elementClassName + " " + className);
    }
  };
  DomUtils.removeClassName = function (element, className) {
    var elementClassName = element.getAttribute && element.getAttribute('class');
    var updClassName = " " + elementClassName + " ";
    var newClassName = updClassName.replace(" " + className + " ", ' ');
    if (updClassName.length !== newClassName.length) element.setAttribute('class', string_1.StringUtils.trim(newClassName));
  };
  DomUtils.toggleClassName = function (element, className, toggle) {
    if (toggle === undefined) {
      if (DomUtils.hasClassName(element, className)) DomUtils.removeClassName(element, className);else DomUtils.addClassName(element, className);
    } else {
      if (toggle) DomUtils.addClassName(element, className);else DomUtils.removeClassName(element, className);
    }
  };
  DomUtils.pxToInt = function (px) {
    return pxToNumber(px, parseInt);
  };
  DomUtils.pxToFloat = function (px) {
    return pxToNumber(px, parseFloat);
  };
  DomUtils.getAbsolutePositionY = function (element) {
    function getAbsolutePositionY_IE(element) {
      return browser_1.Browser.IE && element.parentNode === null ? 0 : element.getBoundingClientRect().top + DomUtils.getDocumentScrollTop();
    }
    function getAbsolutePositionY_FF3(element) {
      return Math.round(element.getBoundingClientRect().top + DomUtils.getDocumentScrollTop());
    }
    function getAbsolutePositionY_NS(curEl) {
      var pos = getAbsoluteScrollOffset_OperaFF(curEl, false);
      var isFirstCycle = true;
      while (curEl != null) {
        pos += curEl.offsetTop;
        if (!isFirstCycle && curEl.offsetParent != null) pos -= curEl.scrollTop;
        if (!isFirstCycle && browser_1.Browser.Firefox) {
          var style = DomUtils.getCurrentStyle(curEl);
          if (curEl.tagName === 'DIV' && style.overflow !== 'visible') pos += DomUtils.pxToInt(style.borderTopWidth);
        }
        isFirstCycle = false;
        curEl = curEl.offsetParent;
      }
      return pos;
    }
    function getAbsolutePositionY_Other(curEl) {
      var pos = 0;
      var isFirstCycle = true;
      while (curEl != null) {
        pos += curEl.offsetTop;
        if (!isFirstCycle && curEl.offsetParent != null) pos -= curEl.scrollTop;
        isFirstCycle = false;
        curEl = curEl.offsetParent;
      }
      return pos;
    }
    if (!element) return 0;
    if (browser_1.Browser.IE) return getAbsolutePositionY_IE(element);else if (browser_1.Browser.Firefox && browser_1.Browser.Version >= 3) return getAbsolutePositionY_FF3(element);else if (browser_1.Browser.NetscapeFamily && (!browser_1.Browser.Firefox || browser_1.Browser.Version < 3)) return getAbsolutePositionY_NS(element);else if (browser_1.Browser.WebKitFamily || browser_1.Browser.Edge) return getAbsolutePositionY_FF3(element);
    return getAbsolutePositionY_Other(element);
  };
  DomUtils.getAbsolutePositionX = function (element) {
    function getAbsolutePositionX_IE(element) {
      return browser_1.Browser.IE && element.parentNode === null ? 0 : element.getBoundingClientRect().left + DomUtils.getDocumentScrollLeft();
    }
    function getAbsolutePositionX_FF3(element) {
      return Math.round(element.getBoundingClientRect().left + DomUtils.getDocumentScrollLeft());
    }
    function getAbsolutePositionX_Opera(curEl) {
      var isFirstCycle = true;
      var pos = getAbsoluteScrollOffset_OperaFF(curEl, true);
      while (curEl != null) {
        pos += curEl.offsetLeft;
        if (!isFirstCycle) pos -= curEl.scrollLeft;
        curEl = curEl.offsetParent;
        isFirstCycle = false;
      }
      pos += document.body.scrollLeft;
      return pos;
    }
    function getAbsolutePositionX_NS(curEl) {
      var pos = getAbsoluteScrollOffset_OperaFF(curEl, true);
      var isFirstCycle = true;
      while (curEl != null) {
        pos += curEl.offsetLeft;
        if (!isFirstCycle && curEl.offsetParent != null) pos -= curEl.scrollLeft;
        if (!isFirstCycle && browser_1.Browser.Firefox) {
          var style = DomUtils.getCurrentStyle(curEl);
          if (curEl.tagName === 'DIV' && style.overflow !== 'visible') pos += DomUtils.pxToInt(style.borderLeftWidth);
        }
        isFirstCycle = false;
        curEl = curEl.offsetParent;
      }
      return pos;
    }
    function getAbsolutePositionX_Other(curEl) {
      var pos = 0;
      var isFirstCycle = true;
      while (curEl != null) {
        pos += curEl.offsetLeft;
        if (!isFirstCycle && curEl.offsetParent != null) pos -= curEl.scrollLeft;
        isFirstCycle = false;
        curEl = curEl.offsetParent;
      }
      return pos;
    }
    if (!element) return 0;
    if (browser_1.Browser.IE) return getAbsolutePositionX_IE(element);else if (browser_1.Browser.Firefox && browser_1.Browser.Version >= 3) return getAbsolutePositionX_FF3(element);else if (browser_1.Browser.Opera && browser_1.Browser.Version <= 12) return getAbsolutePositionX_Opera(element);else if (browser_1.Browser.NetscapeFamily && (!browser_1.Browser.Firefox || browser_1.Browser.Version < 3)) return getAbsolutePositionX_NS(element);else if (browser_1.Browser.WebKitFamily || browser_1.Browser.Edge) return getAbsolutePositionX_FF3(element);else return getAbsolutePositionX_Other(element);
  };
  DomUtils.isInteractiveControl = function (element) {
    return ['A', 'INPUT', 'SELECT', 'OPTION', 'TEXTAREA', 'BUTTON', 'IFRAME'].indexOf(element.tagName) > -1;
  };
  DomUtils.getClearClientHeight = function (element) {
    return element.offsetHeight - (DomUtils.getTopBottomPaddings(element) + DomUtils.getVerticalBordersWidth(element));
  };
  DomUtils.getTopBottomPaddings = function (element, style) {
    var currentStyle = style ? style : DomUtils.getCurrentStyle(element);
    return DomUtils.pxToInt(currentStyle.paddingTop) + DomUtils.pxToInt(currentStyle.paddingBottom);
  };
  DomUtils.getVerticalBordersWidth = function (element, style) {
    if (!common_1.isDefined(style)) style = browser_1.Browser.IE && browser_1.Browser.MajorVersion !== 9 && window.getComputedStyle ? window.getComputedStyle(element) : DomUtils.getCurrentStyle(element);
    var res = 0;
    if (style.borderTopStyle !== 'none') res += DomUtils.pxToFloat(style.borderTopWidth);
    if (style.borderBottomStyle !== 'none') res += DomUtils.pxToFloat(style.borderBottomWidth);
    return res;
  };
  DomUtils.getNodes = function (parent, predicate) {
    var collection = parent.all || parent.getElementsByTagName('*');
    var result = [];
    for (var i = 0; i < collection.length; i++) {
      var element = collection[i];
      if (predicate(element)) result.push(element);
    }
    return result;
  };
  DomUtils.getChildNodes = function (parent, predicate) {
    var collection = parent.childNodes;
    var result = [];
    for (var i = 0; i < collection.length; i++) {
      var element = collection[i];
      if (predicate(element)) result.push(element);
    }
    return result;
  };
  DomUtils.getNodesByClassName = function (parent, className) {
    if (parent.querySelectorAll) {
      var children = parent.querySelectorAll("." + className);
      var result_1 = [];
      children.forEach(function (element) {
        return result_1.push(element);
      });
      return result_1;
    } else return DomUtils.getNodes(parent, function (elem) {
      return DomUtils.hasClassName(elem, className);
    });
  };
  DomUtils.getChildNodesByClassName = function (parent, className) {
    function nodeListToArray(nodeList, filter) {
      var result = [];
      for (var i = 0; i < nodeList.length; i++) {
        var element = nodeList[i];
        if (filter(element)) result.push(element);
      }
      return result;
    }
    if (parent.querySelectorAll) {
      var children = parent.querySelectorAll("." + className);
      return nodeListToArray(children, function (element) {
        return element.parentNode === parent;
      });
    } else {
      return DomUtils.getChildNodes(parent, function (elem) {
        if (DomUtils.isElementNode(elem)) return common_1.isNonNullString(elem.className) && DomUtils.hasClassName(elem, elem.className);else return false;
      });
    }
  };
  DomUtils.getVerticalScrollBarWidth = function () {
    if (DomUtils.verticalScrollBarWidth === undefined) {
      var container = document.createElement('DIV');
      container.style.cssText = 'position: absolute; top: 0px; left: 0px; visibility: hidden; width: 200px; height: 150px; overflow: hidden; box-sizing: content-box';
      document.body.appendChild(container);
      var child = document.createElement('P');
      container.appendChild(child);
      child.style.cssText = 'width: 100%; height: 200px;';
      var widthWithoutScrollBar = child.offsetWidth;
      container.style.overflow = 'scroll';
      var widthWithScrollBar = child.offsetWidth;
      if (widthWithoutScrollBar === widthWithScrollBar) widthWithScrollBar = container.clientWidth;
      DomUtils.verticalScrollBarWidth = widthWithoutScrollBar - widthWithScrollBar;
      document.body.removeChild(container);
    }
    return DomUtils.verticalScrollBarWidth;
  };
  DomUtils.getHorizontalBordersWidth = function (element, style) {
    if (!common_1.isDefined(style)) style = browser_1.Browser.IE && window.getComputedStyle ? window.getComputedStyle(element) : DomUtils.getCurrentStyle(element);
    var res = 0;
    if (style.borderLeftStyle !== 'none') res += DomUtils.pxToFloat(style.borderLeftWidth);
    if (style.borderRightStyle !== 'none') res += DomUtils.pxToFloat(style.borderRightWidth);
    return res;
  };
  DomUtils.getFontFamiliesFromCssString = function (cssString) {
    return cssString.split(',').map(function (fam) {
      return string_1.StringUtils.trim(fam.replace(/'|"/gi, ''));
    });
  };
  DomUtils.getInnerText = function (container) {
    if (browser_1.Browser.Safari && browser_1.Browser.MajorVersion <= 5) {
      if (DomUtils.html2PlainTextFilter === null) {
        DomUtils.html2PlainTextFilter = document.createElement('DIV');
        DomUtils.html2PlainTextFilter.style.width = '0';
        DomUtils.html2PlainTextFilter.style.height = '0';
        DomUtils.html2PlainTextFilter.style.overflow = 'visible';
        DomUtils.html2PlainTextFilter.style.display = 'none';
        document.body.appendChild(DomUtils.html2PlainTextFilter);
      }
      var filter = DomUtils.html2PlainTextFilter;
      filter.innerHTML = container.innerHTML;
      filter.style.display = '';
      var innerText = filter.innerText;
      filter.style.display = 'none';
      return innerText;
    } else if (browser_1.Browser.NetscapeFamily || browser_1.Browser.WebKitFamily || browser_1.Browser.IE && browser_1.Browser.Version >= 9 || browser_1.Browser.Edge) return container.textContent;else return container.innerText;
  };
  DomUtils.html2PlainTextFilter = null;
  DomUtils.verticalScrollBarWidth = undefined;
  return DomUtils;
}();
exports.DomUtils = DomUtils;
function cloneObject(srcObject) {
  if (typeof srcObject !== 'object' || !common_1.isDefined(srcObject)) return srcObject;
  var newObject = {};
  for (var i in srcObject) newObject[i] = srcObject[i];
  return newObject;
}
function pxToNumber(px, parseFunction) {
  var result = 0;
  if (common_1.isDefined(px) && px !== '') {
    try {
      var indexOfPx = px.indexOf('px');
      if (indexOfPx > -1) result = parseFunction(px.substr(0, indexOfPx));
    } catch (e) {}
  }
  return result;
}
function getAbsoluteScrollOffset_OperaFF(curEl, isX) {
  var pos = 0;
  var isFirstCycle = true;
  while (curEl != null) {
    if (curEl.tagName === 'BODY') break;
    var style = DomUtils.getCurrentStyle(curEl);
    if (style.position === 'absolute') break;
    if (!isFirstCycle && curEl.tagName === 'DIV' && (style.position === '' || style.position === 'static')) pos -= isX ? curEl.scrollLeft : curEl.scrollTop;
    curEl = curEl.parentNode;
    isFirstCycle = false;
  }
  return pos;
}