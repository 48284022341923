import { ListUtils } from '@devexpress/utils/lib/utils/list';
import { RangePermissionsChangedSubDocumentChange } from '../changes/sub-document/range-permissions-changed';
import { ConstRangePermission, RangePermission } from '../range-permissions';
import { BaseManipulator } from './base-manipulator';
import { SearchUtils } from '@devexpress/utils/lib/utils/search';
export class RangePermissionManipulator extends BaseManipulator {
  createRangePermission(subDocument, permissionTemplate) {
    subDocument.rangePermissions.push(new RangePermission(subDocument.positionManager, permissionTemplate.interval, permissionTemplate.userName, permissionTemplate.group));
    subDocument.rangePermissions = subDocument.rangePermissions.sort(ConstRangePermission.comparer);
    subDocument.filterRangePermissions(this.modelManipulator.modelManager.richOptions.documentProtection);
    this.modelManipulator.notifyModelChanged(new RangePermissionsChangedSubDocumentChange(subDocument.id, permissionTemplate));
  }
  deleteRangePermission(subDocument, permissionTemplate, permissionIndex = ListUtils.indexBy(subDocument.rangePermissions, b => b.constRangePermission.equals(permissionTemplate))) {
    subDocument.rangePermissions.splice(permissionIndex, 1)[0].destructor(subDocument.positionManager);
    subDocument.filterRangePermissions(this.modelManipulator.modelManager.richOptions.documentProtection);
    this.modelManipulator.notifyModelChanged(new RangePermissionsChangedSubDocumentChange(subDocument.id, permissionTemplate));
  }
  insertRangePermissionsFromSubDocument(fromSubDocument, toSubDocument, fromInterval, modelsConstOffset) {
    const rangePermissions = fromSubDocument.rangePermissions;
    let ind = SearchUtils.normedInterpolationIndexOf(rangePermissions, b => b.start, fromInterval.start);
    while (rangePermissions[ind] && rangePermissions[ind].start >= fromInterval.start) ind--;
    ind = Math.max(0, ind);
    for (let rpm; (rpm = rangePermissions[ind]) && rpm.start <= fromInterval.end; ind++) {
      if (fromInterval.containsInterval(rpm.interval)) {
        const template = rpm.constRangePermission;
        template.interval.start += modelsConstOffset;
        template.interval.end += modelsConstOffset + 1;
        this.createRangePermission(toSubDocument, template);
      }
    }
    toSubDocument.rangePermissions = toSubDocument.rangePermissions.sort(ConstRangePermission.comparer);
  }
  deleteRangePermissions(subDocument, interval) {
    const rangePermissions = subDocument.rangePermissions;
    const result = [];
    let ind = SearchUtils.normedInterpolationIndexOf(rangePermissions, b => b.start, interval.start);
    while (rangePermissions[ind] && rangePermissions[ind].start >= interval.start) ind--;
    ind = Math.max(0, ind);
    for (let curr; (curr = rangePermissions[ind]) && curr.interval.start <= interval.end;) {
      if (interval.containsInterval(curr.interval)) {
        const tmpl = curr.constRangePermission;
        this.deleteRangePermission(subDocument, tmpl, ind);
        result.push(tmpl);
      } else ind++;
    }
    return result;
  }
}