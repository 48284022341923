import { SelectionIntervalsInfo } from '../../../../common/selection/selection-intervals-info';
import { InputPositionBase } from '../../../../common/selection/input-position-base';
import { TableCellApi } from '../../table/table-cell';
import { ApiParametersChecker } from '../../api-utils/parameter-checker';
import { TableBaseCollection } from './table-base-collection';
export class TableCellCollection extends TableBaseCollection {
  constructor(processor, subDocument, tablePosition, parentRow) {
    super(processor, subDocument);
    this._parentRow = parentRow;
    this._tablePosition = tablePosition;
  }
  _getItem(coreItem) {
    const pos = this._tablePosition.clone();
    pos.cellIndex = pos.row.cells.indexOf(coreItem);
    pos.cell = pos.row.cells[pos.cellIndex];
    return new TableCellApi(this._processor, this._subDocument, pos, this._parentRow);
  }
  _getCoreItems() {
    return this._tablePosition.row.cells;
  }
  insert(index, toRight = false) {
    const {
      table,
      row,
      rowIndex
    } = this._tablePosition;
    const indexDescriptor = ApiParametersChecker.numberDescriptor('index', val => val, 0, row.cells.length);
    const toRightDescriptor = ApiParametersChecker.booleanDescriptor('toRight', val => val);
    index = ApiParametersChecker.check(index, 1, false, [indexDescriptor]);
    toRight = ApiParametersChecker.check(toRight, 2, false, [toRightDescriptor]);
    this._processor.beginUpdate();
    this._history.addTransaction(() => {
      const interval = row.cells[index].interval;
      const inputPositionIntervals = SelectionIntervalsInfo.fromInterval(this._subDocument, interval);
      const inputPosition = new InputPositionBase().setIntervals(inputPositionIntervals);
      if (toRight) this._insertCellToTheRight(table, rowIndex, index, inputPosition);else this._insertCellToTheLeft(table, rowIndex, index, inputPosition);
    });
    this._processor.endUpdate();
    return this.getByIndex(index + Number(toRight));
  }
  remove(index) {
    const {
      table,
      row,
      rowIndex
    } = this._tablePosition;
    const directionDescriptor = ApiParametersChecker.numberDescriptor('index', val => val, 0, row.cells.length);
    index = ApiParametersChecker.check(index, 1, false, [directionDescriptor]);
    this._processor.beginUpdate();
    this._history.addTransaction(() => {
      if (this.count > 1 && table.rows.length > 1) this._removeCell(table, rowIndex, index);else if (table.rows.length > 1) this._removeRow(table, rowIndex);else this._removeTable(table);
    });
    this._processor.endUpdate();
  }
}