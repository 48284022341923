export var RtfParsingState;
(function (RtfParsingState) {
  RtfParsingState[RtfParsingState["Normal"] = 0] = "Normal";
  RtfParsingState[RtfParsingState["BinData"] = 1] = "BinData";
  RtfParsingState[RtfParsingState["HexData"] = 2] = "HexData";
})(RtfParsingState || (RtfParsingState = {}));
export var Win32MapMode;
(function (Win32MapMode) {
  Win32MapMode[Win32MapMode["Text"] = 1] = "Text";
  Win32MapMode[Win32MapMode["LowMetric"] = 2] = "LowMetric";
  Win32MapMode[Win32MapMode["HighMetric"] = 3] = "HighMetric";
  Win32MapMode[Win32MapMode["LowEnglish"] = 4] = "LowEnglish";
  Win32MapMode[Win32MapMode["HighEnglish"] = 5] = "HighEnglish";
  Win32MapMode[Win32MapMode["Twips"] = 6] = "Twips";
  Win32MapMode[Win32MapMode["Isotropic"] = 7] = "Isotropic";
  Win32MapMode[Win32MapMode["Anisotropic"] = 8] = "Anisotropic";
})(Win32MapMode || (Win32MapMode = {}));