export class TableStyleOptionsApi {
  constructor(headerRow, totalRow, bandedRows, firstColumn, lastColumn, bandedColumns) {
    Object.assign(this, {
      headerRow,
      totalRow,
      bandedRows,
      firstColumn,
      lastColumn,
      bandedColumns
    });
  }
}
export const TABLE_STYLE_OPTIONS_KEYS = ['headerRow', 'totalRow', 'bandedRows', 'firstColumn', 'lastColumn', 'bandedColumns'];