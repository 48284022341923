import { StringMapUtils } from '@devexpress/utils/lib/utils/map/string';
import { StringUtils } from '@devexpress/utils/lib/utils/string';
import { DrawingTextFont } from '../drawing/drawing-text-font';
export class ThemeFontSchemePart {
  constructor() {
    this.supplementalFonts = {};
    this.isValid = false;
    this.latin = new DrawingTextFont();
    this.eastAsian = new DrawingTextFont();
    this.complexScript = new DrawingTextFont();
  }
  addSupplementalFont(script, typeface) {
    if (!StringUtils.isNullOrEmpty(script) && !this.supplementalFonts[script] !== undefined) this.supplementalFonts[script] = typeface;
  }
  copyFrom(value) {
    this.latin.copyFrom(value.latin);
    this.eastAsian.copyFrom(value.eastAsian);
    this.complexScript.copyFrom(value.complexScript);
    this.isValid = value.isValid;
    StringMapUtils.clear(this.supplementalFonts);
    StringMapUtils.forEach(value.supplementalFonts, (value, key) => this.addSupplementalFont[key] = value);
  }
  getTypeface(currentUICulture) {
    const name = currentUICulture.name;
    const script = ThemeFontSchemePart.cultureNameToScriptTable[name];
    if (script !== undefined) {
      if (this.supplementalFonts[script] !== undefined) return this.supplementalFonts[script];
    }
    return this.getDefaultTypeface();
  }
  clear() {
    this.latin.clear();
    this.eastAsian.clear();
    this.complexScript.clear();
    StringMapUtils.clear(this.supplementalFonts);
    this.isValid = false;
  }
  getDefaultTypeface() {
    return this.latin.typeface;
  }
}
ThemeFontSchemePart.cultureNameToScriptTable = {
  "ar-LY": "Arab",
  "ar-DZ": "Arab",
  "ar-MA": "Arab",
  "ar-TN": "Arab",
  "ar-OM": "Arab",
  "ar-YE": "Arab",
  "ar-SY": "Arab",
  "ar-JO": "Arab",
  "ar-LB": "Arab",
  "ar-KW": "Arab",
  "ar-AE": "Arab",
  "ar-BH": "Arab",
  "ar-QA": "Arab",
  "ar-SA": "Arab",
  "ur-PK": "Arab",
  "fa-IR": "Arab",
  "ps-AF": "Arab",
  "prs-AF": "Arab",
  "ar-IQ": "Arab",
  "ar-EG": "Arab",
  "hy-AM": "Armn",
  "bn-IN": "Beng",
  "as-IN": "Beng",
  "bn-BD": "Beng",
  "iu-Cans-CA": "Cans",
  "sr-Cyrl-BA": "Cyrl",
  "bs-Cyrl-BA": "Cyrl",
  "sr-Cyrl-RS": "Cyrl",
  "sr-Cyrl-ME": "Cyrl",
  "bg-BG": "Cyrl",
  "ru-RU": "Cyrl",
  "uk-UA": "Cyrl",
  "be-BY": "Cyrl",
  "tg-Cyrl-TJ": "Cyrl",
  "mk-MK": "Cyrl",
  "kk-KZ": "Cyrl",
  "ky-KG": "Cyrl",
  "tt-RU": "Cyrl",
  "mn-MN": "Cyrl",
  "ba-RU": "Cyrl",
  "sah-RU": "Cyrl",
  "az-Cyrl-AZ": "Cyrl",
  "uz-Cyrl-UZ": "Cyrl",
  "sr-Cyrl-CS": "Cyrl",
  "hi-IN": "Deva",
  "mr-IN": "Deva",
  "sa-IN": "Deva",
  "kok-IN": "Deva",
  "ne-NP": "Deva",
  "am-ET": "Ethi",
  "ka-GE": "Geor",
  "el-GR": "Grek",
  "gu-IN": "Gujr",
  "pa-IN": "Guru",
  "ko-KR": "Hang",
  "zh-SG": "Hans",
  "zh-CN": "Hans",
  "zh-MO": "Hant",
  "zh-TW": "Hant",
  "zh-HK": "Hant",
  "he-IL": "Hebr",
  "ja-JP": "Jpan",
  "km-KH": "Khmr",
  "kn-IN": "Knda",
  "lo-LA": "Laoo",
  "de-LU": "Latn",
  "en-CA": "Latn",
  "es-GT": "Latn",
  "fr-CH": "Latn",
  "hr-BA": "Latn",
  "smj-NO": "Latn",
  "de-LI": "Latn",
  "en-NZ": "Latn",
  "es-CR": "Latn",
  "fr-LU": "Latn",
  "bs-Latn-BA": "Latn",
  "smj-SE": "Latn",
  "en-IE": "Latn",
  "es-PA": "Latn",
  "fr-MC": "Latn",
  "sr-Latn-BA": "Latn",
  "sma-NO": "Latn",
  "en-ZA": "Latn",
  "es-DO": "Latn",
  "sma-SE": "Latn",
  "en-JM": "Latn",
  "es-VE": "Latn",
  "sms-FI": "Latn",
  "en-029": "Latn",
  "es-CO": "Latn",
  "sr-Latn-RS": "Latn",
  "smn-FI": "Latn",
  "en-BZ": "Latn",
  "es-PE": "Latn",
  "en-TT": "Latn",
  "es-AR": "Latn",
  "sr-Latn-ME": "Latn",
  "en-ZW": "Latn",
  "es-EC": "Latn",
  "en-PH": "Latn",
  "es-CL": "Latn",
  "es-UY": "Latn",
  "es-PY": "Latn",
  "en-IN": "Latn",
  "es-BO": "Latn",
  "ca-ES": "Latn",
  "cs-CZ": "Latn",
  "da-DK": "Latn",
  "de-DE": "Latn",
  "en-US": "Latn",
  "es-ES": "Latn",
  "fi-FI": "Latn",
  "fr-FR": "Latn",
  "hu-HU": "Latn",
  "is-IS": "Latn",
  "it-IT": "Latn",
  "nl-NL": "Latn",
  "nb-NO": "Latn",
  "pl-PL": "Latn",
  "pt-BR": "Latn",
  "rm-CH": "Latn",
  "ro-RO": "Latn",
  "hr-HR": "Latn",
  "sk-SK": "Latn",
  "sq-AL": "Latn",
  "sv-SE": "Latn",
  "tr-TR": "Latn",
  "id-ID": "Latn",
  "sl-SI": "Latn",
  "et-EE": "Latn",
  "lv-LV": "Latn",
  "lt-LT": "Latn",
  "az-Latn-AZ": "Latn",
  "eu-ES": "Latn",
  "hsb-DE": "Latn",
  "tn-ZA": "Latn",
  "xh-ZA": "Latn",
  "zu-ZA": "Latn",
  "af-ZA": "Latn",
  "fo-FO": "Latn",
  "mt-MT": "Latn",
  "se-NO": "Latn",
  "ms-MY": "Latn",
  "en-MY": "Latn",
  "es-SV": "Latn",
  "sw-KE": "Latn",
  "tk-TM": "Latn",
  "uz-Latn-UZ": "Latn",
  "cy-GB": "Latn",
  "gl-ES": "Latn",
  "fy-NL": "Latn",
  "fil-PH": "Latn",
  "ha-Latn-NG": "Latn",
  "yo-NG": "Latn",
  "quz-BO": "Latn",
  "nso-ZA": "Latn",
  "lb-LU": "Latn",
  "kl-GL": "Latn",
  "ig-NG": "Latn",
  "arn-CL": "Latn",
  "moh-CA": "Latn",
  "br-FR": "Latn",
  "en-SG": "Latn",
  "es-HN": "Latn",
  "mi-NZ": "Latn",
  "oc-FR": "Latn",
  "co-FR": "Latn",
  "gsw-FR": "Latn",
  "qut-GT": "Latn",
  "rw-RW": "Latn",
  "wo-SN": "Latn",
  "gd-GB": "Latn",
  "es-NI": "Latn",
  "es-PR": "Latn",
  "es-US": "Latn",
  "de-CH": "Latn",
  "en-GB": "Latn",
  "es-MX": "Latn",
  "fr-BE": "Latn",
  "it-CH": "Latn",
  "nl-BE": "Latn",
  "nn-NO": "Latn",
  "pt-PT": "Latn",
  "sr-Latn-CS": "Latn",
  "sv-FI": "Latn",
  "dsb-DE": "Latn",
  "se-SE": "Latn",
  "ga-IE": "Latn",
  "ms-BN": "Latn",
  "iu-Latn-CA": "Latn",
  "tzm-Latn-DZ": "Latn",
  "quz-EC": "Latn",
  "de-AT": "Latn",
  "en-AU": "Latn",
  "fr-CA": "Latn",
  "se-FI": "Latn",
  "quz-PE": "Latn",
  "ml-IN": "Mlym",
  "mn-Mong-CN": "Mong",
  "or-IN": "Orya",
  "si-LK": "Sinh",
  "syr-SY": "Syrc",
  "ta-IN": "Taml",
  "te-IN": "Telu",
  "dv-MV": "Thaa",
  "th-TH": "Thai",
  "bo-CN": "Tibt",
  "ug-CN": "Uigh",
  "vi-VN": "Viet",
  "ii-CN": "Yiii"
};