export var JSONRichEditBehaviorOptionsProperty;
(function (JSONRichEditBehaviorOptionsProperty) {
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Copy"] = 0] = "Copy";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["CreateNew"] = 1] = "CreateNew";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Cut"] = 2] = "Cut";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Drag"] = 3] = "Drag";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Drop"] = 4] = "Drop";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["FontSource"] = 5] = "FontSource";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["ForeColorSource"] = 6] = "ForeColorSource";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["MaxZoomFactor"] = 7] = "MaxZoomFactor";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["MinZoomFactor"] = 8] = "MinZoomFactor";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["OfficeScrolling"] = 9] = "OfficeScrolling";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Open"] = 10] = "Open";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["OvertypeAllowed"] = 11] = "OvertypeAllowed";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["PageBreakInsertMode"] = 12] = "PageBreakInsertMode";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Paste"] = 13] = "Paste";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["PasteLineBreakSubstitution"] = 14] = "PasteLineBreakSubstitution";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["PasteSingleCellAsText"] = 15] = "PasteSingleCellAsText";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Printing"] = 16] = "Printing";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Save"] = 17] = "Save";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["SaveAs"] = 18] = "SaveAs";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["ShowPopupMenu"] = 19] = "ShowPopupMenu";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["TabMarker"] = 20] = "TabMarker";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Touch"] = 21] = "Touch";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["UseFontSubstitution"] = 22] = "UseFontSubstitution";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Zooming"] = 23] = "Zooming";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["DocumentPropertiesAutoUpdate"] = 24] = "DocumentPropertiesAutoUpdate";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["KeepTextHighlightingOnClearFormatting"] = 25] = "KeepTextHighlightingOnClearFormatting";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["Download"] = 26] = "Download";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["AcceptsTab"] = 27] = "AcceptsTab";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["FullScreen"] = 28] = "FullScreen";
  JSONRichEditBehaviorOptionsProperty[JSONRichEditBehaviorOptionsProperty["RaiseClientEventsOnModificationsViaAPI"] = 29] = "RaiseClientEventsOnModificationsViaAPI";
})(JSONRichEditBehaviorOptionsProperty || (JSONRichEditBehaviorOptionsProperty = {}));
export var JSONControlOptionsProperty;
(function (JSONControlOptionsProperty) {
  JSONControlOptionsProperty[JSONControlOptionsProperty["Copy"] = 0] = "Copy";
  JSONControlOptionsProperty[JSONControlOptionsProperty["CreateNew"] = 1] = "CreateNew";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Cut"] = 2] = "Cut";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Drag"] = 3] = "Drag";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Drop"] = 4] = "Drop";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Open"] = 5] = "Open";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Paste"] = 6] = "Paste";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Printing"] = 7] = "Printing";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Save"] = 8] = "Save";
  JSONControlOptionsProperty[JSONControlOptionsProperty["SaveAs"] = 9] = "SaveAs";
  JSONControlOptionsProperty[JSONControlOptionsProperty["FullScreen"] = 10] = "FullScreen";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Bookmarks"] = 11] = "Bookmarks";
  JSONControlOptionsProperty[JSONControlOptionsProperty["CharacterFormatting"] = 12] = "CharacterFormatting";
  JSONControlOptionsProperty[JSONControlOptionsProperty["CharacterStyle"] = 13] = "CharacterStyle";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Fields"] = 14] = "Fields";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Hyperlinks"] = 15] = "Hyperlinks";
  JSONControlOptionsProperty[JSONControlOptionsProperty["InlinePictures"] = 16] = "InlinePictures";
  JSONControlOptionsProperty[JSONControlOptionsProperty["ParagraphFormatting"] = 17] = "ParagraphFormatting";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Paragraphs"] = 18] = "Paragraphs";
  JSONControlOptionsProperty[JSONControlOptionsProperty["ParagraphStyle"] = 19] = "ParagraphStyle";
  JSONControlOptionsProperty[JSONControlOptionsProperty["ParagraphTabs"] = 20] = "ParagraphTabs";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Sections"] = 21] = "Sections";
  JSONControlOptionsProperty[JSONControlOptionsProperty["TabSymbol"] = 22] = "TabSymbol";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Undo"] = 23] = "Undo";
  JSONControlOptionsProperty[JSONControlOptionsProperty["NumberingBulleted"] = 24] = "NumberingBulleted";
  JSONControlOptionsProperty[JSONControlOptionsProperty["NumberingMultiLevel"] = 25] = "NumberingMultiLevel";
  JSONControlOptionsProperty[JSONControlOptionsProperty["NumberingSimple"] = 26] = "NumberingSimple";
  JSONControlOptionsProperty[JSONControlOptionsProperty["HeadersFooters"] = 27] = "HeadersFooters";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Tables"] = 28] = "Tables";
  JSONControlOptionsProperty[JSONControlOptionsProperty["TableStyle"] = 29] = "TableStyle";
  JSONControlOptionsProperty[JSONControlOptionsProperty["TabMarker"] = 30] = "TabMarker";
  JSONControlOptionsProperty[JSONControlOptionsProperty["PageBreakInsertMode"] = 31] = "PageBreakInsertMode";
  JSONControlOptionsProperty[JSONControlOptionsProperty["AcceptsTab"] = 32] = "AcceptsTab";
  JSONControlOptionsProperty[JSONControlOptionsProperty["Download"] = 33] = "Download";
  JSONControlOptionsProperty[JSONControlOptionsProperty["RaiseClientEventsOnModificationsViaAPI"] = 34] = "RaiseClientEventsOnModificationsViaAPI";
})(JSONControlOptionsProperty || (JSONControlOptionsProperty = {}));