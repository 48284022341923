import { SubDocumentInterval } from '../../../common/model/sub-document';
import { TableWidthUnit, TableWidthUnitType } from '../../../common/model/tables/secondary-structures/table-units';
import { ColorHelper } from '../../../common/model/color/color';
import { ColorModelInfo } from '../../../common/model/color/color-model-info';
import { ParagraphAlignmentHistoryItem } from '../../../common/model/history/items/paragraph-properties-history-items';
import { TableCellVerticalAlignmentHistoryItem } from '../../../common/model/history/items/tables/table-cell-properties-history-items';
import { TableCellMargins } from '../../../common/model/tables/secondary-structures/table-base-structures';
import { BorderInfo } from '../../../common/model/borders/border-info';
import { ApiParametersChecker } from '../api-utils/parameter-checker';
import { BorderLineStyleApi, TableContentHorizontalAlignmentApi, TableContentVerticalAlignmentApi, TableWidthTypeApi } from './enums';
import { TableBorderApi } from './table-borders';
import { MarginsApi } from '../size';
import { ModelParametersChecker } from '../api-utils/model-parameter-checker';
export class TableElementBase {
  constructor(processor, subDocument) {
    this._processor = processor;
    this._subDocument = subDocument;
    this._history = processor.modelManager.history;
    this._modelManipulator = processor.modelManager.modelManipulator;
  }
  _validateWidth(width) {
    const widthDescriptor = ApiParametersChecker.objectDescriptor('width', 'TableWidth', val => val);
    const widthTypeDescriptor = ApiParametersChecker.enumDescriptor('width.type', val => val, TableWidthTypeApi, 'TableWidthType');
    const widthValueDescriptor = ApiParametersChecker.numberDescriptor('width.value', val => val, 0);
    ApiParametersChecker.check(width, 1, false, [widthDescriptor]);
    ApiParametersChecker.check(width.type, 1, false, [widthTypeDescriptor]);
    ApiParametersChecker.check(width.value, 1, false, [widthValueDescriptor]);
  }
  _getWidth(preferredWidth) {
    const {
      type,
      value
    } = preferredWidth;
    return {
      type: Number(type),
      value: type === TableWidthUnitType.FiftiethsOfPercent ? Math.floor(value / 50) : value
    };
  }
  _getModelWidth(width) {
    const actualValue = width.type === TableWidthTypeApi.Percent ? Math.floor(width.value * 50) : width.value;
    return TableWidthUnit.create(actualValue, Number(width.type));
  }
  _getBackgroundColor(shadingInfo) {
    const backColorHash = shadingInfo.backColor.toRgb(this._subDocument.documentModel.colorProvider);
    return ColorHelper.getHashString(backColorHash);
  }
  _validateContentHorizontalAlignment(contentHorizontalAlignment) {
    const valueDescriptor = ApiParametersChecker.enumDescriptor('contentHorizontalAlignment', val => val, TableContentHorizontalAlignmentApi, 'TableContentHorizontalAlignment');
    ApiParametersChecker.check(contentHorizontalAlignment, 1, false, [valueDescriptor]);
  }
  _getStartHorizontalAlignment(cell) {
    const startPosition = cell.interval.start;
    const firstParagraph = this._subDocument.getParagraphByPosition(startPosition);
    return firstParagraph.getParagraphMergedProperties().alignment;
  }
  _setCellHorizontalAlignment(cell, value) {
    const documentInterval = new SubDocumentInterval(this._subDocument, cell.interval);
    const historyItem = new ParagraphAlignmentHistoryItem(this._modelManipulator, documentInterval, Number(value), true);
    this._history.addAndRedo(historyItem);
  }
  _validateContentVerticalAlignment(contentVerticalAlignment) {
    const valueDescriptor = ApiParametersChecker.enumDescriptor('contentVerticalAlignment', val => val, TableContentVerticalAlignmentApi, 'TableContentVerticalAlignment');
    ApiParametersChecker.check(contentVerticalAlignment, 1, false, [valueDescriptor]);
  }
  _setCellVerticalAlignment(tablePosition, value) {
    const {
      cell,
      table,
      rowIndex,
      cellIndex
    } = tablePosition;
    if (Number(cell.properties.verticalAlignment) === Number(value)) return;
    const historyItem = new TableCellVerticalAlignmentHistoryItem(this._modelManipulator, this._subDocument, table.index, rowIndex, cellIndex, Number(value), true);
    this._history.addAndRedo(historyItem);
  }
  _validateBorders(factory, borders) {
    const resultBorders = new factory();
    const valueDescriptor = ApiParametersChecker.objectDescriptor('borders', 'Borders', val => val);
    ApiParametersChecker.check(borders, 1, false, [valueDescriptor]);
    for (const borderKey of Object.keys(borders)) {
      const borderDescriptor = ApiParametersChecker.objectDescriptor(`borders.${borderKey}`, 'Border', val => val);
      const borderStyleDescriptor = ApiParametersChecker.enumDescriptor(`borders.${borderKey}.style`, val => Number(val), BorderLineStyleApi, 'BorderLineStyle');
      const borderWidthDescriptor = ApiParametersChecker.numberDescriptor(`borders.${borderKey}.width`, val => val, 0);
      const border = ApiParametersChecker.check(borders[borderKey], 1, true, [borderDescriptor]);
      if (border === void 0) continue;
      resultBorders[borderKey] = new BorderInfo();
      resultBorders[borderKey].style = ApiParametersChecker.check(border.style, 1, false, [borderStyleDescriptor]);
      resultBorders[borderKey].color = this._validateColor(border.color, 1, `borders.${borderKey}.color`);
      resultBorders[borderKey].width = ApiParametersChecker.check(border.width, 1, false, [borderWidthDescriptor]);
    }
    return resultBorders;
  }
  _validateColor(value, parameterIndex, parameterName) {
    const color = ApiParametersChecker.check(value, parameterIndex, false, ModelParametersChecker.colorDescriptors(parameterName));
    return ColorModelInfo.makeByColor(color);
  }
  _getBordersApi(factory, borders) {
    const bordersApi = new factory();
    for (const key of Object.keys(bordersApi)) {
      const color = borders[key].color.toRgb(this._subDocument.documentModel.colorProvider);
      bordersApi[key] = new TableBorderApi(Number(borders[key].style), ColorHelper.getHashString(color), borders[key].width);
    }
    return bordersApi;
  }
  _validateMargins(margins, parameterName) {
    const cellMargins = new TableCellMargins();
    const marginsDescriptor = ApiParametersChecker.objectDescriptor(parameterName, 'Margins', val => val);
    ApiParametersChecker.check(margins, 1, false, [marginsDescriptor]);
    for (const key of Object.keys(margins)) {
      const marginDescriptor = ApiParametersChecker.numberDescriptor(`${parameterName}.${key}`, val => val, 0);
      const value = ApiParametersChecker.check(margins[key], 1, true, [marginDescriptor]);
      if (value === void 0) continue;
      cellMargins[key] = TableWidthUnit.create(value, TableWidthUnitType.ModelUnits);
    }
    return cellMargins;
  }
  _getMarginsApi(margins) {
    return new MarginsApi(margins.left.value, margins.right.value, margins.top.value, margins.bottom.value);
  }
}