export var JSONEnumTable;
(function (JSONEnumTable) {
  JSONEnumTable[JSONEnumTable["StyleIndex"] = 0] = "StyleIndex";
  JSONEnumTable[JSONEnumTable["NestedLevel"] = 1] = "NestedLevel";
  JSONEnumTable[JSONEnumTable["TableProperties"] = 2] = "TableProperties";
  JSONEnumTable[JSONEnumTable["Index"] = 3] = "Index";
  JSONEnumTable[JSONEnumTable["PreferredWidth"] = 4] = "PreferredWidth";
  JSONEnumTable[JSONEnumTable["LookTypes"] = 5] = "LookTypes";
  JSONEnumTable[JSONEnumTable["ParentCell"] = 6] = "ParentCell";
  JSONEnumTable[JSONEnumTable["Rows"] = 7] = "Rows";
})(JSONEnumTable || (JSONEnumTable = {}));
export var JSONEnumTableProperty;
(function (JSONEnumTableProperty) {
  JSONEnumTableProperty[JSONEnumTableProperty["CellMargins"] = 0] = "CellMargins";
  JSONEnumTableProperty[JSONEnumTableProperty["CellSpacing"] = 1] = "CellSpacing";
  JSONEnumTableProperty[JSONEnumTableProperty["Indent"] = 2] = "Indent";
  JSONEnumTableProperty[JSONEnumTableProperty["PreferredWidth"] = 3] = "PreferredWidth";
  JSONEnumTableProperty[JSONEnumTableProperty["Borders"] = 4] = "Borders";
  JSONEnumTableProperty[JSONEnumTableProperty["TableStyleColBandSize"] = 5] = "TableStyleColBandSize";
  JSONEnumTableProperty[JSONEnumTableProperty["TableStyleRowBandSize"] = 6] = "TableStyleRowBandSize";
  JSONEnumTableProperty[JSONEnumTableProperty["IsTableOverlap"] = 7] = "IsTableOverlap";
  JSONEnumTableProperty[JSONEnumTableProperty["AvoidDoubleBorders"] = 8] = "AvoidDoubleBorders";
  JSONEnumTableProperty[JSONEnumTableProperty["LayoutType"] = 9] = "LayoutType";
  JSONEnumTableProperty[JSONEnumTableProperty["TableLookTypes"] = 10] = "TableLookTypes";
  JSONEnumTableProperty[JSONEnumTableProperty["ShadingInfo"] = 11] = "ShadingInfo";
  JSONEnumTableProperty[JSONEnumTableProperty["TableRowAlignment"] = 12] = "TableRowAlignment";
  JSONEnumTableProperty[JSONEnumTableProperty["BottomFromText"] = 13] = "BottomFromText";
  JSONEnumTableProperty[JSONEnumTableProperty["LeftFromText"] = 14] = "LeftFromText";
  JSONEnumTableProperty[JSONEnumTableProperty["TopFromText"] = 15] = "TopFromText";
  JSONEnumTableProperty[JSONEnumTableProperty["RightFromText"] = 16] = "RightFromText";
  JSONEnumTableProperty[JSONEnumTableProperty["TableHorizontalPosition"] = 17] = "TableHorizontalPosition";
  JSONEnumTableProperty[JSONEnumTableProperty["TableVerticalPosition"] = 18] = "TableVerticalPosition";
  JSONEnumTableProperty[JSONEnumTableProperty["HorizontalAlignMode"] = 19] = "HorizontalAlignMode";
  JSONEnumTableProperty[JSONEnumTableProperty["VerticalAlignMode"] = 20] = "VerticalAlignMode";
  JSONEnumTableProperty[JSONEnumTableProperty["HorizontalAnchorType"] = 21] = "HorizontalAnchorType";
  JSONEnumTableProperty[JSONEnumTableProperty["VerticalAnchorType"] = 22] = "VerticalAnchorType";
  JSONEnumTableProperty[JSONEnumTableProperty["TextWrapping"] = 23] = "TextWrapping";
  JSONEnumTableProperty[JSONEnumTableProperty["UseValue"] = 24] = "UseValue";
  JSONEnumTableProperty[JSONEnumTableProperty["ShadingInfoIndex"] = 25] = "ShadingInfoIndex";
})(JSONEnumTableProperty || (JSONEnumTableProperty = {}));
export var JSONEnumTableParentCellInfo;
(function (JSONEnumTableParentCellInfo) {
  JSONEnumTableParentCellInfo[JSONEnumTableParentCellInfo["CellIndex"] = 0] = "CellIndex";
  JSONEnumTableParentCellInfo[JSONEnumTableParentCellInfo["RowIndex"] = 1] = "RowIndex";
  JSONEnumTableParentCellInfo[JSONEnumTableParentCellInfo["TableIndex"] = 2] = "TableIndex";
})(JSONEnumTableParentCellInfo || (JSONEnumTableParentCellInfo = {}));
export var JSONEnumClientTableInfo;
(function (JSONEnumClientTableInfo) {
  JSONEnumClientTableInfo[JSONEnumClientTableInfo["TablePosition"] = 0] = "TablePosition";
  JSONEnumClientTableInfo[JSONEnumClientTableInfo["Position"] = 1] = "Position";
  JSONEnumClientTableInfo[JSONEnumClientTableInfo["Rows"] = 2] = "Rows";
  JSONEnumClientTableInfo[JSONEnumClientTableInfo["TableInfo"] = 3] = "TableInfo";
})(JSONEnumClientTableInfo || (JSONEnumClientTableInfo = {}));
export var JSONEnumClientTableFormatting;
(function (JSONEnumClientTableFormatting) {
  JSONEnumClientTableFormatting[JSONEnumClientTableFormatting["TableProperties"] = 0] = "TableProperties";
  JSONEnumClientTableFormatting[JSONEnumClientTableFormatting["StyleName"] = 1] = "StyleName";
  JSONEnumClientTableFormatting[JSONEnumClientTableFormatting["PreferredWidth"] = 2] = "PreferredWidth";
  JSONEnumClientTableFormatting[JSONEnumClientTableFormatting["LookTypes"] = 3] = "LookTypes";
})(JSONEnumClientTableFormatting || (JSONEnumClientTableFormatting = {}));
export var JSONEnumClientTablePosition;
(function (JSONEnumClientTablePosition) {
  JSONEnumClientTablePosition[JSONEnumClientTablePosition["ParagraphStartPosition"] = 0] = "ParagraphStartPosition";
  JSONEnumClientTablePosition[JSONEnumClientTablePosition["NestedLevel"] = 1] = "NestedLevel";
})(JSONEnumClientTablePosition || (JSONEnumClientTablePosition = {}));