import { TablePosition } from '../../../../common/model/tables/main-structures/table';
import { ApiParametersChecker } from '../../api-utils/parameter-checker';
import { TableRowApi } from '../../table/table-row';
import { TableBaseCollection } from './table-base-collection';
export class TableRowCollection extends TableBaseCollection {
  constructor(processor, subDocument, table, tableApi) {
    super(processor, subDocument);
    this._table = table;
    this._tableApi = tableApi;
  }
  _getItem(coreItem) {
    const pos = new TablePosition(this._table, this._table.rows.indexOf(coreItem), -1);
    pos.row = pos.table.rows[pos.rowIndex];
    return new TableRowApi(this._processor, this._subDocument, pos, this._tableApi);
  }
  _getCoreItems() {
    return this._table.rows;
  }
  insert(index, below = false) {
    const indexDescriptor = ApiParametersChecker.numberDescriptor('index', val => val, 0, this._table.rows.length);
    const belowDescriptor = ApiParametersChecker.booleanDescriptor('below', val => val);
    index = ApiParametersChecker.check(index, 1, false, [indexDescriptor]);
    below = ApiParametersChecker.check(below, 2, false, [belowDescriptor]);
    this._processor.beginUpdate();
    this._history.addTransaction(() => {
      if (below) this._insertRowBelow(this._table, index);else this._insertRowAbove(this._table, index);
    });
    this._processor.endUpdate();
    return this.getByIndex(index + Number(below));
  }
  remove(index) {
    const directionDescriptor = ApiParametersChecker.numberDescriptor('index', val => val, 0, this._table.rows.length);
    index = ApiParametersChecker.check(index, 1, false, [directionDescriptor]);
    this._processor.beginUpdate();
    this._history.addTransaction(() => {
      if (this.count > 1) this._removeRow(this._table, index);else this._removeTable(this._table);
    });
    this._processor.endUpdate();
  }
}