import { MapCreator } from "../../../../../../../common/utils/map-creator";
import { ElementDestination } from "../../destination";
import { SubDocumentInfoType } from "../../../../../../../common/model/enums";
import { NoteType } from "../../../../../../../common/model/footnotes/footnote";
import { StringUtils } from "@devexpress/utils/lib/utils/string";
import { NoteDestination } from "../note-destination";
export class FootNotesDestination extends ElementDestination {
  get elementHandlerTable() {
    return FootNotesDestination.handlerTable;
  }
}
FootNotesDestination.handlerTable = new MapCreator().add('footnote', data => new FootNoteDestination(data)).get();
class FootNoteDestination extends NoteDestination {
  get SubDocumentType() {
    return SubDocumentInfoType.FootNote;
  }
  addNote(id, subDocId, type) {
    if (!this.canAddFootNote(id, type)) return false;
    if (type == NoteType.Normal) this.data.footNotesImporter.notes[id] = subDocId;else this.documentModel.footNoteSeparators.setSeparator(subDocId, type);
    return true;
  }
  canAddFootNote(id, type) {
    if (StringUtils.isNullOrEmpty(id)) return false;
    if (type == NoteType.Normal) return !(id in this.data.footNotesImporter.notes);else {
      if (this.documentModel.footNoteSeparators.getSeparator(type)) return false;
      return this.data.footNotesImporter.separatorIds.includes(id);
    }
  }
}