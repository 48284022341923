"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KeyCode = exports.ModifierKey = exports.KeyUtils = void 0;
var browser_1 = require("../browser");
var encode_1 = require("./encode");
var string_1 = require("./string");
var KeyUtils = function () {
  function KeyUtils() {}
  KeyUtils.getKeyModifiers = function (evt) {
    var result = 0;
    if (evt.altKey) result |= ModifierKey.Alt;
    if (evt.ctrlKey) result |= ModifierKey.Ctrl;
    if (evt.shiftKey) result |= ModifierKey.Shift;
    if (evt.metaKey) result |= ModifierKey.Meta;
    return result;
  };
  KeyUtils.getShortcutCode = function (keyCode, isCtrlKey, isShiftKey, isAltKey, isMetaKey) {
    var value = keyCode;
    value |= isCtrlKey ? ModifierKey.Ctrl : 0;
    value |= isShiftKey ? ModifierKey.Shift : 0;
    value |= isAltKey ? ModifierKey.Alt : 0;
    value |= isMetaKey ? ModifierKey.Meta : 0;
    return value;
  };
  KeyUtils.getShortcutCodeByEvent = function (evt) {
    return KeyUtils.getShortcutCode(KeyUtils.getEventKeyCode(evt), evt.ctrlKey, evt.shiftKey, evt.altKey, browser_1.Browser.MacOSPlatform ? evt.metaKey : false);
  };
  KeyUtils.getEventKeyCode = function (evt) {
    return browser_1.Browser.NetscapeFamily || browser_1.Browser.Opera ? evt.which : evt.keyCode;
  };
  KeyUtils.parseShortcutString = function (shortcutString) {
    if (!shortcutString) return 0;
    var isCtrlKey = false;
    var isShiftKey = false;
    var isAltKey = false;
    var isMetaKey = false;
    var keyCode = null;
    var shcKeys = shortcutString.toString().split('+');
    if (shcKeys.length > 0) {
      for (var i = 0; i < shcKeys.length; i++) {
        var key = string_1.StringUtils.trim(shcKeys[i].toUpperCase());
        switch (key) {
          case 'CONTROL':
          case 'CONTROLKEY':
          case 'CTRL':
            isCtrlKey = true;
            break;
          case 'SHIFT':
          case 'SHIFTKEY':
            isShiftKey = true;
            break;
          case 'ALT':
            isAltKey = true;
            break;
          case 'CMD':
            isMetaKey = true;
            break;
          case 'F1':
            keyCode = KeyCode.F1;
            break;
          case 'F2':
            keyCode = KeyCode.F2;
            break;
          case 'F3':
            keyCode = KeyCode.F3;
            break;
          case 'F4':
            keyCode = KeyCode.F4;
            break;
          case 'F5':
            keyCode = KeyCode.F5;
            break;
          case 'F6':
            keyCode = KeyCode.F6;
            break;
          case 'F7':
            keyCode = KeyCode.F7;
            break;
          case 'F8':
            keyCode = KeyCode.F8;
            break;
          case 'F9':
            keyCode = KeyCode.F9;
            break;
          case 'F10':
            keyCode = KeyCode.F10;
            break;
          case 'F11':
            keyCode = KeyCode.F11;
            break;
          case 'F12':
            keyCode = KeyCode.F12;
            break;
          case 'RETURN':
          case 'ENTER':
            keyCode = KeyCode.Enter;
            break;
          case 'HOME':
            keyCode = KeyCode.Home;
            break;
          case 'END':
            keyCode = KeyCode.End;
            break;
          case 'LEFT':
            keyCode = KeyCode.Left;
            break;
          case 'RIGHT':
            keyCode = KeyCode.Right;
            break;
          case 'UP':
            keyCode = KeyCode.Up;
            break;
          case 'DOWN':
            keyCode = KeyCode.Down;
            break;
          case 'PAGEUP':
            keyCode = KeyCode.PageUp;
            break;
          case 'PAGEDOWN':
            keyCode = KeyCode.PageDown;
            break;
          case 'SPACE':
            keyCode = KeyCode.Space;
            break;
          case 'TAB':
            keyCode = KeyCode.Tab;
            break;
          case 'BACKSPACE':
          case 'BACK':
            keyCode = KeyCode.Backspace;
            break;
          case 'CONTEXT':
            keyCode = KeyCode.ContextMenu;
            break;
          case 'ESCAPE':
          case 'ESC':
            keyCode = KeyCode.Esc;
            break;
          case 'DELETE':
          case 'DEL':
            keyCode = KeyCode.Delete;
            break;
          case 'INSERT':
          case 'INS':
            keyCode = KeyCode.Insert;
            break;
          case 'PLUS':
            keyCode = '+'.charCodeAt(0);
            break;
          default:
            keyCode = key.charCodeAt(0);
            break;
        }
      }
    } else alert(encode_1.EncodeUtils.decodeViaTextArea('Invalid shortcut'));
    return KeyUtils.getShortcutCode(keyCode, isCtrlKey, isShiftKey, isAltKey, isMetaKey);
  };
  return KeyUtils;
}();
exports.KeyUtils = KeyUtils;
var ModifierKey;
(function (ModifierKey) {
  ModifierKey[ModifierKey["None"] = 0] = "None";
  ModifierKey[ModifierKey["Ctrl"] = 65536] = "Ctrl";
  ModifierKey[ModifierKey["Shift"] = 262144] = "Shift";
  ModifierKey[ModifierKey["Alt"] = 1048576] = "Alt";
  ModifierKey[ModifierKey["Meta"] = 16777216] = "Meta";
})(ModifierKey = exports.ModifierKey || (exports.ModifierKey = {}));
var KeyCode;
(function (KeyCode) {
  KeyCode[KeyCode["Backspace"] = 8] = "Backspace";
  KeyCode[KeyCode["Tab"] = 9] = "Tab";
  KeyCode[KeyCode["Enter"] = 13] = "Enter";
  KeyCode[KeyCode["Pause"] = 19] = "Pause";
  KeyCode[KeyCode["CapsLock"] = 20] = "CapsLock";
  KeyCode[KeyCode["Esc"] = 27] = "Esc";
  KeyCode[KeyCode["Space"] = 32] = "Space";
  KeyCode[KeyCode["PageUp"] = 33] = "PageUp";
  KeyCode[KeyCode["PageDown"] = 34] = "PageDown";
  KeyCode[KeyCode["End"] = 35] = "End";
  KeyCode[KeyCode["Home"] = 36] = "Home";
  KeyCode[KeyCode["Left"] = 37] = "Left";
  KeyCode[KeyCode["Up"] = 38] = "Up";
  KeyCode[KeyCode["Right"] = 39] = "Right";
  KeyCode[KeyCode["Down"] = 40] = "Down";
  KeyCode[KeyCode["Insert"] = 45] = "Insert";
  KeyCode[KeyCode["Delete"] = 46] = "Delete";
  KeyCode[KeyCode["Key_0"] = 48] = "Key_0";
  KeyCode[KeyCode["Key_1"] = 49] = "Key_1";
  KeyCode[KeyCode["Key_2"] = 50] = "Key_2";
  KeyCode[KeyCode["Key_3"] = 51] = "Key_3";
  KeyCode[KeyCode["Key_4"] = 52] = "Key_4";
  KeyCode[KeyCode["Key_5"] = 53] = "Key_5";
  KeyCode[KeyCode["Key_6"] = 54] = "Key_6";
  KeyCode[KeyCode["Key_7"] = 55] = "Key_7";
  KeyCode[KeyCode["Key_8"] = 56] = "Key_8";
  KeyCode[KeyCode["Key_9"] = 57] = "Key_9";
  KeyCode[KeyCode["Key_a"] = 65] = "Key_a";
  KeyCode[KeyCode["Key_b"] = 66] = "Key_b";
  KeyCode[KeyCode["Key_c"] = 67] = "Key_c";
  KeyCode[KeyCode["Key_d"] = 68] = "Key_d";
  KeyCode[KeyCode["Key_e"] = 69] = "Key_e";
  KeyCode[KeyCode["Key_f"] = 70] = "Key_f";
  KeyCode[KeyCode["Key_g"] = 71] = "Key_g";
  KeyCode[KeyCode["Key_h"] = 72] = "Key_h";
  KeyCode[KeyCode["Key_i"] = 73] = "Key_i";
  KeyCode[KeyCode["Key_j"] = 74] = "Key_j";
  KeyCode[KeyCode["Key_k"] = 75] = "Key_k";
  KeyCode[KeyCode["Key_l"] = 76] = "Key_l";
  KeyCode[KeyCode["Key_m"] = 77] = "Key_m";
  KeyCode[KeyCode["Key_n"] = 78] = "Key_n";
  KeyCode[KeyCode["Key_o"] = 79] = "Key_o";
  KeyCode[KeyCode["Key_p"] = 80] = "Key_p";
  KeyCode[KeyCode["Key_q"] = 81] = "Key_q";
  KeyCode[KeyCode["Key_r"] = 82] = "Key_r";
  KeyCode[KeyCode["Key_s"] = 83] = "Key_s";
  KeyCode[KeyCode["Key_t"] = 84] = "Key_t";
  KeyCode[KeyCode["Key_u"] = 85] = "Key_u";
  KeyCode[KeyCode["Key_v"] = 86] = "Key_v";
  KeyCode[KeyCode["Key_w"] = 87] = "Key_w";
  KeyCode[KeyCode["Key_x"] = 88] = "Key_x";
  KeyCode[KeyCode["Key_y"] = 89] = "Key_y";
  KeyCode[KeyCode["Key_z"] = 90] = "Key_z";
  KeyCode[KeyCode["Windows"] = 91] = "Windows";
  KeyCode[KeyCode["ContextMenu"] = 93] = "ContextMenu";
  KeyCode[KeyCode["Numpad_0"] = 96] = "Numpad_0";
  KeyCode[KeyCode["Numpad_1"] = 97] = "Numpad_1";
  KeyCode[KeyCode["Numpad_2"] = 98] = "Numpad_2";
  KeyCode[KeyCode["Numpad_3"] = 99] = "Numpad_3";
  KeyCode[KeyCode["Numpad_4"] = 100] = "Numpad_4";
  KeyCode[KeyCode["Numpad_5"] = 101] = "Numpad_5";
  KeyCode[KeyCode["Numpad_6"] = 102] = "Numpad_6";
  KeyCode[KeyCode["Numpad_7"] = 103] = "Numpad_7";
  KeyCode[KeyCode["Numpad_8"] = 104] = "Numpad_8";
  KeyCode[KeyCode["Numpad_9"] = 105] = "Numpad_9";
  KeyCode[KeyCode["Multiply"] = 106] = "Multiply";
  KeyCode[KeyCode["Add"] = 107] = "Add";
  KeyCode[KeyCode["Subtract"] = 109] = "Subtract";
  KeyCode[KeyCode["Decimal"] = 110] = "Decimal";
  KeyCode[KeyCode["Divide"] = 111] = "Divide";
  KeyCode[KeyCode["F1"] = 112] = "F1";
  KeyCode[KeyCode["F2"] = 113] = "F2";
  KeyCode[KeyCode["F3"] = 114] = "F3";
  KeyCode[KeyCode["F4"] = 115] = "F4";
  KeyCode[KeyCode["F5"] = 116] = "F5";
  KeyCode[KeyCode["F6"] = 117] = "F6";
  KeyCode[KeyCode["F7"] = 118] = "F7";
  KeyCode[KeyCode["F8"] = 119] = "F8";
  KeyCode[KeyCode["F9"] = 120] = "F9";
  KeyCode[KeyCode["F10"] = 121] = "F10";
  KeyCode[KeyCode["F11"] = 122] = "F11";
  KeyCode[KeyCode["F12"] = 123] = "F12";
  KeyCode[KeyCode["NumLock"] = 144] = "NumLock";
  KeyCode[KeyCode["ScrollLock"] = 145] = "ScrollLock";
  KeyCode[KeyCode["Semicolon"] = 186] = "Semicolon";
  KeyCode[KeyCode["Equals"] = 187] = "Equals";
  KeyCode[KeyCode["Comma"] = 188] = "Comma";
  KeyCode[KeyCode["Dash"] = 189] = "Dash";
  KeyCode[KeyCode["Period"] = 190] = "Period";
  KeyCode[KeyCode["ForwardSlash"] = 191] = "ForwardSlash";
  KeyCode[KeyCode["GraveAccent"] = 192] = "GraveAccent";
  KeyCode[KeyCode["OpenBracket"] = 219] = "OpenBracket";
  KeyCode[KeyCode["BackSlash"] = 220] = "BackSlash";
  KeyCode[KeyCode["CloseBracket"] = 221] = "CloseBracket";
  KeyCode[KeyCode["SingleQuote"] = 222] = "SingleQuote";
})(KeyCode = exports.KeyCode || (exports.KeyCode = {}));