import { __awaiter } from "tslib";
import { isDefined } from "@devexpress/utils/lib/utils/common";
import { LeafElementDestination } from "../destination";
export class NoteReferenceDestination extends LeafElementDestination {
  processCustomNoteRun(subDocId) {
    this.data.subDocumentInfo.characterImporter.noteDocumentId = subDocId;
  }
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      const id = reader.getAttributeNS("id", this.data.constants.wordProcessingNamespaceConst);
      let subDocId = this.notes[id];
      if (!isDefined(subDocId)) return;
      const customMarkFollows = this.data.readerHelper.getWpSTOnOffValue(reader, "customMarkFollows", false);
      if (customMarkFollows) this.processCustomNoteRun(subDocId);else this.insertNoteRun(subDocId);
    });
  }
}