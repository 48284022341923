import { CommandBase } from '../../common/commands/command-base';
import { SimpleCommandState } from '../../common/commands/command-states';
import { DocumentInfo } from '../../common/rich-edit-core';
import { ModelCreator } from '../../common/model/creator/creator';
import { FontCorrector } from '../../common/model/creator/font-corrector';
import { ModelCreatorOptions } from '../../common/model/creator/options';
import { ControlOptions, DocumentCapability } from '../../common/model/options/control';
export class NewDocumentCommand extends CommandBase {
  getState() {
    var state = new SimpleCommandState(this.isEnabled());
    state.visible = this.control.modelManager.richOptions.control.createNew !== DocumentCapability.Hidden;
    return state;
  }
  executeCore(_state, _options) {
    if (this.control.getModifiedState() && !this.control.owner.confirmOnLosingChanges()) return false;
    NewDocumentCommand.newDocumentInner.call(this);
    this.control.owner.raiseDocumentLoaded();
    return true;
  }
  static newDocumentInner() {
    var _a;
    this.control.closeDocument();
    this.control.loadingPanelManager.loadingPanel.setVisible(true);
    const documentInfo = new DocumentInfo((_a = this.control.documentInfo) === null || _a === void 0 ? void 0 : _a.fileName, false);
    this.control.initialize("", documentInfo, 1, null);
    const options = new ModelCreatorOptions();
    new ModelCreator(options).setModel(this.control.modelManager.model).fillModel();
    new FontCorrector(this.control.modelManager.modelManipulator, this.control.modelManager.model, this.control.modelManager.richOptions.fonts).correct();
    this.control.selection.beginUpdate();
    const selectionUpdated = this.control.selection.changeState(newState => newState.setPosition(0).resetKeepX().setEndOfLine(false).setPageIndex(-1).setSubDocument(this.control.modelManager.model.mainSubDocument));
    this.control.modelManager.modelManipulator.documentProtectionProperties.filterRangePermissions();
    this.control.layoutFormatterManager.openDocument();
    this.control.layout.pageColor = this.control.modelManager.model.getActualPageBackgroundColor();
    this.control.layoutFormatterManager.forceFormatPage(0);
    this.control.inputPosition.reset();
    this.control.horizontalRulerControl.setEnable(true);
    this.control.selection.endUpdate();
    if (!selectionUpdated) this.control.selection.raiseSelectionChanged();
    this.control.layoutFormatterManager.runFormattingAsync();
    this.control.loadingPanelManager.loadingPanel.setVisible(false);
    this.control.barHolder.forceUpdate();
  }
  isEnabled() {
    return super.isEnabled() && ControlOptions.isEnabled(this.control.modelManager.richOptions.control.createNew);
  }
  isEnabledInClosedDocument() {
    return true;
  }
  isEnabledInReadOnlyMode() {
    return true;
  }
}