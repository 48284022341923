import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { ConfigService } from '../../services/config.service';
import { confirm } from 'devextreme/ui/dialog';

export interface ekRechnerFormData {
  hhaId: number;
  modus: string;
  hhaPersonen: any[];
  ekBerechnung: ekBerechnung;
}

export interface ekBerechnung {
  id: number;
  datumBerechnung: Date;
  datumNachweis?: Date | null;
  rechtsgrundlage: number;
  bemerkung: string;
  ekPersonId_1: number;
  ekPersonId_2: number;
  ekPersonId_3: number;
  ekDetails?: ekPosition[];
  betragJahr?: number;
  betragMonat?: number;
}

export interface ekPosition {
  id: number;
  einkommenstyp: string;
  einkommensartId: number;
  frequenz: number;
  betrag_1: number;
  betrag_2: number;
  betrag_3: number;
}

export interface ekPerson {
  index: number;
  hhaAngehoerigerId: number;
  name: string;
}

@Component({
    selector: 'app-einkommensrechner',
    templateUrl: './einkommensrechner.component.html',
    styleUrls: ['./einkommensrechner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EinkommensrechnerComponent {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('form', { static: false }) form: DxFormComponent;

  @Input() formData: ekRechnerFormData;
  @Output() onClose: EventEmitter<ekRechnerFormData> = new EventEmitter(null);

  titel: string = '';
  isLoading: boolean = false;
  dsRechtsgrundlagen: any[] = this.configService.getEinkommenRechtsgrundlagen();

  dsEkPerioden: any[] = [
    { id: 1, name: 'jährlich' },
    { id: 2, name: 'monatlich' },
  ];

  dsEinnahmen: any[] = this.configService.getKatalog('EinkommensartKatalog').filter((x) => x.einkommenstyp === 'einnahme');
  dsAbzuege: any[] = this.configService.getKatalog('EinkommensartKatalog').filter((x) => x.einkommenstyp === 'abzug');

  ekEinnahmen: any[] = [];
  ekAbzuege: any[] = [];

  gesamtEinnahmen1: number = 0;
  gesamtAbzuege1: number = 0;
  gesamtEinnahmen2: number = 0;
  gesamtAbzuege2: number = 0;
  gesamtEinnahmen3: number = 0;
  gesamtAbzuege3: number = 0;

  selectedGrundlage: any;

  betragMonat: number = 0;
  betragJahr: number = 0;

  ekPerson_1: ekPerson = null;
  ekPerson_2: ekPerson = null;
  ekPerson_3: ekPerson = null;

  isDisabled: boolean = true;

  anzahlSpalten: number = 11;

  constructor(private configService: ConfigService) {
    this.showEkRechner = this.showEkRechner.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onChangeEinkommen = this.onChangeEinkommen.bind(this);
    this.einkommenBerechnen = this.einkommenBerechnen.bind(this);
    this.onChangePerson = this.onChangePerson.bind(this);
    this.onClickNeuePerson = this.onClickNeuePerson.bind(this);
    this.onChangeValuePerson = this.onChangeValuePerson.bind(this);
  }

  showEkRechner() {
    this.ekPerson_1 = this.formData.hhaPersonen.find((x) => x.hhaAngehoerigerId === this.formData.ekBerechnung.ekPersonId_1);
    this.ekPerson_2 = this.formData.hhaPersonen.find((x) => x.hhaAngehoerigerId === this.formData.ekBerechnung.ekPersonId_2);
    this.ekPerson_3 = this.formData.hhaPersonen.find((x) => x.hhaAngehoerigerId === this.formData.ekBerechnung.ekPersonId_3);
    if (this.ekPerson_3 === undefined) {
      this.ekPerson_3 = null;
    }
    if (this.ekPerson_3 !== null) {
      this.anzahlSpalten = 13;
    } else {
      this.anzahlSpalten = 11;
    }

    this.popup.title =
      'Einkommensberechnung für ' +
      (this.ekPerson_1 !== null && this.ekPerson_1 !== undefined ? this.ekPerson_1.name : '') +
      (this.ekPerson_2 !== null && this.ekPerson_2 !== undefined ? ' und ' + this.ekPerson_2.name : '');
    this.fillForm();
    setTimeout(() => {
      if (this.form !== undefined) {
        // this.form.instance._refresh();
        this.form.instance.repaint();
      }
      this.popup.instance.show();
    }, 10);
  }

  onClickSave() {
    const validationResult = this.form.instance.validate();

    if (validationResult.isValid) {
      this.onClose.next(this.formData);
      this.popup.instance.hide();
    }
  }

  onClickClose() {
    this.popup.instance.hide();
  }

  onChangeEinkommen(e) {
    this.einkommenBerechnen();
  }

  onChangeEinkommensArt(e) {
    this.refreshDsEinkommensArten();
    this.einkommenBerechnen();
  }

  private refreshDsEinkommensArten() {
    this.dsEinnahmen.forEach((item) => {
      item.disabled = this.formData.ekBerechnung.ekDetails.find((x) => x.einkommensartId === item.id) !== undefined;
    });
    this.dsAbzuege.forEach((item) => {
      item.disabled = this.formData.ekBerechnung.ekDetails.find((x) => x.einkommensartId === item.id) !== undefined;
    });
  }

  private einkommenBerechnen() {
    let einnahmen1 = 0;
    let abzuege1 = 0;
    let einnahmen2 = 0;
    let abzuege2 = 0;
    let einnahmen3 = 0;
    let abzuege3 = 0;
    let gesamt = 0;
    if (
      this.formData.ekBerechnung.ekDetails !== null &&
      this.formData.ekBerechnung.ekDetails !== undefined &&
      this.formData.ekBerechnung.ekDetails.length > 0
    ) {
      // ek_berechnung.istBerechnet = true;
      for (let x = 0; x < this.formData.ekBerechnung.ekDetails.length; x++) {
        let item = this.formData.ekBerechnung.ekDetails[x];

        if (item.einkommenstyp === 'einnahme' && item.einkommensartId > 0) {
          if (item.frequenz === 2) {
            einnahmen1 += item.betrag_1 * 12;
            einnahmen2 += item.betrag_2 * 12;
            einnahmen3 += item.betrag_3 * 12;
          } else {
            einnahmen1 += item.betrag_1;
            einnahmen2 += item.betrag_2;
            einnahmen3 += item.betrag_3;
          }
        } else if (item.einkommenstyp === 'abzug' && item.einkommensartId > 0) {
          if (item.frequenz === 2) {
            abzuege1 += item.betrag_1 * 12;
            abzuege2 += item.betrag_2 * 12;
            abzuege3 += item.betrag_3 * 12;
          } else {
            abzuege1 += item.betrag_1;
            abzuege2 += item.betrag_2;
            abzuege3 += item.betrag_3;
          }
        }
      }
    }
    this.gesamtEinnahmen1 = einnahmen1;
    this.gesamtEinnahmen2 = einnahmen2;
    this.gesamtEinnahmen3 = einnahmen3;
    this.gesamtAbzuege1 = abzuege1;
    this.gesamtAbzuege2 = abzuege2;
    this.gesamtAbzuege3 = abzuege3;
    gesamt = einnahmen1 + einnahmen2 + einnahmen3 - abzuege1 - abzuege2 - abzuege3;

    if (gesamt > 0) {
      this.formData.ekBerechnung.betragJahr = gesamt;
    } else {
      this.formData.ekBerechnung.betragJahr = 0;
    }
    this.formData.ekBerechnung.betragMonat = this.formData.ekBerechnung.betragJahr / 12;
    this.formData.ekBerechnung.betragMonat = Math.round(this.formData.ekBerechnung.betragMonat * 100) / 100;

    this.betragMonat = this.formData.ekBerechnung.betragMonat;
    this.betragJahr = this.formData.ekBerechnung.betragJahr;
  }

  onClickAddEinkommenDetails(e, typ) {
    this.addEinkommenDetails(typ);
  }

  addEinkommenDetails(typ) {
    let idNeu = -1;
    if (this.formData.ekBerechnung.ekDetails !== null && this.formData.ekBerechnung.ekDetails !== undefined) {
      for (let i = 0; i < this.formData.ekBerechnung.ekDetails.length; i++) {
        if (this.formData.ekBerechnung.ekDetails[i].id <= idNeu) {
          idNeu = this.formData.ekBerechnung.ekDetails[i].id - 1;
        }
      }
    }

    this.formData.ekBerechnung.ekDetails.push({
      id: idNeu,
      einkommenstyp: typ,
      einkommensartId: null,
      betrag_1: 0,
      betrag_2: 0,
      betrag_3: 0,
      frequenz: 1,
    });
    this.fillForm();
  }

  onClickRemoveEinkommenDetails(e, ek) {
    this.formData.ekBerechnung.ekDetails = this.formData.ekBerechnung.ekDetails.filter((x) => x.id !== ek.id);
    this.fillForm();
    this.einkommenBerechnen();
  }

  onClickHifsrechner(e, ekPosition) {}

  private fillForm() {
    if (this.formData.ekBerechnung.ekDetails !== null && this.formData.ekBerechnung.ekDetails !== undefined) {
      this.ekEinnahmen = this.formData.ekBerechnung.ekDetails.filter((x) => x.einkommenstyp === 'einnahme');
      this.ekAbzuege = this.formData.ekBerechnung.ekDetails.filter((x) => x.einkommenstyp === 'abzug');
    }
  }

  onClickNeuePerson(e) {
    if (this.ekPerson_3 === null) {
      this.ekPerson_3 = undefined;
      this.anzahlSpalten = 13;
    } else {
      this.ekPerson_3 = null;
      this.anzahlSpalten = 11;
      this.formData.ekBerechnung.ekPersonId_3 = null;
      this.betraegeLoeschen(3);
      this.einkommenBerechnen();
    }
  }

  onChangePerson(e, index) {
    if (index === 1) {
      this.ekPerson_1 = this.formData.hhaPersonen.find((x) => x.hhaAngehoerigerId === this.formData.ekBerechnung.ekPersonId_1);
    } else if (index === 2) {
      this.ekPerson_2 = this.formData.hhaPersonen.find((x) => x.hhaAngehoerigerId === this.formData.ekBerechnung.ekPersonId_2);
    } else if (index === 3) {
      this.ekPerson_3 = this.formData.hhaPersonen.find((x) => x.hhaAngehoerigerId === this.formData.ekBerechnung.ekPersonId_3);
    }
  }

  onChangeValuePerson(e, index) {
    if (e.value === null) {
      confirm('Alle Einkünfte und Abzüge für diese Person werden gelöscht. Fortrsetzen?', 'Bestätigen').then((resolve) => {
        if (resolve === true) {
          this.betraegeLoeschen(index);
          this.form.instance.repaint();
          this.einkommenBerechnen();
        } else {
          e.component.option('value', e.previousValue);
        }
      });
    }
  }

  private betraegeLoeschen(index) {
    this.formData.ekBerechnung.ekDetails.forEach((pos) => {
      if (index === 1) {
        pos.betrag_1 = null;
      } else if (index === 2) {
        pos.betrag_2 = null;
      } else if (index === 3) {
        pos.betrag_3 = null;
      }
    });
  }
}
