import { NumberingFormat } from "../numbering-lists/list-level-properties";
export var NotePosition;
(function (NotePosition) {
  NotePosition[NotePosition["BottomOfPage"] = 0] = "BottomOfPage";
  NotePosition[NotePosition["BelowText"] = 1] = "BelowText";
  NotePosition[NotePosition["EndOfDocument"] = 2] = "EndOfDocument";
  NotePosition[NotePosition["EndOfSection"] = 3] = "EndOfSection";
})(NotePosition || (NotePosition = {}));
export var NoteType;
(function (NoteType) {
  NoteType[NoteType["Separator"] = 0] = "Separator";
  NoteType[NoteType["ContinuationSeparator"] = 1] = "ContinuationSeparator";
  NoteType[NoteType["ContinuationNotice"] = 2] = "ContinuationNotice";
  NoteType[NoteType["Normal"] = 3] = "Normal";
})(NoteType || (NoteType = {}));
export var NoteNumberingRestartType;
(function (NoteNumberingRestartType) {
  NoteNumberingRestartType[NoteNumberingRestartType["Continuous"] = 0] = "Continuous";
  NoteNumberingRestartType[NoteNumberingRestartType["NewPage"] = 1] = "NewPage";
  NoteNumberingRestartType[NoteNumberingRestartType["NewSection"] = 2] = "NewSection";
})(NoteNumberingRestartType || (NoteNumberingRestartType = {}));
export class NoteProperties {
  static createDefault() {
    const result = new NoteProperties();
    result.position = NotePosition.BottomOfPage;
    result.numberingFormat = NumberingFormat.Decimal;
    result.numberingRestartType = NoteNumberingRestartType.Continuous;
    result.startingNumber = 1;
    result.columnCount = 0;
    return result;
  }
  copyFrom(obj) {
    this.position = obj.position;
    this.numberingFormat = obj.numberingFormat;
    this.numberingRestartType = obj.numberingRestartType;
    this.startingNumber = obj.startingNumber;
    this.columnCount = obj.columnCount;
  }
  clone() {
    const result = new NoteProperties();
    result.copyFrom(this);
    return result;
  }
  equals(other) {
    return this.position === other.position && this.numberingFormat === other.numberingFormat && this.numberingRestartType === other.numberingRestartType && this.startingNumber === other.startingNumber && this.columnCount === other.columnCount;
  }
}
export class NoteSeparators {
  constructor(model) {
    this.model = model;
  }
  get separator() {
    return this.model.subDocuments[this.separatorId];
  }
  get continuationSeparator() {
    return this.model.subDocuments[this.continuationSeparatorId];
  }
  get continuationNotice() {
    return this.model.subDocuments[this.continuationNoticeId];
  }
  setSeparator(sudDocId, type) {
    switch (type) {
      case NoteType.Separator:
        this.separatorId = sudDocId;
        break;
      case NoteType.ContinuationSeparator:
        this.continuationSeparatorId = sudDocId;
        break;
      case NoteType.ContinuationNotice:
        this.continuationNoticeId = sudDocId;
        break;
      default:
        throw Error("Invalid separator type.");
    }
  }
  getSeparator(type) {
    switch (type) {
      case NoteType.Separator:
        return this.separator;
      case NoteType.ContinuationSeparator:
        return this.continuationSeparator;
      case NoteType.ContinuationNotice:
        return this.continuationNotice;
      default:
        throw Error("Invalid separator type.");
    }
  }
  *getSeparatos() {
    if (this.separator) yield this.separator;
    if (this.continuationSeparator) yield this.continuationSeparator;
    if (this.continuationNotice) yield this.continuationNotice;
  }
}