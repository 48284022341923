<dx-popup #popup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="true" title="Wiedervorlagen" [title]="'Wiedervorlagen für: ' + vorgangString" width="1300"
    height="600">
    <div *dxTemplate="let data of 'content'">
        <dx-data-grid class="wiedervorlagen-datagrid" #wiedervorlagenDataGrid [dataSource]="dataSource" height="433px"
            (onSelectionChanged)="onSelectionChanged($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
            (onContentReady)="onContentReady($event)"  (onRowDblClick)="onClickEdit($event)">

            <dxo-remote-operations [filtering]="true" [paging]="true" [sorting]="true" [summary]="true"
                [grouping]="true" [groupPaging]="true">
            </dxo-remote-operations>

            <dxo-selection selectAllMode="page" mode="single"></dxo-selection>

            <dxo-paging [pageSize]="30"></dxo-paging>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>

            <dxo-search-panel [width]="300" [visible]="true">
            </dxo-search-panel>

            <dxi-column dataField="faelligDatum" caption="Fällig" [width]="140" dataType="date" format="dd.MM.yyyy"
                sortOrder="asc">
            </dxi-column>

            <dxi-column dataField="vorgang" caption="Vorgang"></dxi-column>
            <dxi-column dataField="betreff" caption="Betreff"></dxi-column>

            <dxi-column dataField="faelligMitarbeiterId" caption="Bearbeiter"
                [filterValue]="authService.getBenutzerId()">
                <dxo-lookup [dataSource]="configService.getBenutzer()" displayExpr="bezeichnung" valueExpr="id">
                </dxo-lookup>
            </dxi-column>

            <dxi-column [width]="110" dataField="status" caption="Erledigt-Status" dataType="boolean" trueText="Ja" falseText="Nein"
                [filterValue]="false">
            </dxi-column>

            <dxi-column dataField="erledigtDatum" caption="Erledigt" [width]="140" dataType="date" format="dd.MM.yyyy">
            </dxi-column>

            <dxi-column dataField="erstelltDatum" caption="Erstellt" [width]="140" dataType="date" format="dd.MM.yyyy">
            </dxi-column>

            <dxi-column dataField="erstelltMitarbeiterId" caption="Ersteller">
                <dxo-lookup [dataSource]="configService.getBenutzer()" displayExpr="bezeichnung" valueExpr="id">
                </dxo-lookup>
            </dxi-column>

            <dxi-column type="buttons" [width]="80" [allowReordering]="false" [allowResizing]="false"
                [allowHiding]="false" [fixed]="true" [showInColumnChooser]="false">
                <dxi-button hint="Bearbeiten" icon="edit" [onClick]="onClickEdit">
                </dxi-button>
                <dxi-button hint="Als erledigt markieren" icon="check" [onClick]="onClickErledigt" [visible]="isVisible">
                </dxi-button>
            </dxi-column>

        </dx-data-grid>
    </div>
    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Schließen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-popup-toolbar-item>
</dx-popup>