export var BorderLineStyleApi;
(function (BorderLineStyleApi) {
  BorderLineStyleApi[BorderLineStyleApi["Nil"] = -1] = "Nil";
  BorderLineStyleApi[BorderLineStyleApi["None"] = 0] = "None";
  BorderLineStyleApi[BorderLineStyleApi["Single"] = 1] = "Single";
  BorderLineStyleApi[BorderLineStyleApi["Thick"] = 2] = "Thick";
  BorderLineStyleApi[BorderLineStyleApi["Double"] = 3] = "Double";
  BorderLineStyleApi[BorderLineStyleApi["Dotted"] = 4] = "Dotted";
  BorderLineStyleApi[BorderLineStyleApi["Dashed"] = 5] = "Dashed";
  BorderLineStyleApi[BorderLineStyleApi["DotDash"] = 6] = "DotDash";
  BorderLineStyleApi[BorderLineStyleApi["DotDotDash"] = 7] = "DotDotDash";
  BorderLineStyleApi[BorderLineStyleApi["Triple"] = 8] = "Triple";
  BorderLineStyleApi[BorderLineStyleApi["ThinThickSmallGap"] = 9] = "ThinThickSmallGap";
  BorderLineStyleApi[BorderLineStyleApi["ThickThinSmallGap"] = 10] = "ThickThinSmallGap";
  BorderLineStyleApi[BorderLineStyleApi["ThinThickThinSmallGap"] = 11] = "ThinThickThinSmallGap";
  BorderLineStyleApi[BorderLineStyleApi["ThinThickMediumGap"] = 12] = "ThinThickMediumGap";
  BorderLineStyleApi[BorderLineStyleApi["ThickThinMediumGap"] = 13] = "ThickThinMediumGap";
  BorderLineStyleApi[BorderLineStyleApi["ThinThickThinMediumGap"] = 14] = "ThinThickThinMediumGap";
  BorderLineStyleApi[BorderLineStyleApi["ThinThickLargeGap"] = 15] = "ThinThickLargeGap";
  BorderLineStyleApi[BorderLineStyleApi["ThickThinLargeGap"] = 16] = "ThickThinLargeGap";
  BorderLineStyleApi[BorderLineStyleApi["ThinThickThinLargeGap"] = 17] = "ThinThickThinLargeGap";
  BorderLineStyleApi[BorderLineStyleApi["Wave"] = 18] = "Wave";
  BorderLineStyleApi[BorderLineStyleApi["DoubleWave"] = 19] = "DoubleWave";
  BorderLineStyleApi[BorderLineStyleApi["DashSmallGap"] = 20] = "DashSmallGap";
  BorderLineStyleApi[BorderLineStyleApi["DashDotStroked"] = 21] = "DashDotStroked";
  BorderLineStyleApi[BorderLineStyleApi["ThreeDEmboss"] = 22] = "ThreeDEmboss";
  BorderLineStyleApi[BorderLineStyleApi["ThreeDEngrave"] = 23] = "ThreeDEngrave";
  BorderLineStyleApi[BorderLineStyleApi["Outset"] = 24] = "Outset";
  BorderLineStyleApi[BorderLineStyleApi["Inset"] = 25] = "Inset";
  BorderLineStyleApi[BorderLineStyleApi["Apples"] = 26] = "Apples";
  BorderLineStyleApi[BorderLineStyleApi["ArchedScallops"] = 27] = "ArchedScallops";
  BorderLineStyleApi[BorderLineStyleApi["BabyPacifier"] = 28] = "BabyPacifier";
  BorderLineStyleApi[BorderLineStyleApi["BabyRattle"] = 29] = "BabyRattle";
  BorderLineStyleApi[BorderLineStyleApi["Balloons3Colors"] = 30] = "Balloons3Colors";
  BorderLineStyleApi[BorderLineStyleApi["BalloonsHotAir"] = 31] = "BalloonsHotAir";
  BorderLineStyleApi[BorderLineStyleApi["BasicBlackDashes"] = 32] = "BasicBlackDashes";
  BorderLineStyleApi[BorderLineStyleApi["BasicBlackDots"] = 33] = "BasicBlackDots";
  BorderLineStyleApi[BorderLineStyleApi["BasicBlackSquares"] = 34] = "BasicBlackSquares";
  BorderLineStyleApi[BorderLineStyleApi["BasicThinLines"] = 35] = "BasicThinLines";
  BorderLineStyleApi[BorderLineStyleApi["BasicWhiteDashes"] = 36] = "BasicWhiteDashes";
  BorderLineStyleApi[BorderLineStyleApi["BasicWhiteDots"] = 37] = "BasicWhiteDots";
  BorderLineStyleApi[BorderLineStyleApi["BasicWhiteSquares"] = 38] = "BasicWhiteSquares";
  BorderLineStyleApi[BorderLineStyleApi["BasicWideInline"] = 39] = "BasicWideInline";
  BorderLineStyleApi[BorderLineStyleApi["BasicWideMidline"] = 40] = "BasicWideMidline";
  BorderLineStyleApi[BorderLineStyleApi["BasicWideOutline"] = 41] = "BasicWideOutline";
  BorderLineStyleApi[BorderLineStyleApi["Bats"] = 42] = "Bats";
  BorderLineStyleApi[BorderLineStyleApi["Birds"] = 43] = "Birds";
  BorderLineStyleApi[BorderLineStyleApi["BirdsFlight"] = 44] = "BirdsFlight";
  BorderLineStyleApi[BorderLineStyleApi["Cabins"] = 45] = "Cabins";
  BorderLineStyleApi[BorderLineStyleApi["CakeSlice"] = 46] = "CakeSlice";
  BorderLineStyleApi[BorderLineStyleApi["CandyCorn"] = 47] = "CandyCorn";
  BorderLineStyleApi[BorderLineStyleApi["CelticKnotwork"] = 48] = "CelticKnotwork";
  BorderLineStyleApi[BorderLineStyleApi["CertificateBanner"] = 49] = "CertificateBanner";
  BorderLineStyleApi[BorderLineStyleApi["ChainLink"] = 50] = "ChainLink";
  BorderLineStyleApi[BorderLineStyleApi["ChampagneBottle"] = 51] = "ChampagneBottle";
  BorderLineStyleApi[BorderLineStyleApi["CheckedBarBlack"] = 52] = "CheckedBarBlack";
  BorderLineStyleApi[BorderLineStyleApi["CheckedBarColor"] = 53] = "CheckedBarColor";
  BorderLineStyleApi[BorderLineStyleApi["Checkered"] = 54] = "Checkered";
  BorderLineStyleApi[BorderLineStyleApi["ChristmasTree"] = 55] = "ChristmasTree";
  BorderLineStyleApi[BorderLineStyleApi["CirclesLines"] = 56] = "CirclesLines";
  BorderLineStyleApi[BorderLineStyleApi["CirclesRectangles"] = 57] = "CirclesRectangles";
  BorderLineStyleApi[BorderLineStyleApi["ClassicalWave"] = 58] = "ClassicalWave";
  BorderLineStyleApi[BorderLineStyleApi["Clocks"] = 59] = "Clocks";
  BorderLineStyleApi[BorderLineStyleApi["Compass"] = 60] = "Compass";
  BorderLineStyleApi[BorderLineStyleApi["Confetti"] = 61] = "Confetti";
  BorderLineStyleApi[BorderLineStyleApi["ConfettiGrays"] = 62] = "ConfettiGrays";
  BorderLineStyleApi[BorderLineStyleApi["ConfettiOutline"] = 63] = "ConfettiOutline";
  BorderLineStyleApi[BorderLineStyleApi["ConfettiStreamers"] = 64] = "ConfettiStreamers";
  BorderLineStyleApi[BorderLineStyleApi["ConfettiWhite"] = 65] = "ConfettiWhite";
  BorderLineStyleApi[BorderLineStyleApi["CornerTriangles"] = 66] = "CornerTriangles";
  BorderLineStyleApi[BorderLineStyleApi["CouponCutoutDashes"] = 67] = "CouponCutoutDashes";
  BorderLineStyleApi[BorderLineStyleApi["CouponCutoutDots"] = 68] = "CouponCutoutDots";
  BorderLineStyleApi[BorderLineStyleApi["CrazyMaze"] = 69] = "CrazyMaze";
  BorderLineStyleApi[BorderLineStyleApi["CreaturesButterfly"] = 70] = "CreaturesButterfly";
  BorderLineStyleApi[BorderLineStyleApi["CreaturesFish"] = 71] = "CreaturesFish";
  BorderLineStyleApi[BorderLineStyleApi["CreaturesInsects"] = 72] = "CreaturesInsects";
  BorderLineStyleApi[BorderLineStyleApi["CreaturesLadyBug"] = 73] = "CreaturesLadyBug";
  BorderLineStyleApi[BorderLineStyleApi["CrossStitch"] = 74] = "CrossStitch";
  BorderLineStyleApi[BorderLineStyleApi["Cup"] = 75] = "Cup";
  BorderLineStyleApi[BorderLineStyleApi["DecoArch"] = 76] = "DecoArch";
  BorderLineStyleApi[BorderLineStyleApi["DecoArchColor"] = 77] = "DecoArchColor";
  BorderLineStyleApi[BorderLineStyleApi["DecoBlocks"] = 78] = "DecoBlocks";
  BorderLineStyleApi[BorderLineStyleApi["DiamondsGray"] = 79] = "DiamondsGray";
  BorderLineStyleApi[BorderLineStyleApi["DoubleD"] = 80] = "DoubleD";
  BorderLineStyleApi[BorderLineStyleApi["DoubleDiamonds"] = 81] = "DoubleDiamonds";
  BorderLineStyleApi[BorderLineStyleApi["Earth1"] = 82] = "Earth1";
  BorderLineStyleApi[BorderLineStyleApi["Earth2"] = 83] = "Earth2";
  BorderLineStyleApi[BorderLineStyleApi["EclipsingSquares1"] = 84] = "EclipsingSquares1";
  BorderLineStyleApi[BorderLineStyleApi["EclipsingSquares2"] = 85] = "EclipsingSquares2";
  BorderLineStyleApi[BorderLineStyleApi["EggsBlack"] = 86] = "EggsBlack";
  BorderLineStyleApi[BorderLineStyleApi["Fans"] = 87] = "Fans";
  BorderLineStyleApi[BorderLineStyleApi["Film"] = 88] = "Film";
  BorderLineStyleApi[BorderLineStyleApi["Firecrackers"] = 89] = "Firecrackers";
  BorderLineStyleApi[BorderLineStyleApi["FlowersBlockPrint"] = 90] = "FlowersBlockPrint";
  BorderLineStyleApi[BorderLineStyleApi["FlowersDaisies"] = 91] = "FlowersDaisies";
  BorderLineStyleApi[BorderLineStyleApi["FlowersModern1"] = 92] = "FlowersModern1";
  BorderLineStyleApi[BorderLineStyleApi["FlowersModern2"] = 93] = "FlowersModern2";
  BorderLineStyleApi[BorderLineStyleApi["FlowersPansy"] = 94] = "FlowersPansy";
  BorderLineStyleApi[BorderLineStyleApi["FlowersRedRose"] = 95] = "FlowersRedRose";
  BorderLineStyleApi[BorderLineStyleApi["FlowersRoses"] = 96] = "FlowersRoses";
  BorderLineStyleApi[BorderLineStyleApi["FlowersTeacup"] = 97] = "FlowersTeacup";
  BorderLineStyleApi[BorderLineStyleApi["FlowersTiny"] = 98] = "FlowersTiny";
  BorderLineStyleApi[BorderLineStyleApi["Gems"] = 99] = "Gems";
  BorderLineStyleApi[BorderLineStyleApi["GingerbreadMan"] = 100] = "GingerbreadMan";
  BorderLineStyleApi[BorderLineStyleApi["Gradient"] = 101] = "Gradient";
  BorderLineStyleApi[BorderLineStyleApi["Handmade1"] = 102] = "Handmade1";
  BorderLineStyleApi[BorderLineStyleApi["Handmade2"] = 103] = "Handmade2";
  BorderLineStyleApi[BorderLineStyleApi["HeartBalloon"] = 104] = "HeartBalloon";
  BorderLineStyleApi[BorderLineStyleApi["HeartGray"] = 105] = "HeartGray";
  BorderLineStyleApi[BorderLineStyleApi["Hearts"] = 106] = "Hearts";
  BorderLineStyleApi[BorderLineStyleApi["HeebieJeebies"] = 107] = "HeebieJeebies";
  BorderLineStyleApi[BorderLineStyleApi["Holly"] = 108] = "Holly";
  BorderLineStyleApi[BorderLineStyleApi["HouseFunky"] = 109] = "HouseFunky";
  BorderLineStyleApi[BorderLineStyleApi["Hypnotic"] = 110] = "Hypnotic";
  BorderLineStyleApi[BorderLineStyleApi["IceCreamCones"] = 111] = "IceCreamCones";
  BorderLineStyleApi[BorderLineStyleApi["LightBulb"] = 112] = "LightBulb";
  BorderLineStyleApi[BorderLineStyleApi["Lightning1"] = 113] = "Lightning1";
  BorderLineStyleApi[BorderLineStyleApi["Lightning2"] = 114] = "Lightning2";
  BorderLineStyleApi[BorderLineStyleApi["MapleLeaf"] = 115] = "MapleLeaf";
  BorderLineStyleApi[BorderLineStyleApi["MapleMuffins"] = 116] = "MapleMuffins";
  BorderLineStyleApi[BorderLineStyleApi["MapPins"] = 117] = "MapPins";
  BorderLineStyleApi[BorderLineStyleApi["Marquee"] = 118] = "Marquee";
  BorderLineStyleApi[BorderLineStyleApi["MarqueeToothed"] = 119] = "MarqueeToothed";
  BorderLineStyleApi[BorderLineStyleApi["Moons"] = 120] = "Moons";
  BorderLineStyleApi[BorderLineStyleApi["Mosaic"] = 121] = "Mosaic";
  BorderLineStyleApi[BorderLineStyleApi["MusicNotes"] = 122] = "MusicNotes";
  BorderLineStyleApi[BorderLineStyleApi["Northwest"] = 123] = "Northwest";
  BorderLineStyleApi[BorderLineStyleApi["Ovals"] = 124] = "Ovals";
  BorderLineStyleApi[BorderLineStyleApi["Packages"] = 125] = "Packages";
  BorderLineStyleApi[BorderLineStyleApi["PalmsBlack"] = 126] = "PalmsBlack";
  BorderLineStyleApi[BorderLineStyleApi["PalmsColor"] = 127] = "PalmsColor";
  BorderLineStyleApi[BorderLineStyleApi["PaperClips"] = 128] = "PaperClips";
  BorderLineStyleApi[BorderLineStyleApi["Papyrus"] = 129] = "Papyrus";
  BorderLineStyleApi[BorderLineStyleApi["PartyFavor"] = 130] = "PartyFavor";
  BorderLineStyleApi[BorderLineStyleApi["PartyGlass"] = 131] = "PartyGlass";
  BorderLineStyleApi[BorderLineStyleApi["Pencils"] = 132] = "Pencils";
  BorderLineStyleApi[BorderLineStyleApi["People"] = 133] = "People";
  BorderLineStyleApi[BorderLineStyleApi["PeopleHats"] = 134] = "PeopleHats";
  BorderLineStyleApi[BorderLineStyleApi["PeopleWaving"] = 135] = "PeopleWaving";
  BorderLineStyleApi[BorderLineStyleApi["Poinsettias"] = 136] = "Poinsettias";
  BorderLineStyleApi[BorderLineStyleApi["PostageStamp"] = 137] = "PostageStamp";
  BorderLineStyleApi[BorderLineStyleApi["Pumpkin1"] = 138] = "Pumpkin1";
  BorderLineStyleApi[BorderLineStyleApi["PushPinNote1"] = 139] = "PushPinNote1";
  BorderLineStyleApi[BorderLineStyleApi["PushPinNote2"] = 140] = "PushPinNote2";
  BorderLineStyleApi[BorderLineStyleApi["Pyramids"] = 141] = "Pyramids";
  BorderLineStyleApi[BorderLineStyleApi["PyramidsAbove"] = 142] = "PyramidsAbove";
  BorderLineStyleApi[BorderLineStyleApi["Quadrants"] = 143] = "Quadrants";
  BorderLineStyleApi[BorderLineStyleApi["Rings"] = 144] = "Rings";
  BorderLineStyleApi[BorderLineStyleApi["Safari"] = 145] = "Safari";
  BorderLineStyleApi[BorderLineStyleApi["Sawtooth"] = 146] = "Sawtooth";
  BorderLineStyleApi[BorderLineStyleApi["SawtoothGray"] = 147] = "SawtoothGray";
  BorderLineStyleApi[BorderLineStyleApi["ScaredCat"] = 148] = "ScaredCat";
  BorderLineStyleApi[BorderLineStyleApi["Seattle"] = 149] = "Seattle";
  BorderLineStyleApi[BorderLineStyleApi["ShadowedSquares"] = 150] = "ShadowedSquares";
  BorderLineStyleApi[BorderLineStyleApi["SharksTeeth"] = 151] = "SharksTeeth";
  BorderLineStyleApi[BorderLineStyleApi["ShorebirdTracks"] = 152] = "ShorebirdTracks";
  BorderLineStyleApi[BorderLineStyleApi["Skyrocket"] = 153] = "Skyrocket";
  BorderLineStyleApi[BorderLineStyleApi["SnowflakeFancy"] = 154] = "SnowflakeFancy";
  BorderLineStyleApi[BorderLineStyleApi["Snowflakes"] = 155] = "Snowflakes";
  BorderLineStyleApi[BorderLineStyleApi["Sombrero"] = 156] = "Sombrero";
  BorderLineStyleApi[BorderLineStyleApi["Southwest"] = 157] = "Southwest";
  BorderLineStyleApi[BorderLineStyleApi["Stars"] = 158] = "Stars";
  BorderLineStyleApi[BorderLineStyleApi["Stars3d"] = 159] = "Stars3d";
  BorderLineStyleApi[BorderLineStyleApi["StarsBlack"] = 160] = "StarsBlack";
  BorderLineStyleApi[BorderLineStyleApi["StarsShadowed"] = 161] = "StarsShadowed";
  BorderLineStyleApi[BorderLineStyleApi["StarsTop"] = 162] = "StarsTop";
  BorderLineStyleApi[BorderLineStyleApi["Sun"] = 163] = "Sun";
  BorderLineStyleApi[BorderLineStyleApi["Swirligig"] = 164] = "Swirligig";
  BorderLineStyleApi[BorderLineStyleApi["TornPaper"] = 165] = "TornPaper";
  BorderLineStyleApi[BorderLineStyleApi["TornPaperBlack"] = 166] = "TornPaperBlack";
  BorderLineStyleApi[BorderLineStyleApi["Trees"] = 167] = "Trees";
  BorderLineStyleApi[BorderLineStyleApi["TriangleParty"] = 168] = "TriangleParty";
  BorderLineStyleApi[BorderLineStyleApi["Triangles"] = 169] = "Triangles";
  BorderLineStyleApi[BorderLineStyleApi["Tribal1"] = 170] = "Tribal1";
  BorderLineStyleApi[BorderLineStyleApi["Tribal2"] = 171] = "Tribal2";
  BorderLineStyleApi[BorderLineStyleApi["Tribal3"] = 172] = "Tribal3";
  BorderLineStyleApi[BorderLineStyleApi["Tribal4"] = 173] = "Tribal4";
  BorderLineStyleApi[BorderLineStyleApi["Tribal5"] = 174] = "Tribal5";
  BorderLineStyleApi[BorderLineStyleApi["Tribal6"] = 175] = "Tribal6";
  BorderLineStyleApi[BorderLineStyleApi["TwistedLines1"] = 176] = "TwistedLines1";
  BorderLineStyleApi[BorderLineStyleApi["TwistedLines2"] = 177] = "TwistedLines2";
  BorderLineStyleApi[BorderLineStyleApi["Vine"] = 178] = "Vine";
  BorderLineStyleApi[BorderLineStyleApi["Waveline"] = 179] = "Waveline";
  BorderLineStyleApi[BorderLineStyleApi["WeavingAngles"] = 180] = "WeavingAngles";
  BorderLineStyleApi[BorderLineStyleApi["WeavingBraid"] = 181] = "WeavingBraid";
  BorderLineStyleApi[BorderLineStyleApi["WeavingRibbon"] = 182] = "WeavingRibbon";
  BorderLineStyleApi[BorderLineStyleApi["WeavingStrips"] = 183] = "WeavingStrips";
  BorderLineStyleApi[BorderLineStyleApi["WhiteFlowers"] = 184] = "WhiteFlowers";
  BorderLineStyleApi[BorderLineStyleApi["Woodwork"] = 185] = "Woodwork";
  BorderLineStyleApi[BorderLineStyleApi["XIllusions"] = 186] = "XIllusions";
  BorderLineStyleApi[BorderLineStyleApi["ZanyTriangles"] = 187] = "ZanyTriangles";
  BorderLineStyleApi[BorderLineStyleApi["ZigZag"] = 188] = "ZigZag";
  BorderLineStyleApi[BorderLineStyleApi["ZigZagStitch"] = 189] = "ZigZagStitch";
})(BorderLineStyleApi || (BorderLineStyleApi = {}));
export var TableWidthTypeApi;
(function (TableWidthTypeApi) {
  TableWidthTypeApi[TableWidthTypeApi["Auto"] = 1] = "Auto";
  TableWidthTypeApi[TableWidthTypeApi["Percent"] = 2] = "Percent";
  TableWidthTypeApi[TableWidthTypeApi["Twips"] = 3] = "Twips";
})(TableWidthTypeApi || (TableWidthTypeApi = {}));
export var TableRowHeightTypeApi;
(function (TableRowHeightTypeApi) {
  TableRowHeightTypeApi[TableRowHeightTypeApi["Minimum"] = 0] = "Minimum";
  TableRowHeightTypeApi[TableRowHeightTypeApi["Auto"] = 1] = "Auto";
  TableRowHeightTypeApi[TableRowHeightTypeApi["Exact"] = 2] = "Exact";
})(TableRowHeightTypeApi || (TableRowHeightTypeApi = {}));
export var TableContentHorizontalAlignmentApi;
(function (TableContentHorizontalAlignmentApi) {
  TableContentHorizontalAlignmentApi[TableContentHorizontalAlignmentApi["Left"] = 0] = "Left";
  TableContentHorizontalAlignmentApi[TableContentHorizontalAlignmentApi["Right"] = 1] = "Right";
  TableContentHorizontalAlignmentApi[TableContentHorizontalAlignmentApi["Center"] = 2] = "Center";
})(TableContentHorizontalAlignmentApi || (TableContentHorizontalAlignmentApi = {}));
export var TableContentVerticalAlignmentApi;
(function (TableContentVerticalAlignmentApi) {
  TableContentVerticalAlignmentApi[TableContentVerticalAlignmentApi["Top"] = 0] = "Top";
  TableContentVerticalAlignmentApi[TableContentVerticalAlignmentApi["Center"] = 2] = "Center";
  TableContentVerticalAlignmentApi[TableContentVerticalAlignmentApi["Bottom"] = 3] = "Bottom";
})(TableContentVerticalAlignmentApi || (TableContentVerticalAlignmentApi = {}));