import { ModelChangeType } from '../../enums';
export class TableRowPropertyChangedSubDocumentChange {
  get subDocumentId() {
    return this.subDocument.id;
  }
  constructor(subDocument, property, newState) {
    this.subDocument = subDocument;
    this.property = property;
    this.newState = newState;
    this.type = ModelChangeType.TableRowPropertyChanged;
  }
}