export var JSONRunBaseProperty;
(function (JSONRunBaseProperty) {
  JSONRunBaseProperty[JSONRunBaseProperty["Type"] = 0] = "Type";
  JSONRunBaseProperty[JSONRunBaseProperty["Offset"] = 1] = "Offset";
  JSONRunBaseProperty[JSONRunBaseProperty["Length"] = 2] = "Length";
  JSONRunBaseProperty[JSONRunBaseProperty["MaskedCharacterPropertiesCacheIndex"] = 3] = "MaskedCharacterPropertiesCacheIndex";
  JSONRunBaseProperty[JSONRunBaseProperty["CharacterStyleIndex"] = 4] = "CharacterStyleIndex";
  JSONRunBaseProperty[JSONRunBaseProperty["MAX_VALUE"] = 5] = "MAX_VALUE";
})(JSONRunBaseProperty || (JSONRunBaseProperty = {}));
export var JSONInlinePictureRunProperty;
(function (JSONInlinePictureRunProperty) {
  JSONInlinePictureRunProperty[JSONInlinePictureRunProperty["Shape"] = 5] = "Shape";
  JSONInlinePictureRunProperty[JSONInlinePictureRunProperty["Size"] = 6] = "Size";
  JSONInlinePictureRunProperty[JSONInlinePictureRunProperty["PictureId"] = 7] = "PictureId";
  JSONInlinePictureRunProperty[JSONInlinePictureRunProperty["DrawingObjectProperties"] = 8] = "DrawingObjectProperties";
})(JSONInlinePictureRunProperty || (JSONInlinePictureRunProperty = {}));
export var JSONInlineTextBoxRunProperty;
(function (JSONInlineTextBoxRunProperty) {
  JSONInlineTextBoxRunProperty[JSONInlineTextBoxRunProperty["Shape"] = 5] = "Shape";
  JSONInlineTextBoxRunProperty[JSONInlineTextBoxRunProperty["Size"] = 6] = "Size";
  JSONInlineTextBoxRunProperty[JSONInlineTextBoxRunProperty["SubDocId"] = 7] = "SubDocId";
  JSONInlineTextBoxRunProperty[JSONInlineTextBoxRunProperty["TextBoxProperties"] = 8] = "TextBoxProperties";
  JSONInlineTextBoxRunProperty[JSONInlineTextBoxRunProperty["DrawingObjectProperties"] = 9] = "DrawingObjectProperties";
})(JSONInlineTextBoxRunProperty || (JSONInlineTextBoxRunProperty = {}));
export var JSONAnchoredPictureRunProperty;
(function (JSONAnchoredPictureRunProperty) {
  JSONAnchoredPictureRunProperty[JSONAnchoredPictureRunProperty["Shape"] = 5] = "Shape";
  JSONAnchoredPictureRunProperty[JSONAnchoredPictureRunProperty["Size"] = 6] = "Size";
  JSONAnchoredPictureRunProperty[JSONAnchoredPictureRunProperty["PictureId"] = 7] = "PictureId";
  JSONAnchoredPictureRunProperty[JSONAnchoredPictureRunProperty["AnchorInfo"] = 8] = "AnchorInfo";
  JSONAnchoredPictureRunProperty[JSONAnchoredPictureRunProperty["DrawingObjectProperties"] = 9] = "DrawingObjectProperties";
})(JSONAnchoredPictureRunProperty || (JSONAnchoredPictureRunProperty = {}));
export var JSONAnchoredTextBoxRunProperty;
(function (JSONAnchoredTextBoxRunProperty) {
  JSONAnchoredTextBoxRunProperty[JSONAnchoredTextBoxRunProperty["Shape"] = 5] = "Shape";
  JSONAnchoredTextBoxRunProperty[JSONAnchoredTextBoxRunProperty["Size"] = 6] = "Size";
  JSONAnchoredTextBoxRunProperty[JSONAnchoredTextBoxRunProperty["SubDocId"] = 7] = "SubDocId";
  JSONAnchoredTextBoxRunProperty[JSONAnchoredTextBoxRunProperty["AnchorInfo"] = 8] = "AnchorInfo";
  JSONAnchoredTextBoxRunProperty[JSONAnchoredTextBoxRunProperty["TextBoxProperties"] = 9] = "TextBoxProperties";
  JSONAnchoredTextBoxRunProperty[JSONAnchoredTextBoxRunProperty["DrawingObjectProperties"] = 10] = "DrawingObjectProperties";
})(JSONAnchoredTextBoxRunProperty || (JSONAnchoredTextBoxRunProperty = {}));
export var JSONDeleteRunsCommandProperty;
(function (JSONDeleteRunsCommandProperty) {
  JSONDeleteRunsCommandProperty[JSONDeleteRunsCommandProperty["Position"] = 0] = "Position";
  JSONDeleteRunsCommandProperty[JSONDeleteRunsCommandProperty["Length"] = 1] = "Length";
})(JSONDeleteRunsCommandProperty || (JSONDeleteRunsCommandProperty = {}));