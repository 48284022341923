import { LogSource } from './log-source';
export class LogSourceParams {
  constructor(enabled, logSource) {
    this.enabled = enabled;
    this.logSource = logSource;
    this.name = LogSource[logSource];
  }
  getName() {
    return this.name;
  }
  isEnabled(_functionInfo) {
    return this.enabled;
  }
  setEnabled(isEnable) {
    this.enabled = isEnable;
  }
  handle(_functionInfo, _valOrFunc) {
    return false;
  }
}