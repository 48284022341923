import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  whiteList: string[] = ['https://feiertage-api.de'];

  constructor(private storageService: StorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let whiteListed: boolean = false;
    for (let index = 0; index < this.whiteList.length; index++) {
      const element = this.whiteList[index];
      if (req.url.startsWith(element)) {
        whiteListed = true;
        break;
      }
    }

    if (whiteListed === false) {
      const token = this.storageService.getFromSession<string>('token');
      if (token) {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
      }
    }

    return next.handle(req);
  }
}

export const authInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
