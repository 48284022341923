import { HtmlTagImporterBase } from './base';
export class HtmlCiteTagImporter extends HtmlTagImporterBase {
  elementTag() {
    return "CITE";
  }
  importBefore() {}
  isImportChildren() {
    return true;
  }
  importAfter() {}
}