import DataSource from 'devextreme/data/data_source';
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { DxAutocompleteComponent } from 'devextreme-angular/ui/autocomplete';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-autocompletebox-osm',
  templateUrl: './autocompletebox-osm.component.html',
  styleUrls: ['./autocompletebox-osm.component.scss'],
  standalone: false,
})
export class AutocompleteboxOsmComponent {
  @ViewChild('ac', { static: false }) ac: DxAutocompleteComponent;

  @Input() valueType: string = 'address';
  @Input() placeholder: string;
  @Input() searchTimeout: number = 500;
  @Input() minSearchLength: number = 3;
  @Input() centerLat: number;
  @Input() centerLng: number;
  @Input() whiteListType: string = 'all_address';
  @Input() value: any;
  @Input() readOnly: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() validationMessage: string = '';
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  autoCompleteDataSource: DataSource;

  private whiteLists: any = {
    private_address: {
      whiteList_countrycode: ['de'],
      whiteList_key: ['highway', 'place', 'building'],
      whiteList_value: ['residential', 'house', 'unclassified', 'apartments', 'detached', 'yes', 'secondary', 'track', 'living_street'],
      whiteList_type: ['house', 'street'],
    },
    insttution_address: {
      whiteList_countrycode: ['de'],
      whiteList_key: ['highway', 'amenity', 'building'],
      whiteList_value: ['kindergarten', 'residential', 'house', 'unclassified', 'apartments', 'detached', 'yes', 'secondary', 'track'],
      whiteList_type: ['house', 'street'],
    },
    all_address: {
      whiteList_countrycode: ['de'],
      whiteList_key: ['highway', 'place', 'amenity', 'building'],
      whiteList_value: [
        'kindergarten',
        'residential',
        'house',
        'unclassified',
        'apartments',
        'detached',
        'yes',
        'secondary',
        'track',
        'office',
        'service',
        'path',
        'footway',
        'tertiary',
        'living_street',
        'school',
      ],
      whiteList_type: ['house', 'street'],
    },
  };

  constructor(private apiService: ApiService, public element: ElementRef) {
    this.getLatLonFromAddress = this.getLatLonFromAddress.bind(this);

    this.autoCompleteDataSource = new DataSource({
      key: 'place_id',
      load: (loadOptions) => {
        if (!loadOptions.searchValue) {
          return null;
        }

        const url = 'https://photon.komoot.io/api?q=' + loadOptions.searchValue + '&lang=de&lat=' + this.centerLat + '&lon=' + this.centerLng;

        return lastValueFrom(this.apiService.get<any>(url)).then((response) => {
          const results: any[] = [];

          response.features.forEach((element) => {
            const newResult = this.mapSearchResults(element);

            if (newResult !== undefined && newResult !== null) {
              const exist = results.find((x) => x.display_name === newResult.display_name);
              if (exist === undefined || exist === null) {
                results.push(newResult);
              }
            }
          });
          return results;
        });
      },
    });
  }

  getLatLonFromAddress(addr: string, cb: (addr: any) => void) {
    const url = 'https://photon.komoot.io/api?q=' + addr;

    this.apiService.get<any>(url).subscribe((response) => {
      if (response && response.features && response.features.length > 0) {
        const result = this.mapSearchResults(response.features[0]);
        if (result !== undefined && result !== null && cb !== undefined && cb !== null) {
          cb(result);
        }
      }
    });
  }

  mapSearchResults(item) {
    if (item == undefined || item == null) {
      return null;
    }
    if (
      item.properties.countrycode &&
      !this.whiteLists[this.whiteListType].whiteList_countrycode.includes(item.properties.countrycode.toLowerCase())
    ) {
      return null;
    }

    if (item.properties.type && !this.whiteLists[this.whiteListType].whiteList_type.includes(item.properties.type)) {
      return null;
    }

    if (item.properties.osm_key && !this.whiteLists[this.whiteListType].whiteList_key.includes(item.properties.osm_key)) {
      return null;
    }

    if (item.properties.osm_value && !this.whiteLists[this.whiteListType].whiteList_value.includes(item.properties.osm_value)) {
      return null;
    }

    let result: any = {
      place_id: item.properties.osm_id,
      display_name: '',
      lon: item.geometry.coordinates[0],
      lat: item.geometry.coordinates[1],
      org: item.properties,
      district:
        item.properties.district !== undefined &&
        item.properties.district !== null &&
        item.properties.district.length > 0 &&
        item.properties.district !== item.properties.city
          ? item.properties.district
          : '',
      locality:
        item.properties.locality !== undefined &&
        item.properties.locality !== null &&
        item.properties.locality.length > 0 &&
        item.properties.locality !== item.properties.city &&
        item.properties.locality !== item.properties.district
          ? item.properties.locality
          : '',
    };

    const street = item.properties.street !== undefined ? item.properties.street : null;
    const housenumberString = item.properties.housenumber !== undefined ? item.properties.housenumber : null;
    const postcode = item.properties.postcode !== undefined ? item.properties.postcode : null;
    const city = item.properties.city !== undefined ? item.properties.city : null;
    const state = item.properties.state !== undefined ? item.properties.state : null;
    const name = item.properties.name !== undefined ? item.properties.name : null;

    switch (item.properties.type) {
      case 'street':
        result.display_name = name;
        result.street = name;

        if (housenumberString !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }

          let housenumber = housenumberString.match(/\d+/g);
          if (housenumber !== undefined && housenumber !== null && housenumber.length > 0) {
            housenumber = housenumber[0];
          } else {
            housenumber = null;
          }

          let housenumberSupplement = housenumberString.match(/[a-zA-Z]+/g);
          if (housenumberSupplement !== undefined && housenumberSupplement !== null && housenumberSupplement.length > 0) {
            housenumberSupplement = housenumberSupplement[0];
          } else {
            housenumberSupplement = null;
          }

          result.display_name += housenumberString;
          result.housenumber = housenumber;
          result.supplement = housenumberSupplement;
        } else {
          result.housenumber = null;
        }

        if (postcode !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ', ';
          }
          result.display_name += postcode;
          result.postcode = postcode;
        } else {
          result.postcode = null;
        }

        if (city !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }
          result.display_name += city;
          result.city = city;

          if (result.district !== undefined && result.district !== null && result.district.length > 0) {
            result.display_name += ' - ' + result.district;
          }
        } else if (state !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }
          if (result.district !== undefined && result.district !== null && result.district.length > 0) {
            result.city = result.district;
            result.display_name += result.district;
            if (result.locality !== undefined && result.locality !== null && result.locality.length > 0) {
              result.display_name += ' - ' + result.locality;
              result.district = result.locality;
            } else {
              result.district = '';
            }
          } else {
            result.display_name += state;
            result.city = state;
          }
        } else {
          result.city = null;
        }

        break;
      case 'house':
        if (street !== null) {
          result.display_name = street;
          result.street = street;
        } else {
          result.street = null;
        }

        if (housenumberString !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }

          let housenumber = housenumberString.match(/\d+/g);
          if (housenumber !== undefined && housenumber !== null && housenumber.length > 0) {
            housenumber = housenumber[0];
          } else {
            housenumber = null;
          }

          let housenumberSupplement = housenumberString.match(/[a-zA-Z]+/g);
          if (housenumberSupplement !== undefined && housenumberSupplement !== null && housenumberSupplement.length > 0) {
            housenumberSupplement = housenumberSupplement[0];
          } else {
            housenumberSupplement = null;
          }

          result.display_name += housenumberString;
          result.housenumber = housenumber;
          result.supplement = housenumberSupplement;
        } else {
          result.housenumber = null;
        }

        if (postcode !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ', ';
          }
          result.display_name += postcode;
          result.postcode = postcode;
        } else {
          result.postcode = null;
        }

        if (city !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }
          result.display_name += city;
          result.city = city;

          if (result.district !== undefined && result.district !== null && result.district.length > 0) {
            result.display_name += ' - ' + result.district;
          }
        } else if (state !== null) {
          if (result.display_name.length > 0) {
            result.display_name += ' ';
          }
          result.display_name += state;
          result.city = state;

          if (result.district !== undefined && result.district !== null && result.district.length > 0) {
            result.display_name += ' - ' + result.district;
          }
        } else {
          result.city = null;
        }
        break;
      default:
        console.warn('Unbekannter Type: ' + item.properties.type, item);
        result = null;
        break;
    }

    return result;
  }

  onSelectionChanged(e) {
    if (e && e.selectedItem !== undefined && e.selectedItem !== null) {
      if (this.valueType === 'street') {
        setTimeout(() => {
          this.value = e.selectedItem.street;
          this.ac.instance.option('value', e.selectedItem.street);
        }, 10);
      }

      this.onSelected.next(e.selectedItem);
    }
  }

  onValueChange(e) {
    if (this.isRequired) {
      this.value = e.value;
      this.validate();
    }
    this.onChange.next(e);
  }

  validate(): any {
    if (this.value !== undefined && this.value !== null && this.value.length > 0) {
      this.ac.isValid = true;
      return {
        state: true,
      };
    } else {
      this.ac.isValid = false;
      return {
        state: false,
        message: this.validationMessage,
      };
    }
  }
}
