import { __awaiter } from "tslib";
import { LeafElementDestination } from '../destination';
export class SectionMarginsDestination extends LeafElementDestination {
  get secProps() {
    return this.data.sectionImporter.properties;
  }
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      this.secProps.margins.left = this.data.readerHelper.getWpSTIntegerValue(reader, 'left', this.secProps.margins.left);
      this.secProps.margins.right = this.data.readerHelper.getWpSTIntegerValue(reader, 'right', this.secProps.margins.right);
      this.secProps.margins.top = this.data.readerHelper.getWpSTIntegerValue(reader, 'top', this.secProps.margins.top);
      this.secProps.margins.bottom = this.data.readerHelper.getWpSTIntegerValue(reader, 'bottom', this.secProps.margins.bottom);
      this.secProps.headerOffset = this.data.readerHelper.getWpSTIntegerValue(reader, 'header', this.secProps.headerOffset);
      this.secProps.footerOffset = this.data.readerHelper.getWpSTIntegerValue(reader, 'footer', this.secProps.footerOffset);
    });
  }
}