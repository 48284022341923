export class BaseManipulator {
  get history() {
    return this.modelManipulator.modelManager.history;
  }
  get model() {
    return this.modelManipulator.modelManager.model;
  }
  constructor(manipulator) {
    this.modelManipulator = manipulator;
  }
}