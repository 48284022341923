"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PolygonalChain = void 0;
var list_1 = require("../utils/list");
var point_1 = require("./point");
var rectangle_1 = require("./rectangle");
var segment_1 = require("./segment");
var vector_1 = require("./vector");
var PolygonalChain = function () {
  function PolygonalChain(points) {
    this.points = [];
    this.points = points;
  }
  PolygonalChain.prototype.getSegment = function (edgeIndex) {
    return new segment_1.Segment(this.points[edgeIndex], this.points[edgeIndex + 1]);
  };
  Object.defineProperty(PolygonalChain.prototype, "bounds", {
    get: function () {
      var vertBounds = list_1.ListUtils.minMaxExtended(this.points, function (p) {
        return p.y;
      });
      var horBounds = list_1.ListUtils.minMaxExtended(this.points, function (p) {
        return p.x;
      });
      return new rectangle_1.Rectangle(horBounds.minValue, vertBounds.minValue, horBounds.maxValue - horBounds.minValue, vertBounds.maxValue - vertBounds.minValue);
    },
    enumerable: false,
    configurable: true
  });
  PolygonalChain.prototype.rotateAround = function (point, angle, rightSC, byClockwise) {
    if (rightSC === void 0) {
      rightSC = false;
    }
    if (byClockwise === void 0) {
      byClockwise = true;
    }
    var center = new point_1.Point(point.x, point.y);
    this.changeCoordinateCenterTo(center);
    var sinFi = Math.sin(angle);
    var cosFi = Math.cos(angle);
    var sign = (rightSC ? 1 : 0) ^ (byClockwise ? 1 : 0) ? -1 : 1;
    for (var _i = 0, _a = this.points; _i < _a.length; _i++) {
      var pt = _a[_i];
      var clone = pt.clone();
      pt.x = clone.x * cosFi + sign * clone.y * sinFi;
      pt.y = -sign * clone.x * sinFi + clone.y * cosFi;
    }
    this.changeCoordinateCenterTo(center.multiply(-1, -1));
    return this;
  };
  PolygonalChain.prototype.changeCoordinateCenterTo = function (p) {
    var offset = new point_1.Point(p.x, p.y).multiply(-1, -1);
    list_1.ListUtils.forEach(this.points, function (rp) {
      return rp.offsetByPoint(offset);
    });
    return this;
  };
  PolygonalChain.prototype.projection = function (axis) {
    return list_1.ListUtils.minMaxExtended(this.points, function (p) {
      return vector_1.Vector.scalarProduct(p, axis);
    });
  };
  return PolygonalChain;
}();
exports.PolygonalChain = PolygonalChain;