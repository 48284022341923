<dx-popup #popup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="false" title="Personen-Suche" width="1200px" height="600px">
    <div *dxTemplate="let data of 'content'">
        <!-- [selectedRowKeys]="selectedItem !== undefined && selectedItem !== null ? [selectedItem.id] : []" -->
        <dx-data-grid class="bgb-person-datagrid" #dataGrid [dataSource]="dataSource" height="433px" width="1160px"
            (onSelectionChanged)="onSelectionChanged($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
            (onContentReady)="onContentReady($event)" (onRowDblClick)="onClickOk($event)"
            [(focusedRowKey)]="focusedRowKey" [autoNavigateToFocusedRow]="true" (onRowPrepared)="onRowPrepared($event)">

            <dxo-remote-operations [filtering]="true" [paging]="true" [sorting]="true" [summary]="true"
                [grouping]="true" [groupPaging]="true">
            </dxo-remote-operations>

            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="bgbPersonSearchDataGrid1">
            </dxo-state-storing>

            <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

            <dxo-selection selectAllMode="page" mode="single"></dxo-selection>

            <dxo-paging [pageSize]="30"></dxo-paging>

            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>

            <dxo-search-panel [width]="300" [visible]="true">
            </dxo-search-panel>


            <dxi-column [width]="200" dataField="id" [visible]="false" [showInColumnChooser]="false"></dxi-column>
            <dxi-column [width]="200" dataField="nachname" caption="Nachname"></dxi-column>
            <dxi-column [width]="200" dataField="vorname" caption="Vorname"></dxi-column>
            <dxi-column [width]="120" dataField="geburtsdatum" caption="Geburtsdatum" dataType="date"
                format="dd.MM.yyyy">
            </dxi-column>

            <dxi-column *ngIf="contextData.selector !== 'kids'" [width]="200" [visible]="false" dataField="telefon1" caption="Telefon 1"></dxi-column>
            <dxi-column *ngIf="contextData.selector !== 'kids'" [width]="200" [visible]="false" dataField="telefon2" caption="Telefon 2"></dxi-column>
            <dxi-column *ngIf="contextData.selector !== 'kids'" [width]="200" [visible]="false" dataField="eMail" caption="Email"></dxi-column>

            <dxi-column *ngIf="contextData.selector === 'kids'" [width]="100" dataField="geschlechtId" caption="Geschlecht">
                <dxo-lookup [dataSource]="configService.getKatalog('GeschlechtKatalog')" displayExpr="bezeichnung"
                    valueExpr="id">
                </dxo-lookup>
            </dxi-column>
            <dxi-column *ngIf="contextData.selector !== 'kids'" [width]="200" dataField="anredeId" caption="Anrede">
                <dxo-lookup [dataSource]="configService.getKatalog('AnredeKatalog')" displayExpr="bezeichnung"
                    valueExpr="id">
                </dxo-lookup>
            </dxi-column>
            <dxi-column *ngIf="contextData.selector !== 'kids'" [width]="80" dataField="titelId" caption="Titel">
                <dxo-lookup [dataSource]="configService.getKatalog('TitelKatalog')" displayExpr="bezeichnung"
                    valueExpr="id">
                </dxo-lookup>
            </dxi-column>
            <dxi-column [width]="200" [visible]="false" dataField="adresse" caption="Adresse"></dxi-column>
            <dxi-column [width]="200" [visible]="false" dataField="staatsangehoerigkeitId"
                caption="Staatsangehörigkeit">
                <dxo-lookup [dataSource]="configService.getKatalog('StaatsangehoerigkeitKatalog')"
                    displayExpr="bezeichnung" valueExpr="id">
                </dxo-lookup>
            </dxi-column>

            <!-- <dxi-column type="buttons" [fixed]="true" [width]="70">
                <dxi-button hint="Bearbeiten" icon="edit" [onClick]="onClickEdit"></dxi-button>
            </dxi-column> -->

        </dx-data-grid>
    </div>



    <!-- ------------------ -->

    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{  disabled: isLoading || (selectedItem === undefined || selectedItem === null), text: 'Übernehmen', type: 'default', stylingMode: 'contained',  icon: 'check', onClick: onClickOk }">
    </dxi-popup-toolbar-item>
    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-popup-toolbar-item>
</dx-popup>

<app-columnChooser *ngIf="init" #columnChooser [dataGrid]="dataGrid"></app-columnChooser>
<app-bgb-person-edit-popup></app-bgb-person-edit-popup>