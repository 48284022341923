import { IsModified } from '../../common/model/json/enums/json-top-level-enums';
import { RichEditDocumentApi } from '../model-api/document';
import { SubDocumentCollectionClientApi } from './collections/sub-documents-collection';
export class RichEditDocumentClientApi extends RichEditDocumentApi {
  constructor(core) {
    super(core);
    this._core = core;
  }
  get modified() {
    return this._core.getModifiedState() == IsModified.True;
  }
  set modified(value) {
    if (!value) this._core.setModifiedFalse();
  }
  get subDocuments() {
    return new SubDocumentCollectionClientApi(this._core);
  }
  get bookmarks() {
    return this.subDocuments.main.bookmarks;
  }
  get images() {
    return this.subDocuments.main.images;
  }
  setDefaultCharacterProperties(characterProperties) {
    super.setDefaultCharacterProperties(characterProperties);
    this._core.inputPosition.reset();
    this._core.barHolder.updateItemsState();
  }
}