export class ShapeTypeCode {}
ShapeTypeCode.NotPrimitive = 0x00000000;
ShapeTypeCode.Rectangle = 0x00000001;
ShapeTypeCode.RoundRect = 0x00000002;
ShapeTypeCode.Ellipse = 0x00000003;
ShapeTypeCode.Diamond = 0x00000004;
ShapeTypeCode.IsocelesTriangle = 0x00000005;
ShapeTypeCode.RightTriangle = 0x00000006;
ShapeTypeCode.Parallelogram = 0x00000007;
ShapeTypeCode.Trapezoid = 0x00000008;
ShapeTypeCode.Hexagon = 0x00000009;
ShapeTypeCode.Octagon = 0x0000000A;
ShapeTypeCode.Plus = 0x0000000B;
ShapeTypeCode.Star = 0x0000000C;
ShapeTypeCode.Arrow = 0x0000000D;
ShapeTypeCode.ThickArrow = 0x0000000E;
ShapeTypeCode.HomePlate = 0x0000000F;
ShapeTypeCode.Cube = 0x00000010;
ShapeTypeCode.Balloon = 0x00000011;
ShapeTypeCode.Seal = 0x00000012;
ShapeTypeCode.Arc = 0x00000013;
ShapeTypeCode.Line = 0x00000014;
ShapeTypeCode.Plaque = 0x00000015;
ShapeTypeCode.Can = 0x00000016;
ShapeTypeCode.Donut = 0x00000017;
ShapeTypeCode.TextSimple = 0x00000018;
ShapeTypeCode.TextOctagon = 0x00000019;
ShapeTypeCode.TextHexagon = 0x0000001A;
ShapeTypeCode.TextCurve = 0x0000001B;
ShapeTypeCode.TextWave = 0x0000001C;
ShapeTypeCode.TextRing = 0x0000001D;
ShapeTypeCode.TextOnCurve = 0x0000001E;
ShapeTypeCode.TextOnRing = 0x0000001F;
ShapeTypeCode.StraightConnector1 = 0x00000020;
ShapeTypeCode.BentConnector2 = 0x00000021;
ShapeTypeCode.BentConnector3 = 0x00000022;
ShapeTypeCode.BentConnector4 = 0x00000023;
ShapeTypeCode.BentConnector5 = 0x00000024;
ShapeTypeCode.CurvedConnector2 = 0x00000025;
ShapeTypeCode.CurvedConnector3 = 0x00000026;
ShapeTypeCode.CurvedConnector4 = 0x00000027;
ShapeTypeCode.CurvedConnector5 = 0x00000028;
ShapeTypeCode.Callout1 = 0x00000029;
ShapeTypeCode.Callout2 = 0x0000002A;
ShapeTypeCode.Callout3 = 0x0000002B;
ShapeTypeCode.AccentCallout1 = 0x0000002C;
ShapeTypeCode.AccentCallout2 = 0x0000002D;
ShapeTypeCode.AccentCallout3 = 0x0000002E;
ShapeTypeCode.BorderCallout1 = 0x0000002F;
ShapeTypeCode.BorderCallout2 = 0x00000030;
ShapeTypeCode.BorderCallout3 = 0x00000031;
ShapeTypeCode.AccentBorderCallout1 = 0x00000032;
ShapeTypeCode.AccentBorderCallout2 = 0x00000033;
ShapeTypeCode.AccentBorderCallout3 = 0x00000034;
ShapeTypeCode.Ribbon = 0x00000035;
ShapeTypeCode.Ribbon2 = 0x00000036;
ShapeTypeCode.Chevron = 0x00000037;
ShapeTypeCode.Pentagon = 0x00000038;
ShapeTypeCode.NoSmoking = 0x00000039;
ShapeTypeCode.Seal8 = 0x0000003A;
ShapeTypeCode.Seal16 = 0x0000003B;
ShapeTypeCode.Seal32 = 0x0000003C;
ShapeTypeCode.WedgeRectCallout = 0x0000003D;
ShapeTypeCode.WedgeRRectCallout = 0x0000003E;
ShapeTypeCode.WedgeEllipseCallout = 0x0000003F;
ShapeTypeCode.Wave = 0x00000040;
ShapeTypeCode.FoldedCorner = 0x00000041;
ShapeTypeCode.LeftArrow = 0x00000042;
ShapeTypeCode.DownArrow = 0x00000043;
ShapeTypeCode.UpArrow = 0x00000044;
ShapeTypeCode.LeftRightArrow = 0x00000045;
ShapeTypeCode.UpDownArrow = 0x00000046;
ShapeTypeCode.IrregularSeal1 = 0x00000047;
ShapeTypeCode.IrregularSeal2 = 0x00000048;
ShapeTypeCode.LightningBolt = 0x00000049;
ShapeTypeCode.Heart = 0x0000004A;
ShapeTypeCode.PictureFrame = 0x0000004B;
ShapeTypeCode.QuadArrow = 0x0000004C;
ShapeTypeCode.LeftArrowCallout = 0x0000004D;
ShapeTypeCode.RightArrowCallout = 0x0000004E;
ShapeTypeCode.UpArrowCallout = 0x0000004F;
ShapeTypeCode.DownArrowCallout = 0x00000050;
ShapeTypeCode.LeftRightArrowCallout = 0x00000051;
ShapeTypeCode.UpDownArrowCallout = 0x00000052;
ShapeTypeCode.QuadArrowCallout = 0x00000053;
ShapeTypeCode.Bevel = 0x00000054;
ShapeTypeCode.LeftBracket = 0x00000055;
ShapeTypeCode.RightBracket = 0x00000056;
ShapeTypeCode.LeftBrace = 0x00000057;
ShapeTypeCode.RightBrace = 0x00000058;
ShapeTypeCode.LeftUpArrow = 0x00000059;
ShapeTypeCode.BentUpArrow = 0x0000005A;
ShapeTypeCode.BentArrow = 0x0000005B;
ShapeTypeCode.Seal24 = 0x0000005C;
ShapeTypeCode.StripedRightArrow = 0x0000005D;
ShapeTypeCode.NotchedRightArrow = 0x0000005E;
ShapeTypeCode.BlockArc = 0x0000005F;
ShapeTypeCode.SmileyFace = 0x00000060;
ShapeTypeCode.VerticalScroll = 0x00000061;
ShapeTypeCode.HorizontalScroll = 0x00000062;
ShapeTypeCode.CircularArrow = 0x00000063;
ShapeTypeCode.NotchedCircularArrow = 0x00000064;
ShapeTypeCode.UturnArrow = 0x00000065;
ShapeTypeCode.CurvedRightArrow = 0x00000066;
ShapeTypeCode.CurvedLeftArrow = 0x00000067;
ShapeTypeCode.CurvedUpArrow = 0x00000068;
ShapeTypeCode.CurvedDownArrow = 0x00000069;
ShapeTypeCode.CloudCallout = 0x0000006A;
ShapeTypeCode.EllipseRibbon = 0x0000006B;
ShapeTypeCode.EllipseRibbon2 = 0x0000006C;
ShapeTypeCode.FlowChartProcess = 0x0000006D;
ShapeTypeCode.FlowChartDecision = 0x0000006E;
ShapeTypeCode.FlowChartInputOutput = 0x0000006F;
ShapeTypeCode.FlowChartPredefinedProcess = 0x00000070;
ShapeTypeCode.FlowChartInternalStorage = 0x00000071;
ShapeTypeCode.FlowChartDocument = 0x00000072;
ShapeTypeCode.FlowChartMultidocument = 0x00000073;
ShapeTypeCode.FlowChartTerminator = 0x00000074;
ShapeTypeCode.FlowChartPreparation = 0x00000075;
ShapeTypeCode.FlowChartManualInput = 0x00000076;
ShapeTypeCode.FlowChartManualOperation = 0x00000077;
ShapeTypeCode.FlowChartConnector = 0x00000078;
ShapeTypeCode.FlowChartPunchedCard = 0x00000079;
ShapeTypeCode.FlowChartPunchedTape = 0x0000007A;
ShapeTypeCode.FlowChartSummingJunction = 0x0000007B;
ShapeTypeCode.FlowChartOr = 0x0000007C;
ShapeTypeCode.FlowChartCollate = 0x0000007D;
ShapeTypeCode.FlowChartSort = 0x0000007E;
ShapeTypeCode.FlowChartExtract = 0x0000007F;
ShapeTypeCode.FlowChartMerge = 0x00000080;
ShapeTypeCode.FlowChartOfflineStorage = 0x00000081;
ShapeTypeCode.FlowChartOnlineStorage = 0x00000082;
ShapeTypeCode.FlowChartMagneticTape = 0x00000083;
ShapeTypeCode.FlowChartMagneticDisk = 0x00000084;
ShapeTypeCode.FlowChartMagneticDrum = 0x00000085;
ShapeTypeCode.FlowChartDisplay = 0x00000086;
ShapeTypeCode.FlowChartDelay = 0x00000087;
ShapeTypeCode.TextPlainText = 0x00000088;
ShapeTypeCode.TextStop = 0x00000089;
ShapeTypeCode.TextTriangle = 0x0000008A;
ShapeTypeCode.TextTriangleInverted = 0x0000008B;
ShapeTypeCode.TextChevron = 0x0000008C;
ShapeTypeCode.TextChevronInverted = 0x0000008D;
ShapeTypeCode.TextRingInside = 0x0000008E;
ShapeTypeCode.TextRingOutside = 0x0000008F;
ShapeTypeCode.TextArchUpCurve = 0x00000090;
ShapeTypeCode.TextArchDownCurve = 0x00000091;
ShapeTypeCode.TextCircleCurve = 0x00000092;
ShapeTypeCode.TextButtonCurve = 0x00000093;
ShapeTypeCode.TextArchUpPour = 0x00000094;
ShapeTypeCode.TextArchDownPour = 0x00000095;
ShapeTypeCode.TextCirclePour = 0x00000096;
ShapeTypeCode.TextButtonPour = 0x00000097;
ShapeTypeCode.TextCurveUp = 0x00000098;
ShapeTypeCode.TextCurveDown = 0x00000099;
ShapeTypeCode.TextCascadeUp = 0x0000009A;
ShapeTypeCode.TextCascadeDown = 0x0000009B;
ShapeTypeCode.TextWave1 = 0x0000009C;
ShapeTypeCode.TextWave2 = 0x0000009D;
ShapeTypeCode.TextWave3 = 0x0000009E;
ShapeTypeCode.TextWave4 = 0x0000009F;
ShapeTypeCode.TextInflate = 0x000000A0;
ShapeTypeCode.TextDeflate = 0x000000A1;
ShapeTypeCode.TextInflateBottom = 0x000000A2;
ShapeTypeCode.TextDeflateBottom = 0x000000A3;
ShapeTypeCode.TextInflateTop = 0x000000A4;
ShapeTypeCode.TextDeflateTop = 0x000000A5;
ShapeTypeCode.TextDeflateInflate = 0x000000A6;
ShapeTypeCode.TextDeflateInflateDeflate = 0x000000A7;
ShapeTypeCode.TextFadeRight = 0x000000A8;
ShapeTypeCode.TextFadeLeft = 0x000000A9;
ShapeTypeCode.TextFadeUp = 0x000000AA;
ShapeTypeCode.TextFadeDown = 0x000000AB;
ShapeTypeCode.TextSlantUp = 0x000000AC;
ShapeTypeCode.TextSlantDown = 0x000000AD;
ShapeTypeCode.TextCanUp = 0x000000AE;
ShapeTypeCode.TextCanDown = 0x000000AF;
ShapeTypeCode.FlowChartAlternateProcess = 0x000000B0;
ShapeTypeCode.FlowChartOffpageConnector = 0x000000B1;
ShapeTypeCode.Callout90 = 0x000000B2;
ShapeTypeCode.AccentCallout90 = 0x000000B3;
ShapeTypeCode.BorderCallout90 = 0x000000B4;
ShapeTypeCode.AccentBorderCallout90 = 0x000000B5;
ShapeTypeCode.LeftRightUpArrow = 0x000000B6;
ShapeTypeCode.Sun = 0x000000B7;
ShapeTypeCode.Moon = 0x000000B8;
ShapeTypeCode.BracketPair = 0x000000B9;
ShapeTypeCode.BracePair = 0x000000BA;
ShapeTypeCode.Seal4 = 0x000000BB;
ShapeTypeCode.DoubleWave = 0x000000BC;
ShapeTypeCode.ActionButtonBlank = 0x000000BD;
ShapeTypeCode.ActionButtonHome = 0x000000BE;
ShapeTypeCode.ActionButtonHelp = 0x000000BF;
ShapeTypeCode.ActionButtonInformation = 0x000000C0;
ShapeTypeCode.ActionButtonForwardNext = 0x000000C1;
ShapeTypeCode.ActionButtonBackPrevious = 0x000000C2;
ShapeTypeCode.ActionButtonEnd = 0x000000C3;
ShapeTypeCode.ActionButtonBeginning = 0x000000C4;
ShapeTypeCode.ActionButtonReturn = 0x000000C5;
ShapeTypeCode.ActionButtonDocument = 0x000000C6;
ShapeTypeCode.ActionButtonSound = 0x000000C7;
ShapeTypeCode.ActionButtonMovie = 0x000000C8;
ShapeTypeCode.HostControl = 0x000000C9;
ShapeTypeCode.Textbox = 0x000000CA;