import { Component, ViewChild, OnDestroy } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { ApiService } from 'src/app/share/services/api.service';
import { PopupService } from '../../services/popup.service';
import { Subscription } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { confirm } from 'devextreme/ui/dialog';
import { ConfigService } from '../../services/config.service';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-wiedervorlagenEditPopup',
  templateUrl: './wiedervorlagenEditPopup.component.html',
  styleUrls: ['./wiedervorlagenEditPopup.component.scss'],
  standalone: false,
})
export class WiedervorlagenEditPopupComponent implements OnDestroy {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('form', { static: false }) form: DxFormComponent;

  onClose: (e) => void;
  formData: any;
  subscription_get_PopupAsObservable: Subscription;
  canShowZumAntrag: boolean = true;
  isLoading: boolean = false;

  get isNew(): boolean {
    if (this.formData) {
      if (this.formData.wiedervorlagenId && this.formData.wiedervorlagenId > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  get canFinish(): boolean {
    if (this.formData) {
      if (this.formData.status === false) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  contextData: any;

  constructor(private apiService: ApiService, private popupService: PopupService, public configService: ConfigService, private router: Router) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickErledigt = this.onClickErledigt.bind(this);
    this.onClickUnerledigt = this.onClickUnerledigt.bind(this);
    this.onClickGeheZu = this.onClickGeheZu.bind(this);

    this.subscription_get_PopupAsObservable = this.popupService.get_wiedervorlageEditPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true) {
        this.canShowZumAntrag = context.data.canShowZumAntrag === false ? false : true;
        this.formData = null;
        this.onClose = context.closeCb;
        this.contextData = context.data;
        this.load();
        this.popup.instance.show();
      } else {
        this.contextData = null;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  load() {
    this.apiService.post(RouteKeys.Wiedervorlage.get, this.contextData).subscribe((response) => {
      this.formData = response;
    });
  }

  onClickGeheZu(e) {
    this.popup.instance.hide();
    if (this.onClose !== undefined && this.onClose !== null) {
      this.onClose(2);
    }
    this.router.navigate(['/depart/inquires/edit'], { queryParams: { ID: this.contextData.entityId } });
  }

  onClickSave(e) {
    var validationResult = this.form.instance.validate();
    if (validationResult.isValid) {
      this.isLoading = true;
      this.apiService.post(RouteKeys.Wiedervorlage.update, this.formData).subscribe({
        next: () => {
          this.isLoading = false;

          notify(
            {
              message: 'Erfolgreich gespeichert.',
            },
            'success'
          );

          if (this.onClose !== undefined && this.onClose !== null) {
            this.onClose(1);
          }
          this.popup.instance.hide();
        },
        error: (err) => {
          this.isLoading = false;

          notify(
            {
              message: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an Ihren Administrator!',
            },
            'error'
          );

          throw err;
        },
      });
    }
  }

  onClickErledigt(e) {
    let result = confirm('<i>Wollen Sie die Wiedervorlage wirklich schließen?</i>', 'Bestätigung');
    result.then((dialogResult) => {
      if (dialogResult) {
        this.isLoading = true;
        this.apiService.post(RouteKeys.Wiedervorlage.finish, { entityId: this.formData.wiedervorlagenId, erledigt: true }).subscribe({
          next: () => {
            this.isLoading = false;

            notify(
              {
                message: 'Erfolgreich gespeichert.',
              },
              'success'
            );

            if (this.onClose !== undefined && this.onClose !== null) {
              this.onClose(1);
            }
            this.popup.instance.hide();
          },
          error: (err) => {
            notify(
              {
                message: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an Ihren Administrator!',
              },
              'error'
            );

            this.isLoading = false;
            throw err;
          },
        });
      }
    });
  }

  onClickUnerledigt(e) {
    let result = confirm('<i>Wollen Sie die Wiedervorlage wirklich wieder öffnen?</i>', 'Bestätigung');
    result.then((dialogResult) => {
      if (dialogResult) {
        this.isLoading = true;
        this.apiService.post(RouteKeys.Wiedervorlage.finish, { entityId: this.formData.wiedervorlagenId, erledigt: false }).subscribe({
          next: () => {
            this.isLoading = false;
            if (this.onClose !== undefined && this.onClose !== null) {
              this.onClose(1);
            }
            this.popup.instance.hide();
          },
          error: (err) => {
            this.isLoading = false;
            throw err;
          },
        });
      }
    });
  }

  onClickClose(e) {
    if (this.onClose !== undefined && this.onClose !== null) {
      this.onClose(3);
    }
    this.popup.instance.hide();
  }

  getVorgang() {
    if (this.formData) {
      return ': ' + this.formData.vorgang;
    } else {
      return '';
    }
  }
}
