import { NumberingListIndexConstants } from '../../../../utils/numbering-list-index-constants';
import { MaskedCharacterProperties } from '../../../../../model/character/character-properties';
import { MaskedParagraphProperties } from '../../../../../model/paragraph/paragraph-properties';
import { ParagraphStyle, TabProperties } from '../../../../../model/paragraph/paragraph-style';
import { RtfBaseStyleImporter } from './base-style-importer';
import { StylesManager } from '../../../../../model/styles-manager';
export class RtfParagraphStyleImporter extends RtfBaseStyleImporter {
  addStyle(style) {
    const result = this.documentModel.stylesManager.addParagraphStyle(style);
    result.localizedName = StylesManager.getPresetParagraphStyleLocalizedName(style.styleName);
    return result;
  }
  ensureStyleExist() {
    this.rtfStyleIndex = Math.max(0, this.rtfStyleIndex);
    this.rtfParentStyleIndex = Math.max(0, this.rtfParentStyleIndex);
  }
  constructor(data) {
    super(data, false);
    this.paragraphTableStyles = {};
  }
  get styleCollection() {
    return this.data.documentModel.paragraphStyles;
  }
  createEmpty() {
    return new ParagraphStyle("", "", false, false, false, false, new MaskedCharacterProperties(), new MaskedParagraphProperties(), new TabProperties(), false, NumberingListIndexConstants.listIndexNotSetted, -1, "");
  }
}