import { FixedInterval } from '@devexpress/utils/lib/intervals/fixed';
import { ApplyFieldHyperlinkStyleHistoryItem } from '../../history/items/apply-field-hyperlink-style-history-item';
import { ChangeFieldHyperlinkInfoHistoryItem } from '../../history/items/change-field-hyperlink-info-history-item';
import { SubDocumentInterval } from '../../sub-document';
import { HyperlinkInfo } from '../field';
import { FieldName } from '../names';
import { FieldCodeParserState, FieldSwitchType } from './field-code-parser';
import { FieldCodeParserClientUpdatingBase } from './field-code-parser-client-updating-base';
import { UrlUtils } from '../../../utils/utils';
export class FieldCodeParserHyperlink extends FieldCodeParserClientUpdatingBase {
  get name() {
    return FieldName.Hyperlink;
  }
  parseCodeCurrentFieldInternal(_responce) {
    if (this.parseSwitchesAndArgs()) this.fillResult();else this.removeInterval(this.getTopField().getResultInterval());
    this.parserState = FieldCodeParserState.end;
    return true;
  }
  fillResult() {
    var _a, _b;
    const field = this.getTopField();
    const text = (_b = (_a = this.parameterInfoList[0]) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : "";
    const newHyperlinkInfo = this.updateHyperlinkInfo(text);
    if (!newHyperlinkInfo) {
      if (!this.modelManager.richOptions.fields.keepHyperlinkResultForInvalidReference) {
        this.removeInterval(this.getTopField().getResultInterval());
      }
      return true;
    }
    const modelManipulator = this.modelManager.modelManipulator;
    const resultInterval = field.getResultInterval();
    if (resultInterval.length === 0) {
      const resultText = text !== null && text !== void 0 ? text : "#" + newHyperlinkInfo.anchor;
      const newResultInterval = new FixedInterval(resultInterval.start, resultText.length);
      this.setInputPositionState();
      this.replaceTextByInterval(resultInterval, resultText);
      const subDocumentInterval = new SubDocumentInterval(this.subDocument, newResultInterval);
      const historyItem = new ApplyFieldHyperlinkStyleHistoryItem(modelManipulator, subDocumentInterval);
      this.modelManager.history.addAndRedo(historyItem);
    }
    const historyItem = new ChangeFieldHyperlinkInfoHistoryItem(modelManipulator, this.subDocument, field.index, newHyperlinkInfo);
    this.modelManager.history.addAndRedo(historyItem);
    return true;
  }
  updateHyperlinkInfo(text) {
    const newHyperlinkInfo = new HyperlinkInfo("", "", "", false);
    [newHyperlinkInfo.uri, newHyperlinkInfo.anchor] = UrlUtils.splitUrlByAnchor(text);
    newHyperlinkInfo.visited = false;
    for (const switchInfo of this.switchInfoList) {
      if (switchInfo.type != FieldSwitchType.FieldSpecific) continue;
      switch (switchInfo.name.toLocaleUpperCase()) {
        case "O":
          newHyperlinkInfo.tip = switchInfo.arg;
          break;
        case "L":
          newHyperlinkInfo.anchor = switchInfo.arg;
          break;
      }
    }
    return newHyperlinkInfo.isValid() ? newHyperlinkInfo : null;
  }
}