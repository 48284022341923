import { MaskedCharacterProperties } from '../../../../../model/character/character-properties';
import { FontInfo } from '../../../../../model/fonts/font-info';
import { ListLevelProperties } from '../../../../../model/numbering-lists/list-level-properties';
export class RtfOldListLevelInfo {
  clone() {
    const result = new RtfOldListLevelInfo();
    result.copyFrom(this);
    return result;
  }
  copyFrom(_obj) {
    this.skipNumbering = _obj.skipNumbering;
    this.includeInformationFromPreviousLevel = _obj.includeInformationFromPreviousLevel;
    this.characterProperties = _obj.characterProperties.clone();
    this.listLevelProperties = _obj.listLevelProperties.clone();
    this.textBefore = _obj.textBefore;
    this.textAfter = _obj.textAfter;
    this.indent = _obj.indent;
  }
  constructor() {
    this.characterProperties = new MaskedCharacterProperties();
    this.characterProperties.fontInfo = new FontInfo();
    this.characterProperties.fontSize = 12;
    this.characterProperties.setAllUse();
    this.listLevelProperties = new ListLevelProperties();
    this.textBefore = "";
    this.textAfter = "";
    this.indent = 0;
  }
}