export class RussianDecoder {
  static decode(str) {
    if (str == null) {
      return null;
    }
    const result = [];
    for (let i = 0; i < str.length; i++) {
      const ord = str.charCodeAt(i);
      const charCode = RussianDecoder.map[ord];
      if (charCode) result.push(String.fromCharCode(charCode));else result.push(String.fromCharCode(ord));
    }
    return result.join('');
  }
}
RussianDecoder.map = {
  128: 1026,
  129: 1027,
  130: 8218,
  131: 1107,
  132: 8222,
  133: 8230,
  134: 8224,
  135: 8225,
  136: 8364,
  137: 8240,
  138: 1033,
  139: 8249,
  140: 1034,
  141: 1036,
  142: 1035,
  143: 1039,
  144: 1106,
  145: 8216,
  146: 8217,
  147: 8220,
  148: 8221,
  149: 8226,
  150: 8211,
  151: 8212,
  153: 8482,
  154: 1113,
  155: 8250,
  156: 1114,
  157: 1116,
  158: 1115,
  159: 1119,
  160: 160,
  161: 1038,
  162: 1118,
  163: 1032,
  164: 164,
  165: 1168,
  166: 166,
  167: 167,
  168: 1025,
  169: 169,
  170: 1028,
  171: 171,
  172: 172,
  173: 173,
  174: 174,
  175: 1031,
  176: 176,
  177: 177,
  178: 1030,
  179: 1110,
  180: 1169,
  181: 181,
  182: 182,
  183: 183,
  184: 1105,
  185: 8470,
  186: 1108,
  187: 187,
  188: 1112,
  189: 1029,
  190: 1109,
  191: 1111,
  192: 1040,
  193: 1041,
  194: 1042,
  195: 1043,
  196: 1044,
  197: 1045,
  198: 1046,
  199: 1047,
  200: 1048,
  201: 1049,
  202: 1050,
  203: 1051,
  204: 1052,
  205: 1053,
  206: 1054,
  207: 1055,
  208: 1056,
  209: 1057,
  210: 1058,
  211: 1059,
  212: 1060,
  213: 1061,
  214: 1062,
  215: 1063,
  216: 1064,
  217: 1065,
  218: 1066,
  219: 1067,
  220: 1068,
  221: 1069,
  222: 1070,
  223: 1071,
  224: 1072,
  225: 1073,
  226: 1074,
  227: 1075,
  228: 1076,
  229: 1077,
  230: 1078,
  231: 1079,
  232: 1080,
  233: 1081,
  234: 1082,
  235: 1083,
  236: 1084,
  237: 1085,
  238: 1086,
  239: 1087,
  240: 1088,
  241: 1089,
  242: 1090,
  243: 1091,
  244: 1092,
  245: 1093,
  246: 1094,
  247: 1095,
  248: 1096,
  249: 1097,
  250: 1098,
  251: 1099,
  252: 1100,
  253: 1101,
  254: 1102,
  255: 1103
};