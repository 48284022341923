import { ListUtils } from '@devexpress/utils/lib/utils/list';
import { NumberMapUtils } from '@devexpress/utils/lib/utils/map/number';
import { LayoutChangeType } from '../../../../layout-formatter/changes/changes/layout-change-base';
import { DocumentLayoutDetailsLevel } from '../../../../layout/document-layout-details-level';
import { LayoutBoxType } from '../../../../layout/main-structures/layout-boxes/layout-box';
import { TableCellBoundFlags } from '../../../../layout/table/layout-table-cell-info';
import { Log } from '../base-logger/log';
import { LogObjToStr } from '../base-logger/log-obj-to-str';
export class LogObjToStrLayout {
  static layoutPoint(p) {
    return p ? `[pageIndex: ${p.pageIndex}, x: ${p.x}, y:${p.y}]` : "null";
  }
  static layoutPosition(lp) {
    return LogObjToStrLayout.layoutPositionShort(lp) + `, boxInd: ${lp.boxIndex}, charOffset:${lp.charOffset}`;
  }
  static layoutPositionShort(lp) {
    return `DetLevel: ${DocumentLayoutDetailsLevel[lp.detailsLevel]}, pageIndex: ${lp.pageIndex}, pageAreaInd: ${lp.pageAreaIndex}, columnInd: ${lp.columnIndex}, rowInd: ${lp.rowIndex}`;
  }
  static layoutChangeBase(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}index: ${obj.index}`);
    result.push(`type: ${LayoutChangeType[obj.changeType]}`);
    return result.join(sep);
  }
  static pageSelectionChange(change, prefix = "") {
    const prefix_1 = `${prefix}\t`;
    const prefix_2 = `${prefix_1}\t`;
    let changeHandler = (change, index) => `${prefix_1}areaSelectionChange[${index}]:\n${LogObjToStrLayout.areaSelectionChange(change, prefix_2)}`;
    const result = [];
    result.push(LogObjToStrLayout.layoutChangeBase(change, prefix));
    result.push(`${prefix}pageSelectionChange:\n${Log.join("\n", Log.map(changeHandler, change.areaChanges))}`);
    return result.join("\n");
  }
  static areaSelectionChange(change, prefix = "") {
    const result = [];
    result.push(LogObjToStrLayout.layoutChangeBase(change, prefix, ",\t"));
    result.push(LogObjToStr.rectangle(change.selection, prefix, ",\t"));
    return result.join(",\t");
  }
  static pageChange(pageChange, prefix = "") {
    const prefix_1 = `${prefix}\t`;
    const prefix_2 = `${prefix_1}\t`;
    let pageAreaChangeHandler = (change, index) => `${prefix_1}pageAreaChange[${index}]:\n${LogObjToStrLayout.pageAreaChange(change, prefix_2)}`;
    let ancPicChangeHandler = (change, index) => `${prefix_1}pictureFloatingChange[${index}]:\n${LogObjToStrLayout.anchoredPictureChange(change, prefix_2)}`;
    const result = [];
    result.push(LogObjToStrLayout.layoutChangeBase(pageChange, prefix));
    result.push(`${prefix}mainPageAreaChanges:\n${Log.join("\n", Log.map(pageAreaChangeHandler, pageChange.mainPageAreaChanges))}`);
    result.push(`${prefix}otherPageAreaChanges:\n${Log.join("\n", Log.map(pageAreaChangeHandler, pageChange.otherPageAreaChanges))}`);
    result.push(`${prefix}floatingPictureChanges:\n${Log.join("\n", Log.map(ancPicChangeHandler, pageChange.anchoredPictureChanges))}`);
    return result.join("\n");
  }
  static pageAreaChange(pageAreaChange, prefix = "") {
    const prefix_1 = `${prefix}\t`;
    const prefix_2 = `${prefix_1}\t`;
    let columnChangeHandler = (change, index) => `${prefix_1}columnChange[${index}]:\n${LogObjToStrLayout.columnChange(change, prefix_2)}`;
    const result = [];
    result.push(LogObjToStrLayout.layoutChangeBase(pageAreaChange, prefix));
    result.push(`${prefix}columnChanges:\n${Log.join("\n", Log.map(columnChangeHandler, pageAreaChange.columnChanges))}`);
    return result.join("\n");
  }
  static anchoredPictureChange(change, prefix = "") {
    const result = [];
    result.push(LogObjToStrLayout.layoutChangeBase(change, prefix));
    result.push(`${prefix}objectId: ${change.index}`);
    return result.join("\n");
  }
  static columnChange(columnChange, prefix = "") {
    const prefix_1 = `${prefix}\t`;
    const customChangeHandler = (_name, handler, change, index) => `${prefix_1}[${index}]:\t${handler(change, "")}`;
    const changesData = [{
      name: "rowChange",
      getChange: cc => cc.rowChanges,
      handler: LogObjToStrLayout.rowChange
    }, {
      name: "paragraphFrameChange",
      getChange: cc => cc.paragraphFrameChanges,
      handler: LogObjToStrLayout.paragraphFrameChange
    }, {
      name: "tableChange",
      getChange: cc => cc.tableChanges,
      handler: LogObjToStrLayout.tableChange
    }];
    const result = [];
    result.push(LogObjToStrLayout.layoutChangeBase(columnChange, prefix));
    for (let data of changesData) result.push(`${prefix}${data.name}s:\n${Log.join("\n", Log.map(Log.w(4, customChangeHandler)(data.name)(data.handler), data.getChange(columnChange)))}`);
    return result.join("\n");
  }
  static rowChange(rowChange, prefix = "") {
    return LogObjToStrLayout.layoutChangeBase(rowChange, prefix);
  }
  static paragraphFrameChange(paragraphFrameChange, prefix = "") {
    const result = [];
    result.push(LogObjToStrLayout.layoutChangeBase(paragraphFrameChange, prefix));
    return result.join("\t");
  }
  static tableChange(tableChange, prefix = "") {
    return LogObjToStrLayout.layoutChangeBase(tableChange, prefix);
  }
  static layoutTableColumnInfo(obj, prefix = "", sep = "\t") {
    const prefix_1 = `${prefix}\t`;
    const result = [];
    result.push(`${prefix}TableIndex: ${obj.logicInfo.grid.table.index}`);
    result.push(`${prefix}NestedLevel: ${obj.logicInfo.grid.table.nestedLevel}`);
    result.push(`${prefix}ParentTableIndex: ${obj.parentCell ? obj.parentCell.parentRow.parentTable.logicInfo.grid.table.index : ""}`);
    result.push(`${prefix}NumRows: ${obj.tableRows.length}`);
    result.push(`${prefix}Rows:\n${ListUtils.map(obj.tableRows, row => LogObjToStrLayout.layoutTableRowInfo(row, prefix_1, sep)).join("\n")}`);
    return result.join(sep);
  }
  static layoutTableRowInfo(obj, prefix = "", sep = "\t") {
    const prefix_1 = `${prefix}\t`;
    const result = [];
    result.push(`${prefix}RowIndex: ${obj.rowIndex}`);
    result.push(`${prefix}NumCells: ${obj.rowCells.length}`);
    result.push(`${prefix}Cells:\n${ListUtils.map(obj.rowCells, cell => LogObjToStrLayout.layoutTableCellInfo(cell, prefix_1, sep)).join("\n\n")}`);
    return result.join(sep);
  }
  static layoutTableCellInfo(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}CellGridIndex: ${obj.cellGridIndex}`);
    result.push(`${prefix}Flags: ${Log.mask(TableCellBoundFlags, obj.boundFlags.getValue(), TableCellBoundFlags.StartAndEndOnOtherColumns)}`);
    result.push(`${prefix}InternalTablesIndexes: ${NumberMapUtils.toListBy(obj.internalTables, tbl => tbl.logicInfo.grid.table.index.toString()).join(" ")}`);
    result.push(`${prefix}NumLayoutRows: ${obj.layoutRows.length}`);
    result.push(`${prefix}CellText: ${ListUtils.map(obj.layoutRows, layoutRow => ListUtils.map(layoutRow.boxes, box => box.getType() == LayoutBoxType.Text ? box.text : "¶").join("")).join("")}`);
    return result.join(sep);
  }
}