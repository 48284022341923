export class SizeUtils {
  static getClientWidth(element) {
    const style = getComputedStyle(element);
    const offset = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth) + parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    const sizeWithScrollBar = SizeUtils.getOffsetWidth(element) - offset;
    const scrollBarSize = Math.round(sizeWithScrollBar) - element.clientWidth;
    return sizeWithScrollBar - scrollBarSize;
  }
  static getClientHeight(element) {
    const style = getComputedStyle(element);
    const offset = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth) + parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
    const sizeWithScrollBar = SizeUtils.getOffsetHeight(element) - offset;
    const scrollBarSize = Math.round(sizeWithScrollBar) - element.clientHeight;
    return sizeWithScrollBar - scrollBarSize;
  }
  static getOffsetSize(element) {
    return element.getBoundingClientRect();
  }
  static getOffsetWidth(element) {
    return SizeUtils.getOffsetSize(element).width;
  }
  static getOffsetHeight(element) {
    return SizeUtils.getOffsetSize(element).height;
  }
}