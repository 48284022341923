export var RichEditClientCommand;
(function (RichEditClientCommand) {
  RichEditClientCommand[RichEditClientCommand["None"] = 0] = "None";
  RichEditClientCommand[RichEditClientCommand["FileNew"] = 1] = "FileNew";
  RichEditClientCommand[RichEditClientCommand["FileOpen"] = 2] = "FileOpen";
  RichEditClientCommand[RichEditClientCommand["FileSave"] = 3] = "FileSave";
  RichEditClientCommand[RichEditClientCommand["FileSaveAs"] = 4] = "FileSaveAs";
  RichEditClientCommand[RichEditClientCommand["FilePrint"] = 5] = "FilePrint";
  RichEditClientCommand[RichEditClientCommand["Undo"] = 6] = "Undo";
  RichEditClientCommand[RichEditClientCommand["Redo"] = 7] = "Redo";
  RichEditClientCommand[RichEditClientCommand["PasteSelection"] = 8] = "PasteSelection";
  RichEditClientCommand[RichEditClientCommand["CopySelection"] = 9] = "CopySelection";
  RichEditClientCommand[RichEditClientCommand["CutSelection"] = 10] = "CutSelection";
  RichEditClientCommand[RichEditClientCommand["ChangeFontName"] = 11] = "ChangeFontName";
  RichEditClientCommand[RichEditClientCommand["ChangeStyle"] = 12] = "ChangeStyle";
  RichEditClientCommand[RichEditClientCommand["ChangeFontSize"] = 13] = "ChangeFontSize";
  RichEditClientCommand[RichEditClientCommand["IncreaseFontSize"] = 14] = "IncreaseFontSize";
  RichEditClientCommand[RichEditClientCommand["DecreaseFontSize"] = 15] = "DecreaseFontSize";
  RichEditClientCommand[RichEditClientCommand["MakeTextUpperCase"] = 16] = "MakeTextUpperCase";
  RichEditClientCommand[RichEditClientCommand["MakeTextLowerCase"] = 17] = "MakeTextLowerCase";
  RichEditClientCommand[RichEditClientCommand["CapitalizeEachWordTextCase"] = 18] = "CapitalizeEachWordTextCase";
  RichEditClientCommand[RichEditClientCommand["ToggleTextCase"] = 19] = "ToggleTextCase";
  RichEditClientCommand[RichEditClientCommand["ToggleFontBold"] = 20] = "ToggleFontBold";
  RichEditClientCommand[RichEditClientCommand["ToggleFontItalic"] = 21] = "ToggleFontItalic";
  RichEditClientCommand[RichEditClientCommand["ToggleFontUnderline"] = 22] = "ToggleFontUnderline";
  RichEditClientCommand[RichEditClientCommand["ToggleFontDoubleUnderline"] = 23] = "ToggleFontDoubleUnderline";
  RichEditClientCommand[RichEditClientCommand["ToggleFontStrikeout"] = 24] = "ToggleFontStrikeout";
  RichEditClientCommand[RichEditClientCommand["ToggleFontSuperscript"] = 26] = "ToggleFontSuperscript";
  RichEditClientCommand[RichEditClientCommand["ToggleFontSubscript"] = 27] = "ToggleFontSubscript";
  RichEditClientCommand[RichEditClientCommand["ChangeFontForeColor"] = 28] = "ChangeFontForeColor";
  RichEditClientCommand[RichEditClientCommand["ChangeFontBackColor"] = 29] = "ChangeFontBackColor";
  RichEditClientCommand[RichEditClientCommand["ClearFormatting"] = 30] = "ClearFormatting";
  RichEditClientCommand[RichEditClientCommand["ToggleBulletedListItem"] = 31] = "ToggleBulletedListItem";
  RichEditClientCommand[RichEditClientCommand["ToggleNumberingListItem"] = 32] = "ToggleNumberingListItem";
  RichEditClientCommand[RichEditClientCommand["ToggleMultilevelListItem"] = 33] = "ToggleMultilevelListItem";
  RichEditClientCommand[RichEditClientCommand["DecreaseIndent"] = 34] = "DecreaseIndent";
  RichEditClientCommand[RichEditClientCommand["IncreaseIndent"] = 35] = "IncreaseIndent";
  RichEditClientCommand[RichEditClientCommand["ToggleShowWhitespace"] = 36] = "ToggleShowWhitespace";
  RichEditClientCommand[RichEditClientCommand["ToggleParagraphAlignmentLeft"] = 37] = "ToggleParagraphAlignmentLeft";
  RichEditClientCommand[RichEditClientCommand["ToggleParagraphAlignmentCenter"] = 38] = "ToggleParagraphAlignmentCenter";
  RichEditClientCommand[RichEditClientCommand["ToggleParagraphAlignmentRight"] = 39] = "ToggleParagraphAlignmentRight";
  RichEditClientCommand[RichEditClientCommand["ToggleParagraphAlignmentJustify"] = 40] = "ToggleParagraphAlignmentJustify";
  RichEditClientCommand[RichEditClientCommand["SetSingleParagraphSpacing"] = 41] = "SetSingleParagraphSpacing";
  RichEditClientCommand[RichEditClientCommand["SetSesquialteralParagraphSpacing"] = 42] = "SetSesquialteralParagraphSpacing";
  RichEditClientCommand[RichEditClientCommand["SetDoubleParagraphSpacing"] = 43] = "SetDoubleParagraphSpacing";
  RichEditClientCommand[RichEditClientCommand["AddSpacingBeforeParagraph"] = 45] = "AddSpacingBeforeParagraph";
  RichEditClientCommand[RichEditClientCommand["AddSpacingAfterParagraph"] = 46] = "AddSpacingAfterParagraph";
  RichEditClientCommand[RichEditClientCommand["RemoveSpacingBeforeParagraph"] = 47] = "RemoveSpacingBeforeParagraph";
  RichEditClientCommand[RichEditClientCommand["RemoveSpacingAfterParagraph"] = 48] = "RemoveSpacingAfterParagraph";
  RichEditClientCommand[RichEditClientCommand["ChangeParagraphBackColor"] = 49] = "ChangeParagraphBackColor";
  RichEditClientCommand[RichEditClientCommand["Find"] = 50] = "Find";
  RichEditClientCommand[RichEditClientCommand["Replace"] = 51] = "Replace";
  RichEditClientCommand[RichEditClientCommand["ShowFontForm"] = 52] = "ShowFontForm";
  RichEditClientCommand[RichEditClientCommand["ShowParagraphForm"] = 53] = "ShowParagraphForm";
  RichEditClientCommand[RichEditClientCommand["InsertPageBreak"] = 54] = "InsertPageBreak";
  RichEditClientCommand[RichEditClientCommand["ShowInsertTableForm"] = 55] = "ShowInsertTableForm";
  RichEditClientCommand[RichEditClientCommand["InsertPicture"] = 56] = "InsertPicture";
  RichEditClientCommand[RichEditClientCommand["ShowBookmarkForm"] = 58] = "ShowBookmarkForm";
  RichEditClientCommand[RichEditClientCommand["ShowHyperlinkForm"] = 59] = "ShowHyperlinkForm";
  RichEditClientCommand[RichEditClientCommand["ShowSymbolForm"] = 65] = "ShowSymbolForm";
  RichEditClientCommand[RichEditClientCommand["SetNormalSectionPageMargins"] = 66] = "SetNormalSectionPageMargins";
  RichEditClientCommand[RichEditClientCommand["SetNarrowSectionPageMargins"] = 67] = "SetNarrowSectionPageMargins";
  RichEditClientCommand[RichEditClientCommand["SetModerateSectionPageMargins"] = 68] = "SetModerateSectionPageMargins";
  RichEditClientCommand[RichEditClientCommand["SetWideSectionPageMargins"] = 69] = "SetWideSectionPageMargins";
  RichEditClientCommand[RichEditClientCommand["ShowPageMarginsSetupForm"] = 70] = "ShowPageMarginsSetupForm";
  RichEditClientCommand[RichEditClientCommand["SetPortraitPageOrientation"] = 71] = "SetPortraitPageOrientation";
  RichEditClientCommand[RichEditClientCommand["SetLandscapePageOrientation"] = 72] = "SetLandscapePageOrientation";
  RichEditClientCommand[RichEditClientCommand["ShowPagePaperSetupForm"] = 73] = "ShowPagePaperSetupForm";
  RichEditClientCommand[RichEditClientCommand["SetSectionOneColumn"] = 74] = "SetSectionOneColumn";
  RichEditClientCommand[RichEditClientCommand["SetSectionTwoColumns"] = 75] = "SetSectionTwoColumns";
  RichEditClientCommand[RichEditClientCommand["SetSectionThreeColumns"] = 76] = "SetSectionThreeColumns";
  RichEditClientCommand[RichEditClientCommand["ShowColumnsSetupForm"] = 77] = "ShowColumnsSetupForm";
  RichEditClientCommand[RichEditClientCommand["InsertColumnBreak"] = 79] = "InsertColumnBreak";
  RichEditClientCommand[RichEditClientCommand["InsertSectionBreakNextPage"] = 80] = "InsertSectionBreakNextPage";
  RichEditClientCommand[RichEditClientCommand["InsertSectionBreakEvenPage"] = 81] = "InsertSectionBreakEvenPage";
  RichEditClientCommand[RichEditClientCommand["InsertSectionBreakOddPage"] = 82] = "InsertSectionBreakOddPage";
  RichEditClientCommand[RichEditClientCommand["ChangePageColor"] = 90] = "ChangePageColor";
  RichEditClientCommand[RichEditClientCommand["ToggleShowHorizontalRuler"] = 94] = "ToggleShowHorizontalRuler";
  RichEditClientCommand[RichEditClientCommand["FullScreen"] = 98] = "FullScreen";
  RichEditClientCommand[RichEditClientCommand["SetSectionLegalPaperKind"] = 99] = "SetSectionLegalPaperKind";
  RichEditClientCommand[RichEditClientCommand["SetSectionFolioPaperKind"] = 100] = "SetSectionFolioPaperKind";
  RichEditClientCommand[RichEditClientCommand["SetSectionA4PaperKind"] = 101] = "SetSectionA4PaperKind";
  RichEditClientCommand[RichEditClientCommand["SetSectionA5PaperKind"] = 102] = "SetSectionA5PaperKind";
  RichEditClientCommand[RichEditClientCommand["SetSectionA6PaperKind"] = 103] = "SetSectionA6PaperKind";
  RichEditClientCommand[RichEditClientCommand["SetSectionB5PaperKind"] = 104] = "SetSectionB5PaperKind";
  RichEditClientCommand[RichEditClientCommand["SetSectionExecutivePaperKind"] = 105] = "SetSectionExecutivePaperKind";
  RichEditClientCommand[RichEditClientCommand["SelectAll"] = 106] = "SelectAll";
  RichEditClientCommand[RichEditClientCommand["ShowPageSetupForm"] = 107] = "ShowPageSetupForm";
  RichEditClientCommand[RichEditClientCommand["ShowNumberingListForm"] = 108] = "ShowNumberingListForm";
  RichEditClientCommand[RichEditClientCommand["ExtendLineDown"] = 109] = "ExtendLineDown";
  RichEditClientCommand[RichEditClientCommand["ExtendLineEnd"] = 110] = "ExtendLineEnd";
  RichEditClientCommand[RichEditClientCommand["ExtendLineStart"] = 111] = "ExtendLineStart";
  RichEditClientCommand[RichEditClientCommand["ExtendLineUp"] = 112] = "ExtendLineUp";
  RichEditClientCommand[RichEditClientCommand["ExtendNextCharacter"] = 113] = "ExtendNextCharacter";
  RichEditClientCommand[RichEditClientCommand["ExtendPreviousCharacter"] = 114] = "ExtendPreviousCharacter";
  RichEditClientCommand[RichEditClientCommand["ExtendSelectLine"] = 115] = "ExtendSelectLine";
  RichEditClientCommand[RichEditClientCommand["InsertParagraph"] = 116] = "InsertParagraph";
  RichEditClientCommand[RichEditClientCommand["InsertText"] = 117] = "InsertText";
  RichEditClientCommand[RichEditClientCommand["LineDown"] = 118] = "LineDown";
  RichEditClientCommand[RichEditClientCommand["LineEnd"] = 119] = "LineEnd";
  RichEditClientCommand[RichEditClientCommand["LineStart"] = 120] = "LineStart";
  RichEditClientCommand[RichEditClientCommand["LineUp"] = 121] = "LineUp";
  RichEditClientCommand[RichEditClientCommand["NextCharacter"] = 122] = "NextCharacter";
  RichEditClientCommand[RichEditClientCommand["PreviousCharacter"] = 123] = "PreviousCharacter";
  RichEditClientCommand[RichEditClientCommand["SelectLine"] = 124] = "SelectLine";
  RichEditClientCommand[RichEditClientCommand["ToggleBackspaceKey"] = 125] = "ToggleBackspaceKey";
  RichEditClientCommand[RichEditClientCommand["ToggleDeleteKey"] = 126] = "ToggleDeleteKey";
  RichEditClientCommand[RichEditClientCommand["InsertLineBreak"] = 127] = "InsertLineBreak";
  RichEditClientCommand[RichEditClientCommand["NextPage"] = 128] = "NextPage";
  RichEditClientCommand[RichEditClientCommand["ExtendNextPage"] = 129] = "ExtendNextPage";
  RichEditClientCommand[RichEditClientCommand["PreviousPage"] = 130] = "PreviousPage";
  RichEditClientCommand[RichEditClientCommand["ExtendPreviousPage"] = 131] = "ExtendPreviousPage";
  RichEditClientCommand[RichEditClientCommand["ChangeInlinePictureScale"] = 132] = "ChangeInlinePictureScale";
  RichEditClientCommand[RichEditClientCommand["IncrementParagraphLeftIndent"] = 133] = "IncrementParagraphLeftIndent";
  RichEditClientCommand[RichEditClientCommand["DecrementParagraphLeftIndent"] = 134] = "DecrementParagraphLeftIndent";
  RichEditClientCommand[RichEditClientCommand["DragMoveContent"] = 135] = "DragMoveContent";
  RichEditClientCommand[RichEditClientCommand["DragCopyContent"] = 136] = "DragCopyContent";
  RichEditClientCommand[RichEditClientCommand["InsertSpace"] = 137] = "InsertSpace";
  RichEditClientCommand[RichEditClientCommand["RulerSectionMarginLeft"] = 138] = "RulerSectionMarginLeft";
  RichEditClientCommand[RichEditClientCommand["RulerSectionMarginRight"] = 139] = "RulerSectionMarginRight";
  RichEditClientCommand[RichEditClientCommand["RulerParagraphRightIndent"] = 140] = "RulerParagraphRightIndent";
  RichEditClientCommand[RichEditClientCommand["RulerSectionColumnsSettings"] = 141] = "RulerSectionColumnsSettings";
  RichEditClientCommand[RichEditClientCommand["RulerParagraphLeftIndents"] = 142] = "RulerParagraphLeftIndents";
  RichEditClientCommand[RichEditClientCommand["InsertTabMark"] = 143] = "InsertTabMark";
  RichEditClientCommand[RichEditClientCommand["InsertShiftTabMark"] = 144] = "InsertShiftTabMark";
  RichEditClientCommand[RichEditClientCommand["DocumentStart"] = 145] = "DocumentStart";
  RichEditClientCommand[RichEditClientCommand["ExtendDocumentStart"] = 146] = "ExtendDocumentStart";
  RichEditClientCommand[RichEditClientCommand["DocumentEnd"] = 147] = "DocumentEnd";
  RichEditClientCommand[RichEditClientCommand["ExtendDocumentEnd"] = 148] = "ExtendDocumentEnd";
  RichEditClientCommand[RichEditClientCommand["GoToNextWord"] = 149] = "GoToNextWord";
  RichEditClientCommand[RichEditClientCommand["ExtendGoToNextWord"] = 150] = "ExtendGoToNextWord";
  RichEditClientCommand[RichEditClientCommand["GoToPrevWord"] = 151] = "GoToPrevWord";
  RichEditClientCommand[RichEditClientCommand["ExtendGoToPrevWord"] = 152] = "ExtendGoToPrevWord";
  RichEditClientCommand[RichEditClientCommand["GoToStartParagraph"] = 153] = "GoToStartParagraph";
  RichEditClientCommand[RichEditClientCommand["ExtendGoToStartParagraph"] = 154] = "ExtendGoToStartParagraph";
  RichEditClientCommand[RichEditClientCommand["GoToEndParagraph"] = 155] = "GoToEndParagraph";
  RichEditClientCommand[RichEditClientCommand["ExtendGoToEndParagraph"] = 156] = "ExtendGoToEndParagraph";
  RichEditClientCommand[RichEditClientCommand["ReloadDocument"] = 157] = "ReloadDocument";
  RichEditClientCommand[RichEditClientCommand["ShowErrorModelIsChangedMessageCommand"] = 158] = "ShowErrorModelIsChangedMessageCommand";
  RichEditClientCommand[RichEditClientCommand["ShowErrorSessionHasExpiredMessageCommand"] = 159] = "ShowErrorSessionHasExpiredMessageCommand";
  RichEditClientCommand[RichEditClientCommand["SelectParagraph"] = 160] = "SelectParagraph";
  RichEditClientCommand[RichEditClientCommand["ShowErrorOpeningAndOverstoreImpossibleMessageCommand"] = 161] = "ShowErrorOpeningAndOverstoreImpossibleMessageCommand";
  RichEditClientCommand[RichEditClientCommand["SetSectionLetterPaperKind"] = 162] = "SetSectionLetterPaperKind";
  RichEditClientCommand[RichEditClientCommand["ShowErrorClipboardAccessDeniedMessageCommand"] = 163] = "ShowErrorClipboardAccessDeniedMessageCommand";
  RichEditClientCommand[RichEditClientCommand["SelectLineNoUpdateControlState"] = 164] = "SelectLineNoUpdateControlState";
  RichEditClientCommand[RichEditClientCommand["ExtendSelectLineNoUpdateControlState"] = 165] = "ExtendSelectLineNoUpdateControlState";
  RichEditClientCommand[RichEditClientCommand["ShowTabsForm"] = 166] = "ShowTabsForm";
  RichEditClientCommand[RichEditClientCommand["ShowCustomNumberingListForm"] = 167] = "ShowCustomNumberingListForm";
  RichEditClientCommand[RichEditClientCommand["ShowServiceFontForm"] = 168] = "ShowServiceFontForm";
  RichEditClientCommand[RichEditClientCommand["ShowServiceSymbolsForm"] = 169] = "ShowServiceSymbolsForm";
  RichEditClientCommand[RichEditClientCommand["RestartNumberingList"] = 170] = "RestartNumberingList";
  RichEditClientCommand[RichEditClientCommand["DeleteTabRuler"] = 171] = "DeleteTabRuler";
  RichEditClientCommand[RichEditClientCommand["InsertTabRuler"] = 172] = "InsertTabRuler";
  RichEditClientCommand[RichEditClientCommand["MoveTabRuler"] = 173] = "MoveTabRuler";
  RichEditClientCommand[RichEditClientCommand["IncrementNumberingIndent"] = 174] = "IncrementNumberingIndent";
  RichEditClientCommand[RichEditClientCommand["DecrementNumberingIndent"] = 175] = "DecrementNumberingIndent";
  RichEditClientCommand[RichEditClientCommand["IncrementParagraphIndentFromFirstRow"] = 176] = "IncrementParagraphIndentFromFirstRow";
  RichEditClientCommand[RichEditClientCommand["DecrementParagraphIndentFromFirstRow"] = 177] = "DecrementParagraphIndentFromFirstRow";
  RichEditClientCommand[RichEditClientCommand["CreateField"] = 178] = "CreateField";
  RichEditClientCommand[RichEditClientCommand["UpdateField"] = 179] = "UpdateField";
  RichEditClientCommand[RichEditClientCommand["ToggleFieldCodes"] = 180] = "ToggleFieldCodes";
  RichEditClientCommand[RichEditClientCommand["ShowAllFieldCodes"] = 186] = "ShowAllFieldCodes";
  RichEditClientCommand[RichEditClientCommand["ShowAllFieldResults"] = 187] = "ShowAllFieldResults";
  RichEditClientCommand[RichEditClientCommand["ToggleAllFields"] = 188] = "ToggleAllFields";
  RichEditClientCommand[RichEditClientCommand["ContinueNumberingList"] = 189] = "ContinueNumberingList";
  RichEditClientCommand[RichEditClientCommand["InsertNumerationToParagraphs"] = 190] = "InsertNumerationToParagraphs";
  RichEditClientCommand[RichEditClientCommand["DeleteNumerationFromParagraphs"] = 191] = "DeleteNumerationFromParagraphs";
  RichEditClientCommand[RichEditClientCommand["ShowErrorInnerExceptionMessageCommand"] = 192] = "ShowErrorInnerExceptionMessageCommand";
  RichEditClientCommand[RichEditClientCommand["ShowErrorAuthExceptionMessageCommand"] = 193] = "ShowErrorAuthExceptionMessageCommand";
  RichEditClientCommand[RichEditClientCommand["ShowEditHyperlinkForm"] = 194] = "ShowEditHyperlinkForm";
  RichEditClientCommand[RichEditClientCommand["OpenHyperlink"] = 195] = "OpenHyperlink";
  RichEditClientCommand[RichEditClientCommand["RemoveHyperlink"] = 196] = "RemoveHyperlink";
  RichEditClientCommand[RichEditClientCommand["ShowErrorSavingMessageCommand"] = 197] = "ShowErrorSavingMessageCommand";
  RichEditClientCommand[RichEditClientCommand["ShowErrorOpeningMessageCommand"] = 198] = "ShowErrorOpeningMessageCommand";
  RichEditClientCommand[RichEditClientCommand["ShowErrorDocVariableErrorCommand"] = 199] = "ShowErrorDocVariableErrorCommand";
  RichEditClientCommand[RichEditClientCommand["UpdateAllFields"] = 200] = "UpdateAllFields";
  RichEditClientCommand[RichEditClientCommand["InsertNonBreakingSpace"] = 201] = "InsertNonBreakingSpace";
  RichEditClientCommand[RichEditClientCommand["RemoveHyperlinks"] = 202] = "RemoveHyperlinks";
  RichEditClientCommand[RichEditClientCommand["CreateDateField"] = 203] = "CreateDateField";
  RichEditClientCommand[RichEditClientCommand["CreateTimeField"] = 204] = "CreateTimeField";
  RichEditClientCommand[RichEditClientCommand["CreatePageField"] = 205] = "CreatePageField";
  RichEditClientCommand[RichEditClientCommand["ShowCreateHyperlinkForm"] = 206] = "ShowCreateHyperlinkForm";
  RichEditClientCommand[RichEditClientCommand["SentenceCase"] = 207] = "SentenceCase";
  RichEditClientCommand[RichEditClientCommand["SwitchTextCase"] = 208] = "SwitchTextCase";
  RichEditClientCommand[RichEditClientCommand["GoToFirstDataRecord"] = 209] = "GoToFirstDataRecord";
  RichEditClientCommand[RichEditClientCommand["GoToPreviousDataRecord"] = 210] = "GoToPreviousDataRecord";
  RichEditClientCommand[RichEditClientCommand["GoToNextDataRecord"] = 211] = "GoToNextDataRecord";
  RichEditClientCommand[RichEditClientCommand["GoToLastDataRecord"] = 212] = "GoToLastDataRecord";
  RichEditClientCommand[RichEditClientCommand["ToggleViewMergedData"] = 213] = "ToggleViewMergedData";
  RichEditClientCommand[RichEditClientCommand["ShowInsertMergeFieldForm"] = 214] = "ShowInsertMergeFieldForm";
  RichEditClientCommand[RichEditClientCommand["CreateMergeField"] = 215] = "CreateMergeField";
  RichEditClientCommand[RichEditClientCommand["ShowFinishAndMergeForm"] = 216] = "ShowFinishAndMergeForm";
  RichEditClientCommand[RichEditClientCommand["ShowSaveMergedDocumentForm"] = 218] = "ShowSaveMergedDocumentForm";
  RichEditClientCommand[RichEditClientCommand["AddSelectedLineCommandNoUpdateControlState"] = 219] = "AddSelectedLineCommandNoUpdateControlState";
  RichEditClientCommand[RichEditClientCommand["InsertHeader"] = 220] = "InsertHeader";
  RichEditClientCommand[RichEditClientCommand["InsertFooter"] = 221] = "InsertFooter";
  RichEditClientCommand[RichEditClientCommand["LinkHeaderFooterToPrevious"] = 222] = "LinkHeaderFooterToPrevious";
  RichEditClientCommand[RichEditClientCommand["CreateBookmark"] = 225] = "CreateBookmark";
  RichEditClientCommand[RichEditClientCommand["DeleteBookmarks"] = 226] = "DeleteBookmarks";
  RichEditClientCommand[RichEditClientCommand["GoToPageHeader"] = 227] = "GoToPageHeader";
  RichEditClientCommand[RichEditClientCommand["GoToPageFooter"] = 228] = "GoToPageFooter";
  RichEditClientCommand[RichEditClientCommand["GoToNextPageHeaderFooter"] = 229] = "GoToNextPageHeaderFooter";
  RichEditClientCommand[RichEditClientCommand["GoToPreviousPageHeaderFooter"] = 230] = "GoToPreviousPageHeaderFooter";
  RichEditClientCommand[RichEditClientCommand["ToggleDifferentFirstPage"] = 231] = "ToggleDifferentFirstPage";
  RichEditClientCommand[RichEditClientCommand["ToggleDifferentOddAndEvenPages"] = 232] = "ToggleDifferentOddAndEvenPages";
  RichEditClientCommand[RichEditClientCommand["ClosePageHeaderFooter"] = 233] = "ClosePageHeaderFooter";
  RichEditClientCommand[RichEditClientCommand["ContextItem_HeadersFooters"] = 234] = "ContextItem_HeadersFooters";
  RichEditClientCommand[RichEditClientCommand["InsertPageNumberField"] = 235] = "InsertPageNumberField";
  RichEditClientCommand[RichEditClientCommand["InsertPageCountField"] = 236] = "InsertPageCountField";
  RichEditClientCommand[RichEditClientCommand["GoToBookmark"] = 237] = "GoToBookmark";
  RichEditClientCommand[RichEditClientCommand["InsertTableCore"] = 238] = "InsertTableCore";
  RichEditClientCommand[RichEditClientCommand["ContextItem_Tables"] = 239] = "ContextItem_Tables";
  RichEditClientCommand[RichEditClientCommand["ShowTablePropertiesForm"] = 240] = "ShowTablePropertiesForm";
  RichEditClientCommand[RichEditClientCommand["ShowCellOptionsForm"] = 242] = "ShowCellOptionsForm";
  RichEditClientCommand[RichEditClientCommand["InsertTableColumnToTheLeft"] = 243] = "InsertTableColumnToTheLeft";
  RichEditClientCommand[RichEditClientCommand["InsertTableColumnToTheRight"] = 244] = "InsertTableColumnToTheRight";
  RichEditClientCommand[RichEditClientCommand["InsertTableRowBelow"] = 245] = "InsertTableRowBelow";
  RichEditClientCommand[RichEditClientCommand["InsertTableRowAbove"] = 246] = "InsertTableRowAbove";
  RichEditClientCommand[RichEditClientCommand["DeleteTableRows"] = 247] = "DeleteTableRows";
  RichEditClientCommand[RichEditClientCommand["DeleteTableColumns"] = 248] = "DeleteTableColumns";
  RichEditClientCommand[RichEditClientCommand["InsertTableCellWithShiftToTheLeft"] = 249] = "InsertTableCellWithShiftToTheLeft";
  RichEditClientCommand[RichEditClientCommand["DeleteTableCellsWithShiftToTheHorizontally"] = 250] = "DeleteTableCellsWithShiftToTheHorizontally";
  RichEditClientCommand[RichEditClientCommand["DeleteTable"] = 251] = "DeleteTable";
  RichEditClientCommand[RichEditClientCommand["ShowInsertTableCellsForm"] = 252] = "ShowInsertTableCellsForm";
  RichEditClientCommand[RichEditClientCommand["ShowDeleteTableCellsForm"] = 253] = "ShowDeleteTableCellsForm";
  RichEditClientCommand[RichEditClientCommand["MergeTableCells"] = 254] = "MergeTableCells";
  RichEditClientCommand[RichEditClientCommand["ShowSplitTableCellsForm"] = 255] = "ShowSplitTableCellsForm";
  RichEditClientCommand[RichEditClientCommand["SplitTableCellsCommand"] = 256] = "SplitTableCellsCommand";
  RichEditClientCommand[RichEditClientCommand["InsertTableCellsWithShiftToTheVertically"] = 257] = "InsertTableCellsWithShiftToTheVertically";
  RichEditClientCommand[RichEditClientCommand["DeleteTableCellsWithShiftToTheVertically"] = 258] = "DeleteTableCellsWithShiftToTheVertically";
  RichEditClientCommand[RichEditClientCommand["ShowBorderShadingForm"] = 259] = "ShowBorderShadingForm";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignTopLeft"] = 260] = "TableCellAlignTopLeft";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignTopCenter"] = 261] = "TableCellAlignTopCenter";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignTopRight"] = 262] = "TableCellAlignTopRight";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignMiddleLeft"] = 263] = "TableCellAlignMiddleLeft";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignMiddleCenter"] = 264] = "TableCellAlignMiddleCenter";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignMiddleRight"] = 265] = "TableCellAlignMiddleRight";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignBottomLeft"] = 266] = "TableCellAlignBottomLeft";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignBottomCenter"] = 267] = "TableCellAlignBottomCenter";
  RichEditClientCommand[RichEditClientCommand["TableCellAlignBottomRight"] = 268] = "TableCellAlignBottomRight";
  RichEditClientCommand[RichEditClientCommand["ApplyTableStyle"] = 269] = "ApplyTableStyle";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellsTopBorder"] = 270] = "ToggleTableCellsTopBorder";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellsRightBorder"] = 271] = "ToggleTableCellsRightBorder";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellsBottomBorder"] = 272] = "ToggleTableCellsBottomBorder";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellsLeftBorder"] = 273] = "ToggleTableCellsLeftBorder";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellNoBorder"] = 274] = "ToggleTableCellNoBorder";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellAllBorders"] = 275] = "ToggleTableCellAllBorders";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellInsideBorders"] = 276] = "ToggleTableCellInsideBorders";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellInsideHorizontalBorders"] = 277] = "ToggleTableCellInsideHorizontalBorders";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellInsideVerticalBorders"] = 278] = "ToggleTableCellInsideVerticalBorders";
  RichEditClientCommand[RichEditClientCommand["ToggleTableCellOutsideBorders"] = 279] = "ToggleTableCellOutsideBorders";
  RichEditClientCommand[RichEditClientCommand["ToggleFirstRow"] = 280] = "ToggleFirstRow";
  RichEditClientCommand[RichEditClientCommand["ToggleLastRow"] = 281] = "ToggleLastRow";
  RichEditClientCommand[RichEditClientCommand["ToggleFirstColumn"] = 282] = "ToggleFirstColumn";
  RichEditClientCommand[RichEditClientCommand["ToggleLastColumn"] = 283] = "ToggleLastColumn";
  RichEditClientCommand[RichEditClientCommand["ToggleBandedRows"] = 284] = "ToggleBandedRows";
  RichEditClientCommand[RichEditClientCommand["ToggleBandedColumn"] = 285] = "ToggleBandedColumn";
  RichEditClientCommand[RichEditClientCommand["SelectTableCell"] = 286] = "SelectTableCell";
  RichEditClientCommand[RichEditClientCommand["SelectTableColumn"] = 287] = "SelectTableColumn";
  RichEditClientCommand[RichEditClientCommand["SelectTableRow"] = 288] = "SelectTableRow";
  RichEditClientCommand[RichEditClientCommand["SelectTable"] = 289] = "SelectTable";
  RichEditClientCommand[RichEditClientCommand["ChangeTableBorderColorRepositoryItem"] = 290] = "ChangeTableBorderColorRepositoryItem";
  RichEditClientCommand[RichEditClientCommand["ChangeTableBorderWidthRepositoryItem"] = 291] = "ChangeTableBorderWidthRepositoryItem";
  RichEditClientCommand[RichEditClientCommand["ChangeTableBorderStyleRepositoryItem"] = 292] = "ChangeTableBorderStyleRepositoryItem";
  RichEditClientCommand[RichEditClientCommand["ShowErrorPathTooLongCommand"] = 293] = "ShowErrorPathTooLongCommand";
  RichEditClientCommand[RichEditClientCommand["ChangeTableCellShading"] = 294] = "ChangeTableCellShading";
  RichEditClientCommand[RichEditClientCommand["ToggleShowTableGridLines"] = 295] = "ToggleShowTableGridLines";
  RichEditClientCommand[RichEditClientCommand["ExtendSelectTableCell"] = 296] = "ExtendSelectTableCell";
  RichEditClientCommand[RichEditClientCommand["ExtendSelectTableColumn"] = 297] = "ExtendSelectTableColumn";
  RichEditClientCommand[RichEditClientCommand["ExtendSelectTableRow"] = 298] = "ExtendSelectTableRow";
  RichEditClientCommand[RichEditClientCommand["ExtendSelectTable"] = 299] = "ExtendSelectTable";
  RichEditClientCommand[RichEditClientCommand["SelectTableCellsRange"] = 300] = "SelectTableCellsRange";
  RichEditClientCommand[RichEditClientCommand["ShowServiceBorderShadingForm"] = 301] = "ShowServiceBorderShadingForm";
  RichEditClientCommand[RichEditClientCommand["RulerTableColumnSeparators"] = 302] = "RulerTableColumnSeparators";
  RichEditClientCommand[RichEditClientCommand["ChangeActiveSubDocumentToMain"] = 303] = "ChangeActiveSubDocumentToMain";
  RichEditClientCommand[RichEditClientCommand["ChangeActiveSubDocumentToHeaderFooterByPageIndex"] = 304] = "ChangeActiveSubDocumentToHeaderFooterByPageIndex";
  RichEditClientCommand[RichEditClientCommand["ChangeActiveSubDocumentToHeaderFooterBySubDocument"] = 305] = "ChangeActiveSubDocumentToHeaderFooterBySubDocument";
  RichEditClientCommand[RichEditClientCommand["ShowErrorInvalidDocumentFormat"] = 306] = "ShowErrorInvalidDocumentFormat";
  RichEditClientCommand[RichEditClientCommand["SetSectionPageSize"] = 307] = "SetSectionPageSize";
  RichEditClientCommand[RichEditClientCommand["SetSectionColumnCount"] = 308] = "SetSectionColumnCount";
  RichEditClientCommand[RichEditClientCommand["ChangeTableRowHeight"] = 309] = "ChangeTableRowHeight";
  RichEditClientCommand[RichEditClientCommand["ChangeTableCellWidth"] = 310] = "ChangeTableCellWidth";
  RichEditClientCommand[RichEditClientCommand["ChangeTableColumnWidth"] = 311] = "ChangeTableColumnWidth";
  RichEditClientCommand[RichEditClientCommand["ChangeTableLook"] = 312] = "ChangeTableLook";
  RichEditClientCommand[RichEditClientCommand["ShowSpellCheckerForm"] = 313] = "ShowSpellCheckerForm";
  RichEditClientCommand[RichEditClientCommand["IgnoreSpellingError"] = 314] = "IgnoreSpellingError";
  RichEditClientCommand[RichEditClientCommand["IgnoreAllSpellingErrors"] = 315] = "IgnoreAllSpellingErrors";
  RichEditClientCommand[RichEditClientCommand["DeleteRepeatedWord"] = 316] = "DeleteRepeatedWord";
  RichEditClientCommand[RichEditClientCommand["AddWordToDictionary"] = 317] = "AddWordToDictionary";
  RichEditClientCommand[RichEditClientCommand["ChangeSpellingError"] = 318] = "ChangeSpellingError";
  RichEditClientCommand[RichEditClientCommand["ChangeAllSpellingErrors"] = 319] = "ChangeAllSpellingErrors";
  RichEditClientCommand[RichEditClientCommand["ShowSpellingCheckCompletedMessage"] = 320] = "ShowSpellingCheckCompletedMessage";
  RichEditClientCommand[RichEditClientCommand["RulerChangeTableColumnWidth"] = 321] = "RulerChangeTableColumnWidth";
  RichEditClientCommand[RichEditClientCommand["RulerChangeTableRowHeight"] = 322] = "RulerChangeTableRowHeight";
  RichEditClientCommand[RichEditClientCommand["DeleteTableCellsByBackspace"] = 323] = "DeleteTableCellsByBackspace";
  RichEditClientCommand[RichEditClientCommand["GoToDataRecord"] = 324] = "GoToDataRecord";
  RichEditClientCommand[RichEditClientCommand["FindAll"] = 325] = "FindAll";
  RichEditClientCommand[RichEditClientCommand["ReplaceNext"] = 326] = "ReplaceNext";
  RichEditClientCommand[RichEditClientCommand["ReplaceAll"] = 327] = "ReplaceAll";
  RichEditClientCommand[RichEditClientCommand["HideFindResults"] = 328] = "HideFindResults";
  RichEditClientCommand[RichEditClientCommand["ActivateRibbon"] = 329] = "ActivateRibbon";
  RichEditClientCommand[RichEditClientCommand["ChangeHeaderOffset"] = 330] = "ChangeHeaderOffset";
  RichEditClientCommand[RichEditClientCommand["ChangeFooterOffset"] = 331] = "ChangeFooterOffset";
  RichEditClientCommand[RichEditClientCommand["Download"] = 332] = "Download";
  RichEditClientCommand[RichEditClientCommand["ShowErrorCantSaveToEmptyPathMessageCommand"] = 333] = "ShowErrorCantSaveToEmptyPathMessageCommand";
  RichEditClientCommand[RichEditClientCommand["RemoveNextWord"] = 334] = "RemoveNextWord";
  RichEditClientCommand[RichEditClientCommand["RemovePrevWord"] = 335] = "RemovePrevWord";
  RichEditClientCommand[RichEditClientCommand["GoToStartNextPage"] = 336] = "GoToStartNextPage";
  RichEditClientCommand[RichEditClientCommand["GoToStartPrevPage"] = 337] = "GoToStartPrevPage";
  RichEditClientCommand[RichEditClientCommand["ExtendGoToStartNextPage"] = 338] = "ExtendGoToStartNextPage";
  RichEditClientCommand[RichEditClientCommand["ExtendGoToStartPrevPage"] = 339] = "ExtendGoToStartPrevPage";
  RichEditClientCommand[RichEditClientCommand["SetSectionPageMargins"] = 340] = "SetSectionPageMargins";
  RichEditClientCommand[RichEditClientCommand["AssignShortcut"] = 341] = "AssignShortcut";
  RichEditClientCommand[RichEditClientCommand["ShowLayoutOptionsForm"] = 342] = "ShowLayoutOptionsForm";
  RichEditClientCommand[RichEditClientCommand["ChangeFloatingObjectFillColor"] = 343] = "ChangeFloatingObjectFillColor";
  RichEditClientCommand[RichEditClientCommand["ChangeFloatingObjectOutlineColor"] = 344] = "ChangeFloatingObjectOutlineColor";
  RichEditClientCommand[RichEditClientCommand["ChangeFloatingObjectOutlineWidth"] = 345] = "ChangeFloatingObjectOutlineWidth";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectSquareTextWrapType"] = 346] = "SetFloatingObjectSquareTextWrapType";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectTightTextWrapType"] = 347] = "SetFloatingObjectTightTextWrapType";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectThroughTextWrapType"] = 348] = "SetFloatingObjectThroughTextWrapType";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectTopAndBottomTextWrapType"] = 349] = "SetFloatingObjectTopAndBottomTextWrapType";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectBehindTextWrapType"] = 350] = "SetFloatingObjectBehindTextWrapType";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectInFrontOfTextWrapType"] = 351] = "SetFloatingObjectInFrontOfTextWrapType";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectTopLeftAlignment"] = 352] = "SetFloatingObjectTopLeftAlignment";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectTopCenterAlignment"] = 353] = "SetFloatingObjectTopCenterAlignment";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectTopRightAlignment"] = 354] = "SetFloatingObjectTopRightAlignment";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectMiddleLeftAlignment"] = 355] = "SetFloatingObjectMiddleLeftAlignment";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectMiddleCenterAlignment"] = 356] = "SetFloatingObjectMiddleCenterAlignment";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectMiddleRightAlignment"] = 357] = "SetFloatingObjectMiddleRightAlignment";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectBottomLeftAlignment"] = 358] = "SetFloatingObjectBottomLeftAlignment";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectBottomCenterAlignment"] = 359] = "SetFloatingObjectBottomCenterAlignment";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectBottomRightAlignment"] = 360] = "SetFloatingObjectBottomRightAlignment";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectBringForward"] = 361] = "FloatingObjectBringForward";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectBringToFront"] = 362] = "FloatingObjectBringToFront";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectBringInFrontOfText"] = 363] = "FloatingObjectBringInFrontOfText";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectSendBackward"] = 364] = "FloatingObjectSendBackward";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectSendToBack"] = 365] = "FloatingObjectSendToBack";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectSendBehindText"] = 366] = "FloatingObjectSendBehindText";
  RichEditClientCommand[RichEditClientCommand["ContextItem_FloatingObjects"] = 367] = "ContextItem_FloatingObjects";
  RichEditClientCommand[RichEditClientCommand["InsertAnchoredTextBox"] = 368] = "InsertAnchoredTextBox";
  RichEditClientCommand[RichEditClientCommand["ChangeActiveSubDocumentToTextBox"] = 369] = "ChangeActiveSubDocumentToTextBox";
  RichEditClientCommand[RichEditClientCommand["ChangeTextBoxProperties"] = 370] = "ChangeTextBoxProperties";
  RichEditClientCommand[RichEditClientCommand["ChangeFloatingObjectShape"] = 371] = "ChangeFloatingObjectShape";
  RichEditClientCommand[RichEditClientCommand["CreateEmptyMergeField"] = 372] = "CreateEmptyMergeField";
  RichEditClientCommand[RichEditClientCommand["CreateEmptyDocVariableField"] = 373] = "CreateEmptyDocVariableField";
  RichEditClientCommand[RichEditClientCommand["SetFloatingObjectInlineTextWrapType"] = 374] = "SetFloatingObjectInlineTextWrapType";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectDragDropChangePosition"] = 375] = "FloatingObjectDragDropChangePosition";
  RichEditClientCommand[RichEditClientCommand["InsertHtml"] = 376] = "InsertHtml";
  RichEditClientCommand[RichEditClientCommand["CreateTocField"] = 377] = "CreateTocField";
  RichEditClientCommand[RichEditClientCommand["CreateTableOfEquationsField"] = 378] = "CreateTableOfEquationsField";
  RichEditClientCommand[RichEditClientCommand["CreateTableOfFiguresField"] = 379] = "CreateTableOfFiguresField";
  RichEditClientCommand[RichEditClientCommand["CreateTableOfTablesField"] = 380] = "CreateTableOfTablesField";
  RichEditClientCommand[RichEditClientCommand["CreateEquationCaptionField"] = 381] = "CreateEquationCaptionField";
  RichEditClientCommand[RichEditClientCommand["CreateFigureCaptionField"] = 382] = "CreateFigureCaptionField";
  RichEditClientCommand[RichEditClientCommand["CreateTableCaptionField"] = 383] = "CreateTableCaptionField";
  RichEditClientCommand[RichEditClientCommand["UpdateTableOfContents"] = 384] = "UpdateTableOfContents";
  RichEditClientCommand[RichEditClientCommand["SetParagraphBodyTextLevel"] = 385] = "SetParagraphBodyTextLevel";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading1Level"] = 386] = "SetParagraphHeading1Level";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading2Level"] = 387] = "SetParagraphHeading2Level";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading3Level"] = 388] = "SetParagraphHeading3Level";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading4Level"] = 389] = "SetParagraphHeading4Level";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading5Level"] = 390] = "SetParagraphHeading5Level";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading6Level"] = 391] = "SetParagraphHeading6Level";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading7Level"] = 392] = "SetParagraphHeading7Level";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading8Level"] = 393] = "SetParagraphHeading8Level";
  RichEditClientCommand[RichEditClientCommand["SetParagraphHeading9Level"] = 394] = "SetParagraphHeading9Level";
  RichEditClientCommand[RichEditClientCommand["ChangeHeadingLevel"] = 395] = "ChangeHeadingLevel";
  RichEditClientCommand[RichEditClientCommand["SetParagraphLevel"] = 396] = "SetParagraphLevel";
  RichEditClientCommand[RichEditClientCommand["CreateFieldWithCode"] = 397] = "CreateFieldWithCode";
  RichEditClientCommand[RichEditClientCommand["ChangeHyperlink"] = 398] = "ChangeHyperlink";
  RichEditClientCommand[RichEditClientCommand["SetAutoFitContents"] = 399] = "SetAutoFitContents";
  RichEditClientCommand[RichEditClientCommand["SetAutoFitWindow"] = 400] = "SetAutoFitWindow";
  RichEditClientCommand[RichEditClientCommand["SetFixedColumnWidth"] = 401] = "SetFixedColumnWidth";
  RichEditClientCommand[RichEditClientCommand["InsertRtf"] = 402] = "InsertRtf";
  RichEditClientCommand[RichEditClientCommand["GetRtf"] = 403] = "GetRtf";
  RichEditClientCommand[RichEditClientCommand["ForceSyncWithServer"] = 404] = "ForceSyncWithServer";
  RichEditClientCommand[RichEditClientCommand["SwitchToSimpleView"] = 405] = "SwitchToSimpleView";
  RichEditClientCommand[RichEditClientCommand["SwitchToPrintLayoutView"] = 406] = "SwitchToPrintLayoutView";
  RichEditClientCommand[RichEditClientCommand["ChangeViewType"] = 407] = "ChangeViewType";
  RichEditClientCommand[RichEditClientCommand["InsertContentFromServer"] = 408] = "InsertContentFromServer";
  RichEditClientCommand[RichEditClientCommand["ShowInsertContentFromServerErrorDialogCommand"] = 409] = "ShowInsertContentFromServerErrorDialogCommand";
  RichEditClientCommand[RichEditClientCommand["OpenDocumentLocally"] = 410] = "OpenDocumentLocally";
  RichEditClientCommand[RichEditClientCommand["InsertPictureLocally"] = 411] = "InsertPictureLocally";
  RichEditClientCommand[RichEditClientCommand["CreateNewDocumentLocally"] = 412] = "CreateNewDocumentLocally";
  RichEditClientCommand[RichEditClientCommand["ExportDocument"] = 413] = "ExportDocument";
  RichEditClientCommand[RichEditClientCommand["DownloadDocumentLocally"] = 414] = "DownloadDocumentLocally";
  RichEditClientCommand[RichEditClientCommand["ShowErrorLoadPictureMessage"] = 415] = "ShowErrorLoadPictureMessage";
  RichEditClientCommand[RichEditClientCommand["PrintDocumentOnClient"] = 416] = "PrintDocumentOnClient";
  RichEditClientCommand[RichEditClientCommand["MailMergeOnClient"] = 417] = "MailMergeOnClient";
  RichEditClientCommand[RichEditClientCommand["DownloadDocx"] = 418] = "DownloadDocx";
  RichEditClientCommand[RichEditClientCommand["DownloadRtf"] = 419] = "DownloadRtf";
  RichEditClientCommand[RichEditClientCommand["DownloadTxt"] = 420] = "DownloadTxt";
  RichEditClientCommand[RichEditClientCommand["DownloadHtml"] = 421] = "DownloadHtml";
  RichEditClientCommand[RichEditClientCommand["PdfExported"] = 430] = "PdfExported";
  RichEditClientCommand[RichEditClientCommand["ChangeCaseMenu"] = 431] = "ChangeCaseMenu";
  RichEditClientCommand[RichEditClientCommand["AlignParagraphMenu"] = 432] = "AlignParagraphMenu";
  RichEditClientCommand[RichEditClientCommand["LineSpacingMenu"] = 433] = "LineSpacingMenu";
  RichEditClientCommand[RichEditClientCommand["MarginsMenu"] = 434] = "MarginsMenu";
  RichEditClientCommand[RichEditClientCommand["OrientationMenu"] = 435] = "OrientationMenu";
  RichEditClientCommand[RichEditClientCommand["SizeMenu"] = 436] = "SizeMenu";
  RichEditClientCommand[RichEditClientCommand["ColumnsMenu"] = 437] = "ColumnsMenu";
  RichEditClientCommand[RichEditClientCommand["BreaksMenu"] = 438] = "BreaksMenu";
  RichEditClientCommand[RichEditClientCommand["AddTextMenu"] = 439] = "AddTextMenu";
  RichEditClientCommand[RichEditClientCommand["InsertCaptionMenu"] = 440] = "InsertCaptionMenu";
  RichEditClientCommand[RichEditClientCommand["InsertTableOfFiguresMenu"] = 441] = "InsertTableOfFiguresMenu";
  RichEditClientCommand[RichEditClientCommand["CreateFieldMenu"] = 442] = "CreateFieldMenu";
  RichEditClientCommand[RichEditClientCommand["TableStyleOptionsMenu"] = 443] = "TableStyleOptionsMenu";
  RichEditClientCommand[RichEditClientCommand["BordersMenu"] = 444] = "BordersMenu";
  RichEditClientCommand[RichEditClientCommand["SelectMenu"] = 445] = "SelectMenu";
  RichEditClientCommand[RichEditClientCommand["DeleteMenu"] = 446] = "DeleteMenu";
  RichEditClientCommand[RichEditClientCommand["InsertMenu"] = 447] = "InsertMenu";
  RichEditClientCommand[RichEditClientCommand["AutoFitMenu"] = 448] = "AutoFitMenu";
  RichEditClientCommand[RichEditClientCommand["AlignmentMenu"] = 449] = "AlignmentMenu";
  RichEditClientCommand[RichEditClientCommand["WrapTextMenu"] = 450] = "WrapTextMenu";
  RichEditClientCommand[RichEditClientCommand["PositionMenu"] = 451] = "PositionMenu";
  RichEditClientCommand[RichEditClientCommand["BringForwardMenu"] = 452] = "BringForwardMenu";
  RichEditClientCommand[RichEditClientCommand["SendBackwardMenu"] = 453] = "SendBackwardMenu";
  RichEditClientCommand[RichEditClientCommand["ChangeFloatingObjectTextWrapTypeMenu"] = 454] = "ChangeFloatingObjectTextWrapTypeMenu";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectBringForwardMenu"] = 455] = "FloatingObjectBringForwardMenu";
  RichEditClientCommand[RichEditClientCommand["FloatingObjectSendBackwardMenu"] = 456] = "FloatingObjectSendBackwardMenu";
  RichEditClientCommand[RichEditClientCommand["NoSpellingSuggestions"] = 457] = "NoSpellingSuggestions";
  RichEditClientCommand[RichEditClientCommand["GetHtml"] = 458] = "GetHtml";
  RichEditClientCommand[RichEditClientCommand["CreateEmptyIfField"] = 459] = "CreateEmptyIfField";
})(RichEditClientCommand || (RichEditClientCommand = {}));