import { SubDocumentApi } from '../model-api/sub-document';
import { BookmarkCollectionClientApi } from './collections/bookmark-collection';
export class SubDocumentClientApi extends SubDocumentApi {
  constructor(core, subDocument) {
    super(core, subDocument);
    this._core = core;
  }
  get bookmarks() {
    return new BookmarkCollectionClientApi(this._core, this._subDocument);
  }
}