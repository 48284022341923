import { LayoutPositionCreatorConflictFlags, LayoutPositionMainSubDocumentCreator, LayoutPositionOtherSubDocumentCreator } from '../../layout-engine/layout-position-creator';
import { DocumentLayoutDetailsLevel } from '../../layout/document-layout-details-level';
import { LayoutWordBounds } from '../../word-bounds-engine/layout-word-bounds';
import { Browser } from '@devexpress/utils/lib/browser';
import { FixedInterval } from '@devexpress/utils/lib/intervals/fixed';
import { ManipulatorHandlerStateBase } from '../base/manipulator-handler-state-base';
import { SubDocumentPosition } from '../../../common/model/sub-document';
import { LayoutBoxType } from '../../../common/layout/main-structures/layout-boxes/layout-box';
export class TouchHandlerStateBase extends ManipulatorHandlerStateBase {
  constructor() {
    super(...arguments);
    this.TOUCH_SCROLL_SENSITIVITY_IN_ROWS = 2;
  }
  dispose() {
    super.dispose();
    clearTimeout(this.popupMenuId);
  }
  onTouchStart(_evt) {}
  onDoubleTap(_evt) {}
  onTouchEnd(_evt) {}
  onTouchMove(_evt) {
    return true;
  }
  captureInputFocus(_evt) {
    this.handler.control.focusManager.captureFocus();
  }
  showPopupMenu() {
    this.popupMenuId = setTimeout(() => this.handler.control.popupMenuManager.showByTouchClick(), 20);
  }
  setEditableDocumentContent() {
    if (!Browser.IE && !Browser.Edge) {
      var subDocument = this.handler.control.selection.activeSubDocument;
      let position = this.handler.control.selection.lastSelectedInterval.start;
      let startInterval = LayoutWordBounds.getLayoutWordStartBound(this.handler.control.layout, subDocument, this.handler.control.selection, position);
      let startIntervalLayoutPosition = this.createLayoutPosition(new SubDocumentPosition(subDocument, startInterval), DocumentLayoutDetailsLevel.Box);
      if (startIntervalLayoutPosition.isLastBoxInRow()) {
        const prevPosition = this.createLayoutPosition(new SubDocumentPosition(subDocument, position - 1), DocumentLayoutDetailsLevel.Box);
        if (prevPosition.detailsLevel !== DocumentLayoutDetailsLevel.Box || prevPosition.box.getType() !== LayoutBoxType.PageBreak) startInterval = LayoutWordBounds.getLayoutWordStartBound(this.handler.control.layout, subDocument, this.handler.control.selection, position - 1);
      }
      let endInterval = LayoutWordBounds.getLayoutWordEndBound(this.handler.control.layout, subDocument, this.handler.control.selection, startInterval, false);
      let textUnderCursor = position >= startInterval && position <= endInterval ? subDocument.getText(new FixedInterval(startInterval, endInterval - startInterval)) : "";
      this.handler.control.inputController.setEditableDocumentContent(textUnderCursor);
      if (textUnderCursor.length) this.handler.control.inputController.setEditableDocumentCursorPosition(position - startInterval);
    }
  }
  setLastLayoutPosition() {
    var subDocument = this.handler.control.selection.activeSubDocument;
    var logPosition = this.handler.control.selection.lastSelectedInterval.start;
    this.lastLayoutPosition = this.createLayoutPosition(new SubDocumentPosition(subDocument, logPosition), DocumentLayoutDetailsLevel.Character, true);
  }
  createLayoutPosition(subDocPos, detailsLevel, useNextBoxBounds = false) {
    const subDocument = subDocPos.subDocument;
    const position = subDocPos.position;
    const endRowConflictFlags = new LayoutPositionCreatorConflictFlags().setDefault(this.handler.control.selection.endOfLine);
    const middleRowConflictFlags = new LayoutPositionCreatorConflictFlags().setDefault(useNextBoxBounds);
    return (subDocument.isMain() ? new LayoutPositionMainSubDocumentCreator(this.handler.control.layout, subDocument, position, detailsLevel) : new LayoutPositionOtherSubDocumentCreator(this.handler.control.layout, subDocument, position, this.handler.control.selection.pageIndex, detailsLevel)).create(endRowConflictFlags, middleRowConflictFlags);
  }
}
export class TouchHandlerBeginDragHelperState extends TouchHandlerStateBase {
  constructor(handler, dragState) {
    super(handler);
    this.dragState = dragState;
  }
  start() {
    var _a;
    (_a = this.handler.control.barHolder.ribbon) === null || _a === void 0 ? void 0 : _a.beginUpdate();
    this.handler.control.horizontalRulerControl.beginUpdate();
  }
  finish() {
    var _a;
    (_a = this.handler.control.barHolder.ribbon) === null || _a === void 0 ? void 0 : _a.endUpdate();
    this.handler.control.horizontalRulerControl.endUpdate();
    this.handler.control.barHolder.updateItemsState();
    this.handler.control.horizontalRulerControl.update();
  }
  onTouchMove(evt) {
    this.handler.switchState(this.dragState);
    this.dragState.onTouchMove(evt);
    return false;
  }
  onTouchEnd(evt) {
    this.handler.switchToDefaultState();
    this.handler.onTouchEnd(evt);
  }
}