export const RendererClassNames = {
  TOP_LEVEL_SERVICE_CONTAINER: "dxre-servicecontainer",
  SERVICE_CONTAINER: "dxrePageService",
  SELECTION_CONTAINER: "dxreSelection",
  SEARCH_SELECTION_CONTAINER: "dxreSearchSelection",
  MISSPELLED_SELECTION_CONTAINER: "dxreMisspelledSelection",
  RANGE_PERMISSIONS_CONTAINER: "dxreRangePermissions",
  MAIN_PAGE_AREA_CONTAINER: "dxreMainPa",
  OTHER_PAGE_AREA_CONTAINER: "dxreOtherPa",
  FLOATING_OBJECTS_CONTAINER: "dxreFloatingPic",
  SHAPE_BG_CONTAINER: "dxreShapeBgContainer",
  PAGES: "dxrePages",
  PAGE_AREA: "dxrePageArea",
  TEXT_BOX_BG: "dxreTextBoxBg",
  ROWS_CONTAINER: "dxreRows",
  PARAGRAPHFRAMES_CONTAINER: "dxreParFrames",
  TABLES_CONTAINER: "dxreTables",
  TABLE_ELEMENTS_CONTAINER: "dxreTable",
  TABLE_BORDERS_CONTAINER: "dxreTableBrds",
  TABLE_ROW_BACKGROUND_CONTAINER: "dxreTableRowBgds",
  TABLE_CELL_BACKGROUND_CONTAINER: "dxreTableCellBgds",
  TABLE_CURSORS_CONTAINER: "dxreTableCursors",
  ANCHORED_PICTURE: "dxreAncPic",
  SIMPLE_VIEW: "dxreSimpleView",
  PAGE: "dxrePage",
  SIMPLE_PAGE_FLOW_MODE: "dxreSimplePage",
  COLUMN: "dxreColumn",
  ROW: "dxreRow",
  BLACKOUT: "dxreBlackout",
  TABLE_CELL_BG: "dxreTableCellBg",
  TABLE_ROW_BG: "dxreTableRowBg",
  PARAGRAPH_FRAME: "dxreParFrame",
  BOX_BG: "dxreBoxBg",
  FIELD_BG: "dxreFieldBg",
  FIELD_BOX_LEVEL1: "dxreFieldBoxLevel1",
  FIELD_BOX_LEVEL2: "dxreFieldBoxLevel2",
  FIELD_BOX_LEVEL3: "dxreFieldBoxLevel3",
  BOX_SPACE: "dxreBoxSpace",
  BOX: "dxreBox",
  HIDDEN_BOX: "dxreHiddenBox",
  PICTURE: "dxrePic",
  START_BOOKMARK: "dxreStartBookmark",
  END_BOOKMARK: "dxreEndBookmark",
  TABLE_BORDER: "dxreTableBrd",
  SELECTION_RANGE_PERMISSION: "dxreSelRangePerm",
  SELECTION_MISSPELLED: "dxreSelMisspelled",
  SELECTION_SEARCH: "dxreSelSearch",
  SELECTION_ROW: "dxreSelRow",
  SELECTION_CURSOR: "dxreSelCursor",
  SELECTION_FLOATING_OBJECTS: "dxreSelFloatingObjects",
  ANCHOR: "dxreAnchor",
  TABLE_ROW_CURSOR: "dxreTableRowCursor",
  TABLE_BORDER_LINE: "dxreTableBorderLine",
  DRAG_CARET: "dxreDragCaret",
  HEADER_INFO: "dxreHeaderInfo",
  FOOTER_INFO: "dxreFooterInfo",
  FULL_TABLE_SELECTOR: "dxreTblSelector",
  AUTO_SCROLL: "dxreAutoScroll",
  AUTO_SCROLL_CURSOR: "dxreAutoScrollCursor",
  AUTO_SCROLL_CURSOR_N: "dxreAutoScrollCursorN",
  AUTO_SCROLL_CURSOR_NS: "dxreAutoScrollCursorNS",
  AUTO_SCROLL_CURSOR_S: "dxreAutoScrollCursorS",
  FLOATING_OBJECT_TEXT_BOX_AREA: "dxreTextBoxArea",
  TABLE_COLUMN_CURSOR: "dxreTableColumnCursor",
  SELECTED_TEXTBOX: "dxreSelectedTextBox",
  CURSOR_NO_BLINK_CLASS_NAME: "dxreSelNoblink"
};