import { NoteProperties } from "../../../../../common/model/footnotes/footnote";
import { TranslationTables } from "../../translation-table/translation-tables";
import { WriterHelper } from "../utils/writer-helper";
import { BaseExporter } from "./base";
import { DocxNsType } from "../../utils/constants";
export class NotePropertiesExporter extends BaseExporter {
  exportSectionLevelFootNoteProperties(properties) {
    if (!this.isDefault(properties)) {
      this.writer.writeWpStartElement('footnotePr');
      this.exportNoteProperties(properties);
      this.writer.endElement();
    }
  }
  exportSectionLevelEndNoteProperties(properties) {
    if (!this.isDefault(properties)) {
      this.writer.writeWpStartElement('endnotePr');
      this.exportNoteProperties(properties);
      this.writer.endElement();
    }
  }
  exportDocumentLevelFootNoteProperties(properties) {
    if (!this.isDefault(properties) || this.data.footNotes.size > 0) {
      this.writer.writeWpStartElement('footnotePr');
      this.exportNoteProperties(properties);
      this.exportFootNoteSeparators();
      this.writer.endElement();
    }
  }
  exportDocumentLevelEndNoteProperties(properties) {
    if (!this.isDefault(properties) || this.data.endNotes.size > 0) {
      this.writer.writeWpStartElement('endnotePr');
      this.exportNoteProperties(properties);
      this.exportEndNoteSeparators();
      this.writer.endElement();
    }
  }
  exportSectionFootNoteColumnCount(properties) {
    if (properties && properties.columnCount !== NotePropertiesExporter.defaultProperties.columnCount) {
      this.writer.elementStartNS(DocxNsType.w15, 'footnoteColumns');
      this.writer.writeWpIntAttr('val', properties.columnCount);
      this.writer.endElement();
    }
  }
  isDefault(properties) {
    return !properties || properties.equals(NotePropertiesExporter.defaultProperties);
  }
  exportNoteProperties(properties) {
    const defaultPosition = NotePropertiesExporter.defaultProperties.position;
    if (properties.position != defaultPosition) this.writer.writeWpStringValue("pos", WriterHelper.getValueFromTables(TranslationTables.footNotePlacementTable, properties.position, defaultPosition));
    if (properties.startingNumber != NotePropertiesExporter.defaultProperties.startingNumber) this.writer.writeWpIntValue("numStart", properties.startingNumber);
    const defaultNumberingFormat = NotePropertiesExporter.defaultProperties.numberingFormat;
    if (properties.numberingFormat != defaultNumberingFormat) this.writer.writeWpStringValue("numFmt", WriterHelper.getValueFromTables(TranslationTables.pageNumberingFormatTable, properties.numberingFormat, defaultNumberingFormat));
    const defaultNumberingRestartType = NotePropertiesExporter.defaultProperties.numberingRestartType;
    if (properties.numberingRestartType != defaultNumberingRestartType) this.writer.writeWpStringValue("numRestart", WriterHelper.getValueFromTables(TranslationTables.noteNumberingRestartTypeTable, properties.numberingRestartType, defaultNumberingRestartType));
  }
  exportFootNoteSeparators() {
    this.exportNoteSeparators(this.data.model.footNoteSeparators, this.exportFootNoteSeparator.bind(this));
  }
  exportEndNoteSeparators() {
    this.exportNoteSeparators(this.data.model.endNoteSeparators, this.exportEndNoteSeparator.bind(this));
  }
  exportFootNoteSeparator(separator) {
    this.exportNoteSeparator('footnote', separator, this.data.footNotes);
  }
  exportEndNoteSeparator(separator) {
    this.exportNoteSeparator('endnote', separator, this.data.endNotes);
  }
  exportNoteSeparators(separators, exporter) {
    for (const separator of separators.getSeparatos()) exporter(separator);
  }
  exportNoteSeparator(tag, note, notes) {
    const id = notes.get(note);
    this.writer.writeWpStartElement(tag);
    this.writer.writeWpIntAttr('id', id);
    this.writer.endElement();
  }
}
NotePropertiesExporter.defaultProperties = NoteProperties.createDefault();