import { CommandBase } from '../../common/commands/command-base';
import { SimpleCommandState } from '../../common/commands/command-states';
import { DocumentInfo } from '../../common/rich-edit-core';
import { DocumentFormat } from '../../common/document-format';
import { FileNameHelper } from '../../common/formats/file-name-helper';
import { ControlOptions, DocumentCapability } from '../../common/model/options/control';
import { FileUtils } from '@devexpress/utils/lib/utils/file';
import { StringUtils } from '@devexpress/utils/lib/utils/string';
import { exportModelToBlob } from '../model-api/formats/exporter';
export class DownloadDocumentCommand extends CommandBase {
  getState() {
    var state = new SimpleCommandState(this.isEnabled());
    state.visible = this.control.modelManager.richOptions.control.save !== DocumentCapability.Hidden;
    return state;
  }
  isEnabledInReadOnlyMode() {
    return true;
  }
  executeCore(_state, options) {
    const format = options.param && options.param.documentFormat !== undefined && options.param.documentFormat !== null ? options.param.documentFormat : DocumentFormat.OpenXml;
    this.download(this.getFileName(options), format);
    return true;
  }
  download(fileName, documentFormat) {
    const core = this.control;
    if (core.fileDownloaded) return;
    if (fileName === "") fileName = DocumentInfo.defaultDocumentName;
    const {
      documentExtension,
      documentFormat: currentDocumentFormat
    } = this.control.documentInfo;
    const keepExtension = !StringUtils.isNullOrEmpty(documentExtension) && currentDocumentFormat === documentFormat;
    const extension = keepExtension ? documentExtension : FileNameHelper.convertToString(documentFormat);
    const finalFileName = fileName + extension;
    const exportModelOptions = this.control.getExportModelOptions({
      documentFormat
    });
    exportModelToBlob(exportModelOptions, blob => FileUtils.startDownloadFileLocal(blob, finalFileName));
    core.fileDownloaded = true;
    setTimeout(() => {
      core.fileDownloaded = false;
    }, 1);
  }
  isEnabled() {
    return super.isEnabled() && ControlOptions.isEnabled(this.control.modelManager.richOptions.control.save);
  }
  canModify() {
    return true;
  }
  getFileName(options) {
    return this.control.documentInfo.getFileNameForDownload(options.param ? options.param.fileName : null);
  }
}
export class DownloadDocxCommand extends DownloadDocumentCommand {
  executeCore(_state, options) {
    this.download(this.getFileName(options), DocumentFormat.OpenXml);
    return true;
  }
}
export class DownloadRtfCommand extends DownloadDocumentCommand {
  executeCore(_state, options) {
    this.download(this.getFileName(options), DocumentFormat.Rtf);
    return true;
  }
}
export class DownloadTxtCommand extends DownloadDocumentCommand {
  executeCore(_state, options) {
    this.download(this.getFileName(options), DocumentFormat.PlainText);
    return true;
  }
}
export class DownloadHtmlCommand extends DownloadDocumentCommand {
  executeCore(_state, options) {
    this.download(this.getFileName(options), DocumentFormat.Html);
    return true;
  }
}
export class DownloadDocumentParameters {
  constructor(documentFormat, fileName = "") {
    this.documentFormat = documentFormat;
    this.fileName = fileName;
  }
}