export class StringResources {
  constructor() {
    this.headerFooter = new HeaderFooterStringResources();
    this.seqCaptionPrefixes = new SeqCaptionPrefixes();
    this.commonLabels = new CommonLabels();
    this.quickSearchPanel = new QuickSearchPanelStringResources();
  }
}
export class HeaderFooterStringResources {}
export class SeqCaptionPrefixes {}
export class CommonLabels {}
export class QuickSearchPanelStringResources {}