export class RulerTemplateManager {
  static getLeftIndentTopElementTemplate() {
    return null;
  }
  static getLeftIndentBodyElementTemplate() {
    return null;
  }
  static getRightIndentElementTemplate() {
    return null;
  }
  static getFirstLineIndentElementTemplate() {
    return null;
  }
  static getLeftTabElementTemplate() {
    return null;
  }
  static getCenterTabElementTemplate() {
    return null;
  }
  static getRightTabElementTemplate() {
    return null;
  }
  static getDecimalTabElementTemplate() {
    return null;
  }
  static getTableColumnDragElementTemplate() {
    return null;
  }
}