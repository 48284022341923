import { DocumentFormat } from '../../common/document-format';
import { FormatterManager } from '../../common/layout-formatter/managers/formatter-manager';
import { AnchorObjectsPositionInfo, DocumentLayout } from '../../common/layout/document-layout';
import { Measurer } from '../../common/measurer/measurer';
import { InnerClientProperties } from '../../common/rich-utils/inner-client-properties';
import { SelectionBase } from '../../common/selection/selection-base';
import { StringResources } from '../../common/string-resources';
import { ViewSettings } from '../../common/view-settings/views-settings';
import { Base64Utils } from '@devexpress/utils/lib/utils/base64';
import { exportModelToBase64, exportModelToBlob } from '../model-api/formats/exporter';
import { createImporter } from '../model-api/formats/importer';
import { isDefined } from '@devexpress/utils/lib/utils/common';
export class DocumentProcessorBase {
  constructor(richOptions, documentRenderer, uiUnitConverter, sessionGuid, clientGuid) {
    this.selection = new SelectionBase();
    this.onCalculateDocumentVariable = null;
    this.measurer = new Measurer('');
    this.modelManager = this.createModelManager(null, richOptions);
    this.innerClientProperties = new InnerClientProperties(new ViewSettings());
    this.documentRenderer = documentRenderer;
    this.uiUnitConverter = uiUnitConverter;
    this.sessionGuid = sessionGuid;
    this.clientGuid = clientGuid;
  }
  dispose() {
    this.measurer.dispose();
    if (this.layoutFormatterManager) this.layoutFormatterManager.dispose();
  }
  openDocument(source, documentFormat, callback) {
    const file = typeof source == 'string' ? Base64Utils.getFileFromBase64(source) : source;
    this.openDocumentInner(file, documentFormat, callback);
  }
  openInnerDocument(model) {
    this.createModel(model);
  }
  exportDocumentToBase64(callback, documentFormat) {
    exportModelToBase64(this.getExportModelOptions({
      documentFormat
    }), callback);
  }
  exportDocumentToBlob(callback, documentFormat) {
    exportModelToBlob(this.getExportModelOptions({
      documentFormat
    }), callback);
  }
  openDocumentInner(file, format, callback) {
    if (this.activeDocumentImporter) {
      callback(false);
      return;
    }
    this.activeDocumentImporter = createImporter(format);
    if (!this.activeDocumentImporter) {
      callback(false);
      return;
    }
    this.activeDocumentImporter.importFromFile(file, this.modelManager.richOptions, (model, formatImagesImporter) => {
      this.activeDocumentImporter = null;
      this.createModel(model);
      this.importImages(formatImagesImporter);
      callback(true);
    }, _reason => {
      this.activeDocumentImporter = null;
      callback(false);
    });
  }
  importImages(formatImagesImporter) {
    formatImagesImporter.import(this.modelManager.modelManipulator);
  }
  createModel(model) {
    this.modelManager = this.createModelManager(model, this.modelManager.richOptions);
    const layout = new DocumentLayout(new AnchorObjectsPositionInfo(model));
    layout.pageColor = model.getActualPageBackgroundColor();
    this.layoutFormatterManager = new FormatterManager(this.measurer, this.innerClientProperties, this.modelManager.model, layout, {
      activeSubDocument: model.mainSubDocument
    }, this.modelManager.richOptions.bookmarks, this.modelManager.richOptions.documentProtection, {
      getVisibleAreaHeight(_includeScrollBars) {
        return 500;
      }
    }, new StringResources(), []);
    this.layoutFormatterManager.openDocument();
  }
  beginUpdate() {
    this.layoutFormatterManager.beginUpdate();
  }
  endUpdate() {
    this.layoutFormatterManager.endUpdate();
  }
  invalidateLayoutAfterFontsLoaded() {
    if (this.measurer && this.layoutFormatterManager && this.layoutFormatterManager.invalidator) {
      this.measurer.clearCache();
      this.layoutFormatterManager.invalidator.onChangedAllLayout();
    }
  }
  getExportModelOptions(initOptions = {}) {
    return {
      modelManager: isDefined(initOptions.modelManager) ? initOptions.modelManager : this.modelManager,
      pictureRenderer: this.documentRenderer,
      uiUnitConverter: this.uiUnitConverter,
      sessionGuid: this.sessionGuid,
      clientGuid: this.clientGuid,
      documentFormat: isDefined(initOptions.documentFormat) ? initOptions.documentFormat : DocumentFormat.OpenXml
    };
  }
}