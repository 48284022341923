export var BorderLineStyle;
(function (BorderLineStyle) {
  BorderLineStyle[BorderLineStyle["Nil"] = -1] = "Nil";
  BorderLineStyle[BorderLineStyle["None"] = 0] = "None";
  BorderLineStyle[BorderLineStyle["Single"] = 1] = "Single";
  BorderLineStyle[BorderLineStyle["Thick"] = 2] = "Thick";
  BorderLineStyle[BorderLineStyle["Double"] = 3] = "Double";
  BorderLineStyle[BorderLineStyle["Dotted"] = 4] = "Dotted";
  BorderLineStyle[BorderLineStyle["Dashed"] = 5] = "Dashed";
  BorderLineStyle[BorderLineStyle["DotDash"] = 6] = "DotDash";
  BorderLineStyle[BorderLineStyle["DotDotDash"] = 7] = "DotDotDash";
  BorderLineStyle[BorderLineStyle["Triple"] = 8] = "Triple";
  BorderLineStyle[BorderLineStyle["ThinThickSmallGap"] = 9] = "ThinThickSmallGap";
  BorderLineStyle[BorderLineStyle["ThickThinSmallGap"] = 10] = "ThickThinSmallGap";
  BorderLineStyle[BorderLineStyle["ThinThickThinSmallGap"] = 11] = "ThinThickThinSmallGap";
  BorderLineStyle[BorderLineStyle["ThinThickMediumGap"] = 12] = "ThinThickMediumGap";
  BorderLineStyle[BorderLineStyle["ThickThinMediumGap"] = 13] = "ThickThinMediumGap";
  BorderLineStyle[BorderLineStyle["ThinThickThinMediumGap"] = 14] = "ThinThickThinMediumGap";
  BorderLineStyle[BorderLineStyle["ThinThickLargeGap"] = 15] = "ThinThickLargeGap";
  BorderLineStyle[BorderLineStyle["ThickThinLargeGap"] = 16] = "ThickThinLargeGap";
  BorderLineStyle[BorderLineStyle["ThinThickThinLargeGap"] = 17] = "ThinThickThinLargeGap";
  BorderLineStyle[BorderLineStyle["Wave"] = 18] = "Wave";
  BorderLineStyle[BorderLineStyle["DoubleWave"] = 19] = "DoubleWave";
  BorderLineStyle[BorderLineStyle["DashSmallGap"] = 20] = "DashSmallGap";
  BorderLineStyle[BorderLineStyle["DashDotStroked"] = 21] = "DashDotStroked";
  BorderLineStyle[BorderLineStyle["ThreeDEmboss"] = 22] = "ThreeDEmboss";
  BorderLineStyle[BorderLineStyle["ThreeDEngrave"] = 23] = "ThreeDEngrave";
  BorderLineStyle[BorderLineStyle["Outset"] = 24] = "Outset";
  BorderLineStyle[BorderLineStyle["Inset"] = 25] = "Inset";
  BorderLineStyle[BorderLineStyle["Apples"] = 26] = "Apples";
  BorderLineStyle[BorderLineStyle["ArchedScallops"] = 27] = "ArchedScallops";
  BorderLineStyle[BorderLineStyle["BabyPacifier"] = 28] = "BabyPacifier";
  BorderLineStyle[BorderLineStyle["BabyRattle"] = 29] = "BabyRattle";
  BorderLineStyle[BorderLineStyle["Balloons3Colors"] = 30] = "Balloons3Colors";
  BorderLineStyle[BorderLineStyle["BalloonsHotAir"] = 31] = "BalloonsHotAir";
  BorderLineStyle[BorderLineStyle["BasicBlackDashes"] = 32] = "BasicBlackDashes";
  BorderLineStyle[BorderLineStyle["BasicBlackDots"] = 33] = "BasicBlackDots";
  BorderLineStyle[BorderLineStyle["BasicBlackSquares"] = 34] = "BasicBlackSquares";
  BorderLineStyle[BorderLineStyle["BasicThinLines"] = 35] = "BasicThinLines";
  BorderLineStyle[BorderLineStyle["BasicWhiteDashes"] = 36] = "BasicWhiteDashes";
  BorderLineStyle[BorderLineStyle["BasicWhiteDots"] = 37] = "BasicWhiteDots";
  BorderLineStyle[BorderLineStyle["BasicWhiteSquares"] = 38] = "BasicWhiteSquares";
  BorderLineStyle[BorderLineStyle["BasicWideInline"] = 39] = "BasicWideInline";
  BorderLineStyle[BorderLineStyle["BasicWideMidline"] = 40] = "BasicWideMidline";
  BorderLineStyle[BorderLineStyle["BasicWideOutline"] = 41] = "BasicWideOutline";
  BorderLineStyle[BorderLineStyle["Bats"] = 42] = "Bats";
  BorderLineStyle[BorderLineStyle["Birds"] = 43] = "Birds";
  BorderLineStyle[BorderLineStyle["BirdsFlight"] = 44] = "BirdsFlight";
  BorderLineStyle[BorderLineStyle["Cabins"] = 45] = "Cabins";
  BorderLineStyle[BorderLineStyle["CakeSlice"] = 46] = "CakeSlice";
  BorderLineStyle[BorderLineStyle["CandyCorn"] = 47] = "CandyCorn";
  BorderLineStyle[BorderLineStyle["CelticKnotwork"] = 48] = "CelticKnotwork";
  BorderLineStyle[BorderLineStyle["CertificateBanner"] = 49] = "CertificateBanner";
  BorderLineStyle[BorderLineStyle["ChainLink"] = 50] = "ChainLink";
  BorderLineStyle[BorderLineStyle["ChampagneBottle"] = 51] = "ChampagneBottle";
  BorderLineStyle[BorderLineStyle["CheckedBarBlack"] = 52] = "CheckedBarBlack";
  BorderLineStyle[BorderLineStyle["CheckedBarColor"] = 53] = "CheckedBarColor";
  BorderLineStyle[BorderLineStyle["Checkered"] = 54] = "Checkered";
  BorderLineStyle[BorderLineStyle["ChristmasTree"] = 55] = "ChristmasTree";
  BorderLineStyle[BorderLineStyle["CirclesLines"] = 56] = "CirclesLines";
  BorderLineStyle[BorderLineStyle["CirclesRectangles"] = 57] = "CirclesRectangles";
  BorderLineStyle[BorderLineStyle["ClassicalWave"] = 58] = "ClassicalWave";
  BorderLineStyle[BorderLineStyle["Clocks"] = 59] = "Clocks";
  BorderLineStyle[BorderLineStyle["Compass"] = 60] = "Compass";
  BorderLineStyle[BorderLineStyle["Confetti"] = 61] = "Confetti";
  BorderLineStyle[BorderLineStyle["ConfettiGrays"] = 62] = "ConfettiGrays";
  BorderLineStyle[BorderLineStyle["ConfettiOutline"] = 63] = "ConfettiOutline";
  BorderLineStyle[BorderLineStyle["ConfettiStreamers"] = 64] = "ConfettiStreamers";
  BorderLineStyle[BorderLineStyle["ConfettiWhite"] = 65] = "ConfettiWhite";
  BorderLineStyle[BorderLineStyle["CornerTriangles"] = 66] = "CornerTriangles";
  BorderLineStyle[BorderLineStyle["CouponCutoutDashes"] = 67] = "CouponCutoutDashes";
  BorderLineStyle[BorderLineStyle["CouponCutoutDots"] = 68] = "CouponCutoutDots";
  BorderLineStyle[BorderLineStyle["CrazyMaze"] = 69] = "CrazyMaze";
  BorderLineStyle[BorderLineStyle["CreaturesButterfly"] = 70] = "CreaturesButterfly";
  BorderLineStyle[BorderLineStyle["CreaturesFish"] = 71] = "CreaturesFish";
  BorderLineStyle[BorderLineStyle["CreaturesInsects"] = 72] = "CreaturesInsects";
  BorderLineStyle[BorderLineStyle["CreaturesLadyBug"] = 73] = "CreaturesLadyBug";
  BorderLineStyle[BorderLineStyle["CrossStitch"] = 74] = "CrossStitch";
  BorderLineStyle[BorderLineStyle["Cup"] = 75] = "Cup";
  BorderLineStyle[BorderLineStyle["DecoArch"] = 76] = "DecoArch";
  BorderLineStyle[BorderLineStyle["DecoArchColor"] = 77] = "DecoArchColor";
  BorderLineStyle[BorderLineStyle["DecoBlocks"] = 78] = "DecoBlocks";
  BorderLineStyle[BorderLineStyle["DiamondsGray"] = 79] = "DiamondsGray";
  BorderLineStyle[BorderLineStyle["DoubleD"] = 80] = "DoubleD";
  BorderLineStyle[BorderLineStyle["DoubleDiamonds"] = 81] = "DoubleDiamonds";
  BorderLineStyle[BorderLineStyle["Earth1"] = 82] = "Earth1";
  BorderLineStyle[BorderLineStyle["Earth2"] = 83] = "Earth2";
  BorderLineStyle[BorderLineStyle["EclipsingSquares1"] = 84] = "EclipsingSquares1";
  BorderLineStyle[BorderLineStyle["EclipsingSquares2"] = 85] = "EclipsingSquares2";
  BorderLineStyle[BorderLineStyle["EggsBlack"] = 86] = "EggsBlack";
  BorderLineStyle[BorderLineStyle["Fans"] = 87] = "Fans";
  BorderLineStyle[BorderLineStyle["Film"] = 88] = "Film";
  BorderLineStyle[BorderLineStyle["Firecrackers"] = 89] = "Firecrackers";
  BorderLineStyle[BorderLineStyle["FlowersBlockPrint"] = 90] = "FlowersBlockPrint";
  BorderLineStyle[BorderLineStyle["FlowersDaisies"] = 91] = "FlowersDaisies";
  BorderLineStyle[BorderLineStyle["FlowersModern1"] = 92] = "FlowersModern1";
  BorderLineStyle[BorderLineStyle["FlowersModern2"] = 93] = "FlowersModern2";
  BorderLineStyle[BorderLineStyle["FlowersPansy"] = 94] = "FlowersPansy";
  BorderLineStyle[BorderLineStyle["FlowersRedRose"] = 95] = "FlowersRedRose";
  BorderLineStyle[BorderLineStyle["FlowersRoses"] = 96] = "FlowersRoses";
  BorderLineStyle[BorderLineStyle["FlowersTeacup"] = 97] = "FlowersTeacup";
  BorderLineStyle[BorderLineStyle["FlowersTiny"] = 98] = "FlowersTiny";
  BorderLineStyle[BorderLineStyle["Gems"] = 99] = "Gems";
  BorderLineStyle[BorderLineStyle["GingerbreadMan"] = 100] = "GingerbreadMan";
  BorderLineStyle[BorderLineStyle["Gradient"] = 101] = "Gradient";
  BorderLineStyle[BorderLineStyle["Handmade1"] = 102] = "Handmade1";
  BorderLineStyle[BorderLineStyle["Handmade2"] = 103] = "Handmade2";
  BorderLineStyle[BorderLineStyle["HeartBalloon"] = 104] = "HeartBalloon";
  BorderLineStyle[BorderLineStyle["HeartGray"] = 105] = "HeartGray";
  BorderLineStyle[BorderLineStyle["Hearts"] = 106] = "Hearts";
  BorderLineStyle[BorderLineStyle["HeebieJeebies"] = 107] = "HeebieJeebies";
  BorderLineStyle[BorderLineStyle["Holly"] = 108] = "Holly";
  BorderLineStyle[BorderLineStyle["HouseFunky"] = 109] = "HouseFunky";
  BorderLineStyle[BorderLineStyle["Hypnotic"] = 110] = "Hypnotic";
  BorderLineStyle[BorderLineStyle["IceCreamCones"] = 111] = "IceCreamCones";
  BorderLineStyle[BorderLineStyle["LightBulb"] = 112] = "LightBulb";
  BorderLineStyle[BorderLineStyle["Lightning1"] = 113] = "Lightning1";
  BorderLineStyle[BorderLineStyle["Lightning2"] = 114] = "Lightning2";
  BorderLineStyle[BorderLineStyle["MapleLeaf"] = 115] = "MapleLeaf";
  BorderLineStyle[BorderLineStyle["MapleMuffins"] = 116] = "MapleMuffins";
  BorderLineStyle[BorderLineStyle["MapPins"] = 117] = "MapPins";
  BorderLineStyle[BorderLineStyle["Marquee"] = 118] = "Marquee";
  BorderLineStyle[BorderLineStyle["MarqueeToothed"] = 119] = "MarqueeToothed";
  BorderLineStyle[BorderLineStyle["Moons"] = 120] = "Moons";
  BorderLineStyle[BorderLineStyle["Mosaic"] = 121] = "Mosaic";
  BorderLineStyle[BorderLineStyle["MusicNotes"] = 122] = "MusicNotes";
  BorderLineStyle[BorderLineStyle["Northwest"] = 123] = "Northwest";
  BorderLineStyle[BorderLineStyle["Ovals"] = 124] = "Ovals";
  BorderLineStyle[BorderLineStyle["Packages"] = 125] = "Packages";
  BorderLineStyle[BorderLineStyle["PalmsBlack"] = 126] = "PalmsBlack";
  BorderLineStyle[BorderLineStyle["PalmsColor"] = 127] = "PalmsColor";
  BorderLineStyle[BorderLineStyle["PaperClips"] = 128] = "PaperClips";
  BorderLineStyle[BorderLineStyle["Papyrus"] = 129] = "Papyrus";
  BorderLineStyle[BorderLineStyle["PartyFavor"] = 130] = "PartyFavor";
  BorderLineStyle[BorderLineStyle["PartyGlass"] = 131] = "PartyGlass";
  BorderLineStyle[BorderLineStyle["Pencils"] = 132] = "Pencils";
  BorderLineStyle[BorderLineStyle["People"] = 133] = "People";
  BorderLineStyle[BorderLineStyle["PeopleHats"] = 134] = "PeopleHats";
  BorderLineStyle[BorderLineStyle["PeopleWaving"] = 135] = "PeopleWaving";
  BorderLineStyle[BorderLineStyle["Poinsettias"] = 136] = "Poinsettias";
  BorderLineStyle[BorderLineStyle["PostageStamp"] = 137] = "PostageStamp";
  BorderLineStyle[BorderLineStyle["Pumpkin1"] = 138] = "Pumpkin1";
  BorderLineStyle[BorderLineStyle["PushPinNote1"] = 139] = "PushPinNote1";
  BorderLineStyle[BorderLineStyle["PushPinNote2"] = 140] = "PushPinNote2";
  BorderLineStyle[BorderLineStyle["Pyramids"] = 141] = "Pyramids";
  BorderLineStyle[BorderLineStyle["PyramidsAbove"] = 142] = "PyramidsAbove";
  BorderLineStyle[BorderLineStyle["Quadrants"] = 143] = "Quadrants";
  BorderLineStyle[BorderLineStyle["Rings"] = 144] = "Rings";
  BorderLineStyle[BorderLineStyle["Safari"] = 145] = "Safari";
  BorderLineStyle[BorderLineStyle["Sawtooth"] = 146] = "Sawtooth";
  BorderLineStyle[BorderLineStyle["SawtoothGray"] = 147] = "SawtoothGray";
  BorderLineStyle[BorderLineStyle["ScaredCat"] = 148] = "ScaredCat";
  BorderLineStyle[BorderLineStyle["Seattle"] = 149] = "Seattle";
  BorderLineStyle[BorderLineStyle["ShadowedSquares"] = 150] = "ShadowedSquares";
  BorderLineStyle[BorderLineStyle["SharksTeeth"] = 151] = "SharksTeeth";
  BorderLineStyle[BorderLineStyle["ShorebirdTracks"] = 152] = "ShorebirdTracks";
  BorderLineStyle[BorderLineStyle["Skyrocket"] = 153] = "Skyrocket";
  BorderLineStyle[BorderLineStyle["SnowflakeFancy"] = 154] = "SnowflakeFancy";
  BorderLineStyle[BorderLineStyle["Snowflakes"] = 155] = "Snowflakes";
  BorderLineStyle[BorderLineStyle["Sombrero"] = 156] = "Sombrero";
  BorderLineStyle[BorderLineStyle["Southwest"] = 157] = "Southwest";
  BorderLineStyle[BorderLineStyle["Stars"] = 158] = "Stars";
  BorderLineStyle[BorderLineStyle["Stars3d"] = 159] = "Stars3d";
  BorderLineStyle[BorderLineStyle["StarsBlack"] = 160] = "StarsBlack";
  BorderLineStyle[BorderLineStyle["StarsShadowed"] = 161] = "StarsShadowed";
  BorderLineStyle[BorderLineStyle["StarsTop"] = 162] = "StarsTop";
  BorderLineStyle[BorderLineStyle["Sun"] = 163] = "Sun";
  BorderLineStyle[BorderLineStyle["Swirligig"] = 164] = "Swirligig";
  BorderLineStyle[BorderLineStyle["TornPaper"] = 165] = "TornPaper";
  BorderLineStyle[BorderLineStyle["TornPaperBlack"] = 166] = "TornPaperBlack";
  BorderLineStyle[BorderLineStyle["Trees"] = 167] = "Trees";
  BorderLineStyle[BorderLineStyle["TriangleParty"] = 168] = "TriangleParty";
  BorderLineStyle[BorderLineStyle["Triangles"] = 169] = "Triangles";
  BorderLineStyle[BorderLineStyle["Tribal1"] = 170] = "Tribal1";
  BorderLineStyle[BorderLineStyle["Tribal2"] = 171] = "Tribal2";
  BorderLineStyle[BorderLineStyle["Tribal3"] = 172] = "Tribal3";
  BorderLineStyle[BorderLineStyle["Tribal4"] = 173] = "Tribal4";
  BorderLineStyle[BorderLineStyle["Tribal5"] = 174] = "Tribal5";
  BorderLineStyle[BorderLineStyle["Tribal6"] = 175] = "Tribal6";
  BorderLineStyle[BorderLineStyle["TwistedLines1"] = 176] = "TwistedLines1";
  BorderLineStyle[BorderLineStyle["TwistedLines2"] = 177] = "TwistedLines2";
  BorderLineStyle[BorderLineStyle["Vine"] = 178] = "Vine";
  BorderLineStyle[BorderLineStyle["Waveline"] = 179] = "Waveline";
  BorderLineStyle[BorderLineStyle["WeavingAngles"] = 180] = "WeavingAngles";
  BorderLineStyle[BorderLineStyle["WeavingBraid"] = 181] = "WeavingBraid";
  BorderLineStyle[BorderLineStyle["WeavingRibbon"] = 182] = "WeavingRibbon";
  BorderLineStyle[BorderLineStyle["WeavingStrips"] = 183] = "WeavingStrips";
  BorderLineStyle[BorderLineStyle["WhiteFlowers"] = 184] = "WhiteFlowers";
  BorderLineStyle[BorderLineStyle["Woodwork"] = 185] = "Woodwork";
  BorderLineStyle[BorderLineStyle["XIllusions"] = 186] = "XIllusions";
  BorderLineStyle[BorderLineStyle["ZanyTriangles"] = 187] = "ZanyTriangles";
  BorderLineStyle[BorderLineStyle["ZigZag"] = 188] = "ZigZag";
  BorderLineStyle[BorderLineStyle["ZigZagStitch"] = 189] = "ZigZagStitch";
  BorderLineStyle[BorderLineStyle["Disabled"] = 2147483647] = "Disabled";
})(BorderLineStyle || (BorderLineStyle = {}));