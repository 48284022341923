export var JSONFontInfoProperty;
(function (JSONFontInfoProperty) {
  JSONFontInfoProperty[JSONFontInfoProperty["Name"] = 0] = "Name";
  JSONFontInfoProperty[JSONFontInfoProperty["ScriptMultiplier"] = 1] = "ScriptMultiplier";
  JSONFontInfoProperty[JSONFontInfoProperty["CssString"] = 2] = "CssString";
  JSONFontInfoProperty[JSONFontInfoProperty["CanBeSet"] = 3] = "CanBeSet";
  JSONFontInfoProperty[JSONFontInfoProperty["SubScriptOffset"] = 4] = "SubScriptOffset";
  JSONFontInfoProperty[JSONFontInfoProperty["FontFamily"] = 5] = "FontFamily";
})(JSONFontInfoProperty || (JSONFontInfoProperty = {}));
export var JSONColorModelInfoProperty;
(function (JSONColorModelInfoProperty) {
  JSONColorModelInfoProperty[JSONColorModelInfoProperty["ColorType"] = 0] = "ColorType";
  JSONColorModelInfoProperty[JSONColorModelInfoProperty["Auto"] = 1] = "Auto";
  JSONColorModelInfoProperty[JSONColorModelInfoProperty["ThemeColorIndex"] = 2] = "ThemeColorIndex";
  JSONColorModelInfoProperty[JSONColorModelInfoProperty["Tint"] = 3] = "Tint";
  JSONColorModelInfoProperty[JSONColorModelInfoProperty["Rgb"] = 4] = "Rgb";
  JSONColorModelInfoProperty[JSONColorModelInfoProperty["ColorIndex"] = 5] = "ColorIndex";
})(JSONColorModelInfoProperty || (JSONColorModelInfoProperty = {}));
export var JSONCharacterFormattingProperty;
(function (JSONCharacterFormattingProperty) {
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["FontInfoIndex"] = 0] = "FontInfoIndex";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["Size"] = 1] = "Size";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["Bold"] = 2] = "Bold";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["Italic"] = 3] = "Italic";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["StrikeoutType"] = 4] = "StrikeoutType";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["UnderlineType"] = 5] = "UnderlineType";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["AllCaps"] = 6] = "AllCaps";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["StrikeoutWordsOnly"] = 7] = "StrikeoutWordsOnly";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["UnderlineWordsOnly"] = 8] = "UnderlineWordsOnly";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["Script"] = 9] = "Script";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["Hidden"] = 10] = "Hidden";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["LangInfo"] = 11] = "LangInfo";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["NoProof"] = 12] = "NoProof";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["UseValue"] = 13] = "UseValue";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["FontName"] = 14] = "FontName";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["CompositeFontInfo"] = 15] = "CompositeFontInfo";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["TextColor"] = 16] = "TextColor";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["ShadingInfo"] = 17] = "ShadingInfo";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["HighlightColor"] = 18] = "HighlightColor";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["UnderlineColor"] = 19] = "UnderlineColor";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["StrikeoutColor"] = 20] = "StrikeoutColor";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["TextColorIndex"] = 21] = "TextColorIndex";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["ShadingInfoIndex"] = 22] = "ShadingInfoIndex";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["HighlightColorIndex"] = 23] = "HighlightColorIndex";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["UnderlineColorIndex"] = 24] = "UnderlineColorIndex";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["StrikeoutColorIndex"] = 25] = "StrikeoutColorIndex";
  JSONCharacterFormattingProperty[JSONCharacterFormattingProperty["SmallCaps"] = 26] = "SmallCaps";
})(JSONCharacterFormattingProperty || (JSONCharacterFormattingProperty = {}));
export var JSONLangInfoProperty;
(function (JSONLangInfoProperty) {
  JSONLangInfoProperty[JSONLangInfoProperty["Latin"] = 0] = "Latin";
  JSONLangInfoProperty[JSONLangInfoProperty["Bidi"] = 1] = "Bidi";
  JSONLangInfoProperty[JSONLangInfoProperty["EastAsia"] = 2] = "EastAsia";
})(JSONLangInfoProperty || (JSONLangInfoProperty = {}));
export var JSONCompositeFontInfoProperty;
(function (JSONCompositeFontInfoProperty) {
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["asciiFontName"] = 0] = "asciiFontName";
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["hightAnsiFontName"] = 1] = "hightAnsiFontName";
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["complexScriptFontName"] = 2] = "complexScriptFontName";
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["eastAsiaFontName"] = 3] = "eastAsiaFontName";
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["asciiThemeFont"] = 4] = "asciiThemeFont";
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["hightAnsiThemeFont"] = 5] = "hightAnsiThemeFont";
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["complexScriptThemeFont"] = 6] = "complexScriptThemeFont";
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["eastAsiaThemeFont"] = 7] = "eastAsiaThemeFont";
  JSONCompositeFontInfoProperty[JSONCompositeFontInfoProperty["hintFont"] = 8] = "hintFont";
})(JSONCompositeFontInfoProperty || (JSONCompositeFontInfoProperty = {}));
export var JSONInsertTextCommandProperty;
(function (JSONInsertTextCommandProperty) {
  JSONInsertTextCommandProperty[JSONInsertTextCommandProperty["Text"] = 0] = "Text";
  JSONInsertTextCommandProperty[JSONInsertTextCommandProperty["RunType"] = 1] = "RunType";
  JSONInsertTextCommandProperty[JSONInsertTextCommandProperty["Length"] = 2] = "Length";
  JSONInsertTextCommandProperty[JSONInsertTextCommandProperty["Position"] = 3] = "Position";
  JSONInsertTextCommandProperty[JSONInsertTextCommandProperty["CharacterStyleName"] = 4] = "CharacterStyleName";
  JSONInsertTextCommandProperty[JSONInsertTextCommandProperty["CharacterPropertiesIndex"] = 5] = "CharacterPropertiesIndex";
  JSONInsertTextCommandProperty[JSONInsertTextCommandProperty["CharacterProperties"] = 6] = "CharacterProperties";
})(JSONInsertTextCommandProperty || (JSONInsertTextCommandProperty = {}));
export var JSONLoadFontInfoCommand;
(function (JSONLoadFontInfoCommand) {
  JSONLoadFontInfoCommand[JSONLoadFontInfoCommand["FontInfo"] = 0] = "FontInfo";
  JSONLoadFontInfoCommand[JSONLoadFontInfoCommand["FontInfoIndex"] = 1] = "FontInfoIndex";
})(JSONLoadFontInfoCommand || (JSONLoadFontInfoCommand = {}));