import { Component, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { lastValueFrom, Subscription } from 'rxjs';
import { PopupService } from '../../services/popup.service';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { ApiService } from '../../services/api.service';

interface IFormData {
  jahr: number;
  monat: number;
}

@Component({
  selector: 'app-bgb-stapelberechnung-dialog',
  templateUrl: './bgb-stapelberechnung-dialog.component.html',
  styleUrls: ['./bgb-stapelberechnung-dialog.component.scss'],
  standalone: false,
})
export class BgbStapelberechnungDialogComponent {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;

  subscription_get_PopupAsObservable: Subscription;

  onClose: (e) => void;

  formData: IFormData;
  isLoading: boolean = false;
  isError: boolean = false;
  isBerechnet: boolean = false;
  errorText: string = '';

  constructor(private popupService: PopupService, private apiService: ApiService) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickStart = this.onClickStart.bind(this);

    this.formData = {
      jahr: new Date().getFullYear(),
      monat: new Date().getMonth(),
    };

    this.subscription_get_PopupAsObservable = this.popupService.get_stapelBerechnungPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true) {
        this.isBerechnet = false;
        this.isError = false;
        this.isLoading = false;
        this.popup.instance.show();
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  onClickClose() {
    this.popup.instance.hide();
  }

  onClickStart() {
    this.isBerechnet = false;
    this.isError = false;
    this.isLoading = true;

    setTimeout(() => {
      if (this.isLoading === true) {
        this.isError = true;
        this.isLoading = false;
        this.isBerechnet = false;
        this.errorText = 'Time out überschritten!';
      }
    }, 10000);

    this.apiService.post<any>(RouteKeys.Bgb.elternbeitrag_stapelberechnung, this.formData).subscribe({
      next: (response) => {
        console.debug(response);
        this.isLoading = false;
        this.isBerechnet = true;
      },
      error: (err) => {
        this.isError = true;
        this.isLoading = false;
        this.isBerechnet = false;
        this.errorText = err.message;
        throw err;
      },
    });
  }
}
