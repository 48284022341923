export var LogSource;
(function (LogSource) {
  LogSource[LogSource["Main"] = 1] = "Main";
  LogSource[LogSource["RowFormatter"] = 2] = "RowFormatter";
  LogSource[LogSource["LayoutFormatter"] = 3] = "LayoutFormatter";
  LogSource[LogSource["LayoutFormatterNotifier"] = 4] = "LayoutFormatterNotifier";
  LogSource[LogSource["LayoutFormatterInvalidator"] = 5] = "LayoutFormatterInvalidator";
  LogSource[LogSource["CanvasManager"] = 6] = "CanvasManager";
  LogSource[LogSource["DocumentRenderer"] = 7] = "DocumentRenderer";
  LogSource[LogSource["SelectionRenderer"] = 8] = "SelectionRenderer";
  LogSource[LogSource["SelectionFormatter"] = 9] = "SelectionFormatter";
  LogSource[LogSource["DiplayPageInfo"] = 10] = "DiplayPageInfo";
  LogSource[LogSource["DiplayTableBoundsInfo"] = 11] = "DiplayTableBoundsInfo";
  LogSource[LogSource["DiplayLayoutBoundsInfo"] = 12] = "DiplayLayoutBoundsInfo";
  LogSource[LogSource["RestartManager"] = 13] = "RestartManager";
  LogSource[LogSource["BoxWrapHolder"] = 14] = "BoxWrapHolder";
  LogSource[LogSource["PositionSaver"] = 15] = "PositionSaver";
  LogSource[LogSource["EventManager"] = 16] = "EventManager";
  LogSource[LogSource["TableFormatter"] = 17] = "TableFormatter";
  LogSource[LogSource["HtmlImporter"] = 18] = "HtmlImporter";
  LogSource[LogSource["SimpleViewRenderer"] = 19] = "SimpleViewRenderer";
  LogSource[LogSource["DocxImporter"] = 20] = "DocxImporter";
})(LogSource || (LogSource = {}));