import { DecoderHelper } from '../dx-decoding/decoder-helper';
import { CharacterDecoder } from './character-decoder';
export class CodePageCharacterDecoder extends CharacterDecoder {
  constructor(codePage) {
    super();
    this.codePage = codePage;
    this._bytes = [];
  }
  processChar(importer, ch) {
    if (this._bytes.length == CodePageCharacterDecoder.capacity) this.flush(importer);
    this._bytes.push(ch);
  }
  flushByChar(importer, chars) {
    const count = chars.length;
    for (let i = 0; i < count; i++) importer.processChar(chars[i]);
  }
  flushByString(importer, chars) {
    importer.destination.processText(chars.join(""));
  }
  flush(importer) {
    if (this._bytes.length > 0) {
      const chars = DecoderHelper.getChars(this._bytes, this.codePage);
      if (!importer.destination.canAppendText || chars.length <= 1) {
        this.flushByChar(importer, chars);
      } else {
        this.flushByString(importer, chars);
      }
      this._bytes = [];
    }
  }
}
CodePageCharacterDecoder.capacity = 2048;