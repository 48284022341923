import { Component, EventEmitter, Output, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { ApiService } from 'src/app/share/services/api.service';
import { PopupService } from '../../services/popup.service';
import { lastValueFrom, Subscription } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ConfigService } from '../../services/config.service';
import { AuthService } from '../../services/auth.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-wiedervorlagenGridPopup',
  templateUrl: './wiedervorlagenGridPopup.component.html',
  styleUrls: ['./wiedervorlagenGridPopup.component.scss'],
  standalone: false,
})
export class WiedervorlagenGridPopupComponent implements OnInit, OnDestroy {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('wiedervorlagenDataGrid', { static: false }) wiedervorlagenDataGrid: DxDataGridComponent;

  dataSource: DataSource;
  canShowZumAntrag: boolean = true;
  id: number;
  vorgangString: string = '';
  selectedItem: any = null;

  subscription_get_PopupAsObservable: Subscription;

  constructor(
    private apiService: ApiService,
    private popupService: PopupService,
    public authService: AuthService,
    public configService: ConfigService
  ) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickErledigt = this.onClickErledigt.bind(this);

    this.subscription_get_PopupAsObservable = this.popupService.get_wiedervorlageGridPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true) {
        if (this.wiedervorlagenDataGrid) {
          this.wiedervorlagenDataGrid.instance.clearFilter();
          this.wiedervorlagenDataGrid.instance.clearSorting();
          this.wiedervorlagenDataGrid.instance.clearSelection();

          this.wiedervorlagenDataGrid.instance.columnOption('faelligMitarbeiterId', {
            selectedFilterOperation: '=',
            filterValue: this.authService.getBenutzerId(),
          });

          this.wiedervorlagenDataGrid.instance.columnOption('status', {
            selectedFilterOperation: '=',
            filterValue: false,
          });
        }

        this.canShowZumAntrag = context.data.canShowZumAntrag === false ? false : true;
        this.selectedItem = null;
        this.id = context.data.id;
        this.vorgangString = context.data.antragsZeichen;
        this.dataSource.reload();
        this.popup.instance.show();
      }
    });
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      key: 'wiedervorlagenId',
      load: (loadOptions) => {
        loadOptions.userData = {
          id: this.id,
        };

        return lastValueFrom(this.apiService.post<any>(RouteKeys.Wiedervorlage.get_allByAntrag, loadOptions))
          .then((response) => {
            return {
              data: response.data,
              totalCount: response.totalCount,
              summary: response.summary,
              groupCount: response.groupCount,
            };
          })
          .catch((err) => {
            throw err.message;
          });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  onContentReady(e) {
    this.wiedervorlagenDataGrid.instance.clearSelection();

    e.component.columnOption('command:select', {
      visibleIndex: 1,
      fixedPosition: 'right',
      visibleWidth: 70,
    });
  }

  onToolbarPreparing(e) {
    var searchPanel = e.toolbarOptions.items.filter((item) => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }

    e.toolbarOptions.items.push({
      location: 'before',
      widget: 'dxButton',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'undo',
        hint: 'Filter zurücksetzen',
        onClick: (event) => {
          this.wiedervorlagenDataGrid.instance.clearFilter();
          this.wiedervorlagenDataGrid.instance.clearSorting();

          this.wiedervorlagenDataGrid.instance.columnOption('faelligMitarbeiterId', {
            selectedFilterOperation: '=',
            filterValue: this.authService.getBenutzerId(),
          });

          this.wiedervorlagenDataGrid.instance.columnOption('status', {
            selectedFilterOperation: '=',
            filterValue: false,
          });
        },
      },
    });

    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      cssClass: 'datagridToolbarNewBtn',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'add',
        text: 'Neu',
        onClick: this.onClickNew.bind(this),
      },
    });
  }

  onSelectionChanged(e) {
    this.selectedItem = e.selectedRowsData[0];
  }

  onClickNew(e) {
    this.popupService.showWiedervorlageEditPopup({ isNew: true, entityId: this.id }, (state) => {
      this.handleEditCloseEvent(state);
    });
  }

  onClickEdit(e) {
    if (e !== undefined && e !== null) {
      let data;

      if (e.row === undefined) {
        data = e.data;
      } else {
        data = e.row.data;
      }

      this.popupService.showWiedervorlageEditPopup(
        { canShowZumAntrag: this.canShowZumAntrag, entityId: data.entityId, wiedervorlagenId: data.wiedervorlagenId },
        (state) => {
          this.handleEditCloseEvent(state);
        }
      );
    }
  }

  onClickClose(e) {
    this.popup.instance.hide();
  }

  onClickErledigt(e) {
    let result = confirm('<i>Wollen Sie die Wiedervorlage wirklich schließen?</i>', 'Bestätigung');
    result.then((dialogResult) => {
      if (dialogResult) {
        this.apiService.post(RouteKeys.Wiedervorlage.finish, { entityId: e.row.data.wiedervorlagenId, erledigt: true }).subscribe({
          next: () => {
            this.wiedervorlagenDataGrid.instance.refresh();
          },
          error: (err) => {
            throw err;
          },
        });
      }
    });
  }

  private handleEditCloseEvent(state: number) {
    if (state === 1) {
      this.wiedervorlagenDataGrid.instance.refresh();
    } else if (state === 2) {
      this.popup.instance.hide();
    }
  }

  isVisible(e) {
    return !e.row.data.status;
  }
}
