<dx-popup #popup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="true" title="Stapelberechnung" width="390" height="310" [disabled]="isLoading">
    <div *dxTemplate="let data of 'content'" class="popup-content">
        <dx-form [formData]="formData" class="stapelbearbeitung-form">
          <dxi-item  [colSpan]="2">
            <span>Es werden alle Elternbeiträge für die folgende Periode</span>
          </dxi-item>          
            <dxi-item itemType="group" [colCount]="2">
                <dxi-item dataField="monat" editorType="dxNumberBox" [label]="{ text: '' }" [editorOptions]="{ value: formData.monat, min: 1, max: 12, showClearButton: false}"
                  [isRequired]="true" >
                </dxi-item>
                <dxi-item dataField="jahr" editorType="dxNumberBox" [label]="{ text: '' }" [editorOptions]="{ value: formData.jahr, min: 2024, max: 2099, showClearButton: false}"
                  [isRequired]="true">
                </dxi-item>

                <dxi-item  [colSpan]="2">
                  <span>neu berechnet.</span>
                </dxi-item>
            </dxi-item>
        </dx-form>
        <div class="message-container">
          <span *ngIf="!isError && isBerechnet">Die Berechnung wurde erfolgreich abgeschlossen</span>
          <span *ngIf="isError" class="error-container">{{errorText}}</span>
        </div>

        <dx-load-panel
          #loadPanel
          shadingColor="rgba(0,0,0,0.4)"
          [visible]= "isLoading"
          [position]="{ of: '#popup' }"
          [showIndicator]="true"
          [showPane]="true"
          [shading]="false"
          [hideOnOutsideClick]="false"
          message="Stapelberechung läuft..."
        >
        </dx-load-panel>
    </div>

    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Berechnung starten', type: 'default', stylingMode: 'contained', icon: 'check', 
        onClick: onClickStart, disabled: !(formData.monat > 0 && formData.jahr > 0) || isError}">
    </dxi-popup-toolbar-item>
    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Schließen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-popup-toolbar-item>
</dx-popup>