import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, timer, Observable } from 'rxjs';
import { RouteKeys } from '../helper/route-keys.helper';
import { ApiService } from './api.service';

@Injectable()
export class PollService {
  private isInit: boolean = false;
  private timer_subscription: Subscription;
  private clientServerPollTime: number;
  private counts = new BehaviorSubject<any>({});

  constructor(private apiService: ApiService) {}

  public init() {
    if (!this.isInit) {
      let _timer = timer(500, 10000);

      this.counts = new BehaviorSubject<any>({});

      this.timer_subscription = _timer.subscribe((t) => {
        this.timer_tick();
      });

      this.isInit = true;
    }

    return this.counts;
  }

  public getPollDataAsObservable(): Observable<any> {
    return this.counts.asObservable();
  }

  private timer_tick() {
    if (this.isInit) {
      this.apiService.post<any>(RouteKeys.Poll.get).subscribe({
        next: (response) => {
          if (response !== null && response !== undefined && response.data !== null && response.data !== undefined) {
            this.counts.next(response.data);
          }

          if (this.clientServerPollTime !== response.pollTime) {
            this.timer_subscription.unsubscribe();
            this.clientServerPollTime = response.pollTime;
            let _timer = timer(500, this.clientServerPollTime);
            this.timer_subscription = _timer.subscribe((t) => {
              this.timer_tick();
            });
          }
        },
        error: (err) => {
          this.stop();
          throw err;
        },
      });
    } else {
      this.stop();
    }
  }

  public refresh() {
    this.counts.next(this.counts.value);
  }

  public stop() {
    this.isInit = false;

    if (this.timer_subscription) {
      this.timer_subscription.unsubscribe();
      this.timer_subscription.closed = true;
      this.timer_subscription.remove(this.timer_subscription);
    }

    if (this.counts) {
      this.counts.complete();
    }
  }
}
