export class HtmlBuilder {
  constructor() {
    this.childElements = [];
    this.callbacks = [];
  }
  getHtmlString(needDefaultMarkup = false) {
    const content = this.childElements.map(x => {
      if (typeof x === 'string') return x;
      return x.outerHTML;
    }).join('');
    return needDefaultMarkup ? this.addDefaultMarkup(content) : content;
  }
  isEmpty() {
    return this.callbacks.length === 0 && this.childElements.length === 0;
  }
  clear() {
    this._currentElement = null;
    this.callbacks = [];
    this.childElements = [];
    return this;
  }
  startChild(tagName, namespaceUri) {
    const element = namespaceUri ? document.createElementNS(namespaceUri, tagName) : document.createElement(tagName);
    if (this._currentElement) this._currentElement.appendChild(element);else {
      this.childElements.push(element);
    }
    this._currentElement = element;
    return this;
  }
  configure(config) {
    config(this._currentElement);
    return this;
  }
  addCallback(callback) {
    this.callbacks.push(callback);
  }
  assignFrom(builder) {
    if (!builder) return this;
    if (typeof builder === "string") {
      this.addElement(builder);
      return this;
    }
    builder.callbacks.forEach(callback => {
      callback(this);
    });
    builder.childElements.forEach(el => {
      this.addElement(el);
    });
    builder.clear();
    return this;
  }
  addElement(element) {
    if (!element) return this;
    if (!this._currentElement) {
      this.childElements.push(element);
      return this;
    }
    if (typeof element === 'string') this._currentElement.innerHTML += element;else if (this._currentElement) this._currentElement.appendChild(element);else this._currentElement = element;
    return this;
  }
  addBreak(cssText) {
    this.startChild('br');
    if (cssText) this.configure(el => el.style.cssText = cssText);
    this.endChild('br');
    return this;
  }
  endChild(tagName) {
    try {
      let currentElement = this._currentElement;
      while (currentElement.tagName.toLowerCase() !== tagName.toLowerCase()) currentElement = currentElement.parentElement;
      this._currentElement = currentElement.parentElement;
    } catch (e) {
      console.warn(`The element with the ${tagName} tag is not found.`);
    }
    return this;
  }
  addDefaultMarkup(content) {
    return '<!DOCTYPE html>' + '<html lang="en">' + '<head>' + '<meta charset="UTF-8">' + '<meta name="viewport" content="width=device-width, initial-scale=1.0">' + '</head>' + '<body>' + content + '</body>' + '</html>';
  }
}