import { ListUtils } from '@devexpress/utils/lib/utils/list';
import { HtmlTagImporterBase } from './base';
import { LevelInfo } from '../html-importer';
export class HtmlLiTagImporter extends HtmlTagImporterBase {
  elementTag() {
    return 'LI';
  }
  importBefore() {
    const firstChild = this.element.childNodes[0];
    this.element.innerHTML = this.element.innerHTML.trim();
    if (this.isListElement(firstChild)) this.importer.addParagraphRun(this.element, this.importer.currListInfo);
  }
  isImportChildren() {
    return true;
  }
  importAfter() {
    const lastChild = ListUtils.last([...this.element.childNodes]);
    if (!this.importer.prevRunIsParagraph && !this.isListElement(lastChild)) this.importer.addParagraphRun(this.element, this.importer.currListInfo);
  }
  isListElement(elem) {
    const tagName = LevelInfo.getElementTag(elem);
    return tagName === 'OL' || tagName === 'UL';
  }
}