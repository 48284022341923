export class Characters {}
Characters.Dot = '.';
Characters.EnDash = '–';
Characters.Bullet = '•';
Characters.LeftSingleQuote = '‘';
Characters.RightSingleQuote = '’';
Characters.LeftDoubleQuote = '“';
Characters.RightDoubleQuote = '”';
Characters.PilcrowSign = '¶';
Characters.CurrencySign = '¤';
Characters.CopyrightSymbol = '©';
Characters.TrademarkSymbol = '™';
Characters.OptionalHyphen = '­';
Characters.RegisteredTrademarkSymbol = '®';
Characters.Ellipsis = '…';
Characters.OpeningSingleQuotationMark = '‘';
Characters.ClosingSingleQuotationMark = '’';
Characters.OpeningDoubleQuotationMark = '“';
Characters.ClosingDoubleQuotationMark = '”';
Characters.EmDash = '—';
Characters.SeparatorMark = '|';
Characters.FloatingObjectMark = '\b';
Characters.ColumnBreak = '\u000e';
Characters.Colon = ':';
Characters.Underscore = '_';
Characters.EqualSign = '=';
Characters.MiddleDot = '·';
Characters.Dash = '-';
Characters.ParagraphMark = '\r';
Characters.SectionMark = '\u001d';
Characters.Hyphen = '\u001f';
Characters.TabMark = '\t';
Characters.NonBreakingSpace = ' ';
Characters.Space = ' ';
Characters.EmSpace = ' ';
Characters.EnSpace = ' ';
Characters.QmSpace = ' ';
Characters.ZeroWidthSpace = '​';
Characters.LineBreak = '\v';
Characters.PageBreak = '\f';
Characters.ObjectMark = '￼';
Characters.CanadianSyllabicsChiSign = '᙭';