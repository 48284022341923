import { TableHeightUnit } from '../../../common/model/tables/secondary-structures/table-units';
import { TableRowHeightHistoryItem } from '../../../common/model/history/items/tables/table-row-properties-history-items';
import { TableCellCollection } from '../collections/table/table-cell-collection';
import { convertToIntervalApi } from '../interval';
import { TableApi } from './table';
import { TableRowHeightTypeApi } from './enums';
import { ApiParametersChecker } from '../api-utils/parameter-checker';
import { TableElementBase } from './table-element-base';
export class TableRowApi extends TableElementBase {
  constructor(processor, subDocument, tablePosition, parentTable) {
    super(processor, subDocument);
    this._tablePosition = tablePosition;
    this._parentTable = parentTable || new TableApi(this._processor, this._subDocument, this._tablePosition.table);
  }
  get index() {
    return this._tablePosition.rowIndex;
  }
  get interval() {
    return convertToIntervalApi(this._tablePosition.row.interval);
  }
  get cells() {
    return new TableCellCollection(this._processor, this._subDocument, this._tablePosition.clone(), this);
  }
  get parentTable() {
    return this._parentTable;
  }
  get height() {
    return {
      type: Number(this._tablePosition.row.height.type),
      value: this._tablePosition.row.height.value
    };
  }
  set height(value) {
    const heightDescriptor = ApiParametersChecker.objectDescriptor('height', 'TableRowHeight', val => val);
    const heightTypeDescriptor = ApiParametersChecker.enumDescriptor('height.type', val => val, TableRowHeightTypeApi, 'TableRowHeightType');
    const heightValueDescriptor = ApiParametersChecker.numberDescriptor('height.value', val => val, 0);
    ApiParametersChecker.check(value, 1, false, [heightDescriptor]);
    ApiParametersChecker.check(value.type, 1, false, [heightTypeDescriptor]);
    ApiParametersChecker.check(value.value, 1, false, [heightValueDescriptor]);
    const height = TableHeightUnit.create(value.value, Number(value.type));
    const historyItem = new TableRowHeightHistoryItem(this._modelManipulator, this._subDocument, this._tablePosition.table.index, this.index, height);
    this._history.addAndRedo(historyItem);
  }
}