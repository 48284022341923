<dx-popup class="popup" [showTitle]="true" [title]="'Spaltenauswahl'" [dragEnabled]="false"
  [hideOnOutsideClick]="false" [height]="'63%'" [width]="'350px'" (onShowing)="onShowingPopup($event)"
  (onHidden)="onHiddenPopup($event)" [(visible)]="popupVisible">
  
  <div *dxTemplate="let data of 'content'">

    <div class="columchooser">

      <div class="columchooser-hl-container">
        <div class="row-header">
          <dx-button text="Alle auswählen" type="default" (onClick)="onClickAllOn()">
          </dx-button>

          <dx-button text="Alle abwählen" type="default" (onClick)="onClickAllOff()">
          </dx-button>
        </div>
      </div>

      <dx-scroll-view #scrollView class="columchooser-container" reachBottomText="Updating...">
        <div class="row">
          <ng-container *ngFor="let item of shadow_columns;">
            <div class="dx-field-colChooser">
              <dx-check-box [(value)]="item.visible" [text]="item.name"></dx-check-box>
            </div>
          </ng-container>
        </div>
      </dx-scroll-view>

    </div>
  </div>

  <dxi-popup-toolbar-item widget="dxButton" location="before" toolbar="bottom"
    [options]="{ text: 'Übernehmen', type: 'default', stylingMode: 'contained',  icon: 'check', onClick: onClickUebernehmen }">
  </dxi-popup-toolbar-item>

  <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom" 
    [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
  </dxi-popup-toolbar-item>
</dx-popup>
