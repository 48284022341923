import { FixedInterval } from '@devexpress/utils/lib/intervals/fixed';
import { DomUtils } from '@devexpress/utils/lib/utils/dom';
import { StringUtils } from '@devexpress/utils/lib/utils/string';
import { HtmlTagImporterBase } from './base';
export class HtmlPTagImporter extends HtmlTagImporterBase {
  constructor() {
    super(...arguments);
    this.listInfo = null;
    this.importChildren = false;
  }
  elementTag() {
    return "P";
  }
  importBefore() {
    this.importer.addCurrLevelParagraphRunIfNeeded();
    this.element.innerHTML = this.element.innerHTML.trim();
    let emptyParagraphMatches = this.element.outerHTML.match(/^<([^\s >]+)(\s[^>]*)?>&nbsp;<\/\1>/gi);
    if (!(emptyParagraphMatches === null || emptyParagraphMatches === void 0 ? void 0 : emptyParagraphMatches.length)) {
      this.listInfo = this.getListInfo();
      this.importChildren = true;
    }
  }
  isImportChildren() {
    return this.importChildren;
  }
  importAfter() {
    this.importer.addParagraphRun(this.element, this.listInfo);
  }
  getListInfo() {
    var _a, _b;
    if (!StringUtils.trim(DomUtils.getInnerText(this.element)) || ((_b = (_a = this.element.parentElement) === null || _a === void 0 ? void 0 : _a.closest('ul,ol,li')) === null || _b === void 0 ? void 0 : _b.tagName) !== 'LI') return null;
    if (!this.importer.currListItemLevelInfo) return this.importer.paragraphListPropertiesUtils.import(this.element, new FixedInterval(this.importer.currPosition, 0));
    if (!this.importer.currListItemLevelInfo.tagImporter.paragraphWasAddedBefore) return this.importer.currListInfo;
    return null;
  }
}
export class HtmlH1TagImporter extends HtmlPTagImporter {
  elementTag() {
    return "H1";
  }
}
export class HtmlH2TagImporter extends HtmlPTagImporter {
  elementTag() {
    return "H2";
  }
}
export class HtmlH3TagImporter extends HtmlPTagImporter {
  elementTag() {
    return "H3";
  }
}
export class HtmlH4TagImporter extends HtmlPTagImporter {
  elementTag() {
    return "H4";
  }
}
export class HtmlH5TagImporter extends HtmlPTagImporter {
  elementTag() {
    return "H5";
  }
}
export class HtmlH6TagImporter extends HtmlPTagImporter {
  elementTag() {
    return "H6";
  }
}