export const FONT_NAME_TO_FILE_NAME = {
  'Angsana New': 'angsana.ttc',
  'Angsana Newb': 'angsana.ttc',
  'Angsana Newi': 'angsana.ttc',
  'Angsana Newz': 'angsana.ttc',
  'Arial': 'arial.ttf',
  'Arialb': 'arialbd.ttf',
  'Ariali': 'ariali.ttf',
  'Arialz': 'arialbi.ttf',
  'Arial Unicode MS': 'Arialuni.ttf',
  'Arial Unicode MSb': 'Arialuni.ttf',
  'Arial Unicode MSi': 'Arialuni.ttf',
  'Arial Unicode MSz': 'Arialuni.ttf',
  'Arial Black': 'ariblk.ttf',
  'Arial Blackb': 'ariblk.ttf',
  'Arial Blacki': 'AriBlkIt.ttf',
  'Arial Blackz': 'AriBlkIt.ttf',
  'Batang': 'batang.ttc',
  'Batangb': 'batang.ttc',
  'Batangi': 'batang.ttc',
  'Batangz': 'batang.ttc',
  'Book Antiqua': 'BKANT',
  'Book Antiquab': 'ANTQUAB',
  'Book Antiquai': 'ANTQUAI',
  'Book Antiquaz': 'ANTQUABI',
  'Bookman Old Style': 'bookos.ttf',
  'Bookman Old Styleb': 'bookos.ttf',
  'Bookman Old Stylei': 'bookos.ttf',
  'Bookman Old Stylez': 'bookos.ttf',
  'Calibri': 'Calibri.ttf',
  'Calibrib': 'Calibrib.ttf',
  'Calibrii': 'Calibrii.ttf',
  'Calibriz': 'Calibriz.ttf',
  'Calibri Light': 'Calibril.ttf',
  'Calibri Lightb': 'Calibril.ttf',
  'Calibri Lighti': 'Calibrili.ttf',
  'Calibri Lightz': 'Calibrili.ttf',
  'Cambria': 'cambria.ttc',
  'Cambriab': 'cambriab.ttf',
  'Cambriai': 'cambriai.ttf',
  'Cambriaz': 'cambriaz.ttf',
  'Candara': 'Candara.ttf',
  'Candarab': 'Candarab.ttf',
  'Candarai': 'Candarai.ttf',
  'Candaraz': 'Candaraz.ttf',
  'Century': 'CENTURY.ttf',
  'Centuryb': 'CENTURY.ttf',
  'Centuryi': 'CENTURY.ttf',
  'Centuryz': 'CENTURY.ttf',
  'Century Gothic': 'gothic.ttf',
  'Century Gothicb': 'gothic.ttf',
  'Century Gothici': 'gothic.ttf',
  'Century Gothicz': 'gothic.ttf',
  'CC-Gothic': 'gothic.ttf',
  'CC-Gothicb': 'gothic.ttf',
  'CC-Gothici': 'gothic.ttf',
  'CC-Gothicz': 'gothic.ttf',
  'Century Schoolbook': 'censcbk.ttf',
  'Century Schoolbookb': 'censcbk.ttf',
  'Century Schoolbooki': 'censcbk.ttf',
  'Century Schoolbookz': 'censcbk.ttf',
  'Comic Sans MS': 'comic.ttf',
  'Comic Sans MSb': 'comicbd.ttf',
  'Comic Sans MSi': 'comici.ttf',
  'Comic Sans MSz': 'comicz.ttf',
  'Consolas': 'Consola.ttf',
  'Consolasb': 'Consolab.ttf',
  'Consolasi': 'Consolai.ttf',
  'Consolasz': 'Consolaz.ttf',
  'Constantia': 'constan.ttf',
  'Constantiab': 'constanb.ttf',
  'Constantiai': 'constani.ttf',
  'Constantiaz': 'constanz.ttf',
  'Corbel': 'corbel.ttf',
  'Corbelb': 'corbelb.ttf',
  'Corbeli': 'corbeli.ttf',
  'Corbelz': 'corbelz.ttf',
  'Cordia New': 'cordia.ttf',
  'Cordia Newb': 'cordiab.ttf',
  'Cordia Newi': 'cordiai.ttf',
  'Cordia Newz': 'cordiaz.ttf',
  'Courier New': 'cour.ttf',
  'Courier Newb': 'courbd.ttf',
  'Courier Newi': 'courbi.ttf',
  'Courier Newz': 'couri.ttf',
  'Courier': 'cour.ttf',
  'Courierb': 'courbd.ttf',
  'Courieri': 'courbi.ttf',
  'Courierz': 'couri.ttf',
  'DaunPenh': 'daunpenh.ttf',
  'DaunPenhb': 'daunpenh.ttf',
  'DaunPenhi': 'daunpenh.ttf',
  'DaunPenhz': 'daunpenh.ttf',
  'Dotum': 'gulim.ttc',
  'Dotumb': 'gulim.ttc',
  'Dotumi': 'gulim.ttc',
  'Dotumz': 'gulim.ttc',
  'FangSong': 'simfang.ttf',
  'FangSongb': 'simfang.ttf',
  'FangSongi': 'simfang.ttf',
  'FangSongz': 'simfang.ttf',
  'Franklin Gothic Book': 'frabk.ttf',
  'Franklin Gothic Bookb': 'frabk.ttf',
  'Franklin Gothic Booki': 'frabk.ttf',
  'Franklin Gothic Bookz': 'frabk.ttf',
  'Franklin Gothic Medium': 'frabk.ttf',
  'Franklin Gothic Mediumb': 'frabk.ttf',
  'Franklin Gothic Mediumi': 'frabk.ttf',
  'Franklin Gothic Mediumz': 'frabk.ttf',
  'Garamond': 'GARA.TTF',
  'Garamondb': 'GARA.TTF',
  'Garamondi': 'GARA.TTF',
  'Garamondz': 'GARA.TTF',
  'Gautami': 'gautami.ttf',
  'Gautamib': 'Gautamib.ttf',
  'Gautamii': 'gautami.ttf',
  'Gautamiz': 'Gautamib.ttf',
  'Georgia': 'georgia.ttf',
  'Georgiab': 'georgiab.ttf',
  'Georgiai': 'georgiai.ttf',
  'Georgiaz': 'georgiaz.ttf',
  'Gill Sans MT': 'gil_____.ttf',
  'Gill Sans MTb': 'gil_____.ttf',
  'Gill Sans MTi': 'gil_____.ttf',
  'Gill Sans MTz': 'gil_____.ttf',
  'Gulim': 'gulim.ttc',
  'Gulimb': 'gulim.ttc',
  'Gulimi': 'gulim.ttc',
  'Gulimz': 'gulim.ttc',
  'GungSuh': 'batang.ttc',
  'GungSuhb': 'batang.ttc',
  'GungSuhi': 'batang.ttc',
  'GungSuhz': 'batang.ttc',
  'Impact': 'impact.ttf',
  'Impactb': 'impact.ttf',
  'Impacti': 'impact.ttf',
  'Impactz': 'impact.ttf',
  'Iskoola Pota': 'iskpota.ttf',
  'Iskoola Potab': 'iskpotab.ttf',
  'Iskoola Potai': 'iskpota.ttf',
  'Iskoola Potaz': 'iskpotab.ttf',
  'KaiTi': 'simkai.ttf',
  'KaiTib': 'simkai.ttf',
  'KaiTii': 'simkai.ttf',
  'KaiTiz': 'simkai.ttf',
  'Kalinga': 'kalinga.ttf',
  'Kalingab': 'kalingab.ttf',
  'Kalingai': 'kalinga.ttf',
  'Kalingaz': 'kalingab.ttf',
  'Kartika': 'kartika.ttf',
  'Kartikab': 'kartikab.ttf',
  'Kartikai': 'kartika.ttf',
  'Kartikaz': 'kartikab.ttf',
  'Latha': 'latha.ttf',
  'Lathab': 'Lathab.ttf',
  'Lathai': 'latha.ttf',
  'Lathaz': 'Lathab.ttf',
  'Lucida Console': 'lucon.ttf',
  'Lucida Consoleb': 'lucon.ttf',
  'Lucida Consolei': 'lucon.ttf',
  'Lucida Consolez': 'lucon.ttf',
  'Lucida Sans': 'lsansdi.ttf',
  'Lucida Sansb': 'lsansdi.ttf',
  'Lucida Sansi': 'lsansdi.ttf',
  'Lucida Sansz': 'lsansdi.ttf',
  'Lucida Sans Unicode': 'l_10646.ttf',
  'Lucida Sans Unicodeb': 'l_10646.ttf',
  'Lucida Sans Unicodei': 'l_10646.ttf',
  'Lucida Sans Unicodez': 'l_10646.ttf',
  'Malgun Gothic': 'malgun.ttf',
  'Malgun Gothicb': 'malgunbd.ttf',
  'Malgun Gothici': 'malgun.ttf',
  'Malgun Gothicz': 'malgunbd.ttf',
  'Mangal': 'Mangal.ttf',
  'Mangalb': 'MangalB.ttf',
  'Mangali': 'Mangal.ttf',
  'Mangalz': 'MangalB.ttf',
  'Meiryo': 'meiryo_00.ttf',
  'Meiryob': 'meiryob_00.ttf',
  'Meiryoi': 'meiryob_01.ttf',
  'Meiryoz': 'meiryo_01.ttf',
  'Microsoft JhengHei': 'msjh_00.ttf',
  'Microsoft JhengHeib': 'msjhbd_00.ttf',
  'Microsoft JhengHeii': 'msjh_00.ttf',
  'Microsoft JhengHeiz': 'msjhbd_00.ttf',
  'Microsoft YaHei': 'msyh_00.ttf',
  'Microsoft YaHeib': 'msyhbd_00.ttf',
  'Microsoft YaHeii': 'msyh_00.ttf',
  'Microsoft YaHeiz': 'msyhbd_00.ttf',
  'MingLiU': 'mingliu_00.ttf',
  'MingLiUb': 'mingliub_00.ttf',
  'MingLiUi': 'mingliu_00.ttf',
  'MingLiUz': 'mingliub_00.ttf',
  'MingLiU_HKSCS': 'mingliu_02.ttf',
  'MingLiU_HKSCSb': 'mingliub_02.ttf',
  'MingLiU_HKSCSi': 'mingliu_02.ttf',
  'MingLiU_HKSCSz': 'mingliub_02.ttf',
  'MS Gothic': 'msgothic.ttc',
  'MS Gothicb': 'msgothic.ttc',
  'MS Gothici': 'msgothic.ttc',
  'MS Gothicz': 'msgothic.ttc',
  'MS Mincho': 'msmincho.ttc',
  'MS Minchob': 'msmincho.ttc',
  'MS Minchoi': 'msmincho.ttc',
  'MS Minchoz': 'msmincho.ttc',
  'MS PGothic': 'msgothic_02.ttf',
  'MS PGothicb': 'msgothic_02.ttfc',
  'MS PGothici': 'msgothic_02.ttf',
  'MS PGothicz': 'msgothic_02.ttf',
  'MS PMincho': 'msmincho_01.ttf',
  'MS PMinchob': 'msmincho_01.ttfc',
  'MS PMinchoi': 'msmincho_01.ttf',
  'MS PMinchoz': 'msmincho_01.ttf',
  'Nyala': 'nyala.ttf',
  'Nyalab': 'nyala.ttf',
  'Nyalai': 'nyala.ttf',
  'Nyalaz': 'nyala.ttf',
  'Palatino Linotype': 'pala.ttf',
  'Palatino Linotypeb': 'palab.ttf',
  'Palatino Linotypei': 'palai.ttf',
  'Palatino Linotypez': 'palabi.ttf',
  'PMingLiU': 'mingliu_01.ttf',
  'PMingLiUb': 'mingliub_01.ttf',
  'PMingLiUi': 'mingliu_01.ttf',
  'PMingLiUz': 'mingliub_01.ttf',
  'PMingLiU-ExtB': 'mingliub_02.ttf',
  'PMingLiU-ExtBb': 'mingliub_02.ttf',
  'PMingLiU-ExtBi': 'mingliub_02.ttf',
  'PMingLiU-ExtBz': 'mingliub_02.ttf',
  'Raavi': 'raavi.ttf',
  'Raavib': 'raavib.ttf',
  'Raavii': 'raavi.ttf',
  'Raaviz': 'raavib.ttf',
  'Rockwell': 'rock.ttf',
  'Rockwellb': 'rockb.ttf',
  'Rockwelli': 'rocki.ttf',
  'Rockwellz': 'rockbi.ttf',
  'Segoe UI': 'segoeui.ttf',
  'Segoe UIb': 'segoeuib.ttf',
  'Segoe UIi': 'segoeuii.ttf',
  'Segoe UIz': 'segoeuiz.ttf',
  'Shruti': 'shruti.ttf',
  'Shrutib': 'shrutib.ttf',
  'Shrutii': 'shruti.ttf',
  'Shrutiz': 'shrutib.ttf',
  'SimHei': 'simhei.ttf',
  'SimHeib': 'simhei.ttf',
  'SimHeii': 'simhei.ttf',
  'SimHeiz': 'simhei.ttf',
  'SimSun': 'simsun_00.ttf',
  'SimSunb': 'simsunb.ttf',
  'SimSuni': 'simsun_00.ttf',
  'SimSunz': 'simsunb.ttf',
  'Sylfaen': 'sylfaen.ttf',
  'Sylfaenb': 'sylfaen.ttf',
  'Sylfaeni': 'sylfaen.ttf',
  'Sylfaenz': 'sylfaen.ttf',
  'Tahoma': 'tahoma.ttf',
  'Tahomab': 'tahomabd.ttf',
  'Tahomai': 'tahoma.ttf',
  'Tahomaz': 'tahomabd.ttf',
  'Times': 'times.ttf',
  'Timesb': 'timesbd.ttf',
  'Timesi': 'timesi.ttf',
  'Timesz': 'timesbi.ttf',
  'Times New Roman': 'times.ttf',
  'Times New Romanb': 'timesbd.ttf',
  'Times New Romani': 'timesi.ttf',
  'Times New Romanz': 'timesbi.ttf',
  'Trebuchet MS': 'trebuc.ttf',
  'Trebuchet MSb': 'trebucbd.ttf',
  'Trebuchet MSi': 'trebucit.ttf',
  'Trebuchet MSz': 'trebucbi.ttf',
  'Tunga': 'tunga.ttf',
  'Tungab': 'tungab.ttf',
  'Tungai': 'tunga.ttf',
  'Tungaz': 'tungab.ttf',
  'TW Cen MT': 'tcm_____.ttf',
  'TW Cen MTb': 'tcm_____.ttf',
  'TW Cen MTi': 'tcm_____.ttf',
  'TW Cen MTz': 'tcm_____.ttf',
  'Verdana': 'verdana.ttf',
  'Verdanab': 'verdanab.ttf',
  'Verdanai': 'verdanai.ttf',
  'Verdanaz': 'verdanaz.ttf',
  'Vrinda': 'vrinda.ttf',
  'Vrindab': 'vrindab.ttf',
  'Vrindai': 'vrinda.ttf',
  'Vrindaz': 'vrindab.ttf',
  'Symbol': 'symbol.ttf',
  'Symbolb': 'symbol.ttf',
  'Symboli': 'symbol.ttf',
  'Symbolz': 'symbol.ttf',
  'Webdings': 'Webdings.ttf',
  'Webdingsb': 'Webdings.ttf',
  'Webdingsi': 'Webdings.ttf',
  'Webdingsz': 'Webdings.ttf',
  'Wingdings': 'Wingding.ttf',
  'Wingdingsb': 'Wingding.ttf',
  'Wingdingsi': 'Wingding.ttf',
  'Wingdingsz': 'Wingding.ttf',
  'Microsoft Sans Serif': 'Micross.ttf',
  'Microsoft Sans Serifb': 'Micross.ttf',
  'Microsoft Sans Serifi': 'Micross.ttf',
  'Microsoft Sans Serifz': 'Micross.ttf',
  'Microsoft JhengHei Light': 'Msjhl.ttf',
  'Microsoft JhengHei Lightb': 'Msjhl.ttf',
  'Microsoft JhengHei Lighti': 'Msjhl.ttf',
  'Microsoft JhengHei Lightz': 'Msjhl.ttf'
};