import { BorderLineStyle } from '../../../../model/borders/enums';
import { SubDocumentInfoType } from '../../../../model/enums';
import { HeaderFooterType } from '../../../../model/section/enums';
import { ShadingPattern } from '../../../../model/shadings/shading-pattern';
import { TableCellPropertiesMerger } from '../../../../model/tables/properties-mergers/table-cell-properties-merger';
import { TableCellPropertiesMask } from '../../../../model/tables/properties/table-cell-properties';
import { TablePropertiesMask } from '../../../../model/tables/properties/table-properties';
import { TableRowPropertiesMask } from '../../../../model/tables/properties/table-row-properties';
import { ConditionalTableStyleFormatting, TableCellMergingState, TableCellVerticalAlignment, TableLayoutType, TableLookTypes, TableRowAlignment, TextDirection } from '../../../../model/tables/secondary-structures/table-base-structures';
import { TableHeightUnitType, TableWidthUnitType } from '../../../../model/tables/secondary-structures/table-units';
import { Log } from './log';
import { LogListHelper } from './log-list-helper';
export class LogObjToStr {
  static point(p) {
    return p ? `[x: ${p.x}, y:${p.y}]` : "null";
  }
  static fixedInterval(interval) {
    return `[${interval.start}, ${interval.end}]`;
  }
  static tableWidthUnit(unit) {
    return `type: ${TableWidthUnitType[unit.type]}, val: ${unit.value}`;
  }
  static tableHeightUnit(unit) {
    return `type: ${TableHeightUnitType[unit.type]}, val: ${unit.value}`;
  }
  static tableMembers(table, prefix = "") {
    const result = [];
    result.push(`${prefix}index: ${table.index}`);
    result.push(`${prefix}nestedLevel: ${table.nestedLevel}`);
    result.push(`${prefix}lookTypes: ${Log.mask(TableLookTypes, table.lookTypes)}`);
    result.push(`${prefix}preferredWidth: ${LogObjToStr.tableWidthUnit(table.preferredWidth)}`);
    return result.join("\n");
  }
  static tableProperties(props, prefix = "") {
    const result = [];
    result.push(`${prefix}mask: ${Log.mask(TablePropertiesMask, props.mask)}`);
    if (props.mask & TablePropertiesMask.UseTopMargin) result.push(`${prefix}cellMargins.top: ${LogObjToStr.tableWidthUnit(props.cellMargins.top)}`);
    if (props.mask & TablePropertiesMask.UseRightMargin) result.push(`${prefix}cellMargins.right: ${LogObjToStr.tableWidthUnit(props.cellMargins.right)}`);
    if (props.mask & TablePropertiesMask.UseBottomMargin) result.push(`${prefix}cellMargins.bottom: ${LogObjToStr.tableWidthUnit(props.cellMargins.bottom)}`);
    if (props.mask & TablePropertiesMask.UseLeftMargin) result.push(`${prefix}cellMargins.left: ${LogObjToStr.tableWidthUnit(props.cellMargins.left)}`);
    if (props.mask & TablePropertiesMask.UseCellSpacing) result.push(`${prefix}cellSpacing: ${LogObjToStr.tableWidthUnit(props.cellSpacing)}`);
    if (props.mask & TablePropertiesMask.UseTableIndent) result.push(`${prefix}indent: ${LogObjToStr.tableWidthUnit(props.indent)}`);
    if (props.mask & TablePropertiesMask.UseTopBorder) result.push(`${prefix}borders.top: ${LogObjToStr.borderInfo(props.borders.top)}`);
    if (props.mask & TablePropertiesMask.UseRightBorder) result.push(`${prefix}borders.right: ${LogObjToStr.borderInfo(props.borders.right)}`);
    if (props.mask & TablePropertiesMask.UseBottomBorder) result.push(`${prefix}borders.bottom: ${LogObjToStr.borderInfo(props.borders.bottom)}`);
    if (props.mask & TablePropertiesMask.UseLeftBorder) result.push(`${prefix}borders.left: ${LogObjToStr.borderInfo(props.borders.left)}`);
    if (props.mask & TablePropertiesMask.UseInsideHorizontalBorder) result.push(`${prefix}borders.insideHorizontal: ${LogObjToStr.borderInfo(props.borders.insideHorizontal)}`);
    if (props.mask & TablePropertiesMask.UseInsideVerticalBorder) result.push(`${prefix}borders.insideVertical: ${LogObjToStr.borderInfo(props.borders.insideVertical)}`);
    if (props.mask & TablePropertiesMask.UseTableStyleColBandSize) result.push(`${prefix}tableStyleColumnBandSize: ${props.tableStyleColumnBandSize}`);
    if (props.mask & TablePropertiesMask.UseTableStyleRowBandSize) result.push(`${prefix}tableStyleRowBandSize: ${props.tableStyleRowBandSize}`);
    if (props.mask & TablePropertiesMask.UseAvoidDoubleBorders) result.push(`${prefix}avoidDoubleBorders: ${props.avoidDoubleBorders}`);
    if (props.mask & TablePropertiesMask.UseShadingInfoIndex) result.push(`${prefix}shadingInfo: ${LogObjToStr.shadingInfo(props.shadingInfo)} \n`);
    if (props.mask & TablePropertiesMask.UseTableAlignment) result.push(`${prefix}tableRowAlignment: ${props.tableRowAlignment}\n`);
    result.push(`${prefix}layoutType: ${TableLayoutType[props.layoutType]}`);
    return result.join("\n");
  }
  static borderInfo(brd) {
    return `color: [${LogObjToStr.colorModelInfo(brd.color)}], width: ${brd.width}, style: ${BorderLineStyle[brd.style]}`;
  }
  static colorModelInfo(color) {
    return color.toDebugString();
  }
  static shadingInfo(shadingInfo) {
    return `backColor: ${shadingInfo.backColor}, foreColor: ${shadingInfo.foreColor}, shadingPattern: ${ShadingPattern[shadingInfo.shadingPattern]}`;
  }
  static tableCellMembers(cell, prefix = "") {
    const result = [];
    result.push(`${prefix}columnSpan: ${cell.columnSpan}`);
    result.push(`${prefix}conditionalFormatting: ${Log.mask(ConditionalTableStyleFormatting, cell.conditionalFormatting)}`);
    result.push(`${prefix}startParagraphPosition: ${cell.startParagraphPosition.value}`);
    result.push(`${prefix}endParagrapPosition: ${cell.endParagrapPosition.value}`);
    result.push(`${prefix}verticalMerging: ${TableCellMergingState[cell.verticalMerging]}`);
    result.push(`${prefix}preferredWidth: ${LogObjToStr.tableWidthUnit(cell.preferredWidth)}`);
    return result.join("\n");
  }
  static tableCellProperties(props, prefix = "") {
    const result = [];
    result.push(`${prefix}mask: ${Log.mask(TableCellPropertiesMask, props.mask)}`);
    if (props.mask & TableCellPropertiesMask.UseTopBorder) result.push(`${prefix}borders.top: ${props.borders.top}`);
    if (props.mask & TableCellPropertiesMask.UseRightBorder) result.push(`${prefix}borders.right: ${props.borders.right}`);
    if (props.mask & TableCellPropertiesMask.UseBottomBorder) result.push(`${prefix}borders.bottom: ${props.borders.bottom}`);
    if (props.mask & TableCellPropertiesMask.UseLeftBorder) result.push(`${prefix}borders.left: ${props.borders.left}`);
    if (props.mask & TableCellPropertiesMask.UseTopLeftDiagonalBorder) result.push(`${prefix}borders.topLeftDiagonal: ${props.borders.topLeftDiagonal}`);
    if (props.mask & TableCellPropertiesMask.UseTopRightDiagonalBorder) result.push(`${prefix}borders.topRightDiagonal: ${props.borders.topRightDiagonal}`);
    if (props.mask & TableCellPropertiesMask.UseTopMargin) result.push(`${prefix}cellMargins.top: ${LogObjToStr.tableWidthUnit(props.cellMargins.top)}`);
    if (props.mask & TableCellPropertiesMask.UseRightMargin) result.push(`${prefix}cellMargins.right: ${LogObjToStr.tableWidthUnit(props.cellMargins.right)}`);
    if (props.mask & TableCellPropertiesMask.UseBottomMargin) result.push(`${prefix}cellMargins.bottom: ${LogObjToStr.tableWidthUnit(props.cellMargins.bottom)}`);
    if (props.mask & TableCellPropertiesMask.UseLeftMargin) result.push(`${prefix}cellMargins.left: ${LogObjToStr.tableWidthUnit(props.cellMargins.left)}`);
    if (props.mask & TableCellPropertiesMask.UseFitText) result.push(`${prefix}fitText: ${props.fitText}`);
    if (props.mask & TableCellPropertiesMask.UseHideCellMark) result.push(`${prefix}hideCellMark: ${props.hideCellMark}`);
    if (props.mask & TableCellPropertiesMask.UseNoWrap) result.push(`${prefix}noWrap: ${props.noWrap}`);
    if (props.mask & TableCellPropertiesMask.UseShadingInfoIndex) result.push(`${prefix}shadingInfo: ${LogObjToStr.shadingInfo(props.shadingInfo)}`);
    if (props.mask & TableCellPropertiesMask.UseTextDirection) result.push(`${prefix}textDirection: ${TextDirection[props.textDirection]}`);
    if (props.mask & TableCellPropertiesMask.UseVerticalAlignment) result.push(`${prefix}verticalAlignment: ${TableCellVerticalAlignment[props.verticalAlignment]}`);
    return result.join("\n");
  }
  static tableRowMembers(row, prefix = "") {
    const result = [];
    result.push(`${prefix}conditionalFormatting: ${Log.mask(ConditionalTableStyleFormatting, row.conditionalFormatting)}`);
    result.push(`${prefix}height: ${LogObjToStr.tableHeightUnit(row.height)}`);
    result.push(`${prefix}gridAfter: ${row.gridAfter}`);
    result.push(`${prefix}gridBefore: ${row.gridBefore}`);
    result.push(`${prefix}widthAfter: ${LogObjToStr.tableWidthUnit(row.widthAfter)}`);
    result.push(`${prefix}widthBefore: ${LogObjToStr.tableWidthUnit(row.widthBefore)}`);
    return result.join("\n");
  }
  static tableRowProperties(props, prefix = "") {
    const result = [];
    result.push(`${prefix}mask: ${Log.mask(TableRowPropertiesMask, props.mask)}`);
    if (props.mask & TableRowPropertiesMask.UseCantSplit) result.push(`${prefix}cantSplit: ${props.cantSplit}`);
    if (props.mask & TableRowPropertiesMask.UseCellSpacing) result.push(`${prefix}cellSpacing: ${LogObjToStr.tableWidthUnit(props.cellSpacing)}`);
    if (props.mask & TableRowPropertiesMask.UseHeader) result.push(`${prefix}header: ${props.header}`);
    if (props.mask & TableRowPropertiesMask.UseHideCellMark) result.push(`${prefix}hideCellMark: ${props.hideCellMark}`);
    if (props.mask & TableRowPropertiesMask.UseTableRowAlignment) result.push(`${prefix}tableRowAlignment: ${TableRowAlignment[props.tableRowAlignment]}`);
    return result.join("\n");
  }
  static tableConditionalStyle(style, prefix = "") {
    const prefixNext = prefix + "\t";
    const result = [];
    result.push(`${prefix}tableProperties:\n${LogObjToStr.tableProperties(style.tableProperties, prefixNext)}`);
    result.push(`${prefix}tableRowProperties:\n${LogObjToStr.tableRowProperties(style.tableRowProperties, prefixNext)}`);
    result.push(`${prefix}tableCellProperties:\n${LogObjToStr.tableCellProperties(style.tableCellProperties, prefixNext)}`);
    return result.join("\n");
  }
  static tableStyle(style, prefix = "") {
    const prefixNext = prefix + "\t";
    const listOfActualCondStyles = [];
    for (let styleFlag of TableCellPropertiesMerger.conditionalTableStyleFormattingPriority) if (style.conditionalStyles[styleFlag]) listOfActualCondStyles.push(styleFlag);
    const condStylesHandler = styleFlag => `${prefix}${ConditionalTableStyleFormatting[styleFlag]}:\n${LogObjToStr.tableConditionalStyle(style.conditionalStyles[styleFlag], prefixNext)}`;
    const result = [];
    result.push(`${prefix}name: ${style.styleName}`);
    result.push(`${prefix}isDefault: ${style.isDefault}`);
    result.push(`${prefix}hidden: ${style.hidden}`);
    result.push(`${Log.map(condStylesHandler, listOfActualCondStyles).join("\n")}`);
    if (style.parent) result.push(`${LogObjToStr.tableStyle(style.parent, prefix)}`);
    return result.join("\n");
  }
  static rectangle(r, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}x: ${r.x}`);
    result.push(`${prefix}y: ${r.y}`);
    result.push(`${prefix}width: ${r.width}`);
    result.push(`${prefix}height: ${r.height}`);
    return result.join(sep);
  }
  static historyItemListLevelStateObject(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}value: ${obj.value}`);
    result.push(`${prefix}listLevelIndex: ${obj.listLevelIndex}`);
    result.push(`${prefix}numberingListIndex: ${obj.numberingListIndex}`);
    result.push(`${prefix}isAbstractNumberingList: ${obj.isAbstractNumberingList}`);
    return result.join(sep);
  }
  static historyItemTableStateObject(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}value: ${obj.value}`);
    result.push(`${prefix}value: ${obj.tableIndex}`);
    result.push(`${prefix}value: ${obj.tableNestedLevel}`);
    result.push(`${prefix}value: ${obj.tableStartPosition}`);
    return result.join(sep);
  }
  static historyItemTableRowStateObject(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}value: ${obj.value}`);
    result.push(`${prefix}value: ${obj.tableIndex}`);
    result.push(`${prefix}value: ${obj.tableNestedLevel}`);
    result.push(`${prefix}value: ${obj.tableStartPosition}`);
    result.push(`${prefix}value: ${obj.rowIndex}`);
    return result.join(sep);
  }
  static historyItemTableCellStateObject(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}value: ${obj.value}`);
    result.push(`${prefix}value: ${obj.tableIndex}`);
    result.push(`${prefix}value: ${obj.tableNestedLevel}`);
    result.push(`${prefix}value: ${obj.tableStartPosition}`);
    result.push(`${prefix}value: ${obj.rowIndex}`);
    result.push(`${prefix}value: ${obj.cellIndex}`);
    return result.join(sep);
  }
  static historyItemIntervalStateObject(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}value: ${obj.value}`);
    result.push(`${prefix}interval: ${LogObjToStr.fixedInterval(obj.interval)}`);
    return result.join(sep);
  }
  static size(info, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}width: ${info.width}`);
    result.push(`${prefix}height: ${info.height}`);
    return result.join(sep);
  }
  static cacheImageInfo(info, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}base64Defined?: ${info.base64 !== undefined}`);
    result.push(`${prefix}actualId: ${info.actualId}`);
    result.push(`${prefix}tmpId: ${info.tmpId}`);
    result.push(`${prefix}referenceInfo: ${info.referenceInfo}`);
    return result.join(sep);
  }
  static historyItemSectionStateObject(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}value: ${obj.value}`);
    result.push(`${prefix}sectionIndex: ${obj.sectionIndex}`);
    return result.join(sep);
  }
  static historyItemState(handler, obj, prefix = "\t", sep = "\n") {
    return LogListHelper.level_1(Log.w(3, Log.flip(Log.flip(handler, 0, 1), 1, 2))("", ", "), obj.objects, prefix, sep);
  }
  static hyperlinkInfo(info, prefix = "", sep = "\t") {
    const result = [];
    if (info) {
      result.push(`${prefix}uri: ${info.uri}`);
      result.push(`${prefix}tip: ${info.tip}`);
      result.push(`${prefix}anchor: ${info.anchor}`);
      result.push(`${prefix}visited: ${info.visited}`);
    }
    return result.join(sep);
  }
  static subDocumentInfoBase(info, prefix = "", sep = "\t") {
    const result = [];
    result.push(`${prefix}subDocumentId: ${info.subDocumentId}`);
    result.push(`${prefix}getType(): ${SubDocumentInfoType[info.getType()]}`);
    return result.join(sep);
  }
  static headerFooterSubDocumentInfoBase(info, prefix = "", sep = "\t") {
    const result = [];
    result.push(LogObjToStr.subDocumentInfoBase(info, prefix, sep));
    result.push(`${prefix}headerFooterType: ${HeaderFooterType[info.headerFooterType]}`);
    return result.join(sep);
  }
  static historyItemListLevelUseStateObject(obj, prefix = "", sep = "\t") {
    const result = [];
    result.push(LogObjToStr.historyItemListLevelStateObject(obj, prefix, sep));
    result.push(`${prefix}use: ${obj.use}`);
    return result.join(sep);
  }
  static historyItemIntervalState(handler, obj, prefix = "\t", sep = "\n") {
    const result = [];
    result.push(LogObjToStr.historyItemState(handler, obj, prefix, sep)());
    result.push(`${prefix}interval: ${LogObjToStr.fixedInterval(obj.interval)}`);
    return () => result.join(sep);
  }
}