<dx-popup #popup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="true" title="Bestätigungscode eingeben" width="400" height="277">
    <div *dxTemplate="let data of 'content'">
        <span>Um sich einloggen zu können, müssen Sie einen Bestätigungscode eingeben, welchen Sie in einer E-Mail bekommen haben sollten.</span>
        <dx-form class="login-form" #form [width]="'100%'" [formData]="formData" labelLocation="left" [colCount]="1">
            <dxi-item dataField="verifyCode" [colSpan]="2" width="200">
                <dxo-label [visible]="false" ></dxo-label>  
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
        </dx-form>
        <div *ngIf="error && error.length > 0">
            <span class="login-error-text color-error">{{error}}</span>
        </div>
    </div>
    <dxi-popup-toolbar-item widget="dxButton" location="before" toolbar="bottom"
        [options]="{ disabled: isLoading || formData === undefined || formData === null, text: 'Bestätigen', type: 'default', stylingMode: 'contained',  icon: 'check', onClick: onClickOk }">
    </dxi-popup-toolbar-item>
    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-popup-toolbar-item>
</dx-popup>