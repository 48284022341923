<dx-popup #popup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="true" title="Dokument aus Vorlage erstellen" width="800" height="620">
    <div *dxTemplate="let data of 'content'" class="cz-popup-content">
        <dx-data-grid class="vorlagen-datagrid" #vorlagenDataGrid [dataSource]="dataSource" height="350"
            (onSelectionChanged)="onSelectionChanged($event)" (onToolbarPreparing)="onToolbarPreparing($event)"
            (onContentReady)="onContentReady($event)">

            <dxo-remote-operations [filtering]="true" [paging]="true" [sorting]="true" [summary]="true"
                [grouping]="true" [groupPaging]="true">
            </dxo-remote-operations>


            <dxo-selection selectAllMode="page" mode="single"></dxo-selection>

            <dxo-paging [pageSize]="30"></dxo-paging>
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>

            <dxo-search-panel [width]="300" [visible]="true">
            </dxo-search-panel>

            <dxi-column dataField="bezeichnung" caption="Bezeichnung"></dxi-column>
            <dxi-column dataField="fileName" caption="Datei"></dxi-column>
            <dxi-column dataField="datum" caption="Änderungsdatum" [width]="150" dataType="date" format="dd.MM.yyyy">
            </dxi-column>
        </dx-data-grid>

        <dx-form class="edit-form" #form [width]="'100%'" [formData]="formData" labelLocation="left" [colCount]="1">
            <dxi-item dataField="bezeichnung" [label]="{text: 'Name des zu erstellenden Dokumentes'}" [colSpan]="2">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="bearbeiten" [label]="{text: ' '}">
                <dx-radio-group [items]="optionen" [(value)]="formData.bearbeiten" valueExpr="id" (onValueChanged)="onChangeBearbeiten($event)">
                </dx-radio-group>                
            </dxi-item>
        </dx-form>
    </div>
    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ disabled: !selectedItem || isLoading, text: 'Erstellen', type: 'default', stylingMode: 'contained',  icon: 'download', onClick: onClickErzeugen }">
    </dxi-popup-toolbar-item>
    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-popup-toolbar-item>
</dx-popup>