import { TableCell } from '../../../../../model/tables/main-structures/table-cell';
import { RtfTableCellProperties } from './properties/rtf-table-cell-properties';
export class RtfTableCell {
  constructor(row, _right = 0) {
    this.startPos = -1;
    this.endPos = -1;
    this.index = -1;
    this.columnSpan = 0;
    this.idForParentCellMap = RtfTableCell.__id++;
    this.row = row;
    this.properties = new RtfTableCellProperties();
  }
  get isEmpty() {
    return this.startPos == -1 || this.endPos == -1;
  }
  createCell(row, subDocument) {
    const cell = new TableCell(row, this.properties.coreProperties);
    const startPar = subDocument.getParagraphByPosition(this.startPos);
    cell.startParagraphPosition = subDocument.positionManager.registerPosition(startPar.startLogPosition.value);
    const cellMarkPos = this.endPos - 1;
    if (cellMarkPos <= this.startPos) cell.endParagrapPosition = subDocument.positionManager.registerPosition(startPar.getEndPosition());else {
      const endPar = subDocument.getParagraphByPosition(cellMarkPos);
      cell.endParagrapPosition = subDocument.positionManager.registerPosition(endPar.getEndPosition());
    }
    cell.columnSpan = this.columnSpan;
    this.properties.apply(cell);
    return cell;
  }
}
RtfTableCell.__id = 0;