export var ShadingPattern;
(function (ShadingPattern) {
  ShadingPattern[ShadingPattern["Clear"] = 0] = "Clear";
  ShadingPattern[ShadingPattern["DiagCross"] = 1] = "DiagCross";
  ShadingPattern[ShadingPattern["DiagStripe"] = 2] = "DiagStripe";
  ShadingPattern[ShadingPattern["HorzCross"] = 3] = "HorzCross";
  ShadingPattern[ShadingPattern["HorzStripe"] = 4] = "HorzStripe";
  ShadingPattern[ShadingPattern["Nil"] = 5] = "Nil";
  ShadingPattern[ShadingPattern["Pct5"] = 6] = "Pct5";
  ShadingPattern[ShadingPattern["Pct10"] = 7] = "Pct10";
  ShadingPattern[ShadingPattern["Pct12"] = 8] = "Pct12";
  ShadingPattern[ShadingPattern["Pct15"] = 9] = "Pct15";
  ShadingPattern[ShadingPattern["Pct20"] = 10] = "Pct20";
  ShadingPattern[ShadingPattern["Pct25"] = 11] = "Pct25";
  ShadingPattern[ShadingPattern["Pct30"] = 12] = "Pct30";
  ShadingPattern[ShadingPattern["Pct35"] = 13] = "Pct35";
  ShadingPattern[ShadingPattern["Pct37"] = 14] = "Pct37";
  ShadingPattern[ShadingPattern["Pct40"] = 15] = "Pct40";
  ShadingPattern[ShadingPattern["Pct45"] = 16] = "Pct45";
  ShadingPattern[ShadingPattern["Pct50"] = 17] = "Pct50";
  ShadingPattern[ShadingPattern["Pct55"] = 18] = "Pct55";
  ShadingPattern[ShadingPattern["Pct60"] = 19] = "Pct60";
  ShadingPattern[ShadingPattern["Pct62"] = 20] = "Pct62";
  ShadingPattern[ShadingPattern["Pct65"] = 21] = "Pct65";
  ShadingPattern[ShadingPattern["Pct70"] = 22] = "Pct70";
  ShadingPattern[ShadingPattern["Pct75"] = 23] = "Pct75";
  ShadingPattern[ShadingPattern["Pct80"] = 24] = "Pct80";
  ShadingPattern[ShadingPattern["Pct85"] = 25] = "Pct85";
  ShadingPattern[ShadingPattern["Pct87"] = 26] = "Pct87";
  ShadingPattern[ShadingPattern["Pct90"] = 27] = "Pct90";
  ShadingPattern[ShadingPattern["Pct95"] = 28] = "Pct95";
  ShadingPattern[ShadingPattern["ReverseDiagStripe"] = 29] = "ReverseDiagStripe";
  ShadingPattern[ShadingPattern["Solid"] = 30] = "Solid";
  ShadingPattern[ShadingPattern["ThinDiagCross"] = 31] = "ThinDiagCross";
  ShadingPattern[ShadingPattern["ThinDiagStripe"] = 32] = "ThinDiagStripe";
  ShadingPattern[ShadingPattern["ThinHorzCross"] = 33] = "ThinHorzCross";
  ShadingPattern[ShadingPattern["ThinHorzStripe"] = 34] = "ThinHorzStripe";
  ShadingPattern[ShadingPattern["ThinReverseDiagStripe"] = 35] = "ThinReverseDiagStripe";
  ShadingPattern[ShadingPattern["ThinVertStripe"] = 36] = "ThinVertStripe";
  ShadingPattern[ShadingPattern["VertStripe"] = 37] = "VertStripe";
  ShadingPattern[ShadingPattern["Pct2"] = 38] = "Pct2";
  ShadingPattern[ShadingPattern["Pct7"] = 39] = "Pct7";
  ShadingPattern[ShadingPattern["Pct17"] = 40] = "Pct17";
  ShadingPattern[ShadingPattern["Pct22"] = 41] = "Pct22";
  ShadingPattern[ShadingPattern["Pct27"] = 42] = "Pct27";
  ShadingPattern[ShadingPattern["Pct32"] = 43] = "Pct32";
  ShadingPattern[ShadingPattern["Pct42"] = 44] = "Pct42";
  ShadingPattern[ShadingPattern["Pct47"] = 45] = "Pct47";
  ShadingPattern[ShadingPattern["Pct52"] = 46] = "Pct52";
  ShadingPattern[ShadingPattern["Pct57"] = 47] = "Pct57";
  ShadingPattern[ShadingPattern["Pct67"] = 48] = "Pct67";
  ShadingPattern[ShadingPattern["Pct72"] = 49] = "Pct72";
  ShadingPattern[ShadingPattern["Pct77"] = 50] = "Pct77";
  ShadingPattern[ShadingPattern["Pct82"] = 51] = "Pct82";
  ShadingPattern[ShadingPattern["Pct92"] = 52] = "Pct92";
  ShadingPattern[ShadingPattern["Pct97"] = 53] = "Pct97";
})(ShadingPattern || (ShadingPattern = {}));