export class DrawingValueConstants {}
DrawingValueConstants.ThousandthOfPercentage = 100000;
DrawingValueConstants.MinCoordinate = -27273042329600;
DrawingValueConstants.MaxCoordinate = 27273042316900;
DrawingValueConstants.OnePositiveFixedAngle = 60000;
DrawingValueConstants.MaxPositiveFixedAngle = 21600000;
DrawingValueConstants.MaxFixedAngle = 5400000;
DrawingValueConstants.MaxFOVAngle = 10800000;
DrawingValueConstants.MaxOutlineWidth = 20116800;
DrawingValueConstants.MaxPositiveCoordinate32 = 51206400;
DrawingValueConstants.MinTextIndentLevel = -2;
DrawingValueConstants.MaxTextIndentLevel = 8;
DrawingValueConstants.MaxTextSpacingPercent = 13200000;
DrawingValueConstants.MaxTextSpacingPoints = 158400;
DrawingValueConstants.MaxStartAtNumValue = 32767;
DrawingValueConstants.MinTextBulletSizePercent = 25000;
DrawingValueConstants.MaxTextBulletSizePercent = 400000;
DrawingValueConstants.MinTextBulletSizePoints = 100;
DrawingValueConstants.MaxTextBulletSizePoints = 400000;
DrawingValueConstants.MaxWidthInPoints = 1584;
DrawingValueConstants.EmusInPoint = 12700;