export var FileTabItemId;
(function (FileTabItemId) {
  FileTabItemId[FileTabItemId["CreateNewDocument"] = 412] = "CreateNewDocument";
  FileTabItemId[FileTabItemId["OpenDocument"] = 410] = "OpenDocument";
  FileTabItemId[FileTabItemId["ExportDocument"] = 413] = "ExportDocument";
  FileTabItemId[FileTabItemId["Download"] = 414] = "Download";
  FileTabItemId[FileTabItemId["DownloadDocx"] = 418] = "DownloadDocx";
  FileTabItemId[FileTabItemId["DownloadRtf"] = 419] = "DownloadRtf";
  FileTabItemId[FileTabItemId["DownloadTxt"] = 420] = "DownloadTxt";
  FileTabItemId[FileTabItemId["DownloadHtml"] = 421] = "DownloadHtml";
  FileTabItemId[FileTabItemId["PrintDocument"] = 416] = "PrintDocument";
})(FileTabItemId || (FileTabItemId = {}));
export var HomeTabItemId;
(function (HomeTabItemId) {
  HomeTabItemId[HomeTabItemId["ChangeCaseMenu"] = 431] = "ChangeCaseMenu";
  HomeTabItemId[HomeTabItemId["AlignParagraphMenu"] = 432] = "AlignParagraphMenu";
  HomeTabItemId[HomeTabItemId["LineSpacingMenu"] = 433] = "LineSpacingMenu";
  HomeTabItemId[HomeTabItemId["Undo"] = 6] = "Undo";
  HomeTabItemId[HomeTabItemId["Redo"] = 7] = "Redo";
  HomeTabItemId[HomeTabItemId["Cut"] = 10] = "Cut";
  HomeTabItemId[HomeTabItemId["Copy"] = 9] = "Copy";
  HomeTabItemId[HomeTabItemId["Paste"] = 8] = "Paste";
  HomeTabItemId[HomeTabItemId["ChangeFontName"] = 11] = "ChangeFontName";
  HomeTabItemId[HomeTabItemId["ChangeFontSize"] = 13] = "ChangeFontSize";
  HomeTabItemId[HomeTabItemId["IncreaseFontSize"] = 14] = "IncreaseFontSize";
  HomeTabItemId[HomeTabItemId["DecreaseFontSize"] = 15] = "DecreaseFontSize";
  HomeTabItemId[HomeTabItemId["ChangeFontForeColor"] = 28] = "ChangeFontForeColor";
  HomeTabItemId[HomeTabItemId["ChangeFontHighlightColor"] = 29] = "ChangeFontHighlightColor";
  HomeTabItemId[HomeTabItemId["ShowFontDialog"] = 52] = "ShowFontDialog";
  HomeTabItemId[HomeTabItemId["ToggleFontBold"] = 20] = "ToggleFontBold";
  HomeTabItemId[HomeTabItemId["ToggleFontItalic"] = 21] = "ToggleFontItalic";
  HomeTabItemId[HomeTabItemId["ToggleFontUnderline"] = 22] = "ToggleFontUnderline";
  HomeTabItemId[HomeTabItemId["ToggleFontDoubleUnderline"] = 23] = "ToggleFontDoubleUnderline";
  HomeTabItemId[HomeTabItemId["ToggleFontStrikeout"] = 24] = "ToggleFontStrikeout";
  HomeTabItemId[HomeTabItemId["ToggleFontSubscript"] = 27] = "ToggleFontSubscript";
  HomeTabItemId[HomeTabItemId["ToggleFontSuperscript"] = 26] = "ToggleFontSuperscript";
  HomeTabItemId[HomeTabItemId["CapitalizationSentenceCase"] = 207] = "CapitalizationSentenceCase";
  HomeTabItemId[HomeTabItemId["CapitalizationUpperCase"] = 16] = "CapitalizationUpperCase";
  HomeTabItemId[HomeTabItemId["CapitalizationLowerCase"] = 17] = "CapitalizationLowerCase";
  HomeTabItemId[HomeTabItemId["CapitalizeEachWordTextCase"] = 18] = "CapitalizeEachWordTextCase";
  HomeTabItemId[HomeTabItemId["CapitalizationToggleCase"] = 19] = "CapitalizationToggleCase";
  HomeTabItemId[HomeTabItemId["ClearFormatting"] = 30] = "ClearFormatting";
  HomeTabItemId[HomeTabItemId["ToggleBulletedList"] = 31] = "ToggleBulletedList";
  HomeTabItemId[HomeTabItemId["ToggleNumberedList"] = 32] = "ToggleNumberedList";
  HomeTabItemId[HomeTabItemId["ToggleMultilevelList"] = 33] = "ToggleMultilevelList";
  HomeTabItemId[HomeTabItemId["ToggleParagraphAlignmentLeft"] = 37] = "ToggleParagraphAlignmentLeft";
  HomeTabItemId[HomeTabItemId["ToggleParagraphAlignmentCenter"] = 38] = "ToggleParagraphAlignmentCenter";
  HomeTabItemId[HomeTabItemId["ToggleParagraphAlignmentRight"] = 39] = "ToggleParagraphAlignmentRight";
  HomeTabItemId[HomeTabItemId["ToggleParagraphAlignmentJustify"] = 40] = "ToggleParagraphAlignmentJustify";
  HomeTabItemId[HomeTabItemId["ToggleShowHiddenSymbols"] = 36] = "ToggleShowHiddenSymbols";
  HomeTabItemId[HomeTabItemId["SetSingleParagraphSpacing"] = 41] = "SetSingleParagraphSpacing";
  HomeTabItemId[HomeTabItemId["SetSesquialteralParagraphSpacing"] = 42] = "SetSesquialteralParagraphSpacing";
  HomeTabItemId[HomeTabItemId["SetDoubleParagraphSpacing"] = 43] = "SetDoubleParagraphSpacing";
  HomeTabItemId[HomeTabItemId["AddSpacingBeforeParagraph"] = 45] = "AddSpacingBeforeParagraph";
  HomeTabItemId[HomeTabItemId["AddSpacingAfterParagraph"] = 46] = "AddSpacingAfterParagraph";
  HomeTabItemId[HomeTabItemId["RemoveSpacingBeforeParagraph"] = 47] = "RemoveSpacingBeforeParagraph";
  HomeTabItemId[HomeTabItemId["RemoveSpacingAfterParagraph"] = 48] = "RemoveSpacingAfterParagraph";
  HomeTabItemId[HomeTabItemId["ChangeParagraphBackColor"] = 49] = "ChangeParagraphBackColor";
  HomeTabItemId[HomeTabItemId["ChangeStyle"] = 12] = "ChangeStyle";
  HomeTabItemId[HomeTabItemId["Find"] = 50] = "Find";
  HomeTabItemId[HomeTabItemId["Replace"] = 51] = "Replace";
})(HomeTabItemId || (HomeTabItemId = {}));
export var InsertTabItemId;
(function (InsertTabItemId) {
  InsertTabItemId[InsertTabItemId["ShowInsertTableDialog"] = 55] = "ShowInsertTableDialog";
  InsertTabItemId[InsertTabItemId["InsertPictureLocally"] = 411] = "InsertPictureLocally";
  InsertTabItemId[InsertTabItemId["ShowBookmarkDialog"] = 58] = "ShowBookmarkDialog";
  InsertTabItemId[InsertTabItemId["ShowHyperlinkDialog"] = 59] = "ShowHyperlinkDialog";
  InsertTabItemId[InsertTabItemId["InsertHeader"] = 220] = "InsertHeader";
  InsertTabItemId[InsertTabItemId["InsertFooter"] = 221] = "InsertFooter";
  InsertTabItemId[InsertTabItemId["InsertPageNumberField"] = 235] = "InsertPageNumberField";
  InsertTabItemId[InsertTabItemId["InsertPageCountField"] = 236] = "InsertPageCountField";
  InsertTabItemId[InsertTabItemId["InsertFloatingTextBox"] = 368] = "InsertFloatingTextBox";
})(InsertTabItemId || (InsertTabItemId = {}));
export var PageLayoutTabItemId;
(function (PageLayoutTabItemId) {
  PageLayoutTabItemId[PageLayoutTabItemId["MarginsMenu"] = 434] = "MarginsMenu";
  PageLayoutTabItemId[PageLayoutTabItemId["OrientationMenu"] = 435] = "OrientationMenu";
  PageLayoutTabItemId[PageLayoutTabItemId["SizeMenu"] = 436] = "SizeMenu";
  PageLayoutTabItemId[PageLayoutTabItemId["ColumnsMenu"] = 437] = "ColumnsMenu";
  PageLayoutTabItemId[PageLayoutTabItemId["BreaksMenu"] = 438] = "BreaksMenu";
  PageLayoutTabItemId[PageLayoutTabItemId["SetNormalSectionPageMargins"] = 66] = "SetNormalSectionPageMargins";
  PageLayoutTabItemId[PageLayoutTabItemId["SetNarrowSectionPageMargins"] = 67] = "SetNarrowSectionPageMargins";
  PageLayoutTabItemId[PageLayoutTabItemId["SetModerateSectionPageMargins"] = 68] = "SetModerateSectionPageMargins";
  PageLayoutTabItemId[PageLayoutTabItemId["SetWideSectionPageMargins"] = 69] = "SetWideSectionPageMargins";
  PageLayoutTabItemId[PageLayoutTabItemId["ShowMarginsPageSetupDialog"] = 70] = "ShowMarginsPageSetupDialog";
  PageLayoutTabItemId[PageLayoutTabItemId["SetPortraitPageOrientation"] = 71] = "SetPortraitPageOrientation";
  PageLayoutTabItemId[PageLayoutTabItemId["SetLandscapePageOrientation"] = 72] = "SetLandscapePageOrientation";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionLetterPaperSize"] = 162] = "SetSectionLetterPaperSize";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionLegalPaperSize"] = 99] = "SetSectionLegalPaperSize";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionFolioPaperSize"] = 100] = "SetSectionFolioPaperSize";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionA4PaperSize"] = 101] = "SetSectionA4PaperSize";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionB5PaperSize"] = 104] = "SetSectionB5PaperSize";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionExecutivePaperSize"] = 105] = "SetSectionExecutivePaperSize";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionA5PaperSize"] = 102] = "SetSectionA5PaperSize";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionA6PaperSize"] = 103] = "SetSectionA6PaperSize";
  PageLayoutTabItemId[PageLayoutTabItemId["ShowPaperPageSetupDialog"] = 73] = "ShowPaperPageSetupDialog";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionOneColumn"] = 74] = "SetSectionOneColumn";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionTwoColumns"] = 75] = "SetSectionTwoColumns";
  PageLayoutTabItemId[PageLayoutTabItemId["SetSectionThreeColumns"] = 76] = "SetSectionThreeColumns";
  PageLayoutTabItemId[PageLayoutTabItemId["InsertPageBreak"] = 54] = "InsertPageBreak";
  PageLayoutTabItemId[PageLayoutTabItemId["InsertColumnBreak"] = 79] = "InsertColumnBreak";
  PageLayoutTabItemId[PageLayoutTabItemId["InsertSectionBreakNextPage"] = 80] = "InsertSectionBreakNextPage";
  PageLayoutTabItemId[PageLayoutTabItemId["InsertSectionBreakEvenPage"] = 81] = "InsertSectionBreakEvenPage";
  PageLayoutTabItemId[PageLayoutTabItemId["InsertSectionBreakOddPage"] = 82] = "InsertSectionBreakOddPage";
  PageLayoutTabItemId[PageLayoutTabItemId["ChangePageColor"] = 90] = "ChangePageColor";
})(PageLayoutTabItemId || (PageLayoutTabItemId = {}));
export var ReferencesTabItemId;
(function (ReferencesTabItemId) {
  ReferencesTabItemId[ReferencesTabItemId["AddTextMenu"] = 439] = "AddTextMenu";
  ReferencesTabItemId[ReferencesTabItemId["InsertCaptionMenu"] = 440] = "InsertCaptionMenu";
  ReferencesTabItemId[ReferencesTabItemId["InsertTableOfFiguresMenu"] = 441] = "InsertTableOfFiguresMenu";
  ReferencesTabItemId[ReferencesTabItemId["InsertTableOfContents"] = 377] = "InsertTableOfContents";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphBodyTextLevel"] = 385] = "SetParagraphBodyTextLevel";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading1Level"] = 386] = "SetParagraphHeading1Level";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading2Level"] = 387] = "SetParagraphHeading2Level";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading3Level"] = 388] = "SetParagraphHeading3Level";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading4Level"] = 389] = "SetParagraphHeading4Level";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading5Level"] = 390] = "SetParagraphHeading5Level";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading6Level"] = 391] = "SetParagraphHeading6Level";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading7Level"] = 392] = "SetParagraphHeading7Level";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading8Level"] = 393] = "SetParagraphHeading8Level";
  ReferencesTabItemId[ReferencesTabItemId["SetParagraphHeading9Level"] = 394] = "SetParagraphHeading9Level";
  ReferencesTabItemId[ReferencesTabItemId["CreateFigureCaptionField"] = 382] = "CreateFigureCaptionField";
  ReferencesTabItemId[ReferencesTabItemId["CreateTableCaptionField"] = 383] = "CreateTableCaptionField";
  ReferencesTabItemId[ReferencesTabItemId["CreateEquationCaptionField"] = 381] = "CreateEquationCaptionField";
  ReferencesTabItemId[ReferencesTabItemId["CreateTableOfFiguresField"] = 379] = "CreateTableOfFiguresField";
  ReferencesTabItemId[ReferencesTabItemId["CreateTableOfTablesField"] = 380] = "CreateTableOfTablesField";
  ReferencesTabItemId[ReferencesTabItemId["CreateTableOfEquationsField"] = 378] = "CreateTableOfEquationsField";
  ReferencesTabItemId[ReferencesTabItemId["UpdateTableOfContents"] = 384] = "UpdateTableOfContents";
})(ReferencesTabItemId || (ReferencesTabItemId = {}));
export var MailMergeTabItemId;
(function (MailMergeTabItemId) {
  MailMergeTabItemId[MailMergeTabItemId["CreateFieldMenu"] = 442] = "CreateFieldMenu";
  MailMergeTabItemId[MailMergeTabItemId["CreateField"] = 178] = "CreateField";
  MailMergeTabItemId[MailMergeTabItemId["CreateDateField"] = 203] = "CreateDateField";
  MailMergeTabItemId[MailMergeTabItemId["CreateTimeField"] = 204] = "CreateTimeField";
  MailMergeTabItemId[MailMergeTabItemId["CreatePageField"] = 205] = "CreatePageField";
  MailMergeTabItemId[MailMergeTabItemId["CreateNumPagesField"] = 236] = "CreateNumPagesField";
  MailMergeTabItemId[MailMergeTabItemId["CreateEmptyMergeField"] = 372] = "CreateEmptyMergeField";
  MailMergeTabItemId[MailMergeTabItemId["CreateEmptyDocVariableField"] = 373] = "CreateEmptyDocVariableField";
  MailMergeTabItemId[MailMergeTabItemId["CreateEmptyIfField"] = 459] = "CreateEmptyIfField";
  MailMergeTabItemId[MailMergeTabItemId["ShowInsertMergeFieldDialog"] = 214] = "ShowInsertMergeFieldDialog";
  MailMergeTabItemId[MailMergeTabItemId["ToggleViewMergedData"] = 213] = "ToggleViewMergedData";
  MailMergeTabItemId[MailMergeTabItemId["ShowAllFieldCodes"] = 186] = "ShowAllFieldCodes";
  MailMergeTabItemId[MailMergeTabItemId["ShowAllFieldResults"] = 187] = "ShowAllFieldResults";
  MailMergeTabItemId[MailMergeTabItemId["UpdateAllFields"] = 200] = "UpdateAllFields";
  MailMergeTabItemId[MailMergeTabItemId["GoToFirstDataRecord"] = 209] = "GoToFirstDataRecord";
  MailMergeTabItemId[MailMergeTabItemId["GoToPreviousDataRecord"] = 210] = "GoToPreviousDataRecord";
  MailMergeTabItemId[MailMergeTabItemId["GoToNextDataRecord"] = 211] = "GoToNextDataRecord";
  MailMergeTabItemId[MailMergeTabItemId["GoToLastDataRecord"] = 212] = "GoToLastDataRecord";
  MailMergeTabItemId[MailMergeTabItemId["ShowMailMergeDialog"] = 216] = "ShowMailMergeDialog";
})(MailMergeTabItemId || (MailMergeTabItemId = {}));
export var ViewTabItemId;
(function (ViewTabItemId) {
  ViewTabItemId[ViewTabItemId["SwitchToSimpleView"] = 405] = "SwitchToSimpleView";
  ViewTabItemId[ViewTabItemId["SwitchToPrintLayout"] = 406] = "SwitchToPrintLayout";
  ViewTabItemId[ViewTabItemId["ToggleShowHorizontalRuler"] = 94] = "ToggleShowHorizontalRuler";
  ViewTabItemId[ViewTabItemId["ToggleFullScreen"] = 98] = "ToggleFullScreen";
})(ViewTabItemId || (ViewTabItemId = {}));
export var HeaderAndFooterTabItemId;
(function (HeaderAndFooterTabItemId) {
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["GoToPageHeader"] = 227] = "GoToPageHeader";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["GoToPageFooter"] = 228] = "GoToPageFooter";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["GoToPreviousPageHeaderFooter"] = 230] = "GoToPreviousPageHeaderFooter";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["GoToNextPageHeaderFooter"] = 229] = "GoToNextPageHeaderFooter";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["LinkHeaderFooterToPrevious"] = 222] = "LinkHeaderFooterToPrevious";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["ToggleDifferentFirstPage"] = 231] = "ToggleDifferentFirstPage";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["ToggleDifferentOddAndEvenPages"] = 232] = "ToggleDifferentOddAndEvenPages";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["ChangeHeaderOffset"] = 330] = "ChangeHeaderOffset";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["ChangeFooterOffset"] = 331] = "ChangeFooterOffset";
  HeaderAndFooterTabItemId[HeaderAndFooterTabItemId["ClosePageHeaderFooter"] = 233] = "ClosePageHeaderFooter";
})(HeaderAndFooterTabItemId || (HeaderAndFooterTabItemId = {}));
export var TableDesignTabItemId;
(function (TableDesignTabItemId) {
  TableDesignTabItemId[TableDesignTabItemId["TableStyleOptionsMenu"] = 443] = "TableStyleOptionsMenu";
  TableDesignTabItemId[TableDesignTabItemId["BordersMenu"] = 444] = "BordersMenu";
  TableDesignTabItemId[TableDesignTabItemId["ToggleFirstRow"] = 280] = "ToggleFirstRow";
  TableDesignTabItemId[TableDesignTabItemId["ToggleLastRow"] = 281] = "ToggleLastRow";
  TableDesignTabItemId[TableDesignTabItemId["ToggleBandedRows"] = 284] = "ToggleBandedRows";
  TableDesignTabItemId[TableDesignTabItemId["ToggleFirstColumn"] = 282] = "ToggleFirstColumn";
  TableDesignTabItemId[TableDesignTabItemId["ToggleLastColumn"] = 283] = "ToggleLastColumn";
  TableDesignTabItemId[TableDesignTabItemId["ToggleBandedColumn"] = 285] = "ToggleBandedColumn";
  TableDesignTabItemId[TableDesignTabItemId["ApplyTableStyle"] = 269] = "ApplyTableStyle";
  TableDesignTabItemId[TableDesignTabItemId["ChangeTableBorderStyleRepositoryItem"] = 292] = "ChangeTableBorderStyleRepositoryItem";
  TableDesignTabItemId[TableDesignTabItemId["ChangeTableBorderWidthRepositoryItem"] = 291] = "ChangeTableBorderWidthRepositoryItem";
  TableDesignTabItemId[TableDesignTabItemId["ChangeTableBorderColorRepositoryItem"] = 290] = "ChangeTableBorderColorRepositoryItem";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellsBottomBorder"] = 272] = "ToggleTableCellsBottomBorder";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellsTopBorder"] = 270] = "ToggleTableCellsTopBorder";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellsLeftBorder"] = 273] = "ToggleTableCellsLeftBorder";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellsRightBorder"] = 271] = "ToggleTableCellsRightBorder";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellNoBorder"] = 274] = "ToggleTableCellNoBorder";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellAllBorders"] = 275] = "ToggleTableCellAllBorders";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellOutsideBorders"] = 279] = "ToggleTableCellOutsideBorders";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellInsideBorders"] = 276] = "ToggleTableCellInsideBorders";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellInsideHorizontalBorders"] = 277] = "ToggleTableCellInsideHorizontalBorders";
  TableDesignTabItemId[TableDesignTabItemId["ToggleTableCellInsideVerticalBorders"] = 278] = "ToggleTableCellInsideVerticalBorders";
  TableDesignTabItemId[TableDesignTabItemId["ToggleShowTableGridLines"] = 295] = "ToggleShowTableGridLines";
  TableDesignTabItemId[TableDesignTabItemId["ChangeTableCellShading"] = 294] = "ChangeTableCellShading";
})(TableDesignTabItemId || (TableDesignTabItemId = {}));
export var TableLayoutTabItemId;
(function (TableLayoutTabItemId) {
  TableLayoutTabItemId[TableLayoutTabItemId["SelectMenu"] = 445] = "SelectMenu";
  TableLayoutTabItemId[TableLayoutTabItemId["DeleteMenu"] = 446] = "DeleteMenu";
  TableLayoutTabItemId[TableLayoutTabItemId["InsertMenu"] = 447] = "InsertMenu";
  TableLayoutTabItemId[TableLayoutTabItemId["AutoFitMenu"] = 448] = "AutoFitMenu";
  TableLayoutTabItemId[TableLayoutTabItemId["AlignmentMenu"] = 449] = "AlignmentMenu";
  TableLayoutTabItemId[TableLayoutTabItemId["SelectTableCell"] = 286] = "SelectTableCell";
  TableLayoutTabItemId[TableLayoutTabItemId["SelectTableColumn"] = 287] = "SelectTableColumn";
  TableLayoutTabItemId[TableLayoutTabItemId["SelectTableRow"] = 288] = "SelectTableRow";
  TableLayoutTabItemId[TableLayoutTabItemId["SelectTable"] = 289] = "SelectTable";
  TableLayoutTabItemId[TableLayoutTabItemId["ToggleShowTableGridLines"] = 295] = "ToggleShowTableGridLines";
  TableLayoutTabItemId[TableLayoutTabItemId["ShowDeleteTableCellsDialog"] = 253] = "ShowDeleteTableCellsDialog";
  TableLayoutTabItemId[TableLayoutTabItemId["DeleteTableColumns"] = 248] = "DeleteTableColumns";
  TableLayoutTabItemId[TableLayoutTabItemId["DeleteTableRows"] = 247] = "DeleteTableRows";
  TableLayoutTabItemId[TableLayoutTabItemId["DeleteTable"] = 251] = "DeleteTable";
  TableLayoutTabItemId[TableLayoutTabItemId["InsertTableRowAbove"] = 246] = "InsertTableRowAbove";
  TableLayoutTabItemId[TableLayoutTabItemId["InsertTableRowBelow"] = 245] = "InsertTableRowBelow";
  TableLayoutTabItemId[TableLayoutTabItemId["InsertTableColumnToTheLeft"] = 243] = "InsertTableColumnToTheLeft";
  TableLayoutTabItemId[TableLayoutTabItemId["InsertTableColumnToTheRight"] = 244] = "InsertTableColumnToTheRight";
  TableLayoutTabItemId[TableLayoutTabItemId["MergeTableCells"] = 254] = "MergeTableCells";
  TableLayoutTabItemId[TableLayoutTabItemId["ShowSplitTableCellsDialog"] = 255] = "ShowSplitTableCellsDialog";
  TableLayoutTabItemId[TableLayoutTabItemId["SetAutoFitContents"] = 399] = "SetAutoFitContents";
  TableLayoutTabItemId[TableLayoutTabItemId["SetAutoFitWindow"] = 400] = "SetAutoFitWindow";
  TableLayoutTabItemId[TableLayoutTabItemId["SetFixedColumnWidth"] = 401] = "SetFixedColumnWidth";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignTopLeft"] = 260] = "TableCellAlignTopLeft";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignTopCenter"] = 261] = "TableCellAlignTopCenter";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignTopRight"] = 262] = "TableCellAlignTopRight";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignMiddleLeft"] = 263] = "TableCellAlignMiddleLeft";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignMiddleCenter"] = 264] = "TableCellAlignMiddleCenter";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignMiddleRight"] = 265] = "TableCellAlignMiddleRight";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignBottomLeft"] = 266] = "TableCellAlignBottomLeft";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignBottomCenter"] = 267] = "TableCellAlignBottomCenter";
  TableLayoutTabItemId[TableLayoutTabItemId["TableCellAlignBottomRight"] = 268] = "TableCellAlignBottomRight";
})(TableLayoutTabItemId || (TableLayoutTabItemId = {}));
export var FloatingObjectsFormatTabItemId;
(function (FloatingObjectsFormatTabItemId) {
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["WrapTextMenu"] = 450] = "WrapTextMenu";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["PositionMenu"] = 451] = "PositionMenu";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["BringForwardMenu"] = 452] = "BringForwardMenu";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SendBackwardMenu"] = 453] = "SendBackwardMenu";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["ChangeFloatingObjectFillColor"] = 343] = "ChangeFloatingObjectFillColor";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["ChangeFloatingObjectOutlineColor"] = 344] = "ChangeFloatingObjectOutlineColor";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["ChangeFloatingObjectOutlineWidth"] = 345] = "ChangeFloatingObjectOutlineWidth";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectInlineTextWrapType"] = 374] = "SetFloatingObjectInlineTextWrapType";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectSquareTextWrapType"] = 346] = "SetFloatingObjectSquareTextWrapType";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectTightTextWrapType"] = 347] = "SetFloatingObjectTightTextWrapType";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectThroughTextWrapType"] = 348] = "SetFloatingObjectThroughTextWrapType";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectTopAndBottomTextWrapType"] = 349] = "SetFloatingObjectTopAndBottomTextWrapType";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectBehindTextWrapType"] = 350] = "SetFloatingObjectBehindTextWrapType";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectInFrontOfTextWrapType"] = 351] = "SetFloatingObjectInFrontOfTextWrapType";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectTopLeftAlignment"] = 352] = "SetFloatingObjectTopLeftAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectTopCenterAlignment"] = 353] = "SetFloatingObjectTopCenterAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectTopRightAlignment"] = 354] = "SetFloatingObjectTopRightAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectMiddleLeftAlignment"] = 355] = "SetFloatingObjectMiddleLeftAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectMiddleCenterAlignment"] = 356] = "SetFloatingObjectMiddleCenterAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectMiddleRightAlignment"] = 357] = "SetFloatingObjectMiddleRightAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectBottomLeftAlignment"] = 358] = "SetFloatingObjectBottomLeftAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectBottomCenterAlignment"] = 359] = "SetFloatingObjectBottomCenterAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["SetFloatingObjectBottomRightAlignment"] = 360] = "SetFloatingObjectBottomRightAlignment";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["FloatingObjectBringForward"] = 361] = "FloatingObjectBringForward";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["FloatingObjectBringToFront"] = 362] = "FloatingObjectBringToFront";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["FloatingObjectBringInFrontOfText"] = 363] = "FloatingObjectBringInFrontOfText";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["FloatingObjectSendBackward"] = 364] = "FloatingObjectSendBackward";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["FloatingObjectSendToBack"] = 365] = "FloatingObjectSendToBack";
  FloatingObjectsFormatTabItemId[FloatingObjectsFormatTabItemId["FloatingObjectSendBehindText"] = 366] = "FloatingObjectSendBehindText";
})(FloatingObjectsFormatTabItemId || (FloatingObjectsFormatTabItemId = {}));