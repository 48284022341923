import { EmptyBatchUpdatableObject } from "@devexpress/utils/lib/class/batch-updatable";
import { ClientModelManager } from "../../../model-manager";
import { RangeCopy } from "../../../model/manipulators/range/create-range-copy-operation";
import { SubDocumentIntervals } from "../../../model/sub-document";
import { HtmlDocumentExporter } from "./html-document-exporter";
import { DocumentExporterOptions } from "../../options";
export function getHtmlFromSubDocument(processor, subDocument, coreInterval) {
  const rangeCopy = RangeCopy.create(new SubDocumentIntervals(subDocument, [coreInterval]));
  const modelManager = new ClientModelManager(rangeCopy.model, processor.modelManager.richOptions, new EmptyBatchUpdatableObject());
  const exportModelOptions = processor.getExportModelOptions({
    modelManager: modelManager
  });
  return new HtmlDocumentExporter(exportModelOptions, new DocumentExporterOptions()).exportAsString();
}