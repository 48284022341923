export var DestinationType;
(function (DestinationType) {
  DestinationType[DestinationType["ShapePictureDestination"] = 0] = "ShapePictureDestination";
  DestinationType[DestinationType["SectionHeaderFooterDestinationBase"] = 1] = "SectionHeaderFooterDestinationBase";
  DestinationType[DestinationType["ShapePropertiesDestinationBase"] = 2] = "ShapePropertiesDestinationBase";
  DestinationType[DestinationType["ShapeDestinationBase"] = 3] = "ShapeDestinationBase";
  DestinationType[DestinationType["HyperlinkPropertyValueDestination"] = 4] = "HyperlinkPropertyValueDestination";
  DestinationType[DestinationType["RangePermissionDestinationBase"] = 5] = "RangePermissionDestinationBase";
  DestinationType[DestinationType["DestinationOldParagraphNumberingBase"] = 6] = "DestinationOldParagraphNumberingBase";
  DestinationType[DestinationType["FieldSubDestination"] = 7] = "FieldSubDestination";
  DestinationType[DestinationType["UnicodeStringValueDestination"] = 8] = "UnicodeStringValueDestination";
  DestinationType[DestinationType["StringValueDestinationBase"] = 9] = "StringValueDestinationBase";
  DestinationType[DestinationType["StringValueDestination"] = 10] = "StringValueDestination";
  DestinationType[DestinationType["HexContentDestination"] = 11] = "HexContentDestination";
  DestinationType[DestinationType["BookmarkDestinationBase"] = 12] = "BookmarkDestinationBase";
  DestinationType[DestinationType["EmptyDestination"] = 13] = "EmptyDestination";
  DestinationType[DestinationType["UnicodeDestination"] = 14] = "UnicodeDestination";
  DestinationType[DestinationType["SkipDestination"] = 15] = "SkipDestination";
  DestinationType[DestinationType["DestinationRevisionAuthorTable"] = 16] = "DestinationRevisionAuthorTable";
  DestinationType[DestinationType["UserTableDestination"] = 17] = "UserTableDestination";
  DestinationType[DestinationType["FontTableDestination"] = 18] = "FontTableDestination";
  DestinationType[DestinationType["ColorTableDestination"] = 19] = "ColorTableDestination";
  DestinationType[DestinationType["PictureDestination"] = 20] = "PictureDestination";
  DestinationType[DestinationType["DefaultDestination"] = 21] = "DefaultDestination";
  DestinationType[DestinationType["FormFieldNameDestination"] = 22] = "FormFieldNameDestination";
  DestinationType[DestinationType["TableContentFieldDestination"] = 23] = "TableContentFieldDestination";
  DestinationType[DestinationType["FormFieldHelpTextDestination"] = 24] = "FormFieldHelpTextDestination";
  DestinationType[DestinationType["FieldStatusBarTextDestination"] = 25] = "FieldStatusBarTextDestination";
  DestinationType[DestinationType["FormFieldEntryMacroDestination"] = 26] = "FormFieldEntryMacroDestination";
  DestinationType[DestinationType["FormFieldExitMacroDestination"] = 27] = "FormFieldExitMacroDestination";
  DestinationType[DestinationType["InfoDestination"] = 28] = "InfoDestination";
  DestinationType[DestinationType["CustomPropertiesDestination"] = 29] = "CustomPropertiesDestination";
  DestinationType[DestinationType["StringPropertyDestination"] = 30] = "StringPropertyDestination";
  DestinationType[DestinationType["MailMergeDestination"] = 31] = "MailMergeDestination";
  DestinationType[DestinationType["StringPropertyBaseDestination"] = 32] = "StringPropertyBaseDestination";
  DestinationType[DestinationType["FieldMapDataDestination"] = 33] = "FieldMapDataDestination";
  DestinationType[DestinationType["BookmarkStartDestination"] = 34] = "BookmarkStartDestination";
  DestinationType[DestinationType["BookmarkEndDestination"] = 35] = "BookmarkEndDestination";
  DestinationType[DestinationType["RangePermissionStartDestination"] = 36] = "RangePermissionStartDestination";
  DestinationType[DestinationType["RangePermissionEndDestination"] = 37] = "RangePermissionEndDestination";
  DestinationType[DestinationType["DocumentVariableDestination"] = 38] = "DocumentVariableDestination";
  DestinationType[DestinationType["TextBeforeDestination"] = 39] = "TextBeforeDestination";
  DestinationType[DestinationType["TextAfterDestination"] = 40] = "TextAfterDestination";
  DestinationType[DestinationType["DestinationOldSectionNumberingLevel"] = 41] = "DestinationOldSectionNumberingLevel";
  DestinationType[DestinationType["DestinationOldParagraphNumbering"] = 42] = "DestinationOldParagraphNumbering";
  DestinationType[DestinationType["SkipNestedTableDestination"] = 43] = "SkipNestedTableDestination";
  DestinationType[DestinationType["CodeFieldDestination"] = 44] = "CodeFieldDestination";
  DestinationType[DestinationType["FieldDestination"] = 45] = "FieldDestination";
  DestinationType[DestinationType["FormFieldDestination"] = 46] = "FormFieldDestination";
  DestinationType[DestinationType["ResultFieldDestination"] = 47] = "ResultFieldDestination";
  DestinationType[DestinationType["DestinationSubDocument"] = 48] = "DestinationSubDocument";
  DestinationType[DestinationType["StyleSheetDestination"] = 49] = "StyleSheetDestination";
  DestinationType[DestinationType["CharacterStyleDestination"] = 50] = "CharacterStyleDestination";
  DestinationType[DestinationType["TableStyleDestination"] = 51] = "TableStyleDestination";
  DestinationType[DestinationType["PageBackgroundDestination"] = 52] = "PageBackgroundDestination";
  DestinationType[DestinationType["ShapeInstanceDestination"] = 53] = "ShapeInstanceDestination";
  DestinationType[DestinationType["ShapeDestination"] = 54] = "ShapeDestination";
  DestinationType[DestinationType["SectionPageFooterDestination"] = 55] = "SectionPageFooterDestination";
  DestinationType[DestinationType["SectionPageHeaderDestination"] = 56] = "SectionPageHeaderDestination";
  DestinationType[DestinationType["ListTableDestination"] = 57] = "ListTableDestination";
  DestinationType[DestinationType["ListNameDestination"] = 58] = "ListNameDestination";
  DestinationType[DestinationType["ListStyleNameDestination"] = 59] = "ListStyleNameDestination";
  DestinationType[DestinationType["ListLevelDestination"] = 60] = "ListLevelDestination";
  DestinationType[DestinationType["ListLevelTextDestination"] = 61] = "ListLevelTextDestination";
  DestinationType[DestinationType["ListLevelNumbersDestination"] = 62] = "ListLevelNumbersDestination";
  DestinationType[DestinationType["ListOverrideTableDestination"] = 63] = "ListOverrideTableDestination";
  DestinationType[DestinationType["ListOverrideLevelDestination"] = 64] = "ListOverrideLevelDestination";
  DestinationType[DestinationType["DefaultCharacterPropertiesDestination"] = 65] = "DefaultCharacterPropertiesDestination";
  DestinationType[DestinationType["DefaultParagraphPropertiesDestination"] = 66] = "DefaultParagraphPropertiesDestination";
  DestinationType[DestinationType["ShapePropertyNameDestination"] = 67] = "ShapePropertyNameDestination";
  DestinationType[DestinationType["ShapePropertyDestination"] = 68] = "ShapePropertyDestination";
  DestinationType[DestinationType["ShapePropertyValueDestination"] = 69] = "ShapePropertyValueDestination";
  DestinationType[DestinationType["ShapeTextDestination"] = 70] = "ShapeTextDestination";
  DestinationType[DestinationType["ShapePropertyHyperlinkDestination"] = 71] = "ShapePropertyHyperlinkDestination";
  DestinationType[DestinationType["ShapePropertyHsvValueDestination"] = 72] = "ShapePropertyHsvValueDestination";
  DestinationType[DestinationType["HyperlinkSourceValueDestination"] = 73] = "HyperlinkSourceValueDestination";
  DestinationType[DestinationType["HyperlinkLocationValueDestination"] = 74] = "HyperlinkLocationValueDestination";
})(DestinationType || (DestinationType = {}));