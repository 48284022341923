export class TableBordersBaseApi {
  constructor(top, right, bottom, left) {
    Object.assign(this, {
      top,
      right,
      bottom,
      left
    });
  }
}
export class TableBordersApi extends TableBordersBaseApi {
  constructor(top, right, bottom, left, insideHorizontal, insideVertical) {
    super(top, right, bottom, left);
    Object.assign(this, {
      insideHorizontal,
      insideVertical
    });
  }
}
export class TableCellBordersApi extends TableBordersBaseApi {
  constructor(top, right, bottom, left) {
    super(top, right, bottom, left);
  }
}
export class TableBorderApi {
  constructor(style, color, width) {
    Object.assign(this, {
      style,
      color,
      width
    });
  }
}