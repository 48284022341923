import { __awaiter } from "tslib";
import { NoteType } from "../../../../../../common/model/footnotes/footnote";
import { SubDocument } from "../../../../../../common/model/sub-document";
import { MapCreator } from "../../../../../../common/utils/map-creator";
import { TranslationTables } from "../../../translation-table/translation-tables";
import { BodyDestinationBase } from "../document/body-destination-base";
import { ParagraphDestination } from "../paragraph/paragraph-destination";
import { HeaderFooterDestination } from "../section/header-footer/header-footer-destination";
import { TableDestination } from "../table/table/table-destination";
export class NoteDestination extends BodyDestinationBase {
  get elementHandlerTable() {
    return HeaderFooterDestination.handlerTable;
  }
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      this.type = this.data.readerHelper.getWpEnumValue(reader, "type", TranslationTables.noteTypeTable.importMap, NoteType.Normal);
      const note = this.createSubDocument(this.SubDocumentType);
      note.info.noteType = this.type;
      this.data.pushCurrentSubDocument(note);
      this.id = reader.getAttributeNS("id", this.data.constants.wordProcessingNamespaceConst);
    });
  }
  processElementClose(_reader) {
    return __awaiter(this, void 0, void 0, function* () {
      this.data.fixLastParagraph();
      const subDoc = this.data.popCurrentSubDocument();
      this.subDocId = subDoc.id;
      if (!this.addNote(this.id, this.subDocId, this.type)) this.data.documentModel.subDocumentsCollection.delete(this.subDocId);
    });
  }
  createSubDocument(type) {
    return this.documentModel.createSubDocument(type, SubDocument.MAIN_SUBDOCUMENT_ID, true);
  }
}
NoteDestination.handlerTable = new MapCreator().add('p', data => new ParagraphDestination(data)).add('tbl', data => new TableDestination(data)).add('bookmarkStart', BodyDestinationBase.onBookmarkStart).add('bookmarkEnd', BodyDestinationBase.onBookmarkEnd).add('permStart', BodyDestinationBase.onRangePermissionStart).add('permEnd', BodyDestinationBase.onRangePermissionEnd).add('sdt', BodyDestinationBase.onStructuredDocument).add('customXml', BodyDestinationBase.onCustomXml).get();