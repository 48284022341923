import { formatMessage } from 'devextreme/localization';
import { ParagraphStyle } from '../../../common/model/paragraph/paragraph-style';
import { CharacterStyle } from '../../../common/model/character/character-style';
export class KnownStyleNames {
  static createPresetStylesLocalizedNames() {
    const result = {};
    KnownStyleNames.add(result, ParagraphStyle.normalStyleName, 'XtraRichEditStringId.LocalizedStyleName_Normal');
    KnownStyleNames.add(result, CharacterStyle.hyperlinkStyleName, 'XtraRichEditStringId.LocalizedStyleName_Hyperlink');
    KnownStyleNames.add(result, CharacterStyle.defaultParagraphCharacterStyleName, 'XtraRichEditStringId.DefaultStyleName_DefaultParagraphFont');
    KnownStyleNames.add(result, CharacterStyle.lineNumberingStyleName, 'XtraRichEditStringId.DefaultStyleName_linenumber');
    KnownStyleNames.add(result, KnownStyleNames.ArticleSection, 'XtraRichEditStringId.DefaultStyleName_ArticleSection');
    KnownStyleNames.add(result, KnownStyleNames.AnnotationText, 'XtraRichEditStringId.DefaultStyleName_annotationtext');
    KnownStyleNames.add(result, KnownStyleNames.AnnotationReference, 'XtraRichEditStringId.DefaultStyleName_annotationreference');
    KnownStyleNames.add(result, KnownStyleNames.AnnotationSubject, 'XtraRichEditStringId.DefaultStyleName_annotationsubject');
    KnownStyleNames.add(result, KnownStyleNames.BalloonText, 'XtraRichEditStringId.DefaultStyleName_BalloonText');
    KnownStyleNames.add(result, KnownStyleNames.BlockText, 'XtraRichEditStringId.DefaultStyleName_BlockText');
    KnownStyleNames.add(result, KnownStyleNames.BodyText, 'XtraRichEditStringId.DefaultStyleName_BodyText');
    KnownStyleNames.add(result, KnownStyleNames.BodyText2, 'XtraRichEditStringId.DefaultStyleName_BodyText2');
    KnownStyleNames.add(result, KnownStyleNames.BodyText3, 'XtraRichEditStringId.DefaultStyleName_BodyText3');
    KnownStyleNames.add(result, KnownStyleNames.BodyTextFirstIndent, 'XtraRichEditStringId.DefaultStyleName_BodyTextFirstIndent');
    KnownStyleNames.add(result, KnownStyleNames.BodyTextFirstIndent2, 'XtraRichEditStringId.DefaultStyleName_BodyTextFirstIndent2');
    KnownStyleNames.add(result, KnownStyleNames.BodyTextIndent, 'XtraRichEditStringId.DefaultStyleName_BodyTextIndent');
    KnownStyleNames.add(result, KnownStyleNames.BodyTextIndent2, 'XtraRichEditStringId.DefaultStyleName_BodyTextIndent2');
    KnownStyleNames.add(result, KnownStyleNames.BodyTextIndent3, 'XtraRichEditStringId.DefaultStyleName_BodyTextIndent3');
    KnownStyleNames.add(result, KnownStyleNames.Caption, 'XtraRichEditStringId.DefaultStyleName_caption');
    KnownStyleNames.add(result, KnownStyleNames.Closing, 'XtraRichEditStringId.DefaultStyleName_Closing');
    KnownStyleNames.add(result, KnownStyleNames.CommentReference, 'XtraRichEditStringId.DefaultStyleName_CommentReference');
    KnownStyleNames.add(result, KnownStyleNames.CommentSubject, 'XtraRichEditStringId.DefaultStyleName_CommentSubject');
    KnownStyleNames.add(result, KnownStyleNames.CommentText, 'XtraRichEditStringId.DefaultStyleName_CommentText');
    KnownStyleNames.add(result, KnownStyleNames.Date, 'XtraRichEditStringId.DefaultStyleName_Date');
    KnownStyleNames.add(result, KnownStyleNames.DocumentMap, 'XtraRichEditStringId.DefaultStyleName_DocumentMap');
    KnownStyleNames.add(result, KnownStyleNames.EmailSignature, 'XtraRichEditStringId.DefaultStyleName_EmailSignature');
    KnownStyleNames.add(result, KnownStyleNames.EndnoteReference, 'XtraRichEditStringId.DefaultStyleName_endnotereference');
    KnownStyleNames.add(result, KnownStyleNames.EndnoteText, 'XtraRichEditStringId.DefaultStyleName_endnotetext');
    KnownStyleNames.add(result, KnownStyleNames.EnvelopeAddress, 'XtraRichEditStringId.DefaultStyleName_envelopeaddress');
    KnownStyleNames.add(result, KnownStyleNames.EnvelopeReturn, 'XtraRichEditStringId.DefaultStyleName_envelopereturn');
    KnownStyleNames.add(result, KnownStyleNames.Footer, 'XtraRichEditStringId.DefaultStyleName_footer');
    KnownStyleNames.add(result, KnownStyleNames.FootnoteText, 'XtraRichEditStringId.DefaultStyleName_footnotetext');
    KnownStyleNames.add(result, KnownStyleNames.FootnoteReference, 'XtraRichEditStringId.DefaultStyleName_footnotereference');
    KnownStyleNames.add(result, KnownStyleNames.Header, 'XtraRichEditStringId.DefaultStyleName_header');
    KnownStyleNames.add(result, KnownStyleNames.HTMLAcronym, 'XtraRichEditStringId.DefaultStyleName_HTMLAcronym');
    KnownStyleNames.add(result, KnownStyleNames.HTMLAddress, 'XtraRichEditStringId.DefaultStyleName_HTMLAddress');
    KnownStyleNames.add(result, KnownStyleNames.HTMLBottomOfForm, 'XtraRichEditStringId.DefaultStyleName_HTMLBottomofForm');
    KnownStyleNames.add(result, KnownStyleNames.HTMLCite, 'XtraRichEditStringId.DefaultStyleName_HTMLCite');
    KnownStyleNames.add(result, KnownStyleNames.HTMLCode, 'XtraRichEditStringId.DefaultStyleName_HTMLCode');
    KnownStyleNames.add(result, KnownStyleNames.HTMLDefinition, 'XtraRichEditStringId.DefaultStyleName_HTMLDefinition');
    KnownStyleNames.add(result, KnownStyleNames.HTMLKeyboard, 'XtraRichEditStringId.DefaultStyleName_HTMLKeyboard');
    KnownStyleNames.add(result, KnownStyleNames.HTMLPreformatted, 'XtraRichEditStringId.DefaultStyleName_HTMLPreformatted');
    KnownStyleNames.add(result, KnownStyleNames.HTMLSample, 'XtraRichEditStringId.DefaultStyleName_HTMLSample');
    KnownStyleNames.add(result, KnownStyleNames.HTMLTopOfForm, 'XtraRichEditStringId.DefaultStyleName_HTMLTopofForm');
    KnownStyleNames.add(result, KnownStyleNames.HTMLTypewriter, 'XtraRichEditStringId.DefaultStyleName_HTMLTypewriter');
    KnownStyleNames.add(result, KnownStyleNames.HTMLVariable, 'XtraRichEditStringId.DefaultStyleName_HTMLVariable');
    KnownStyleNames.add(result, KnownStyleNames.HyperlinkFollowed, 'XtraRichEditStringId.DefaultStyleName_HyperlinkFollowed');
    KnownStyleNames.add(result, KnownStyleNames.HyperlinkStrongEmphasis, 'XtraRichEditStringId.DefaultStyleName_HyperlinkStrongEmphasis');
    KnownStyleNames.add(result, KnownStyleNames.Emphasis, 'XtraRichEditStringId.DefaultStyleName_Emphasis');
    KnownStyleNames.add(result, KnownStyleNames.FollowedHyperlink, 'XtraRichEditStringId.DefaultStyleName_FollowedHyperlink');
    KnownStyleNames.add(result, KnownStyleNames.Index1, 'XtraRichEditStringId.DefaultStyleName_index1');
    KnownStyleNames.add(result, KnownStyleNames.Index2, 'XtraRichEditStringId.DefaultStyleName_index2');
    KnownStyleNames.add(result, KnownStyleNames.Index3, 'XtraRichEditStringId.DefaultStyleName_index3');
    KnownStyleNames.add(result, KnownStyleNames.Index4, 'XtraRichEditStringId.DefaultStyleName_index4');
    KnownStyleNames.add(result, KnownStyleNames.Index5, 'XtraRichEditStringId.DefaultStyleName_index5');
    KnownStyleNames.add(result, KnownStyleNames.Index6, 'XtraRichEditStringId.DefaultStyleName_index6');
    KnownStyleNames.add(result, KnownStyleNames.Index7, 'XtraRichEditStringId.DefaultStyleName_index7');
    KnownStyleNames.add(result, KnownStyleNames.Index8, 'XtraRichEditStringId.DefaultStyleName_index8');
    KnownStyleNames.add(result, KnownStyleNames.Index9, 'XtraRichEditStringId.DefaultStyleName_index9');
    KnownStyleNames.add(result, KnownStyleNames.IndexHeading, 'XtraRichEditStringId.DefaultStyleName_indexheading');
    KnownStyleNames.add(result, KnownStyleNames.List, 'XtraRichEditStringId.DefaultStyleName_List');
    KnownStyleNames.add(result, KnownStyleNames.List2, 'XtraRichEditStringId.DefaultStyleName_List2');
    KnownStyleNames.add(result, KnownStyleNames.List3, 'XtraRichEditStringId.DefaultStyleName_List3');
    KnownStyleNames.add(result, KnownStyleNames.List4, 'XtraRichEditStringId.DefaultStyleName_List4');
    KnownStyleNames.add(result, KnownStyleNames.List5, 'XtraRichEditStringId.DefaultStyleName_List5');
    KnownStyleNames.add(result, KnownStyleNames.ListBullet, 'XtraRichEditStringId.DefaultStyleName_ListBullet');
    KnownStyleNames.add(result, KnownStyleNames.ListBullet2, 'XtraRichEditStringId.DefaultStyleName_ListBullet2');
    KnownStyleNames.add(result, KnownStyleNames.ListBullet3, 'XtraRichEditStringId.DefaultStyleName_ListBullet3');
    KnownStyleNames.add(result, KnownStyleNames.ListBullet4, 'XtraRichEditStringId.DefaultStyleName_ListBullet4');
    KnownStyleNames.add(result, KnownStyleNames.ListBullet5, 'XtraRichEditStringId.DefaultStyleName_ListBullet5');
    KnownStyleNames.add(result, KnownStyleNames.ListContinue, 'XtraRichEditStringId.DefaultStyleName_ListContinue');
    KnownStyleNames.add(result, KnownStyleNames.ListContinue2, 'XtraRichEditStringId.DefaultStyleName_ListContinue2');
    KnownStyleNames.add(result, KnownStyleNames.ListContinue3, 'XtraRichEditStringId.DefaultStyleName_ListContinue3');
    KnownStyleNames.add(result, KnownStyleNames.ListContinue4, 'XtraRichEditStringId.DefaultStyleName_ListContinue4');
    KnownStyleNames.add(result, KnownStyleNames.ListContinue5, 'XtraRichEditStringId.DefaultStyleName_ListContinue5');
    KnownStyleNames.add(result, KnownStyleNames.ListNumber, 'XtraRichEditStringId.DefaultStyleName_ListNumber');
    KnownStyleNames.add(result, KnownStyleNames.ListNumber2, 'XtraRichEditStringId.DefaultStyleName_ListNumber2');
    KnownStyleNames.add(result, KnownStyleNames.ListNumber3, 'XtraRichEditStringId.DefaultStyleName_ListNumber3');
    KnownStyleNames.add(result, KnownStyleNames.ListNumber4, 'XtraRichEditStringId.DefaultStyleName_ListNumber4');
    KnownStyleNames.add(result, KnownStyleNames.ListNumber5, 'XtraRichEditStringId.DefaultStyleName_ListNumber5');
    KnownStyleNames.add(result, KnownStyleNames.MacroText, 'XtraRichEditStringId.DefaultStyleName_MacroText');
    KnownStyleNames.add(result, KnownStyleNames.MacroToAHeading, 'XtraRichEditStringId.DefaultStyleName_macrotoaheading');
    KnownStyleNames.add(result, KnownStyleNames.MessageHeader, 'XtraRichEditStringId.DefaultStyleName_MessageHeader');
    KnownStyleNames.add(result, KnownStyleNames.NoList, 'XtraRichEditStringId.DefaultStyleName_NoList');
    KnownStyleNames.add(result, KnownStyleNames.NormalIndent, 'XtraRichEditStringId.DefaultStyleName_NormalIndent');
    KnownStyleNames.add(result, KnownStyleNames.NormalTable, 'XtraRichEditStringId.DefaultStyleName_NormalTable');
    KnownStyleNames.add(result, KnownStyleNames.NormalWeb, 'XtraRichEditStringId.DefaultStyleName_NormalWeb');
    KnownStyleNames.add(result, KnownStyleNames.NoteHeading, 'XtraRichEditStringId.DefaultStyleName_NoteHeading');
    KnownStyleNames.add(result, KnownStyleNames.OutlineList1, 'XtraRichEditStringId.DefaultStyleName_OutlineList1');
    KnownStyleNames.add(result, KnownStyleNames.OutlineList2, 'XtraRichEditStringId.DefaultStyleName_OutlineList2');
    KnownStyleNames.add(result, KnownStyleNames.OutlineList3, 'XtraRichEditStringId.DefaultStyleName_OutlineList3');
    KnownStyleNames.add(result, KnownStyleNames.PageNumber, 'XtraRichEditStringId.DefaultStyleName_pagenumber');
    KnownStyleNames.add(result, KnownStyleNames.PlainText, 'XtraRichEditStringId.DefaultStyleName_PlainText');
    KnownStyleNames.add(result, KnownStyleNames.Salutation, 'XtraRichEditStringId.DefaultStyleName_Salutation');
    KnownStyleNames.add(result, KnownStyleNames.Signature, 'XtraRichEditStringId.DefaultStyleName_Signature');
    KnownStyleNames.add(result, KnownStyleNames.Strong, 'XtraRichEditStringId.DefaultStyleName_Strong');
    KnownStyleNames.add(result, KnownStyleNames.TableOfAuthorities, 'XtraRichEditStringId.DefaultStyleName_tableofauthorities');
    KnownStyleNames.add(result, KnownStyleNames.TableOfFigures, 'XtraRichEditStringId.DefaultStyleName_tableoffigures');
    KnownStyleNames.add(result, KnownStyleNames.Table3DEffects1, 'XtraRichEditStringId.DefaultStyleName_Table3Deffects1');
    KnownStyleNames.add(result, KnownStyleNames.Table3DEffects2, 'XtraRichEditStringId.DefaultStyleName_Table3Deffects2');
    KnownStyleNames.add(result, KnownStyleNames.Table3DEffects3, 'XtraRichEditStringId.DefaultStyleName_Table3Deffects3');
    KnownStyleNames.add(result, KnownStyleNames.TableClassic1, 'XtraRichEditStringId.DefaultStyleName_TableClassic1');
    KnownStyleNames.add(result, KnownStyleNames.TableClassic2, 'XtraRichEditStringId.DefaultStyleName_TableClassic2');
    KnownStyleNames.add(result, KnownStyleNames.TableClassic3, 'XtraRichEditStringId.DefaultStyleName_TableClassic3');
    KnownStyleNames.add(result, KnownStyleNames.TableClassic4, 'XtraRichEditStringId.DefaultStyleName_TableClassic4');
    KnownStyleNames.add(result, KnownStyleNames.TableColorful1, 'XtraRichEditStringId.DefaultStyleName_TableColorful1');
    KnownStyleNames.add(result, KnownStyleNames.TableColorful2, 'XtraRichEditStringId.DefaultStyleName_TableColorful2');
    KnownStyleNames.add(result, KnownStyleNames.TableColorful3, 'XtraRichEditStringId.DefaultStyleName_TableColorful3');
    KnownStyleNames.add(result, KnownStyleNames.TableColumns1, 'XtraRichEditStringId.DefaultStyleName_TableColumns1');
    KnownStyleNames.add(result, KnownStyleNames.TableColumns2, 'XtraRichEditStringId.DefaultStyleName_TableColumns2');
    KnownStyleNames.add(result, KnownStyleNames.TableColumns3, 'XtraRichEditStringId.DefaultStyleName_TableColumns3');
    KnownStyleNames.add(result, KnownStyleNames.TableColumns4, 'XtraRichEditStringId.DefaultStyleName_TableColumns4');
    KnownStyleNames.add(result, KnownStyleNames.TableColumns5, 'XtraRichEditStringId.DefaultStyleName_TableColumns5');
    KnownStyleNames.add(result, KnownStyleNames.TableContemporary, 'XtraRichEditStringId.DefaultStyleName_TableContemporary');
    KnownStyleNames.add(result, KnownStyleNames.TableElegant, 'XtraRichEditStringId.DefaultStyleName_TableElegant');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid, 'XtraRichEditStringId.DefaultStyleName_TableGrid');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid1, 'XtraRichEditStringId.DefaultStyleName_TableGrid1');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid2, 'XtraRichEditStringId.DefaultStyleName_TableGrid2');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid3, 'XtraRichEditStringId.DefaultStyleName_TableGrid3');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid4, 'XtraRichEditStringId.DefaultStyleName_TableGrid4');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid5, 'XtraRichEditStringId.DefaultStyleName_TableGrid5');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid6, 'XtraRichEditStringId.DefaultStyleName_TableGrid6');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid7, 'XtraRichEditStringId.DefaultStyleName_TableGrid7');
    KnownStyleNames.add(result, KnownStyleNames.TableGrid8, 'XtraRichEditStringId.DefaultStyleName_TableGrid8');
    KnownStyleNames.add(result, KnownStyleNames.TableList1, 'XtraRichEditStringId.DefaultStyleName_TableList1');
    KnownStyleNames.add(result, KnownStyleNames.TableList2, 'XtraRichEditStringId.DefaultStyleName_TableList2');
    KnownStyleNames.add(result, KnownStyleNames.TableList3, 'XtraRichEditStringId.DefaultStyleName_TableList3');
    KnownStyleNames.add(result, KnownStyleNames.TableList4, 'XtraRichEditStringId.DefaultStyleName_TableList4');
    KnownStyleNames.add(result, KnownStyleNames.TableList5, 'XtraRichEditStringId.DefaultStyleName_TableList5');
    KnownStyleNames.add(result, KnownStyleNames.TableList6, 'XtraRichEditStringId.DefaultStyleName_TableList6');
    KnownStyleNames.add(result, KnownStyleNames.TableList7, 'XtraRichEditStringId.DefaultStyleName_TableList7');
    KnownStyleNames.add(result, KnownStyleNames.TableList8, 'XtraRichEditStringId.DefaultStyleName_TableList8');
    KnownStyleNames.add(result, KnownStyleNames.TableWeb1, 'XtraRichEditStringId.DefaultStyleName_TableWeb1');
    KnownStyleNames.add(result, KnownStyleNames.TableWeb2, 'XtraRichEditStringId.DefaultStyleName_TableWeb2');
    KnownStyleNames.add(result, KnownStyleNames.TableWeb3, 'XtraRichEditStringId.DefaultStyleName_TableWeb3');
    KnownStyleNames.add(result, KnownStyleNames.TableNormal, 'XtraRichEditStringId.DefaultStyleName_TableNormal');
    KnownStyleNames.add(result, KnownStyleNames.TableProfessional, 'XtraRichEditStringId.DefaultStyleName_TableProfessional');
    KnownStyleNames.add(result, KnownStyleNames.TableSimple1, 'XtraRichEditStringId.DefaultStyleName_TableSimple1');
    KnownStyleNames.add(result, KnownStyleNames.TableSimple2, 'XtraRichEditStringId.DefaultStyleName_TableSimple2');
    KnownStyleNames.add(result, KnownStyleNames.TableSimple3, 'XtraRichEditStringId.DefaultStyleName_TableSimple3');
    KnownStyleNames.add(result, KnownStyleNames.TableSubtle1, 'XtraRichEditStringId.DefaultStyleName_TableSubtle1');
    KnownStyleNames.add(result, KnownStyleNames.TableSubtle2, 'XtraRichEditStringId.DefaultStyleName_TableSubtle2');
    KnownStyleNames.add(result, KnownStyleNames.TableTheme, 'XtraRichEditStringId.DefaultStyleName_TableTheme');
    KnownStyleNames.add(result, KnownStyleNames.TOAHeading, 'XtraRichEditStringId.DefaultStyleName_toaheading');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Title, 'XtraRichEditStringId.DefaultStyleName_Title');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Subtitle, 'XtraRichEditStringId.DefaultStyleName_Subtitle');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC1, 'XtraRichEditStringId.DefaultStyleName_toc1');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC2, 'XtraRichEditStringId.DefaultStyleName_toc2');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC3, 'XtraRichEditStringId.DefaultStyleName_toc3');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC4, 'XtraRichEditStringId.DefaultStyleName_toc4');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC5, 'XtraRichEditStringId.DefaultStyleName_toc5');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC6, 'XtraRichEditStringId.DefaultStyleName_toc6');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC7, 'XtraRichEditStringId.DefaultStyleName_toc7');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC8, 'XtraRichEditStringId.DefaultStyleName_toc8');
    KnownStyleNames.addAndLink(result, KnownStyleNames.TOC9, 'XtraRichEditStringId.DefaultStyleName_toc9');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading1, 'XtraRichEditStringId.DefaultStyleName_heading1');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading2, 'XtraRichEditStringId.DefaultStyleName_heading2');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading3, 'XtraRichEditStringId.DefaultStyleName_heading3');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading4, 'XtraRichEditStringId.DefaultStyleName_heading4');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading5, 'XtraRichEditStringId.DefaultStyleName_heading5');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading6, 'XtraRichEditStringId.DefaultStyleName_heading6');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading7, 'XtraRichEditStringId.DefaultStyleName_heading7');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading8, 'XtraRichEditStringId.DefaultStyleName_heading8');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Heading9, 'XtraRichEditStringId.DefaultStyleName_heading9');
    KnownStyleNames.addAndLink(result, KnownStyleNames.Quote, 'XtraRichEditStringId.DefaultStyleName_Quote');
    KnownStyleNames.addAndLink(result, KnownStyleNames.IntenseQuote, 'XtraRichEditStringId.DefaultStyleName_IntenseQuote');
    KnownStyleNames.add(result, KnownStyleNames.NoSpacing, 'XtraRichEditStringId.DefaultStyleName_NoSpacing');
    KnownStyleNames.add(result, KnownStyleNames.ListParagraph, 'XtraRichEditStringId.DefaultStyleName_ListParagraph');
    KnownStyleNames.add(result, KnownStyleNames.IntenseEmphasis, 'XtraRichEditStringId.DefaultStyleName_IntenseEmphasis');
    KnownStyleNames.add(result, KnownStyleNames.SubtleEmphasis, 'XtraRichEditStringId.DefaultStyleName_SubtleEmphasis');
    KnownStyleNames.add(result, KnownStyleNames.BookTitle, 'XtraRichEditStringId.DefaultStyleName_BookTitle');
    return result;
  }
  static add(result, name, localizationId) {
    result[name.toLocaleLowerCase()] = formatMessage(localizationId);
  }
  static addAndLink(result, name, localizationId) {
    KnownStyleNames.add(result, name, localizationId);
    result[name.toLocaleLowerCase() + ' char'] = formatMessage('XtraRichEditStringId.LinkedCharacterStyleFormatString', formatMessage(localizationId));
  }
}
KnownStyleNames.AnnotationText = "annotation text";
KnownStyleNames.AnnotationReference = "annotation reference";
KnownStyleNames.AnnotationSubject = "annotation subject";
KnownStyleNames.ArticleSection = "Article / Section";
KnownStyleNames.BalloonText = "Balloon Text";
KnownStyleNames.BlockText = "Block Text";
KnownStyleNames.BodyText = "Body Text";
KnownStyleNames.BodyText2 = "Body Text 2";
KnownStyleNames.BodyText3 = "Body Text 3";
KnownStyleNames.BodyTextFirstIndent = "Body Text First Indent";
KnownStyleNames.BodyTextFirstIndent2 = "Body Text First Indent 2";
KnownStyleNames.BodyTextIndent = "Body Text Indent";
KnownStyleNames.BodyTextIndent2 = "Body Text Indent 2";
KnownStyleNames.BodyTextIndent3 = "Body Text Indent 3";
KnownStyleNames.Caption = "Caption";
KnownStyleNames.Closing = "Closing";
KnownStyleNames.CommentReference = "Comment Reference";
KnownStyleNames.CommentSubject = "Comment Subject";
KnownStyleNames.CommentText = "Comment Text";
KnownStyleNames.Date = "Date";
KnownStyleNames.DocumentMap = "Document Map";
KnownStyleNames.EmailSignature = "E-mail Signature";
KnownStyleNames.EndnoteReference = "Endnote Reference";
KnownStyleNames.EndnoteText = "Endnote Text";
KnownStyleNames.EnvelopeAddress = "Envelope Address";
KnownStyleNames.EnvelopeReturn = "Envelope Return";
KnownStyleNames.Footer = "Footer";
KnownStyleNames.FootnoteText = "Footnote Text";
KnownStyleNames.FootnoteReference = "Footnote Reference";
KnownStyleNames.Header = "Header";
KnownStyleNames.Heading1 = "Heading 1";
KnownStyleNames.Heading2 = "Heading 2";
KnownStyleNames.Heading3 = "Heading 3";
KnownStyleNames.Heading4 = "Heading 4";
KnownStyleNames.Heading5 = "Heading 5";
KnownStyleNames.Heading6 = "Heading 6";
KnownStyleNames.Heading7 = "Heading 7";
KnownStyleNames.Heading8 = "Heading 8";
KnownStyleNames.Heading9 = "Heading 9";
KnownStyleNames.HTMLAcronym = "HTML Acronym";
KnownStyleNames.HTMLAddress = "HTML Address";
KnownStyleNames.HTMLBottomOfForm = "HTML Bottom of Form";
KnownStyleNames.HTMLCite = "HTML Cite";
KnownStyleNames.HTMLCode = "HTML Code";
KnownStyleNames.HTMLDefinition = "HTML Definition";
KnownStyleNames.HTMLKeyboard = "HTML Keyboard";
KnownStyleNames.HTMLPreformatted = "HTML Preformatted";
KnownStyleNames.HTMLSample = "HTML Sample";
KnownStyleNames.HTMLTopOfForm = "HTML Top of Form";
KnownStyleNames.HTMLTypewriter = "HTML Typewriter";
KnownStyleNames.HTMLVariable = "HTML Variable";
KnownStyleNames.HyperlinkFollowed = "HyperlinkFollowed";
KnownStyleNames.HyperlinkStrongEmphasis = "HyperlinkStrongEmphasis";
KnownStyleNames.Emphasis = "Emphasis";
KnownStyleNames.FollowedHyperlink = "FollowedHyperlink";
KnownStyleNames.Index1 = "Index 1";
KnownStyleNames.Index2 = "Index 2";
KnownStyleNames.Index3 = "Index 3";
KnownStyleNames.Index4 = "Index 4";
KnownStyleNames.Index5 = "Index 5";
KnownStyleNames.Index6 = "Index 6";
KnownStyleNames.Index7 = "Index 7";
KnownStyleNames.Index8 = "Index 8";
KnownStyleNames.Index9 = "Index 9";
KnownStyleNames.IndexHeading = "Index Heading";
KnownStyleNames.List = "List";
KnownStyleNames.List2 = "List 2";
KnownStyleNames.List3 = "List 3";
KnownStyleNames.List4 = "List 4";
KnownStyleNames.List5 = "List 5";
KnownStyleNames.ListBullet = "List Bullet";
KnownStyleNames.ListBullet2 = "List Bullet 2";
KnownStyleNames.ListBullet3 = "List Bullet 3";
KnownStyleNames.ListBullet4 = "List Bullet 4";
KnownStyleNames.ListBullet5 = "List Bullet 5";
KnownStyleNames.ListContinue = "List Continue";
KnownStyleNames.ListContinue2 = "List Continue 2";
KnownStyleNames.ListContinue3 = "List Continue 3";
KnownStyleNames.ListContinue4 = "List Continue 4";
KnownStyleNames.ListContinue5 = "List Continue 5";
KnownStyleNames.ListNumber = "List Number";
KnownStyleNames.ListNumber2 = "List Number 2";
KnownStyleNames.ListNumber3 = "List Number 3";
KnownStyleNames.ListNumber4 = "List Number 4";
KnownStyleNames.ListNumber5 = "List Number 5";
KnownStyleNames.MacroText = "Macro Text";
KnownStyleNames.MacroToAHeading = "macro to a heading";
KnownStyleNames.MessageHeader = "Message Header";
KnownStyleNames.NoList = "No List";
KnownStyleNames.NormalIndent = "Normal Indent";
KnownStyleNames.NormalWeb = "Normal (Web)";
KnownStyleNames.NoteHeading = "Note Heading";
KnownStyleNames.NormalTable = "Normal Table";
KnownStyleNames.OutlineList1 = "Outline List 1";
KnownStyleNames.OutlineList2 = "Outline List 2";
KnownStyleNames.OutlineList3 = "Outline List 3";
KnownStyleNames.PageNumber = "Page Number";
KnownStyleNames.PlainText = "Plain Text";
KnownStyleNames.Salutation = "Salutation";
KnownStyleNames.Signature = "Signature";
KnownStyleNames.Strong = "Strong";
KnownStyleNames.Subtitle = "Subtitle";
KnownStyleNames.TableOfAuthorities = "Table of Authorities";
KnownStyleNames.TableOfFigures = "Table of Figures";
KnownStyleNames.Table3DEffects1 = "Table 3D effects 1";
KnownStyleNames.Table3DEffects2 = "Table 3D effects 2";
KnownStyleNames.Table3DEffects3 = "Table 3D effects 3";
KnownStyleNames.TableClassic1 = "Table Classic 1";
KnownStyleNames.TableClassic2 = "Table Classic 2";
KnownStyleNames.TableClassic3 = "Table Classic 3";
KnownStyleNames.TableClassic4 = "Table Classic 4";
KnownStyleNames.TableColorful1 = "Table Colorful 1";
KnownStyleNames.TableColorful2 = "Table Colorful 2";
KnownStyleNames.TableColorful3 = "Table Colorful 3";
KnownStyleNames.TableColumns1 = "Table Columns 1";
KnownStyleNames.TableColumns2 = "Table Columns 2";
KnownStyleNames.TableColumns3 = "Table Columns 3";
KnownStyleNames.TableColumns4 = "Table Columns 4";
KnownStyleNames.TableColumns5 = "Table Columns 5";
KnownStyleNames.TableContemporary = "Table Contemporary";
KnownStyleNames.TableElegant = "Table Elegant";
KnownStyleNames.TableGrid = "Table Grid";
KnownStyleNames.TableGrid1 = "Table Grid 1";
KnownStyleNames.TableGrid2 = "Table Grid 2";
KnownStyleNames.TableGrid3 = "Table Grid 3";
KnownStyleNames.TableGrid4 = "Table Grid 4";
KnownStyleNames.TableGrid5 = "Table Grid 5";
KnownStyleNames.TableGrid6 = "Table Grid 6";
KnownStyleNames.TableGrid7 = "Table Grid 7";
KnownStyleNames.TableGrid8 = "Table Grid 8";
KnownStyleNames.TableList1 = "Table List 1";
KnownStyleNames.TableList2 = "Table List 2";
KnownStyleNames.TableList3 = "Table List 3";
KnownStyleNames.TableList4 = "Table List 4";
KnownStyleNames.TableList5 = "Table List 5";
KnownStyleNames.TableList6 = "Table List 6";
KnownStyleNames.TableList7 = "Table List 7";
KnownStyleNames.TableList8 = "Table List 8";
KnownStyleNames.TableNormal = "Table Normal";
KnownStyleNames.TableProfessional = "Table Professional";
KnownStyleNames.TableSimple1 = "Table Simple 1";
KnownStyleNames.TableSimple2 = "Table Simple 2";
KnownStyleNames.TableSimple3 = "Table Simple 3";
KnownStyleNames.TableSubtle1 = "Table Subtle 1";
KnownStyleNames.TableSubtle2 = "Table Subtle 2";
KnownStyleNames.TableTheme = "Table Theme";
KnownStyleNames.TableWeb1 = "Table Web 1";
KnownStyleNames.TableWeb2 = "Table Web 2";
KnownStyleNames.TableWeb3 = "Table Web 3";
KnownStyleNames.Title = "Title";
KnownStyleNames.TOAHeading = "TOA Heading";
KnownStyleNames.TOC1 = "TOC 1";
KnownStyleNames.TOC2 = "TOC 2";
KnownStyleNames.TOC3 = "TOC 3";
KnownStyleNames.TOC4 = "TOC 4";
KnownStyleNames.TOC5 = "TOC 5";
KnownStyleNames.TOC6 = "TOC 6";
KnownStyleNames.TOC7 = "TOC 7";
KnownStyleNames.TOC8 = "TOC 8";
KnownStyleNames.TOC9 = "TOC 9";
KnownStyleNames.Quote = "Quote";
KnownStyleNames.IntenseQuote = "Intense Quote";
KnownStyleNames.NoSpacing = "No Spacing";
KnownStyleNames.ListParagraph = "List Paragraph";
KnownStyleNames.IntenseEmphasis = "Intense Emphasis";
KnownStyleNames.SubtleEmphasis = "Subtle Emphasis";
KnownStyleNames.BookTitle = "Book Title";