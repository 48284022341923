import { MathUtils } from '@devexpress/utils/lib/utils/math';
export class TableBordersBase {
  getHashCode() {
    return MathUtils.somePrimes[10] * this.top.getHashCode() ^ MathUtils.somePrimes[11] * this.right.getHashCode() ^ MathUtils.somePrimes[12] * this.bottom.getHashCode() ^ MathUtils.somePrimes[13] * this.left.getHashCode();
  }
  equals(obj) {
    if (!obj) return false;
    return this.bottom.equals(obj.bottom) && this.left.equals(obj.left) && this.right.equals(obj.right) && this.top.equals(obj.top);
  }
  copyFrom(obj) {
    this.bottom = obj.bottom.clone();
    this.left = obj.left.clone();
    this.right = obj.right.clone();
    this.top = obj.top.clone();
  }
}