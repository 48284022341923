{
    "OfficeStringId.Caption_EditHyperlinkForm": "Hyperlink bearbeiten",
    "OfficeStringId.Caption_EditHyperlinkFormDescription": " ",
    "OfficeStringId.Caption_InsertHyperlinkForm": "Hyperlink einfügen",
    "OfficeStringId.Caption_InsertHyperlinkFormDescription": " ",
    "OfficeStringId.Caption_UnitCentimeters": "Zentimeter",
    "OfficeStringId.Caption_UnitInches": "Zoll",
    "OfficeStringId.Caption_UnitMillimeters": "Millimeter",
    "OfficeStringId.Caption_UnitPercent": "Prozent",
    "OfficeStringId.Caption_UnitPoints": "Punkte",
    "OfficeStringId.FileFilterDescription_AllFiles": "Alle Dateien",
    "OfficeStringId.FileFilterDescription_AllSupportedFiles": "Alle unterstützten Dateien",
    "OfficeStringId.FileFilterDescription_BitmapFiles": "Windows Bitmap",
    "OfficeStringId.FileFilterDescription_EmfFiles": "Microsoft Enhanced Metafile",
    "OfficeStringId.FileFilterDescription_GifFiles": "Interchage-Grafikformat",
    "OfficeStringId.FileFilterDescription_JPEGFiles": "JPEG File Interchange Format",
    "OfficeStringId.FileFilterDescription_PNGFiles": "Portable Network Graphics",
    "OfficeStringId.FileFilterDescription_TiffFiles": "Tag Image File Format",
    "OfficeStringId.FileFilterDescription_WmfFiles": "Windows-Metadatei",
    "OfficeStringId.MenuCmd_AlignmentCenter": "Zentriert",
    "OfficeStringId.MenuCmd_AlignmentCenterDescription": "Zentrieren von Text.",
    "OfficeStringId.MenuCmd_AlignmentJustify": "Blocksatz",
    "OfficeStringId.MenuCmd_AlignmentJustifyDescription": "Text an der linken und rechten Ränder hinzufügen zusätzliche Leerzeichen zwischen Wörtern Bedarf ausrichten.\n\nDies erzeugt ein sauberes Schriftbild entlang der linken und rechten Seite der Seite.",
    "OfficeStringId.MenuCmd_AlignmentLeft": "Text linksbündig",
    "OfficeStringId.MenuCmd_AlignmentLeftDescription": "Ausrichten von Text auf der linken Seite.",
    "OfficeStringId.MenuCmd_AlignmentRight": "Text rechtsbündig",
    "OfficeStringId.MenuCmd_AlignmentRightDescription": "Ausrichten von Text auf der rechten Seite.",
    "OfficeStringId.MenuCmd_ChangeFontColor": "Schriftfarbe",
    "OfficeStringId.MenuCmd_ChangeFontColorDescription": "Die Textfarbe ändern.",
    "OfficeStringId.MenuCmd_ChangeFontName": "Schriftart",
    "OfficeStringId.MenuCmd_ChangeFontNameDescription": "Die Schriftart ändern.",
    "OfficeStringId.MenuCmd_ChangeFontSize": "Schriftgröße",
    "OfficeStringId.MenuCmd_ChangeFontSizeDescription": "Ändern des Schriftgrads.",
    "OfficeStringId.MenuCmd_ClearUndo": "ClearUndo",
    "OfficeStringId.MenuCmd_ClearUndoDescription": "Clear Undo Buffer",
    "OfficeStringId.MenuCmd_CopySelection": "Kopieren",
    "OfficeStringId.MenuCmd_CopySelectionDescription": "Kopieren Sie die Auswahl und legen Sie sie in die Zwischenablage.",
    "OfficeStringId.MenuCmd_CutSelection": "Ausschneiden",
    "OfficeStringId.MenuCmd_CutSelectionDescription": "Die Auswahl aus dem Dokument ausschneiden und in der Zwischenablage ablegen.",
    "OfficeStringId.MenuCmd_DecreaseFontSize": "Schrift verkleinern",
    "OfficeStringId.MenuCmd_DecreaseFontSizeDescription": "Verkleinern des Schriftgrads.",
    "OfficeStringId.MenuCmd_DeleteComment": "Kommentar löschen",
    "OfficeStringId.MenuCmd_DeleteCommentDescription": "Den ausgewählten Kommentar löschen.",
    "OfficeStringId.MenuCmd_EditComment": "Kommentar bearbeiten",
    "OfficeStringId.MenuCmd_EditCommentDescription": "Add a note about this part of the document.",
    "OfficeStringId.MenuCmd_EditHyperlink": "Hyperlink bearbeiten...",
    "OfficeStringId.MenuCmd_EditHyperlinkDescription": "Hyperlink bearbeiten...",
    "OfficeStringId.MenuCmd_Encrypt": "Mit Kennwort verschlüsseln",
    "OfficeStringId.MenuCmd_EncryptDescription": "Ein Kennwort zum Öffnen dieser Arbeitsmappe vorschreiben.",
    "OfficeStringId.MenuCmd_FloatingObjectBringForward": "Ebene nach vorne",
    "OfficeStringId.MenuCmd_FloatingObjectBringForwardCommandGroup": "Ebene nach vorne",
    "OfficeStringId.MenuCmd_FloatingObjectBringForwardCommandGroupDescription": "Das markierte Objekt eine Ebene nach vorne oder in den Vordergrund vor alle anderen Objekte verschieben.",
    "OfficeStringId.MenuCmd_FloatingObjectBringForwardDescription": "Das ausgewählte Objekt weiter in den Vordergrund verschieben, sodass es von einer geringeren Anzahl von Objekten verdeckt wird, die sich vor ihm befinden.",
    "OfficeStringId.MenuCmd_FloatingObjectBringToFront": "In den Vordergrund",
    "OfficeStringId.MenuCmd_FloatingObjectBringToFrontDescription": "Stellen Sie das ausgewählte Objekt vor alle anderen Objekte in den Vordergrund.",
    "OfficeStringId.MenuCmd_FloatingObjectSendBackward": "Ebene nach hinten",
    "OfficeStringId.MenuCmd_FloatingObjectSendBackwardCommandGroup": "Ebene nach hinten",
    "OfficeStringId.MenuCmd_FloatingObjectSendBackwardCommandGroupDescription": "Das markierte Objekt eine Ebene nach hinten oder in den Hintergrund hinter alle Objekte verschieben.",
    "OfficeStringId.MenuCmd_FloatingObjectSendBackwardDescription": "Das ausgewählte Objekt weiter in den Hintergrund verschieben, sodass es von den Objekten verdeckt wird, die sich vor ihm befinden.",
    "OfficeStringId.MenuCmd_FloatingObjectSendToBack": "In den Hintergrund",
    "OfficeStringId.MenuCmd_FloatingObjectSendToBackDescription": "Stellen Sie das ausgewählte Objekt hinter alle anderen Objekte in den Hintergrund.",
    "OfficeStringId.MenuCmd_Hyperlink": "Link",
    "OfficeStringId.MenuCmd_HyperlinkDescription": "Einen Link in Ihrem Dokument erstellen, um den schnellen Zugriff auf Webseiten und Dateien zu ermöglichen.\n\nMit Links können Sie auch an Stellen innerhalb Ihres Dokuments springen.",
    "OfficeStringId.MenuCmd_IncreaseFontSize": "Grow Font",
    "OfficeStringId.MenuCmd_IncreaseFontSizeDescription": "Increase the font size.",
    "OfficeStringId.MenuCmd_InsertComment": "Kommentar einfügen",
    "OfficeStringId.MenuCmd_InsertCommentDescription": "Fügen Sie eine Notiz zu diesem Teil des Dokuments hinzu.",
    "OfficeStringId.MenuCmd_InsertFloatingObjectPicture": "Bild",
    "OfficeStringId.MenuCmd_InsertFloatingObjectPictureDescription": "Einfügen eines Bildes aus einer Datei.",
    "OfficeStringId.MenuCmd_InsertHyperlink": "Link...",
    "OfficeStringId.MenuCmd_InsertHyperlinkDescription": "Einen Link in Ihrem Dokument erstellen, um den schnellen Zugriff auf Webseiten und Dateien zu ermöglichen.\n\nMit Links können Sie auch an Stellen innerhalb Ihres Dokuments springen.",
    "OfficeStringId.MenuCmd_InsertSymbol": "Symbol",
    "OfficeStringId.MenuCmd_InsertSymbolDescription": "Symbole einfügen, die auf der Tastatur nicht vorhanden sind, z. B. Copyrightsymbole, Markensymbole, Absatzmarken und Unicode-Zeichen.",
    "OfficeStringId.MenuCmd_LoadDocument": "Öffnen",
    "OfficeStringId.MenuCmd_LoadDocumentDescription": "Öffnen Sie ein Dokument.",
    "OfficeStringId.MenuCmd_NewEmptyDocument": "Neu",
    "OfficeStringId.MenuCmd_NewEmptyDocumentDescription": "Erstellen Sie ein neues Dokument.",
    "OfficeStringId.MenuCmd_OpenHyperlink": "Hyperlink öffnen",
    "OfficeStringId.MenuCmd_OpenHyperlinkDescription": "Öffne Hyperlink",
    "OfficeStringId.MenuCmd_PageMarginsModerate": "Moderate\nTop:\t{1,10}\tBottom:\t{3,10}\nLeft:\t{0,10}\tRight:\t\t{2,10}",
    "OfficeStringId.MenuCmd_PageMarginsModerateDescription": " ",
    "OfficeStringId.MenuCmd_PageMarginsNarrow": "Schmal\nOben:\t{1,10}\tUnten:\t{3,10}\nLinks:\t{0,10}\tRechts:\t{2,10}",
    "OfficeStringId.MenuCmd_PageMarginsNarrowDescription": " ",
    "OfficeStringId.MenuCmd_PageMarginsNormal": "Normal\nOben:\t{1,10}\tUnten:\t{3,10}\nLinks:\t{0,10}\tRechts:\t{2,10}",
    "OfficeStringId.MenuCmd_PageMarginsNormalDescription": " ",
    "OfficeStringId.MenuCmd_PageMarginsWide": "Breit\nOben:\t{1,10}\tUnten:\t{3,10}\nLinks:\t{0,10}\tRechts:\t{2,10}",
    "OfficeStringId.MenuCmd_PageMarginsWideDescription": " ",
    "OfficeStringId.MenuCmd_PageOrientationCommandGroup": "Ausrichtung",
    "OfficeStringId.MenuCmd_PageOrientationCommandGroupDescription": "Richten Sie Ihre Seiten im Hoch- oder Querformat aus.",
    "OfficeStringId.MenuCmd_PageOrientationLandscape": "Querformat",
    "OfficeStringId.MenuCmd_PageOrientationLandscapeDescription": " ",
    "OfficeStringId.MenuCmd_PageOrientationPortrait": "Hochformat",
    "OfficeStringId.MenuCmd_PageOrientationPortraitDescription": " ",
    "OfficeStringId.MenuCmd_Paste": "Einfügen",
    "OfficeStringId.MenuCmd_PasteDescription": "Fügen Sie den Inhalt der Zwischenablage ein.",
    "OfficeStringId.MenuCmd_Print": "&Drucken",
    "OfficeStringId.MenuCmd_PrintDescription": "Wählen Sie einen Drucker, Anzahl der Kopien und andere Druckoptionen vor dem Drucken.",
    "OfficeStringId.MenuCmd_PrintPreview": "Druck&vorschau",
    "OfficeStringId.MenuCmd_PrintPreviewDescription": "Druckvorschau aufrufen vor dem Druck vornehmen.",
    "OfficeStringId.MenuCmd_QuickPrint": "&Schnelldruck",
    "OfficeStringId.MenuCmd_QuickPrintDescription": "Senden Sie das Dokument ohne Änderungen direkt an den Standarddrucker.",
    "OfficeStringId.MenuCmd_Redo": "Wiederholen",
    "OfficeStringId.MenuCmd_RedoDescription": "Wiederholen",
    "OfficeStringId.MenuCmd_RemoveHyperlink": "Hyperlink entfernen",
    "OfficeStringId.MenuCmd_RemoveHyperlinkDescription": "Hyperlink entfernen",
    "OfficeStringId.MenuCmd_RemoveHyperlinks": "Hyperlinks entfernen",
    "OfficeStringId.MenuCmd_RemoveHyperlinksDescription": "Hyperlinks entfernen",
    "OfficeStringId.MenuCmd_SaveDocument": "Speichern",
    "OfficeStringId.MenuCmd_SaveDocumentAs": "Speichern unter",
    "OfficeStringId.MenuCmd_SaveDocumentAsDescription": "Öffnen des Dialogfelds Speichern unter, wählen ein Dateiformat zu speichern Sie das Dokument an einen neuen Speicherort.",
    "OfficeStringId.MenuCmd_SaveDocumentDescription": "Das Dokument speichern.",
    "OfficeStringId.MenuCmd_ShowPasteSpecialForm": "Inhalte einfügen",
    "OfficeStringId.MenuCmd_ShowPasteSpecialFormDescription": "Zeigen Sie das Dialogfeld Inhalte einfügen.",
    "OfficeStringId.MenuCmd_StatusBarPopupMenuHeader": "Statusleiste anpassen",
    "OfficeStringId.MenuCmd_StatusBarPopupMenuZoom": "Zoom",
    "OfficeStringId.MenuCmd_StatusBarPopupMenuZoomSlider": "Zoomregler",
    "OfficeStringId.MenuCmd_StatusBarZoomDescription": "Zoommodus. Klicken Sie hier, um das Dialogfeld 'Zoom' zu öffnen.",
    "OfficeStringId.MenuCmd_StatusBarZoomSliderDescription": "Zoom",
    "OfficeStringId.MenuCmd_ToggleFontBold": "Fett",
    "OfficeStringId.MenuCmd_ToggleFontBoldDescription": "Make the selected text bold.",
    "OfficeStringId.MenuCmd_ToggleFontDoubleUnderline": "Doppelt unterstrichen",
    "OfficeStringId.MenuCmd_ToggleFontDoubleUnderlineDescription": "Doppelt unterstrichen",
    "OfficeStringId.MenuCmd_ToggleFontItalic": "Kursiv",
    "OfficeStringId.MenuCmd_ToggleFontItalicDescription": "Italicize the selected text.",
    "OfficeStringId.MenuCmd_ToggleFontStrikeout": "Durchgestrichen",
    "OfficeStringId.MenuCmd_ToggleFontStrikeoutDescription": "Zeichnen Sie eine Linie durch die Mitte des ausgewählten Texts.",
    "OfficeStringId.MenuCmd_ToggleFontUnderline": "Unterstreichen",
    "OfficeStringId.MenuCmd_ToggleFontUnderlineDescription": "Den markierten Text unterstreichen.",
    "OfficeStringId.MenuCmd_Undo": "Rückgängig machen",
    "OfficeStringId.MenuCmd_UndoDescription": "Rückgängig machen",
    "OfficeStringId.MenuCmd_Zoom100Percent": "100 %",
    "OfficeStringId.MenuCmd_Zoom100PercentDescription": "Vergrößern Sie das Dokument zu 100 % der normalen Größe.",
    "OfficeStringId.MenuCmd_ZoomIn": "Vergrößern",
    "OfficeStringId.MenuCmd_ZoomInDescription": "Vergrößern",
    "OfficeStringId.MenuCmd_ZoomOut": "Verkleinern",
    "OfficeStringId.MenuCmd_ZoomOutDescription": "Verkleinern",
    "OfficeStringId.Msg_InternalError": "Ein interner Fehler ist aufgetreten.",
    "OfficeStringId.Msg_InvalidBeginInit": "Fehler: Aufruf der Prozedur BeginInit innerhalb der Prozedur BeginUpdate",
    "OfficeStringId.Msg_InvalidBeginUpdate": "Fehler: Aufruf der Prozedur BeginUpdate innerhalb der Prozedur BeginInit",
    "OfficeStringId.Msg_InvalidCopyFromDocumentModel": "Fehler: Die Dateistrukturen des Quell- und Zieldokuments sind verschieden.",
    "OfficeStringId.Msg_InvalidEndInit": "Fehler: Aufruf der Prozedur EndInit oder CancelInit ohne vorangehendem Aufruf von BeginInit oder innerhalb der Prozedur BeginUpdate",
    "OfficeStringId.Msg_InvalidEndUpdate": "Fehler: Aufruf der Prozedur EndUpdate oder CancelUpate ohne Aufruf der Pozedur BeginUpdate innerhalb von BeginInit",
    "OfficeStringId.Msg_InvalidFontSize": "Die Anzahl muss zwischen {0} und {1} sein.",
    "OfficeStringId.Msg_InvalidNumber": "Dies ist keine gültige Zahl.",
    "OfficeStringId.Msg_InvalidNumberConverterValue": "Value must be between {0} and {1}.",
    "OfficeStringId.Msg_InvalidRemoveDataSource": "Eine Datenquelle kann nicht während des Seriendruckvorgangs gelöscht werden.",
    "OfficeStringId.Msg_IsNotValid": "'{0}' ist kein gültiger Wert für '{1}'",
    "OfficeStringId.Msg_Loading": "Laden...",
    "OfficeStringId.Msg_MagicNumberNotFound": "Die Datei, die Sie versuchen zu öffnen, ist in anderen Format als durch die Dateierweiterung angegebene.",
    "OfficeStringId.Msg_UnsupportedFormatOrCorruptedFile": "Nicht unterstütztes Format oder beschädigte Datei.",
    "OfficeStringId.UnitAbbreviation_Centimeter": " cm",
    "OfficeStringId.UnitAbbreviation_Inch": "\"",
    "OfficeStringId.UnitAbbreviation_Millimeter": " mm",
    "OfficeStringId.UnitAbbreviation_Percent": "%",
    "OfficeStringId.UnitAbbreviation_Pica": "pc",
    "OfficeStringId.UnitAbbreviation_Point": " pt",
    "XtraRichEditStringId.BorderLineStyleNone": "keiner",
    "XtraRichEditStringId.Caption_AlignmentCenter": "Zentriert",
    "XtraRichEditStringId.Caption_AlignmentDistribute": "Distributed",
    "XtraRichEditStringId.Caption_AlignmentJustify": "Blocksatz",
    "XtraRichEditStringId.Caption_AlignmentJustifyHigh": "Justify High",
    "XtraRichEditStringId.Caption_AlignmentJustifyLow": "Justify Low",
    "XtraRichEditStringId.Caption_AlignmentJustifyMedium": "Justify Medium",
    "XtraRichEditStringId.Caption_AlignmentLeft": "Links",
    "XtraRichEditStringId.Caption_AlignmentRight": "Rechts",
    "XtraRichEditStringId.Caption_AlignmentThaiDistribute": "Thai Distributed",
    "XtraRichEditStringId.Caption_AllMarkup": "Markup: alle",
    "XtraRichEditStringId.Caption_BulletsAndNumbering_Nothing": "Nichts",
    "XtraRichEditStringId.Caption_BulletsAndNumbering_SpaceCharacter": "Space",
    "XtraRichEditStringId.Caption_BulletsAndNumbering_TabCharacter": "Tab character",
    "XtraRichEditStringId.Caption_Bytes": "Bytes",
    "XtraRichEditStringId.Caption_CaptionPrefixEquation": "Gleichung",
    "XtraRichEditStringId.Caption_CaptionPrefixFigure": "Figur",
    "XtraRichEditStringId.Caption_CaptionPrefixTable": "Tabelle",
    "XtraRichEditStringId.Caption_ClipboardSubItem": "Zwischenablage",
    "XtraRichEditStringId.Caption_ColorAutomatic": "Automatisch",
    "XtraRichEditStringId.Caption_ColumnBreak": "Column Break",
    "XtraRichEditStringId.Caption_Comments": "Kommentare",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_BottomLeftCell": "Untere linke Zelle",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_BottomRightCell": "Zelle rechts unten",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_EvenColumnBanding": "Auch gebänderte Spalten",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_EvenRowBanding": "Auch gebänderte Zeilen",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_FirstColumn": "Erste Spalte",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_FirstRow": "Kopfzeile",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_LastColumn": "Letzte Spalte",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_LastRow": "Ergebniszeile",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_OddColumnBanding": "Ungerade gebänderte Spalten",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_OddRowBanding": "Ungerade gebänderte Zeilen",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_TopLeftCell": "Oberste linke Zelle",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_TopRightCell": "Oberste rechte Zelle",
    "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_WholeTable": "Gesamte Tabelle",
    "XtraRichEditStringId.Caption_CurrentDocumentHyperlinkTooltip": "Aktuelles Dokument",
    "XtraRichEditStringId.Caption_CurrentParagraphText": "Beispiel Text",
    "XtraRichEditStringId.Caption_CustomPropertyCheckedBy": "Checked by",
    "XtraRichEditStringId.Caption_CustomPropertyClient": "Client",
    "XtraRichEditStringId.Caption_CustomPropertyDateCompleted": "Abschlussdatum",
    "XtraRichEditStringId.Caption_CustomPropertyDepartment": "Abteilung",
    "XtraRichEditStringId.Caption_CustomPropertyDestination": "Ziel",
    "XtraRichEditStringId.Caption_CustomPropertyDisposition": "Disposition",
    "XtraRichEditStringId.Caption_CustomPropertyDivision": "Division",
    "XtraRichEditStringId.Caption_CustomPropertyDocumentNumber": "Belegnummer",
    "XtraRichEditStringId.Caption_CustomPropertyEditor": "Editor",
    "XtraRichEditStringId.Caption_CustomPropertyForwardTo": "Weiterleiten an",
    "XtraRichEditStringId.Caption_CustomPropertyGroup": "Gruppe",
    "XtraRichEditStringId.Caption_CustomPropertyLanguage": "Sprache",
    "XtraRichEditStringId.Caption_CustomPropertyMailstop": "Mailstop",
    "XtraRichEditStringId.Caption_CustomPropertyMatter": "Angelegenheit",
    "XtraRichEditStringId.Caption_CustomPropertyNo": "Nein",
    "XtraRichEditStringId.Caption_CustomPropertyOffice": "Büro",
    "XtraRichEditStringId.Caption_CustomPropertyOwner": "Besitzer",
    "XtraRichEditStringId.Caption_CustomPropertyProject": "Projekt",
    "XtraRichEditStringId.Caption_CustomPropertyPublisher": "Publisher",
    "XtraRichEditStringId.Caption_CustomPropertyPurpose": "Purpose",
    "XtraRichEditStringId.Caption_CustomPropertyReceivedFrom": "Erhalten von",
    "XtraRichEditStringId.Caption_CustomPropertyRecordedBy": "Aufgenommen von",
    "XtraRichEditStringId.Caption_CustomPropertyRecordedDate": "Recorded date",
    "XtraRichEditStringId.Caption_CustomPropertyReference": "Referenz",
    "XtraRichEditStringId.Caption_CustomPropertySource": "Quelle",
    "XtraRichEditStringId.Caption_CustomPropertyStatus": "Status",
    "XtraRichEditStringId.Caption_CustomPropertyTelephoneNumber": "Telephone number",
    "XtraRichEditStringId.Caption_CustomPropertyTypeBoolean": "Ja oder Nein",
    "XtraRichEditStringId.Caption_CustomPropertyTypeDateTime": "Datum",
    "XtraRichEditStringId.Caption_CustomPropertyTypeNumber": "Nummer",
    "XtraRichEditStringId.Caption_CustomPropertyTypeText": "Text",
    "XtraRichEditStringId.Caption_CustomPropertyTypist": "Schreibkraft",
    "XtraRichEditStringId.Caption_CustomPropertyValue": "Wert",
    "XtraRichEditStringId.Caption_CustomPropertyYes": "Ja",
    "XtraRichEditStringId.Caption_EmptyParentStyle": "(zugrunde liegende Eigenschaften)",
    "XtraRichEditStringId.Caption_EvenPageFooter": "Fußzeile gerade Seite",
    "XtraRichEditStringId.Caption_EvenPageHeader": "Kopfzeile gerade Seite",
    "XtraRichEditStringId.Caption_FirstLineIndentHanging": "Hängender Einzug",
    "XtraRichEditStringId.Caption_FirstLineIndentIndented": "Erste Zeile",
    "XtraRichEditStringId.Caption_FirstLineIndentNone": "(keiner)",
    "XtraRichEditStringId.Caption_FirstPageFooter": "Fußzeile erste Seite",
    "XtraRichEditStringId.Caption_FirstPageHeader": "Kopfzeile erste Seite",
    "XtraRichEditStringId.Caption_FollowingParagraphText": "Folgender Paragraph",
    "XtraRichEditStringId.Caption_GroupDocumentChanges": "Änderungen",
    "XtraRichEditStringId.Caption_GroupTableAlignment": "Ausrichtung",
    "XtraRichEditStringId.Caption_GroupTableCellSize": "Zellengröße",
    "XtraRichEditStringId.Caption_GroupTableDrawBorders": "Grenzen & & Schattierungen",
    "XtraRichEditStringId.Caption_GroupTableMerge": "Zusammenführen",
    "XtraRichEditStringId.Caption_GroupTableRowsAndColumns": "Zeilen && Spalten",
    "XtraRichEditStringId.Caption_GroupTableStyleOptions": "Optionen für Tabellenformat",
    "XtraRichEditStringId.Caption_GroupTableStyles": "Tabellenformatvorlagen",
    "XtraRichEditStringId.Caption_GroupTableTable": "Tabelle",
    "XtraRichEditStringId.Caption_Heading": "Überschrift",
    "XtraRichEditStringId.Caption_HeightTypeExact": "Genau",
    "XtraRichEditStringId.Caption_HeightTypeMinimum": "Mindestens",
    "XtraRichEditStringId.Caption_LineSpacingAtLeast": "Mindestens",
    "XtraRichEditStringId.Caption_LineSpacingDouble": "Doppelt",
    "XtraRichEditStringId.Caption_LineSpacingExactly": "Genau",
    "XtraRichEditStringId.Caption_LineSpacingMultiple": "Mehrfach",
    "XtraRichEditStringId.Caption_LineSpacingSesquialteral": "1,5 Zeilen",
    "XtraRichEditStringId.Caption_LineSpacingSingle": "Einfach",
    "XtraRichEditStringId.Caption_MainDocumentComments": "Dokument Kommentare",
    "XtraRichEditStringId.Caption_NoColor": "Keine Farbe",
    "XtraRichEditStringId.Caption_NoMarkup": "Markup: keine",
    "XtraRichEditStringId.Caption_NumberingListBoxNone": "Nichts",
    "XtraRichEditStringId.Caption_OddPageFooter": "Fußzeile ungerade Seite",
    "XtraRichEditStringId.Caption_OddPageHeader": "Kopfzeile ungerade Seite",
    "XtraRichEditStringId.Caption_OriginalMarkup": "Orginal",
    "XtraRichEditStringId.Caption_OutlineLevel1": "Ebene 1",
    "XtraRichEditStringId.Caption_OutlineLevel2": "Ebene 2",
    "XtraRichEditStringId.Caption_OutlineLevel3": "Ebene 3",
    "XtraRichEditStringId.Caption_OutlineLevel4": "Ebene 4",
    "XtraRichEditStringId.Caption_OutlineLevel5": "Ebene 5",
    "XtraRichEditStringId.Caption_OutlineLevel6": "Ebene 6",
    "XtraRichEditStringId.Caption_OutlineLevel7": "Ebene 7",
    "XtraRichEditStringId.Caption_OutlineLevel8": "Ebene 8",
    "XtraRichEditStringId.Caption_OutlineLevel9": "Ebene 9",
    "XtraRichEditStringId.Caption_OutlineLevelBody": "Textkörper",
    "XtraRichEditStringId.Caption_PageBreak": "Seitenumbruch",
    "XtraRichEditStringId.Caption_PageCategoryTableTools": "Tabellentools",
    "XtraRichEditStringId.Caption_PageFooter": "Fuß",
    "XtraRichEditStringId.Caption_PageHeader": "Kopf",
    "XtraRichEditStringId.Caption_PageSetupSectionDirectionLeftToRight": "Links-nach-rechts",
    "XtraRichEditStringId.Caption_PageSetupSectionDirectionRightToLeft": "Rechts-nach-links",
    "XtraRichEditStringId.Caption_PageSetupSectionStartColumn": "Neue Spalte",
    "XtraRichEditStringId.Caption_PageSetupSectionStartContinuous": "Fortlaufend",
    "XtraRichEditStringId.Caption_PageSetupSectionStartEvenPage": "Jede Seite",
    "XtraRichEditStringId.Caption_PageSetupSectionStartNextPage": "Neue Seite",
    "XtraRichEditStringId.Caption_PageSetupSectionStartOddPage": "Ungerade Seiten",
    "XtraRichEditStringId.Caption_PageTableDesign": "Design",
    "XtraRichEditStringId.Caption_PageTableLayout": "Layout",
    "XtraRichEditStringId.Caption_ParagraphAlignment_Center": "Zentriert",
    "XtraRichEditStringId.Caption_ParagraphAlignment_Distribute": "Distributed",
    "XtraRichEditStringId.Caption_ParagraphAlignment_Justify": "Anpassen",
    "XtraRichEditStringId.Caption_ParagraphAlignment_JustifyHigh": "Justify High",
    "XtraRichEditStringId.Caption_ParagraphAlignment_JustifyLow": "Justify Low",
    "XtraRichEditStringId.Caption_ParagraphAlignment_JustifyMedium": "Justify Medium",
    "XtraRichEditStringId.Caption_ParagraphAlignment_Left": "Links",
    "XtraRichEditStringId.Caption_ParagraphAlignment_Right": "Rechts",
    "XtraRichEditStringId.Caption_ParagraphAlignment_ThaiDistribute": "Thai Distributed",
    "XtraRichEditStringId.Caption_ParagraphFirstLineIndent_Hanging": "Anhängen",
    "XtraRichEditStringId.Caption_ParagraphFirstLineIndent_Indented": "Eingerückt",
    "XtraRichEditStringId.Caption_ParagraphFirstLineIndent_None": "Keine",
    "XtraRichEditStringId.Caption_ParagraphLineSpacing_AtLeast": "Zuletzt",
    "XtraRichEditStringId.Caption_ParagraphLineSpacing_Double": "Doppelt",
    "XtraRichEditStringId.Caption_ParagraphLineSpacing_Exactly": "Genau",
    "XtraRichEditStringId.Caption_ParagraphLineSpacing_Multiple": "Mehrere",
    "XtraRichEditStringId.Caption_ParagraphLineSpacing_Sesquialteral": "Sequenziell",
    "XtraRichEditStringId.Caption_ParagraphLineSpacing_Single": "Einfach",
    "XtraRichEditStringId.Caption_PreviousParagraphText": "Vorheriger Paragraph",
    "XtraRichEditStringId.Caption_Readonly": "Keine Änderungen (schreibgeschützt)",
    "XtraRichEditStringId.Caption_SameAsPrevious": "Wie die vorherige Seite",
    "XtraRichEditStringId.Caption_SectionBreak": "Section Break",
    "XtraRichEditStringId.Caption_SectionBreakTypeColumn": "Spalte",
    "XtraRichEditStringId.Caption_SectionBreakTypeContinuous": "Fortlaufend",
    "XtraRichEditStringId.Caption_SectionBreakTypeEvenPage": "Even Page",
    "XtraRichEditStringId.Caption_SectionBreakTypeNextPage": "Nächste Seite",
    "XtraRichEditStringId.Caption_SectionBreakTypeOddPage": "Odd Page",
    "XtraRichEditStringId.Caption_SectionPropertiesApplyThisPointForward": "Dieser Punkt nach vorne",
    "XtraRichEditStringId.Caption_SectionPropertiesApplyToCurrentSection": "Aktuelle Sektion",
    "XtraRichEditStringId.Caption_SectionPropertiesApplyToSelectedSections": "Wähle Sektion",
    "XtraRichEditStringId.Caption_SectionPropertiesApplyToWholeDocument": "Gesamtes Dokument",
    "XtraRichEditStringId.Caption_SimpleMarkup": "Einfaches Markup",
    "XtraRichEditStringId.Caption_TrackedChanges": "Nachverfolgungen",
    "XtraRichEditStringId.ClearFormatting": "Format zurücksetzen",
    "XtraRichEditStringId.ColorAuto": "Automatisch",
    "XtraRichEditStringId.ColumnsSetupForm_InvalidColumnCount": "Die Zahl muss zwischen {0} und {1} liegen.",
    "XtraRichEditStringId.Comment": "Kommentar [{0} {1}]:",
    "XtraRichEditStringId.CommentToolTipHeader": "von {0}",
    "XtraRichEditStringId.CommentToolTipHeaderWithDate": "{0} von {1}",
    "XtraRichEditStringId.DefaultDocumentName": "Dokument",
    "XtraRichEditStringId.DefaultStyleName_annotationreference": "Anmerkungsreferenz",
    "XtraRichEditStringId.DefaultStyleName_annotationsubject": "Gegenstand der Anmerkung",
    "XtraRichEditStringId.DefaultStyleName_annotationtext": "Anmerkungstext",
    "XtraRichEditStringId.DefaultStyleName_ArticleSection": "Artikel / Abschnitt",
    "XtraRichEditStringId.DefaultStyleName_BalloonText": "Ballontext",
    "XtraRichEditStringId.DefaultStyleName_BlockText": "Blocksatz",
    "XtraRichEditStringId.DefaultStyleName_BodyText": "Textkörper",
    "XtraRichEditStringId.DefaultStyleName_BodyText2": "Textkörper 2",
    "XtraRichEditStringId.DefaultStyleName_BodyText3": "Textkörper 3",
    "XtraRichEditStringId.DefaultStyleName_BodyTextFirstIndent": "Textkörper erster Einzug",
    "XtraRichEditStringId.DefaultStyleName_BodyTextFirstIndent2": "Textkörper erster Einzug 2",
    "XtraRichEditStringId.DefaultStyleName_BodyTextIndent": "Body Text Indent",
    "XtraRichEditStringId.DefaultStyleName_BodyTextIndent2": "Textkörpereinzug 2",
    "XtraRichEditStringId.DefaultStyleName_BodyTextIndent3": "Textkörpereinzug 3",
    "XtraRichEditStringId.DefaultStyleName_caption": "Beschriftung",
    "XtraRichEditStringId.DefaultStyleName_Closing": "Schließen",
    "XtraRichEditStringId.DefaultStyleName_CommentReference": "Comment Reference",
    "XtraRichEditStringId.DefaultStyleName_CommentSubject": "Comment Subject",
    "XtraRichEditStringId.DefaultStyleName_CommentText": "Comment Text",
    "XtraRichEditStringId.DefaultStyleName_Date": "Datum",
    "XtraRichEditStringId.DefaultStyleName_DefaultParagraphFont": "Standardschriftart für den Absatz",
    "XtraRichEditStringId.DefaultStyleName_DocumentMap": "Dokumentenverzeichnis",
    "XtraRichEditStringId.DefaultStyleName_EmailSignature": "E-Mail-Signatur",
    "XtraRichEditStringId.DefaultStyleName_Emphasis": "Emphasis",
    "XtraRichEditStringId.DefaultStyleName_endnotereference": "Endnotenreferenz",
    "XtraRichEditStringId.DefaultStyleName_endnotetext": "Endnotentext",
    "XtraRichEditStringId.DefaultStyleName_envelopeaddress": "Briefumschlag Adresse",
    "XtraRichEditStringId.DefaultStyleName_envelopereturn": "Briefumschlag Absender",
    "XtraRichEditStringId.DefaultStyleName_FollowedHyperlink": "FollowedHyperlink",
    "XtraRichEditStringId.DefaultStyleName_footer": "Fußzeile",
    "XtraRichEditStringId.DefaultStyleName_footnotereference": "Fußnotenreferenz",
    "XtraRichEditStringId.DefaultStyleName_footnotetext": "Fußnotentext",
    "XtraRichEditStringId.DefaultStyleName_header": "Kopfzeile",
    "XtraRichEditStringId.DefaultStyleName_heading1": "Überschrift 1",
    "XtraRichEditStringId.DefaultStyleName_heading2": "Überschrift 2",
    "XtraRichEditStringId.DefaultStyleName_heading3": "Überschrift 3",
    "XtraRichEditStringId.DefaultStyleName_heading4": "Überschrift 4",
    "XtraRichEditStringId.DefaultStyleName_heading5": "Überschrift 5",
    "XtraRichEditStringId.DefaultStyleName_heading6": "Überschrift 6",
    "XtraRichEditStringId.DefaultStyleName_heading7": "Überschrift 7",
    "XtraRichEditStringId.DefaultStyleName_heading8": "Überschrift 8",
    "XtraRichEditStringId.DefaultStyleName_heading9": "Überschrift 9",
    "XtraRichEditStringId.DefaultStyleName_HTMLAcronym": "HTML Kürzel",
    "XtraRichEditStringId.DefaultStyleName_HTMLAddress": "HTML Addresse",
    "XtraRichEditStringId.DefaultStyleName_HTMLBottomofForm": "HTML am Ende des Formulars",
    "XtraRichEditStringId.DefaultStyleName_HTMLCite": "HTML Anführungszeichen",
    "XtraRichEditStringId.DefaultStyleName_HTMLCode": "HTML Kode",
    "XtraRichEditStringId.DefaultStyleName_HTMLDefinition": "HTML Definition",
    "XtraRichEditStringId.DefaultStyleName_HTMLKeyboard": "HTML Tastatur",
    "XtraRichEditStringId.DefaultStyleName_HTMLPreformatted": "HTML Vorformatiert",
    "XtraRichEditStringId.DefaultStyleName_HTMLSample": "HTML Beispiel",
    "XtraRichEditStringId.DefaultStyleName_HTMLTopofForm": "HTML zu Beginn des Formulars",
    "XtraRichEditStringId.DefaultStyleName_HTMLTypewriter": "HTML Schreibmaschine",
    "XtraRichEditStringId.DefaultStyleName_HTMLVariable": "HTML Variabel",
    "XtraRichEditStringId.DefaultStyleName_HyperlinkFollowed": "Hyperlink folgen",
    "XtraRichEditStringId.DefaultStyleName_HyperlinkStrongEmphasis": "Hyperlink betont",
    "XtraRichEditStringId.DefaultStyleName_index1": "Index 1",
    "XtraRichEditStringId.DefaultStyleName_index2": "Index 2",
    "XtraRichEditStringId.DefaultStyleName_index3": "Index 3",
    "XtraRichEditStringId.DefaultStyleName_index4": "Index 4",
    "XtraRichEditStringId.DefaultStyleName_index5": "Index 5",
    "XtraRichEditStringId.DefaultStyleName_index6": "Index 6",
    "XtraRichEditStringId.DefaultStyleName_index7": "Index 7",
    "XtraRichEditStringId.DefaultStyleName_index8": "Index 8",
    "XtraRichEditStringId.DefaultStyleName_index9": "Index 9",
    "XtraRichEditStringId.DefaultStyleName_indexheading": "Index Titel",
    "XtraRichEditStringId.DefaultStyleName_linenumber": "Zeilennummer",
    "XtraRichEditStringId.DefaultStyleName_List": "Liste",
    "XtraRichEditStringId.DefaultStyleName_List2": "Liste 2",
    "XtraRichEditStringId.DefaultStyleName_List3": "Liste 3",
    "XtraRichEditStringId.DefaultStyleName_List4": "Liste 4",
    "XtraRichEditStringId.DefaultStyleName_List5": "Liste 5",
    "XtraRichEditStringId.DefaultStyleName_ListBullet": "Listenpunkt",
    "XtraRichEditStringId.DefaultStyleName_ListBullet2": "Listenpunkt 2",
    "XtraRichEditStringId.DefaultStyleName_ListBullet3": "Listenpunkt 3",
    "XtraRichEditStringId.DefaultStyleName_ListBullet4": "Listenpunkt 4",
    "XtraRichEditStringId.DefaultStyleName_ListBullet5": "Listenpunkt 5",
    "XtraRichEditStringId.DefaultStyleName_ListContinue": "Fortsetzungsliste",
    "XtraRichEditStringId.DefaultStyleName_ListContinue2": "Fortsetzungsliste 2",
    "XtraRichEditStringId.DefaultStyleName_ListContinue3": "Fortsetzungsliste 3",
    "XtraRichEditStringId.DefaultStyleName_ListContinue4": "Fortsetzungsliste 4",
    "XtraRichEditStringId.DefaultStyleName_ListContinue5": "Fortsetzungsliste 5",
    "XtraRichEditStringId.DefaultStyleName_ListNumber": "Listennummer",
    "XtraRichEditStringId.DefaultStyleName_ListNumber2": "Listennummer 2",
    "XtraRichEditStringId.DefaultStyleName_ListNumber3": "Listennummer 3",
    "XtraRichEditStringId.DefaultStyleName_ListNumber4": "Listennummer 4",
    "XtraRichEditStringId.DefaultStyleName_ListNumber5": "Listennummer 5",
    "XtraRichEditStringId.DefaultStyleName_MacroText": "Macro Text",
    "XtraRichEditStringId.DefaultStyleName_macrotoaheading": "Überschriftenmakro",
    "XtraRichEditStringId.DefaultStyleName_MessageHeader": "Nachrichtenüberschrift",
    "XtraRichEditStringId.DefaultStyleName_NoList": "keine Liste",
    "XtraRichEditStringId.DefaultStyleName_Normal": "Normal",
    "XtraRichEditStringId.DefaultStyleName_NormalIndent": "Normaler Einzug",
    "XtraRichEditStringId.DefaultStyleName_NormalTable": "Normale Tabelle",
    "XtraRichEditStringId.DefaultStyleName_NormalWeb": "Normal (Web)",
    "XtraRichEditStringId.DefaultStyleName_NoteHeading": "Bemerkung Titel",
    "XtraRichEditStringId.DefaultStyleName_OutlineList1": "Gliederung 1",
    "XtraRichEditStringId.DefaultStyleName_OutlineList2": "Gliederung 2",
    "XtraRichEditStringId.DefaultStyleName_OutlineList3": "Gliederung 3",
    "XtraRichEditStringId.DefaultStyleName_pagenumber": "Seitenzahl",
    "XtraRichEditStringId.DefaultStyleName_PlainText": "Reiner Text",
    "XtraRichEditStringId.DefaultStyleName_Salutation": "Begrüßung",
    "XtraRichEditStringId.DefaultStyleName_Signature": "Signatur",
    "XtraRichEditStringId.DefaultStyleName_Strong": "Strong",
    "XtraRichEditStringId.DefaultStyleName_Subtitle": "Untertitel",
    "XtraRichEditStringId.DefaultStyleName_Table3Deffects1": "Tabelle 3D Effekte 1",
    "XtraRichEditStringId.DefaultStyleName_Table3Deffects2": "Tabelle 3D Effekte 2",
    "XtraRichEditStringId.DefaultStyleName_Table3Deffects3": "Tabelle 3D Effekte 3",
    "XtraRichEditStringId.DefaultStyleName_TableClassic1": "Tabelle klassisch 1",
    "XtraRichEditStringId.DefaultStyleName_TableClassic2": "Tabelle klassisch 2",
    "XtraRichEditStringId.DefaultStyleName_TableClassic3": "Tabelle klassisch 3",
    "XtraRichEditStringId.DefaultStyleName_TableClassic4": "Tabelle klassisch 4",
    "XtraRichEditStringId.DefaultStyleName_TableColorful1": "Tabelle farbig 1",
    "XtraRichEditStringId.DefaultStyleName_TableColorful2": "Tabelle farbig 2",
    "XtraRichEditStringId.DefaultStyleName_TableColorful3": "Tabelle farbig 3",
    "XtraRichEditStringId.DefaultStyleName_TableColumns1": "Tabelle 1-spaltig",
    "XtraRichEditStringId.DefaultStyleName_TableColumns2": "Tabelle 2-spaltig",
    "XtraRichEditStringId.DefaultStyleName_TableColumns3": "Tabelle 3-spaltig",
    "XtraRichEditStringId.DefaultStyleName_TableColumns4": "Tabelle 4-spaltig",
    "XtraRichEditStringId.DefaultStyleName_TableColumns5": "Tabelle 5-spaltig",
    "XtraRichEditStringId.DefaultStyleName_TableContemporary": "Tabelle zeitgenössisch",
    "XtraRichEditStringId.DefaultStyleName_TableElegant": "Tabelle elegant",
    "XtraRichEditStringId.DefaultStyleName_TableGrid": "Datentabelle",
    "XtraRichEditStringId.DefaultStyleName_TableGrid1": "Tabelle Gitternetz 1",
    "XtraRichEditStringId.DefaultStyleName_TableGrid2": "Tabelle Gitternetz 2",
    "XtraRichEditStringId.DefaultStyleName_TableGrid3": "Tabelle Gitternetz 3",
    "XtraRichEditStringId.DefaultStyleName_TableGrid4": "Tabelle Gitternetz 4",
    "XtraRichEditStringId.DefaultStyleName_TableGrid5": "Tabelle Gitternetz 5",
    "XtraRichEditStringId.DefaultStyleName_TableGrid6": "Tabelle Gitternetz 6",
    "XtraRichEditStringId.DefaultStyleName_TableGrid7": "Tabelle Gitternetz 7",
    "XtraRichEditStringId.DefaultStyleName_TableGrid8": "Tabelle Gitternetz 8",
    "XtraRichEditStringId.DefaultStyleName_TableList1": "Tabelle Liste 1",
    "XtraRichEditStringId.DefaultStyleName_TableList2": "Tabelle Liste 2",
    "XtraRichEditStringId.DefaultStyleName_TableList3": "Tabelle Liste 3",
    "XtraRichEditStringId.DefaultStyleName_TableList4": "Tabelle Liste 4",
    "XtraRichEditStringId.DefaultStyleName_TableList5": "Tabelle List 5",
    "XtraRichEditStringId.DefaultStyleName_TableList6": "Tabelle Liste 6",
    "XtraRichEditStringId.DefaultStyleName_TableList7": "Tabelle Liste 7",
    "XtraRichEditStringId.DefaultStyleName_TableList8": "Tabelle Liste 8",
    "XtraRichEditStringId.DefaultStyleName_TableNormal": "Table Normal",
    "XtraRichEditStringId.DefaultStyleName_tableofauthorities": "Quellenverzeichnis",
    "XtraRichEditStringId.DefaultStyleName_tableoffigures": "Abbildungsverzeichnis",
    "XtraRichEditStringId.DefaultStyleName_TableProfessional": "Professionelle Tabelle",
    "XtraRichEditStringId.DefaultStyleName_TableSimple1": "Tabelle einfach 1",
    "XtraRichEditStringId.DefaultStyleName_TableSimple2": "Tabelle einfach 2",
    "XtraRichEditStringId.DefaultStyleName_TableSimple3": "Tabelle einfach 3",
    "XtraRichEditStringId.DefaultStyleName_TableSubtle1": "Tabelle fein 1",
    "XtraRichEditStringId.DefaultStyleName_TableSubtle2": "Tabelle fein 2",
    "XtraRichEditStringId.DefaultStyleName_TableTheme": "Table Theme",
    "XtraRichEditStringId.DefaultStyleName_TableWeb1": "Tabelle Web 1",
    "XtraRichEditStringId.DefaultStyleName_TableWeb2": "Tabelle Web 2",
    "XtraRichEditStringId.DefaultStyleName_TableWeb3": "Tabelle Web 3",
    "XtraRichEditStringId.DefaultStyleName_Title": "Titel",
    "XtraRichEditStringId.DefaultStyleName_toaheading": "TOA Heading",
    "XtraRichEditStringId.DefaultStyleName_toc1": "Inhaltsverzeichnis 1",
    "XtraRichEditStringId.DefaultStyleName_toc2": "Inhaltsverzeichnis 2",
    "XtraRichEditStringId.DefaultStyleName_toc3": "Inhaltsverzeichnis 3",
    "XtraRichEditStringId.DefaultStyleName_toc4": "Inhaltsverzeichnis 4",
    "XtraRichEditStringId.DefaultStyleName_toc5": "Inhaltsverzeichnis 5",
    "XtraRichEditStringId.DefaultStyleName_toc6": "Inhaltsverzeichnis 6",
    "XtraRichEditStringId.DefaultStyleName_toc7": "Inhaltsverzeichnis 7",
    "XtraRichEditStringId.DefaultStyleName_toc8": "Inhaltsverzeichnis 8",
    "XtraRichEditStringId.DefaultStyleName_toc9": "Inhaltsverzeichnis 9",
    "XtraRichEditStringId.DefaultStyleName_Quote": "Quote",
    "XtraRichEditStringId.DefaultStyleName_IntenseQuote": "Intense Quote",
    "XtraRichEditStringId.DefaultStyleName_NoSpacing": "No Spacing",
    "XtraRichEditStringId.DefaultStyleName_ListParagraph": "List Paragraph",
    "XtraRichEditStringId.DefaultStyleName_IntenseEmphasis": "Intense Emphasis",
    "XtraRichEditStringId.DefaultStyleName_SubtleEmphasis": "Subtle Emphasis",
    "XtraRichEditStringId.DefaultStyleName_BookTitle": "Book Title",
    "XtraRichEditStringId.DialogCaption_InsertSymbol": "Symbol",
    "XtraRichEditStringId.FieldLastName": "Nachname",
    "XtraRichEditStringId.FieldMapAddress1": "Adresse 1",
    "XtraRichEditStringId.FieldMapAddress2": "Adresse 2",
    "XtraRichEditStringId.FieldMapAddress3": "Adresse 3",
    "XtraRichEditStringId.FieldMapBusinessFax": "Fax geschäftlich",
    "XtraRichEditStringId.FieldMapBusinessPhone": "Telefon geschäftlich",
    "XtraRichEditStringId.FieldMapCity": "Stadt",
    "XtraRichEditStringId.FieldMapCompany": "Firma",
    "XtraRichEditStringId.FieldMapCountry": "Land oder Region",
    "XtraRichEditStringId.FieldMapDepartment": "Abteilung",
    "XtraRichEditStringId.FieldMapEMailAddress": "E-Mail-Adresse",
    "XtraRichEditStringId.FieldMapFirstName": "Vorname",
    "XtraRichEditStringId.FieldMapHomeFax": "Fax privat",
    "XtraRichEditStringId.FieldMapHomePhone": "Telefon privat",
    "XtraRichEditStringId.FieldMapJobTitle": "Beruf",
    "XtraRichEditStringId.FieldMapMiddleName": "Weiterer Vorn.",
    "XtraRichEditStringId.FieldMapNickName": "Spitzname",
    "XtraRichEditStringId.FieldMapPartnerFirstName": "Ehepartner/Partner Vorname",
    "XtraRichEditStringId.FieldMapPartnerLastName": "Ehepartner/Partner Nachname",
    "XtraRichEditStringId.FieldMapPartnerMiddleName": "Ehepartner/Partner weitere Vorn.",
    "XtraRichEditStringId.FieldMapPartnerNickName": "Ehepartner/Partner Spitzname",
    "XtraRichEditStringId.FieldMapPartnerTitle": "Ehepartner/Partner Titel",
    "XtraRichEditStringId.FieldMapPhoneticGuideFirstName": "",
    "XtraRichEditStringId.FieldMapPhoneticGuideLastName": "",
    "XtraRichEditStringId.FieldMapPostalCode": "Postleitzahl",
    "XtraRichEditStringId.FieldMapState": "Staat",
    "XtraRichEditStringId.FieldMapSuffix": "Nachsilbe",
    "XtraRichEditStringId.FieldMapTitle": "Anrede",
    "XtraRichEditStringId.FieldMapUniqueId": "Eindeutige ID",
    "XtraRichEditStringId.FieldMapWebPage": "Webseite",
    "XtraRichEditStringId.FileFilterDescription_AllFiles": "Alle Dateien",
    "XtraRichEditStringId.FileFilterDescription_AllSupportedFiles": "Alle unterstützten Dateien",
    "XtraRichEditStringId.FileFilterDescription_BitmapFiles": "Bitmap-Grafiken",
    "XtraRichEditStringId.FileFilterDescription_DocFiles": "Microsoft Word Dokument",
    "XtraRichEditStringId.FileFilterDescription_EmfFiles": "EMF-Grafiken",
    "XtraRichEditStringId.FileFilterDescription_ePubFiles": "Elektronisches Publizieren",
    "XtraRichEditStringId.FileFilterDescription_GifFiles": "GIF-Grafiken",
    "XtraRichEditStringId.FileFilterDescription_HtmlFiles": "HTML Format",
    "XtraRichEditStringId.FileFilterDescription_JPEGFiles": "JPEG -Grafiken",
    "XtraRichEditStringId.FileFilterDescription_MhtFiles": "Web Archive, einzelne Datei",
    "XtraRichEditStringId.FileFilterDescription_OpenDocumentFiles": "OpenDokument Text Dokument",
    "XtraRichEditStringId.FileFilterDescription_OpenXmlFiles": "Word 2007 Dokument",
    "XtraRichEditStringId.FileFilterDescription_PDFFiles": "Portable Document Format",
    "XtraRichEditStringId.FileFilterDescription_PNGFiles": "PNG-Grafiken",
    "XtraRichEditStringId.FileFilterDescription_RtfFiles": "Rich Text Format",
    "XtraRichEditStringId.FileFilterDescription_TextFiles": "Textdateien",
    "XtraRichEditStringId.FileFilterDescription_TiffFiles": "TIF-Grafiken",
    "XtraRichEditStringId.FileFilterDescription_WmfFiles": "WMF-Grafiken",
    "XtraRichEditStringId.FileFilterDescription_WordMLFiles": "XML-Dokument",
    "XtraRichEditStringId.FileFilterDescription_XamlFiles": "Xaml Dokument",
    "XtraRichEditStringId.FindAndReplaceForm_AnyOneCharacterInTheSet": "Ein beliebiges Zeichen in der Menge",
    "XtraRichEditStringId.FindAndReplaceForm_AnyOneCharacterNotInTheSet": "Ein beliebiges Zeichen nicht in der Menge",
    "XtraRichEditStringId.FindAndReplaceForm_AnySingleCharacter": "Ein einzelner Buchstabe",
    "XtraRichEditStringId.FindAndReplaceForm_BeginningOfLine": "Beginn einer Zeile",
    "XtraRichEditStringId.FindAndReplaceForm_BeginningOfWord": "Beginn eines Wortes",
    "XtraRichEditStringId.FindAndReplaceForm_EndOfLine": "Ende eine Zeile",
    "XtraRichEditStringId.FindAndReplaceForm_EndOfWord": "Ende eines Wortes",
    "XtraRichEditStringId.FindAndReplaceForm_EscapeSpecialCharacter": "Austritt Sonderzeichen",
    "XtraRichEditStringId.FindAndReplaceForm_Integer": "Ganze Zahl",
    "XtraRichEditStringId.FindAndReplaceForm_OneOrMore": "Ein oder mehrere",
    "XtraRichEditStringId.FindAndReplaceForm_Or": "Oder",
    "XtraRichEditStringId.FindAndReplaceForm_SpaceOrTab": "Leerzeichen oder Tabulator",
    "XtraRichEditStringId.FindAndReplaceForm_TagExpression": "Tag Ausdruck",
    "XtraRichEditStringId.FindAndReplaceForm_TaggedExpression": "Tagged Ausdruck {0}",
    "XtraRichEditStringId.FindAndReplaceForm_WordCharacter": "Word Charakter",
    "XtraRichEditStringId.FindAndReplaceForm_ZeroOrMore": "Null oder mehr",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionAlignmentCenter": "Zentriert",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionAlignmentLeft": "Links",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionAlignmentRight": "Rechts",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeCharacter": "Zeichen",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeColumn": "Spalte",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeInsideMargin": "Innerer Rand",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeLeftMargin": "Linker Rand",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeMargin": "Seitenrand",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeOutsideMargin": "Äußerer Rand",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypePage": "Seite",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeRightMargin": "Rechter Seitenrand",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentBottom": "Unten",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentCenter": "Zentriert",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentInside": "Innen",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentOutside": "Außen",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentTop": "Oben",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeBottomMargin": "Seitenrand unten",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeInsideMargin": "Innerer Rand",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeLine": "Linie",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeMargin": "Seitenrand",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeOutsideMargin": "Äußerer Rand",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypePage": "Seite",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeParagraph": "Absatz",
    "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeTopMargin": "Oberer Rand",
    "XtraRichEditStringId.FontStyle_Bold": "Fett",
    "XtraRichEditStringId.FontStyle_BoldItalic": "Fett Kursiv",
    "XtraRichEditStringId.FontStyle_Italic": "Kursiv",
    "XtraRichEditStringId.FontStyle_Regular": "Normal",
    "XtraRichEditStringId.FontStyle_Strikeout": "Durchgestrichen",
    "XtraRichEditStringId.FontStyle_Underline": "Unterstrichen",
    "XtraRichEditStringId.HyperlinkForm_EditHyperlinkTitle": "Hyperlink bearbeiten",
    "XtraRichEditStringId.HyperlinkForm_InsertHyperlinkTitle": "Hyperlink einfügen",
    "XtraRichEditStringId.HyperlinkForm_SelectedBookmarkNone": "<Keine>",
    "XtraRichEditStringId.HyperlinkForm_SelectionInDocument": "Auswahl im Dokument",
    "XtraRichEditStringId.InsertTableForm_InvalidColumnCount": "Die Zahl muss zwischen {0} und {1} liegen.",
    "XtraRichEditStringId.InsertTableForm_InvalidRowCount": "Die Zahl muss zwischen {0} und {1} liegen.",
    "XtraRichEditStringId.KeyName_Alt": "Alt",
    "XtraRichEditStringId.KeyName_Control": "Ctrl",
    "XtraRichEditStringId.KeyName_Shift": "Shift",
    "XtraRichEditStringId.LinkedCharacterStyleFormatString": "{0} Zeichen",
    "XtraRichEditStringId.LocalizedStyleName_Hyperlink": "Hyperlink",
    "XtraRichEditStringId.LocalizedStyleName_Normal": "Normal",
    "XtraRichEditStringId.MenuCmd_Accept": "Annehmen",
    "XtraRichEditStringId.MenuCmd_AcceptAllChanges": "Alle Änderungen annehmen",
    "XtraRichEditStringId.MenuCmd_AcceptAllChangesAndStopTracking": "Alle Änderungen annehmen und Nachverfolgung beenden",
    "XtraRichEditStringId.MenuCmd_AcceptAllChangesAndStopTrackingDescription": "Accept All Changes and Stop Tracking",
    "XtraRichEditStringId.MenuCmd_AcceptAllChangesDescription": "Accept All Changes",
    "XtraRichEditStringId.MenuCmd_AcceptAllChangesShown": "Alle angezeigten Änderungen annehmen",
    "XtraRichEditStringId.MenuCmd_AcceptAllChangesShownDescription": "Accept All Changes Shown",
    "XtraRichEditStringId.MenuCmd_AcceptAndMoveToNextRevision": "Annehmen und weiter",
    "XtraRichEditStringId.MenuCmd_AcceptAndMoveToNextRevisionDescription": "Diese Änderung beibehalten und zur nächsten Änderung wechseln.",
    "XtraRichEditStringId.MenuCmd_AcceptCurrentRevision": "Diese Änderung annehmen",
    "XtraRichEditStringId.MenuCmd_AcceptCurrentRevisionDescription": "Accept This Change",
    "XtraRichEditStringId.MenuCmd_AcceptDescription": "Klicken Sie, um weitere Optionen anzuzeigen, z.B. das gleichzeitige Annehmen aller Änderungen.",
    "XtraRichEditStringId.MenuCmd_AddParagraphsToTableOfContents": " Text hinzufügen",
    "XtraRichEditStringId.MenuCmd_AddParagraphsToTableOfContentsDescription": "Fügen Sie die aktuellen Absatz als Eintrag in das Inhaltsverzeichnis ein",
    "XtraRichEditStringId.MenuCmd_AddSpacingAfterParagraph": "Abstand nach dem Absatz",
    "XtraRichEditStringId.MenuCmd_AddSpacingAfterParagraphDescription": " ",
    "XtraRichEditStringId.MenuCmd_AddSpacingBeforeParagraph": "Abstand vor dem Absatz",
    "XtraRichEditStringId.MenuCmd_AddSpacingBeforeParagraphDescription": " ",
    "XtraRichEditStringId.MenuCmd_AddWordToDictionary": "Zum Wörterbuch hinzufügen",
    "XtraRichEditStringId.MenuCmd_AddWordToDictionaryDescription": "Zum Wörterbuch hinzufügen",
    "XtraRichEditStringId.MenuCmd_AllAuthors": "Alle Autoren",
    "XtraRichEditStringId.MenuCmd_BackSpaceKey": "Rückschritt-Taste",
    "XtraRichEditStringId.MenuCmd_BackSpaceKeyDescription": "Rückschritt-Taste",
    "XtraRichEditStringId.MenuCmd_Bookmark": "Lesezeichen...",
    "XtraRichEditStringId.MenuCmd_BookmarkDescription": "Lesezeichen...",
    "XtraRichEditStringId.MenuCmd_BrowserPrint": "Browser drucken",
    "XtraRichEditStringId.MenuCmd_BrowserPrintDescription": "Dokument drucken mit Browser Druckfunktionen.",
    "XtraRichEditStringId.MenuCmd_BrowserPrintPreview": "Browser Druckansicht",
    "XtraRichEditStringId.MenuCmd_BrowserPrintPreviewDescription": "Vorschau im Browser Seiten vor dem Druck.",
    "XtraRichEditStringId.MenuCmd_CapitalizeEachWordTextCase": "Jedes Wort Großschreiben",
    "XtraRichEditStringId.MenuCmd_CapitalizeEachWordTextCaseDescription": "Jedes Wort großschreiben.",
    "XtraRichEditStringId.MenuCmd_ChangeCharacterStyle": "Schriftstil ändern",
    "XtraRichEditStringId.MenuCmd_ChangeCharacterStyleDescription": "Schriftstil ändern",
    "XtraRichEditStringId.MenuCmd_ChangeColumnCount": "Spalten",
    "XtraRichEditStringId.MenuCmd_ChangeColumnCountDescription": "Spalten",
    "XtraRichEditStringId.MenuCmd_ChangeColumnSize": "Ändern Spaltengröße",
    "XtraRichEditStringId.MenuCmd_ChangeColumnSizeDescription": "SpalteÄndernGrößeBeschreibung",
    "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemColor": "Farbstift",
    "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemColorDescription": "Ändern Sie die Stiftfarbe.",
    "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemLineStyle": "Linienart",
    "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemLineStyleDescription": "Ändern Sie den Stil der Linie, um Grenzen zu ziehen.",
    "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemLineThickness": "Linie Gewicht",
    "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemLineThicknessDescription": "Ändern Sie die Breite der Linie, um Grenzen zu ziehen.",
    "XtraRichEditStringId.MenuCmd_ChangeDisplayForReviewMode": "Display for Review",
    "XtraRichEditStringId.MenuCmd_ChangeDisplayForReviewModeDescription": "Wählen Sie aus, wie Sie Änderungen in diesem Dokument sehen möchten.",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectAlignment": "Objektposition",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectAlignmentDescription": "Das ausgewählte Objekt auf der Seite positionieren.\n    \nFür Text wird automatisch festgelegt, dass er um das Objekt fließt.",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectFillColor": "Fülleffekt",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectFillColorDescription": "Füllen Sie die ausgewählte Form mit einer Volltonfarbe.",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectOutlineColor": "Formkontur",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectOutlineColorDescription": "Die Farbe für die Kontur der ausgewählten Form angeben.",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectOutlineWidth": "Formkontur Gewicht",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectOutlineWidthDescription": "Die Breite für die Kontur der ausgewählten Form angeben.",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectTextWrapType": "Zeilenumbruch",
    "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectTextWrapTypeDescription": "Den Textumbruch um das ausgewählte Objekt ändern.\n    \nWählen Sie \"Mit Text in Zeile\" aus, um das Objekt so zu konfigurieren, dass es zusammen mit dem umgebenden Text verschoben wird.",
    "XtraRichEditStringId.MenuCmd_ChangeFontColor": "Schriftfarbe",
    "XtraRichEditStringId.MenuCmd_ChangeFontColorDescription": "Schriftfarbe ändern.",
    "XtraRichEditStringId.MenuCmd_ChangeFontName": "Schriftart",
    "XtraRichEditStringId.MenuCmd_ChangeFontNameDescription": "Schriftart ändern.",
    "XtraRichEditStringId.MenuCmd_ChangeFontSize": "Schriftgröße",
    "XtraRichEditStringId.MenuCmd_ChangeFontSizeDescription": "Schriftgröße ändern.",
    "XtraRichEditStringId.MenuCmd_ChangeHyphenationOptions": "Silbentrennung",
    "XtraRichEditStringId.MenuCmd_ChangeHyphenationOptionsDescription": "Wenn ein Wort nicht mehr ganz in eine Zeile passt, wird es normalerweise in die nächste Zeile verschoben. Bei aktivierter Silbentrennung wird das Wort stattdessen getrennt.",
    "XtraRichEditStringId.MenuCmd_ChangeIndent": "Einzug ändern",
    "XtraRichEditStringId.MenuCmd_ChangeIndentDescription": "Einzug ändern",
    "XtraRichEditStringId.MenuCmd_ChangeLanguage": "Sprache",
    "XtraRichEditStringId.MenuCmd_ChangeLanguageDescription": "Festlegen der Sprache zum Prüfen von Rechtschreibung und Grammatik des markierten Textes.",
    "XtraRichEditStringId.MenuCmd_ChangeMistakenWord": "(keine Vorschläge)",
    "XtraRichEditStringId.MenuCmd_ChangeMistakenWordDescription": "(keine Vorschläge)",
    "XtraRichEditStringId.MenuCmd_ChangeNoProof": "NoProof",
    "XtraRichEditStringId.MenuCmd_ChangeNoProofDescription": "Änderung NoProof",
    "XtraRichEditStringId.MenuCmd_ChangePageColor": "Seitenfarbe",
    "XtraRichEditStringId.MenuCmd_ChangePageColorDescription": "Eine Farbe als Seitenhintergrund auswählen.",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphBackColor": "Schattierung",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphBackColorDescription": "Den Hintergrund des markierten Texts oder Abschnitts einfärben.",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphFirstLineIndent": "ErhöheAbsatzErstzeileneinzug",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphFirstLineIndentDescription": "IncrementParagraphErstzeileneinzugBeschreibung",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphLeftIndent": "ÄndereParagraphLinkerEinzug",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphLeftIndentDescription": "ÄndereParagraphLinkerEinzugBeschreibung",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphLineSpacing": "Zeilenabstand",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphLineSpacingDescription": "Ändern Sie den Abstand zwischen den Textzeilen.\n\nSie können auch die Höhe vor und nach Absätzen ändern.",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphStyle": "Absatzstil ändern",
    "XtraRichEditStringId.MenuCmd_ChangeParagraphStyleDescription": "Absatzstil ändern.",
    "XtraRichEditStringId.MenuCmd_ChangeSectionLineNumbering": "Zeilennummer",
    "XtraRichEditStringId.MenuCmd_ChangeSectionLineNumberingDescription": "Fügen Sie Zeilennummern im Rand neben jeder Zeile des Dokuments ein.",
    "XtraRichEditStringId.MenuCmd_ChangeSectionPageMargins": "Ränder",
    "XtraRichEditStringId.MenuCmd_ChangeSectionPageMarginsDescription": "Ändern Sie den Rand für das gesamte Dokument oder den Absatz.",
    "XtraRichEditStringId.MenuCmd_ChangeSectionPageOrientation": "Seitenausrichtung",
    "XtraRichEditStringId.MenuCmd_ChangeSectionPageOrientationDescription": "Wechselt zwischen Hoch- und Querformat.",
    "XtraRichEditStringId.MenuCmd_ChangeSectionPagePaperKind": "Grösse",
    "XtraRichEditStringId.MenuCmd_ChangeSectionPagePaperKindDescription": "Wählen Sie ein Papierformat für den aktuellen Abschnitt.",
    "XtraRichEditStringId.MenuCmd_ChangeStyle": "Stile",
    "XtraRichEditStringId.MenuCmd_ChangeStyleDescription": "Formatiert Titel, Anführungszeichen und andere Textarten, die diese Stilgalerie verwenden.",
    "XtraRichEditStringId.MenuCmd_ChangeTableBorders": "Ränder",
    "XtraRichEditStringId.MenuCmd_ChangeTableBordersDescription": "Passen Sie die Ränder den ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ChangeTableCellAlignmentPlaceholder": "Ausrichtung",
    "XtraRichEditStringId.MenuCmd_ChangeTableCellAlignmentPlaceholderDescription": "Passen Sie die Ausrichtung der ausgewählten Zellen.",
    "XtraRichEditStringId.MenuCmd_ChangeTableCellsContentAlignment": "Zellausrichtung",
    "XtraRichEditStringId.MenuCmd_ChangeTableCellsContentAlignmentDescription": "Zellausrichtung",
    "XtraRichEditStringId.MenuCmd_ChangeTableCellShading": "Schattierung",
    "XtraRichEditStringId.MenuCmd_ChangeTableCellShadingDescription": "Farbe des Hintergrunds hinter den ausgewählten Zellen.",
    "XtraRichEditStringId.MenuCmd_ChangeTextCase": "Wechsle Argument",
    "XtraRichEditStringId.MenuCmd_ChangeTextCaseDescription": "Ändern Sie alle markierten Text in Großbuchstaben, Kleinbuchstaben oder andere gemeinsame Aktivierungen.",
    "XtraRichEditStringId.MenuCmd_CheckSpelling": "Rechtschreibung prüfen",
    "XtraRichEditStringId.MenuCmd_CheckSpellingDescription": "Rechtschreibung prüfen",
    "XtraRichEditStringId.MenuCmd_ClearFormatting": "Formatierung löschen",
    "XtraRichEditStringId.MenuCmd_ClearFormattingDescription": "Löscht  alle  Formatierungen des markierten Bereiches. Es bleibt nur der reine Text übrig.",
    "XtraRichEditStringId.MenuCmd_ClearUndo": "Lösche Rückgängig",
    "XtraRichEditStringId.MenuCmd_ClearUndoDescription": "Lösche Rückgängig Buffer",
    "XtraRichEditStringId.MenuCmd_ClosePageHeaderFooter": "Schließt Kopf- und Fußzeilen",
    "XtraRichEditStringId.MenuCmd_ClosePageHeaderFooterDescription": "Schließt die Kopf- und Fußzeilentools.\n\n\nDurch Doppelklick in den Kopf- oder Fußbereich können Sie in den Bearbeitunsmodus zurück kehren.",
    "XtraRichEditStringId.MenuCmd_CollapseOrExpandFormulaBar": "Reduzieren oder erweitern Sie die Bearbeitungsleiste",
    "XtraRichEditStringId.MenuCmd_CollapseOrExpandFormulaBarDescription": "Reduzieren oder erweitern Sie die Bearbeitungsleiste (STRG + UMSCHALT + U)",
    "XtraRichEditStringId.MenuCmd_Comment": "Kommentare anzeigen",
    "XtraRichEditStringId.MenuCmd_CommentDescription": "Choose whether or not to highlight comments in the document.",
    "XtraRichEditStringId.MenuCmd_CopySelection": "Kopieren",
    "XtraRichEditStringId.MenuCmd_CopySelectionDescription": "Kopiert den ausgewählten Text in die Zwischenablage.",
    "XtraRichEditStringId.MenuCmd_CreateBookmark": "Lesezeichen erstellen",
    "XtraRichEditStringId.MenuCmd_CreateBookmarkDescription": "Lesezeichen erstellen",
    "XtraRichEditStringId.MenuCmd_CreateField": "Feld erstellen",
    "XtraRichEditStringId.MenuCmd_CreateFieldDescription": "Feld erstellen",
    "XtraRichEditStringId.MenuCmd_CreateHyperlink": "Hyperlink erstellen",
    "XtraRichEditStringId.MenuCmd_CreateHyperlinkDescription": "Hyperlink erstellen",
    "XtraRichEditStringId.MenuCmd_CutSelection": "Ausschneiden",
    "XtraRichEditStringId.MenuCmd_CutSelectionDescription": "Schneidet den markierten Text aus und kopiert ihn in die Zwischenablage",
    "XtraRichEditStringId.MenuCmd_DecreaseFontSize": "Schriftgröße verkleinern",
    "XtraRichEditStringId.MenuCmd_DecreaseFontSizeDescription": "Vermindert die Schriftgröße.",
    "XtraRichEditStringId.MenuCmd_DecrementFontSize": "Schriftgröße verringern",
    "XtraRichEditStringId.MenuCmd_DecrementFontSizeDescription": "Schriftgröße verringern",
    "XtraRichEditStringId.MenuCmd_DecrementIndent": "Einzug verringern",
    "XtraRichEditStringId.MenuCmd_DecrementIndentDescription": "Verringert den linken Einzug eines Absatzes.",
    "XtraRichEditStringId.MenuCmd_DecrementNumerationFromParagraph": "Nummerierung in Absatz vermindern",
    "XtraRichEditStringId.MenuCmd_DecrementNumerationFromParagraphDescription": "Nummerierung in Absatz vermindern",
    "XtraRichEditStringId.MenuCmd_DecrementParagraphLeftIndent": "Linken Einzug verringern",
    "XtraRichEditStringId.MenuCmd_DecrementParagraphLeftIndentDescription": "Linken Einzug verringern.",
    "XtraRichEditStringId.MenuCmd_DecrementParagraphOutlineLevel": "Abnehmende Gliederungsebene",
    "XtraRichEditStringId.MenuCmd_DecrementParagraphOutlineLevelDescription": "Dekremente Gliederungsebene",
    "XtraRichEditStringId.MenuCmd_Delete": "Löschen",
    "XtraRichEditStringId.MenuCmd_DeleteAllComments": "Alle Kommentare löschen",
    "XtraRichEditStringId.MenuCmd_DeleteAllCommentsDescription": "Alle Kommentare löschen.",
    "XtraRichEditStringId.MenuCmd_DeleteAllCommentsShown": "Lösche alle angezeigten Kommentare.",
    "XtraRichEditStringId.MenuCmd_DeleteAllCommentsShownDescription": "Lösche alle angezeigten Kommentare.",
    "XtraRichEditStringId.MenuCmd_DeleteBack": "Rückwärts löschen",
    "XtraRichEditStringId.MenuCmd_DeleteBackCore": "DeleteBackCore",
    "XtraRichEditStringId.MenuCmd_DeleteBackCoreDescription": "DeleteBackCoreDescription",
    "XtraRichEditStringId.MenuCmd_DeleteBackDescription": "Rückwärts löschen",
    "XtraRichEditStringId.MenuCmd_DeleteBookmark": "Lesezeichen löschen",
    "XtraRichEditStringId.MenuCmd_DeleteBookmarkDescription": "Lesezeichen löschen",
    "XtraRichEditStringId.MenuCmd_DeleteComment": "Kommentar löschen",
    "XtraRichEditStringId.MenuCmd_DeleteCommentDescription": "Kommentar löschen",
    "XtraRichEditStringId.MenuCmd_DeleteCore": "Löschen",
    "XtraRichEditStringId.MenuCmd_DeleteCoreDescription": "Löschen",
    "XtraRichEditStringId.MenuCmd_DeleteDescription": "Löschen",
    "XtraRichEditStringId.MenuCmd_DeleteNumerationFromParagraph": "Nummerierung aus Absatz löschen",
    "XtraRichEditStringId.MenuCmd_DeleteNumerationFromParagraphDescription": "Nummerierung aus Absatz löschen",
    "XtraRichEditStringId.MenuCmd_DeleteOneComment": "Kommentar löschen",
    "XtraRichEditStringId.MenuCmd_DeleteOneCommentDescription": "Delete the selected comment.",
    "XtraRichEditStringId.MenuCmd_DeleteRepeatedWord": "Lösche wiederholtes Wort",
    "XtraRichEditStringId.MenuCmd_DeleteRepeatedWordDescription": "Lösche wiederholtes Wort",
    "XtraRichEditStringId.MenuCmd_DeleteTable": "Lösche Tabelle",
    "XtraRichEditStringId.MenuCmd_DeleteTableCells": "Lösche Zelle",
    "XtraRichEditStringId.MenuCmd_DeleteTableCellsDescription": "Lösche Zelle",
    "XtraRichEditStringId.MenuCmd_DeleteTableCellsMenuItem": "Lösche Zelle...",
    "XtraRichEditStringId.MenuCmd_DeleteTableColumns": "Lösche Spalte",
    "XtraRichEditStringId.MenuCmd_DeleteTableColumnsDescription": "Lösche Spalte",
    "XtraRichEditStringId.MenuCmd_DeleteTableDescription": "Lösche ganze Tabelle",
    "XtraRichEditStringId.MenuCmd_DeleteTableElements": "Löschen",
    "XtraRichEditStringId.MenuCmd_DeleteTableElementsDescription": "Löschen von Zeilen, Spalten, Zellen oder die gesamte Tabelle",
    "XtraRichEditStringId.MenuCmd_DeleteTableRows": "Lösche Zeile",
    "XtraRichEditStringId.MenuCmd_DeleteTableRowsDescription": "Lösche Zeile",
    "XtraRichEditStringId.MenuCmd_DeleteTableStyle": "Löschen der Tabelle Style...",
    "XtraRichEditStringId.MenuCmd_DeleteWord": "Wort löschen",
    "XtraRichEditStringId.MenuCmd_DeleteWordBack": "Wort rückwärts löschen",
    "XtraRichEditStringId.MenuCmd_DeleteWordBackCore": "",
    "XtraRichEditStringId.MenuCmd_DeleteWordBackCoreDescription": "",
    "XtraRichEditStringId.MenuCmd_DeleteWordBackDescription": "Wort rückwärts löschen",
    "XtraRichEditStringId.MenuCmd_DeleteWordCore": "",
    "XtraRichEditStringId.MenuCmd_DeleteWordCoreDescription": "",
    "XtraRichEditStringId.MenuCmd_DeleteWordDescription": "Wort löschen",
    "XtraRichEditStringId.MenuCmd_DeselectAll": "Alle abwählen",
    "XtraRichEditStringId.MenuCmd_DeselectAllDescription": "Reset Dokument Auswahl.",
    "XtraRichEditStringId.MenuCmd_DraftView": "Entwurfsansicht",
    "XtraRichEditStringId.MenuCmd_DraftViewDescription": "Entwurfsansicht",
    "XtraRichEditStringId.MenuCmd_EditHyperlink": "Hyperlink bearbeiten...",
    "XtraRichEditStringId.MenuCmd_EditHyperlinkDescription": "Hyperlink bearbeiten...",
    "XtraRichEditStringId.MenuCmd_EditPageFooter": "Fußzeile",
    "XtraRichEditStringId.MenuCmd_EditPageFooterDescription": "Die Fußzeile des Dokuments bearbeiten\n\nDer Inhalt der FUßzeile wird unten auf jeder gedruckten Seite angezeigt.",
    "XtraRichEditStringId.MenuCmd_EditPageHeader": "Kopf",
    "XtraRichEditStringId.MenuCmd_EditPageHeaderDescription": "Die Kopfzeile des Dokuments bearbeiten\n\nDer Inhalt der Kopfzeile wird oben auf jeder gedruckten Seite angezeigt.",
    "XtraRichEditStringId.MenuCmd_EditTOC": "Bearbeite Inhaltsverzeichnis...",
    "XtraRichEditStringId.MenuCmd_EncryptDocument": "Mit Kennwort verschlüsseln",
    "XtraRichEditStringId.MenuCmd_EncryptDocumentDescription": "Dieses Dokument mit einem Kennwort schützen.",
    "XtraRichEditStringId.MenuCmd_EnsureCaretVisibleHorizontally": "Einfügemarke horizontal sichtbar halten",
    "XtraRichEditStringId.MenuCmd_EnsureCaretVisibleHorizontallyDescription": "Einfügemarke horizontal sichtbar halten",
    "XtraRichEditStringId.MenuCmd_EnsureCaretVisibleVertically": "Einfügemarke vertikal sichtbar halten",
    "XtraRichEditStringId.MenuCmd_EnsureCaretVisibleVerticallyDescription": "Einfügemarke vertikal sichtbar halten",
    "XtraRichEditStringId.MenuCmd_EnterKey": "EnterTaste",
    "XtraRichEditStringId.MenuCmd_EnterKeyDescription": "EnterTasteBeschreibung",
    "XtraRichEditStringId.MenuCmd_Find": "Suchen",
    "XtraRichEditStringId.MenuCmd_FindAndSelectBackward": "FindeUndSelektiereRückwärts",
    "XtraRichEditStringId.MenuCmd_FindAndSelectBackwardDescription": "FindeUndSelektiereRückwärts",
    "XtraRichEditStringId.MenuCmd_FindAndSelectForward": "FindeUndSelektiereVorwärts",
    "XtraRichEditStringId.MenuCmd_FindAndSelectForwardDescription": "FindeUndSelektiereVorwärts",
    "XtraRichEditStringId.MenuCmd_FindDescription": "Text im Dokument suchen.",
    "XtraRichEditStringId.MenuCmd_FindNext": "Finde nächsten",
    "XtraRichEditStringId.MenuCmd_FindNextDescription": "Wiederholt die letzte Suche vorwärts.",
    "XtraRichEditStringId.MenuCmd_FindPrev": "Finde vorherigen",
    "XtraRichEditStringId.MenuCmd_FindPrevDescription": "Wiederholt die letzte Suche rückwärts.",
    "XtraRichEditStringId.MenuCmd_FirstDataRecord": "Erster Datensatz",
    "XtraRichEditStringId.MenuCmd_FirstDataRecordDescription": "Erster Datensatz",
    "XtraRichEditStringId.MenuCmd_FitHeight": "Höhe anpassen",
    "XtraRichEditStringId.MenuCmd_FitHeightDescription": "Adjusts the document zoom factor to fit the entire page height to the editing surface dimensions.",
    "XtraRichEditStringId.MenuCmd_FitToPage": "An Seite anpassen",
    "XtraRichEditStringId.MenuCmd_FitToPageDescription": "Passt den Dokument-Zoom-Faktor auf die gesamte Seite auf die Bearbeitung Oberflächen Abmessungen passen.",
    "XtraRichEditStringId.MenuCmd_FitWidth": "Breite anpassen",
    "XtraRichEditStringId.MenuCmd_FitWidthDescription": "Passt den Dokument-Zoom-Faktor um die gesamte Seitenbreite auf die Bearbeitung Oberflächen Abmessungen passen.",
    "XtraRichEditStringId.MenuCmd_FloatingObjectBringForward": "Eine Ebene nach vorne",
    "XtraRichEditStringId.MenuCmd_FloatingObjectBringForwardDescription": "Das ausgewählte Objekt weiter in den Vordergrund verschieben, sodass es von einer geringeren Anzahl von Objekten verdeckt wird, die sich vor ihm befinden.",
    "XtraRichEditStringId.MenuCmd_FloatingObjectBringForwardPlaceholder": "Eine Ebene nach vorne",
    "XtraRichEditStringId.MenuCmd_FloatingObjectBringForwardPlaceholderDescription": "Das ausgewählte Objekt weiter in den Vordergrund verschieben, sodass es von einer geringeren Anzahl von Objekten verdeckt wird, die sich vor ihm befinden.",
    "XtraRichEditStringId.MenuCmd_FloatingObjectBringInFrontOfText": "Vor dem Text platzieren",
    "XtraRichEditStringId.MenuCmd_FloatingObjectBringInFrontOfTextDescription": " ",
    "XtraRichEditStringId.MenuCmd_FloatingObjectBringToFront": "In den Vordergrund",
    "XtraRichEditStringId.MenuCmd_FloatingObjectBringToFrontDescription": "Das ausgewählte Objekt vor alle anderen Objekte stellen, sodass kein Teil des Objekts von einem anderen Objekt verdeckt wird.",
    "XtraRichEditStringId.MenuCmd_FloatingObjectSendBackward": "Eine Ebene nach hinten",
    "XtraRichEditStringId.MenuCmd_FloatingObjectSendBackwardDescription": "Das ausgewählte Objekt weiter in den Hintergrund verschieben, sodass es von den Objekten verdeckt wird, die sich vor ihm befinden.",
    "XtraRichEditStringId.MenuCmd_FloatingObjectSendBackwardPlaceholder": "Eine Ebene nach hinten",
    "XtraRichEditStringId.MenuCmd_FloatingObjectSendBackwardPlaceholderDescription": "Das ausgewählte Objekt weiter in den Hintergrund verschieben, sodass es von den Objekten verdeckt wird, die sich vor ihm befinden.",
    "XtraRichEditStringId.MenuCmd_FloatingObjectSendBehindText": "Hinter den Text bringen",
    "XtraRichEditStringId.MenuCmd_FloatingObjectSendBehindTextDescription": " ",
    "XtraRichEditStringId.MenuCmd_FloatingObjectSendToBack": "In den Hintergrund",
    "XtraRichEditStringId.MenuCmd_FloatingObjectSendToBackDescription": "Das ausgewählte Objekt hinter alle anderen Objekte stellen.",
    "XtraRichEditStringId.MenuCmd_FontSubscript": "Tiefstellen",
    "XtraRichEditStringId.MenuCmd_FontSubscriptDescription": "Erzeugt kleine Buchstaben unterhalb der Grundlinie.",
    "XtraRichEditStringId.MenuCmd_FontSuperscript": "Hochstellen",
    "XtraRichEditStringId.MenuCmd_FontSuperscriptDescription": "Erzeugt kleine Buchstaben oberhalb der Grundlinie.",
    "XtraRichEditStringId.MenuCmd_GoToNextHeaderFooter": "Nächsten anzeigen.",
    "XtraRichEditStringId.MenuCmd_GoToNextHeaderFooterDescription": "Navigieren Sie zum nächsten Abschnitt Kopf-oder Fußzeile",
    "XtraRichEditStringId.MenuCmd_GoToPage": "Gehe auf Seite",
    "XtraRichEditStringId.MenuCmd_GoToPageDescription": "Gehe auf Seite",
    "XtraRichEditStringId.MenuCmd_GoToPageFooter": "Gehe zur Fußzeile",
    "XtraRichEditStringId.MenuCmd_GoToPageFooterDescription": "Aktivieren Sie die Fußzeile auf dieser Seite, so dass Sie sie bearbeiten können.",
    "XtraRichEditStringId.MenuCmd_GoToPageHeader": "Gehe zur Kopfzeile",
    "XtraRichEditStringId.MenuCmd_GoToPageHeaderDescription": "Aktivieren Sie die Kopfzeile auf dieser Seite, so dass Sie sie bearbeiten können.",
    "XtraRichEditStringId.MenuCmd_GoToPreviousHeaderFooter": "Vorherigen anzeigen",
    "XtraRichEditStringId.MenuCmd_GoToPreviousHeaderFooterDescription": "Navigieren Sie zum vorherigen Abschnitt Kopf-oder Fußzeile",
    "XtraRichEditStringId.MenuCmd_HighlightText": "Farbe der Textmarkierung",
    "XtraRichEditStringId.MenuCmd_HighlightTextDescription": "Lässt den Text so aussehen, als wäre er mit einem Marker angestrichen.",
    "XtraRichEditStringId.MenuCmd_Hyperlink": "Hyperlink...",
    "XtraRichEditStringId.MenuCmd_HyperlinkDescription": "Erstellen Sie einen Hyperlink zu einer Webseite, einem Bild, einer E-Mail-Adresse oder einem Programm.",
    "XtraRichEditStringId.MenuCmd_IgnoreAllMistakenWords": "Alle ignorieren",
    "XtraRichEditStringId.MenuCmd_IgnoreAllMistakenWordsDescription": "Alle ignorieren",
    "XtraRichEditStringId.MenuCmd_IgnoreMistakenWord": "Ignorieren",
    "XtraRichEditStringId.MenuCmd_IgnoreMistakenWordDescription": "Ignorieren",
    "XtraRichEditStringId.MenuCmd_IncreaseFontSize": "Schrift vergrößern",
    "XtraRichEditStringId.MenuCmd_IncreaseFontSizeDescription": "Erhöht die Schriftgröße.",
    "XtraRichEditStringId.MenuCmd_IncrementFontSize": "Schriftgröße erhöhen",
    "XtraRichEditStringId.MenuCmd_IncrementFontSizeDescription": "Schriftgröße erhöhen",
    "XtraRichEditStringId.MenuCmd_IncrementIndent": "Einzug erhöhen",
    "XtraRichEditStringId.MenuCmd_IncrementIndentDescription": "Den linken Einzug für den ganzen Absatz erhöhen.",
    "XtraRichEditStringId.MenuCmd_IncrementNumerationFromParagraph": "Nummerierung in Absatz erhöhen",
    "XtraRichEditStringId.MenuCmd_IncrementNumerationFromParagraphDescription": "Nummerierung in Absatz erhöhen",
    "XtraRichEditStringId.MenuCmd_IncrementParagraphLeftIndent": "Linken Absatzeinzug erhöhen",
    "XtraRichEditStringId.MenuCmd_IncrementParagraphLeftIndentDescription": "Linken Absatzeinzug erhöhen",
    "XtraRichEditStringId.MenuCmd_IncrementParagraphOutlineLevel": "Zunehmende Gliederungsebene",
    "XtraRichEditStringId.MenuCmd_IncrementParagraphOutlineLevelDescription": "Zunehmende Gliederungsebene",
    "XtraRichEditStringId.MenuCmd_InsertBreak": "Umbrüche",
    "XtraRichEditStringId.MenuCmd_InsertBreakDescription": "Fügt Seiten, einen Abschnitt oder Spaltenumbrüche, in das Dokument ein.",
    "XtraRichEditStringId.MenuCmd_InsertBulletList": "Listenpunkte",
    "XtraRichEditStringId.MenuCmd_InsertBulletListDescription": "Eine Aufzählung beginnen.",
    "XtraRichEditStringId.MenuCmd_InsertCaptionPlaceholder": "Beschriftungen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertCaptionPlaceholderDescription": "Hinzufügen einer Beschriftung, in ein Foto, oder ein anderes Bild.\n\n Eine Beschriftung ist eine Textzeile, die unter einem Objekt erscheint, um es zu beschreiben.",
    "XtraRichEditStringId.MenuCmd_InsertClosingDoubleQuotationMark": "Oberes doppeltes Anführungszeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertClosingDoubleQuotationMarkDescription": "Oberes doppeltes Anführungszeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertClosingSingleQuotationMark": "Oberes einfaches Anführungszeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertClosingSingleQuotationMarkDescription": "Oberes einfaches Anführungszeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertColumnBreak": "Spaltenumbruch",
    "XtraRichEditStringId.MenuCmd_InsertColumnBreakDescription": "Spaltenumbruch einfügen",
    "XtraRichEditStringId.MenuCmd_InsertCopyrightSymbol": "Copyrightsymbol einfügen",
    "XtraRichEditStringId.MenuCmd_InsertCopyrightSymbolDescription": "Copyrightsymbol einfügen",
    "XtraRichEditStringId.MenuCmd_InsertEllipsis": "Auslassungspunkte einfügen",
    "XtraRichEditStringId.MenuCmd_InsertEllipsisDescription": "Auslassungspunkte einfügen",
    "XtraRichEditStringId.MenuCmd_InsertEmDash": "Gevierteltstrich einfügen",
    "XtraRichEditStringId.MenuCmd_InsertEmDashDescription": "Gevierteltstrich einfügen",
    "XtraRichEditStringId.MenuCmd_InsertEnDash": "Halbgevierteltstrich einfügen",
    "XtraRichEditStringId.MenuCmd_InsertEnDashDescription": "Halbgevierteltstrich einfügen",
    "XtraRichEditStringId.MenuCmd_InsertEquationsCaption": "Beschreibungsgleichung",
    "XtraRichEditStringId.MenuCmd_InsertEquationsCaptionDescription": "Füge eine Beschreibungsgleichung hinzu",
    "XtraRichEditStringId.MenuCmd_InsertField": "Feld einfügen",
    "XtraRichEditStringId.MenuCmd_InsertFieldDescription": "Feld einfügen",
    "XtraRichEditStringId.MenuCmd_InsertFiguresCaption": "Zahlenbeschreibung",
    "XtraRichEditStringId.MenuCmd_InsertFiguresCaptionDescription": "Füge eine Zahlenbeschreibung hinzu",
    "XtraRichEditStringId.MenuCmd_InsertFloatingObjectPicture": "Bild aus Datei einfügen",
    "XtraRichEditStringId.MenuCmd_InsertFloatingObjectPictureDescription": "Bild aus Datei einfügen.",
    "XtraRichEditStringId.MenuCmd_InsertHyperlink": "Hyperlink einfügen",
    "XtraRichEditStringId.MenuCmd_InsertHyperlinkDescription": "Hyperlink einfügen",
    "XtraRichEditStringId.MenuCmd_InsertHyphen": "InsertHyphen",
    "XtraRichEditStringId.MenuCmd_InsertLineBreak": "Zeilenumbruch einfügen",
    "XtraRichEditStringId.MenuCmd_InsertLineBreakDescription": "Zeilenumbruch einfügen",
    "XtraRichEditStringId.MenuCmd_InsertMergeField": "Seriendruckfeld einfügen",
    "XtraRichEditStringId.MenuCmd_InsertMergeFieldDescription": "Seriendruckfeld einfügen",
    "XtraRichEditStringId.MenuCmd_InsertMultilevelList": "Gliederung",
    "XtraRichEditStringId.MenuCmd_InsertMultilevelListDescription": "Eine Gliederung beginnen",
    "XtraRichEditStringId.MenuCmd_InsertNonBreakingHyphen": "InsertNonBreakingHyphen",
    "XtraRichEditStringId.MenuCmd_InsertNonBreakingSpace": "Leerzeichen ohne Umbruch einfügen",
    "XtraRichEditStringId.MenuCmd_InsertNonBreakingSpaceDescription": "Leerzeichen ohne Umbruch einfügen",
    "XtraRichEditStringId.MenuCmd_InsertOpeningDoubleQuotationMark": "Unteres doppeltes Anführungszeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertOpeningDoubleQuotationMarkDescription": "Unteres doppeltes Anführungszeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertOpeningSingleQuotationMark": "Unteres einfaches Anführungszeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertOpeningSingleQuotationMarkDescription": "Unteres einfaches Anführungszeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertPageBreak": "Seitenumbruch",
    "XtraRichEditStringId.MenuCmd_InsertPageBreak2": "Seitenumbruch",
    "XtraRichEditStringId.MenuCmd_InsertPageBreak2Description": "Die nächste Seite an der aktuellen Position zu starten.",
    "XtraRichEditStringId.MenuCmd_InsertPageBreakDescription": "Die nächste Seite an der aktuellen Position beginnen.",
    "XtraRichEditStringId.MenuCmd_InsertPageCountField": "Seitenzähler",
    "XtraRichEditStringId.MenuCmd_InsertPageCountFieldDescription": "Gesamtseitenzahl des Dokuments einfügen.",
    "XtraRichEditStringId.MenuCmd_InsertPageNumberField": "Seitennummer",
    "XtraRichEditStringId.MenuCmd_InsertPageNumberFieldDescription": "Einfügen von Seitenzahlen in das Dokument.",
    "XtraRichEditStringId.MenuCmd_InsertParagraph": "Absatz einfügen",
    "XtraRichEditStringId.MenuCmd_InsertParagraphDescription": "Absatz einfügen",
    "XtraRichEditStringId.MenuCmd_InsertPicture": "Inlinebild aus Datei einfügen",
    "XtraRichEditStringId.MenuCmd_InsertPictureDescription": "Inlinebild aus Datei einfügen.",
    "XtraRichEditStringId.MenuCmd_InsertRegisteredTrademarkSymbol": "Zeichen für Markenzeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertRegisteredTrademarkSymbolDescription": "Zeichen für Markenzeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertSectionBreakContinuous": "Sektion (Kontinuierlich)",
    "XtraRichEditStringId.MenuCmd_InsertSectionBreakContinuousDescription": "Einfügen eines Abschnittsumbruchs und beginnen Sie den neuen Abschnitt auf derselben Seite.",
    "XtraRichEditStringId.MenuCmd_InsertSectionBreakEvenPage": "Sektion (Gerade Seite)",
    "XtraRichEditStringId.MenuCmd_InsertSectionBreakEvenPageDescription": "Einfügen eines Abschnittsumbruchs und beginnt einen neuen Abschnitt auf der nächsten geradzahligen Seite.",
    "XtraRichEditStringId.MenuCmd_InsertSectionBreakNextPage": "Sektion (Nächste)",
    "XtraRichEditStringId.MenuCmd_InsertSectionBreakNextPageDescription": "Einfügen eines Abschnittsumbruchs und beginnt einen neuen Abschnitt auf der nächsten Seite.",
    "XtraRichEditStringId.MenuCmd_InsertSectionBreakOddPage": "Sektion (Ungerade Seite)",
    "XtraRichEditStringId.MenuCmd_InsertSectionBreakOddPageDescription": "Einfügen eines Abschnittsumbruchs und beginnt einen neuen Abschnitt auf der nächsten gungeraden Seite.",
    "XtraRichEditStringId.MenuCmd_InsertSimpleList": "Nummerierung",
    "XtraRichEditStringId.MenuCmd_InsertSimpleListDescription": "Eine nummerierte Liste beginnen.",
    "XtraRichEditStringId.MenuCmd_InsertSymbol": "Symbol einfügen",
    "XtraRichEditStringId.MenuCmd_InsertSymbolDescription": "Einfügen von Symbolen, die nicht auf Ihrer Tastatur sind, wie das Urheberrecht Symbole, Marken-Symbole, Absatzmarken und Unicode-Zeichen.",
    "XtraRichEditStringId.MenuCmd_InsertTab": "Tabulator einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTabDescription": "Tabulator einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTable": "Tabelle",
    "XtraRichEditStringId.MenuCmd_InsertTableCells": "Zellen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTableCellsDescription": "Zellen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTableColumnToTheLeft": "Spalten links einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTableColumnToTheLeftDescription": "Fügen Sie eine neue Spalte direkt links neben der ausgewählten Spalte ein",
    "XtraRichEditStringId.MenuCmd_InsertTableColumnToTheRight": "Spalte rechts einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTableColumnToTheRightDescription": "Fügen Sie eine neue Spalte direkt rechts neben der ausgewählten Spalte ein",
    "XtraRichEditStringId.MenuCmd_InsertTableDescription": "Ein Tabelle in das Dokument einfügen.",
    "XtraRichEditStringId.MenuCmd_InsertTableElement": "Einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTableOfContents": "Tabelle Inhaltsverzeichnis",
    "XtraRichEditStringId.MenuCmd_InsertTableOfContentsDescription": "Fügen Sie das Inhaltsverzeichnis in ein Dokument ein.\n\n Sobald Sie ein Inhaltsverzeichnis hinzugefügt haben, klicken Sie auf die Schaltfläche Text hinzufügen, um Einträge in die Tabelle einzufügen.",
    "XtraRichEditStringId.MenuCmd_InsertTableOfEquations": "Tabelle Gleichungen",
    "XtraRichEditStringId.MenuCmd_InsertTableOfEquationsDescription": "Fügen Sie eine Tabelle der Gleichungen in das Dokument ein.\n\n Eine Tabelle der Gleichungen enthält eine Liste aller Gleichungen in dem Dokument.",
    "XtraRichEditStringId.MenuCmd_InsertTableOfFigures": "Zahlentabelle",
    "XtraRichEditStringId.MenuCmd_InsertTableOfFiguresDescription": "Fügen Sie eine Tabelle der Zahlen in das Dokument ein.\n\n Eine Tabelle der Zahlen enthält eine Liste aller Zahlen in dem Dokument.",
    "XtraRichEditStringId.MenuCmd_InsertTableOfFiguresPlaceholder": "Zahlentabelle einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTableOfFiguresPlaceholderDescription": "Fügen Sie eine Tabelle der Zahlen in das Dokument ein.\n\n Eine Tabelle der Zahlen enthält eine Liste aller Abbildungen, Tabellen oder Gleichungen in dem Dokument.",
    "XtraRichEditStringId.MenuCmd_InsertTableOfTables": "Tabelle der Tabellen",
    "XtraRichEditStringId.MenuCmd_InsertTableOfTablesDescription": "Fügen Sie eine Tabelle der Tabellen in das Dokument ein.\n\n Eine Tabelle der Tabellen enthält eine Liste aller Tabellen in dem Dokument.",
    "XtraRichEditStringId.MenuCmd_InsertTableRowAbove": "Zeile oberhalb einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTableRowAboveDescription": "Fügt eine neue Zeile direkt oberhalb der ausgewählten Zeile ein.",
    "XtraRichEditStringId.MenuCmd_InsertTableRowBelow": "Zeile unterhalb einfügen.",
    "XtraRichEditStringId.MenuCmd_InsertTableRowBelowDescription": "Fügt eine neue Zeile direkt unterhalb der ausgewählten Zeile ein.",
    "XtraRichEditStringId.MenuCmd_InsertTablesCaption": "Tabellenbeschriftung",
    "XtraRichEditStringId.MenuCmd_InsertTablesCaptionDescription": "Tabellenbeschriftung hinzufügen.",
    "XtraRichEditStringId.MenuCmd_InsertTabToParagraph": "EinfügenTabZuAbsatz",
    "XtraRichEditStringId.MenuCmd_InsertTabToParagraphDescription": "EinfügenTabZuAbsatzBeschreibung",
    "XtraRichEditStringId.MenuCmd_InsertText": "Text einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTextBox": "Textfeld",
    "XtraRichEditStringId.MenuCmd_InsertTextBoxDescription": "Vorformatierte Textfelder einfügen.",
    "XtraRichEditStringId.MenuCmd_InsertTextDescription": "Text einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTrademarkSymbol": "TM-Zeichen einfügen",
    "XtraRichEditStringId.MenuCmd_InsertTrademarkSymbolDescription": "TM-Zeichen einfügen",
    "XtraRichEditStringId.MenuCmd_Language": "Sprache",
    "XtraRichEditStringId.MenuCmd_LanguageDescription": "Festlegen der Sprache zum Prüfen von Rechtschreibung und Grammatik des markierten Textes.",
    "XtraRichEditStringId.MenuCmd_LastDataRecord": "Letzter Datensatz",
    "XtraRichEditStringId.MenuCmd_LastDataRecordDescription": "Letzter Datensatz",
    "XtraRichEditStringId.MenuCmd_LoadDocument": "Öffnen",
    "XtraRichEditStringId.MenuCmd_LoadDocumentDescription": "Öffnen",
    "XtraRichEditStringId.MenuCmd_LockField": "Feld sperren",
    "XtraRichEditStringId.MenuCmd_LockFieldDescription": "Feld sperren",
    "XtraRichEditStringId.MenuCmd_LockTracking": "Nachverfolgung sperren",
    "XtraRichEditStringId.MenuCmd_LockTrackingDescription": "Ein Kennwort verwenden, um andere daran zu hindern, die Nachverfolgung von Änderungen zu deaktivieren",
    "XtraRichEditStringId.MenuCmd_MailMergeSaveDocumentAsCommand": "Seriendruck",
    "XtraRichEditStringId.MenuCmd_MailMergeSaveDocumentAsCommandDescription": "Seriendruck",
    "XtraRichEditStringId.MenuCmd_MakeTextLowerCase": "Kleinbuchstaben",
    "XtraRichEditStringId.MenuCmd_MakeTextLowerCaseDescription": "Ändern Sie alle markierten Texte in Kleinbuchstaben.",
    "XtraRichEditStringId.MenuCmd_MakeTextUpperCase": "Großbuchstaben",
    "XtraRichEditStringId.MenuCmd_MakeTextUpperCaseDescription": "Ändern Sie alle markierten Texte in Großbuchstaben.",
    "XtraRichEditStringId.MenuCmd_MergeTableCells": "Zellen verbinden",
    "XtraRichEditStringId.MenuCmd_MergeTableCellsDescription": "Führen Sie die ausgewählten Zellen in einer Zelle zusammen.",
    "XtraRichEditStringId.MenuCmd_ModifyHyperlink": "ÄndereHyperlink",
    "XtraRichEditStringId.MenuCmd_ModifyHyperlinkDescription": "ÄndereHyperlinkBeschreibumg",
    "XtraRichEditStringId.MenuCmd_ModifyTableStyle": "Ändern der Tabellenformatvorlage...",
    "XtraRichEditStringId.MenuCmd_MoveBackward": "Gehe zurück",
    "XtraRichEditStringId.MenuCmd_MoveBackwardDescription": "Gehe zurück",
    "XtraRichEditStringId.MenuCmd_MoveForward": "Gehe vorwärts",
    "XtraRichEditStringId.MenuCmd_MoveForwardDescription": "Gehe vorwärts",
    "XtraRichEditStringId.MenuCmd_MoveLineDown": "Eine Zeile abwärts",
    "XtraRichEditStringId.MenuCmd_MoveLineDownDescription": "Eine Zeile abwärts",
    "XtraRichEditStringId.MenuCmd_MoveLineUp": "Eine Zeile hoch",
    "XtraRichEditStringId.MenuCmd_MoveLineUpDescription": "Eine Zeile hoch",
    "XtraRichEditStringId.MenuCmd_MoveNextPage": "Gehe zur nächsten Seite",
    "XtraRichEditStringId.MenuCmd_MoveNextPageDescription": "Gehe zur nächsten Seite",
    "XtraRichEditStringId.MenuCmd_MoveNextParagraph": "Gehe zum nächsten Absatz",
    "XtraRichEditStringId.MenuCmd_MoveNextParagraphDescription": "Gehe zum nächsten Absatz",
    "XtraRichEditStringId.MenuCmd_MoveNextWord": "Gehe zum nächsten Wort",
    "XtraRichEditStringId.MenuCmd_MoveNextWordDescription": "Gehe zum nächsten Wort",
    "XtraRichEditStringId.MenuCmd_MovePreviousPage": "Gehe zur vorangehenden Seite",
    "XtraRichEditStringId.MenuCmd_MovePreviousPageDescription": "Gehe zur vorangehenden Seite",
    "XtraRichEditStringId.MenuCmd_MovePreviousParagraph": "Gehe zum vorangehenden Absatz",
    "XtraRichEditStringId.MenuCmd_MovePreviousParagraphDescription": "Gehe zum vorangehenden Absatz",
    "XtraRichEditStringId.MenuCmd_MovePreviousWord": "Gehe zum vorangehenden Wort",
    "XtraRichEditStringId.MenuCmd_MovePreviousWordDescription": "Gehe zum vorangehenden Wort",
    "XtraRichEditStringId.MenuCmd_MoveScreenDown": "Eine Bildschirmseite hinunter",
    "XtraRichEditStringId.MenuCmd_MoveScreenDownDescription": "Eine Bildschirmseite hinunter",
    "XtraRichEditStringId.MenuCmd_MoveScreenUp": "Eine Bildschirmseite hinauf",
    "XtraRichEditStringId.MenuCmd_MoveScreenUpDescription": "Eine Bildschirmseite hinauf",
    "XtraRichEditStringId.MenuCmd_MoveToBeginOfDocument": "Gehe zum Anfang des Dokuments",
    "XtraRichEditStringId.MenuCmd_MoveToBeginOfDocumentDescription": "Gehe zum Anfang des Dokuments",
    "XtraRichEditStringId.MenuCmd_MoveToEndOfDocument": "Gehe zum Ende des Dokuments",
    "XtraRichEditStringId.MenuCmd_MoveToEndOfDocumentDescription": "Gehe zum Ende des Dokuments",
    "XtraRichEditStringId.MenuCmd_MoveToEndOfLine": "Gehe zum Zeilenende",
    "XtraRichEditStringId.MenuCmd_MoveToEndOfLineDescription": "Gehe zum Zeilenende",
    "XtraRichEditStringId.MenuCmd_MoveToStartOfLine": "Gehe zum Zeilenanfang",
    "XtraRichEditStringId.MenuCmd_MoveToStartOfLineDescription": "Gehe zum Zeilenanfang",
    "XtraRichEditStringId.MenuCmd_NewComment": "Neuer Kommentar",
    "XtraRichEditStringId.MenuCmd_NewCommentDescription": "Fügen Sie einen Kommentar zu diesem Teil des Dokumentes hinzu.",
    "XtraRichEditStringId.MenuCmd_NewEmptyDocument": "Neu",
    "XtraRichEditStringId.MenuCmd_NewEmptyDocumentDescription": "Ein neues Dokument erzeugen.",
    "XtraRichEditStringId.MenuCmd_NewTableStyle": "Neues Tabellenformat...",
    "XtraRichEditStringId.MenuCmd_NextComment": "Nächste",
    "XtraRichEditStringId.MenuCmd_NextCommentDescription": "Zum nächsten Kommentar im Dokument navigieren.",
    "XtraRichEditStringId.MenuCmd_NextDataRecord": "Nächster Datensatz",
    "XtraRichEditStringId.MenuCmd_NextDataRecordDescription": "Nächster Datensatz",
    "XtraRichEditStringId.MenuCmd_NextRevision": "Nächste",
    "XtraRichEditStringId.MenuCmd_NextRevisionDescription": "Zur nächsten Überarbeitung springen.",
    "XtraRichEditStringId.MenuCmd_None": " ",
    "XtraRichEditStringId.MenuCmd_OpenHyperlink": "Hyperlink öffnen",
    "XtraRichEditStringId.MenuCmd_OpenHyperlinkAtCaretPosition": "ÖffneHyperlinkaufCursorPosition",
    "XtraRichEditStringId.MenuCmd_OpenHyperlinkAtCaretPositionDescription": "ÖffneHyperlinkAufCursorPositionBeschreibung",
    "XtraRichEditStringId.MenuCmd_OpenHyperlinkDescription": "Hyperlink öffnen",
    "XtraRichEditStringId.MenuCmd_OvertypeText": "OvertypeText",
    "XtraRichEditStringId.MenuCmd_OvertypeTextDescription": "OvertypeText",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentCenter": "Zentrieren",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentCenterDescription": "Zentriert den Text.",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentDistribute": "Distributed",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentDistributeDescription": "Align text to both left and right margins.\n\nSpace will be added between characters and words. If the last line is short, extra spaces will be added between the characters to make it match the width of the paragraph.",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustify": "Blocksatz",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyDescription": "Dehnt den Text zeilenweise genau zwischen dem linken und rechten Rand aus, indem der dafür notwendige Freiraum zwischen den Worten eingefügt wird.",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyHigh": "Justify High",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyHighDescription": "Align text to both left and right margins.\n\nSpecifies that a paragraph uses the kashida with the widest length for Arabic text. For other languages, paragraph text is justified with the widest inter-word spacing.",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyLow": "Justify Low",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyLowDescription": "Align text to both left and right margins.\n\nIf the language is Arabic, the paragraph uses small length Kashida. In other languages, text is justified with short inter-word spacing.",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyMedium": "Justify Medium",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyMediumDescription": "Align text to both left and right margins.\n\nIf the language is Arabic, the paragraph uses medium-length Kashida. In other languages, text is justified with wider inter-word spacing.",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentLeft": "Text linksbündig",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentLeftDescription": "Ordnet den Text am linken Rand an.",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentRight": "Text rechtsbündig",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentRightDescription": "Ordnet den Text am rechten Rand an.",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentThaiDistribute": "Thai Distributed",
    "XtraRichEditStringId.MenuCmd_ParagraphAlignmentThaiDistributeDescription": "Align text to both left and right margins by adding extra space between each two characters for languages with tone mark and vowel mark.",
    "XtraRichEditStringId.MenuCmd_ParagraphLeftToRight": "Left-to-Right Text Direction",
    "XtraRichEditStringId.MenuCmd_ParagraphLeftToRightDescription": "Set the text to read from right to left.",
    "XtraRichEditStringId.MenuCmd_ParagraphRightToLeft": "Right-to-Left Text Direction",
    "XtraRichEditStringId.MenuCmd_ParagraphRightToLeftDescription": "Set the text to read from left to right.",
    "XtraRichEditStringId.MenuCmd_ParagraphSuppressHyphenation": "Unterdrückt Silbentrennung für den aktuellen Absatz",
    "XtraRichEditStringId.MenuCmd_ParagraphSuppressHyphenationDescription": "Unterdrückt Silbentrennung für den aktuellen Absatz",
    "XtraRichEditStringId.MenuCmd_ParagraphSuppressLineNumbers": "Unterdrückt Zeilennummern für den aktuellen Absatz",
    "XtraRichEditStringId.MenuCmd_ParagraphSuppressLineNumbersDescription": "Unterdrückt Zeilennummern für den aktuellen Absatz",
    "XtraRichEditStringId.MenuCmd_Paste": "Einfügen",
    "XtraRichEditStringId.MenuCmd_PasteDescription": "Fügt den Text aus der Zwischenablage ein.",
    "XtraRichEditStringId.MenuCmd_PasteFiles": "Dateien",
    "XtraRichEditStringId.MenuCmd_PasteFilesDescription": "Fügt den Inhalt der Zwischenablage als Datei ein.",
    "XtraRichEditStringId.MenuCmd_PasteHtmlText": "HTML Format",
    "XtraRichEditStringId.MenuCmd_PasteHtmlTextDescription": "Fügt den Inhalt der Zwischenablage als HTML Format ein.",
    "XtraRichEditStringId.MenuCmd_PasteImage": "Foto",
    "XtraRichEditStringId.MenuCmd_PasteImageDescription": "Fügt den Inhalt der Zwischenablage las Foto ein.",
    "XtraRichEditStringId.MenuCmd_PasteMetafileImage": "Metadatei",
    "XtraRichEditStringId.MenuCmd_PasteMetafileImageDescription": "Fügt den Inhalt der Zwischenablage als Metadatei ein.",
    "XtraRichEditStringId.MenuCmd_PastePlainText": "Unformatierter Text",
    "XtraRichEditStringId.MenuCmd_PastePlainTextDescription": "Fügt den Inhalt der Zwischenablage als unformatierten Text ein.",
    "XtraRichEditStringId.MenuCmd_PasteRtfText": "Formatierter Text (RTF)",
    "XtraRichEditStringId.MenuCmd_PasteRtfTextDescription": "Fügt den Inhalt der Zwischenablage als Text mit formatierter Schrift und Tabelle ein.",
    "XtraRichEditStringId.MenuCmd_PasteSilverlightXamlText": "Formatierter Text (XAML)",
    "XtraRichEditStringId.MenuCmd_PasteSilverlightXamlTextDescription": "Fügt den Inhalt der Zwischenablage als Text mit formatierter Schrift ein.",
    "XtraRichEditStringId.MenuCmd_PlaceCaretToPhysicalPoint": "Einfügemarke an physischen Punkt setzen",
    "XtraRichEditStringId.MenuCmd_PlaceCaretToPhysicalPointDescription": "Einfügemarke an physischen Punkt setzen.",
    "XtraRichEditStringId.MenuCmd_PreviousComment": "Vorherige",
    "XtraRichEditStringId.MenuCmd_PreviousCommentDescription": "Zum vorherigen Kommentar im Dokument navigieren.",
    "XtraRichEditStringId.MenuCmd_PreviousDataRecord": "Vorheriger Datensatz",
    "XtraRichEditStringId.MenuCmd_PreviousDataRecordDescription": "Vorheriger Datensatz",
    "XtraRichEditStringId.MenuCmd_PrevRevision": "Vorherige",
    "XtraRichEditStringId.MenuCmd_PrevRevisionDescription": "Zur vorherigen Überarbeitung springen.",
    "XtraRichEditStringId.MenuCmd_Print": "&Drucken",
    "XtraRichEditStringId.MenuCmd_PrintDescription": "Den Drucker, die Anzahl der Drucke und andere Druckoptinen vor dem Druck wählen.",
    "XtraRichEditStringId.MenuCmd_PrintPreview": "Mit &Vorschau drucken",
    "XtraRichEditStringId.MenuCmd_PrintPreviewDescription": "Druckvorschau aufrufen und Änderungen an den Druckeinstellungen vor dem Druck vornehmen.",
    "XtraRichEditStringId.MenuCmd_PrintView": "Druckansicht",
    "XtraRichEditStringId.MenuCmd_PrintViewDescription": "Druckansicht",
    "XtraRichEditStringId.MenuCmd_ProtectDocument": "Geschütztes Dokument",
    "XtraRichEditStringId.MenuCmd_ProtectDocumentDescription": "Einschränkung der Bearbeitung des Dokuments durch Angabe eines Passworts.",
    "XtraRichEditStringId.MenuCmd_QuickPrint": "&Schnelldruck",
    "XtraRichEditStringId.MenuCmd_QuickPrintDescription": "Sendet das Dokument direkt und ohne weitere Spezifikationen an den Standarddrucker.",
    "XtraRichEditStringId.MenuCmd_ReadingView": "Leseansicht",
    "XtraRichEditStringId.MenuCmd_ReadingViewDescription": "Leseansicht",
    "XtraRichEditStringId.MenuCmd_Redo": "Wiederherstellen",
    "XtraRichEditStringId.MenuCmd_RedoDescription": "Wiederherstellen",
    "XtraRichEditStringId.MenuCmd_Reject": "Ablehnen",
    "XtraRichEditStringId.MenuCmd_RejectAllChanges": "Alle Änderungen ablehnen",
    "XtraRichEditStringId.MenuCmd_RejectAllChangesAndStopTracking": "Alle Änderungen ablehnen und Nachverfolgung beenden",
    "XtraRichEditStringId.MenuCmd_RejectAllChangesAndStopTrackingDescription": "Reject All Changes and Stop Tracking",
    "XtraRichEditStringId.MenuCmd_RejectAllChangesDescription": "Reject All Changes",
    "XtraRichEditStringId.MenuCmd_RejectAllChangesShown": "Alle angezeigten Änderungen ablehnen",
    "XtraRichEditStringId.MenuCmd_RejectAllChangesShownDescription": "Alle angezeigten Änderungen ablehnen",
    "XtraRichEditStringId.MenuCmd_RejectAndMoveToNextRevision": "Ablehnen und weiter",
    "XtraRichEditStringId.MenuCmd_RejectAndMoveToNextRevisionDescription": "Undo this change and move on to the next one.",
    "XtraRichEditStringId.MenuCmd_RejectCurrentRevision": "Diese Änderung ablehnen",
    "XtraRichEditStringId.MenuCmd_RejectCurrentRevisionDescription": "Reject This Change",
    "XtraRichEditStringId.MenuCmd_RejectDescription": "Klicken Sie, um weitere Optionen anzuzeigen, z.B. das gleichzeitige Ablehnen aller Änderungen.",
    "XtraRichEditStringId.MenuCmd_RemoveHyperlink": "Hyperlink entfernen",
    "XtraRichEditStringId.MenuCmd_RemoveHyperlinkDescription": "Hyperlink entfernen",
    "XtraRichEditStringId.MenuCmd_RemoveSpacingAfterParagraph": "Leerraum &nach dem Absatz entfernen.",
    "XtraRichEditStringId.MenuCmd_RemoveSpacingAfterParagraphDescription": " ",
    "XtraRichEditStringId.MenuCmd_RemoveSpacingBeforeParagraph": "Leerraum &vor dem Absatz entfernen.",
    "XtraRichEditStringId.MenuCmd_RemoveSpacingBeforeParagraphDescription": " ",
    "XtraRichEditStringId.MenuCmd_Replace": "Ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceAllBackward": "Alle Fundstellen rückwärts ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceAllBackwardDescription": "Alle Fundstellen rückwärts ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceAllForward": "Alle Fundstellen vorwärts ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceAllForwardDescription": "Alle Fundstellen vorwärts ersetzen.",
    "XtraRichEditStringId.MenuCmd_ReplaceBackward": "Rückwärts ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceBackwardDescription": "Text rückwärts ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceDescription": "Text im Dokument ersetzen.",
    "XtraRichEditStringId.MenuCmd_ReplaceForward": "Vowärts ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceForwardDescription": "Text vorwärts ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceText": "Text ersetzen",
    "XtraRichEditStringId.MenuCmd_ReplaceTextDescription": "",
    "XtraRichEditStringId.MenuCmd_ResetCharacterFormatting": "Reset Zeichenformatierung",
    "XtraRichEditStringId.MenuCmd_ResetCharacterFormattingDescription": "Macht die Auswahl der Standard-Zeichenformate des angewandten Stils.",
    "XtraRichEditStringId.MenuCmd_ResetTableCellsBorders": "Keine Ränder",
    "XtraRichEditStringId.MenuCmd_ResetTableCellsBordersDescription": "Passt die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_Reviewers": "Bewertungen",
    "XtraRichEditStringId.MenuCmd_ReviewersDescription": "Wählen Sie welche Überarbeitungskommentare um im Dokument zu markieren.",
    "XtraRichEditStringId.MenuCmd_ReviewingPane": "Überarbeitungsbereich",
    "XtraRichEditStringId.MenuCmd_ReviewingPaneDescription": "Alle Änderungen im Dokument in einer Liste anzeigen.",
    "XtraRichEditStringId.MenuCmd_SaveDocument": "Speichern",
    "XtraRichEditStringId.MenuCmd_SaveDocumentAs": "Speichern unter",
    "XtraRichEditStringId.MenuCmd_SaveDocumentAsDescription": "Speichern unter",
    "XtraRichEditStringId.MenuCmd_SaveDocumentDescription": "Speichern",
    "XtraRichEditStringId.MenuCmd_ScrollDown": "Nach unten scrollen",
    "XtraRichEditStringId.MenuCmd_ScrollDownDescription": "Nach unten scrollen",
    "XtraRichEditStringId.MenuCmd_ScrollToPage": "Gehe zu Seite",
    "XtraRichEditStringId.MenuCmd_ScrollToPageDescription": "Zu einer bestimmten Seite gehen.",
    "XtraRichEditStringId.MenuCmd_SelectAll": "Alles auswählen",
    "XtraRichEditStringId.MenuCmd_SelectAllDescription": "Alles auswählen",
    "XtraRichEditStringId.MenuCmd_SelectBookmark": "Lesezeichen auswählen",
    "XtraRichEditStringId.MenuCmd_SelectBookmarkDescription": "Lesezeichen auswählen",
    "XtraRichEditStringId.MenuCmd_SelectComment": "Select Comment",
    "XtraRichEditStringId.MenuCmd_SelectCommentDescription": "Select Comment",
    "XtraRichEditStringId.MenuCmd_SelectFieldNextToCaret": "Nächstes Feld auswählen",
    "XtraRichEditStringId.MenuCmd_SelectFieldNextToCaretDescription": "Nächstes Feld auswählen",
    "XtraRichEditStringId.MenuCmd_SelectFieldPrevToCaret": "Vorangehendes Feld auswählen",
    "XtraRichEditStringId.MenuCmd_SelectFieldPrevToCaretDescription": "Vorangehendes Feld auswählen",
    "XtraRichEditStringId.MenuCmd_SelectTable": "Tabelle auswählen",
    "XtraRichEditStringId.MenuCmd_SelectTableCell": "Tabellenzelle auswählen",
    "XtraRichEditStringId.MenuCmd_SelectTableCellDescription": "Tabellenzelle auswählen",
    "XtraRichEditStringId.MenuCmd_SelectTableColumns": "Tabellen Spalten auswählen",
    "XtraRichEditStringId.MenuCmd_SelectTableColumnsDescription": "Tabellen Spalten auswählen",
    "XtraRichEditStringId.MenuCmd_SelectTableDescription": "Tabelle auswählen",
    "XtraRichEditStringId.MenuCmd_SelectTableElements": "Auswahl",
    "XtraRichEditStringId.MenuCmd_SelectTableElementsDescription": "Wählen Sie die aktuelle Zelle, Zeile, Spalte oder die gesamte Tabelle aus",
    "XtraRichEditStringId.MenuCmd_SelectTableRow": "Tabellenzeile auswählen",
    "XtraRichEditStringId.MenuCmd_SelectTableRowDescription": "Tabellenzeile auswählen",
    "XtraRichEditStringId.MenuCmd_SetDoubleParagraphSpacing": "2.0",
    "XtraRichEditStringId.MenuCmd_SetDoubleParagraphSpacingDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectBehindTextWrapType": "Hinter den Text",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectBehindTextWrapTypeDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomCenterAlignment": "Unten zentriert",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomCenterAlignmentDescription": "Unten-Zentriert-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomLeftAlignment": "Unten links",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomLeftAlignmentDescription": "Unten-Links-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomRightAlignment": "Unten rechts",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomRightAlignmentDescription": "Unten-Rechts-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectInFrontOfTextWrapType": "Vor den Text",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectInFrontOfTextWrapTypeDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectInlineWithTextWrapType": "In Line with Text",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectInlineWithTextWrapTypeDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleCenterAlignment": "Mitte zentriert",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleCenterAlignmentDescription": "Mitte-Zentriert-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleLeftAlignment": "Mitte links",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleLeftAlignmentDescription": "Mitte-Links-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleRightAlignment": "Mitte rechts",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleRightAlignmentDescription": "Mitte-Rechts-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectSquareTextWrapType": "Quadrat",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectSquareTextWrapTypeDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectThroughTextWrapType": "Transparent",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectThroughTextWrapTypeDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTightTextWrapType": "Eng",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTightTextWrapTypeDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopAndBottomTextWrapType": "Oben und unten",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopAndBottomTextWrapTypeDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopCenterAlignment": "Oben zentriert",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopCenterAlignmentDescription": "Oben-Zentriert-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopLeftAlignment": "Oben links",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopLeftAlignmentDescription": "Oben-Links-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopRightAlignment": "Oben rechts",
    "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopRightAlignmentDescription": "Oben-Rechts-Position mit quadratischem Textfluss",
    "XtraRichEditStringId.MenuCmd_SetHyphenateDocumentAutomatic": "Automatisch",
    "XtraRichEditStringId.MenuCmd_SetHyphenateDocumentAutomaticDescription": "Turns on Automatic Hyphenation",
    "XtraRichEditStringId.MenuCmd_SetHyphenateDocumentNone": "Keine",
    "XtraRichEditStringId.MenuCmd_SetHyphenateDocumentNoneDescription": "Turns off Automatic Hyphenation",
    "XtraRichEditStringId.MenuCmd_SetLandscapePageOrientation": "Querformat",
    "XtraRichEditStringId.MenuCmd_SetLandscapePageOrientationDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetModerateSectionPageMargins": "Mittelmäßig\nOben:\t{1,10}\tUnten:\t{3,10}\nLinks:\t{0,10}\tRechts:\t{2,10}",
    "XtraRichEditStringId.MenuCmd_SetModerateSectionPageMarginsDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetNarrowSectionPageMargins": "Eng\nOben:\t{1,10}\tUnten:\t{3,10}\nLinks:\t{0,10}\tRechts:\t{2,10}",
    "XtraRichEditStringId.MenuCmd_SetNarrowSectionPageMarginsDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetNormalSectionPageMargins": "Normal\nOben:\t{1,10}\tUnten:\t{3,10}\nLinks:\t{0,10}\tRechts:\t{2,10}",
    "XtraRichEditStringId.MenuCmd_SetNormalSectionPageMarginsDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetParagraphBodyTextLevel": "Zeige nicht in Inhaltstabelle",
    "XtraRichEditStringId.MenuCmd_SetParagraphBodyTextLevelDescription": "Zeige nicht in Inhaltstabelle",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading1Level": "Level 1",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading1LevelDescription": "Level 1",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading2Level": "Level 2",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading2LevelDescription": "Level 2",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading3Level": "Level 3",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading3LevelDescription": "Level 3",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading4Level": "Level 4",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading4LevelDescription": "Level 4",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading5Level": "Level 5",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading5LevelDescription": "Level 5",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading6Level": "Level 6",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading6LevelDescription": "Level 6",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading7Level": "Level 7",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading7LevelDescription": "Level 7",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading8Level": "Level 8",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading8LevelDescription": "Level 8",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading9Level": "Level 9",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeading9LevelDescription": "Level 9",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeadingLevel": "Ebene {0}",
    "XtraRichEditStringId.MenuCmd_SetParagraphHeadingLevelDescription": "Ebene {0}",
    "XtraRichEditStringId.MenuCmd_SetPortraitPageOrientation": "Hochformat",
    "XtraRichEditStringId.MenuCmd_SetPortraitPageOrientationDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetSectionColumns": "Spalte",
    "XtraRichEditStringId.MenuCmd_SetSectionColumnsDescription": "Teile Text in zwei oder mehr Spalten.",
    "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingContinuous": "Fortlaufend",
    "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingContinuousDescription": "Fortlaufend",
    "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingNone": "Keine",
    "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingNoneDescription": "Ohne Zeilennummerierung",
    "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingRestartNewPage": "Jede Seite neu beginnen",
    "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingRestartNewPageDescription": "Jede Seite neu beginnen",
    "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingRestartNewSection": "Jeden Abschnitt neu beginnen",
    "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingRestartNewSectionDescription": "Jeden Abschnitt neu beginnen",
    "XtraRichEditStringId.MenuCmd_SetSectionOneColumn": "Eins",
    "XtraRichEditStringId.MenuCmd_SetSectionOneColumnDescription": "Eine Spalte.",
    "XtraRichEditStringId.MenuCmd_SetSectionThreeColumns": "Drei",
    "XtraRichEditStringId.MenuCmd_SetSectionThreeColumnsDescription": "Drei Spalten.",
    "XtraRichEditStringId.MenuCmd_SetSectionTwoColumns": "Zwei",
    "XtraRichEditStringId.MenuCmd_SetSectionTwoColumnsDescription": "Zwei Spalten.",
    "XtraRichEditStringId.MenuCmd_SetSesquialteralParagraphSpacing": "1.5",
    "XtraRichEditStringId.MenuCmd_SetSesquialteralParagraphSpacingDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetSingleParagraphSpacing": "1.0",
    "XtraRichEditStringId.MenuCmd_SetSingleParagraphSpacingDescription": " ",
    "XtraRichEditStringId.MenuCmd_SetWideSectionPageMargins": "Breit\nOben:\t{1,10}\tUnten:\t{3,10}\nLinks:\t{0,10}\tRechts:\t{2,10}",
    "XtraRichEditStringId.MenuCmd_SetWideSectionPageMarginsDescription": " ",
    "XtraRichEditStringId.MenuCmd_ShiftTabKey": "Shift-Tab-Taste",
    "XtraRichEditStringId.MenuCmd_ShiftTabKeyDescription": "Shift-Tab-Taste",
    "XtraRichEditStringId.MenuCmd_ShowAllFieldCodes": "Feldbeschreibungen anzeigen",
    "XtraRichEditStringId.MenuCmd_ShowAllFieldCodesDescription": "Feldbeschreibungen anzeigen",
    "XtraRichEditStringId.MenuCmd_ShowAllFieldResults": "Feldergebnisse anzeigen",
    "XtraRichEditStringId.MenuCmd_ShowAllFieldResultsDescription": "Feldergebnisse anzeigen",
    "XtraRichEditStringId.MenuCmd_ShowBookmarkForm": "Lesezeichen",
    "XtraRichEditStringId.MenuCmd_ShowBookmarkFormDescription": "Erstellen Sie ein Lesezeichen, um eine bestimmte Stelle im Dokument zu benennen. Sie können Hyperlinks erstellen, um zu einem Lesezeichen zu springen.",
    "XtraRichEditStringId.MenuCmd_ShowBordersShadingForm": "Rahmen und Schattierung",
    "XtraRichEditStringId.MenuCmd_ShowBordersShadingFormDescription": "Explore additional border options, such as line color and line width",
    "XtraRichEditStringId.MenuCmd_ShowColumnsSetupForm": "Mehr Spalten",
    "XtraRichEditStringId.MenuCmd_ShowColumnsSetupFormDescription": "Zeigen die Spaltendialogbox um die Spaltenbreite anzupassen.",
    "XtraRichEditStringId.MenuCmd_ShowCommentForm": "Kommentar",
    "XtraRichEditStringId.MenuCmd_ShowCommentFormDescription": "Zeigen Sie das Dialogfeld Kommentar.",
    "XtraRichEditStringId.MenuCmd_ShowDocumentProperties": "Dokumenteigenschaften",
    "XtraRichEditStringId.MenuCmd_ShowDocumentPropertiesDescription": "Show the Properties dialog box.",
    "XtraRichEditStringId.MenuCmd_ShowEditStyleForm": "Formatvorlage ändern",
    "XtraRichEditStringId.MenuCmd_ShowEditStyleFormDescription": "Das Dialogfeld 'Formatvorlage ändern' anzeigen.",
    "XtraRichEditStringId.MenuCmd_ShowFloatingObjectLayoutOptionsForm": "Layout",
    "XtraRichEditStringId.MenuCmd_ShowFloatingObjectLayoutOptionsFormDescription": "Das Dialogfeld 'Layout' anzeigen.",
    "XtraRichEditStringId.MenuCmd_ShowFontForm": "Schriftart",
    "XtraRichEditStringId.MenuCmd_ShowFontFormDescription": "Schriftauswahldialog zeigen.",
    "XtraRichEditStringId.MenuCmd_ShowHyperlinkForm": "Hyperlink einfügen",
    "XtraRichEditStringId.MenuCmd_ShowHyperlinkFormDescription": "Erstellen Sie einen Hyperlink zu einer Webseite, einem Bild, einer E-Mail-Adresse oder einem Programm.",
    "XtraRichEditStringId.MenuCmd_ShowHyphenationForm": "Silbentrennungsoptionen...",
    "XtraRichEditStringId.MenuCmd_ShowHyphenationFormDescription": "Das Silbentrennungsverhalten des markierten Texts ändern.",
    "XtraRichEditStringId.MenuCmd_ShowInsertMergeFieldForm": "Seriendruckfeld einfügen",
    "XtraRichEditStringId.MenuCmd_ShowInsertMergeFieldFormDescription": "Fügen Sie ein Feld aus einer Liste der Empfänger oder einer Datentabelle in das Dokument ein.",
    "XtraRichEditStringId.MenuCmd_ShowLineNumberingForm": "Zeilennummerierungs Optionen",
    "XtraRichEditStringId.MenuCmd_ShowLineNumberingFormDescription": "Zeilennummerierungs Optionen",
    "XtraRichEditStringId.MenuCmd_ShowLineSpacingForm": "Zeilenabstand Optionen...",
    "XtraRichEditStringId.MenuCmd_ShowLineSpacingFormDescription": " ",
    "XtraRichEditStringId.MenuCmd_ShowMarkup": "Markup anzeigen",
    "XtraRichEditStringId.MenuCmd_ShowMarkupDescription": "Die Markuptypen auswählen, die in Ihrem Dokument angezeigt werden.",
    "XtraRichEditStringId.MenuCmd_ShowNumberingList": "Nummerierung",
    "XtraRichEditStringId.MenuCmd_ShowNumberingListDescription": "Anzeigen der Dialogbox für nummerierte Listen.",
    "XtraRichEditStringId.MenuCmd_ShowPageMarginsSetupForm": "&Benutzerdefinierte Seitenränder...",
    "XtraRichEditStringId.MenuCmd_ShowPageMarginsSetupFormDescription": " ",
    "XtraRichEditStringId.MenuCmd_ShowPagePaperSetupForm": "&Weitere Papierformate...",
    "XtraRichEditStringId.MenuCmd_ShowPagePaperSetupFormDescription": " ",
    "XtraRichEditStringId.MenuCmd_ShowPageSetupForm": "Seiten Setup",
    "XtraRichEditStringId.MenuCmd_ShowPageSetupFormDescription": "Zeige die Seiten Setup Dialogbox.",
    "XtraRichEditStringId.MenuCmd_ShowParagraphForm": "Absatz",
    "XtraRichEditStringId.MenuCmd_ShowParagraphFormDescription": "Zeigt die Absatzformatierungs-Dialogbox.",
    "XtraRichEditStringId.MenuCmd_ShowPasteSpecialForm": "Inhalte einfügen",
    "XtraRichEditStringId.MenuCmd_ShowPasteSpecialFormDescription": "Inhalte einfügen",
    "XtraRichEditStringId.MenuCmd_ShowRangeEditingPermissionsForm": "Auswahl der Bearbeitung von Berechtigungen",
    "XtraRichEditStringId.MenuCmd_ShowRangeEditingPermissionsFormDescription": "Auswahl der Bearbeitung von Berechtigungen",
    "XtraRichEditStringId.MenuCmd_ShowSymbol": "Symbol",
    "XtraRichEditStringId.MenuCmd_ShowSymbolDescription": "Anzeigen der Systemdialogbox.",
    "XtraRichEditStringId.MenuCmd_ShowTableOptionsForm": "\tZellenbegrenzungen",
    "XtraRichEditStringId.MenuCmd_ShowTableOptionsFormDescription": "Zellenbegrenzungen und Abstand zwischen den Zellen anpassen.",
    "XtraRichEditStringId.MenuCmd_ShowTablePropertiesForm": "Eigenschaften",
    "XtraRichEditStringId.MenuCmd_ShowTablePropertiesFormDescription": "Zeige die Tabellendialogbox mit erweiterten Eigenschaften der Tabelle, wie Einrückung und Textfluss-Optionen ändern.",
    "XtraRichEditStringId.MenuCmd_ShowTablePropertiesFormDescriptionMenuItem": "Zeige die Tabelleneigenschaften Dialogbox.",
    "XtraRichEditStringId.MenuCmd_ShowTablePropertiesFormMenuItem": "Tabelleneigenschaften...",
    "XtraRichEditStringId.MenuCmd_ShowTabsForm": "Tabulatoren",
    "XtraRichEditStringId.MenuCmd_ShowTabsFormDescription": "Tabulatoren",
    "XtraRichEditStringId.MenuCmd_SimpleView": "Standardansicht",
    "XtraRichEditStringId.MenuCmd_SimpleViewDescription": "Standardansicht",
    "XtraRichEditStringId.MenuCmd_SplitTable": "Teile Tabelle",
    "XtraRichEditStringId.MenuCmd_SplitTableCells": "Teile Zellen",
    "XtraRichEditStringId.MenuCmd_SplitTableCellsDescription": "Teile die ausgewählte Zelle in mehrere neue Zellen",
    "XtraRichEditStringId.MenuCmd_SplitTableCellsMenuItem": "Teile Zellen...",
    "XtraRichEditStringId.MenuCmd_SplitTableDescription": "Teile die Tabelle in zwei neue Tabellen.\nDie ausgewählte Zeile wird die erste Zeile der neuen Tabelle werden.",
    "XtraRichEditStringId.MenuCmd_SwitchToDraftView": "Entwurfsansicht",
    "XtraRichEditStringId.MenuCmd_SwitchToDraftViewDescription": "Anzeigen des Dokumentes in der Entwurfsansicht ermöglicht ein schnelles Bearbeiten des Textes.\n\nEinige Elemente sind in dieser Ansicht nicht sichtbar.",
    "XtraRichEditStringId.MenuCmd_SwitchToPrintLayoutView": "Drucklayout",
    "XtraRichEditStringId.MenuCmd_SwitchToPrintLayoutViewDescription": "Anzeigen des Dokumentes, wie es später ausgedruckt aussieht.",
    "XtraRichEditStringId.MenuCmd_SwitchToSimpleView": "Einfache Ansicht",
    "XtraRichEditStringId.MenuCmd_SwitchToSimpleViewDescription": "Anzeigen des Dokumentes als einfache Notiz.\n\nDiese Ansicht ignoriert das Seitenlayout und legt das Hauptaugenmerk auf das Bearbeiten des Textes.",
    "XtraRichEditStringId.MenuCmd_SymbolFormInsertButton": "Einfügen",
    "XtraRichEditStringId.MenuCmd_TabKey": "Tab-Taste",
    "XtraRichEditStringId.MenuCmd_TabKeyDescription": "Tab-Taste",
    "XtraRichEditStringId.MenuCmd_ToggleAllFields": "Toggle All Fields",
    "XtraRichEditStringId.MenuCmd_ToggleAllFieldsDescription": "Toggle between displaying field codes and their results in the document",
    "XtraRichEditStringId.MenuCmd_ToggleAuthorVisibility": "Autoren Sichtbarkeit umschalten",
    "XtraRichEditStringId.MenuCmd_ToggleAuthorVisibilityDescription": "Toggle Authors Visibility",
    "XtraRichEditStringId.MenuCmd_ToggleBandedColumn": "Gebänderte Spalten",
    "XtraRichEditStringId.MenuCmd_ToggleBandedColumnDescription": "Display banded columns, in which even columns are formatted differently from odd columns.\n\nThis banding can make tables easier to read.",
    "XtraRichEditStringId.MenuCmd_ToggleBandedRows": "Gebänderte Zeilen",
    "XtraRichEditStringId.MenuCmd_ToggleBandedRowsDescription": "Display banded rows, in which even rows are formatted differently from odd rows.\n\nThis banding can make tables easier to read.",
    "XtraRichEditStringId.MenuCmd_ToggleDifferentFirstPage": "Erste Seite anders",
    "XtraRichEditStringId.MenuCmd_ToggleDifferentFirstPageDescription": "Geben Sie eine eindeutige Kopf-und Fußzeile der ersten Seite des Dokuments.",
    "XtraRichEditStringId.MenuCmd_ToggleDifferentOddAndEvenPages": "Unterscheidliche graden und ungeraden Seiten.",
    "XtraRichEditStringId.MenuCmd_ToggleDifferentOddAndEvenPagesDescription": "Festlegen, dass ungeraden Seiten eine anden Kopf- und Fußzeile haben als gerade Seiten.",
    "XtraRichEditStringId.MenuCmd_ToggleFieldCodes": "Feldfunktionen",
    "XtraRichEditStringId.MenuCmd_ToggleFieldCodesDescription": "Feldfunktionen",
    "XtraRichEditStringId.MenuCmd_ToggleFieldLocked": "Toggle Field Locked",
    "XtraRichEditStringId.MenuCmd_ToggleFieldLockedDescription": "Toggle Field Locked",
    "XtraRichEditStringId.MenuCmd_ToggleFirstColumn": "Erste Spalte",
    "XtraRichEditStringId.MenuCmd_ToggleFirstColumnDescription": "Zeigt Sonderformatierung für die erste Spalte der Tabelle.",
    "XtraRichEditStringId.MenuCmd_ToggleFirstRow": "Kopfzeile",
    "XtraRichEditStringId.MenuCmd_ToggleFirstRowDescription": "Zeigt Sonderformatierung für die erste Zeile der Tabelle.",
    "XtraRichEditStringId.MenuCmd_ToggleFontBold": "Fett",
    "XtraRichEditStringId.MenuCmd_ToggleFontBoldDescription": "Ausgewählten Text fett darstellen.",
    "XtraRichEditStringId.MenuCmd_ToggleFontDoubleStrikeout": "Doppelt durchgestrichen",
    "XtraRichEditStringId.MenuCmd_ToggleFontDoubleStrikeoutDescription": "Doppelt durchgestrichen",
    "XtraRichEditStringId.MenuCmd_ToggleFontDoubleUnderline": "Doppelt unterstreichen",
    "XtraRichEditStringId.MenuCmd_ToggleFontDoubleUnderlineDescription": "Doppelt unterstreichen",
    "XtraRichEditStringId.MenuCmd_ToggleFontItalic": "Kursiv",
    "XtraRichEditStringId.MenuCmd_ToggleFontItalicDescription": "Ausgewählten Text kursiv darstellen.",
    "XtraRichEditStringId.MenuCmd_ToggleFontStrikeout": "Durchgestrichen",
    "XtraRichEditStringId.MenuCmd_ToggleFontStrikeoutDescription": "Den markierten Text durchstreichen.",
    "XtraRichEditStringId.MenuCmd_ToggleFontUnderline": "Unterstreichen",
    "XtraRichEditStringId.MenuCmd_ToggleFontUnderlineDescription": "Ausgewählten Text unterstreichen.",
    "XtraRichEditStringId.MenuCmd_ToggleHeaderFooterLinkToPrevious": "Link zur vorhergehenden",
    "XtraRichEditStringId.MenuCmd_ToggleHeaderFooterLinkToPreviousDescription": "Link zu den vorherigen Abschnitt, so dass die Kopf-und Fußzeile im aktuellen Abschnitt den gleichen Inhalt wie im vorherigen Abschnitt enthalten.",
    "XtraRichEditStringId.MenuCmd_ToggleHiddenText": "Ausgeblendet",
    "XtraRichEditStringId.MenuCmd_ToggleHiddenTextDescription": "Ausgeblendet",
    "XtraRichEditStringId.MenuCmd_ToggleLastColumn": "Letzte Spalte",
    "XtraRichEditStringId.MenuCmd_ToggleLastColumnDescription": "Zeigt Sonderformatierung für die letzte Spalte der Tabelle.",
    "XtraRichEditStringId.MenuCmd_ToggleLastRow": "Ergebniszeile",
    "XtraRichEditStringId.MenuCmd_ToggleLastRowDescription": "Zeigt Sonderformatierung für die letzte Zeile der Tabelle.",
    "XtraRichEditStringId.MenuCmd_ToggleOvertype": "Überschreibmodus",
    "XtraRichEditStringId.MenuCmd_ToggleOvertypeDescription": "Überschreibmodus",
    "XtraRichEditStringId.MenuCmd_ToggleShowHorizontalRuler": "Horizontales Lineal",
    "XtraRichEditStringId.MenuCmd_ToggleShowHorizontalRulerDescription": "Sehen Sie das horizontale Lineal, zur Messung und Line-Up Objekte im Dokument",
    "XtraRichEditStringId.MenuCmd_ToggleShowTableGridLines": "Gitternetzlinien anzeigen",
    "XtraRichEditStringId.MenuCmd_ToggleShowTableGridLinesDescription": "Ein-oder Ausblenden der Gitternetzlinien innerhalb der Tabelle.",
    "XtraRichEditStringId.MenuCmd_ToggleShowVerticalRuler": "Vertikales Lineal",
    "XtraRichEditStringId.MenuCmd_ToggleShowVerticalRulerDescription": "Sehen Sie das vertikale Lineal, zur Messung und Line-Up Objekte im Dokument",
    "XtraRichEditStringId.MenuCmd_ToggleSpellCheckAsYouType": "Rechtschreibprüfung während der Eingabe",
    "XtraRichEditStringId.MenuCmd_ToggleSpellCheckAsYouTypeDescription": "w",
    "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitContents": "AutoAnpassen an Inhalt",
    "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitContentsDescription": "Auto-Fit Tabelle für den Inhalt.",
    "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitPlaceholder": "AutoAnpassen",
    "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitPlaceholderDescription": "Automatische Größenänderung die Spaltenbreiten auf den Text in ihnen basiert.\n\n Sie können die Breite der Tabelle auf die Größe des Fensters setzen, oder zurückkonvertieren auf die feste Spaltenbreiten.",
    "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitWindow": "Größe an Fenster anpassen",
    "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitWindowDescription": "Auto-Fit Tabelle für das Fenster.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsAllBorders": "Alle Ränder",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsAllBordersDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomBorder": "Bodenrand",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomBorderDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomCenterAlignment": "Zentriert Unten Ausrichten",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomCenterAlignmentDescription": "Zentriere Text und richte ihn in der Zelle nach unten aus.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomLeftAlignment": "Unten Links Ausrichten",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomLeftAlignmentDescription": "Zentriere Text und richte ihn in der Zelle nach unten links aus.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomRightAlignment": "Unten Rechts Ausrichten",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomRightAlignmentDescription": "Zentriere Text und richte ihn in der Zelle nach unten rechts aus.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideBorder": "Innere Ränder",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideBorderDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideHorizontalBorder": "Innerer Horizontaler Rand",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideHorizontalBorderDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideVerticalBorder": "Innerer Vertikaler Rand",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideVerticalBorderDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsLeftBorder": "Linker Rand",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsLeftBorderDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleCenterAlignment": "Zentralisieren",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleCenterAlignmentDescription": "Zentriere Text horizontal und vertikal in der Zelle.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleLeftAlignment": "Links Zentrieren",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleLeftAlignmentDescription": "Zentriere Text vertikal und richte Ihn auf die linke Seite der Zelle aus.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleRightAlignment": "Rechts Zentrieren",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleRightAlignmentDescription": "Zentriere Text vertikal und richte Ihn auf die rechte Seite der Zelle aus.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsOutsideBorder": "Äusserer Rand",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsOutsideBorderDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsRightBorder": "Rechter Rand",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsRightBorderDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopBorder": "Oberer Rand",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopBorderDescription": "Passen Sie die Ränder der ausgewählten Zellen an.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopCenterAlignment": "Zentriert nach oben Ausrichten",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopCenterAlignmentDescription": "Zentriere Text und richte ihn auf die obere Seite der Zelle aus.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopLeftAlignment": "Zentriert Oben Links",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopLeftAlignmentDescription": "Zentriere Text und richte Ihn auf die obere linke Seite der Zelle aus.",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopRightAlignment": "Zentriert Oben Rechts",
    "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopRightAlignmentDescription": "Zentriere Text und richte Ihn auf die obere rechte Seite der Zelle aus.",
    "XtraRichEditStringId.MenuCmd_ToggleTableFixedColumnWidth": "Feste Spaltenbreite",
    "XtraRichEditStringId.MenuCmd_ToggleTableFixedColumnWidthDescription": "Setzt die Tabellengrösse auf eine fixe Breite.",
    "XtraRichEditStringId.MenuCmd_ToggleTextCase": "gROSS-/kLEINSCHREIBUNG umkehren",
    "XtraRichEditStringId.MenuCmd_ToggleTextCaseDescription": "gROSS-/kLEINSCHREIBUNG umkehren.",
    "XtraRichEditStringId.MenuCmd_ToggleViewMergedData": "Vorschau Seriendruck",
    "XtraRichEditStringId.MenuCmd_ToggleViewMergedDataDescription": "Ersetzt die Seriendruckfelder in Ihrem Dokument mit aktuellen Daten aus Ihrer Empfänger so dass Sie sehen können, wie es aussieht.",
    "XtraRichEditStringId.MenuCmd_ToggleWhitespace": "¶-Zeichen zeigen/verstecken",
    "XtraRichEditStringId.MenuCmd_ToggleWhitespaceDescription": "Macht die Absatzmarken und andere versteckte Formatierungszeichen sichtbar oder verbirgt sie.",
    "XtraRichEditStringId.MenuCmd_TrackChanges": "Änderungen nachverfolgen",
    "XtraRichEditStringId.MenuCmd_TrackChangesDescription": "Am Dokument vorgenommene Änderungen nachverfolgen.",
    "XtraRichEditStringId.MenuCmd_Undo": "Rückgängig",
    "XtraRichEditStringId.MenuCmd_UndoDescription": "Rückgängig",
    "XtraRichEditStringId.MenuCmd_UnlockField": "Unlock Field",
    "XtraRichEditStringId.MenuCmd_UnlockFieldDescription": "Unlock Field",
    "XtraRichEditStringId.MenuCmd_UnprotectDocument": "Ungeschütztes Dokument",
    "XtraRichEditStringId.MenuCmd_UnprotectDocumentDescription": "Ungeschütztes Dokument",
    "XtraRichEditStringId.MenuCmd_UpdateField": "Feld aktualisieren",
    "XtraRichEditStringId.MenuCmd_UpdateFieldDescription": "Feld aktualisieren",
    "XtraRichEditStringId.MenuCmd_UpdateFields": "Aktualisierungs Felder",
    "XtraRichEditStringId.MenuCmd_UpdateFieldsDescription": "Aktualisierungs Felder",
    "XtraRichEditStringId.MenuCmd_UpdateTableOfContents": "Aktualisierungs Tabelle",
    "XtraRichEditStringId.MenuCmd_UpdateTableOfContentsDescription": "Aktualisieren Sie die Inhaltstabelle, so dass sich alle Einträge auf die richtige Seitenzahl beziehen.",
    "XtraRichEditStringId.MenuCmd_UpdateTableOfFigures": "Tabelle aktualisieren",
    "XtraRichEditStringId.MenuCmd_UpdateTableOfFiguresDescription": "Aktualisiert die Tabelle Zahlen, um alle Einträge in das Dokument einzufügen.",
    "XtraRichEditStringId.MenuCmd_Zoom": "Zoom",
    "XtraRichEditStringId.MenuCmd_ZoomDescription": "Zoom",
    "XtraRichEditStringId.MenuCmd_ZoomIn": "Vergrößern",
    "XtraRichEditStringId.MenuCmd_ZoomInDescription": "Vergrößern",
    "XtraRichEditStringId.MenuCmd_ZoomOut": "Verkleinern",
    "XtraRichEditStringId.MenuCmd_ZoomOutDescription": "Verkleinern",
    "XtraRichEditStringId.Msg_BookmarkCreationFailing": "Es existiert bereits ein Lesezeichen mit dem selben Namen. Soll es ersetzt werden?",
    "XtraRichEditStringId.Msg_CantDeleteDefaultStyle": "Kann den Standardstil nicht löschen.",
    "XtraRichEditStringId.Msg_CantPasteFormField": "You cannot paste form fields into comments, headers, footers, footnotes, endnotes, or text boxes.",
    "XtraRichEditStringId.Msg_CantResetDefaultProperties": "Kann die ursprünglichen Standardstil Einstellungen nicht zurücksetzen.",
    "XtraRichEditStringId.Msg_ClickToComment": " commented:",
    "XtraRichEditStringId.Msg_ClickToFollowHyperlink": "Klicken um Link zu folgen",
    "XtraRichEditStringId.Msg_ContinueSearchFromBeginningQuestion": "Wollen Sie die Suche am Anfang des Dokuments beginnen?",
    "XtraRichEditStringId.Msg_ContinueSearchFromEndQuestion": "Wollen Sie die Suche vom Ende des Dokuments an rückwärts vornehmen?",
    "XtraRichEditStringId.Msg_ContinueSearchInRemainderQuestion": "Wollen Sie die Suche für den verbleibenden Text durchführen?",
    "XtraRichEditStringId.Msg_CreateHyperlinkError": "Der Hyperlink kann nicht erstellt werden. Der Hyperlink existiert bereits im angegebenen Bereich.",
    "XtraRichEditStringId.Msg_DeleteTableStyleQuestion": "Möchten Sie Stil {0} aus dem Dokument löschen?",
    "XtraRichEditStringId.Msg_DocumentPositionDoesntMatchDocument": "Fehler: angegebene Dokumentposition oder Bereich gehört zu einem anderen Dokument oder Filialdokument",
    "XtraRichEditStringId.Msg_DocumentProtectionInvalidPassword": "Das Passwort ist falsch!",
    "XtraRichEditStringId.Msg_DocumentProtectionInvalidPasswordConfirmation": "Die Passwortbestätigung stimmt nicht überein.",
    "XtraRichEditStringId.Msg_DuplicateBookmark": "Lesezeichen mit diesem Namen existiert bereits in dem Dokument",
    "XtraRichEditStringId.Msg_EncryptedFile": "Verschlüsselte Dateien werden derzeit nicht unterstützt.",
    "XtraRichEditStringId.Msg_EncryptedFileIntegrityCheckFailed": "This file may have been tampered with or corrupted and the contents should not be trusted. Do you wish to continue opening this file?",
    "XtraRichEditStringId.Msg_ErrorInvalidCustomPropertyValue": "The value entered does not match the specified type. The value will be stored as text.",
    "XtraRichEditStringId.Msg_ErrorLinkDeletedStyle": "Fehler: Die Verlinkung mit einem gelöschten Stil ist nicht möglich.",
    "XtraRichEditStringId.Msg_IncorrectBookmarkName": "Der Name des Lesezeichens muss mit einem Buchstaben beginnen und darf nur Buchstaben, Ziffern und Unterstriche enthalten.",
    "XtraRichEditStringId.Msg_IncorrectNumericFieldFormat": "Fehler: Die Zahl kann im angegebenen Format nicht dargestellt werden.",
    "XtraRichEditStringId.Msg_IncorrectPassword": "The password you supplied is not correct. Verify that the CAPS LOCK key is off and be sure to use the correct capitalization.",
    "XtraRichEditStringId.Msg_IncorrectPattern": "Das Muster ist falsch.",
    "XtraRichEditStringId.Msg_IntegrityCheckFailedError": "Encrypted file integrity check failed!\nThis file may have been tampered with or corrupted.",
    "XtraRichEditStringId.Msg_InternalError": "Ein interner Fehler ist aufgetreten.",
    "XtraRichEditStringId.Msg_InvalidBeginInit": "Fehler: Aufruf der Prozedur BeginInit innerhalb der Prozedur BeginUpdate",
    "XtraRichEditStringId.Msg_InvalidBeginUpdate": "Fehler: Aufruf der Prozedur BeginUpdate innerhalb der Prozedur BeginInit",
    "XtraRichEditStringId.Msg_InvalidCopyFromDocumentModel": "Fehler: Die Dateistrukturen des Quell- und Zieldokuments sind verschieden.",
    "XtraRichEditStringId.Msg_InvalidDictionaryFormat": "The dictionary format is incorrect.",
    "XtraRichEditStringId.Msg_InvalidDivisor": "Die Zahl muss ein Teiler von {0} sein.",
    "XtraRichEditStringId.Msg_InvalidDocumentModel": "Fehler: Unterschiedliche Dokumentenstrukturen.",
    "XtraRichEditStringId.Msg_InvalidEndInit": "Fehler: Aufruf der Prozedur EndInit oder CancelInit ohne vorangehendem Aufruf von BeginInit oder innerhalb der Prozedur BeginUpdate",
    "XtraRichEditStringId.Msg_InvalidEndUpdate": "Fehler: Aufruf der Prozedur EndUpdate oder CancelUpate ohne Aufruf der Pozedur BeginUpdate innerhalb von BeginInit",
    "XtraRichEditStringId.Msg_InvalidFontSize": "Die Zahl muss zwischen {0} und {1} liegen.",
    "XtraRichEditStringId.Msg_InvalidHeaderFooterOffset": "Die Messung muss zwischen {0} und {1} sein.",
    "XtraRichEditStringId.Msg_InvalidHtmlImagePath": "The specified image path does not exist.",
    "XtraRichEditStringId.Msg_InvalidImageFile": "The specified image is not valid.",
    "XtraRichEditStringId.Msg_InvalidNavigateUri": "Auf die angegebene URI kann nicht zugegriffen werden.",
    "XtraRichEditStringId.Msg_InvalidNumber": "Dies ist keine gültige Zahl.",
    "XtraRichEditStringId.Msg_InvalidNumberingListIndex": "NumberingListIndex bezieht sich auf eine Liste, die nicht vorhanden ist.",
    "XtraRichEditStringId.Msg_InvalidNumberingListStartAtValue": "'Start' muss zwischen {0} und {1} liegen für diese Format",
    "XtraRichEditStringId.Msg_InvalidParagraphContainNumbering": "Fehler: Der Absatz enthält bereits eine Nummerierung.",
    "XtraRichEditStringId.Msg_InvalidParentStyle": "Fehler: Eine ungültige Zuweisung eines 'parent style' hat eine zirkuläre Referenz verursacht.",
    "XtraRichEditStringId.Msg_InvalidSetCharacterProperties": "Fehler: Die Attribute können nicht ohne Aufruf der Prozedur BeginInit oder ohne Hinzufügung eines Objekts zum Dokument gesetzt werden.",
    "XtraRichEditStringId.Msg_InvalidSetParagraphProperties": "Fehler: Die Attribute können nicht ohne Aufruf der Prozedur BeginInit oder ohne Hinzufügung eines Objekts zum Dokument gesetzt werden.",
    "XtraRichEditStringId.Msg_InvalidStyleName": "Ungültiger Stilname",
    "XtraRichEditStringId.Msg_InvalidTabStop": "Dies ist kein gültiger Tabulator.",
    "XtraRichEditStringId.Msg_InvalidValueRange": "Der Wert muss zwischen {0} und {1} liegen.",
    "XtraRichEditStringId.Msg_IsNotValid": "'{0}' ist kein gültiger Wert für '{1}'",
    "XtraRichEditStringId.Msg_LeftRightSectionMarginsTooLarge": "Die Links/rechts-Ränder sind zu groß für die Seitenhöhe in einigen Abschnitten.",
    "XtraRichEditStringId.Msg_Loading": "Laden...",
    "XtraRichEditStringId.Msg_MagicNumberNotFound": "Die Datei, die Sie versuchen zu öffnen, ist in anderen Format als durch die Dateierweiterung angegebene.",
    "XtraRichEditStringId.Msg_MenuManagerPropertyIsNotSet": "The MenuManager property of RichEditControl is not set.",
    "XtraRichEditStringId.Msg_MissingOperator": "!Fehlender Operator",
    "XtraRichEditStringId.Msg_NoDefaultTabs": "Standard tab stops können nicht gestzt werden.",
    "XtraRichEditStringId.Msg_NoTocEntriesFound": "Keine Tabelleninhalt Einträge gefunden.",
    "XtraRichEditStringId.Msg_NoTrackedChanges": "There aren't any tracked changes in your document.",
    "XtraRichEditStringId.Msg_NumberingListNotInListCollection": "Kann Nummerierungsliste nicht verwenden. Die Nummerierungsliste muss Document.NumberingLists Sammlung hinzugefügt ",
    "XtraRichEditStringId.Msg_OperationIsNotValidDueDocumentState": "Operation is not valid due to the current state of the document.",
    "XtraRichEditStringId.Msg_ParagraphStyleNameAlreadyExists": "Der Stilname existiert bereits.",
    "XtraRichEditStringId.Msg_PasswordNotConfirmed": "Confirmation password is not identical.",
    "XtraRichEditStringId.Msg_PasswordRequiredError": "Password required to open this file!",
    "XtraRichEditStringId.Msg_PasswordTooLong": "Password length must be less than {0} characters long.",
    "XtraRichEditStringId.Msg_PrintingUnavailable": "Drucken ist nicht möglich. Prüfen Sie, ob folgende Assemblys installiert sind:\n{0}\n{1}",
    "XtraRichEditStringId.Msg_ReplacementsCount": "{0} Ersetzungen wurden durchgeführt.",
    "XtraRichEditStringId.Msg_Saving": "Speicherng...",
    "XtraRichEditStringId.Msg_SearchComplete": "Die Suche ist beendet.",
    "XtraRichEditStringId.Msg_SearchInBackwardDirectionComplete": "Der Anfang des Dokuments wurde erreicht.",
    "XtraRichEditStringId.Msg_SearchInForwardDirectionComplete": "Das Ende des Dokuments wurde erreicht.",
    "XtraRichEditStringId.Msg_SearchInSelectionComplete": "Die Suche im markierten Text ist beendet.",
    "XtraRichEditStringId.Msg_SearchItemNotFound": "Der Suchbegriff wurde nicht gefunden.",
    "XtraRichEditStringId.Msg_SelectBookmarkError": "Kann kein Lesezeichen für ein inaktives SubDocument auswählen.",
    "XtraRichEditStringId.Msg_SelectCommentError": "Cannot select a comment of inactive SubDocument.",
    "XtraRichEditStringId.Msg_StyleAlreadyLinked": "Fehler: Der Stil enthält bereits einen Stilverweis (Link) ",
    "XtraRichEditStringId.Msg_StyleNameIsEmpty": "The style name cannot be empty.",
    "XtraRichEditStringId.Msg_SyntaxErrorInFieldPattern": "Syntax Fehler, {0}.",
    "XtraRichEditStringId.Msg_TableExceedsMaximumNumberOfColumns": "Dies überschreitet die maximale Anzahl von Spalten.",
    "XtraRichEditStringId.Msg_TableIntegrityError": "Fehler: Die Tabellenintegrität ist zerstört.",
    "XtraRichEditStringId.Msg_TopBottomSectionMarginsTooLarge": "Die obere/untere Ränder sind zu groß für die Seitenhöhe in einigen Abschnitten.",
    "XtraRichEditStringId.Msg_UnexpectedEndOfFormula": "!Unerwartetes Ende der Formel",
    "XtraRichEditStringId.Msg_UnknownSwitchArgument": "Fehler! Unbekanntes switch Argument",
    "XtraRichEditStringId.Msg_UnmatchedQuotesInFieldPattern": "Fehler: Die Suchzeichenfolge enthält nicht als solche gekennzeichnete Anführungszeichen.",
    "XtraRichEditStringId.Msg_UnsupportedDocVersion": "Nur MS Word 97 und spätere Versionen werden unterstützt",
    "XtraRichEditStringId.Msg_UnsupportedFormatException": "Dieses Dateiformat wird nicht unterstützt.",
    "XtraRichEditStringId.Msg_UseDeletedBookmarkError": "Fehler: gelöschtes Lesezeichen wird verwendet",
    "XtraRichEditStringId.Msg_UseDeletedCommentError": "Error: using deleted comment",
    "XtraRichEditStringId.Msg_UseDeletedCustomMarkError": "Error: using deleted customMark",
    "XtraRichEditStringId.Msg_UseDeletedFieldError": "Fehler: Feld im Absatz wird benutzt.",
    "XtraRichEditStringId.Msg_UseDeletedFormFieldError": "Error: using deleted form field",
    "XtraRichEditStringId.Msg_UseDeletedHyperlinkError": "Fehler: gelöschter Hyperlink wird verwendet",
    "XtraRichEditStringId.Msg_UseDeletedParagraphError": "Fehler: Zugriff auf einen gelöschten Absatz.",
    "XtraRichEditStringId.Msg_UseDeletedSectionError": "Fehler: Zugriff auf einen gelöschten Abschnitt.",
    "XtraRichEditStringId.Msg_UseDeletedStyleError": "Fehler: Zugriff auf einen gelöschten Stil.",
    "XtraRichEditStringId.Msg_UseDeletedTableCellError": "Fehlerr: Verwendung gelöschter Tabellenzelle",
    "XtraRichEditStringId.Msg_UseDeletedTableError": "Fehler: Verwendung gelöschter Tabelle",
    "XtraRichEditStringId.Msg_UseDeletedTableRowError": "Fehler: Verwendung gelöschter Tabellezeile",
    "XtraRichEditStringId.Msg_UsedWrongUnit": "Es wurde keine korrekte Maßeinheit angegeben.",
    "XtraRichEditStringId.Msg_UseInvalidCharacterProperties": "Fehler: Die Formateigenschaften der Zeichen sind nicht mehr gültig.",
    "XtraRichEditStringId.Msg_UseInvalidCompatibilitySettings": "Fehler: Diese Kompatibilitätseinstellungen sind nicht mehr gültig",
    "XtraRichEditStringId.Msg_UseInvalidCustomProperties": "Error: this custom properties is no longer valid",
    "XtraRichEditStringId.Msg_UseInvalidDocument": "Fehler: Dieses Dokument ist nicht mehr gültig",
    "XtraRichEditStringId.Msg_UseInvalidDocumentEncryption": "Error: these document encryption settings are no longer valid",
    "XtraRichEditStringId.Msg_UseInvalidDocumentProperties": "Error: this document properties is no longer valid",
    "XtraRichEditStringId.Msg_UseInvalidDocumentTrackChangesOptions": "Error: these document track changes options are no longer valid",
    "XtraRichEditStringId.Msg_UseInvalidParagraphProperties": "Fehler: Die Formateigenschaften des Absatzes sind nicht mehr gültig.",
    "XtraRichEditStringId.Msg_VariableDeletedOrMissed": "Fehler: Die Dokumentenvariable fehlt oder ist gelöscht (aus Variablenauswahl)",
    "XtraRichEditStringId.Msg_WrongPasswordError": "Password check failed!",
    "XtraRichEditStringId.Msg_ZeroDivide": "!Null geteilt",
    "XtraRichEditStringId.MsgWinOnlyLimitation": "This action can be performed only on Windows operating system.",
    "XtraRichEditStringId.No": "N",
    "XtraRichEditStringId.Page": "Seite {2}: [{3}]",
    "XtraRichEditStringId.RtlComment": "[{1} {0}] Comment",
    "XtraRichEditStringId.SelectionCollection_AtLeastOneSelectionIsRequiredException": "Cannot remove a selection. At least one selection is required.",
    "XtraRichEditStringId.SelectionCollection_CannotRemoveCaretException": "Caret-Zeichen kann nicht entfernt werden.",
    "XtraRichEditStringId.SelectionCollection_CurrentSelectionAndSpecifiedSelectionIntersectException": "Current selection and the specified selection intersect.",
    "XtraRichEditStringId.SelectionCollection_EmptyCollectionException": "Eine leere Auflistung kann nicht hinzugefügt werden.",
    "XtraRichEditStringId.SelectionCollection_FirstCellContinuesVerticalMergeException": "The first cell in the selected range continues the vertical merge, which is not allowed in a selection collection.",
    "XtraRichEditStringId.SelectionCollection_LastCellContinuesVerticalMergeException": "The last cell in the selected range continues the vertical merge, which is not allowed in a selection collection.",
    "XtraRichEditStringId.SelectionCollection_OutOfRangeException": "Index was out of range. Must be non-negative and less than the size of the selection collection.",
    "XtraRichEditStringId.SelectionCollection_PartiallySelectedCellsException": "Teilweise markierten Zellen sind nicht zulässig.",
    "XtraRichEditStringId.SelectionCollection_RangeCannotBeEmptyException": "Range cannot be empty.",
    "XtraRichEditStringId.SelectionCollection_SelecitonShouldIncludeNotMoreThanOneRowException": "A selection range should include not more than one row",
    "XtraRichEditStringId.SelectionCollection_SelectionCollectionEmptyException": "Die Auswahl-Auflistung ist leer.",
    "XtraRichEditStringId.SelectionCollection_SelectionExtendsOutsideTableException": "Die Auswahl erstreckt sich außerhalb der Tabelle, so dass die gesamte Zeile ausgewählt werden muss.",
    "XtraRichEditStringId.SelectionCollection_SelectionShouldContainAtLeastOneCharacterException": "Die Auswahl sollte mindestens ein Zeichen enthalten.",
    "XtraRichEditStringId.SelectionCollection_SpecifiedSelectionsIntersectException": "Specified selections intersect.",
    "XtraRichEditStringId.TabForm_All": "Alle",
    "XtraRichEditStringId.TargetFrameDescription_Blank": "Neues Fenster",
    "XtraRichEditStringId.TargetFrameDescription_Parent": "Übergeordneter Rahmen",
    "XtraRichEditStringId.TargetFrameDescription_Self": "Selber Rahmen",
    "XtraRichEditStringId.TargetFrameDescription_Top": "Ganze Seite",
    "XtraRichEditStringId.UnderlineNone": "(keiner)",
    "XtraRichEditStringId.UserGroup_Administrators": "Administrators",
    "XtraRichEditStringId.UserGroup_Contributors": "Contributors",
    "XtraRichEditStringId.UserGroup_CurrentUser": "Current User",
    "XtraRichEditStringId.UserGroup_Editors": "Editors",
    "XtraRichEditStringId.UserGroup_Everyone": "Everyone",
    "XtraRichEditStringId.UserGroup_Owners": "Owners",
    "XtraRichEditStringId.Yes": "Y",
    "RichEditExtensionsStringId.Caption_ColorAutomatic": "Automatisch",
    "RichEditExtensionsStringId.Caption_Formatting": "Formatieren",
    "RichEditExtensionsStringId.Caption_GroupCaptions": "Beschriftungen",
    "RichEditExtensionsStringId.Caption_GroupClipboard": "Zwischenablage",
    "RichEditExtensionsStringId.Caption_GroupCommon": "Allgemein",
    "RichEditExtensionsStringId.Caption_GroupDocumentComment": "Kommentar",
    "RichEditExtensionsStringId.Caption_GroupDocumentLanguage": "Sprache",
    "RichEditExtensionsStringId.Caption_GroupDocumentProofing": "Dokumentprüfung",
    "RichEditExtensionsStringId.Caption_GroupDocumentProtection": "Schützen",
    "RichEditExtensionsStringId.Caption_GroupDocumentTracking": "Tracking",
    "RichEditExtensionsStringId.Caption_GroupDocumentViews": "Dokumentenansichten",
    "RichEditExtensionsStringId.Caption_GroupEditing": "Bearbeiten",
    "RichEditExtensionsStringId.Caption_GroupFloatingPictureToolsArrange": "Ordnen",
    "RichEditExtensionsStringId.Caption_GroupFloatingPictureToolsShapeStyles": "Formenarten",
    "RichEditExtensionsStringId.Caption_GroupFont": "Schriftart",
    "RichEditExtensionsStringId.Caption_GroupHeaderFooter": "Kopf- && Fußzeilen",
    "RichEditExtensionsStringId.Caption_GroupHeaderFooterToolsDesignClose": "Schließen",
    "RichEditExtensionsStringId.Caption_GroupHeaderFooterToolsDesignNavigation": "Navigation",
    "RichEditExtensionsStringId.Caption_GroupHeaderFooterToolsDesignOptions": "Optionen",
    "RichEditExtensionsStringId.Caption_GroupIllustrations": "Illustrationen",
    "RichEditExtensionsStringId.Caption_GroupInfo": "Info",
    "RichEditExtensionsStringId.Caption_GroupLinks": "Hyperlinks",
    "RichEditExtensionsStringId.Caption_GroupMailMerge": "Serienbriefe",
    "RichEditExtensionsStringId.Caption_GroupPageBackground": "Seitenhintergrund",
    "RichEditExtensionsStringId.Caption_GroupPages": "Seiten",
    "RichEditExtensionsStringId.Caption_GroupPageSetup": "Seite einrichten",
    "RichEditExtensionsStringId.Caption_GroupParagraph": "Absatz",
    "RichEditExtensionsStringId.Caption_GroupShow": "Anzeigen",
    "RichEditExtensionsStringId.Caption_GroupStyles": "Stile",
    "RichEditExtensionsStringId.Caption_GroupSymbols": "Symbole",
    "RichEditExtensionsStringId.Caption_GroupTableOfContents": "Inhaltsverzeichnis",
    "RichEditExtensionsStringId.Caption_GroupTables": "Tabellen",
    "RichEditExtensionsStringId.Caption_GroupText": "Text",
    "RichEditExtensionsStringId.Caption_GroupZoom": "Zoom",
    "RichEditExtensionsStringId.Caption_NoColor": "Keine Farbe",
    "RichEditExtensionsStringId.Caption_NoFill": "Keine Füllung",
    "RichEditExtensionsStringId.Caption_NoOutline": "Kein Rahmen",
    "RichEditExtensionsStringId.Caption_PageCategoryFloatingPictureTools": "Bildtools",
    "RichEditExtensionsStringId.Caption_PageCategoryHeaderFooterTools": "Kopf- && Fußzeilentools",
    "RichEditExtensionsStringId.Caption_PageFile": "Datei",
    "RichEditExtensionsStringId.Caption_PageFloatingObjectPictureToolsFormat": "Format",
    "RichEditExtensionsStringId.Caption_PageHeaderFooterToolsDesign": "Entwurf",
    "RichEditExtensionsStringId.Caption_PageHome": "Start",
    "RichEditExtensionsStringId.Caption_PageInsert": "Einfügen",
    "RichEditExtensionsStringId.Caption_PageLayout": "Layout",
    "RichEditExtensionsStringId.Caption_PageMailings": "Serienbriefe",
    "RichEditExtensionsStringId.Caption_PagePageLayout": "Seitenlayout",
    "RichEditExtensionsStringId.Caption_PageReferences": "Verweise",
    "RichEditExtensionsStringId.Caption_PageReview": "Überprüfung",
    "RichEditExtensionsStringId.Caption_PageView": "Ansicht",
    "ASPxRichEditStringId.Font": "Schriftart",
    "ASPxRichEditStringId.FontStyle": "Schriftstil",
    "ASPxRichEditStringId.FontSize": "Schriftgrad",
    "ASPxRichEditStringId.FontColor": "Schriftfarbe",
    "ASPxRichEditStringId.UnderlineStyle": "Unterstreichungstil",
    "ASPxRichEditStringId.UnderlineColor": "Unterstreichungsfarbe",
    "ASPxRichEditStringId.Effects": "Effekt",
    "ASPxRichEditStringId.AllCaps": "All caps",
    "ASPxRichEditStringId.Hidden": "Ausgeblendet",
    "ASPxRichEditStringId.Normal": "Normal",
    "ASPxRichEditStringId.Bold": "Fett",
    "ASPxRichEditStringId.Italic": "Kursiv",
    "ASPxRichEditStringId.BoldItalic": "Fett kursiv",
    "ASPxRichEditStringId.UnderlineWordsOnly": "Nur Wörter unterstreichen",
    "ASPxRichEditStringId.UnderlineType_None": "Keine",
    "ASPxRichEditStringId.UnderlineType_Single": "Einfach",
    "ASPxRichEditStringId.UnderlineType_Dotted": "Punktiert",
    "ASPxRichEditStringId.UnderlineType_Dashed": "Gestrichelt",
    "ASPxRichEditStringId.UnderlineType_DashDotted": "Strich punktiert",
    "ASPxRichEditStringId.UnderlineType_DashDotDotted": "Strich-Punkt punktiert",
    "ASPxRichEditStringId.UnderlineType_Double": "Doppelt",
    "ASPxRichEditStringId.UnderlineType_HeavyWave": "Schwere Welle",
    "ASPxRichEditStringId.UnderlineType_LongDashed": "Lang gestrichelt",
    "ASPxRichEditStringId.UnderlineType_ThickSingle": "Fett einfach",
    "ASPxRichEditStringId.UnderlineType_ThickDotted": "Thick Dotted",
    "ASPxRichEditStringId.UnderlineType_ThickDashed": "Thick Dashed",
    "ASPxRichEditStringId.UnderlineType_ThickDashDotted": "Thick Dash Dotted",
    "ASPxRichEditStringId.UnderlineType_ThickDashDotDotted": "Thick Dash Dot Dotted",
    "ASPxRichEditStringId.UnderlineType_ThickLongDashed": "Thick Long Dashed",
    "ASPxRichEditStringId.UnderlineType_DoubleWave": "Doppelte Welle",
    "ASPxRichEditStringId.UnderlineType_Wave": "Welle",
    "ASPxRichEditStringId.UnderlineType_DashSmallGap": "Dash Small Gap",
    "ASPxRichEditStringId.StrikeoutType_None": "Keine",
    "ASPxRichEditStringId.StrikeoutType_Single": "Einfach",
    "ASPxRichEditStringId.StrikeoutType_Double": "Doppelt",
    "ASPxRichEditStringId.CharacterFormattingScript_Normal": "Normal",
    "ASPxRichEditStringId.CharacterFormattingScript_Subscript": "Tiefgestellt",
    "ASPxRichEditStringId.CharacterFormattingScript_Superscript": "Hochgestellt",
    "ASPxRichEditStringId.ParagraphTab0": "Einzüge und Abstände",
    "ASPxRichEditStringId.ParagraphTab1": "Zeilen- und Seitenumbruch",
    "ASPxRichEditStringId.General": "Allgemein",
    "ASPxRichEditStringId.Alignment": "Ausrichtung",
    "ASPxRichEditStringId.Left": "Links",
    "ASPxRichEditStringId.Right": "Rechts",
    "ASPxRichEditStringId.Centered": "Zentriert",
    "ASPxRichEditStringId.Justified": "Blocksatz",
    "ASPxRichEditStringId.OutlineLevel": "Gliederungsebene",
    "ASPxRichEditStringId.BodyText": "Textkörper",
    "ASPxRichEditStringId.Level": "Niveau",
    "ASPxRichEditStringId.Indentation": "Einzug",
    "ASPxRichEditStringId.Special": "Spezial",
    "ASPxRichEditStringId.By": "Nach",
    "ASPxRichEditStringId.none": "(keine)",
    "ASPxRichEditStringId.FirstLine": "Erste Zeile",
    "ASPxRichEditStringId.Hanging": "Hängend",
    "ASPxRichEditStringId.Spacing": "Abstand",
    "ASPxRichEditStringId.Before": "Vor",
    "ASPxRichEditStringId.LineSpacing": "Zeilenabstand",
    "ASPxRichEditStringId.At": "Bei",
    "ASPxRichEditStringId.After": "Nach",
    "ASPxRichEditStringId.Single": "Einfach",
    "ASPxRichEditStringId.spacing_1_5_lines": "1,5 Zeilen",
    "ASPxRichEditStringId.Double": "Doppelt",
    "ASPxRichEditStringId.Multiple": "Mehrfach",
    "ASPxRichEditStringId.Exactly": "Genau",
    "ASPxRichEditStringId.AtLeast": "Mindestens",
    "ASPxRichEditStringId.NoSpace": "Fügen Sie keine Leerzeichen zwischen Absatz von der gleichen Art",
    "ASPxRichEditStringId.Pagination": "Paginierung",
    "ASPxRichEditStringId.KLT": "Diesen Absatz zusammenhalten",
    "ASPxRichEditStringId.PBB": "Seitenumbruch",
    "ASPxRichEditStringId.Options": "Optionen",
    "ASPxRichEditStringId.Preview": "Vorschau",
    "ASPxRichEditStringId.Style": "Stil",
    "ASPxRichEditStringId.Color": "Farbe",
    "ASPxRichEditStringId.SaveToServer": "Save file to the server",
    "ASPxRichEditStringId.DownloadCopy": "Eine Kopie herunterladen",
    "ASPxRichEditStringId.FolderPath": "Ordnerpfad",
    "ASPxRichEditStringId.FileType": "Dateityp",
    "ASPxRichEditStringId.FileName": "Dateiname",
    "ASPxRichEditStringId.ChooseType": "Choose download file type",
    "ASPxRichEditStringId.SaveFileAs": "Save file as",
    "ASPxRichEditStringId.Invalid_FileName": "Ungültiger Dateiname",
    "ASPxRichEditStringId.PathDoesNotExist": "Path does not exist.\nCheck the path and try again.",
    "ASPxRichEditStringId.Portrait": "Hochformat",
    "ASPxRichEditStringId.Landscape": "Querformat",
    "ASPxRichEditStringId.Continuous": "Fortlaufend",
    "ASPxRichEditStringId.NewPage": "Neue Seite",
    "ASPxRichEditStringId.EvenPage": "Gerade Seite",
    "ASPxRichEditStringId.OddPage": "Ungerade Seite",
    "ASPxRichEditStringId.WholeDocument": "Gesamtes Dokument",
    "ASPxRichEditStringId.CurrentSection": "Aktueller Abschnitt",
    "ASPxRichEditStringId.SelectedSections": "Markierte Abschnitte",
    "ASPxRichEditStringId.ThisPointForward": "Dokument ab hier",
    "ASPxRichEditStringId.Margins": "Ränder",
    "ASPxRichEditStringId.Paper": "Papier",
    "ASPxRichEditStringId.Layout": "Layout",
    "ASPxRichEditStringId.Top": "Oben",
    "ASPxRichEditStringId.Bottom": "Unten",
    "ASPxRichEditStringId.Orientation": "Orientierung",
    "ASPxRichEditStringId.PaperSize": "Papierformat",
    "ASPxRichEditStringId.Width": "Breite",
    "ASPxRichEditStringId.Height": "Höhe",
    "ASPxRichEditStringId.Section": "Abschnitt",
    "ASPxRichEditStringId.SectionStart": "Abschnittsbeginn",
    "ASPxRichEditStringId.HeadersAndFooters": "Kopf- und Fußzeilen",
    "ASPxRichEditStringId.DifferentOddAndEven": "Gerade/ungerade anders",
    "ASPxRichEditStringId.DifferentFirstPage": "Erste Seite anders",
    "ASPxRichEditStringId.ApplyTo": "Papierzufuhr:",
    "ASPxRichEditStringId.FromEdge": "Vom Rand",
    "ASPxRichEditStringId.Header": "Kopfzeile",
    "ASPxRichEditStringId.Footer": "Fußzeile",
    "ASPxRichEditStringId.CancelButton": "Abbrechen",
    "ASPxRichEditStringId.OkButton": "OK",
    "ASPxRichEditStringId.OpenButton": "Öffnen",
    "ASPxRichEditStringId.SaveAsButton": "Speichern",
    "ASPxRichEditStringId.DownloadButton": "Download",
    "ASPxRichEditStringId.SelectButton": "Auswählen",
    "ASPxRichEditStringId.InsertButton": "Einfügen",
    "ASPxRichEditStringId.ChangeButton": "Ändern",
    "ASPxRichEditStringId.CloseButton": "Schließen",
    "ASPxRichEditStringId.InsertTable_TableSize": "Tabellengröße",
    "ASPxRichEditStringId.InsertTable_NumberOfColumns": "Anzahl der Spalten",
    "ASPxRichEditStringId.InsertTable_NumberOfRows": "Anzahl der Zeilen",
    "ASPxRichEditStringId.SplitCells_MergeCells": "Zellen vor dem Teilen zusammenführen",
    "ASPxRichEditStringId.InsertCells_ShiftCellsRight": "Zellen nach rechts verschieben",
    "ASPxRichEditStringId.InsertCells_ShiftCellsDown": "Zellen nach unten verschieben",
    "ASPxRichEditStringId.InsertCells_InsertEntireRow": "Ganze Zeile einfügen",
    "ASPxRichEditStringId.InsertCells_InsertEntireColumn": "Ganze Spalte einfügen",
    "ASPxRichEditStringId.DeleteCells_ShiftCellsLeft": "Zellen nach links verschieben",
    "ASPxRichEditStringId.DeleteCells_ShiftCellsUp": "Zellen nach oben verschieben",
    "ASPxRichEditStringId.DeleteCells_DeleteEntireRow": "Ganze Zeile löschen",
    "ASPxRichEditStringId.DeleteCells_DeleteEntireColumn": "Ganze Spalte löschen",
    "ASPxRichEditStringId.InsertImage_FromWeb": "Aus dem Web (URL)",
    "ASPxRichEditStringId.InsertImage_FromLocal": "Von Ihrem Computer",
    "ASPxRichEditStringId.InsertImage_EnterUrl": "Enter image web address",
    "ASPxRichEditStringId.InsertImage_UploadInstructions": "Browse your computer for the image file to upload",
    "ASPxRichEditStringId.InsertImage_ImagePreview": "Bildvorschau",
    "ASPxRichEditStringId.TableProperties_Size": "Größe",
    "ASPxRichEditStringId.TableProperties_Alignment": "Ausrichtung",
    "ASPxRichEditStringId.TableProperties_VerticalAlignment": "Vertikale Ausrichtung",
    "ASPxRichEditStringId.TableProperties_MeasureIn": "Maßeinheit",
    "ASPxRichEditStringId.TableProperties_PreferredWidth": "Bevorzugte Breite",
    "ASPxRichEditStringId.TableProperties_IndentLeft": "Einzug von links:",
    "ASPxRichEditStringId.TableProperties_SpecifyHeight": "Höhe definieren",
    "ASPxRichEditStringId.TableProperties_RowHeight": "Zeilenhöhe",
    "ASPxRichEditStringId.TableProperties_Table": "Tabelle",
    "ASPxRichEditStringId.TableProperties_Row": "Zeile",
    "ASPxRichEditStringId.TableProperties_Column": "Spalte",
    "ASPxRichEditStringId.TableProperties_Cell": "Zelle",
    "ASPxRichEditStringId.TableProperties_Options": "Optionen...",
    "ASPxRichEditStringId.TableProperties_BordersAndShading": "Rahmen und Schattierung...",
    "ASPxRichEditStringId.TableProperties_PreviousRow": "Vorherige Zeile",
    "ASPxRichEditStringId.TableProperties_NextRow": "Nächste Zeile",
    "ASPxRichEditStringId.TableProperties_PreviousColumn": "Vorherige Spalte",
    "ASPxRichEditStringId.TableProperties_NextColumn": "Nächste Spalte",
    "ASPxRichEditStringId.TableProperties_CantSplit": "Zeilenwechsel auf Seiten zulassen",
    "ASPxRichEditStringId.TableOptions_DefaultCellMargins": "Standardzellenbegrenzungen",
    "ASPxRichEditStringId.TableOptions_DefaultCellSpacing": "Standardzellenabstand",
    "ASPxRichEditStringId.TableOptions_AllowSpacing": "Abstand zwischen Zellen zulassen",
    "ASPxRichEditStringId.TableOptions_AutoResize": "Automatische Größenänderung zulassen",
    "ASPxRichEditStringId.BorderShading_Borders": "Rahmen",
    "ASPxRichEditStringId.BorderShading_Shading": "Schattierung",
    "ASPxRichEditStringId.BorderShading_Fill": "Füllen",
    "ASPxRichEditStringId.BorderShading_BordersDescription": "Diagramm oder Schaltflächen klicken, um Rahmen hinzuzufügen",
    "ASPxRichEditStringId.BorderShading_BordersNone": "Keine",
    "ASPxRichEditStringId.BorderShading_BordersBox": "Rechteck",
    "ASPxRichEditStringId.BorderShading_BordersAll": "Alle",
    "ASPxRichEditStringId.BorderShading_BordersGrid": "Raster",
    "ASPxRichEditStringId.BorderShading_BordersCustom": "Benutzerdefiniert",
    "ASPxRichEditStringId.CellOptions_CellMargins": "Zellenbegrenzungen",
    "ASPxRichEditStringId.CellOptions_SameAsTable": "Genauso wie die gesamte Tabelle",
    "ASPxRichEditStringId.CellOptions_WrapText": "Textfluss",
    "ASPxRichEditStringId.Columns_Presets": "Voreinstellungen",
    "ASPxRichEditStringId.Columns_NumberOfColumns": "Anzahl der Spalten",
    "ASPxRichEditStringId.Columns_WidthAndSpacing": "Breite und Abstand",
    "ASPxRichEditStringId.Columns_EqualWidth": "Gleiche Spaltenbreite",
    "ASPxRichEditStringId.Columns_Col": "Spalte #:",
    "ASPxRichEditStringId.Columns_Width": "Breite",
    "ASPxRichEditStringId.Columns_Spacing": "Abstand",
    "ASPxRichEditStringId.PageFile": "Datei",
    "ASPxRichEditStringId.PageHome": "Startseite",
    "ASPxRichEditStringId.PageInsert": "Einfügen",
    "ASPxRichEditStringId.PagePageLayout": "Seitenlayout",
    "ASPxRichEditStringId.PageReferences": "Verweise",
    "ASPxRichEditStringId.PageMailings": "Seriendruck",
    "ASPxRichEditStringId.PageReview": "Überprüfen",
    "ASPxRichEditStringId.PageView": "Ansicht",
    "ASPxRichEditStringId.PageHeaderAndFooter": "Header && Footer",
    "ASPxRichEditStringId.PageTableDesign": "Design",
    "ASPxRichEditStringId.PageTableLayout": "Layout",
    "ASPxRichEditStringId.PageFloatingObjects": "Format",
    "ASPxRichEditStringId.GroupUndo": "Rückgängig",
    "ASPxRichEditStringId.GroupFont": "Schriftart",
    "ASPxRichEditStringId.GroupParagraph": "Absatz",
    "ASPxRichEditStringId.GroupClipboard": "Zwischenablage",
    "ASPxRichEditStringId.GroupEditing": "Bearbeiten",
    "ASPxRichEditStringId.GroupCommon": "Gemeinsam",
    "ASPxRichEditStringId.GroupStyles": "Stile",
    "ASPxRichEditStringId.GroupZoom": "Zoom",
    "ASPxRichEditStringId.GroupShow": "Anzeigen",
    "ASPxRichEditStringId.GroupIllustrations": "Illustrationen",
    "ASPxRichEditStringId.GroupText": "Text",
    "ASPxRichEditStringId.GroupTables": "Tabellen",
    "ASPxRichEditStringId.GroupSymbols": "Symbole",
    "ASPxRichEditStringId.GroupLinks": "Links",
    "ASPxRichEditStringId.GroupPages": "Seiten",
    "ASPxRichEditStringId.GroupHeaderFooter": "Header && Footer",
    "ASPxRichEditStringId.GroupHeaderFooterToolsDesignClose": "Schließen",
    "ASPxRichEditStringId.GroupHeaderFooterToolsDesignNavigation": "Navigation",
    "ASPxRichEditStringId.GroupMailMerge": "Seriendruck",
    "ASPxRichEditStringId.GroupInsertFields": "Seriendruckfelder einfügen",
    "ASPxRichEditStringId.GroupMailMergeView": "Ansicht",
    "ASPxRichEditStringId.GroupCurrentRecord": "Aktueller Datensatz",
    "ASPxRichEditStringId.GroupFinish": "Fertig stellen",
    "ASPxRichEditStringId.GroupDocumentViews": "Dokumentansichten",
    "ASPxRichEditStringId.GroupHeaderFooterToolsDesignOptions": "Optionen",
    "ASPxRichEditStringId.GroupHeaderFooterToolsDesignPosition": "Position",
    "ASPxRichEditStringId.GroupPageSetup": "Seite einrichten",
    "ASPxRichEditStringId.GroupDocumentProtection": "Schutz",
    "ASPxRichEditStringId.GroupDocumentProofing": "Dokumentprüfung",
    "ASPxRichEditStringId.GroupDocumentComment": "Kommentar",
    "ASPxRichEditStringId.GroupDocumentTracking": "Nachverfolgen",
    "ASPxRichEditStringId.GroupTableOfContents": "Inhaltsverzeichnis",
    "ASPxRichEditStringId.GroupFloatingPictureToolsArrange": "Anordnen",
    "ASPxRichEditStringId.GroupFloatingPictureToolsShapeStyles": "Formenarten",
    "ASPxRichEditStringId.GroupCaptions": "Beschreibungen",
    "ASPxRichEditStringId.GroupPageBackground": "Hintergrund",
    "ASPxRichEditStringId.GroupDocumentLanguage": "Sprache",
    "ASPxRichEditStringId.GroupView": "Ansicht",
    "ASPxRichEditStringId.GroupTableToolsDesignTableStyleOptions": "Optionen für Tabellenformat",
    "ASPxRichEditStringId.GroupTableToolsDesignTableStyles": "Tabellenformatvorlagen",
    "ASPxRichEditStringId.GroupTableToolsDesignBordersAndShadings": "Borders && Shadings",
    "ASPxRichEditStringId.GroupTableToolsLayoutTable": "Tabelle",
    "ASPxRichEditStringId.GroupTableToolsLayoutRowsAndColumns": "Zeilen und Spalten",
    "ASPxRichEditStringId.GroupTableToolsLayoutMerge": "Zusammenführen",
    "ASPxRichEditStringId.GroupTableToolsLayoutCellSize": "Zellengröße",
    "ASPxRichEditStringId.GroupTableToolsLayoutAlignment": "Ausrichtung",
    "ASPxRichEditStringId.GroupFloatingObjectsShapeStyles": "Formenarten",
    "ASPxRichEditStringId.GroupFloatingObjectsArrange": "Anordnen",
    "ASPxRichEditStringId.RequiredFieldError": "Feld ist erforderlich",
    "ASPxRichEditStringId.MenuCmd_ToggleFullScreen": "Ganzer Bildschirm",
    "ASPxRichEditStringId.MenuCmd_ToggleFullScreenDescription": "Enable / disable the full screen mode.",
    "ASPxRichEditStringId.SaveAsFileTitle": "Speichern als",
    "ASPxRichEditStringId.OpenFileTitle": "Öffnen",
    "ASPxRichEditStringId.FontTitle": "Schriftart",
    "ASPxRichEditStringId.ParagraphTitle": "Absatz",
    "ASPxRichEditStringId.PageSetupTitle": "Seite einrichten",
    "ASPxRichEditStringId.ColumnsTitle": "Spalten",
    "ASPxRichEditStringId.InsertTableTitle": "Tabelle einfügen",
    "ASPxRichEditStringId.InsertTableCellsTitle": "Zellen einfügen",
    "ASPxRichEditStringId.DeleteTableCellsTitle": "Zellen löschen",
    "ASPxRichEditStringId.SplitTableCellsTitle": "Zellen teilen",
    "ASPxRichEditStringId.TablePropertiesTitle": "Tabelleneigenschaften",
    "ASPxRichEditStringId.BorderShadingTitle": "Rahmen und Schattierung",
    "ASPxRichEditStringId.InsertMergeFieldTitle": "Seriendruckfeld einfügen",
    "ASPxRichEditStringId.ExportRangeTitle": "Exportbereich",
    "ASPxRichEditStringId.BookmarkTitle": "Lesezeichen",
    "ASPxRichEditStringId.FindReplaceTitle": "Suchen und Ersetzen",
    "ASPxRichEditStringId.InsertImageTitle": "Bild einfügen",
    "ASPxRichEditStringId.ErrorTitle": "Fehler",
    "ASPxRichEditStringId.SpellingTitle": "Rechtschreibung",
    "ASPxRichEditStringId.InformationTitle": "Informationen",
    "ASPxRichEditStringId.WarningTitle": "Warnung",
    "ASPxRichEditStringId.RulerMarginLeftTitle": "Margin Left",
    "ASPxRichEditStringId.RulerMarginRightTitle": "Rand rechts",
    "ASPxRichEditStringId.RulerFirstLineIdentTitle": "Erste Zeile Ident",
    "ASPxRichEditStringId.RulerHangingIdentTitle": "Hängende Ident",
    "ASPxRichEditStringId.RulerRightIdentTitle": "Rechts Ident",
    "ASPxRichEditStringId.RulerLeftIdentTitle": "Links Ident",
    "ASPxRichEditStringId.RulerFirstLineIndentTitle": "First Line Indent",
    "ASPxRichEditStringId.RulerHangingIndentTitle": "Hanging Indent",
    "ASPxRichEditStringId.RulerRightIndentTitle": "Right Indent",
    "ASPxRichEditStringId.RulerLeftIndentTitle": "Left Indent",
    "ASPxRichEditStringId.RulerLeftTabTitle": "Tabstopp links",
    "ASPxRichEditStringId.RulerRightTabTitle": "Tabstopp rechts",
    "ASPxRichEditStringId.RulerCenterTabTitle": "Tabstopp zentriert",
    "ASPxRichEditStringId.RulerDecimalTabTitle": "Dezimal-Titel",
    "ASPxRichEditStringId.MarginsNarrow": "Schmal",
    "ASPxRichEditStringId.MarginsNormal": "Normal",
    "ASPxRichEditStringId.MarginsModerate": "Mittel",
    "ASPxRichEditStringId.MarginsWide": "Breit",
    "ASPxRichEditStringId.ModelIsChangedError": "Das Dokument wurde außerhalb des Editors geändert und neu geladen werden muss. < Br / > Ihre Änderungen in letzter Minute möglicherweise nicht beibehalten werden, sofern vorhanden.",
    "ASPxRichEditStringId.SessionHasExpiredError": "Ihre Sitzung ist abgelaufen. < Br / > Bitte aktualisieren Sie die Seite um fortzufahren.",
    "ASPxRichEditStringId.OpeningAndOverstoreImpossibleError": "Öffnen und speichern-Dokuments Operationen dürfen nicht < Br / > da das Arbeitsverzeichnis für den Editor nicht angegeben ist.",
    "ASPxRichEditStringId.ClipboardAccessDeniedError": "Access to the clipboard is denied due to your browser's security policy. <br />Instead, you can use the keyboard shortcuts:<br />{0}+X (cut), {0}+C (copy) and {0}+V (paste).",
    "ASPxRichEditStringId.ClipboardAccessDeniedErrorTouch": "Access to the clipboard is denied due to your browser's security policy. Instead, use the native menu that can be invoked by tapping and holding selected content.",
    "ASPxRichEditStringId.InnerExceptionsError": "Ein unbekannter Fehler ist aufgetreten, und der Editor muss neu geladen werden. Die letzten Änderungen können verloren.",
    "ASPxRichEditStringId.AuthExceptionsError": "Zugriff auf das Dokument ist eingeschränkt und der Editor neu geladen werden muss. Die letzten Änderungen können verloren.",
    "ASPxRichEditStringId.CantSaveToEmptyPathError": "Cannot save a document opened not from a file. Handle the Saving event to process custom saving",
    "ASPxRichEditStringId.ConfirmOnLosingChanges": "Are you sure you want to perform the action? All unsaved document data will be lost.",
    "ASPxRichEditStringId.RestartNumbering": "Neu nummerieren",
    "ASPxRichEditStringId.ContinueNumbering": "Nummerierung fortsetzen",
    "ASPxRichEditStringId.UpdateField": "Felder aktualisieren",
    "ASPxRichEditStringId.ToggleFieldCodes": "Feldfunktionen ein/aus",
    "ASPxRichEditStringId.OpenHyperlink": "Hyperlink öffnen",
    "ASPxRichEditStringId.EditHyperlink": "Hyperlink bearbeiten",
    "ASPxRichEditStringId.RemoveHyperlink": "Hyperlink entfernen",
    "ASPxRichEditStringId.CantOpenDocumentError": "Das Dokument konnte nicht geöffnet werden, da ein Serverfehler aufgetreten ist.",
    "ASPxRichEditStringId.CantSaveDocumentError": "Das Dokument konnte nicht gespeichert werden, da ein Serverfehler aufgetreten ist.",
    "ASPxRichEditStringId.DocVariableExceptionError": "Das DOCVARIABLE-Feld konnte nicht berechnet werden, da ein Serverfehler aufgetreten ist.",
    "ASPxRichEditStringId.InvalidDocumentFormatError": "Die Datei ist beschädigt und kann nicht geöffnet werden.",
    "ASPxRichEditStringId.InsertContentFromServerExceptionError": "The InsertContentFromServerCommand could not be executed because a server error occurred.",
    "ASPxRichEditStringId.LoadPictureError": "Could not load the image.",
    "ASPxRichEditStringId.MenuCmd_CreateField": "Feld erstellen",
    "ASPxRichEditStringId.MenuCmd_CreateFieldDescription": "Create a dynamic element used as a placeholder for data that might change in a document.",
    "ASPxRichEditStringId.MenuCmd_UpdateAllFields": "Seriendruckfelder aktualisieren",
    "ASPxRichEditStringId.MenuCmd_UpdateAllFieldsDescription": "Alle dynamische Elementen im Dokument aktualisieren, indem Sie immer die neuesten Quelldaten.",
    "ASPxRichEditStringId.ParagraphStyles": "Absatzformate",
    "ASPxRichEditStringId.CharacterStyles": "Zeichenformate",
    "ASPxRichEditStringId.BulletedAndNumberingTitle": "Aufzählungen und Nummerierungen",
    "ASPxRichEditStringId.CustomizeNumberedListTitle": "Nummerierte Liste anpassen",
    "ASPxRichEditStringId.CustomizeBulletedListTitle": "Aufzählung anpassen",
    "ASPxRichEditStringId.CustomizeOutlineNumberedTitle": "Anpassen der Übersichtsliste",
    "ASPxRichEditStringId.HyperlinkTitle": "Hyperlink",
    "ASPxRichEditStringId.TabsTitle": "Tabulatoren",
    "ASPxRichEditStringId.SymbolsTitle": "Symbole",
    "ASPxRichEditStringId.OtherLabels_None": "keine",
    "ASPxRichEditStringId.OtherLabels_All": "Alle",
    "ASPxRichEditStringId.OtherLabels_ConfirmToRewriteExistingFile": "bereits vorhanden sind. Wollen Sie es ersetzen?",
    "ASPxRichEditStringId.Numbering_Character": "Zeichen...",
    "ASPxRichEditStringId.Numbering_Font": "Schriftart...",
    "ASPxRichEditStringId.Numbering_BulletCharacter": "Aufzählungszeichen",
    "ASPxRichEditStringId.Numbering_BulletPosition": "Aufzählungszeichenposition",
    "ASPxRichEditStringId.Numbering_TextPosition": "Textposition",
    "ASPxRichEditStringId.Numbering_AlignedAt": "Aligned at",
    "ASPxRichEditStringId.Numbering_IndentAt": "Einzug bei",
    "ASPxRichEditStringId.Numbering_StartAt": "Anfangen bei",
    "ASPxRichEditStringId.Numbering_NumberFormat": "Zahlenformat",
    "ASPxRichEditStringId.Numbering_NumberStyle": "Zahlenformatvorlage",
    "ASPxRichEditStringId.Numbering_NumberPosition": "Nummernposition",
    "ASPxRichEditStringId.Numbering_Left": "Links",
    "ASPxRichEditStringId.Numbering_Center": "Zentriert",
    "ASPxRichEditStringId.Numbering_Right": "Rechts",
    "ASPxRichEditStringId.Numbering_FollowNumberWith": "Text danach:",
    "ASPxRichEditStringId.Numbering_Level": "Niveau",
    "ASPxRichEditStringId.Numbering_TabCharacter": "Tabulatorzeichen",
    "ASPxRichEditStringId.Numbering_Space": "Leerzchn",
    "ASPxRichEditStringId.Numbering_Nothing": "Nichts",
    "ASPxRichEditStringId.Numbering_Customize": "Anpassen...",
    "ASPxRichEditStringId.Numbering_Bulleted": "Aufgezählt",
    "ASPxRichEditStringId.Numbering_Numbered": "Nummerierung",
    "ASPxRichEditStringId.Numbering_OutlineNumbered": "Gliederung",
    "ASPxRichEditStringId.Hyperlink_EmailAddress": "E-Mail-Adresse",
    "ASPxRichEditStringId.Hyperlink_Url": "URL",
    "ASPxRichEditStringId.Hyperlink_Bookmark": "Lesezeichen",
    "ASPxRichEditStringId.Hyperlink_WebPage": "Webseite",
    "ASPxRichEditStringId.Hyperlink_PlaceInThisDocument": "Aktuelles Dokument",
    "ASPxRichEditStringId.Hyperlink_EmailTo": "E-Mail an",
    "ASPxRichEditStringId.Hyperlink_Subject": "Betreff",
    "ASPxRichEditStringId.Hyperlink_DisplayProperties": "Eigenschaften anzeigen",
    "ASPxRichEditStringId.Hyperlink_Text": "Text",
    "ASPxRichEditStringId.Hyperlink_ToolTip": "ToolTip",
    "ASPxRichEditStringId.Hyperlink_InvalidEmail": "Ungültige e-mail",
    "ASPxRichEditStringId.Tabs_Set": "Festlegen",
    "ASPxRichEditStringId.Tabs_Clear": "Löschen",
    "ASPxRichEditStringId.Tabs_ClearAll": "Alle löschen",
    "ASPxRichEditStringId.Tabs_TabStopPosition": "Tabstoppposition",
    "ASPxRichEditStringId.Tabs_DefaultTabStops": "Standardtabstopps",
    "ASPxRichEditStringId.Tabs_TabStopsToBeCleared": "Zu löschende Tabstopps",
    "ASPxRichEditStringId.Tabs_Alignment": "Ausrichtung",
    "ASPxRichEditStringId.Tabs_Leader": "Füllzeichen",
    "ASPxRichEditStringId.Tabs_Left": "Links",
    "ASPxRichEditStringId.Tabs_Center": "Zentriert",
    "ASPxRichEditStringId.Tabs_Right": "Rechts",
    "ASPxRichEditStringId.Tabs_Decimal": "Dezimal",
    "ASPxRichEditStringId.Tabs_None": "Keine",
    "ASPxRichEditStringId.Tabs_Hyphens": "Bindestriche",
    "ASPxRichEditStringId.Tabs_EqualSign": "Gleichheitszeichen",
    "ASPxRichEditStringId.Tabs_Dots": "Punkte",
    "ASPxRichEditStringId.Tabs_Underline": "Unterstreichen",
    "ASPxRichEditStringId.Tabs_MiddleDots": "Middle dots",
    "ASPxRichEditStringId.Tabs_ThickLine": "Dicke Linie",
    "ASPxRichEditStringId.FinishMerge_From": "Aus",
    "ASPxRichEditStringId.FinishMerge_Count": "Anzahl",
    "ASPxRichEditStringId.FinishMerge_MergeMode": "Zusammenführen-Modus",
    "ASPxRichEditStringId.FinishMerge_AllRecords": "Alle Datensätze",
    "ASPxRichEditStringId.FinishMerge_CurrentRecord": "Aktueller Datensatz",
    "ASPxRichEditStringId.FinishMerge_Range": "Bereich",
    "ASPxRichEditStringId.FinishMerge_NewParagraph": "Neuer Absatz",
    "ASPxRichEditStringId.FinishMerge_NewSection": "Neuer Abschnitt",
    "ASPxRichEditStringId.FinishMerge_JoinTables": "Verknüpfen von Tabellen",
    "ASPxRichEditStringId.InsertMergeField_Fields": "Felder",
    "ASPxRichEditStringId.Bookmarks_BookmarkName": "Textmarkenname",
    "ASPxRichEditStringId.Bookmarks_SortBy": "Sortieren nach",
    "ASPxRichEditStringId.Bookmarks_Name": "Name",
    "ASPxRichEditStringId.Bookmarks_Location": "Ort",
    "ASPxRichEditStringId.Bookmarks_Add": "Hinzufügen",
    "ASPxRichEditStringId.Bookmarks_Delete": "Löschen",
    "ASPxRichEditStringId.Bookmarks_GoTo": "Gehe zu",
    "ASPxRichEditStringId.Bookmarks_NameErrorText": "Lesezeichen-Namen müssen mit einem Buchstaben beginnen",
    "ASPxRichEditStringId.FindReplace_Find": "Suchen",
    "ASPxRichEditStringId.FindReplace_AdvancedFind": "Erweiterte Suche",
    "ASPxRichEditStringId.FindReplace_Replace": "Ersetzen",
    "ASPxRichEditStringId.FindReplace_ReplaceAll": "Alle ersetzen",
    "ASPxRichEditStringId.FindReplace_ReplaceWith": "Ersetzen durch",
    "ASPxRichEditStringId.FindReplace_Previous": "Vorherige",
    "ASPxRichEditStringId.FindReplace_Next": "Nächste",
    "ASPxRichEditStringId.FindReplace_MatchCase": "Groß-/Kleinschreibung beachten",
    "ASPxRichEditStringId.FindReplace_Results": "Ergebnisse",
    "ASPxRichEditStringId.FindReplace_NoResult": "Wir konnten das gesuchte Element nicht finden.",
    "ASPxRichEditStringId.FindReplace_TooLongResult": "The result is too long. Check out the pages to see your results in text.",
    "ASPxRichEditStringId.FindReplace_ReplaceAllNotify": "{0} Einträge wurden ausgetauscht",
    "ASPxRichEditStringId.FindReplace_Items": "Elemente",
    "ASPxRichEditStringId.FindReplace_NoMatches": "Keine Übereinstimmungen",
    "ASPxRichEditStringId.FindReplace_Of": "von",
    "ASPxRichEditStringId.SpellingCheckCompletedInformation": "Die Rechtschreibprüfung ist abgeschlossen.",
    "ASPxRichEditStringId.Spelling_IgnoreOnce": "Einmal ignorieren",
    "ASPxRichEditStringId.Spelling_IgnoreAll": "Alle ignorieren",
    "ASPxRichEditStringId.Spelling_AddToDictionary": "Zum Wörterbuch hinzufügen",
    "ASPxRichEditStringId.Spelling_Delete": "Löschen",
    "ASPxRichEditStringId.Spelling_Change": "Ändern",
    "ASPxRichEditStringId.Spelling_ChangeAll": "Alle ändern",
    "ASPxRichEditStringId.Spelling_NotInDictionary": "Nicht im Wörterbuch:",
    "ASPxRichEditStringId.Spelling_RepeatedWord": "Wiederholtes Wort:",
    "ASPxRichEditStringId.Spelling_Suggestions": "Vorschläge:",
    "ASPxRichEditStringId.Spelling_NoSpellingSuggestions": "(keine Rechtschreibvorschläge)",
    "ASPxRichEditStringId.MenuCmd_MakeTextSentenceCase": "Ersten Buchstaben im Satz großschreiben",
    "ASPxRichEditStringId.MenuCmd_MakeTextSentenceCaseDescription": "Ersten Buchstaben im Satz großschreiben",
    "ASPxRichEditStringId.MenuCmd_ShowCellOptionsForm": "Zellenbegrenzungen",
    "ASPxRichEditStringId.MenuCmd_ShowCellOptionsFormDescription": "Zellenbegrenzungen und Abstand zwischen den Zellen anpassen.",
    "ASPxRichEditStringId.MenuCmd_HeaderOffset": "Kopfzeile von oben:",
    "ASPxRichEditStringId.MenuCmd_FooterOffset": "Fußzeile von unten:",
    "ASPxRichEditStringId.MenuCmd_HeaderOffsetDescription": "Den Abstand zwischen dem oberen Rand der Seite und dem oberen Rand der Kopfzeile angeben.",
    "ASPxRichEditStringId.MenuCmd_FooterOffsetDescription": "Den Abstand zwischen dem unteren Rand der Seite und dem unteren Rand der Fußzeile angeben.",
    "ASPxRichEditStringId.LayoutOptions_Inline": "Mit Text in Zeile",
    "ASPxRichEditStringId.LayoutOptions_LockAnchor": "Lock Anchor",
    "ASPxRichEditStringId.LayoutOptions_LockAspectRatio": "Seitenverhältnis sperren",
    "ASPxRichEditStringId.LayoutOptions_Reset": "Zurücksetzen",
    "ASPxRichEditStringId.LayoutOptions_BothSides": "Beide Seiten",
    "ASPxRichEditStringId.LayoutOptions_LeftOnly": "Nur Links",
    "ASPxRichEditStringId.LayoutOptions_RightOnly": "Right only",
    "ASPxRichEditStringId.LayoutOptions_LargestOnly": "Largest only",
    "ASPxRichEditStringId.LayoutOptions_HorizontalPositionAlignmentInside": "Innen",
    "ASPxRichEditStringId.LayoutOptions_HorizontalPositionAlignmentOutside": "Außerhalb",
    "ASPxRichEditStringId.LayoutOptions_Position": "Position",
    "ASPxRichEditStringId.LayoutOptions_Horizontal": "Horizontal",
    "ASPxRichEditStringId.LayoutOptions_Vertical": "Vertikal",
    "ASPxRichEditStringId.LayoutOptions_RelativeTo": "bezogen auf",
    "ASPxRichEditStringId.LayoutOptions_HorizontalBookLayoutOf": "von",
    "ASPxRichEditStringId.LayoutOptions_AbsolutePosition": "Absolute position",
    "ASPxRichEditStringId.LayoutOptions_RelativePosition": "Relative Position",
    "ASPxRichEditStringId.LayoutOptions_Alignment": "Ausrichtung",
    "ASPxRichEditStringId.LayoutOptions_BookLayout": "Buchlayout",
    "ASPxRichEditStringId.LayoutOptions_ToTheRightOf": "auf der rechten Seite des",
    "ASPxRichEditStringId.LayoutOptions_Below": "Unter",
    "ASPxRichEditStringId.LayoutOptions_TextWrapping": "Textumbruch",
    "ASPxRichEditStringId.LayoutOptions_WrappingStyle": "Umbruchart",
    "ASPxRichEditStringId.LayoutOptions_WrapText": "Umbrechen von Text",
    "ASPxRichEditStringId.LayoutOptions_DistanceFromText": "Distance from text",
    "ASPxRichEditStringId.LayoutOptions_Size": "Größe",
    "ASPxRichEditStringId.LayoutOptions_Absolute": "Absolut",
    "ASPxRichEditStringId.LayoutOptions_Relative": "Relative",
    "ASPxRichEditStringId.LayoutOptions_Rotate": "Drehen",
    "ASPxRichEditStringId.LayoutOptions_Rotation": "Drehung",
    "ASPxRichEditStringId.LayoutOptions_Scale": "Skalierung",
    "ASPxRichEditStringId.LayoutOptions_OriginalSize": "Originalgröße",
    "ASPxRichEditStringId.FileManager_OpenedFileDeleteErrorText": "Die Datei kann nicht gelöscht werden. Sie ist bereits von einem anderen Benutzer geöffnet.",
    "ASPxRichEditStringId.FileManager_OpenedFileMoveErrorText": "The file cannot be moved. It is already opened by another user.",
    "ASPxRichEditStringId.FileManager_OpenedFileRenameErrorText": "The file cannot be renamed. It is already opened by another user.",
    "ASPxRichEditStringId.CreateDateField": "DATE",
    "ASPxRichEditStringId.CreateDateFieldDescription": "Fügt das aktuelle Datum und die Uhrzeit.",
    "ASPxRichEditStringId.CreateTimeField": "TIME",
    "ASPxRichEditStringId.CreateTimeFieldDescription": "Fügt die aktuelle Uhrzeit ein.",
    "ASPxRichEditStringId.CreatePageField": "PAGE",
    "ASPxRichEditStringId.CreatePageFieldDescription": "Inserts the number of the page containing the field.",
    "ASPxRichEditStringId.CreatePageCountField": "NUMPAGES",
    "ASPxRichEditStringId.CreatePageCountFieldDescription": "Inserts the total number of pages.",
    "ASPxRichEditStringId.CreateEmptyMergeField": "MERGEFIELD",
    "ASPxRichEditStringId.CreateEmptyMergeFieldDescription": "Retrieves a value from the bound data source.",
    "ASPxRichEditStringId.CreateEmptyDocVariableField": "DOCVARIABLE",
    "ASPxRichEditStringId.CreateEmptyDocVariableFieldDescription": "Ermöglicht es Ihnen, komplexen Inhalte programmgesteuert einfügen, wenn das Feld aktualisiert wird.",
    "ASPxRichEditStringId.InsertMergeField_NoFields": "Keine Felder",
    "ASPxRichEditStringId.Tabs_NoTabs": "Keine Tabstopps",
    "ASPxRichEditStringId.Bookmarks_NoBookmarks": "Keine Textmarken",
    "ASPxRichEditStringId.MenuCmd_Font_settings": "Schriftarteinstellungen",
    "ASPxRichEditStringId.MenuCmd_Align_Paragraph": "Absatz ausrichten"
  }