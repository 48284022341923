export var CharacterPropertiesMask;
(function (CharacterPropertiesMask) {
  CharacterPropertiesMask[CharacterPropertiesMask["UseNone"] = 0] = "UseNone";
  CharacterPropertiesMask[CharacterPropertiesMask["UseFontName"] = 1] = "UseFontName";
  CharacterPropertiesMask[CharacterPropertiesMask["UseDoubleFontSize"] = 2] = "UseDoubleFontSize";
  CharacterPropertiesMask[CharacterPropertiesMask["UseFontBold"] = 4] = "UseFontBold";
  CharacterPropertiesMask[CharacterPropertiesMask["UseFontItalic"] = 8] = "UseFontItalic";
  CharacterPropertiesMask[CharacterPropertiesMask["UseFontStrikeoutType"] = 16] = "UseFontStrikeoutType";
  CharacterPropertiesMask[CharacterPropertiesMask["UseFontUnderlineType"] = 32] = "UseFontUnderlineType";
  CharacterPropertiesMask[CharacterPropertiesMask["UseAllCaps"] = 64] = "UseAllCaps";
  CharacterPropertiesMask[CharacterPropertiesMask["UseForeColorIndex"] = 128] = "UseForeColorIndex";
  CharacterPropertiesMask[CharacterPropertiesMask["UseShadingInfoIndex"] = 256] = "UseShadingInfoIndex";
  CharacterPropertiesMask[CharacterPropertiesMask["UseUnderlineColorIndex"] = 512] = "UseUnderlineColorIndex";
  CharacterPropertiesMask[CharacterPropertiesMask["UseStrikeoutColorIndex"] = 1024] = "UseStrikeoutColorIndex";
  CharacterPropertiesMask[CharacterPropertiesMask["UseUnderlineWordsOnly"] = 2048] = "UseUnderlineWordsOnly";
  CharacterPropertiesMask[CharacterPropertiesMask["UseStrikeoutWordsOnly"] = 4096] = "UseStrikeoutWordsOnly";
  CharacterPropertiesMask[CharacterPropertiesMask["UseScript"] = 8192] = "UseScript";
  CharacterPropertiesMask[CharacterPropertiesMask["UseHidden"] = 16384] = "UseHidden";
  CharacterPropertiesMask[CharacterPropertiesMask["UseLangInfo"] = 32768] = "UseLangInfo";
  CharacterPropertiesMask[CharacterPropertiesMask["UseNoProof"] = 65536] = "UseNoProof";
  CharacterPropertiesMask[CharacterPropertiesMask["UseFontInfo"] = 131072] = "UseFontInfo";
  CharacterPropertiesMask[CharacterPropertiesMask["UseHighlightColorIndex"] = 262144] = "UseHighlightColorIndex";
  CharacterPropertiesMask[CharacterPropertiesMask["UseRightToLeft"] = 524288] = "UseRightToLeft";
  CharacterPropertiesMask[CharacterPropertiesMask["UseCompositeFontInfo"] = 1048576] = "UseCompositeFontInfo";
  CharacterPropertiesMask[CharacterPropertiesMask["UseSmallCaps"] = 8589934592] = "UseSmallCaps";
  CharacterPropertiesMask[CharacterPropertiesMask["UseAll"] = 9007199254740991] = "UseAll";
})(CharacterPropertiesMask || (CharacterPropertiesMask = {}));
export var CharacterFormattingScript;
(function (CharacterFormattingScript) {
  CharacterFormattingScript[CharacterFormattingScript["Normal"] = 0] = "Normal";
  CharacterFormattingScript[CharacterFormattingScript["Subscript"] = 1] = "Subscript";
  CharacterFormattingScript[CharacterFormattingScript["Superscript"] = 2] = "Superscript";
})(CharacterFormattingScript || (CharacterFormattingScript = {}));
export var ThemeFontType;
(function (ThemeFontType) {
  ThemeFontType[ThemeFontType["None"] = 0] = "None";
  ThemeFontType[ThemeFontType["MajorEastAsia"] = 1] = "MajorEastAsia";
  ThemeFontType[ThemeFontType["MajorBidi"] = 2] = "MajorBidi";
  ThemeFontType[ThemeFontType["MajorAscii"] = 3] = "MajorAscii";
  ThemeFontType[ThemeFontType["MajorHAnsi"] = 4] = "MajorHAnsi";
  ThemeFontType[ThemeFontType["MinorEastAsia"] = 5] = "MinorEastAsia";
  ThemeFontType[ThemeFontType["MinorBidi"] = 6] = "MinorBidi";
  ThemeFontType[ThemeFontType["MinorAscii"] = 7] = "MinorAscii";
  ThemeFontType[ThemeFontType["MinorHAnsi"] = 8] = "MinorHAnsi";
})(ThemeFontType || (ThemeFontType = {}));
export var FontTypeHint;
(function (FontTypeHint) {
  FontTypeHint[FontTypeHint["Default"] = 0] = "Default";
  FontTypeHint[FontTypeHint["Cs"] = 1] = "Cs";
  FontTypeHint[FontTypeHint["EastAsia"] = 2] = "EastAsia";
  FontTypeHint[FontTypeHint["None"] = 3] = "None";
})(FontTypeHint || (FontTypeHint = {}));
export var StrikeoutType;
(function (StrikeoutType) {
  StrikeoutType[StrikeoutType["None"] = 0] = "None";
  StrikeoutType[StrikeoutType["Single"] = 1] = "Single";
  StrikeoutType[StrikeoutType["Double"] = 2] = "Double";
})(StrikeoutType || (StrikeoutType = {}));
export var UnderlineType;
(function (UnderlineType) {
  UnderlineType[UnderlineType["None"] = 0] = "None";
  UnderlineType[UnderlineType["Single"] = 1] = "Single";
  UnderlineType[UnderlineType["Dotted"] = 2] = "Dotted";
  UnderlineType[UnderlineType["Dashed"] = 3] = "Dashed";
  UnderlineType[UnderlineType["DashDotted"] = 4] = "DashDotted";
  UnderlineType[UnderlineType["DashDotDotted"] = 5] = "DashDotDotted";
  UnderlineType[UnderlineType["Double"] = 6] = "Double";
  UnderlineType[UnderlineType["HeavyWave"] = 7] = "HeavyWave";
  UnderlineType[UnderlineType["LongDashed"] = 8] = "LongDashed";
  UnderlineType[UnderlineType["ThickSingle"] = 9] = "ThickSingle";
  UnderlineType[UnderlineType["ThickDotted"] = 10] = "ThickDotted";
  UnderlineType[UnderlineType["ThickDashed"] = 11] = "ThickDashed";
  UnderlineType[UnderlineType["ThickDashDotted"] = 12] = "ThickDashDotted";
  UnderlineType[UnderlineType["ThickDashDotDotted"] = 13] = "ThickDashDotDotted";
  UnderlineType[UnderlineType["ThickLongDashed"] = 14] = "ThickLongDashed";
  UnderlineType[UnderlineType["DoubleWave"] = 15] = "DoubleWave";
  UnderlineType[UnderlineType["Wave"] = 16] = "Wave";
  UnderlineType[UnderlineType["DashSmallGap"] = 17] = "DashSmallGap";
})(UnderlineType || (UnderlineType = {}));