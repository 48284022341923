import { EmptyBatchUpdatableObject } from "@devexpress/utils/lib/class/batch-updatable";
import { ClientModelManager } from "../../model-manager";
import { RangeCopy } from "../../model/manipulators/range/create-range-copy-operation";
import { SubDocumentIntervals } from "../../model/sub-document";
import { RtfExporter } from "./export/exporter";
import { RtfDocumentExporterOptions } from "./export/rtf-document-exporter-options";
import { RtfImporterOptions } from "./import/importer-options";
import { RtfImporter } from "./import/rtf-importer";
import { getAfterInsertCallback, getAfterInsertReject } from "../callback-helpers";
export function getRtfFromSubDocumentPublic(richOptions, subDocument, coreInterval) {
  const rangeCopy = RangeCopy.create(new SubDocumentIntervals(subDocument, [coreInterval]));
  const newModelManager = new ClientModelManager(rangeCopy.model, richOptions, new EmptyBatchUpdatableObject());
  return new RtfExporter(newModelManager.modelManipulator, new RtfDocumentExporterOptions()).exportAsString();
}
export function insertRtfInSubDocumentPublic(processor, subDocument, position, rtf, callback) {
  const options = new RtfImporterOptions(() => {});
  new RtfImporter(options).importFromString(rtf, processor.modelManager.richOptions, getAfterInsertCallback(processor, subDocument, position, callback), getAfterInsertReject(callback));
}