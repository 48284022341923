export var FileTabCommandId;
(function (FileTabCommandId) {
  FileTabCommandId[FileTabCommandId["CreateDocument"] = 412] = "CreateDocument";
  FileTabCommandId[FileTabCommandId["OpenDocument"] = 410] = "OpenDocument";
  FileTabCommandId[FileTabCommandId["ExportDocument"] = 413] = "ExportDocument";
  FileTabCommandId[FileTabCommandId["DownloadDocumentMenu"] = 414] = "DownloadDocumentMenu";
  FileTabCommandId[FileTabCommandId["DownloadDocx"] = 418] = "DownloadDocx";
  FileTabCommandId[FileTabCommandId["DownloadRtf"] = 419] = "DownloadRtf";
  FileTabCommandId[FileTabCommandId["DownloadTxt"] = 420] = "DownloadTxt";
  FileTabCommandId[FileTabCommandId["DownloadHtml"] = 421] = "DownloadHtml";
  FileTabCommandId[FileTabCommandId["PrintDocument"] = 416] = "PrintDocument";
})(FileTabCommandId || (FileTabCommandId = {}));
export var HomeTabCommandId;
(function (HomeTabCommandId) {
  HomeTabCommandId[HomeTabCommandId["ChangeCaseMenu"] = 431] = "ChangeCaseMenu";
  HomeTabCommandId[HomeTabCommandId["AlignParagraphMenu"] = 432] = "AlignParagraphMenu";
  HomeTabCommandId[HomeTabCommandId["LineSpacingMenu"] = 433] = "LineSpacingMenu";
  HomeTabCommandId[HomeTabCommandId["Undo"] = 6] = "Undo";
  HomeTabCommandId[HomeTabCommandId["Redo"] = 7] = "Redo";
  HomeTabCommandId[HomeTabCommandId["Cut"] = 10] = "Cut";
  HomeTabCommandId[HomeTabCommandId["Copy"] = 9] = "Copy";
  HomeTabCommandId[HomeTabCommandId["Paste"] = 8] = "Paste";
  HomeTabCommandId[HomeTabCommandId["ChangeFontName"] = 11] = "ChangeFontName";
  HomeTabCommandId[HomeTabCommandId["ChangeFontSize"] = 13] = "ChangeFontSize";
  HomeTabCommandId[HomeTabCommandId["IncreaseFontSize"] = 14] = "IncreaseFontSize";
  HomeTabCommandId[HomeTabCommandId["DecreaseFontSize"] = 15] = "DecreaseFontSize";
  HomeTabCommandId[HomeTabCommandId["ChangeFontForeColor"] = 28] = "ChangeFontForeColor";
  HomeTabCommandId[HomeTabCommandId["ShowFontDialog"] = 52] = "ShowFontDialog";
  HomeTabCommandId[HomeTabCommandId["ToggleFontBold"] = 20] = "ToggleFontBold";
  HomeTabCommandId[HomeTabCommandId["ToggleFontItalic"] = 21] = "ToggleFontItalic";
  HomeTabCommandId[HomeTabCommandId["ToggleFontUnderline"] = 22] = "ToggleFontUnderline";
  HomeTabCommandId[HomeTabCommandId["ToggleFontStrikeout"] = 24] = "ToggleFontStrikeout";
  HomeTabCommandId[HomeTabCommandId["ToggleFontSubscript"] = 27] = "ToggleFontSubscript";
  HomeTabCommandId[HomeTabCommandId["ToggleFontSuperscript"] = 26] = "ToggleFontSuperscript";
  HomeTabCommandId[HomeTabCommandId["CapitalizationSentenceCase"] = 207] = "CapitalizationSentenceCase";
  HomeTabCommandId[HomeTabCommandId["CapitalizationUpperCase"] = 16] = "CapitalizationUpperCase";
  HomeTabCommandId[HomeTabCommandId["CapitalizationLowerCase"] = 17] = "CapitalizationLowerCase";
  HomeTabCommandId[HomeTabCommandId["CapitalizeEachWordCase"] = 18] = "CapitalizeEachWordCase";
  HomeTabCommandId[HomeTabCommandId["CapitalizationToggleCase"] = 19] = "CapitalizationToggleCase";
  HomeTabCommandId[HomeTabCommandId["ClearFormatting"] = 30] = "ClearFormatting";
  HomeTabCommandId[HomeTabCommandId["ToggleBulletedList"] = 31] = "ToggleBulletedList";
  HomeTabCommandId[HomeTabCommandId["ToggleNumberedList"] = 32] = "ToggleNumberedList";
  HomeTabCommandId[HomeTabCommandId["ToggleMultilevelList"] = 33] = "ToggleMultilevelList";
  HomeTabCommandId[HomeTabCommandId["ToggleParagraphAlignmentLeft"] = 37] = "ToggleParagraphAlignmentLeft";
  HomeTabCommandId[HomeTabCommandId["ToggleParagraphAlignmentCenter"] = 38] = "ToggleParagraphAlignmentCenter";
  HomeTabCommandId[HomeTabCommandId["ToggleParagraphAlignmentRight"] = 39] = "ToggleParagraphAlignmentRight";
  HomeTabCommandId[HomeTabCommandId["ToggleParagraphAlignmentJustify"] = 40] = "ToggleParagraphAlignmentJustify";
  HomeTabCommandId[HomeTabCommandId["ToggleShowHiddenSymbols"] = 36] = "ToggleShowHiddenSymbols";
  HomeTabCommandId[HomeTabCommandId["SetSingleParagraphSpacing"] = 41] = "SetSingleParagraphSpacing";
  HomeTabCommandId[HomeTabCommandId["SetSesquialteralParagraphSpacing"] = 42] = "SetSesquialteralParagraphSpacing";
  HomeTabCommandId[HomeTabCommandId["SetDoubleParagraphSpacing"] = 43] = "SetDoubleParagraphSpacing";
  HomeTabCommandId[HomeTabCommandId["AddSpacingBeforeParagraph"] = 45] = "AddSpacingBeforeParagraph";
  HomeTabCommandId[HomeTabCommandId["AddSpacingAfterParagraph"] = 46] = "AddSpacingAfterParagraph";
  HomeTabCommandId[HomeTabCommandId["RemoveSpacingBeforeParagraph"] = 47] = "RemoveSpacingBeforeParagraph";
  HomeTabCommandId[HomeTabCommandId["RemoveSpacingAfterParagraph"] = 48] = "RemoveSpacingAfterParagraph";
  HomeTabCommandId[HomeTabCommandId["ChangeShading"] = 49] = "ChangeShading";
  HomeTabCommandId[HomeTabCommandId["ChangeStyle"] = 12] = "ChangeStyle";
  HomeTabCommandId[HomeTabCommandId["Find"] = 50] = "Find";
  HomeTabCommandId[HomeTabCommandId["Replace"] = 51] = "Replace";
  HomeTabCommandId[HomeTabCommandId["ChangeFontHighlightColor"] = 29] = "ChangeFontHighlightColor";
  HomeTabCommandId[HomeTabCommandId["ToggleFontDoubleUnderline"] = 23] = "ToggleFontDoubleUnderline";
})(HomeTabCommandId || (HomeTabCommandId = {}));
export var InsertTabCommandId;
(function (InsertTabCommandId) {
  InsertTabCommandId[InsertTabCommandId["ShowInsertTableDialog"] = 55] = "ShowInsertTableDialog";
  InsertTabCommandId[InsertTabCommandId["InsertPicture"] = 411] = "InsertPicture";
  InsertTabCommandId[InsertTabCommandId["ShowBookmarkDialog"] = 58] = "ShowBookmarkDialog";
  InsertTabCommandId[InsertTabCommandId["ShowHyperlinkDialog"] = 59] = "ShowHyperlinkDialog";
  InsertTabCommandId[InsertTabCommandId["InsertHeader"] = 220] = "InsertHeader";
  InsertTabCommandId[InsertTabCommandId["InsertFooter"] = 221] = "InsertFooter";
  InsertTabCommandId[InsertTabCommandId["InsertPageNumberField"] = 235] = "InsertPageNumberField";
  InsertTabCommandId[InsertTabCommandId["InsertPageCountField"] = 236] = "InsertPageCountField";
  InsertTabCommandId[InsertTabCommandId["InsertFloatingTextBox"] = 368] = "InsertFloatingTextBox";
})(InsertTabCommandId || (InsertTabCommandId = {}));
export var PageLayoutTabCommandId;
(function (PageLayoutTabCommandId) {
  PageLayoutTabCommandId[PageLayoutTabCommandId["MarginsMenu"] = 434] = "MarginsMenu";
  PageLayoutTabCommandId[PageLayoutTabCommandId["OrientationMenu"] = 435] = "OrientationMenu";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SizeMenu"] = 436] = "SizeMenu";
  PageLayoutTabCommandId[PageLayoutTabCommandId["ColumnsMenu"] = 437] = "ColumnsMenu";
  PageLayoutTabCommandId[PageLayoutTabCommandId["BreaksMenu"] = 438] = "BreaksMenu";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionNormalPageMargins"] = 66] = "SetSectionNormalPageMargins";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionNarrowPageMargins"] = 67] = "SetSectionNarrowPageMargins";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionModeratePageMargins"] = 68] = "SetSectionModeratePageMargins";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionWidePageMargins"] = 69] = "SetSectionWidePageMargins";
  PageLayoutTabCommandId[PageLayoutTabCommandId["ShowMarginsPageSetupDialog"] = 70] = "ShowMarginsPageSetupDialog";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetPortraitPageOrientation"] = 71] = "SetPortraitPageOrientation";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetLandscapePageOrientation"] = 72] = "SetLandscapePageOrientation";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionLetterPaperSize"] = 162] = "SetSectionLetterPaperSize";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionLegalPaperSize"] = 99] = "SetSectionLegalPaperSize";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionFolioPaperSize"] = 100] = "SetSectionFolioPaperSize";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionA4PaperSize"] = 101] = "SetSectionA4PaperSize";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionB5PaperSize"] = 104] = "SetSectionB5PaperSize";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionExecutivePaperSize"] = 105] = "SetSectionExecutivePaperSize";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionA5PaperSize"] = 102] = "SetSectionA5PaperSize";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionA6PaperSize"] = 103] = "SetSectionA6PaperSize";
  PageLayoutTabCommandId[PageLayoutTabCommandId["ShowPageSetupDialog"] = 73] = "ShowPageSetupDialog";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionOneColumn"] = 74] = "SetSectionOneColumn";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionTwoColumns"] = 75] = "SetSectionTwoColumns";
  PageLayoutTabCommandId[PageLayoutTabCommandId["SetSectionThreeColumns"] = 76] = "SetSectionThreeColumns";
  PageLayoutTabCommandId[PageLayoutTabCommandId["InsertPageBreak"] = 54] = "InsertPageBreak";
  PageLayoutTabCommandId[PageLayoutTabCommandId["InsertColumnBreak"] = 79] = "InsertColumnBreak";
  PageLayoutTabCommandId[PageLayoutTabCommandId["InsertSectionBreakNextPage"] = 80] = "InsertSectionBreakNextPage";
  PageLayoutTabCommandId[PageLayoutTabCommandId["InsertSectionBreakEvenPage"] = 81] = "InsertSectionBreakEvenPage";
  PageLayoutTabCommandId[PageLayoutTabCommandId["InsertSectionBreakOddPage"] = 82] = "InsertSectionBreakOddPage";
  PageLayoutTabCommandId[PageLayoutTabCommandId["ChangePageColor"] = 90] = "ChangePageColor";
})(PageLayoutTabCommandId || (PageLayoutTabCommandId = {}));
export var ReferencesTabCommandId;
(function (ReferencesTabCommandId) {
  ReferencesTabCommandId[ReferencesTabCommandId["AddTextMenu"] = 439] = "AddTextMenu";
  ReferencesTabCommandId[ReferencesTabCommandId["InsertCaptionMenu"] = 440] = "InsertCaptionMenu";
  ReferencesTabCommandId[ReferencesTabCommandId["InsertTableOfFiguresMenu"] = 441] = "InsertTableOfFiguresMenu";
  ReferencesTabCommandId[ReferencesTabCommandId["InsertTableOfContents"] = 377] = "InsertTableOfContents";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphBodyTextLevel"] = 385] = "SetParagraphBodyTextLevel";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading1Level"] = 386] = "SetParagraphHeading1Level";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading2Level"] = 387] = "SetParagraphHeading2Level";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading3Level"] = 388] = "SetParagraphHeading3Level";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading4Level"] = 389] = "SetParagraphHeading4Level";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading5Level"] = 390] = "SetParagraphHeading5Level";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading6Level"] = 391] = "SetParagraphHeading6Level";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading7Level"] = 392] = "SetParagraphHeading7Level";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading8Level"] = 393] = "SetParagraphHeading8Level";
  ReferencesTabCommandId[ReferencesTabCommandId["SetParagraphHeading9Level"] = 394] = "SetParagraphHeading9Level";
  ReferencesTabCommandId[ReferencesTabCommandId["CreateFigureCaptionField"] = 382] = "CreateFigureCaptionField";
  ReferencesTabCommandId[ReferencesTabCommandId["CreateTableCaptionField"] = 383] = "CreateTableCaptionField";
  ReferencesTabCommandId[ReferencesTabCommandId["CreateEquationCaptionField"] = 381] = "CreateEquationCaptionField";
  ReferencesTabCommandId[ReferencesTabCommandId["CreateTableOfFiguresField"] = 379] = "CreateTableOfFiguresField";
  ReferencesTabCommandId[ReferencesTabCommandId["CreateTableOfTablesField"] = 380] = "CreateTableOfTablesField";
  ReferencesTabCommandId[ReferencesTabCommandId["CreateTableOfEquationsField"] = 378] = "CreateTableOfEquationsField";
  ReferencesTabCommandId[ReferencesTabCommandId["UpdateTableOfContents"] = 384] = "UpdateTableOfContents";
})(ReferencesTabCommandId || (ReferencesTabCommandId = {}));
export var MailMergeTabCommandId;
(function (MailMergeTabCommandId) {
  MailMergeTabCommandId[MailMergeTabCommandId["CreateFieldMenu"] = 442] = "CreateFieldMenu";
  MailMergeTabCommandId[MailMergeTabCommandId["CreateField"] = 178] = "CreateField";
  MailMergeTabCommandId[MailMergeTabCommandId["CreateDateField"] = 203] = "CreateDateField";
  MailMergeTabCommandId[MailMergeTabCommandId["CreateTimeField"] = 204] = "CreateTimeField";
  MailMergeTabCommandId[MailMergeTabCommandId["CreatePageField"] = 205] = "CreatePageField";
  MailMergeTabCommandId[MailMergeTabCommandId["CreateNumPagesField"] = 236] = "CreateNumPagesField";
  MailMergeTabCommandId[MailMergeTabCommandId["CreateEmptyMergeField"] = 372] = "CreateEmptyMergeField";
  MailMergeTabCommandId[MailMergeTabCommandId["CreateEmptyDocVariableField"] = 373] = "CreateEmptyDocVariableField";
  MailMergeTabCommandId[MailMergeTabCommandId["CreateEmptyIfField"] = 459] = "CreateEmptyIfField";
  MailMergeTabCommandId[MailMergeTabCommandId["ShowInsertMergeFieldDialog"] = 214] = "ShowInsertMergeFieldDialog";
  MailMergeTabCommandId[MailMergeTabCommandId["ToggleViewMergedData"] = 213] = "ToggleViewMergedData";
  MailMergeTabCommandId[MailMergeTabCommandId["ShowAllFieldCodes"] = 186] = "ShowAllFieldCodes";
  MailMergeTabCommandId[MailMergeTabCommandId["ShowAllFieldResults"] = 187] = "ShowAllFieldResults";
  MailMergeTabCommandId[MailMergeTabCommandId["UpdateAllFields"] = 200] = "UpdateAllFields";
  MailMergeTabCommandId[MailMergeTabCommandId["GoToFirstDataRecord"] = 209] = "GoToFirstDataRecord";
  MailMergeTabCommandId[MailMergeTabCommandId["GoToPreviousDataRecord"] = 210] = "GoToPreviousDataRecord";
  MailMergeTabCommandId[MailMergeTabCommandId["GoToNextDataRecord"] = 211] = "GoToNextDataRecord";
  MailMergeTabCommandId[MailMergeTabCommandId["GoToLastDataRecord"] = 212] = "GoToLastDataRecord";
  MailMergeTabCommandId[MailMergeTabCommandId["ShowMailMergeDialog"] = 216] = "ShowMailMergeDialog";
})(MailMergeTabCommandId || (MailMergeTabCommandId = {}));
export var ViewTabCommandId;
(function (ViewTabCommandId) {
  ViewTabCommandId[ViewTabCommandId["SwitchToSimpleView"] = 405] = "SwitchToSimpleView";
  ViewTabCommandId[ViewTabCommandId["SwitchToPrintLayout"] = 406] = "SwitchToPrintLayout";
  ViewTabCommandId[ViewTabCommandId["ToggleShowHorizontalRuler"] = 94] = "ToggleShowHorizontalRuler";
  ViewTabCommandId[ViewTabCommandId["ToggleFullScreen"] = 98] = "ToggleFullScreen";
})(ViewTabCommandId || (ViewTabCommandId = {}));
export var HeaderAndFooterTabCommandId;
(function (HeaderAndFooterTabCommandId) {
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["GoToPageHeader"] = 227] = "GoToPageHeader";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["GoToPageFooter"] = 228] = "GoToPageFooter";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["GoToPreviousPageHeaderFooter"] = 230] = "GoToPreviousPageHeaderFooter";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["GoToNextPageHeaderFooter"] = 229] = "GoToNextPageHeaderFooter";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["LinkHeaderFooterToPrevious"] = 222] = "LinkHeaderFooterToPrevious";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["ToggleDifferentFirstPage"] = 231] = "ToggleDifferentFirstPage";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["ToggleDifferentOddAndEvenPages"] = 232] = "ToggleDifferentOddAndEvenPages";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["ChangeHeaderOffset"] = 330] = "ChangeHeaderOffset";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["ChangeFooterOffset"] = 331] = "ChangeFooterOffset";
  HeaderAndFooterTabCommandId[HeaderAndFooterTabCommandId["CloseHeaderFooter"] = 233] = "CloseHeaderFooter";
})(HeaderAndFooterTabCommandId || (HeaderAndFooterTabCommandId = {}));
export var TableDesignTabCommandId;
(function (TableDesignTabCommandId) {
  TableDesignTabCommandId[TableDesignTabCommandId["TableStyleOptionsMenu"] = 443] = "TableStyleOptionsMenu";
  TableDesignTabCommandId[TableDesignTabCommandId["BordersMenu"] = 444] = "BordersMenu";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleFirstRow"] = 280] = "ToggleFirstRow";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleLastRow"] = 281] = "ToggleLastRow";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleBandedRows"] = 284] = "ToggleBandedRows";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleFirstColumn"] = 282] = "ToggleFirstColumn";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleLastColumn"] = 283] = "ToggleLastColumn";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleBandedColumn"] = 285] = "ToggleBandedColumn";
  TableDesignTabCommandId[TableDesignTabCommandId["ChangeTableStyle"] = 269] = "ChangeTableStyle";
  TableDesignTabCommandId[TableDesignTabCommandId["ChangeTableRepositoryItemBorderStyle"] = 292] = "ChangeTableRepositoryItemBorderStyle";
  TableDesignTabCommandId[TableDesignTabCommandId["ChangeTableRepositoryItemBorderWidth"] = 291] = "ChangeTableRepositoryItemBorderWidth";
  TableDesignTabCommandId[TableDesignTabCommandId["ChangeTableRepositoryItemBorderColor"] = 290] = "ChangeTableRepositoryItemBorderColor";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellsBottomBorder"] = 272] = "ToggleTableCellsBottomBorder";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellsTopBorder"] = 270] = "ToggleTableCellsTopBorder";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellsLeftBorder"] = 273] = "ToggleTableCellsLeftBorder";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellsRightBorder"] = 271] = "ToggleTableCellsRightBorder";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellNoBorder"] = 274] = "ToggleTableCellNoBorder";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellAllBorders"] = 275] = "ToggleTableCellAllBorders";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellOutsideBorders"] = 279] = "ToggleTableCellOutsideBorders";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellInsideBorders"] = 276] = "ToggleTableCellInsideBorders";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellInsideHorizontalBorders"] = 277] = "ToggleTableCellInsideHorizontalBorders";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableCellInsideVerticalBorders"] = 278] = "ToggleTableCellInsideVerticalBorders";
  TableDesignTabCommandId[TableDesignTabCommandId["ToggleTableViewGridlines"] = 295] = "ToggleTableViewGridlines";
  TableDesignTabCommandId[TableDesignTabCommandId["ChangeTableCellShading"] = 294] = "ChangeTableCellShading";
})(TableDesignTabCommandId || (TableDesignTabCommandId = {}));
export var TableLayoutTabCommandId;
(function (TableLayoutTabCommandId) {
  TableLayoutTabCommandId[TableLayoutTabCommandId["SelectMenu"] = 445] = "SelectMenu";
  TableLayoutTabCommandId[TableLayoutTabCommandId["DeleteMenu"] = 446] = "DeleteMenu";
  TableLayoutTabCommandId[TableLayoutTabCommandId["InsertMenu"] = 447] = "InsertMenu";
  TableLayoutTabCommandId[TableLayoutTabCommandId["AutoFitMenu"] = 448] = "AutoFitMenu";
  TableLayoutTabCommandId[TableLayoutTabCommandId["AlignmentMenu"] = 449] = "AlignmentMenu";
  TableLayoutTabCommandId[TableLayoutTabCommandId["SelectTableCell"] = 286] = "SelectTableCell";
  TableLayoutTabCommandId[TableLayoutTabCommandId["SelectTableColumn"] = 287] = "SelectTableColumn";
  TableLayoutTabCommandId[TableLayoutTabCommandId["SelectTableRow"] = 288] = "SelectTableRow";
  TableLayoutTabCommandId[TableLayoutTabCommandId["SelectTable"] = 289] = "SelectTable";
  TableLayoutTabCommandId[TableLayoutTabCommandId["ToggleShowTableGridLines"] = 295] = "ToggleShowTableGridLines";
  TableLayoutTabCommandId[TableLayoutTabCommandId["ShowDeleteCellsDialog"] = 253] = "ShowDeleteCellsDialog";
  TableLayoutTabCommandId[TableLayoutTabCommandId["DeleteTableColumns"] = 248] = "DeleteTableColumns";
  TableLayoutTabCommandId[TableLayoutTabCommandId["DeleteTableRows"] = 247] = "DeleteTableRows";
  TableLayoutTabCommandId[TableLayoutTabCommandId["DeleteTable"] = 251] = "DeleteTable";
  TableLayoutTabCommandId[TableLayoutTabCommandId["InsertTableRowAbove"] = 246] = "InsertTableRowAbove";
  TableLayoutTabCommandId[TableLayoutTabCommandId["InsertTableRowBelow"] = 245] = "InsertTableRowBelow";
  TableLayoutTabCommandId[TableLayoutTabCommandId["InsertTableColumnToTheLeft"] = 243] = "InsertTableColumnToTheLeft";
  TableLayoutTabCommandId[TableLayoutTabCommandId["InsertTableColumnToTheRight"] = 244] = "InsertTableColumnToTheRight";
  TableLayoutTabCommandId[TableLayoutTabCommandId["MergeTableCells"] = 254] = "MergeTableCells";
  TableLayoutTabCommandId[TableLayoutTabCommandId["ShowSplitCellsDialog"] = 255] = "ShowSplitCellsDialog";
  TableLayoutTabCommandId[TableLayoutTabCommandId["SetAutoFitContents"] = 399] = "SetAutoFitContents";
  TableLayoutTabCommandId[TableLayoutTabCommandId["SetAutoFitWindow"] = 400] = "SetAutoFitWindow";
  TableLayoutTabCommandId[TableLayoutTabCommandId["SetFixedColumnWidth"] = 401] = "SetFixedColumnWidth";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignTopLeft"] = 260] = "TableCellAlignTopLeft";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignTopCenter"] = 261] = "TableCellAlignTopCenter";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignTopRight"] = 262] = "TableCellAlignTopRight";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignMiddleLeft"] = 263] = "TableCellAlignMiddleLeft";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignMiddleCenter"] = 264] = "TableCellAlignMiddleCenter";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignMiddleRight"] = 265] = "TableCellAlignMiddleRight";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignBottomLeft"] = 266] = "TableCellAlignBottomLeft";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignBottomCenter"] = 267] = "TableCellAlignBottomCenter";
  TableLayoutTabCommandId[TableLayoutTabCommandId["TableCellAlignBottomRight"] = 268] = "TableCellAlignBottomRight";
})(TableLayoutTabCommandId || (TableLayoutTabCommandId = {}));
export var FloatingObjectsFormatTabCommandId;
(function (FloatingObjectsFormatTabCommandId) {
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["WrapTextMenu"] = 450] = "WrapTextMenu";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["PositionMenu"] = 451] = "PositionMenu";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["BringToFrontMenu"] = 452] = "BringToFrontMenu";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SendToBackMenu"] = 453] = "SendToBackMenu";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["ChangeFloatingObjectFillColor"] = 343] = "ChangeFloatingObjectFillColor";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["ChangeFloatingObjectOutlineColor"] = 344] = "ChangeFloatingObjectOutlineColor";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["ChangeFloatingObjectOutlineWidth"] = 345] = "ChangeFloatingObjectOutlineWidth";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectInlineTextWrapType"] = 374] = "SetFloatingObjectInlineTextWrapType";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectSquareTextWrapType"] = 346] = "SetFloatingObjectSquareTextWrapType";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectTightTextWrapType"] = 347] = "SetFloatingObjectTightTextWrapType";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectThroughTextWrapType"] = 348] = "SetFloatingObjectThroughTextWrapType";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectTopAndBottomTextWrapType"] = 349] = "SetFloatingObjectTopAndBottomTextWrapType";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectBehindTextWrapType"] = 350] = "SetFloatingObjectBehindTextWrapType";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectInFrontOfTextWrapType"] = 351] = "SetFloatingObjectInFrontOfTextWrapType";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectTopLeftAlignment"] = 352] = "SetFloatingObjectTopLeftAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectTopCenterAlignment"] = 353] = "SetFloatingObjectTopCenterAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectTopRightAlignment"] = 354] = "SetFloatingObjectTopRightAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectMiddleLeftAlignment"] = 355] = "SetFloatingObjectMiddleLeftAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectMiddleCenterAlignment"] = 356] = "SetFloatingObjectMiddleCenterAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectMiddleRightAlignment"] = 357] = "SetFloatingObjectMiddleRightAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectBottomLeftAlignment"] = 358] = "SetFloatingObjectBottomLeftAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectBottomCenterAlignment"] = 359] = "SetFloatingObjectBottomCenterAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["SetFloatingObjectBottomRightAlignment"] = 360] = "SetFloatingObjectBottomRightAlignment";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["FloatingObjectBringForward"] = 361] = "FloatingObjectBringForward";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["FloatingObjectBringToFront"] = 362] = "FloatingObjectBringToFront";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["FloatingObjectBringInFrontOfText"] = 363] = "FloatingObjectBringInFrontOfText";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["FloatingObjectSendBackward"] = 364] = "FloatingObjectSendBackward";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["FloatingObjectSendToBack"] = 365] = "FloatingObjectSendToBack";
  FloatingObjectsFormatTabCommandId[FloatingObjectsFormatTabCommandId["FloatingObjectSendBehindText"] = 366] = "FloatingObjectSendBehindText";
})(FloatingObjectsFormatTabCommandId || (FloatingObjectsFormatTabCommandId = {}));
export var ContextMenuCommandId;
(function (ContextMenuCommandId) {
  ContextMenuCommandId[ContextMenuCommandId["ApplySpellingSuggestion"] = 318] = "ApplySpellingSuggestion";
  ContextMenuCommandId[ContextMenuCommandId["NoSpellingSuggestions"] = 457] = "NoSpellingSuggestions";
  ContextMenuCommandId[ContextMenuCommandId["IgnoreSpellingError"] = 314] = "IgnoreSpellingError";
  ContextMenuCommandId[ContextMenuCommandId["IgnoreAllSpellingErrors"] = 315] = "IgnoreAllSpellingErrors";
  ContextMenuCommandId[ContextMenuCommandId["AddWordToDictionary"] = 317] = "AddWordToDictionary";
  ContextMenuCommandId[ContextMenuCommandId["OpenHyperlink"] = 195] = "OpenHyperlink";
  ContextMenuCommandId[ContextMenuCommandId["ShowEditHyperlinkDialog"] = 194] = "ShowEditHyperlinkDialog";
  ContextMenuCommandId[ContextMenuCommandId["RemoveHyperlink"] = 196] = "RemoveHyperlink";
  ContextMenuCommandId[ContextMenuCommandId["UpdateField"] = 179] = "UpdateField";
  ContextMenuCommandId[ContextMenuCommandId["ToggleFieldCodes"] = 180] = "ToggleFieldCodes";
  ContextMenuCommandId[ContextMenuCommandId["RestartNumberedList"] = 170] = "RestartNumberedList";
  ContextMenuCommandId[ContextMenuCommandId["ContinueNumberedList"] = 189] = "ContinueNumberedList";
  ContextMenuCommandId[ContextMenuCommandId["Cut"] = 10] = "Cut";
  ContextMenuCommandId[ContextMenuCommandId["Copy"] = 9] = "Copy";
  ContextMenuCommandId[ContextMenuCommandId["Paste"] = 8] = "Paste";
  ContextMenuCommandId[ContextMenuCommandId["TableMenu"] = 239] = "TableMenu";
  ContextMenuCommandId[ContextMenuCommandId["InsertTableColumnToTheLeft"] = 243] = "InsertTableColumnToTheLeft";
  ContextMenuCommandId[ContextMenuCommandId["InsertTableColumnToTheRight"] = 244] = "InsertTableColumnToTheRight";
  ContextMenuCommandId[ContextMenuCommandId["InsertTableRowAbove"] = 246] = "InsertTableRowAbove";
  ContextMenuCommandId[ContextMenuCommandId["InsertTableRowBelow"] = 245] = "InsertTableRowBelow";
  ContextMenuCommandId[ContextMenuCommandId["ShowInsertTableCellsDialog"] = 252] = "ShowInsertTableCellsDialog";
  ContextMenuCommandId[ContextMenuCommandId["ShowDeleteTableCellsDialog"] = 253] = "ShowDeleteTableCellsDialog";
  ContextMenuCommandId[ContextMenuCommandId["ShowSplitCellsDialog"] = 255] = "ShowSplitCellsDialog";
  ContextMenuCommandId[ContextMenuCommandId["MergeTableCells"] = 254] = "MergeTableCells";
  ContextMenuCommandId[ContextMenuCommandId["DecreaseParagraphIndent"] = 34] = "DecreaseParagraphIndent";
  ContextMenuCommandId[ContextMenuCommandId["IncreaseParagraphIndent"] = 35] = "IncreaseParagraphIndent";
  ContextMenuCommandId[ContextMenuCommandId["ShowFontDialog"] = 52] = "ShowFontDialog";
  ContextMenuCommandId[ContextMenuCommandId["ShowParagraphDialog"] = 53] = "ShowParagraphDialog";
  ContextMenuCommandId[ContextMenuCommandId["ShowBookmarkDialog"] = 58] = "ShowBookmarkDialog";
  ContextMenuCommandId[ContextMenuCommandId["ShowHyperlinkDialog"] = 59] = "ShowHyperlinkDialog";
  ContextMenuCommandId[ContextMenuCommandId["ChangeFloatingObjectTextWrapTypeMenu"] = 454] = "ChangeFloatingObjectTextWrapTypeMenu";
  ContextMenuCommandId[ContextMenuCommandId["SetFloatingObjectInlineTextWrapType"] = 374] = "SetFloatingObjectInlineTextWrapType";
  ContextMenuCommandId[ContextMenuCommandId["SetFloatingObjectSquareTextWrapType"] = 346] = "SetFloatingObjectSquareTextWrapType";
  ContextMenuCommandId[ContextMenuCommandId["SetFloatingObjectTightTextWrapType"] = 347] = "SetFloatingObjectTightTextWrapType";
  ContextMenuCommandId[ContextMenuCommandId["SetFloatingObjectThroughTextWrapType"] = 348] = "SetFloatingObjectThroughTextWrapType";
  ContextMenuCommandId[ContextMenuCommandId["SetFloatingObjectTopAndBottomTextWrapType"] = 349] = "SetFloatingObjectTopAndBottomTextWrapType";
  ContextMenuCommandId[ContextMenuCommandId["SetFloatingObjectBehindTextWrapType"] = 350] = "SetFloatingObjectBehindTextWrapType";
  ContextMenuCommandId[ContextMenuCommandId["SetFloatingObjectInFrontOfTextWrapType"] = 351] = "SetFloatingObjectInFrontOfTextWrapType";
  ContextMenuCommandId[ContextMenuCommandId["FloatingObjectBringForwardMenu"] = 455] = "FloatingObjectBringForwardMenu";
  ContextMenuCommandId[ContextMenuCommandId["FloatingObjectBringForward"] = 361] = "FloatingObjectBringForward";
  ContextMenuCommandId[ContextMenuCommandId["FloatingObjectBringToFront"] = 362] = "FloatingObjectBringToFront";
  ContextMenuCommandId[ContextMenuCommandId["FloatingObjectBringInFrontOfText"] = 363] = "FloatingObjectBringInFrontOfText";
  ContextMenuCommandId[ContextMenuCommandId["FloatingObjectSendBackwardMenu"] = 456] = "FloatingObjectSendBackwardMenu";
  ContextMenuCommandId[ContextMenuCommandId["FloatingObjectSendBackward"] = 364] = "FloatingObjectSendBackward";
  ContextMenuCommandId[ContextMenuCommandId["FloatingObjectSendToBack"] = 365] = "FloatingObjectSendToBack";
  ContextMenuCommandId[ContextMenuCommandId["FloatingObjectSendBehindText"] = 366] = "FloatingObjectSendBehindText";
  ContextMenuCommandId[ContextMenuCommandId["SelectAll"] = 106] = "SelectAll";
})(ContextMenuCommandId || (ContextMenuCommandId = {}));