export var ColorType;
(function (ColorType) {
  ColorType[ColorType["Rgb"] = 0] = "Rgb";
  ColorType[ColorType["Theme"] = 1] = "Theme";
  ColorType[ColorType["Index"] = 2] = "Index";
  ColorType[ColorType["Auto"] = 3] = "Auto";
})(ColorType || (ColorType = {}));
export class ThemeColorIndexConstants {}
ThemeColorIndexConstants.None = -1;
ThemeColorIndexConstants.Light1 = 0;
ThemeColorIndexConstants.Dark1 = 1;
ThemeColorIndexConstants.Light2 = 2;
ThemeColorIndexConstants.Dark2 = 3;
ThemeColorIndexConstants.Accent1 = 4;
ThemeColorIndexConstants.Accent2 = 5;
ThemeColorIndexConstants.Accent3 = 6;
ThemeColorIndexConstants.Accent4 = 7;
ThemeColorIndexConstants.Accent5 = 8;
ThemeColorIndexConstants.Accent6 = 9;
ThemeColorIndexConstants.Hyperlink = 10;
ThemeColorIndexConstants.FollowedHyperlink = 11;
export var DrawingColorType;
(function (DrawingColorType) {
  DrawingColorType[DrawingColorType["Rgb"] = 0] = "Rgb";
  DrawingColorType[DrawingColorType["System"] = 1] = "System";
  DrawingColorType[DrawingColorType["Scheme"] = 2] = "Scheme";
  DrawingColorType[DrawingColorType["Preset"] = 3] = "Preset";
  DrawingColorType[DrawingColorType["ScRgb"] = 4] = "ScRgb";
  DrawingColorType[DrawingColorType["Hsl"] = 5] = "Hsl";
})(DrawingColorType || (DrawingColorType = {}));
export var ThemeColorValues;
(function (ThemeColorValues) {
  ThemeColorValues[ThemeColorValues["None"] = -1] = "None";
  ThemeColorValues[ThemeColorValues["Light1"] = 0] = "Light1";
  ThemeColorValues[ThemeColorValues["Dark1"] = 1] = "Dark1";
  ThemeColorValues[ThemeColorValues["Light2"] = 2] = "Light2";
  ThemeColorValues[ThemeColorValues["Dark2"] = 3] = "Dark2";
  ThemeColorValues[ThemeColorValues["Accent1"] = 4] = "Accent1";
  ThemeColorValues[ThemeColorValues["Accent2"] = 5] = "Accent2";
  ThemeColorValues[ThemeColorValues["Accent3"] = 6] = "Accent3";
  ThemeColorValues[ThemeColorValues["Accent4"] = 7] = "Accent4";
  ThemeColorValues[ThemeColorValues["Accent5"] = 8] = "Accent5";
  ThemeColorValues[ThemeColorValues["Accent6"] = 9] = "Accent6";
  ThemeColorValues[ThemeColorValues["Hyperlink"] = 10] = "Hyperlink";
  ThemeColorValues[ThemeColorValues["FollowedHyperlink"] = 11] = "FollowedHyperlink";
  ThemeColorValues[ThemeColorValues["Background1"] = 12] = "Background1";
  ThemeColorValues[ThemeColorValues["Background2"] = 13] = "Background2";
  ThemeColorValues[ThemeColorValues["Text1"] = 14] = "Text1";
  ThemeColorValues[ThemeColorValues["Text2"] = 15] = "Text2";
})(ThemeColorValues || (ThemeColorValues = {}));