import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './share/guards/auth.guard';
import { StorageService } from './share/services/storage.service';
import { ApiService } from './share/services/api.service';
import { AuthService } from './share/services/auth.service';
import { authInterceptorProvider } from './share/interceptors/auth.interceptor';
import { InjectorModule } from './share/injection/injector.module';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { RollenGuard } from './share/guards/rollen.guard';
import { UrlService } from './share/services/url.service';
import { PopupService } from './share/services/popup.service';
import { ConfigService } from './share/services/config.service';
import { LanguageService } from './share/services/language.service';
import { PollService } from './share/services/poll.service';
import { CanDeactivateGuard } from './share/guards/canDeactivate.guard';
import { errorInterceptorProvider } from './share/interceptors/error.interceptor';
import { ConfigExternService } from './share/services/config-extern.service';
import { GlobalErrorHandler } from './share/helper/error.handler'

registerLocaleData(localeDe);
registerLocaleData(localeEn);

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        InjectorModule.forRoot()], providers: [
        { provide: LOCALE_ID, useValue: 'de' },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        authInterceptorProvider,
        errorInterceptorProvider,
        StorageService,
        ApiService,
        AuthService,
        ConfigService,
        PollService,
        AuthGuard,
        LanguageService,
        RollenGuard,
        UrlService,
        PopupService,
        CanDeactivateGuard,
        ConfigExternService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
