import { Component, ViewChild, OnDestroy } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { ApiService } from 'src/app/share/services/api.service';
import { Subscription } from 'rxjs';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { Router } from '@angular/router';
import { PopupService } from 'src/app/share/services/popup.service';
import { ConfigService } from 'src/app/share/services/config.service';

@Component({
  selector: 'app-bgb-person-edit-popup',
  templateUrl: './bgb-person-edit-popup.component.html',
  styleUrls: ['./bgb-person-edit-popup.component.scss'],
  standalone: false,
})
export class BgbPersonEditPopupComponent implements OnDestroy {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('form', { static: false }) form: DxFormComponent;

  onClose: (e) => void;
  formData: any;
  subscription_get_PopupAsObservable: Subscription;
  isLoading: boolean = false;
  contextData: any;

  constructor(private apiService: ApiService, private popupService: PopupService, public configService: ConfigService, private router: Router) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickSave = this.onClickSave.bind(this);

    this.subscription_get_PopupAsObservable = this.popupService.get_bgbPersonEditPopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true) {
        this.formData = null;
        this.onClose = context.closeCb;
        this.contextData = context.data;

        if (this.contextData.isNew === true) {
          this.formData = {
            anredeId: null,
            geschlechtId: null,
            nachname: null,
            vorname: null,
            geburtsdatum: null,
            titelId: null,
            staatsangehoerigkeitId: null,
            telefon1: null,
            telefon2: null,
            eMail: null,
          };
          this.popup.instance.show();
        } else {
          this.load();
        }
      } else {
        this.contextData = null;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  load() {
    this.apiService.post(RouteKeys.Bgb.person_get, { id: this.contextData.entityId }).subscribe((response) => {
      this.formData = response;
      this.popup.instance.show();
    });
  }

  onClickSave(e) {
    var validationResult = this.form.instance.validate();
    if (validationResult.isValid) {
      this.isLoading = true;
      this.apiService.post(RouteKeys.Bgb.person_save, this.formData).subscribe({
        next: (response) => {
          this.isLoading = false;

          if (this.onClose !== undefined && this.onClose !== null) {
            this.onClose({
              state: 1,
              data: response,
            });
          }
          this.popup.instance.hide();
        },
        error: (err) => {
          this.isLoading = false;
          throw err;
        },
      });
    }
  }

  onClickClose(e) {
    if (this.onClose !== undefined && this.onClose !== null) {
      this.onClose({
        state: 2,
      });
    }
    this.popup.instance.hide();
  }
}
