import { TableBorders } from '../../../borders/table-borders';
import { JSONTableBordersProperty } from '../../enums/table/json-table-structures-enums';
import { JSONBorderInfoConverter } from '../json-border-info-converter';
export class JSONTableBordersConverter {
  static convertFromJSON(obj, colorModelInfoCache) {
    var result = new TableBorders();
    result.top = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableBordersProperty.TopBorder], colorModelInfoCache);
    result.left = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableBordersProperty.LeftBorder], colorModelInfoCache);
    result.right = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableBordersProperty.RightBorder], colorModelInfoCache);
    result.bottom = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableBordersProperty.BottomBorder], colorModelInfoCache);
    result.insideHorizontal = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableBordersProperty.InsideHorizontalBorder], colorModelInfoCache);
    result.insideVertical = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableBordersProperty.InsideVerticalBorder], colorModelInfoCache);
    return result;
  }
  static convertToJSON(source) {
    var result = {};
    result[JSONTableBordersProperty.TopBorder] = JSONBorderInfoConverter.convertToJSON(source.top);
    result[JSONTableBordersProperty.LeftBorder] = JSONBorderInfoConverter.convertToJSON(source.left);
    result[JSONTableBordersProperty.RightBorder] = JSONBorderInfoConverter.convertToJSON(source.right);
    result[JSONTableBordersProperty.BottomBorder] = JSONBorderInfoConverter.convertToJSON(source.bottom);
    result[JSONTableBordersProperty.InsideHorizontalBorder] = JSONBorderInfoConverter.convertToJSON(source.insideHorizontal);
    result[JSONTableBordersProperty.InsideVerticalBorder] = JSONBorderInfoConverter.convertToJSON(source.insideVertical);
    return result;
  }
}