export var SectionStartType;
(function (SectionStartType) {
  SectionStartType[SectionStartType["NextPage"] = 0] = "NextPage";
  SectionStartType[SectionStartType["OddPage"] = 1] = "OddPage";
  SectionStartType[SectionStartType["EvenPage"] = 2] = "EvenPage";
  SectionStartType[SectionStartType["Continuous"] = 3] = "Continuous";
  SectionStartType[SectionStartType["Column"] = 4] = "Column";
})(SectionStartType || (SectionStartType = {}));
export var HeaderFooterType;
(function (HeaderFooterType) {
  HeaderFooterType[HeaderFooterType["First"] = 0] = "First";
  HeaderFooterType[HeaderFooterType["Odd"] = 1] = "Odd";
  HeaderFooterType[HeaderFooterType["Even"] = 2] = "Even";
})(HeaderFooterType || (HeaderFooterType = {}));
export var LineNumberingRestartType;
(function (LineNumberingRestartType) {
  LineNumberingRestartType[LineNumberingRestartType["NewPage"] = 0] = "NewPage";
  LineNumberingRestartType[LineNumberingRestartType["NewSection"] = 1] = "NewSection";
  LineNumberingRestartType[LineNumberingRestartType["Continuous"] = 2] = "Continuous";
})(LineNumberingRestartType || (LineNumberingRestartType = {}));