import { EmptyBatchUpdatableObject } from "@devexpress/utils/lib/class/batch-updatable";
import { ClientModelManager } from "../model-manager";
import { SubDocumentPosition, SubDocumentInterval } from "../model/sub-document";
export function getAfterInsertCallback(processor, subDocument, position, callback) {
  return (model, formatImagesImporter) => {
    formatImagesImporter.whenAllPicturesLoaded(_success => {
      processor.beginUpdate();
      const result = processor.modelManager.modelManipulator.subDocument.insertSubDocument(new SubDocumentPosition(subDocument, position), new SubDocumentInterval(model.mainSubDocument, model.mainSubDocument.interval));
      model.updateHyperlinkFields(processor, new SubDocumentInterval(subDocument, result.insetedInterval), result.newSubDocuments);
      processor.endUpdate();
      callback(result.insetedInterval, true);
    }, 5000);
    const clientModelManager = new ClientModelManager(model, processor.modelManager.richOptions, new EmptyBatchUpdatableObject());
    formatImagesImporter.import(clientModelManager.modelManipulator);
  };
}
export function getAfterInsertReject(callback) {
  return _reason => {
    callback(null, false);
  };
}