import { MaskedCharacterProperties } from '../../../../../common/model/character/character-properties';
import { Chunk } from '../../../../../common/model/chunk';
import { TextManipulator } from '../../../../../common/model/manipulators/text-manipulator/text-manipulator';
import { TextRun } from '../../../../../common/model/runs/text-run';
import { MaskedCharacterPropertiesBundle } from '../../../../../common/rich-utils/properties-bundle';
import { ListUtils } from '@devexpress/utils/lib/utils/list';
import { SearchUtils } from '@devexpress/utils/lib/utils/search';
export class CharacterImporter {
  get style() {
    if (!this._style) this.resetStyle();
    return this._style;
  }
  set style(value) {
    this._style = value;
  }
  get charPropsBundle() {
    return new MaskedCharacterPropertiesBundle(this.properties, this.style);
  }
  constructor(data) {
    this.data = data;
    this.resetProperties();
    if (!this.data.subDocument.chunks[0]) this.data.subDocument.chunks = [new Chunk(this.data.subDocument.positionManager.registerPosition(0), '', true)];
  }
  resetProperties() {
    this.properties = MaskedCharacterProperties.createDefault(this.data.documentModel);
    return this;
  }
  resetStyle() {
    this._style = this.data.stylesImporter.characterManager.defaultStyle;
    return this;
  }
  resetNoteDocumentId() {
    delete this.noteDocumentId;
    return this;
  }
  reset() {
    this.resetProperties().resetStyle().resetNoteDocumentId();
  }
  insertText(text) {
    const length = text.length;
    const run = new TextRun(this.data.subDocumentInfo.positionImporter.currPosition, length, this.data.subDocumentInfo.paragraphImporter.paragraph, this.charPropsBundle);
    run.noteDocumentId = this.noteDocumentId;
    this.addRun(run, text);
  }
  addRun(run, text) {
    const chunk = ListUtils.last(this.data.subDocument.chunks);
    chunk.textRuns.push(run);
    chunk.textBuffer += text;
    this.data.subDocumentInfo.positionImporter.currPosition += run.getLength();
  }
  addRunAtPos(run, text, pos) {
    const chunkIndex = SearchUtils.normedInterpolationIndexOf(this.data.subDocument.chunks, c => c.startLogPosition.value, pos);
    const chunk = this.data.subDocument.chunks[chunkIndex];
    const startOffsetAtChunk = pos - chunk.startLogPosition.value;
    const currentRunIndex = SearchUtils.normedInterpolationIndexOf(chunk.textRuns, r => r.startOffset, startOffsetAtChunk);
    if (chunk.textRuns[currentRunIndex].startOffset < startOffsetAtChunk) this.addRun(run, text);else {
      chunk.textRuns.splice(currentRunIndex, 0, run);
      TextManipulator.moveRunsInChunk(chunk, currentRunIndex + 1, text.length);
      chunk.textBuffer = [chunk.textBuffer.substr(0, startOffsetAtChunk), text, chunk.textBuffer.substr(startOffsetAtChunk)].join('');
      this.data.subDocumentInfo.positionImporter.currPosition += run.getLength();
    }
  }
  deleteOneSimpleRun(_start) {}
}