import { DocumentFormat } from '../../../common/document-format';
import { Errors } from '@devexpress/utils/lib/errors';
import { Base64Utils } from '@devexpress/utils/lib/utils/base64';
import { isString } from '@devexpress/utils/lib/utils/common';
import { Importer } from '../../formats/docx/import/importer';
import { ImporterOptions } from '../../formats/docx/import/importer-options';
import { RtfImporterOptions } from '../../../common/formats/rtf/import/importer-options';
import { RtfImporter } from '../../../common/formats/rtf/import/rtf-importer';
import { TxtImporter } from '../../../common/formats/txt/txt-importer';
import { getAfterInsertCallback, getAfterInsertReject } from '../../../common/formats/callback-helpers';
import { HtmlDocumentImporter } from '../../../common/formats/html/import/html-document-importer';
export function createImporter(format, throwInvalidFile = reason => {
  throw new Error(Errors.InternalException + " " + reason);
}) {
  switch (format) {
    case DocumentFormat.OpenXml:
      return new Importer(new ImporterOptions(throwInvalidFile));
    case DocumentFormat.Rtf:
      return new RtfImporter(new RtfImporterOptions(throwInvalidFile));
    case DocumentFormat.PlainText:
      return new TxtImporter();
    case DocumentFormat.Html:
      return new HtmlDocumentImporter();
    default:
      console.log('Unsupported format');
      return null;
  }
}
export function insertContentInSubDocumentPublic(processor, subDocument, position, content, documentFormat, callback) {
  if (content instanceof ArrayBuffer) content = Base64Utils.fromArrayBuffer(content);
  if (isString(content)) content = Base64Utils.getFileFromBase64(content);
  const importer = createImporter(documentFormat, () => {});
  if (!importer) throw new Error('Unknown document format');
  importer.importFromFile(content, processor.modelManager.richOptions, getAfterInsertCallback(processor, subDocument, position, callback), getAfterInsertReject(callback));
}