import { StringUtils } from '@devexpress/utils/lib/utils/string';
import { ValueInfo } from './value-info';
export class StringValueParser {
  static parse(inputString) {
    return StringUtils.isNullOrEmpty(inputString) ? ValueInfo.empty : StringValueParser.splitUnitFromValue(inputString);
  }
  static splitUnitFromValue(inputString) {
    let value = '';
    let unit = inputString;
    const pos = inputString.search(StringValueParser.regex);
    if (pos != -1) {
      value = inputString.substring(0, pos);
      unit = inputString.substring(pos);
    } else {
      value = inputString;
      unit = '';
    }
    const val = parseFloat(value);
    return isNaN(val) ? new ValueInfo(unit) : new ValueInfo(unit, val);
  }
}
StringValueParser.regex = /(?<=[0-9.])[^0-9.]/;