export var RtfFontType;
(function (RtfFontType) {
  RtfFontType[RtfFontType["Undefined"] = 0] = "Undefined";
  RtfFontType[RtfFontType["DoubleByteCharactersFont"] = 1] = "DoubleByteCharactersFont";
  RtfFontType[RtfFontType["LowAnsiCharactersFont"] = 2] = "LowAnsiCharactersFont";
  RtfFontType[RtfFontType["HighAnsiCharactersFont"] = 3] = "HighAnsiCharactersFont";
})(RtfFontType || (RtfFontType = {}));
export var RtfFontFamily;
(function (RtfFontFamily) {
  RtfFontFamily[RtfFontFamily["Default"] = 0] = "Default";
  RtfFontFamily[RtfFontFamily["Roman"] = 1] = "Roman";
  RtfFontFamily[RtfFontFamily["Swiss"] = 2] = "Swiss";
  RtfFontFamily[RtfFontFamily["Modern"] = 3] = "Modern";
  RtfFontFamily[RtfFontFamily["Script"] = 4] = "Script";
  RtfFontFamily[RtfFontFamily["Decor"] = 5] = "Decor";
  RtfFontFamily[RtfFontFamily["Tech"] = 6] = "Tech";
  RtfFontFamily[RtfFontFamily["Bidi"] = 7] = "Bidi";
})(RtfFontFamily || (RtfFontFamily = {}));
export var RtfCharset;
(function (RtfCharset) {
  RtfCharset[RtfCharset["Ansi"] = 0] = "Ansi";
  RtfCharset[RtfCharset["Mac"] = 1] = "Mac";
  RtfCharset[RtfCharset["PC"] = 2] = "PC";
  RtfCharset[RtfCharset["Pca"] = 3] = "Pca";
})(RtfCharset || (RtfCharset = {}));