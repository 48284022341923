import { NumberingList } from '../../../../../common/model/numbering-lists/numbering-list';
import { ListUtils } from '@devexpress/utils/lib/utils/list';
import { DocxNsType } from '../../utils/constants';
import { ExporterBaseWithRootElement } from './base';
export class StylesExporter extends ExporterBaseWithRootElement {
  constructor() {
    super(...arguments);
    this.styleNameToIndex = new Map();
    this.sortedStyleIndexes = [];
    this.processedStyleNames = new Set();
  }
  get filePath() {
    return 'word/styles.xml';
  }
  get rootElement() {
    return 'styles';
  }
  get rootNSPrefix() {
    return this.data.constants.namespaces[DocxNsType.WordProcessing].prefix;
  }
  get rootNSValue() {
    return this.data.constants.namespaces[DocxNsType.WordProcessing].namespace;
  }
  fillWriter() {
    this.exportDocumentDefaults();
    const paragraphStyleIndexes = this.getSortedStyleIndexes();
    ListUtils.forEach(paragraphStyleIndexes, index => this.data.parStyleExporter.export(this.data.model.paragraphStyles[index], index));
    ListUtils.forEach(this.data.model.characterStyles, (style, index) => this.data.charStyleExporter.export(style, index));
    ListUtils.forEach(this.data.model.tableStyles, (style, index) => this.data.tblStyleExporter.export(style, index));
  }
  exportDocumentDefaults() {
    this.writer.writeWpStartElement('docDefaults');
    this.exportDocumentCharacterDefaults();
    this.exportDocumentParagraphDefaults();
    this.writer.endElement();
  }
  exportDocumentCharacterDefaults() {
    this.writer.writeWpStartElement('rPrDefault');
    this.writer.writeWpStartElement('rPr');
    this.data.charPropsExporter.exportRunPropertiesCore(this.data.model.defaultCharacterProperties);
    this.writer.endElement();
    this.writer.endElement();
  }
  exportDocumentParagraphDefaults() {
    this.writer.writeWpStartElement('pPrDefault');
    this.writer.writeWpStartElement('pPr');
    this.data.parPropsExporter.exportParagraphPropertiesCore(this.data.model.defaultParagraphProperties, NumberingList.NumberingListNotSettedIndex, 0, null, true);
    this.writer.endElement();
    this.writer.endElement();
  }
  getSortedStyleIndexes() {
    this.styleNameToIndex = new Map();
    this.sortedStyleIndexes = [];
    this.processedStyleNames = new Set();
    const styles = this.data.model.paragraphStyles;
    styles.forEach((style, index) => this.styleNameToIndex.set(style.styleName, index));
    for (let index = 0; index < styles.length; index++) this.sortStyleIndexRecursive(index);
    return this.sortedStyleIndexes;
  }
  sortStyleIndexRecursive(index) {
    const styles = this.data.model.paragraphStyles;
    const style = styles[index];
    if (this.processedStyleNames.has(style.styleName)) return;
    this.processedStyleNames.add(style.styleName);
    if (style.parent) {
      const parentIndex = this.styleNameToIndex.get(style.parent.styleName);
      this.sortStyleIndexRecursive(parentIndex);
    }
    this.sortedStyleIndexes.push(index);
  }
}