var _a;
import { __awaiter } from "tslib";
import { NoteProperties, NoteNumberingRestartType } from "../../../../../../common/model/footnotes/footnote";
import { MapCreator } from "../../../../../../common/utils/map-creator";
import { TranslationTables } from "../../../translation-table/translation-tables";
import { LeafElementDestination, ElementDestination } from "../destination";
export class NotePropertiesLeafElementDestination extends LeafElementDestination {
  constructor(data, footNote) {
    super(data);
    this.footNote = footNote;
  }
}
class NoteNumberingStartDestination extends NotePropertiesLeafElementDestination {
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      this.footNote.startingNumber = this.data.readerHelper.getWpSTIntegerValue(reader, "val", 1);
    });
  }
}
class NoteNumberingRestartTypeDestination extends NotePropertiesLeafElementDestination {
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      this.footNote.numberingRestartType = this.data.readerHelper.getWpEnumValue(reader, "val", TranslationTables.noteNumberingRestartTypeTable.importMap, NoteNumberingRestartType.Continuous);
    });
  }
}
export class NotePropertiesDestination extends ElementDestination {
  static getThis(data) {
    return data.destinationStack.getThis();
  }
  constructor(data, defaultPosition, defaultFormat) {
    super(data);
    this.notes = NoteProperties.createDefault();
    this.defaultPosition = defaultPosition;
    this.defaultFormat = defaultFormat;
  }
  get elementHandlerTable() {
    return NotePropertiesDestination.handlerTable;
  }
  processElementClose(_reader) {
    return __awaiter(this, void 0, void 0, function* () {
      this.setProperties(this.notes);
    });
  }
  createPlacementDestination(data) {
    return new NotePlacementDestination(data, this.notes, this.defaultPosition);
  }
  createNumberingFormatDestination(data) {
    return new NoteNumberingFormatDestination(data, this.notes, this.defaultFormat);
  }
}
_a = NotePropertiesDestination;
NotePropertiesDestination.handlerTable = new MapCreator().add('pos', data => _a.getThis(data).createPlacementDestination(data)).add('numFmt', data => _a.getThis(data).createNumberingFormatDestination(data)).add('numStart', data => new NoteNumberingStartDestination(data, _a.getThis(data).notes)).add('numRestart', data => new NoteNumberingRestartTypeDestination(data, _a.getThis(data).notes)).get();
export class NotePlacementDestination extends NotePropertiesLeafElementDestination {
  constructor(data, footNote, defaultPosition) {
    super(data, footNote);
    this.defaultPosition = defaultPosition;
  }
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      const footNotePosition = this.data.readerHelper.getWpEnumValue(reader, "val", TranslationTables.footNotePlacementTable.importMap, this.defaultPosition);
      this.footNote.position = footNotePosition;
    });
  }
}
export class NoteNumberingFormatDestination extends NotePropertiesLeafElementDestination {
  constructor(data, footNote, defaultNumberingFormat) {
    super(data, footNote);
    this.defaultNumberingFormat = defaultNumberingFormat;
  }
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      this.footNote.numberingFormat = this.data.readerHelper.getWpEnumValue(reader, "val", TranslationTables.pageNumberingFormatTable.importMap, this.defaultNumberingFormat);
    });
  }
}