export var FieldName;
(function (FieldName) {
  FieldName[FieldName["Unknown"] = 0] = "Unknown";
  FieldName[FieldName["Time"] = 1] = "Time";
  FieldName[FieldName["Date"] = 2] = "Date";
  FieldName[FieldName["Page"] = 3] = "Page";
  FieldName[FieldName["NumPages"] = 4] = "NumPages";
  FieldName[FieldName["MergeField"] = 5] = "MergeField";
  FieldName[FieldName["DocVariable"] = 6] = "DocVariable";
  FieldName[FieldName["Hyperlink"] = 7] = "Hyperlink";
  FieldName[FieldName["Seq"] = 8] = "Seq";
  FieldName[FieldName["Tc"] = 9] = "Tc";
  FieldName[FieldName["PageRef"] = 10] = "PageRef";
  FieldName[FieldName["Toc"] = 11] = "Toc";
  FieldName[FieldName["FillIn"] = 12] = "FillIn";
  FieldName[FieldName["If"] = 13] = "If";
})(FieldName || (FieldName = {}));