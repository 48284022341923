export var ParagraphFrameVerticalPositionType;
(function (ParagraphFrameVerticalPositionType) {
  ParagraphFrameVerticalPositionType[ParagraphFrameVerticalPositionType["Page"] = 0] = "Page";
  ParagraphFrameVerticalPositionType[ParagraphFrameVerticalPositionType["Paragraph"] = 1] = "Paragraph";
  ParagraphFrameVerticalPositionType[ParagraphFrameVerticalPositionType["Margin"] = 2] = "Margin";
})(ParagraphFrameVerticalPositionType || (ParagraphFrameVerticalPositionType = {}));
export var ParagraphFrameHorizontalPositionType;
(function (ParagraphFrameHorizontalPositionType) {
  ParagraphFrameHorizontalPositionType[ParagraphFrameHorizontalPositionType["Page"] = 0] = "Page";
  ParagraphFrameHorizontalPositionType[ParagraphFrameHorizontalPositionType["Column"] = 1] = "Column";
  ParagraphFrameHorizontalPositionType[ParagraphFrameHorizontalPositionType["Margin"] = 2] = "Margin";
})(ParagraphFrameHorizontalPositionType || (ParagraphFrameHorizontalPositionType = {}));
export var ParagraphFrameTextWrapType;
(function (ParagraphFrameTextWrapType) {
  ParagraphFrameTextWrapType[ParagraphFrameTextWrapType["Auto"] = 0] = "Auto";
  ParagraphFrameTextWrapType[ParagraphFrameTextWrapType["Around"] = 1] = "Around";
  ParagraphFrameTextWrapType[ParagraphFrameTextWrapType["None"] = 2] = "None";
  ParagraphFrameTextWrapType[ParagraphFrameTextWrapType["NotBeside"] = 3] = "NotBeside";
  ParagraphFrameTextWrapType[ParagraphFrameTextWrapType["Through"] = 4] = "Through";
  ParagraphFrameTextWrapType[ParagraphFrameTextWrapType["Tight"] = 5] = "Tight";
})(ParagraphFrameTextWrapType || (ParagraphFrameTextWrapType = {}));
export var OfficeImageFormat;
(function (OfficeImageFormat) {
  OfficeImageFormat[OfficeImageFormat["None"] = 0] = "None";
  OfficeImageFormat[OfficeImageFormat["Bmp"] = 1] = "Bmp";
  OfficeImageFormat[OfficeImageFormat["Emf"] = 2] = "Emf";
  OfficeImageFormat[OfficeImageFormat["Exif"] = 3] = "Exif";
  OfficeImageFormat[OfficeImageFormat["Gif"] = 4] = "Gif";
  OfficeImageFormat[OfficeImageFormat["Icon"] = 5] = "Icon";
  OfficeImageFormat[OfficeImageFormat["Jpeg"] = 6] = "Jpeg";
  OfficeImageFormat[OfficeImageFormat["MemoryBmp"] = 7] = "MemoryBmp";
  OfficeImageFormat[OfficeImageFormat["Png"] = 8] = "Png";
  OfficeImageFormat[OfficeImageFormat["Tiff"] = 9] = "Tiff";
  OfficeImageFormat[OfficeImageFormat["Wmf"] = 10] = "Wmf";
})(OfficeImageFormat || (OfficeImageFormat = {}));
export var PictureSourceType;
(function (PictureSourceType) {
  PictureSourceType[PictureSourceType["Emf"] = 0] = "Emf";
  PictureSourceType[PictureSourceType["Png"] = 1] = "Png";
  PictureSourceType[PictureSourceType["Jpeg"] = 2] = "Jpeg";
  PictureSourceType[PictureSourceType["Mac"] = 3] = "Mac";
  PictureSourceType[PictureSourceType["PmmMetafile"] = 4] = "PmmMetafile";
  PictureSourceType[PictureSourceType["Wmf"] = 5] = "Wmf";
  PictureSourceType[PictureSourceType["WindowsDib"] = 6] = "WindowsDib";
  PictureSourceType[PictureSourceType["WindowsBmp"] = 7] = "WindowsBmp";
})(PictureSourceType || (PictureSourceType = {}));