import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { ApiService } from 'src/app/share/services/api.service';

@Component({
    selector: 'bilder-edit',
    templateUrl: './bilder-edit.component.html',
    styleUrls: ['./bilder-edit.component.scss'],
    standalone: false
})
export class BilderEditComponent {

  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);

  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('form', { static: false }) form: DxFormComponent;
  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;

  maxFileSize: number = 10485760;

  allowedMimeTypes = allowed_MimeTypes;
  allowedFileExtensions = allowed_FileExtensions;

  formData: any;
  filesToUpload: any[];
  error: string = null;
  title: string = 'bearbeiten';
  isSaving: boolean = false;
  saveCounter: number = 0;

  constructor(private apiService: ApiService) {
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
  }


  openNew(type: string, entityId: number) {
    this.filesToUpload = null;

    if (this.fileUploader) {
      this.fileUploader.instance.reset();
    }

    this.formData = {
      type: type,
      id: null,
      entityId: entityId,
    };

    this.popup.instance.show();
  }

  onValueChanged(e) {
    this.error = null;
    if (e.value.length > 0) {
      this.filesToUpload = e.value;
    }
    else {
      this.filesToUpload = null;
    }
    
  }

  onClickSave() {
    this.saveCounter = 0;
    
    if (this.filesToUpload !== null && this.filesToUpload.length > 0) {
      this.isSaving = true;
      for (let index = 0; index < this.filesToUpload.length; index++) {
        const file = this.filesToUpload[index];
        if (file !== undefined && file !== null) {
          const validateResult = this.validateFile(file);
          if (validateResult.isValid !== true) {
            this.error = validateResult.error;
            this.isSaving = false;
            return;
          }
        }
      }
  
      this.save();
    }
  }

  private save() {
    const file = this.filesToUpload[this.saveCounter];

    let input = new FormData();
    input.append('file', file);
    input.append('vievmodel', JSON.stringify(this.formData));

    this.apiService.upload(RouteKeys.Bilder.add, input, (response) => {
      this.saveCounter++;

      if (this.saveCounter < this.filesToUpload.length) {
        this.save();
      }
      else {
        this.onClose.next(true);
        this.popup.instance.hide();
        this.isSaving = false;
      }
    }, err => {
      this.isSaving = false;
      throw err;
    });
  }

  private validateFile(file) {

    let validateResult = {
      isValid: true,
      error: ''
    };

    if (file.size > this.maxFileSize) {
      validateResult.isValid = false;
      validateResult.error = 'Datei ist zu groß, die maximale Dateigröße beträgt ' + this.maxFileSize / 1024 / 1024 + ' Megabyte! (' + file.name + ')';
    }

    const fileNameParts = file.name.split('.');
    const toFind = '.' + fileNameParts[fileNameParts.length - 1];
    const allowedExt = this.allowedFileExtensions.find(x => x.toLowerCase() === toFind.toLowerCase());
    if (!allowedExt) {
      validateResult.isValid = false;
      validateResult.error = 'Unzulässiger Dateityp! (' + file.name + ')';
    }

    return validateResult;
  }

  onClickClose() {
    this.popup.instance.hide();
  }

}


const allowed_FileExtensions: string[] = [
  '.bmp',
  '.gif',
  '.jpeg',
  '.jpg',
  '.jpe',
  '.png',
  '.tif',
  '.tiff'
];

const allowed_MimeTypes: string = 'image/*,';