export var JSONShapeProperty;
(function (JSONShapeProperty) {
  JSONShapeProperty[JSONShapeProperty["FillColor"] = 0] = "FillColor";
  JSONShapeProperty[JSONShapeProperty["OutlineColor"] = 1] = "OutlineColor";
  JSONShapeProperty[JSONShapeProperty["OutlineWidth"] = 2] = "OutlineWidth";
})(JSONShapeProperty || (JSONShapeProperty = {}));
export var JSONAnchorInlineBaseSize;
(function (JSONAnchorInlineBaseSize) {
  JSONAnchorInlineBaseSize[JSONAnchorInlineBaseSize["LockAspectRatio"] = 0] = "LockAspectRatio";
  JSONAnchorInlineBaseSize[JSONAnchorInlineBaseSize["Rotation"] = 1] = "Rotation";
  JSONAnchorInlineBaseSize[JSONAnchorInlineBaseSize["MAX_VALUE"] = 2] = "MAX_VALUE";
})(JSONAnchorInlineBaseSize || (JSONAnchorInlineBaseSize = {}));
export var JSONPictureSize;
(function (JSONPictureSize) {
  JSONPictureSize[JSONPictureSize["Scale"] = 2] = "Scale";
})(JSONPictureSize || (JSONPictureSize = {}));
export var JSONInlineTextBoxSize;
(function (JSONInlineTextBoxSize) {
  JSONInlineTextBoxSize[JSONInlineTextBoxSize["AbsoluteSize"] = 2] = "AbsoluteSize";
})(JSONInlineTextBoxSize || (JSONInlineTextBoxSize = {}));
export var JSONAnchorTextBoxSize;
(function (JSONAnchorTextBoxSize) {
  JSONAnchorTextBoxSize[JSONAnchorTextBoxSize["AbsoluteSize"] = 2] = "AbsoluteSize";
  JSONAnchorTextBoxSize[JSONAnchorTextBoxSize["RelativeSize"] = 3] = "RelativeSize";
  JSONAnchorTextBoxSize[JSONAnchorTextBoxSize["RelativeSizeType"] = 4] = "RelativeSizeType";
  JSONAnchorTextBoxSize[JSONAnchorTextBoxSize["UseAbsoluteSize"] = 5] = "UseAbsoluteSize";
})(JSONAnchorTextBoxSize || (JSONAnchorTextBoxSize = {}));
export var JSONTextBoxContentMargins;
(function (JSONTextBoxContentMargins) {
  JSONTextBoxContentMargins[JSONTextBoxContentMargins["Left"] = 0] = "Left";
  JSONTextBoxContentMargins[JSONTextBoxContentMargins["Right"] = 1] = "Right";
  JSONTextBoxContentMargins[JSONTextBoxContentMargins["Top"] = 2] = "Top";
  JSONTextBoxContentMargins[JSONTextBoxContentMargins["Bottom"] = 3] = "Bottom";
})(JSONTextBoxContentMargins || (JSONTextBoxContentMargins = {}));
export var JSONAnchorInfoProperty;
(function (JSONAnchorInfoProperty) {
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["AllowOverlap"] = 0] = "AllowOverlap";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["Hidden"] = 1] = "Hidden";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["LayoutTableCell"] = 2] = "LayoutTableCell";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["Locked"] = 3] = "Locked";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["IsBehindDoc"] = 4] = "IsBehindDoc";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["LeftDistance"] = 5] = "LeftDistance";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["RightDistance"] = 6] = "RightDistance";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["TopDistance"] = 7] = "TopDistance";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["BottomDistance"] = 8] = "BottomDistance";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["ZOrder"] = 9] = "ZOrder";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["WrapType"] = 10] = "WrapType";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["WrapSide"] = 11] = "WrapSide";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["HorizontalPositionType"] = 12] = "HorizontalPositionType";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["HorizontalPositionAlignment"] = 13] = "HorizontalPositionAlignment";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["VerticalPositionType"] = 14] = "VerticalPositionType";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["VerticalPositionAlignment"] = 15] = "VerticalPositionAlignment";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["OffsetX"] = 16] = "OffsetX";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["OffsetY"] = 17] = "OffsetY";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["PercentOffsetX"] = 18] = "PercentOffsetX";
  JSONAnchorInfoProperty[JSONAnchorInfoProperty["PercentOffsetY"] = 19] = "PercentOffsetY";
})(JSONAnchorInfoProperty || (JSONAnchorInfoProperty = {}));
export var JSONInlinePictureCommandsImageInfo;
(function (JSONInlinePictureCommandsImageInfo) {
  JSONInlinePictureCommandsImageInfo[JSONInlinePictureCommandsImageInfo["Position"] = 0] = "Position";
  JSONInlinePictureCommandsImageInfo[JSONInlinePictureCommandsImageInfo["CacheInfo"] = 1] = "CacheInfo";
  JSONInlinePictureCommandsImageInfo[JSONInlinePictureCommandsImageInfo["ScaleSize"] = 2] = "ScaleSize";
  JSONInlinePictureCommandsImageInfo[JSONInlinePictureCommandsImageInfo["DrawingObjectProperties"] = 3] = "DrawingObjectProperties";
})(JSONInlinePictureCommandsImageInfo || (JSONInlinePictureCommandsImageInfo = {}));
export var JSONImageCacheInfo;
(function (JSONImageCacheInfo) {
  JSONImageCacheInfo[JSONImageCacheInfo["TmpId"] = 0] = "TmpId";
  JSONImageCacheInfo[JSONImageCacheInfo["ActualId"] = 1] = "ActualId";
  JSONImageCacheInfo[JSONImageCacheInfo["Url"] = 2] = "Url";
  JSONImageCacheInfo[JSONImageCacheInfo["Base64"] = 3] = "Base64";
  JSONImageCacheInfo[JSONImageCacheInfo["Size"] = 4] = "Size";
})(JSONImageCacheInfo || (JSONImageCacheInfo = {}));
export var JSONInlineObjectProperty;
(function (JSONInlineObjectProperty) {
  JSONInlineObjectProperty[JSONInlineObjectProperty["Scales"] = 0] = "Scales";
  JSONInlineObjectProperty[JSONInlineObjectProperty["LockAspectRatio"] = 1] = "LockAspectRatio";
})(JSONInlineObjectProperty || (JSONInlineObjectProperty = {}));
export var JSONTextBoxProperties;
(function (JSONTextBoxProperties) {
  JSONTextBoxProperties[JSONTextBoxProperties["ResizeShapeToFitText"] = 0] = "ResizeShapeToFitText";
  JSONTextBoxProperties[JSONTextBoxProperties["Upright"] = 1] = "Upright";
  JSONTextBoxProperties[JSONTextBoxProperties["VerticalAlignment"] = 2] = "VerticalAlignment";
  JSONTextBoxProperties[JSONTextBoxProperties["WrapText"] = 3] = "WrapText";
  JSONTextBoxProperties[JSONTextBoxProperties["LeftMargin"] = 4] = "LeftMargin";
  JSONTextBoxProperties[JSONTextBoxProperties["RightMargin"] = 5] = "RightMargin";
  JSONTextBoxProperties[JSONTextBoxProperties["TopMargin"] = 6] = "TopMargin";
  JSONTextBoxProperties[JSONTextBoxProperties["BottomMargin"] = 7] = "BottomMargin";
})(JSONTextBoxProperties || (JSONTextBoxProperties = {}));
export var JSONInsertFloatingObjectCommandInfo;
(function (JSONInsertFloatingObjectCommandInfo) {
  JSONInsertFloatingObjectCommandInfo[JSONInsertFloatingObjectCommandInfo["Position"] = 0] = "Position";
  JSONInsertFloatingObjectCommandInfo[JSONInsertFloatingObjectCommandInfo["ImageCacheId"] = 1] = "ImageCacheId";
  JSONInsertFloatingObjectCommandInfo[JSONInsertFloatingObjectCommandInfo["Size"] = 2] = "Size";
  JSONInsertFloatingObjectCommandInfo[JSONInsertFloatingObjectCommandInfo["AnchorInfo"] = 3] = "AnchorInfo";
  JSONInsertFloatingObjectCommandInfo[JSONInsertFloatingObjectCommandInfo["SubDocId"] = 4] = "SubDocId";
  JSONInsertFloatingObjectCommandInfo[JSONInsertFloatingObjectCommandInfo["DrawingObjectProperties"] = 5] = "DrawingObjectProperties";
})(JSONInsertFloatingObjectCommandInfo || (JSONInsertFloatingObjectCommandInfo = {}));
export var JSONNonVisualDrawingObjectProperties;
(function (JSONNonVisualDrawingObjectProperties) {
  JSONNonVisualDrawingObjectProperties[JSONNonVisualDrawingObjectProperties["Name"] = 0] = "Name";
  JSONNonVisualDrawingObjectProperties[JSONNonVisualDrawingObjectProperties["Title"] = 1] = "Title";
  JSONNonVisualDrawingObjectProperties[JSONNonVisualDrawingObjectProperties["Description"] = 2] = "Description";
  JSONNonVisualDrawingObjectProperties[JSONNonVisualDrawingObjectProperties["Id"] = 3] = "Id";
  JSONNonVisualDrawingObjectProperties[JSONNonVisualDrawingObjectProperties["Hidden"] = 4] = "Hidden";
  JSONNonVisualDrawingObjectProperties[JSONNonVisualDrawingObjectProperties["ClickHyperlink"] = 5] = "ClickHyperlink";
  JSONNonVisualDrawingObjectProperties[JSONNonVisualDrawingObjectProperties["HoverHyperlink"] = 6] = "HoverHyperlink";
})(JSONNonVisualDrawingObjectProperties || (JSONNonVisualDrawingObjectProperties = {}));