import { ChangeFieldHyperlinkInfoHistoryItem } from '../../model/history/items/change-field-hyperlink-info-history-item';
import { Url } from '@devexpress/utils/lib/utils/url';
import { ReadOnlyMode } from '../../interfaces/i-rich-edit-core';
import { RichEditClientCommand } from '../client-command';
import { CommandSimpleOptions } from '../command-base';
import { HyperlinkCommandBase } from './hyperlink-command-base';
import { createUrlValidationOptions, UrlUtils } from '../../../common/utils/utils';
export class OpenHyperlinkCommand extends HyperlinkCommandBase {
  executeCore(state, options) {
    let field;
    if (options.param) field = options.param;else {
      if (!state.visible) return false;
      field = state.value;
    }
    var hyperlinkInfo = field.getHyperlinkInfo();
    const fieldIsEditable = this.selection.activeSubDocument.isEditable([field.getAllFieldInterval()]);
    if (!hyperlinkInfo.visited && this.control.readOnly != ReadOnlyMode.Persistent && fieldIsEditable) {
      var newHyperlinkInfo = hyperlinkInfo.clone();
      newHyperlinkInfo.visited = true;
      this.history.beginTransaction();
      this.history.addAndRedo(new ChangeFieldHyperlinkInfoHistoryItem(this.modelManipulator, this.selection.activeSubDocument, field.index, newHyperlinkInfo));
      this.history.endTransaction();
      this.aspxForceSendingRequest();
    }
    if (hyperlinkInfo.isUri()) {
      const options = createUrlValidationOptions(this.control.modelManager);
      let uri = hyperlinkInfo.getUriWithAnchor();
      if (!UrlUtils.isValid(uri, options)) uri = UrlUtils.EmptyPage;
      Url.navigate(uri, "_blank");
    } else {
      this.control.commandManager.getCommand(RichEditClientCommand.GoToBookmark).execute(this.control.commandManager.isPublicApiCall, new CommandSimpleOptions(this.control, hyperlinkInfo.anchor));
    }
    return true;
  }
  isEnabledInReadOnlyMode() {
    return true;
  }
  aspxForceSendingRequest() {}
}