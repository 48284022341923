import { TableConditionalFormattingCalculator } from '../../../../common/model/tables/table-utils';
import { Collection } from '../collection';
export class TableBaseCollection extends Collection {
  constructor(processor, subDocument) {
    super(processor);
    this._subDocument = subDocument;
    this._history = processor.modelManager.history;
    this._modelManipulator = processor.modelManager.modelManipulator;
  }
  _insertTable(rowCount, columnCount, position, availableWidth, inputPosition) {
    const table = this._modelManipulator.table.insertTable(this._subDocument, rowCount, columnCount, position, availableWidth, inputPosition, true);
    this._updateTable(table);
    return table;
  }
  _insertRowBelow(table, rowIndex) {
    this._modelManipulator.table.insertRowBelow(this._subDocument, table, rowIndex);
    this._updateTable(table);
  }
  _insertRowAbove(table, rowIndex) {
    this._modelManipulator.table.insertRowAbove(this._subDocument, table, rowIndex);
    this._updateTable(table);
  }
  _insertCellToTheRight(table, rowIndex, cellIndex, inputPosition) {
    this._modelManipulator.table.insertCellToTheRight(this._subDocument, table, rowIndex, cellIndex, inputPosition, true, true, true, false);
    this._normalizeVerticalSpans(table);
  }
  _insertCellToTheLeft(table, rowIndex, cellIndex, inputPosition) {
    this._modelManipulator.table.insertCellToTheLeft(this._subDocument, table, rowIndex, cellIndex, inputPosition);
    this._normalizeVerticalSpans(table);
  }
  _removeTable(table) {
    this._modelManipulator.table.removeTableWithContent(this._subDocument, table);
  }
  _removeRow(table, rowIndex) {
    this._modelManipulator.table.removeTableRowWithContent(this._subDocument, table, rowIndex);
    this._normalizeCellColumnSpans(table);
    this._updateTable(table);
  }
  _removeCell(table, rowIndex, cellIndex) {
    this._modelManipulator.table.removeTableCellWithContent(this._subDocument, table, rowIndex, cellIndex);
    this._normalizeVerticalSpans(table);
    this._updateTable(table);
  }
  _normalizeCellColumnSpans(table) {
    this._modelManipulator.table.normalizeCellColumnSpans(this._subDocument, table, false);
  }
  _normalizeVerticalSpans(table) {
    this._modelManipulator.table.normalizeVerticalSpans(this._subDocument, table);
  }
  _updateTable(table) {
    TableConditionalFormattingCalculator.updateTable(this._processor.modelManager, table, this._subDocument);
  }
}