export var DrawingObjectVerticalPositionType;
(function (DrawingObjectVerticalPositionType) {
  DrawingObjectVerticalPositionType[DrawingObjectVerticalPositionType["Page"] = 0] = "Page";
  DrawingObjectVerticalPositionType[DrawingObjectVerticalPositionType["Line"] = 1] = "Line";
  DrawingObjectVerticalPositionType[DrawingObjectVerticalPositionType["Paragraph"] = 2] = "Paragraph";
  DrawingObjectVerticalPositionType[DrawingObjectVerticalPositionType["Margin"] = 3] = "Margin";
  DrawingObjectVerticalPositionType[DrawingObjectVerticalPositionType["TopMargin"] = 4] = "TopMargin";
  DrawingObjectVerticalPositionType[DrawingObjectVerticalPositionType["BottomMargin"] = 5] = "BottomMargin";
  DrawingObjectVerticalPositionType[DrawingObjectVerticalPositionType["InsideMargin"] = 6] = "InsideMargin";
  DrawingObjectVerticalPositionType[DrawingObjectVerticalPositionType["OutsideMargin"] = 7] = "OutsideMargin";
})(DrawingObjectVerticalPositionType || (DrawingObjectVerticalPositionType = {}));
export var DrawingObjectHorizontalPositionType;
(function (DrawingObjectHorizontalPositionType) {
  DrawingObjectHorizontalPositionType[DrawingObjectHorizontalPositionType["Page"] = 0] = "Page";
  DrawingObjectHorizontalPositionType[DrawingObjectHorizontalPositionType["Character"] = 1] = "Character";
  DrawingObjectHorizontalPositionType[DrawingObjectHorizontalPositionType["Column"] = 2] = "Column";
  DrawingObjectHorizontalPositionType[DrawingObjectHorizontalPositionType["Margin"] = 3] = "Margin";
  DrawingObjectHorizontalPositionType[DrawingObjectHorizontalPositionType["LeftMargin"] = 4] = "LeftMargin";
  DrawingObjectHorizontalPositionType[DrawingObjectHorizontalPositionType["RightMargin"] = 5] = "RightMargin";
  DrawingObjectHorizontalPositionType[DrawingObjectHorizontalPositionType["InsideMargin"] = 6] = "InsideMargin";
  DrawingObjectHorizontalPositionType[DrawingObjectHorizontalPositionType["OutsideMargin"] = 7] = "OutsideMargin";
})(DrawingObjectHorizontalPositionType || (DrawingObjectHorizontalPositionType = {}));