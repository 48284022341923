import { MapCreator } from "../../../../../../../common/utils/map-creator";
import { ElementDestination } from "../../destination";
import { SubDocumentInfoType } from "../../../../../../../common/model/enums";
import { NoteType } from "../../../../../../../common/model/footnotes/footnote";
import { StringUtils } from "@devexpress/utils/lib/utils/string";
import { NoteDestination } from "../note-destination";
export class EndNotesDestination extends ElementDestination {
  get elementHandlerTable() {
    return EndNotesDestination.handlerTable;
  }
}
EndNotesDestination.handlerTable = new MapCreator().add('endnote', data => new EndNoteDestination(data)).get();
class EndNoteDestination extends NoteDestination {
  get SubDocumentType() {
    return SubDocumentInfoType.EndNote;
  }
  addNote(id, subDocId, type) {
    if (!this.canAddEndNote(id, type)) return false;
    if (type == NoteType.Normal) this.data.endNotesImporter.notes[id] = subDocId;else this.documentModel.endNoteSeparators.setSeparator(subDocId, type);
    return true;
  }
  canAddEndNote(id, type) {
    if (StringUtils.isNullOrEmpty(id)) return false;
    if (type == NoteType.Normal) return !(id in this.data.endNotesImporter.notes);else {
      if (this.documentModel.endNoteSeparators.getSeparator(type) != null) return false;
      return this.data.endNotesImporter.separatorIds.includes(id);
    }
  }
}