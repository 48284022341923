export var FloatingObjectHorizontalPositionTypeApi;
(function (FloatingObjectHorizontalPositionTypeApi) {
  FloatingObjectHorizontalPositionTypeApi[FloatingObjectHorizontalPositionTypeApi["Aligned"] = 0] = "Aligned";
  FloatingObjectHorizontalPositionTypeApi[FloatingObjectHorizontalPositionTypeApi["Absolute"] = 1] = "Absolute";
  FloatingObjectHorizontalPositionTypeApi[FloatingObjectHorizontalPositionTypeApi["Relative"] = 2] = "Relative";
})(FloatingObjectHorizontalPositionTypeApi || (FloatingObjectHorizontalPositionTypeApi = {}));
export var FloatingObjectVerticalPositionTypeApi;
(function (FloatingObjectVerticalPositionTypeApi) {
  FloatingObjectVerticalPositionTypeApi[FloatingObjectVerticalPositionTypeApi["Aligned"] = 0] = "Aligned";
  FloatingObjectVerticalPositionTypeApi[FloatingObjectVerticalPositionTypeApi["Absolute"] = 1] = "Absolute";
  FloatingObjectVerticalPositionTypeApi[FloatingObjectVerticalPositionTypeApi["Relative"] = 2] = "Relative";
})(FloatingObjectVerticalPositionTypeApi || (FloatingObjectVerticalPositionTypeApi = {}));
export var WrapTypeApi;
(function (WrapTypeApi) {
  WrapTypeApi[WrapTypeApi["Inline"] = 0] = "Inline";
  WrapTypeApi[WrapTypeApi["TopAndBottom"] = 1] = "TopAndBottom";
  WrapTypeApi[WrapTypeApi["Tight"] = 2] = "Tight";
  WrapTypeApi[WrapTypeApi["Through"] = 3] = "Through";
  WrapTypeApi[WrapTypeApi["Square"] = 4] = "Square";
  WrapTypeApi[WrapTypeApi["BehindText"] = 5] = "BehindText";
  WrapTypeApi[WrapTypeApi["InFrontOfText"] = 6] = "InFrontOfText";
})(WrapTypeApi || (WrapTypeApi = {}));
export var WrapSideApi;
(function (WrapSideApi) {
  WrapSideApi[WrapSideApi["Both"] = 0] = "Both";
  WrapSideApi[WrapSideApi["Left"] = 1] = "Left";
  WrapSideApi[WrapSideApi["Right"] = 2] = "Right";
  WrapSideApi[WrapSideApi["Largest"] = 3] = "Largest";
})(WrapSideApi || (WrapSideApi = {}));
export var FloatingObjectHorizontalAlignmentApi;
(function (FloatingObjectHorizontalAlignmentApi) {
  FloatingObjectHorizontalAlignmentApi[FloatingObjectHorizontalAlignmentApi["None"] = 0] = "None";
  FloatingObjectHorizontalAlignmentApi[FloatingObjectHorizontalAlignmentApi["Left"] = 1] = "Left";
  FloatingObjectHorizontalAlignmentApi[FloatingObjectHorizontalAlignmentApi["Center"] = 2] = "Center";
  FloatingObjectHorizontalAlignmentApi[FloatingObjectHorizontalAlignmentApi["Right"] = 3] = "Right";
  FloatingObjectHorizontalAlignmentApi[FloatingObjectHorizontalAlignmentApi["Inside"] = 4] = "Inside";
  FloatingObjectHorizontalAlignmentApi[FloatingObjectHorizontalAlignmentApi["Outside"] = 5] = "Outside";
})(FloatingObjectHorizontalAlignmentApi || (FloatingObjectHorizontalAlignmentApi = {}));
export var FloatingObjectHorizontalAnchorElementApi;
(function (FloatingObjectHorizontalAnchorElementApi) {
  FloatingObjectHorizontalAnchorElementApi[FloatingObjectHorizontalAnchorElementApi["Page"] = 0] = "Page";
  FloatingObjectHorizontalAnchorElementApi[FloatingObjectHorizontalAnchorElementApi["Character"] = 1] = "Character";
  FloatingObjectHorizontalAnchorElementApi[FloatingObjectHorizontalAnchorElementApi["Column"] = 2] = "Column";
  FloatingObjectHorizontalAnchorElementApi[FloatingObjectHorizontalAnchorElementApi["Margin"] = 3] = "Margin";
  FloatingObjectHorizontalAnchorElementApi[FloatingObjectHorizontalAnchorElementApi["LeftMargin"] = 4] = "LeftMargin";
  FloatingObjectHorizontalAnchorElementApi[FloatingObjectHorizontalAnchorElementApi["RightMargin"] = 5] = "RightMargin";
  FloatingObjectHorizontalAnchorElementApi[FloatingObjectHorizontalAnchorElementApi["InsideMargin"] = 6] = "InsideMargin";
  FloatingObjectHorizontalAnchorElementApi[FloatingObjectHorizontalAnchorElementApi["OutsideMargin"] = 7] = "OutsideMargin";
})(FloatingObjectHorizontalAnchorElementApi || (FloatingObjectHorizontalAnchorElementApi = {}));
export var FloatingObjectVerticalAlignmentApi;
(function (FloatingObjectVerticalAlignmentApi) {
  FloatingObjectVerticalAlignmentApi[FloatingObjectVerticalAlignmentApi["Top"] = 1] = "Top";
  FloatingObjectVerticalAlignmentApi[FloatingObjectVerticalAlignmentApi["Center"] = 2] = "Center";
  FloatingObjectVerticalAlignmentApi[FloatingObjectVerticalAlignmentApi["Bottom"] = 3] = "Bottom";
  FloatingObjectVerticalAlignmentApi[FloatingObjectVerticalAlignmentApi["Inside"] = 4] = "Inside";
  FloatingObjectVerticalAlignmentApi[FloatingObjectVerticalAlignmentApi["Outside"] = 5] = "Outside";
})(FloatingObjectVerticalAlignmentApi || (FloatingObjectVerticalAlignmentApi = {}));
export var FloatingObjectVerticalAnchorElementApi;
(function (FloatingObjectVerticalAnchorElementApi) {
  FloatingObjectVerticalAnchorElementApi[FloatingObjectVerticalAnchorElementApi["Page"] = 0] = "Page";
  FloatingObjectVerticalAnchorElementApi[FloatingObjectVerticalAnchorElementApi["Line"] = 1] = "Line";
  FloatingObjectVerticalAnchorElementApi[FloatingObjectVerticalAnchorElementApi["Paragraph"] = 2] = "Paragraph";
  FloatingObjectVerticalAnchorElementApi[FloatingObjectVerticalAnchorElementApi["Margin"] = 3] = "Margin";
  FloatingObjectVerticalAnchorElementApi[FloatingObjectVerticalAnchorElementApi["TopMargin"] = 4] = "TopMargin";
  FloatingObjectVerticalAnchorElementApi[FloatingObjectVerticalAnchorElementApi["BottomMargin"] = 5] = "BottomMargin";
  FloatingObjectVerticalAnchorElementApi[FloatingObjectVerticalAnchorElementApi["InsideMargin"] = 6] = "InsideMargin";
  FloatingObjectVerticalAnchorElementApi[FloatingObjectVerticalAnchorElementApi["OutsideMargin"] = 7] = "OutsideMargin";
})(FloatingObjectVerticalAnchorElementApi || (FloatingObjectVerticalAnchorElementApi = {}));