import { CreateBookmarkCommand, DeleteBookmarksCommand, GoToBookmarkCommand } from './bookmark-command';
import { ChangeFontHighlightColorCommand } from './character-properties/change-font-back-color-command';
import { ChangeFontColorCommand } from './character-properties/change-font-color-command';
import { ChangeFontNameCommand } from './character-properties/change-font-name-command';
import { ChangeFontSizeCommand, DecreaseFontSizeCommand, IncreaseFontSizeCommand } from './character-properties/change-font-size-command';
import { ToggleFontBoldCommand } from './character-properties/toggle-font-bold-command';
import { ToggleFontItalicCommand } from './character-properties/toggle-font-italic-command';
import { ToggleFontSubscriptCommand, ToggleFontSuperscriptCommand } from './character-properties/toggle-font-script-command';
import { ToggleFontStrikeoutCommand } from './character-properties/toggle-font-strikeout-command';
import { ToggleFontDoubleUnderlineCommand, ToggleFontSingleUnderlineCommand } from './character-properties/toggle-font-underline-command';
import { RichEditClientCommand } from './client-command';
import { ShowErrorAuthExceptionMessageCommand, ShowErrorCantOpenDocument, ShowErrorCantSaveDocument, ShowErrorCantSaveToEmptyPathMessageCommand, ShowErrorDocVariableExceptionCommand, ShowErrorInnerExceptionMessageCommand, ShowErrorInvalidDocumentMessageCommand, ShowErrorModelIsChangedMessageCommand, ShowErrorOpeningAndOverstoreImpossibleMessageCommand, ShowErrorPathTooLong, ShowErrorSessionHasExpiredMessageCommand, ShowInsertContentFromServerErrorDialogCommand, ShowLoadPictureErrorDialogCommand, ShowSpellingCheckCompletedCommand, ShowWarningClipboardAccessDeniedMessageCommand } from './dialogs/dialog-alert-message-command';
import { DialogBookmarksCommand } from './dialogs/dialog-bookmarks-command';
import { DialogBorderShadingCommand, DialogServiceBorderShadingCommand } from './dialogs/dialog-border-shading-command';
import { DialogColumnsCommand } from './dialogs/dialog-columns-command';
import { DialogCustomNumberingListCommand } from './dialogs/dialog-custom-numbering-list-command';
import { DialogFindReplaceCommand } from './dialogs/dialog-find-replace-command';
import { DialogFinishAndMergeCommand } from './dialogs/dialog-finish-and-merge-command';
import { DialogFontCommand, DialogServiceFontCommand } from './dialogs/dialog-font-command';
import { DialogCreateHyperlinkCommand, DialogCreateOrEditHyperlinkCommand, DialogEditHyperlinkCommand } from './dialogs/dialog-hyperlink-command';
import { DialogInsertImageCommand } from './dialogs/dialog-insert-image-command';
import { DialogInsertMergeFieldCommand } from './dialogs/dialog-insert-merge-field-command';
import { DialogDeleteTableCellsCommand, DialogInsertTableCellsCommand } from './dialogs/dialog-insert-table-cells-command';
import { DialogInsertTableCommand } from './dialogs/dialog-insert-table-command';
import { DialogLayoutOptionsCommand } from './dialogs/dialog-layout-options-command';
import { DialogNumberingListCommand } from './dialogs/dialog-numbering-list-command';
import { DialogPageSetupCommand, ShowPagePaperSetupFormCommand } from './dialogs/dialog-page-setup-command';
import { DialogParagraphPropertiesCommand } from './dialogs/dialog-paragraph-properties-command';
import { DialogSpellCheckerCommand } from './dialogs/dialog-spell-checker-command';
import { DialogSplitTableCellsCommand } from './dialogs/dialog-split-table-cells-command';
import { DialogServiceSymbolsCommand, DialogSymbolsCommand } from './dialogs/dialog-symbols-command';
import { DialogCellPropertiesCommand, DialogTablePropertiesCommand } from './dialogs/dialog-table-properties-command';
import { DialogTabsCommand } from './dialogs/dialog-tabs-command';
import { ShowQuickSearchPanelCommand } from './dialogs/show-quick-search-panel-command';
import { ChangePageColorCommand } from './document-properties/change-page-color-command';
import { PrintDocumentOnClient } from './document/print-document-on-client-command';
import { ChangeHyperlinkCommand } from './fields/change-hyperlink-command';
import { CreateDateFieldCommand, CreateEmptyDocVariableFieldCommand, CreateEmptyIfFieldCommand, CreateEmptyMergeFieldCommand, CreateEquationCaptionFieldCommand, CreateFieldCommand, CreateFieldWithCodeCommand, CreateFigureCaptionFieldCommand, CreateMergeFieldCommand, CreatePageCountFieldCommand, CreatePageFieldCommand, CreateTableCaptionFieldCommand, CreateTableOfEquationsFieldCommand, CreateTableOfFiguresFieldCommand, CreateTableOfTablesFieldCommand, CreateTimeFieldCommand, CreateTocFieldCommand } from './fields/create-field-command';
import { GoToFirstDataRecordCommand, GoToLastDataRecordCommand, GoToNextDataRecordCommand, GoToPreviousDataRecordCommand, GoToRecordCommandBase } from './fields/go-to-record-command';
import { OpenHyperlinkCommand } from './fields/open-hyperlink-command';
import { RemoveHyperlinkCommand } from './fields/remove-hyperlink-command';
import { RemoveHyperlinksCommand } from './fields/remove-hyperlinks-command';
import { ShowAllFieldCodesCommand, ShowAllFieldResultCommand } from './fields/show-all-field-code-result-command';
import { ToggleAllFieldsCommand } from './fields/toggle-all-fields-command';
import { ToggleFieldCodesCommand } from './fields/toggle-field-codes-command';
import { ToggleViewMergedDataCommand } from './fields/toggle-view-merged-data-command';
import { UpdateAllFieldsCommand } from './fields/update-all-fields-command';
import { UpdateFieldCommand } from './fields/update-field-command';
import { UpdateTableOfContentsCommand } from './fields/update-table-of-contents-command';
import { FindAllCommand } from './find-replace/find-all-command';
import { HideFindResultsCommand } from './find-replace/hide-find-results-command';
import { ReplaceAllCommand } from './find-replace/replace-all-command';
import { ReplaceNextCommand } from './find-replace/replace-next-command';
import { ChangeFloatingObjectShapeCommand, ChangeShapeFillColorCommand, ChangeShapeOutlineColorCommand, ChangeShapeOutlineWidthCommand } from './floating-objects/change-shape-property-command';
import { ChangeTextBoxPropertiesCommand } from './floating-objects/change-text-box-properties-command';
import { ChangeFloatingObjectTextWrapTypeMenu, ContextItemFloatingObjects, FloatingObjectBringForwardMenu, FloatingObjectSendBackwardMenu } from './floating-objects/context-item-floating-objects';
import { FloatingObjectBringForwardCommand, FloatingObjectBringInFrontOfTextCommand, FloatingObjectBringToFrontCommand, FloatingObjectSendBackwardCommand, FloatingObjectSendBehindTextCommand, FloatingObjectSendToBackCommand } from './floating-objects/floating-object-bring-forward-command';
import { FloatingObjectDragDropChangePositionCommand } from './floating-objects/floating-object-drag-drop-change-position-command';
import { InsertAnchoredTextBoxCommand } from './floating-objects/insert-anchored-text-box-command';
import { SetFloatingObjectBottomCenterAlignmentCommand, SetFloatingObjectBottomLeftAlignmentCommand, SetFloatingObjectBottomRightAlignmentCommand, SetFloatingObjectMiddleCenterAlignmentCommand, SetFloatingObjectMiddleLeftAlignmentCommand, SetFloatingObjectMiddleRightAlignmentCommand, SetFloatingObjectTopCenterAlignmentCommand, SetFloatingObjectTopLeftAlignmentCommand, SetFloatingObjectTopRightAlignmentCommand } from './floating-objects/set-floating-object-alignment-command';
import { SetFloatingObjectBehindTextWrapTypeCommand, SetFloatingObjectInFrontOfTextWrapTypeCommand, SetFloatingObjectInlineTextWrapTypeCommand, SetFloatingObjectSquareTextWrapTypeCommand, SetFloatingObjectThroughTextWrapTypeCommand, SetFloatingObjectTightTextWrapTypeCommand, SetFloatingObjectTopAndBottomTextWrapTypeCommand } from './floating-objects/set-floating-object-text-wrap-type-command';
import { CloseHeaderFooterCommand } from './header-footer/close-header-footer-command';
import { ContextItemHeadersFooters } from './header-footer/context-item-headers-footers';
import { DifferentFirstPageHeaderFooterCommand, DifferentOddEvenHeaderFooterCommand } from './header-footer/different-first-odd-even-page-header-footer-command';
import { GoToFooterCommand, GoToHeaderCommand } from './header-footer/go-to-header-footer-command';
import { GoToNextHeaderFooterCommand, GoToPreviousHeaderFooterCommand } from './header-footer/go-to-prev-next-header-footer-command';
import { InsertFooterCommand, InsertHeaderCommand } from './header-footer/insert-header-footer-commands';
import { LinkHeaderFooterToPreviousCommand } from './header-footer/link-header-footer-commands';
import { ApplyStyleCommand } from './layout/apply-style-command';
import { ClearFormattingCommand } from './layout/clear-formatting-command';
import { ChangeViewTypeCommand, SwitchToPrintLayoutViewCommand, SwitchToSimpleViewCommand } from './layout/switch-view-command';
import { ToggleFullScreenCommand } from './layout/toggle-full-screen-command';
import { ToggleShowHiddenSymbolsCommand } from './layout/toggle-show-hidden-symbols-command';
import { ToggleShowTableGridLinesCommand } from './layout/toggle-show-table-grid-lines-command';
import { DecrementNumberingIndentCommand, IncrementNumberingIndentCommand } from './numbering/change-numbering-indent-command';
import { ContinueNumberingListCommand } from './numbering/continue-numbering-list-command';
import { InsertNumerationToParagraphsCommand } from './numbering/insert-numeration-to-paragraphs-command';
import { DeleteNumerationFromParagraphsCommand } from './numbering/remove-numeration-from-paragraphs-command';
import { RestartNumberingListCommand } from './numbering/restart-numbering-list-command';
import { ToggleBulletedListCommand } from './numbering/toggle-bulleted-list-command';
import { ToggleMultiLevelListCommand } from './numbering/toggle-multi-level-list-command';
import { ToggleNumberingListCommand } from './numbering/toggle-numbering-list-command';
import { AddSpacingAfterParagraphCommand } from './paragraph-properties/add-spacing-after-paragraph-command';
import { AddSpacingBeforeParagraphCommand } from './paragraph-properties/add-spacing-before-paragraph-command';
import { ChangeHeadingLevelCommand } from './paragraph-properties/change-heading-level-command';
import { DecrementIndentCommand, IncrementIndentCommand } from './paragraph-properties/change-indent-command';
import { ChangeParagraphBackColorCommand } from './paragraph-properties/change-paragraph-back-color-command';
import { DecrementParagraphIndentFromFirstRowCommand, IncrementParagraphIndentFromFirstRowCommand } from './paragraph-properties/change-paragraph-indent-from-first-row-command';
import { DecrementParagraphLeftIndentCommand, IncrementParagraphLeftIndentCommand } from './paragraph-properties/change-paragraph-left-indent-command';
import { RemoveSpacingAfterParagraphCommand } from './paragraph-properties/remove-spacing-after-paragraph-command';
import { RemoveSpacingBeforeParagraphCommand } from './paragraph-properties/remove-spacing-before-paragraph-command';
import { SetDoubleParagraphSpacingCommand, SetSesquialteralParagraphSpacingCommand, SetSingleParagraphSpacingCommand } from './paragraph-properties/set-double-paragraph-spacing-command';
import { ToggleParagraphAlignmentCenterCommand, ToggleParagraphAlignmentJustifyCommand, ToggleParagraphAlignmentLeftCommand, ToggleParagraphAlignmentRightCommand } from './paragraph-properties/toggle-paragraph-alignment-command';
import { RedoCommand } from './redo-command';
import { RulerParagraphLeftIndentsCommand, RulerParagraphRightIndentCommand } from './ruler/ruler-paragraph-indents-command';
import { DeleteTabAtParagraphCommand, InsertTabToParagraphCommand, MoveTabRulerInParagraphCommand } from './ruler/ruler-paragraph-tabs-command';
import { RulerSectionColumnsSettingsCommand } from './ruler/ruler-section-columns-settings-command';
import { RulerSectionMarginLeftCommand, RulerSectionMarginRightCommand } from './ruler/ruler-section-margins-command';
import { RulerTableColumnSeparatorsCommand } from './ruler/ruler-table-column-separators-command';
import { RulerChangeTableColumnWidthCommand, RulerChangeTableRowHeightCommand } from './ruler/ruler-table-commands';
import { ToggleShowHorizontalRulerCommand } from './ruler/toggle-show-horizontal-ruler-command';
import { ChangeFooterOffsetRibbonCommand } from './section-properties/change-footer-offset-command';
import { ChangeHeaderOffsetRibbonCommand } from './section-properties/change-header-offset-command';
import { SetLandscapePageOrientationCommand, SetPortraitPageOrientationCommand } from './section-properties/set-landscape-command';
import { SetModerateSectionPageMarginsCommand, SetNarrowSectionPageMarginsCommand, SetNormalSectionPageMarginsCommand, SetSectionPageMarginsCommand, SetWideSectionPageMarginsCommand } from './section-properties/set-predefined-section-page-margins-commands';
import { SetSectionColumnCountCommand, SetSectionOneColumnCommand, SetSectionThreeColumnsCommand, SetSectionTwoColumnsCommand } from './section-properties/set-section-column-count-commands';
import { SetSectionA4PaperKindCommand, SetSectionA5PaperKindCommand, SetSectionA6PaperKindCommand, SetSectionB5PaperKindCommand, SetSectionExecutivePaperKindCommand, SetSectionFolioPaperKindCommand, SetSectionLegalPaperKindCommand, SetSectionLetterPaperKindCommand, SetSectionPageSizeCommand } from './section-properties/set-section-paper-kind-commands';
import { ExtendGoToDocumentEndCommand, GoToDocumentEndCommand } from './selection/go-to-document-end-command';
import { ExtendGoToDocumentStartCommand, GoToDocumentStartCommand } from './selection/go-to-document-start-command';
import { ExtendGoToLineAboveCommand, GoToLineAboveCommand } from './selection/go-to-line-above-command';
import { ExtendLineDownCommand, LineDownCommand } from './selection/go-to-line-below-command';
import { ExtendGoToLineEndCommand, GoToLineEndCommand } from './selection/go-to-line-end-command';
import { ExtendGoToLineStartCommand, GoToLineStartCommand } from './selection/go-to-line-start-command';
import { ExtendGoToNextCharacterCommand, GoToNextCharacterCommand } from './selection/go-to-next-character-command';
import { ExtendGoToNextPageCommand, GoToNextPageCommand } from './selection/go-to-next-page-command';
import { ExtendGoToNextWordCommand, GoToNextWordCommand } from './selection/go-to-next-word-command';
import { ExtendGoToParagraphEndCommand, GoToParagraphEndCommand } from './selection/go-to-paragraph-end-command';
import { ExtendGoToParagraphStartCommand, GoToParagraphStartCommand } from './selection/go-to-paragraph-start-command';
import { ExtendGoToPrevCharacterCommand, GoToPrevCharacterCommand } from './selection/go-to-prev-character-command';
import { ExtendGoToPrevPageCommand, GoToPrevPageCommand } from './selection/go-to-prev-page-command';
import { ExtendGoToPrevWordCommand, GoToPrevWordCommand } from './selection/go-to-prev-word-command';
import { ExtendGoToStartNextPageCommand, ExtendGoToStartPrevPageCommand, GoToStartNextPageCommand, GoToStartPrevPageCommand } from './selection/go-to-start-page-command-base';
import { SelectAllDocumentCommand } from './selection/select-all-document-command';
import { AddSelectedLineCommandNoUpdateControlState, ExtendSelectLineCommand, ExtendSelectLineCommandNoUpdateControlState, SelectLineCommand, SelectLineCommandNoUpdateControlState } from './selection/select-line-command';
import { SelectParagraphCommand } from './selection/select-paragraph-command';
import { ExtendSelectTableCommand, ExtendSelectTableCellCommand, ExtendSelectTableColumnCommand, ExtendSelectTableRowCommand, SelectTableCellCommand, SelectTableCellsRangeCommand, SelectTableColumnCommand, SelectTableCommand, SelectTableRowCommand } from './selection/select-table-command';
import { AssignShortcutCommand } from './shortcuts/assign-shortcut-command';
import { AddWordToDictionary } from './spelling/add-word-to-dictionary-command';
import { ChangeAllSpellingErrorsCommand } from './spelling/change-all-spelling-errors-command';
import { ChangeSpellingErrorCommand, NoSpellingSuggestionsCommand } from './spelling/change-spelling-error-command';
import { DeleteRepeatedWordCommand } from './spelling/delete-repeated-word-command';
import { IgnoreAllSpellingErrorsCommand } from './spelling/ignore-all-spelling-errors-command';
import { IgnoreSpellingErrorCommand } from './spelling/ignore-spelling-error-command';
import { ChangeActiveSubDocumentToHeaderFooterByPageIndexCommand, ChangeActiveSubDocumentToHeaderFooterBySubDocumentCommand, ChangeActiveSubDocumentToMainCommand, ChangeActiveSubDocumentToTextBoxCommand } from './sub-document/change-active-sub-document-command';
import { ApplyTableStyleCommand } from './tables/apply-table-style-command';
import { AutoFitContentsCommand, AutoFitWindowCommand, FixedColumnWidthCommand } from './tables/auto-fit-commands';
import { ChangeTableBorderColorRepositoryItemCommand, ChangeTableBorderStyleRepositoryItemCommand, ChangeTableBorderWidthRepositoryItemCommand } from './tables/change-table-border-repository-item';
import { ChangeTableCellBottomCenterAlignmentCommand, ChangeTableCellBottomLeftAlignmentCommand, ChangeTableCellBottomRightAlignmentCommand, ChangeTableCellMiddleCenterAlignmentCommand, ChangeTableCellMiddleLeftAlignmentCommand, ChangeTableCellMiddleRightAlignmentCommand, ChangeTableCellTopCenterAlignmentCommand, ChangeTableCellTopLeftAlignmentCommand, ChangeTableCellTopRightAlignmentCommand } from './tables/change-table-cell-alignment-command';
import { ChangeTableCellShadingCommand } from './tables/change-table-cell-shading-command';
import { ChangeTableCellWidthCommand } from './tables/change-table-cell-width-command';
import { ChangeTableColumnWidthCommand } from './tables/change-table-column-width-command';
import { ChangeTableLookCommand, ToggleBandedColumnCommand, ToggleBandedRowsCommand, ToggleFirstColumnCommand, ToggleFirstRowCommand, ToggleLastColumnCommand, ToggleLastRowCommand } from './tables/change-table-look-command';
import { ChangeTableRowHeightCommand } from './tables/change-table-row-height-command';
import { ContextItemTables } from './tables/context-item-tables';
import { DeleteTableCellsByBackspaceCommand } from './tables/delete-table-cells-by-backspace';
import { DeleteTableCellsWithShiftToTheHorizontallyCommand } from './tables/delete-table-cells-with-shift-to-the-horizontally-command';
import { DeleteTableCellsWithShiftToTheVerticallyCommand } from './tables/delete-table-cells-with-shift-to-the-vertically-command';
import { DeleteTableColumnsCommand } from './tables/delete-table-columns-command';
import { DeleteTableCommand } from './tables/delete-table-command';
import { DeleteTableRowsCommand } from './tables/delete-table-rows-command';
import { InsertTableCellWithShiftToTheLeftCommand } from './tables/insert-table-cells-with-shift-to-the-horizontally-command';
import { InsertTableCellsWithShiftToTheVerticallyCommand } from './tables/insert-table-cells-with-shift-to-the-vertically-command';
import { InsertTableColumnToTheLeftCommand, InsertTableColumnToTheRightCommand } from './tables/insert-table-column-commands';
import { InsertTableCoreCommand } from './tables/insert-table-core-command';
import { InsertTableRowAboveCommand, InsertTableRowBelowCommand } from './tables/insert-table-row-commands';
import { MergeTableCellsCommand } from './tables/merge-table-cells-command';
import { SplitTableCellsCommand } from './tables/split-table-cells-command';
import { ToggleTableCellAllBordersCommand, ToggleTableCellInsideBordersCommand, ToggleTableCellInsideHorizontalBordersCommand, ToggleTableCellInsideVerticalBordersCommand, ToggleTableCellNoBorderCommand, ToggleTableCellOutsideBordersCommand, ToggleTableCellsBottomBorderCommand, ToggleTableCellsLeftBorderCommand, ToggleTableCellsRightBorderCommand, ToggleTableCellsTopBorderCommand } from './tables/toggle-table-cells-border-command';
import { BackspaceCommand } from './text/backspace-command';
import { ChangeInlinePictureScaleCommand } from './text/change-inline-picture-scale-command';
import { CapitalizeEachWordCaseCommand, MakeTextLowerCaseCommand, MakeTextUpperCaseCommand, SentenceCaseCommand, SwitchTextCaseCommand, ToggleTextCaseCommand } from './text/change-text-case-commands';
import { CopySelectionCommand, CutSelectionCommand, InsertHtmlCommand, PasteSelectionCommand } from './text/clipboard-commands';
import { DeleteCommand } from './text/delete-command';
import { DragCopyContentCommand, DragMoveContentCommand } from './text/drag-drop-commands';
import { InsertColumnBreakCommand } from './text/insert-column-break-command';
import { InsertLineBreakCommand } from './text/insert-line-break-command';
import { InsertPageBreakCommand } from './text/insert-page-break-command';
import { InsertParagraphCommand } from './text/insert-paragraph-command';
import { InsertSectionBreakEvenPageCommand, InsertSectionBreakNextPageCommand, InsertSectionBreakOddPageCommand } from './text/insert-section-break-command';
import { InsertNonBreakingSpaceCommand, InsertSpaceCommand } from './text/insert-space-command';
import { InsertShiftTabCommand, InsertTabCommand } from './text/insert-tab-command';
import { InsertTextCommand } from './text/insert-text-command';
import { RemoveNextWordCommand, RemovePrevWordCommand } from './text/remove-word-command';
import { SetParagraphBodyTextLevelCommand, SetParagraphHeading1LevelCommand, SetParagraphHeading2LevelCommand, SetParagraphHeading3LevelCommand, SetParagraphHeading4LevelCommand, SetParagraphHeading5LevelCommand, SetParagraphHeading6LevelCommand, SetParagraphHeading7LevelCommand, SetParagraphHeading8LevelCommand, SetParagraphHeading9LevelCommand, SetParagraphLevelCommand } from './toc/set-paragraph-level-command';
import { UndoCommand } from './undo-command';
export class CommandManager {
  constructor(control, printNonce) {
    this.publicEnabledState = {};
    this.lastCommandsChain = [];
    this.executingCommandsChain = [];
    this.executingCommandCounter = 0;
    this.isPublicApiCall = false;
    this.isPrintingProcessing = false;
    this.formatImagesImporters = [];
    this.clipboardTimerId = null;
    this.lastTextInsertDate = new Date(0);
    this.commands = {};
    this.createCommand(control, RichEditClientCommand.AddSpacingAfterParagraph, AddSpacingAfterParagraphCommand);
    this.createCommand(control, RichEditClientCommand.AddSpacingBeforeParagraph, AddSpacingBeforeParagraphCommand);
    this.createCommand(control, RichEditClientCommand.CapitalizeEachWordTextCase, CapitalizeEachWordCaseCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFontBackColor, ChangeFontHighlightColorCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFontForeColor, ChangeFontColorCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFontName, ChangeFontNameCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFontSize, ChangeFontSizeCommand);
    this.createCommand(control, RichEditClientCommand.ChangeInlinePictureScale, ChangeInlinePictureScaleCommand);
    this.createCommand(control, RichEditClientCommand.ChangePageColor, ChangePageColorCommand);
    this.createCommand(control, RichEditClientCommand.ChangeParagraphBackColor, ChangeParagraphBackColorCommand);
    this.createCommand(control, RichEditClientCommand.ChangeStyle, ApplyStyleCommand);
    this.createCommand(control, RichEditClientCommand.ClearFormatting, ClearFormattingCommand);
    this.createCommand(control, RichEditClientCommand.ContinueNumberingList, ContinueNumberingListCommand);
    this.createCommand(control, RichEditClientCommand.CopySelection, CopySelectionCommand);
    this.createCommand(control, RichEditClientCommand.CreateField, CreateFieldCommand);
    this.createCommand(control, RichEditClientCommand.CutSelection, CutSelectionCommand);
    this.createCommand(control, RichEditClientCommand.DecreaseFontSize, DecreaseFontSizeCommand);
    this.createCommand(control, RichEditClientCommand.DecreaseIndent, DecrementIndentCommand);
    this.createCommand(control, RichEditClientCommand.DecrementNumberingIndent, DecrementNumberingIndentCommand);
    this.createCommand(control, RichEditClientCommand.DecrementParagraphIndentFromFirstRow, DecrementParagraphIndentFromFirstRowCommand);
    this.createCommand(control, RichEditClientCommand.DecrementParagraphLeftIndent, DecrementParagraphLeftIndentCommand);
    this.createCommand(control, RichEditClientCommand.DeleteNumerationFromParagraphs, DeleteNumerationFromParagraphsCommand);
    this.createCommand(control, RichEditClientCommand.DeleteTabRuler, DeleteTabAtParagraphCommand);
    this.createCommand(control, RichEditClientCommand.DocumentEnd, GoToDocumentEndCommand);
    this.createCommand(control, RichEditClientCommand.DocumentStart, GoToDocumentStartCommand);
    this.createCommand(control, RichEditClientCommand.DragCopyContent, DragCopyContentCommand);
    this.createCommand(control, RichEditClientCommand.DragMoveContent, DragMoveContentCommand);
    this.createCommand(control, RichEditClientCommand.ExtendDocumentEnd, ExtendGoToDocumentEndCommand);
    this.createCommand(control, RichEditClientCommand.ExtendDocumentStart, ExtendGoToDocumentStartCommand);
    this.createCommand(control, RichEditClientCommand.ExtendGoToEndParagraph, ExtendGoToParagraphEndCommand);
    this.createCommand(control, RichEditClientCommand.ExtendGoToNextWord, ExtendGoToNextWordCommand);
    this.createCommand(control, RichEditClientCommand.ExtendGoToPrevWord, ExtendGoToPrevWordCommand);
    this.createCommand(control, RichEditClientCommand.ExtendGoToStartParagraph, ExtendGoToParagraphStartCommand);
    this.createCommand(control, RichEditClientCommand.ExtendLineDown, ExtendLineDownCommand);
    this.createCommand(control, RichEditClientCommand.ExtendLineEnd, ExtendGoToLineEndCommand);
    this.createCommand(control, RichEditClientCommand.ExtendLineStart, ExtendGoToLineStartCommand);
    this.createCommand(control, RichEditClientCommand.ExtendLineUp, ExtendGoToLineAboveCommand);
    this.createCommand(control, RichEditClientCommand.ExtendNextCharacter, ExtendGoToNextCharacterCommand);
    this.createCommand(control, RichEditClientCommand.ExtendNextPage, ExtendGoToNextPageCommand);
    this.createCommand(control, RichEditClientCommand.ExtendPreviousCharacter, ExtendGoToPrevCharacterCommand);
    this.createCommand(control, RichEditClientCommand.ExtendPreviousPage, ExtendGoToPrevPageCommand);
    this.createCommand(control, RichEditClientCommand.ExtendSelectLine, ExtendSelectLineCommand);
    this.createCommand(control, RichEditClientCommand.ExtendSelectLineNoUpdateControlState, ExtendSelectLineCommandNoUpdateControlState);
    this.createCommand(control, RichEditClientCommand.FullScreen, ToggleFullScreenCommand);
    this.createCommand(control, RichEditClientCommand.GoToEndParagraph, GoToParagraphEndCommand);
    this.createCommand(control, RichEditClientCommand.GoToNextWord, GoToNextWordCommand);
    this.createCommand(control, RichEditClientCommand.GoToPrevWord, GoToPrevWordCommand);
    this.createCommand(control, RichEditClientCommand.GoToFirstDataRecord, GoToFirstDataRecordCommand);
    this.createCommand(control, RichEditClientCommand.GoToPreviousDataRecord, GoToPreviousDataRecordCommand);
    this.createCommand(control, RichEditClientCommand.GoToNextDataRecord, GoToNextDataRecordCommand);
    this.createCommand(control, RichEditClientCommand.GoToLastDataRecord, GoToLastDataRecordCommand);
    this.createCommand(control, RichEditClientCommand.GoToStartParagraph, GoToParagraphStartCommand);
    this.createCommand(control, RichEditClientCommand.IncreaseFontSize, IncreaseFontSizeCommand);
    this.createCommand(control, RichEditClientCommand.IncreaseIndent, IncrementIndentCommand);
    this.createCommand(control, RichEditClientCommand.IncrementNumberingIndent, IncrementNumberingIndentCommand);
    this.createCommand(control, RichEditClientCommand.IncrementParagraphIndentFromFirstRow, IncrementParagraphIndentFromFirstRowCommand);
    this.createCommand(control, RichEditClientCommand.IncrementParagraphLeftIndent, IncrementParagraphLeftIndentCommand);
    this.createCommand(control, RichEditClientCommand.InsertColumnBreak, InsertColumnBreakCommand);
    this.createCommand(control, RichEditClientCommand.InsertLineBreak, InsertLineBreakCommand);
    this.createCommand(control, RichEditClientCommand.InsertNumerationToParagraphs, InsertNumerationToParagraphsCommand);
    this.createCommand(control, RichEditClientCommand.InsertPageBreak, InsertPageBreakCommand);
    this.createCommand(control, RichEditClientCommand.InsertParagraph, InsertParagraphCommand);
    this.createCommand(control, RichEditClientCommand.InsertPicture, DialogInsertImageCommand);
    this.createCommand(control, RichEditClientCommand.InsertSectionBreakEvenPage, InsertSectionBreakEvenPageCommand);
    this.createCommand(control, RichEditClientCommand.InsertSectionBreakNextPage, InsertSectionBreakNextPageCommand);
    this.createCommand(control, RichEditClientCommand.InsertSectionBreakOddPage, InsertSectionBreakOddPageCommand);
    this.createCommand(control, RichEditClientCommand.InsertShiftTabMark, InsertShiftTabCommand);
    this.createCommand(control, RichEditClientCommand.InsertSpace, InsertSpaceCommand);
    this.createCommand(control, RichEditClientCommand.InsertTabMark, InsertTabCommand);
    this.createCommand(control, RichEditClientCommand.InsertTabRuler, InsertTabToParagraphCommand);
    this.createCommand(control, RichEditClientCommand.InsertText, InsertTextCommand);
    this.createCommand(control, RichEditClientCommand.LineDown, LineDownCommand);
    this.createCommand(control, RichEditClientCommand.LineEnd, GoToLineEndCommand);
    this.createCommand(control, RichEditClientCommand.LineStart, GoToLineStartCommand);
    this.createCommand(control, RichEditClientCommand.LineUp, GoToLineAboveCommand);
    this.createCommand(control, RichEditClientCommand.MakeTextLowerCase, MakeTextLowerCaseCommand);
    this.createCommand(control, RichEditClientCommand.MakeTextUpperCase, MakeTextUpperCaseCommand);
    this.createCommand(control, RichEditClientCommand.MoveTabRuler, MoveTabRulerInParagraphCommand);
    this.createCommand(control, RichEditClientCommand.NextCharacter, GoToNextCharacterCommand);
    this.createCommand(control, RichEditClientCommand.NextPage, GoToNextPageCommand);
    this.createCommand(control, RichEditClientCommand.OpenHyperlink, OpenHyperlinkCommand);
    this.createCommand(control, RichEditClientCommand.PasteSelection, PasteSelectionCommand);
    this.createCommand(control, RichEditClientCommand.PreviousCharacter, GoToPrevCharacterCommand);
    this.createCommand(control, RichEditClientCommand.PreviousPage, GoToPrevPageCommand);
    this.createCommand(control, RichEditClientCommand.Redo, RedoCommand);
    this.createCommand(control, RichEditClientCommand.RemoveHyperlink, RemoveHyperlinkCommand);
    this.createCommand(control, RichEditClientCommand.RemoveSpacingAfterParagraph, RemoveSpacingAfterParagraphCommand);
    this.createCommand(control, RichEditClientCommand.RemoveSpacingBeforeParagraph, RemoveSpacingBeforeParagraphCommand);
    this.createCommand(control, RichEditClientCommand.RestartNumberingList, RestartNumberingListCommand);
    this.createCommand(control, RichEditClientCommand.RulerParagraphLeftIndents, RulerParagraphLeftIndentsCommand);
    this.createCommand(control, RichEditClientCommand.RulerParagraphRightIndent, RulerParagraphRightIndentCommand);
    this.createCommand(control, RichEditClientCommand.RulerSectionColumnsSettings, RulerSectionColumnsSettingsCommand);
    this.createCommand(control, RichEditClientCommand.RulerSectionMarginLeft, RulerSectionMarginLeftCommand);
    this.createCommand(control, RichEditClientCommand.RulerSectionMarginRight, RulerSectionMarginRightCommand);
    this.createCommand(control, RichEditClientCommand.RulerChangeTableColumnWidth, RulerChangeTableColumnWidthCommand);
    this.createCommand(control, RichEditClientCommand.RulerChangeTableRowHeight, RulerChangeTableRowHeightCommand);
    this.createCommand(control, RichEditClientCommand.RulerTableColumnSeparators, RulerTableColumnSeparatorsCommand);
    this.createCommand(control, RichEditClientCommand.SelectAll, SelectAllDocumentCommand);
    this.createCommand(control, RichEditClientCommand.SelectLine, SelectLineCommand);
    this.createCommand(control, RichEditClientCommand.SelectLineNoUpdateControlState, SelectLineCommandNoUpdateControlState);
    this.createCommand(control, RichEditClientCommand.AddSelectedLineCommandNoUpdateControlState, AddSelectedLineCommandNoUpdateControlState);
    this.createCommand(control, RichEditClientCommand.SelectParagraph, SelectParagraphCommand);
    this.createCommand(control, RichEditClientCommand.SetDoubleParagraphSpacing, SetDoubleParagraphSpacingCommand);
    this.createCommand(control, RichEditClientCommand.SetLandscapePageOrientation, SetLandscapePageOrientationCommand);
    this.createCommand(control, RichEditClientCommand.SetModerateSectionPageMargins, SetModerateSectionPageMarginsCommand);
    this.createCommand(control, RichEditClientCommand.SetNarrowSectionPageMargins, SetNarrowSectionPageMarginsCommand);
    this.createCommand(control, RichEditClientCommand.SetNormalSectionPageMargins, SetNormalSectionPageMarginsCommand);
    this.createCommand(control, RichEditClientCommand.SetPortraitPageOrientation, SetPortraitPageOrientationCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionA4PaperKind, SetSectionA4PaperKindCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionA5PaperKind, SetSectionA5PaperKindCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionA6PaperKind, SetSectionA6PaperKindCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionB5PaperKind, SetSectionB5PaperKindCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionExecutivePaperKind, SetSectionExecutivePaperKindCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionFolioPaperKind, SetSectionFolioPaperKindCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionLegalPaperKind, SetSectionLegalPaperKindCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionLetterPaperKind, SetSectionLetterPaperKindCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionOneColumn, SetSectionOneColumnCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionThreeColumns, SetSectionThreeColumnsCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionTwoColumns, SetSectionTwoColumnsCommand);
    this.createCommand(control, RichEditClientCommand.SetSesquialteralParagraphSpacing, SetSesquialteralParagraphSpacingCommand);
    this.createCommand(control, RichEditClientCommand.SetSingleParagraphSpacing, SetSingleParagraphSpacingCommand);
    this.createCommand(control, RichEditClientCommand.SetWideSectionPageMargins, SetWideSectionPageMarginsCommand);
    this.createCommand(control, RichEditClientCommand.ShowAllFieldCodes, ShowAllFieldCodesCommand);
    this.createCommand(control, RichEditClientCommand.ShowAllFieldResults, ShowAllFieldResultCommand);
    this.createCommand(control, RichEditClientCommand.ShowBookmarkForm, DialogBookmarksCommand);
    this.createCommand(control, RichEditClientCommand.ShowColumnsSetupForm, DialogColumnsCommand);
    this.createCommand(control, RichEditClientCommand.ShowCustomNumberingListForm, DialogCustomNumberingListCommand);
    this.createCommand(control, RichEditClientCommand.ShowEditHyperlinkForm, DialogEditHyperlinkCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorAuthExceptionMessageCommand, ShowErrorAuthExceptionMessageCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorClipboardAccessDeniedMessageCommand, ShowWarningClipboardAccessDeniedMessageCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorDocVariableErrorCommand, ShowErrorDocVariableExceptionCommand);
    this.createCommand(control, RichEditClientCommand.ShowInsertContentFromServerErrorDialogCommand, ShowInsertContentFromServerErrorDialogCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorInnerExceptionMessageCommand, ShowErrorInnerExceptionMessageCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorModelIsChangedMessageCommand, ShowErrorModelIsChangedMessageCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorOpeningAndOverstoreImpossibleMessageCommand, ShowErrorOpeningAndOverstoreImpossibleMessageCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorCantSaveToEmptyPathMessageCommand, ShowErrorCantSaveToEmptyPathMessageCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorOpeningMessageCommand, ShowErrorCantOpenDocument);
    this.createCommand(control, RichEditClientCommand.ShowErrorSavingMessageCommand, ShowErrorCantSaveDocument);
    this.createCommand(control, RichEditClientCommand.ShowErrorPathTooLongCommand, ShowErrorPathTooLong);
    this.createCommand(control, RichEditClientCommand.ShowErrorSessionHasExpiredMessageCommand, ShowErrorSessionHasExpiredMessageCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorInvalidDocumentFormat, ShowErrorInvalidDocumentMessageCommand);
    this.createCommand(control, RichEditClientCommand.ShowFinishAndMergeForm, DialogFinishAndMergeCommand);
    this.createCommand(control, RichEditClientCommand.ShowFontForm, DialogFontCommand);
    this.createCommand(control, RichEditClientCommand.ShowHyperlinkForm, DialogCreateOrEditHyperlinkCommand);
    this.createCommand(control, RichEditClientCommand.ShowInsertMergeFieldForm, DialogInsertMergeFieldCommand);
    this.createCommand(control, RichEditClientCommand.ShowInsertTableForm, DialogInsertTableCommand);
    this.createCommand(control, RichEditClientCommand.ShowNumberingListForm, DialogNumberingListCommand);
    this.createCommand(control, RichEditClientCommand.ShowPageMarginsSetupForm, DialogPageSetupCommand);
    this.createCommand(control, RichEditClientCommand.ShowPagePaperSetupForm, ShowPagePaperSetupFormCommand);
    this.createCommand(control, RichEditClientCommand.ShowPageSetupForm, DialogPageSetupCommand);
    this.createCommand(control, RichEditClientCommand.ShowParagraphForm, DialogParagraphPropertiesCommand);
    this.createCommand(control, RichEditClientCommand.Find, ShowQuickSearchPanelCommand);
    this.createCommand(control, RichEditClientCommand.Replace, DialogFindReplaceCommand);
    this.createCommand(control, RichEditClientCommand.ShowServiceFontForm, DialogServiceFontCommand);
    this.createCommand(control, RichEditClientCommand.ShowServiceSymbolsForm, DialogServiceSymbolsCommand);
    this.createCommand(control, RichEditClientCommand.ShowSymbolForm, DialogSymbolsCommand);
    this.createCommand(control, RichEditClientCommand.ShowTablePropertiesForm, DialogTablePropertiesCommand);
    this.createCommand(control, RichEditClientCommand.ShowCellOptionsForm, DialogCellPropertiesCommand);
    this.createCommand(control, RichEditClientCommand.ShowBorderShadingForm, DialogBorderShadingCommand);
    this.createCommand(control, RichEditClientCommand.ShowServiceBorderShadingForm, DialogServiceBorderShadingCommand);
    this.createCommand(control, RichEditClientCommand.ShowTabsForm, DialogTabsCommand);
    this.createCommand(control, RichEditClientCommand.ToggleAllFields, ToggleAllFieldsCommand);
    this.createCommand(control, RichEditClientCommand.ToggleBackspaceKey, BackspaceCommand);
    this.createCommand(control, RichEditClientCommand.ToggleBulletedListItem, ToggleBulletedListCommand);
    this.createCommand(control, RichEditClientCommand.ToggleDeleteKey, DeleteCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFieldCodes, ToggleFieldCodesCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFontDoubleUnderline, ToggleFontDoubleUnderlineCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFontItalic, ToggleFontItalicCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFontStrikeout, ToggleFontStrikeoutCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFontSubscript, ToggleFontSubscriptCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFontSuperscript, ToggleFontSuperscriptCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFontUnderline, ToggleFontSingleUnderlineCommand);
    this.createCommand(control, RichEditClientCommand.ToggleMultilevelListItem, ToggleMultiLevelListCommand);
    this.createCommand(control, RichEditClientCommand.ToggleNumberingListItem, ToggleNumberingListCommand);
    this.createCommand(control, RichEditClientCommand.ToggleParagraphAlignmentCenter, ToggleParagraphAlignmentCenterCommand);
    this.createCommand(control, RichEditClientCommand.ToggleParagraphAlignmentJustify, ToggleParagraphAlignmentJustifyCommand);
    this.createCommand(control, RichEditClientCommand.ToggleParagraphAlignmentLeft, ToggleParagraphAlignmentLeftCommand);
    this.createCommand(control, RichEditClientCommand.ToggleParagraphAlignmentRight, ToggleParagraphAlignmentRightCommand);
    this.createCommand(control, RichEditClientCommand.ToggleShowHorizontalRuler, ToggleShowHorizontalRulerCommand);
    this.createCommand(control, RichEditClientCommand.ToggleShowWhitespace, ToggleShowHiddenSymbolsCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTextCase, ToggleTextCaseCommand);
    this.createCommand(control, RichEditClientCommand.ToggleViewMergedData, ToggleViewMergedDataCommand);
    this.createCommand(control, RichEditClientCommand.Undo, UndoCommand);
    this.createCommand(control, RichEditClientCommand.UpdateAllFields, UpdateAllFieldsCommand);
    this.createCommand(control, RichEditClientCommand.UpdateField, UpdateFieldCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFontBold, ToggleFontBoldCommand);
    this.createCommand(control, RichEditClientCommand.InsertNonBreakingSpace, InsertNonBreakingSpaceCommand);
    this.createCommand(control, RichEditClientCommand.RemoveHyperlinks, RemoveHyperlinksCommand);
    this.createCommand(control, RichEditClientCommand.CreateDateField, CreateDateFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreatePageField, CreatePageFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateTimeField, CreateTimeFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateMergeField, CreateMergeFieldCommand);
    this.createCommand(control, RichEditClientCommand.ShowCreateHyperlinkForm, DialogCreateHyperlinkCommand);
    this.createCommand(control, RichEditClientCommand.SentenceCase, SentenceCaseCommand);
    this.createCommand(control, RichEditClientCommand.SwitchTextCase, SwitchTextCaseCommand);
    this.createCommand(control, RichEditClientCommand.InsertHeader, InsertHeaderCommand);
    this.createCommand(control, RichEditClientCommand.InsertFooter, InsertFooterCommand);
    this.createCommand(control, RichEditClientCommand.LinkHeaderFooterToPrevious, LinkHeaderFooterToPreviousCommand);
    this.createCommand(control, RichEditClientCommand.CreateBookmark, CreateBookmarkCommand);
    this.createCommand(control, RichEditClientCommand.DeleteBookmarks, DeleteBookmarksCommand);
    this.createCommand(control, RichEditClientCommand.GoToBookmark, GoToBookmarkCommand);
    this.createCommand(control, RichEditClientCommand.ContextItem_HeadersFooters, ContextItemHeadersFooters);
    this.createCommand(control, RichEditClientCommand.ClosePageHeaderFooter, CloseHeaderFooterCommand);
    this.createCommand(control, RichEditClientCommand.GoToPageHeader, GoToHeaderCommand);
    this.createCommand(control, RichEditClientCommand.GoToPageFooter, GoToFooterCommand);
    this.createCommand(control, RichEditClientCommand.GoToNextPageHeaderFooter, GoToNextHeaderFooterCommand);
    this.createCommand(control, RichEditClientCommand.GoToPreviousPageHeaderFooter, GoToPreviousHeaderFooterCommand);
    this.createCommand(control, RichEditClientCommand.ToggleDifferentFirstPage, DifferentFirstPageHeaderFooterCommand);
    this.createCommand(control, RichEditClientCommand.ToggleDifferentOddAndEvenPages, DifferentOddEvenHeaderFooterCommand);
    this.createCommand(control, RichEditClientCommand.ChangeHeaderOffset, ChangeHeaderOffsetRibbonCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFooterOffset, ChangeFooterOffsetRibbonCommand);
    this.createCommand(control, RichEditClientCommand.InsertPageNumberField, CreatePageFieldCommand);
    this.createCommand(control, RichEditClientCommand.InsertPageCountField, CreatePageCountFieldCommand);
    this.createCommand(control, RichEditClientCommand.InsertTableCore, InsertTableCoreCommand);
    this.createCommand(control, RichEditClientCommand.ContextItem_Tables, ContextItemTables);
    this.createCommand(control, RichEditClientCommand.InsertTableColumnToTheLeft, InsertTableColumnToTheLeftCommand);
    this.createCommand(control, RichEditClientCommand.InsertTableColumnToTheRight, InsertTableColumnToTheRightCommand);
    this.createCommand(control, RichEditClientCommand.InsertTableRowAbove, InsertTableRowAboveCommand);
    this.createCommand(control, RichEditClientCommand.InsertTableRowBelow, InsertTableRowBelowCommand);
    this.createCommand(control, RichEditClientCommand.DeleteTableRows, DeleteTableRowsCommand);
    this.createCommand(control, RichEditClientCommand.DeleteTableColumns, DeleteTableColumnsCommand);
    this.createCommand(control, RichEditClientCommand.InsertTableCellWithShiftToTheLeft, InsertTableCellWithShiftToTheLeftCommand);
    this.createCommand(control, RichEditClientCommand.DeleteTableCellsWithShiftToTheHorizontally, DeleteTableCellsWithShiftToTheHorizontallyCommand);
    this.createCommand(control, RichEditClientCommand.DeleteTable, DeleteTableCommand);
    this.createCommand(control, RichEditClientCommand.DeleteTableCellsByBackspace, DeleteTableCellsByBackspaceCommand);
    this.createCommand(control, RichEditClientCommand.ShowInsertTableCellsForm, DialogInsertTableCellsCommand);
    this.createCommand(control, RichEditClientCommand.ShowDeleteTableCellsForm, DialogDeleteTableCellsCommand);
    this.createCommand(control, RichEditClientCommand.MergeTableCells, MergeTableCellsCommand);
    this.createCommand(control, RichEditClientCommand.ShowSplitTableCellsForm, DialogSplitTableCellsCommand);
    this.createCommand(control, RichEditClientCommand.SplitTableCellsCommand, SplitTableCellsCommand);
    this.createCommand(control, RichEditClientCommand.InsertTableCellsWithShiftToTheVertically, InsertTableCellsWithShiftToTheVerticallyCommand);
    this.createCommand(control, RichEditClientCommand.DeleteTableCellsWithShiftToTheVertically, DeleteTableCellsWithShiftToTheVerticallyCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignBottomCenter, ChangeTableCellBottomCenterAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignBottomLeft, ChangeTableCellBottomLeftAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignBottomRight, ChangeTableCellBottomRightAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignMiddleCenter, ChangeTableCellMiddleCenterAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignMiddleLeft, ChangeTableCellMiddleLeftAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignMiddleRight, ChangeTableCellMiddleRightAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignTopCenter, ChangeTableCellTopCenterAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignTopLeft, ChangeTableCellTopLeftAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.TableCellAlignTopRight, ChangeTableCellTopRightAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.ApplyTableStyle, ApplyTableStyleCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellAllBorders, ToggleTableCellAllBordersCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellInsideBorders, ToggleTableCellInsideBordersCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellInsideHorizontalBorders, ToggleTableCellInsideHorizontalBordersCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellInsideVerticalBorders, ToggleTableCellInsideVerticalBordersCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellNoBorder, ToggleTableCellNoBorderCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellOutsideBorders, ToggleTableCellOutsideBordersCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellsBottomBorder, ToggleTableCellsBottomBorderCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellsLeftBorder, ToggleTableCellsLeftBorderCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellsRightBorder, ToggleTableCellsRightBorderCommand);
    this.createCommand(control, RichEditClientCommand.ToggleTableCellsTopBorder, ToggleTableCellsTopBorderCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFirstRow, ToggleFirstRowCommand);
    this.createCommand(control, RichEditClientCommand.ToggleLastRow, ToggleLastRowCommand);
    this.createCommand(control, RichEditClientCommand.ToggleFirstColumn, ToggleFirstColumnCommand);
    this.createCommand(control, RichEditClientCommand.ToggleLastColumn, ToggleLastColumnCommand);
    this.createCommand(control, RichEditClientCommand.ToggleBandedRows, ToggleBandedRowsCommand);
    this.createCommand(control, RichEditClientCommand.ToggleBandedColumn, ToggleBandedColumnCommand);
    this.createCommand(control, RichEditClientCommand.SelectTableCell, SelectTableCellCommand);
    this.createCommand(control, RichEditClientCommand.SelectTableColumn, SelectTableColumnCommand);
    this.createCommand(control, RichEditClientCommand.SelectTableRow, SelectTableRowCommand);
    this.createCommand(control, RichEditClientCommand.SelectTable, SelectTableCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTableBorderColorRepositoryItem, ChangeTableBorderColorRepositoryItemCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTableBorderStyleRepositoryItem, ChangeTableBorderStyleRepositoryItemCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTableBorderWidthRepositoryItem, ChangeTableBorderWidthRepositoryItemCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTableCellShading, ChangeTableCellShadingCommand);
    this.createCommand(control, RichEditClientCommand.ToggleShowTableGridLines, ToggleShowTableGridLinesCommand);
    this.createCommand(control, RichEditClientCommand.ExtendSelectTableCell, ExtendSelectTableCellCommand);
    this.createCommand(control, RichEditClientCommand.ExtendSelectTableColumn, ExtendSelectTableColumnCommand);
    this.createCommand(control, RichEditClientCommand.ExtendSelectTableRow, ExtendSelectTableRowCommand);
    this.createCommand(control, RichEditClientCommand.ExtendSelectTable, ExtendSelectTableCommand);
    this.createCommand(control, RichEditClientCommand.SelectTableCellsRange, SelectTableCellsRangeCommand);
    this.createCommand(control, RichEditClientCommand.ChangeActiveSubDocumentToMain, ChangeActiveSubDocumentToMainCommand);
    this.createCommand(control, RichEditClientCommand.ChangeActiveSubDocumentToHeaderFooterByPageIndex, ChangeActiveSubDocumentToHeaderFooterByPageIndexCommand);
    this.createCommand(control, RichEditClientCommand.ChangeActiveSubDocumentToHeaderFooterBySubDocument, ChangeActiveSubDocumentToHeaderFooterBySubDocumentCommand);
    this.createCommand(control, RichEditClientCommand.ChangeActiveSubDocumentToTextBox, ChangeActiveSubDocumentToTextBoxCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionPageSize, SetSectionPageSizeCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionColumnCount, SetSectionColumnCountCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTableRowHeight, ChangeTableRowHeightCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTableCellWidth, ChangeTableCellWidthCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTableColumnWidth, ChangeTableColumnWidthCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTableLook, ChangeTableLookCommand);
    this.createCommand(control, RichEditClientCommand.ShowSpellCheckerForm, DialogSpellCheckerCommand);
    this.createCommand(control, RichEditClientCommand.IgnoreSpellingError, IgnoreSpellingErrorCommand);
    this.createCommand(control, RichEditClientCommand.IgnoreAllSpellingErrors, IgnoreAllSpellingErrorsCommand);
    this.createCommand(control, RichEditClientCommand.DeleteRepeatedWord, DeleteRepeatedWordCommand);
    this.createCommand(control, RichEditClientCommand.AddWordToDictionary, AddWordToDictionary);
    this.createCommand(control, RichEditClientCommand.ChangeSpellingError, ChangeSpellingErrorCommand);
    this.createCommand(control, RichEditClientCommand.NoSpellingSuggestions, NoSpellingSuggestionsCommand);
    this.createCommand(control, RichEditClientCommand.ChangeAllSpellingErrors, ChangeAllSpellingErrorsCommand);
    this.createCommand(control, RichEditClientCommand.ShowSpellingCheckCompletedMessage, ShowSpellingCheckCompletedCommand);
    this.createCommand(control, RichEditClientCommand.GoToDataRecord, GoToRecordCommandBase);
    this.createCommand(control, RichEditClientCommand.FindAll, FindAllCommand);
    this.createCommand(control, RichEditClientCommand.ReplaceNext, ReplaceNextCommand);
    this.createCommand(control, RichEditClientCommand.ReplaceAll, ReplaceAllCommand);
    this.createCommand(control, RichEditClientCommand.HideFindResults, HideFindResultsCommand);
    this.createCommand(control, RichEditClientCommand.RemoveNextWord, RemoveNextWordCommand);
    this.createCommand(control, RichEditClientCommand.RemovePrevWord, RemovePrevWordCommand);
    this.createCommand(control, RichEditClientCommand.GoToStartNextPage, GoToStartNextPageCommand);
    this.createCommand(control, RichEditClientCommand.GoToStartPrevPage, GoToStartPrevPageCommand);
    this.createCommand(control, RichEditClientCommand.ExtendGoToStartNextPage, ExtendGoToStartNextPageCommand);
    this.createCommand(control, RichEditClientCommand.ExtendGoToStartPrevPage, ExtendGoToStartPrevPageCommand);
    this.createCommand(control, RichEditClientCommand.AssignShortcut, AssignShortcutCommand);
    this.createCommand(control, RichEditClientCommand.SetSectionPageMargins, SetSectionPageMarginsCommand);
    this.createCommand(control, RichEditClientCommand.ShowLayoutOptionsForm, DialogLayoutOptionsCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectSquareTextWrapType, SetFloatingObjectSquareTextWrapTypeCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectTightTextWrapType, SetFloatingObjectTightTextWrapTypeCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectThroughTextWrapType, SetFloatingObjectThroughTextWrapTypeCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectTopAndBottomTextWrapType, SetFloatingObjectTopAndBottomTextWrapTypeCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectBehindTextWrapType, SetFloatingObjectBehindTextWrapTypeCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectInFrontOfTextWrapType, SetFloatingObjectInFrontOfTextWrapTypeCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectInlineTextWrapType, SetFloatingObjectInlineTextWrapTypeCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectTopLeftAlignment, SetFloatingObjectTopLeftAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectTopCenterAlignment, SetFloatingObjectTopCenterAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectTopRightAlignment, SetFloatingObjectTopRightAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectMiddleLeftAlignment, SetFloatingObjectMiddleLeftAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectMiddleCenterAlignment, SetFloatingObjectMiddleCenterAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectMiddleRightAlignment, SetFloatingObjectMiddleRightAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectBottomLeftAlignment, SetFloatingObjectBottomLeftAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectBottomCenterAlignment, SetFloatingObjectBottomCenterAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.SetFloatingObjectBottomRightAlignment, SetFloatingObjectBottomRightAlignmentCommand);
    this.createCommand(control, RichEditClientCommand.ContextItem_FloatingObjects, ContextItemFloatingObjects);
    this.createCommand(control, RichEditClientCommand.ChangeFloatingObjectTextWrapTypeMenu, ChangeFloatingObjectTextWrapTypeMenu);
    this.createCommand(control, RichEditClientCommand.FloatingObjectBringForwardMenu, FloatingObjectBringForwardMenu);
    this.createCommand(control, RichEditClientCommand.FloatingObjectSendBackwardMenu, FloatingObjectSendBackwardMenu);
    this.createCommand(control, RichEditClientCommand.InsertAnchoredTextBox, InsertAnchoredTextBoxCommand);
    this.createCommand(control, RichEditClientCommand.FloatingObjectBringForward, FloatingObjectBringForwardCommand);
    this.createCommand(control, RichEditClientCommand.FloatingObjectBringToFront, FloatingObjectBringToFrontCommand);
    this.createCommand(control, RichEditClientCommand.FloatingObjectBringInFrontOfText, FloatingObjectBringInFrontOfTextCommand);
    this.createCommand(control, RichEditClientCommand.FloatingObjectSendBackward, FloatingObjectSendBackwardCommand);
    this.createCommand(control, RichEditClientCommand.FloatingObjectSendToBack, FloatingObjectSendToBackCommand);
    this.createCommand(control, RichEditClientCommand.FloatingObjectSendBehindText, FloatingObjectSendBehindTextCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFloatingObjectFillColor, ChangeShapeFillColorCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFloatingObjectOutlineColor, ChangeShapeOutlineColorCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFloatingObjectOutlineWidth, ChangeShapeOutlineWidthCommand);
    this.createCommand(control, RichEditClientCommand.ChangeFloatingObjectShape, ChangeFloatingObjectShapeCommand);
    this.createCommand(control, RichEditClientCommand.ChangeTextBoxProperties, ChangeTextBoxPropertiesCommand);
    this.createCommand(control, RichEditClientCommand.CreateEmptyMergeField, CreateEmptyMergeFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateEmptyDocVariableField, CreateEmptyDocVariableFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateEmptyIfField, CreateEmptyIfFieldCommand);
    this.createCommand(control, RichEditClientCommand.FloatingObjectDragDropChangePosition, FloatingObjectDragDropChangePositionCommand);
    this.createCommand(control, RichEditClientCommand.InsertHtml, InsertHtmlCommand);
    this.createCommand(control, RichEditClientCommand.CreateTocField, CreateTocFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateTableOfEquationsField, CreateTableOfEquationsFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateTableOfFiguresField, CreateTableOfFiguresFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateTableOfTablesField, CreateTableOfTablesFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateEquationCaptionField, CreateEquationCaptionFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateFigureCaptionField, CreateFigureCaptionFieldCommand);
    this.createCommand(control, RichEditClientCommand.CreateTableCaptionField, CreateTableCaptionFieldCommand);
    this.createCommand(control, RichEditClientCommand.UpdateTableOfContents, UpdateTableOfContentsCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphBodyTextLevel, SetParagraphBodyTextLevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading1Level, SetParagraphHeading1LevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading2Level, SetParagraphHeading2LevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading3Level, SetParagraphHeading3LevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading4Level, SetParagraphHeading4LevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading5Level, SetParagraphHeading5LevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading6Level, SetParagraphHeading6LevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading7Level, SetParagraphHeading7LevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading8Level, SetParagraphHeading8LevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphHeading9Level, SetParagraphHeading9LevelCommand);
    this.createCommand(control, RichEditClientCommand.ChangeHeadingLevel, ChangeHeadingLevelCommand);
    this.createCommand(control, RichEditClientCommand.SetParagraphLevel, SetParagraphLevelCommand);
    this.createCommand(control, RichEditClientCommand.CreateFieldWithCode, CreateFieldWithCodeCommand);
    this.createCommand(control, RichEditClientCommand.ChangeHyperlink, ChangeHyperlinkCommand);
    this.createCommand(control, RichEditClientCommand.SetAutoFitContents, AutoFitContentsCommand);
    this.createCommand(control, RichEditClientCommand.SetAutoFitWindow, AutoFitWindowCommand);
    this.createCommand(control, RichEditClientCommand.SetFixedColumnWidth, FixedColumnWidthCommand);
    this.createCommand(control, RichEditClientCommand.ChangeViewType, ChangeViewTypeCommand);
    this.createCommand(control, RichEditClientCommand.SwitchToSimpleView, SwitchToSimpleViewCommand);
    this.createCommand(control, RichEditClientCommand.SwitchToPrintLayoutView, SwitchToPrintLayoutViewCommand);
    this.createCommand(control, RichEditClientCommand.ShowErrorLoadPictureMessage, ShowLoadPictureErrorDialogCommand);
    this.assingCommand(RichEditClientCommand.PrintDocumentOnClient, new PrintDocumentOnClient(control, printNonce));
  }
  commandIsEnabled(commandId) {
    const value = this.publicEnabledState[commandId];
    return value === undefined ? true : value;
  }
  setCommandEnabled(commandId, enabled) {
    this.publicEnabledState[commandId] = enabled;
  }
  dispose() {
    this.formatImagesImporters.forEach(importer => importer.dispose());
    clearTimeout(this.clipboardTimerId);
    clearTimeout(this.printTimerId);
  }
  abortClipboardCommandExecution() {
    clearTimeout(this.clipboardTimerId);
    this.clipboardTimerId = null;
  }
  getCommand(key) {
    return this.commands[key];
  }
  beforeExecuting(command) {
    this.executingCommandsChain.push(command);
    this.executingCommandCounter++;
  }
  afterExecuting() {
    this.executingCommandCounter--;
    if (this.executingCommandCounter === 0) {
      this.lastCommandsChain = this.executingCommandsChain;
      this.executingCommandsChain = [];
    }
  }
  assertLastExecutedCommandsChain(checkLength, ...types) {
    if (checkLength && this.lastCommandsChain.length !== types.length) return false;
    for (var i = 0, type; type = types[i]; i++) {
      if (!this.lastCommandsChain[i] || !(this.lastCommandsChain[i] instanceof type)) return false;
    }
    return true;
  }
  NotifySelectionChanged(_selection) {
    this.lastCommandsChain = [];
  }
  NotifyScrollPositionChanged() {}
  createCommand(control, commandId, commandType) {
    const command = new commandType(control);
    command.commandId = commandId;
    this.commands[commandId] = command;
  }
  assingCommand(commandId, command) {
    command.commandId = commandId;
    this.commands[commandId] = command;
  }
}