export var JSONAbstractNumberingListProperty;
(function (JSONAbstractNumberingListProperty) {
  JSONAbstractNumberingListProperty[JSONAbstractNumberingListProperty["Deleted"] = 0] = "Deleted";
  JSONAbstractNumberingListProperty[JSONAbstractNumberingListProperty["Id"] = 1] = "Id";
  JSONAbstractNumberingListProperty[JSONAbstractNumberingListProperty["Levels"] = 2] = "Levels";
})(JSONAbstractNumberingListProperty || (JSONAbstractNumberingListProperty = {}));
export var JSONNumberingListProperty;
(function (JSONNumberingListProperty) {
  JSONNumberingListProperty[JSONNumberingListProperty["AlIndex"] = 0] = "AlIndex";
  JSONNumberingListProperty[JSONNumberingListProperty["Id"] = 1] = "Id";
  JSONNumberingListProperty[JSONNumberingListProperty["Levels"] = 2] = "Levels";
})(JSONNumberingListProperty || (JSONNumberingListProperty = {}));
export var JSONNumberingListLevelProperty;
(function (JSONNumberingListLevelProperty) {
  JSONNumberingListLevelProperty[JSONNumberingListLevelProperty["CharacterPropertiesIndex"] = 0] = "CharacterPropertiesIndex";
  JSONNumberingListLevelProperty[JSONNumberingListLevelProperty["ParagraphPropertiesIndex"] = 1] = "ParagraphPropertiesIndex";
  JSONNumberingListLevelProperty[JSONNumberingListLevelProperty["ListLevelPropertiesIndex"] = 2] = "ListLevelPropertiesIndex";
  JSONNumberingListLevelProperty[JSONNumberingListLevelProperty["MAX_VALUE"] = 3] = "MAX_VALUE";
})(JSONNumberingListLevelProperty || (JSONNumberingListLevelProperty = {}));
export var JSONNumberingOverrideListLevelProperty;
(function (JSONNumberingOverrideListLevelProperty) {
  JSONNumberingOverrideListLevelProperty[JSONNumberingOverrideListLevelProperty["NewStart"] = 3] = "NewStart";
  JSONNumberingOverrideListLevelProperty[JSONNumberingOverrideListLevelProperty["OverrideStart"] = 4] = "OverrideStart";
  JSONNumberingOverrideListLevelProperty[JSONNumberingOverrideListLevelProperty["Level"] = 5] = "Level";
})(JSONNumberingOverrideListLevelProperty || (JSONNumberingOverrideListLevelProperty = {}));
export var JSONDeleteAbstractNumberingListCommandProperty;
(function (JSONDeleteAbstractNumberingListCommandProperty) {
  JSONDeleteAbstractNumberingListCommandProperty[JSONDeleteAbstractNumberingListCommandProperty["Index"] = 0] = "Index";
})(JSONDeleteAbstractNumberingListCommandProperty || (JSONDeleteAbstractNumberingListCommandProperty = {}));
export var JSONAddAbstractNumberingListCommandPropery;
(function (JSONAddAbstractNumberingListCommandPropery) {
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["InnerId"] = 0] = "InnerId";
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["Deleted"] = 1] = "Deleted";
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["Levels"] = 2] = "Levels";
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["ParagraphProperties"] = 3] = "ParagraphProperties";
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["ListLevelProperties"] = 4] = "ListLevelProperties";
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["CharacterProperties"] = 5] = "CharacterProperties";
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["OverrideStart"] = 6] = "OverrideStart";
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["NewStart"] = 7] = "NewStart";
  JSONAddAbstractNumberingListCommandPropery[JSONAddAbstractNumberingListCommandPropery["AbstractNumberingListIndex"] = 8] = "AbstractNumberingListIndex";
})(JSONAddAbstractNumberingListCommandPropery || (JSONAddAbstractNumberingListCommandPropery = {}));
export var JSONIOverrideListLevelProperty;
(function (JSONIOverrideListLevelProperty) {
  JSONIOverrideListLevelProperty[JSONIOverrideListLevelProperty["NewStart"] = 0] = "NewStart";
  JSONIOverrideListLevelProperty[JSONIOverrideListLevelProperty["OverrideStart"] = 1] = "OverrideStart";
})(JSONIOverrideListLevelProperty || (JSONIOverrideListLevelProperty = {}));
export var JSONListLevelProperty;
(function (JSONListLevelProperty) {
  JSONListLevelProperty[JSONListLevelProperty["Start"] = 0] = "Start";
  JSONListLevelProperty[JSONListLevelProperty["Format"] = 1] = "Format";
  JSONListLevelProperty[JSONListLevelProperty["ConvertPreviousLevelNumberingToDecimal"] = 2] = "ConvertPreviousLevelNumberingToDecimal";
  JSONListLevelProperty[JSONListLevelProperty["SuppressBulletResize"] = 3] = "SuppressBulletResize";
  JSONListLevelProperty[JSONListLevelProperty["SuppressRestart"] = 4] = "SuppressRestart";
  JSONListLevelProperty[JSONListLevelProperty["Alignment"] = 5] = "Alignment";
  JSONListLevelProperty[JSONListLevelProperty["DisplayFormatString"] = 6] = "DisplayFormatString";
  JSONListLevelProperty[JSONListLevelProperty["RelativeRestartLevel"] = 7] = "RelativeRestartLevel";
  JSONListLevelProperty[JSONListLevelProperty["Separator"] = 8] = "Separator";
  JSONListLevelProperty[JSONListLevelProperty["TemplateCode"] = 9] = "TemplateCode";
  JSONListLevelProperty[JSONListLevelProperty["OriginalLeftIndent"] = 10] = "OriginalLeftIndent";
  JSONListLevelProperty[JSONListLevelProperty["Legacy"] = 11] = "Legacy";
  JSONListLevelProperty[JSONListLevelProperty["LegacySpace"] = 12] = "LegacySpace";
  JSONListLevelProperty[JSONListLevelProperty["LegacyIndent"] = 13] = "LegacyIndent";
})(JSONListLevelProperty || (JSONListLevelProperty = {}));