import { __awaiter } from "tslib";
import { NotePosition } from "../../../../../../../common/model/footnotes/footnote";
import { NumberingFormat } from "../../../../../../../common/model/numbering-lists/list-level-properties";
import { MapCreator } from "../../../../../../../common/utils/map-creator";
import { StringUtils } from "@devexpress/utils/lib/utils/string";
import { LeafElementDestination } from "../../destination";
import { NotePropertiesDestination } from "../note-properties-destination";
export class SectionLevelEndNotePropertiesDestination extends NotePropertiesDestination {
  constructor(data) {
    super(data, NotePosition.BottomOfPage, NumberingFormat.Decimal);
  }
  setProperties(notes) {
    this.data.sectionImporter.properties.endNote = notes;
  }
}
export class DocumentLevelEndNotePropertiesDestination extends NotePropertiesDestination {
  constructor(data) {
    super(data, NotePosition.BottomOfPage, NumberingFormat.Decimal);
  }
  get elementHandlerTable() {
    return DocumentLevelEndNotePropertiesDestination.handlerTable;
  }
  setProperties(notes) {
    this.data.documentModel.sections[0].sectionProperties.endNote = notes;
  }
  processElementClose(reader) {
    const _super = Object.create(null, {
      processElementClose: {
        get: () => super.processElementClose
      }
    });
    return __awaiter(this, void 0, void 0, function* () {
      _super.processElementClose.call(this, reader);
      this.data.documentModel.documentEndNotePosition = this.notes.position;
    });
  }
}
DocumentLevelEndNotePropertiesDestination.handlerTable = new MapCreator().append(NotePropertiesDestination.handlerTable).add('endnote', data => new EndNoteSeparatorDestination(data)).get();
class EndNoteSeparatorDestination extends LeafElementDestination {
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      const id = reader.getAttributeNS("id", this.data.constants.wordProcessingNamespaceConst);
      if (!StringUtils.isNullOrEmpty(id)) this.data.endNotesImporter.separatorIds.push(id);
    });
  }
}