import { HistoryItem } from './history-item';
export class IntervalBasedHistoryItem extends HistoryItem {
  get boundSubDocument() {
    return this.subDocInterval.subDocument;
  }
  get interval() {
    return this.subDocInterval.interval;
  }
  constructor(modelManipulator, subDocInterval) {
    super(modelManipulator);
    this.subDocInterval = subDocInterval;
  }
}