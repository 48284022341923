export var CodePages;
(function (CodePages) {
  CodePages[CodePages["default"] = 1250] = "default";
  CodePages[CodePages["GB2315"] = 936] = "GB2315";
  CodePages[CodePages["UTF8"] = 65001] = "UTF8";
  CodePages[CodePages["Russian"] = 1251] = "Russian";
  CodePages[CodePages["Ansi"] = 1252] = "Ansi";
  CodePages[CodePages["Greek"] = 1253] = "Greek";
  CodePages[CodePages["Turkis"] = 1254] = "Turkis";
  CodePages[CodePages["Baltic"] = 1257] = "Baltic";
  CodePages[CodePages["Vietnamese"] = 1258] = "Vietnamese";
  CodePages[CodePages["Hebrew"] = 1255] = "Hebrew";
  CodePages[CodePages["Arabic"] = 1256] = "Arabic";
  CodePages[CodePages["Hangul"] = 949] = "Hangul";
  CodePages[CodePages["Big5"] = 950] = "Big5";
  CodePages[CodePages["MacRoman"] = 10000] = "MacRoman";
  CodePages[CodePages["MacRussian"] = 10007] = "MacRussian";
})(CodePages || (CodePages = {}));