export var JSONModelPropertyProperty;
(function (JSONModelPropertyProperty) {
  JSONModelPropertyProperty[JSONModelPropertyProperty["DefaultTabWidth"] = 0] = "DefaultTabWidth";
  JSONModelPropertyProperty[JSONModelPropertyProperty["OddEvenPages"] = 1] = "OddEvenPages";
  JSONModelPropertyProperty[JSONModelPropertyProperty["PageColor"] = 2] = "PageColor";
})(JSONModelPropertyProperty || (JSONModelPropertyProperty = {}));
export var JSONDocumentModelProperty;
(function (JSONDocumentModelProperty) {
  JSONDocumentModelProperty[JSONDocumentModelProperty["DocumentProperties"] = 0] = "DocumentProperties";
  JSONDocumentModelProperty[JSONDocumentModelProperty["Styles"] = 1] = "Styles";
  JSONDocumentModelProperty[JSONDocumentModelProperty["Sections"] = 2] = "Sections";
  JSONDocumentModelProperty[JSONDocumentModelProperty["AbstractNumberingLists"] = 3] = "AbstractNumberingLists";
  JSONDocumentModelProperty[JSONDocumentModelProperty["NumberingLists"] = 4] = "NumberingLists";
  JSONDocumentModelProperty[JSONDocumentModelProperty["Headers"] = 5] = "Headers";
  JSONDocumentModelProperty[JSONDocumentModelProperty["Footers"] = 6] = "Footers";
  JSONDocumentModelProperty[JSONDocumentModelProperty["AbstractNumberingListTemplates"] = 7] = "AbstractNumberingListTemplates";
  JSONDocumentModelProperty[JSONDocumentModelProperty["Images"] = 8] = "Images";
  JSONDocumentModelProperty[JSONDocumentModelProperty["PaletteColors"] = 9] = "PaletteColors";
  JSONDocumentModelProperty[JSONDocumentModelProperty["OfficeThemeColors"] = 10] = "OfficeThemeColors";
  JSONDocumentModelProperty[JSONDocumentModelProperty["CompatibilitySettings"] = 11] = "CompatibilitySettings";
})(JSONDocumentModelProperty || (JSONDocumentModelProperty = {}));
export var JSONDocumentProtectionPropertiesProperty;
(function (JSONDocumentProtectionPropertiesProperty) {
  JSONDocumentProtectionPropertiesProperty[JSONDocumentProtectionPropertiesProperty["EnforceProtection"] = 0] = "EnforceProtection";
  JSONDocumentProtectionPropertiesProperty[JSONDocumentProtectionPropertiesProperty["ProtectionType"] = 1] = "ProtectionType";
  JSONDocumentProtectionPropertiesProperty[JSONDocumentProtectionPropertiesProperty["HashAlgorithmType"] = 2] = "HashAlgorithmType";
  JSONDocumentProtectionPropertiesProperty[JSONDocumentProtectionPropertiesProperty["HashIterationCount"] = 3] = "HashIterationCount";
  JSONDocumentProtectionPropertiesProperty[JSONDocumentProtectionPropertiesProperty["PasswordHash"] = 4] = "PasswordHash";
  JSONDocumentProtectionPropertiesProperty[JSONDocumentProtectionPropertiesProperty["PasswordPrefix"] = 5] = "PasswordPrefix";
  JSONDocumentProtectionPropertiesProperty[JSONDocumentProtectionPropertiesProperty["Word2003PasswordHash"] = 6] = "Word2003PasswordHash";
  JSONDocumentProtectionPropertiesProperty[JSONDocumentProtectionPropertiesProperty["OpenOfficePasswordHash"] = 7] = "OpenOfficePasswordHash";
})(JSONDocumentProtectionPropertiesProperty || (JSONDocumentProtectionPropertiesProperty = {}));
export var DocumentProtectionType;
(function (DocumentProtectionType) {
  DocumentProtectionType[DocumentProtectionType["None"] = 0] = "None";
  DocumentProtectionType[DocumentProtectionType["ReadOnly"] = 1] = "ReadOnly";
  DocumentProtectionType[DocumentProtectionType["AllowComments"] = 2] = "AllowComments";
  DocumentProtectionType[DocumentProtectionType["TrackedChanges"] = 3] = "TrackedChanges";
})(DocumentProtectionType || (DocumentProtectionType = {}));
export var JSONDocumentPropertiesProperty;
(function (JSONDocumentPropertiesProperty) {
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["DefaultTabWidth"] = 0] = "DefaultTabWidth";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["DifferentOddAndEvenPages"] = 1] = "DifferentOddAndEvenPages";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["DisplayBackgroundShape"] = 2] = "DisplayBackgroundShape";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["PageBackColor"] = 3] = "PageBackColor";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["DefaultCharacterProperties"] = 4] = "DefaultCharacterProperties";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["DefaultParagraphProperties"] = 5] = "DefaultParagraphProperties";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["DefaultTableProperties"] = 6] = "DefaultTableProperties";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["DefaultTableRowProperties"] = 7] = "DefaultTableRowProperties";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["DefaultTableCellProperties"] = 8] = "DefaultTableCellProperties";
  JSONDocumentPropertiesProperty[JSONDocumentPropertiesProperty["ProtectionProperties"] = 9] = "ProtectionProperties";
})(JSONDocumentPropertiesProperty || (JSONDocumentPropertiesProperty = {}));
export var JSONCompatibilitySettingsProperty;
(function (JSONCompatibilitySettingsProperty) {
  JSONCompatibilitySettingsProperty[JSONCompatibilitySettingsProperty["DontJustifyLinesEndingInSoftLineBreak"] = 0] = "DontJustifyLinesEndingInSoftLineBreak";
  JSONCompatibilitySettingsProperty[JSONCompatibilitySettingsProperty["CompatibilityMode"] = 1] = "CompatibilityMode";
})(JSONCompatibilitySettingsProperty || (JSONCompatibilitySettingsProperty = {}));