export const RICH_EDIT_CLASS_NAME_PREFIX = "dxre-";
export const RULER_CLASS_NAME = RICH_EDIT_CLASS_NAME_PREFIX + "ruler";
export const RULLER_NUMBER_CORRECTION = 5;
export class RulerSettings {}
export var RulerVisibility;
(function (RulerVisibility) {
  RulerVisibility[RulerVisibility["Auto"] = 0] = "Auto";
  RulerVisibility[RulerVisibility["Visible"] = 1] = "Visible";
  RulerVisibility[RulerVisibility["Hidden"] = 2] = "Hidden";
})(RulerVisibility || (RulerVisibility = {}));
export class RulerTitles {}
export class RulerStyles {}
export class RulerTabImages {}
export class RulerStyleInfo {}
export class RulerSpriteInfo {}