import { ModelChangeType } from '../../enums';
export class ParagraphNumberingListChangedSubDocumentChange {
  get subDocumentId() {
    return this.subDocument.id;
  }
  constructor(subDocument, newState, oldAbstractNumberingListIndex) {
    this.subDocument = subDocument;
    this.newState = newState;
    this.oldAbstractNumberingListIndex = oldAbstractNumberingListIndex;
    this.type = ModelChangeType.ParagraphNumberingListChanged;
  }
}