export var JSONEnumTableCell;
(function (JSONEnumTableCell) {
  JSONEnumTableCell[JSONEnumTableCell["StyleIndex"] = 0] = "StyleIndex";
  JSONEnumTableCell[JSONEnumTableCell["TableCellPropertiesIndex"] = 1] = "TableCellPropertiesIndex";
  JSONEnumTableCell[JSONEnumTableCell["ColumnSpan"] = 2] = "ColumnSpan";
  JSONEnumTableCell[JSONEnumTableCell["PreferredWidth"] = 3] = "PreferredWidth";
  JSONEnumTableCell[JSONEnumTableCell["VerticalMerging"] = 4] = "VerticalMerging";
  JSONEnumTableCell[JSONEnumTableCell["StartParagraphPosition"] = 5] = "StartParagraphPosition";
  JSONEnumTableCell[JSONEnumTableCell["EndParagraphPosition"] = 6] = "EndParagraphPosition";
})(JSONEnumTableCell || (JSONEnumTableCell = {}));
export var JSONEnumTableCellProperty;
(function (JSONEnumTableCellProperty) {
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["CellMargins"] = 0] = "CellMargins";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["Borders"] = 1] = "Borders";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["HideCellMark"] = 2] = "HideCellMark";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["NoWrap"] = 3] = "NoWrap";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["FitText"] = 4] = "FitText";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["TextDirection"] = 5] = "TextDirection";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["VerticalAlignment"] = 6] = "VerticalAlignment";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["ShadingInfo"] = 7] = "ShadingInfo";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["ShadingInfoIndex"] = 8] = "ShadingInfoIndex";
  JSONEnumTableCellProperty[JSONEnumTableCellProperty["UseValue"] = 9] = "UseValue";
})(JSONEnumTableCellProperty || (JSONEnumTableCellProperty = {}));
export var JSONEnumClientTableCellInfo;
(function (JSONEnumClientTableCellInfo) {
  JSONEnumClientTableCellInfo[JSONEnumClientTableCellInfo["StartCellPosition"] = 0] = "StartCellPosition";
  JSONEnumClientTableCellInfo[JSONEnumClientTableCellInfo["EndCellPosition"] = 1] = "EndCellPosition";
  JSONEnumClientTableCellInfo[JSONEnumClientTableCellInfo["CellIndex"] = 2] = "CellIndex";
  JSONEnumClientTableCellInfo[JSONEnumClientTableCellInfo["CellInfo"] = 3] = "CellInfo";
  JSONEnumClientTableCellInfo[JSONEnumClientTableCellInfo["RightDirection"] = 4] = "RightDirection";
})(JSONEnumClientTableCellInfo || (JSONEnumClientTableCellInfo = {}));
export var JSONEnumClientTableCellFormatting;
(function (JSONEnumClientTableCellFormatting) {
  JSONEnumClientTableCellFormatting[JSONEnumClientTableCellFormatting["ColumnSpan"] = 0] = "ColumnSpan";
  JSONEnumClientTableCellFormatting[JSONEnumClientTableCellFormatting["CellProperties"] = 1] = "CellProperties";
  JSONEnumClientTableCellFormatting[JSONEnumClientTableCellFormatting["PreferredWidth"] = 2] = "PreferredWidth";
  JSONEnumClientTableCellFormatting[JSONEnumClientTableCellFormatting["VerticalMerging"] = 3] = "VerticalMerging";
})(JSONEnumClientTableCellFormatting || (JSONEnumClientTableCellFormatting = {}));
export var JSONServerTableCellProperty;
(function (JSONServerTableCellProperty) {
  JSONServerTableCellProperty[JSONServerTableCellProperty["CellMargins"] = 0] = "CellMargins";
  JSONServerTableCellProperty[JSONServerTableCellProperty["PreferredWidth"] = 1] = "PreferredWidth";
  JSONServerTableCellProperty[JSONServerTableCellProperty["Borders"] = 2] = "Borders";
  JSONServerTableCellProperty[JSONServerTableCellProperty["HideCellMark"] = 3] = "HideCellMark";
  JSONServerTableCellProperty[JSONServerTableCellProperty["NoWrap"] = 4] = "NoWrap";
  JSONServerTableCellProperty[JSONServerTableCellProperty["FitText"] = 5] = "FitText";
  JSONServerTableCellProperty[JSONServerTableCellProperty["TextDirection"] = 6] = "TextDirection";
  JSONServerTableCellProperty[JSONServerTableCellProperty["VerticalAlignment"] = 7] = "VerticalAlignment";
  JSONServerTableCellProperty[JSONServerTableCellProperty["ShadingInfo"] = 8] = "ShadingInfo";
  JSONServerTableCellProperty[JSONServerTableCellProperty["ColumnSpan"] = 9] = "ColumnSpan";
  JSONServerTableCellProperty[JSONServerTableCellProperty["VerticalMerging"] = 10] = "VerticalMerging";
})(JSONServerTableCellProperty || (JSONServerTableCellProperty = {}));