import { Log } from './log';
export class LogListHelper {
  static level_1(handler, list, prefix = "", sep = "\n") {
    const addSuffix = (val, index) => `${prefix}[${index}]=${handler(val)}`;
    return Log.w(3, Log.join)(sep)(Log.map(addSuffix, list));
  }
  static level_2(handlerLevel_1, handlerLevel_2, list, prefix = "") {
    const addSuffix = (index_1, val, index_2) => `[${index_1}][${index_2}]=${handlerLevel_2(val)}`;
    const levelOneHandler = (el, ind) => `${prefix}${Log.join(Log.splitBy, Log.map(Log.w(3, addSuffix)(ind), handlerLevel_1(el)))}`;
    return Log.w(3, Log.join)("\n")(Log.map(levelOneHandler, list));
  }
}