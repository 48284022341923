import { DxtUtils } from '../../../utils/devextreme/dxt-utils';
import { ToolbarItemTemplateCreator } from './base-types';
import { Rectangle } from '@devexpress/utils/lib/geometry/rectangle';
import { isDefined } from '@devexpress/utils/lib/utils/common';
export class MenuToolbarItemTemplateCreator extends ToolbarItemTemplateCreator {
  constructor(options) {
    super();
    this.options = options;
  }
  createTemplate() {
    const result = super.createTemplate();
    result.widget = 'dxMenu';
    result.options = this.getMenuOptions();
    return result;
  }
  getMenuOptions() {
    DxtUtils.correctItemsIcons(this.options.itemOptions.items);
    return {
      hideSubmenuOnMouseLeave: true,
      focusStateEnabled: false,
      selectByClick: false,
      hint: this.options.itemOptions.text,
      dataSource: [{
        icon: this.options.itemOptions.icon ? DxtUtils.correctIconName(this.options.itemOptions.icon) : undefined,
        text: this.options.itemOptions.text,
        items: this.options.itemOptions.items.map(i => ({
          beginGroup: i.beginGroup,
          name: i.name,
          text: i.text,
          icon: i.icon,
          items: i.items
        }))
      }],
      onSubmenuShowing: e => {
        setTimeout(() => {
          var _a, _b;
          const component = e.component;
          if (component._visibleSubmenu) {
            const level = (_b = (_a = e.submenu._shownSubmenus) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0;
            const content = component._visibleSubmenu._overlay.$content(level);
            const submenu = content.find(".dx-submenu").eq(level);
            submenu.css("position", "");
            setHeight(submenu, null);
            const bounds = this.getContentBounds(submenu);
            const availableHeight = Math.max(0, document.documentElement.clientHeight - bounds.y);
            const maxHeight = Math.min(600, availableHeight);
            if (level > 0 && content.find(".dx-submenu")[level - 1].style.height) submenu.css("position", "fixed");
            if (bounds.height >= maxHeight) {
              const height = Math.min(bounds.height, maxHeight);
              setHeight(submenu, height);
              submenu.dxScrollView({});
            }
          }
        });
      },
      onInitialized: this.options.onInitialized,
      onItemRendered: this.options.onItemRendered,
      onItemClick: this.options.onItemClick,
      elementAttr: {
        class: this.getCssClass()
      },
      onContentReady: e => {
        if (this.options.itemOptions.icon && !this.options.itemOptions.alwaysShowText) {
          const element = e.element.classList ? e.element : e.element[0];
          element.classList.add(MenuToolbarItemTemplateCreator.ShowTextInMenuClassName);
        }
      }
    };
  }
  getContentBounds(content) {
    if (!content) return null;
    const array = Array.from(content);
    if (!array.length) return null;
    let result = this.toRectangle(array[0].getBoundingClientRect());
    if (array.length === 1) return result;
    for (let i = 1; i < array.length; i++) {
      const rect = array[i].getBoundingClientRect();
      result = Rectangle.union(result, this.toRectangle(rect));
    }
    return result;
  }
  toRectangle(rect) {
    return new Rectangle(rect.x, rect.y, rect.width, rect.height);
  }
}
MenuToolbarItemTemplateCreator.ShowTextInMenuClassName = 'dx-showTextInMenu';
const setHeight = function (content, value) {
  var _a;
  const element = content[0];
  if (!element) return;
  const view = ((_a = element.ownerDocument) === null || _a === void 0 ? void 0 : _a.defaultView) || window;
  const style = view.getComputedStyle && view.getComputedStyle(element);
  if (!style) return;
  if (isDefined(value)) {
    if (style.boxSizing === 'content-box') {
      const border = (parseFloat(style.borderTopWidth) || 0) + (parseFloat(style.borderBottomWidth) || 0);
      const padding = (parseFloat(style.paddingTop) || 0) + (parseFloat(style.paddingBottom) || 0);
      value -= border + padding;
    }
    element.style.height = value.toString() + 'px';
  } else element.style.height = '';
};