import { __awaiter } from "tslib";
import { MapCreator } from "../../../../../../../common/utils/map-creator";
import { LeafElementDestination } from "../../destination";
import { NoteProperties, NotePosition } from "../../../../../../../common/model/footnotes/footnote";
import { NumberingFormat } from "../../../../../../../common/model/numbering-lists/list-level-properties";
import { StringUtils } from "@devexpress/utils/lib/utils/string";
import { NotePropertiesDestination } from "../note-properties-destination";
export class SectionLevelFootNotePropertiesDestination extends NotePropertiesDestination {
  constructor(data) {
    super(data, NotePosition.BottomOfPage, NumberingFormat.Decimal);
  }
  setProperties(notes) {
    this.data.sectionImporter.properties.footNote = notes;
  }
}
export class DocumentLevelFootNotePropertiesDestination extends NotePropertiesDestination {
  constructor(data) {
    super(data, NotePosition.BottomOfPage, NumberingFormat.Decimal);
  }
  get elementHandlerTable() {
    return DocumentLevelFootNotePropertiesDestination.handlerTable;
  }
  setProperties(notes) {
    this.data.documentModel.sections[0].sectionProperties.footNote = notes;
  }
}
DocumentLevelFootNotePropertiesDestination.handlerTable = new MapCreator().append(NotePropertiesDestination.handlerTable).add('footnote', data => new FootNoteSeparatorDestination(data)).get();
class FootNoteSeparatorDestination extends LeafElementDestination {
  processElementOpen(reader) {
    return __awaiter(this, void 0, void 0, function* () {
      const id = reader.getAttributeNS("id", this.data.constants.wordProcessingNamespaceConst);
      if (!StringUtils.isNullOrEmpty(id)) this.data.footNotesImporter.separatorIds.push(id);
    });
  }
}
export class SectionFootNoteColumnsCountDestination extends LeafElementDestination {
  processElementOpen(reader) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
      const noteProps = (_a = this.data.sectionImporter.properties.footNote) !== null && _a !== void 0 ? _a : new NoteProperties();
      noteProps.columnCount = this.data.readerHelper.getWpSTIntegerValue(reader, "val", 1);
      this.data.sectionImporter.properties.footNote = noteProps;
    });
  }
}