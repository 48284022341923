<dx-popup #popup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="false" title="Person bearbeiten" width="800" height="auto">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view #scrollView height="100%">

            <dx-form #form [width]="'100%'" [formData]="formData" labelLocation="top" [colCount]="1">

                <dxi-item itemType="group" [colCount]="2">

                    <dxi-item dataField="anredeId" [label]="{ text: 'Anrede'}" editorType="dxSelectBox"
                        [editorOptions]="{ dataSource: configService.getKatalog('AnredeKatalog'), valueExpr: 'id', displayExpr: 'bezeichnung'}">
                    </dxi-item>

                    <dxi-item dataField="geschlechtId" [label]="{ text: 'Geschlecht'}" editorType="dxSelectBox"
                        [editorOptions]="{ dataSource: configService.getKatalog('GeschlechtKatalog'), valueExpr: 'id', displayExpr: 'bezeichnung'}">
                    </dxi-item>

                    <dxi-item dataField="titelId" [label]="{ text: 'Titel'}" editorType="dxSelectBox"
                        [editorOptions]="{ dataSource: configService.getKatalog('TitelKatalog'), valueExpr: 'id', displayExpr: 'bezeichnung'}">
                    </dxi-item>
                    
                    <dxi-item dataField="staatsangehoerigkeitId" [label]="{ text: 'Staatsangehörigkeit'}"
                        editorType="dxSelectBox"
                        [editorOptions]="{ dataSource: configService.getKatalog('StaatsangehoerigkeitKatalog'), valueExpr: 'id', displayExpr: 'bezeichnung'}">
                    </dxi-item>

                    <dxi-item dataField="nachname" [label]="{text: 'Nachname'}" [colSpan]="1" [editorOptions]="{ maxLength: 100}">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item dataField="vorname" [label]="{text: 'Vorname'}" [colSpan]="1" [editorOptions]="{ maxLength: 100}">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item dataField="geburtsdatum" [label]="{ text: 'Geburtsdatum' }" [colSpan]="1"
                        [isRequired]="false" editorType="dxDateBox" [editorOptions]="{ type: 'date'}">
                        <!-- <dxi-validation-rule type="required"></dxi-validation-rule> -->
                    </dxi-item>

                    <dxi-item itemType="empty"></dxi-item>
            
                    <dxi-item dataField="telefon1" [label]="{text: 'Telefon 1'}" [editorOptions]="{ maxLength: 20}" [colSpan]="1">
                    </dxi-item>

                    <dxi-item dataField="telefon2" [label]="{text: 'Telefon 2'}" [editorOptions]="{ maxLength: 20}" [colSpan]="1">
                    </dxi-item>

                    <dxi-item dataField="eMail" [label]="{ text: 'E-Mail'  }" [editorOptions]="{ maxLength: 100 }"
                        [colSpan]="3">
                        <dxi-validation-rule type="email"></dxi-validation-rule>
                    </dxi-item>

                </dxi-item>

            </dx-form>
        </dx-scroll-view>
    </div>

    <!-- ------------------ -->

    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ disabled: isLoading, text: 'Speichern', type: 'default', stylingMode: 'contained',  icon: 'save', onClick: onClickSave }">
    </dxi-popup-toolbar-item>
    <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-popup-toolbar-item>
</dx-popup>