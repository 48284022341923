"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PdfHelperFrame = void 0;
var browser_1 = require("../browser");
var dom_1 = require("../utils/dom");
var plugin_helper_1 = require("./plugin-helper");
var PdfHelperFrame = function () {
  function PdfHelperFrame(container, frameClassName) {
    this.helperFrame = null;
    this.helperFrameName = null;
    this.container = container;
    this.frameClassName = frameClassName;
  }
  PdfHelperFrame.prototype.dispose = function () {
    this.removeHelperFrame();
  };
  PdfHelperFrame.prototype.showPrintDialog = function (resourceUrl) {
    if (browser_1.Browser.Edge || plugin_helper_1.PdfPluginHelper.isInstalled()) this.getHelperFrame().document.location = resourceUrl;else {
      var printWindow_1 = window.open(resourceUrl, 'print', "height=" + window.innerHeight + ",width=" + window.innerWidth + ",tabbar=no");
      if (printWindow_1) {
        printWindow_1.focus();
        setTimeout(function () {
          printWindow_1.print();
        }, 100);
      }
    }
  };
  PdfHelperFrame.prototype.getHelperFrame = function () {
    if (browser_1.Browser.Firefox) this.removeHelperFrame();
    if (!this.helperFrame) this.createHelperFrame();
    return this.helperFrame;
  };
  PdfHelperFrame.prototype.removeHelperFrame = function () {
    if (this.helperFrame) {
      dom_1.DomUtils.hideNode(this.helperFrame);
      try {
        delete window.frames[this.helperFrameName];
      } catch (e) {}
      this.helperFrame = null;
      this.helperFrameName = null;
    }
  };
  PdfHelperFrame.prototype.createHelperFrame = function () {
    var helperFrame = document.createElement('iframe');
    var frameSize = browser_1.Browser.Safari ? '1px' : '0px';
    helperFrame.style.width = frameSize;
    helperFrame.style.height = frameSize;
    helperFrame.name = this.getNewName();
    helperFrame.className = this.frameClassName;
    if (browser_1.Browser.Chrome && browser_1.Browser.Version >= 77 || browser_1.Browser.Edge || browser_1.Browser.Safari) {
      helperFrame.addEventListener('load', function () {
        var _a, _b, _c;
        if (((_a = helperFrame.contentDocument) === null || _a === void 0 ? void 0 : _a.contentType) === 'application/pdf') {
          if (browser_1.Browser.Edge) (_b = helperFrame.contentWindow) === null || _b === void 0 ? void 0 : _b.document.execCommand('print', false, undefined);else if (browser_1.Browser.Safari) {
            setTimeout(function () {
              var _a;
              (_a = helperFrame.contentWindow) === null || _a === void 0 ? void 0 : _a.print();
            }, 1000);
          } else (_c = helperFrame.contentWindow) === null || _c === void 0 ? void 0 : _c.print();
        }
      });
    }
    this.container.appendChild(helperFrame);
    this.helperFrame = window.frames[helperFrame.name];
    this.helperFrameName = helperFrame.name;
  };
  PdfHelperFrame.prototype.getNewName = function () {
    for (var index = 0; true; index++) {
      var name_1 = "dxreHelperFrame" + index;
      if (!window.frames[name_1]) return name_1;
    }
  };
  return PdfHelperFrame;
}();
exports.PdfHelperFrame = PdfHelperFrame;