<dx-popup #popup [fullScreen]="true" [showTitle]="false" [dragEnabled]="false" [hideOnOutsideClick]="false"
  [showCloseButton]="false">

  <div *dxTemplate="let data of 'content'">
    <div [hidden]="docType !== 'doc' || isLoading" #richContainer></div>

    <ng2-pdfjs-viewer #pdfViewer *ngIf="docType === 'pdf'" [openFile]="false"></ng2-pdfjs-viewer>
    
    <dx-scroll-view [useNative]="true" [width]="'100%'" [height]="'100%'" *ngIf="docType === 'image'">
      <img [src]="imageSrc">
    </dx-scroll-view>

    <dx-scroll-view [useNative]="true" [width]="'100%'" [height]="'100%'" *ngIf="docType === 'text'">
      <div [innerHtml]="textSrc">
      </div>
    </dx-scroll-view>

    <div *ngIf="docType === null">
      Dieses Format kann nicht angezeigt werden!
    </div>

    <div class="loading-panel" *ngIf="isLoading">
      <dx-load-indicator height="40" width="40"></dx-load-indicator>
    </div>  
  </div>

  <dxi-popup-toolbar-item *ngIf="isVorlage" widget="dxButton" location="before" toolbar="bottom"
    [options]="{ text: 'Platzhalterliste', type:'default', icon: 'info', stylingMode: 'contained', onClick: onClickPlatzhalterliste}">
  </dxi-popup-toolbar-item>

  <dxi-popup-toolbar-item location="center" toolbar="bottom">
    <div *dxTemplate>
      <strong>{{currentDocName}}</strong>
    </div>
  </dxi-popup-toolbar-item>

  <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
    [options]="{ elementAttr: { id: 'docXToolbarButton' }, text: isReadOnly ? 'Schließen' : 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose}">
  </dxi-popup-toolbar-item>
</dx-popup>