import { Point } from '@devexpress/utils/lib/geometry/point';
import { ListUtils } from '@devexpress/utils/lib/utils/list';
export function rotatePoint(point, angle, center) {
  const x = center.x + (point.x - center.x) * Math.cos(angle) - (point.y - center.y) * Math.sin(angle);
  const y = center.y + (point.y - center.y) * Math.cos(angle) + (point.x - center.x) * Math.sin(angle);
  return new Point(x, y);
}
export class SearchTreeItemResult {
  constructor(parentList, itemIndex, item) {
    this.parentList = parentList;
    this.itemIndex = itemIndex;
    this.item = item;
  }
}
export function searchTreeItem(items, comparer) {
  return ListUtils.unsafeAnyOf(items, (item, index) => {
    if (comparer(item)) return new SearchTreeItemResult(items, index, item);
    if (item.items) {
      const res = searchTreeItem(item.items, comparer);
      if (res) return res;
    }
    return null;
  });
}
export function convertToFunction(func) {
  const type = typeof func;
  if (type == "function") return func;
  if (type == "string") {
    let funcName = func.trim();
    if (/^\b\w+?\b$/.test(funcName) && /^[^0-9]/.test(funcName)) {
      try {
        const handler = eval(funcName);
        if (typeof handler == "function") return handler;
      } catch (e) {}
    }
    let text = funcName;
    if (text.indexOf("function") != 0) text = `function(s, e){${text}}`;
    let result = null;
    try {
      result = eval(`(${text})`);
    } catch (_e) {
      console.error(`Parse error: ${func}`);
      return null;
    }
    return result;
  }
  return null;
}
export function splitByLines(text) {
  return text ? text.split(/\r\n|\r|\n/) : [''];
}
export function createUrlValidationOptions(modelManager) {
  const fieldSettings = modelManager.richOptions.fields;
  return {
    allowedSchemes: fieldSettings.allowedHyperlinkUriSchemes
  };
}
const defaultAllowedProtocols = ['http', 'https', 'mailto', 'tel'];
const disallowedProtocols = ['javascript', 'data'];
export class UrlUtils {
  static isValid(url, options) {
    var _a;
    const resultUrl = this.convertToAbsolute(url);
    if (!resultUrl) return false;
    const protocol = resultUrl.protocol.slice(0, -1);
    if (disallowedProtocols.includes(protocol)) return false;
    const allowedProtocols = (_a = options === null || options === void 0 ? void 0 : options.allowedSchemes) !== null && _a !== void 0 ? _a : defaultAllowedProtocols;
    return allowedProtocols.includes(protocol);
  }
  static isRelative(url) {
    try {
      new URL(url);
      return false;
    } catch (_a) {
      return true;
    }
  }
  static convertToAbsolute(url) {
    const currentLocation = window.location.href;
    try {
      return new URL(url, currentLocation);
    } catch (_a) {
      return null;
    }
  }
  static splitUrlByAnchor(url) {
    const hashTagPosition = url.indexOf("#");
    if (hashTagPosition < 0) return [url, ""];
    return [url.substring(0, hashTagPosition), url.substring(hashTagPosition + 1)];
  }
}
UrlUtils.EmptyPage = "about:blank";