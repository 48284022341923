"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExtendedMinMax = exports.ExtendedMax = exports.ExtendedMin = exports.MinMaxNumber = exports.MinMax = void 0;
var tslib_1 = require("tslib");
var MinMax = function () {
  function MinMax(minElement, maxElement) {
    this.minElement = minElement;
    this.maxElement = maxElement;
  }
  return MinMax;
}();
exports.MinMax = MinMax;
var MinMaxNumber = function (_super) {
  tslib_1.__extends(MinMaxNumber, _super);
  function MinMaxNumber() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Object.defineProperty(MinMaxNumber.prototype, "length", {
    get: function () {
      return this.maxElement - this.minElement;
    },
    enumerable: false,
    configurable: true
  });
  return MinMaxNumber;
}(MinMax);
exports.MinMaxNumber = MinMaxNumber;
var ExtendedMin = function () {
  function ExtendedMin(minElement, minValue) {
    this.minElement = minElement;
    this.minValue = minValue;
  }
  return ExtendedMin;
}();
exports.ExtendedMin = ExtendedMin;
var ExtendedMax = function () {
  function ExtendedMax(maxElement, maxValue) {
    this.maxElement = maxElement;
    this.maxValue = maxValue;
  }
  return ExtendedMax;
}();
exports.ExtendedMax = ExtendedMax;
var ExtendedMinMax = function (_super) {
  tslib_1.__extends(ExtendedMinMax, _super);
  function ExtendedMinMax(minElement, minValue, maxElement, maxValue) {
    var _this = _super.call(this, minElement, maxElement) || this;
    _this.minValue = minValue;
    _this.maxValue = maxValue;
    return _this;
  }
  return ExtendedMinMax;
}(MinMax);
exports.ExtendedMinMax = ExtendedMinMax;