import { ElementDestination } from '../../../destination';
export class TablePropertiesLeafElementDestination extends ElementDestination {
  get elementHandlerTable() {
    return {};
  }
  constructor(data, table, tableProperties) {
    super(data);
    this.table = table;
    this.tableProperties = tableProperties;
  }
  static getProperties(data) {
    return data.destinationStack.getThis().tableProperties;
  }
}