import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import { IErrorItemInterface } from '../../interfaces/ErrorBox/IErrorBoxItems';

@Component({
    selector: 'app-error-box',
    templateUrl: 'error-box.component.html',
    styleUrls: ['error-box.component.scss'],
    standalone: false
})
export class ErrorBoxComponent implements OnChanges {
  @Input() isVisible: boolean;
  @Input() title: string;
  @Input() items: IErrorItemInterface[];
  @Input() scrollView: DxScrollViewComponent;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isVisible !== undefined && changes.isVisible !== null) {
      let element = document.getElementById('errorBox');
      if (element !== undefined && element !== null) {
        if (changes.isVisible.currentValue) {
          element.classList.remove('hidden');
        } else {
          element.classList.add('hidden');
        }
      }
    }
  }
}
