import { ListUtils } from '@devexpress/utils/lib/utils/list';
import { HtmlTagImporterBase } from './base';
import { ParagraphListPropertiesUtils } from '../utils/paragraph-list-properties-utils';
import { ImportedParagraphListInfo } from '../containers/runs';
import { NumberingFormat } from '../../../../model/numbering-lists/list-level-properties';
import { NumberingType } from '../../../../model/numbering-lists/numbering-list';
export class HtmlListTagImporterBase extends HtmlTagImporterBase {
  constructor() {
    super(...arguments);
    this._ignored = false;
  }
  importBefore() {
    this.importer.addCurrLevelParagraphRunIfNeeded();
    const listIndex = this.importer.listIndex;
    const parentList = ListUtils.last(this.importer.listInfos);
    const listLevel = parentList ? parentList.listLevel + 1 : 0;
    if (listLevel === 9) {
      this._ignored = true;
      return;
    }
    const listFormat = ParagraphListPropertiesUtils.getListType(this.element);
    const displayFormat = ParagraphListPropertiesUtils.importDisplayFormatStringByParentListFormat(listFormat, parentList === null || parentList === void 0 ? void 0 : parentList.listFormat, listLevel);
    this.importer.listInfos.push(new ImportedParagraphListInfo(listIndex, listLevel, listFormat, listFormat == NumberingFormat.Bullet ? NumberingType.Bullet : NumberingType.MultiLevel, displayFormat, null));
  }
  isImportChildren() {
    return true;
  }
  importAfter() {
    if (this._ignored) return;
    this.importer.listInfos.pop();
    const parentList = ListUtils.last(this.importer.listInfos);
    if (!parentList) this.importer.listIndex++;
  }
}