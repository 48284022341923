import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { Subscription } from 'rxjs';
import { PopupService } from '../../services/popup.service';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from '../../services/api.service';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ConfigService } from '../../services/config.service';
import { ColumnChooserComponent } from '../columnChooser/columnChooser.component';

@Component({
    selector: 'app-ewo-person-search-popup',
    templateUrl: './ewo-person-search-popup.component.html',
    styleUrls: ['./ewo-person-search-popup.component.scss'],
    standalone: false
})
export class EwoPersonSearchPopupComponent implements OnInit, OnDestroy {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('columnChooser', { static: false }) columnChooser: ColumnChooserComponent;

  subscription_get_PopupAsObservable: Subscription;
  protected _data: any;
  set contextData(data) {
    this._data = JSON.parse(JSON.stringify(data));
  }

  get contextData() {
    return this._data;
  }

  onClose: (e) => void;
  init: boolean = false;
  isLoading: boolean = false;
  selectedItem: any = null;
  dataSource: any[];
  addressLat: number;
  addressLon: number;
  focusedRowKey: number;
  addSorgebrechtigte: boolean = false;

  constructor(private popupService: PopupService, private apiService: ApiService, public configService: ConfigService) {
    this.onClickOk = this.onClickOk.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.getGeschlechtText = this.getGeschlechtText.bind(this);
    this.onSelectAddSorgeberechtigte = this.onSelectAddSorgeberechtigte.bind(this);
    this.onClickEwoClear = this.onClickEwoClear.bind(this);
    this.prepareDataSource = this.prepareDataSource.bind(this);
    this.setInitialCurrentPos();
    this.subscription_get_PopupAsObservable = this.popupService.get_ewoPersonSearchPopupAsObservable.subscribe((e) => this.onPersonSearchEvent(e));
  }

  ngOnInit(): void {
    // this.dataSource = new DataSource({
    //   key: 'id',
    //   load: (loadOptions) => {
    //     return this.apiService.post<any>(RouteKeys.Bgb.person_search, loadOptions)
    //       .toPromise()
    //       .then(response => {
    //         this.init = true;
    //         return {
    //           data: response.data,
    //           totalCount: response.totalCount,
    //           summary: response.summary,
    //           groupCount: response.groupCount
    //         };
    //       }).catch(err => {
    //         throw err.message;
    //       });
    //   }
    // });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  onClickOk(e) {
    if (this.onClose !== undefined && this.onClose !== null) {
      this.onClose({
        status: 1,
        selectedItem: this.selectedItem,
        showAddSorgeberechtigte: this.contextData.showAddSorgeberechtigte,
        addSorgeberechtigte: this.addSorgebrechtigte,
      });
    }
    this.dataGrid.instance.clearSelection();
    this.popup.instance.hide();
  }

  onSelectAddSorgeberechtigte(e) {
    this.addSorgebrechtigte = e.value;
  }

  onClickClose(e) {
    if (this.onClose !== undefined && this.onClose !== null) {
      this.onClose({
        status: 2,
      });
    }
    this.dataGrid.instance.clearSelection();
    this.popup.instance.hide();
  }

  onPersonSearchEvent(e: any): void {
    if (e !== null && e !== undefined && e.open === true) {
      this.onClose = e.closeCb;
      this.contextData = e.data;
      this.selectedItem = null;
      this.dataSource = e.data.persons;

      setTimeout(() => {
        this.init = true;
      }, 0);

      if (this.dataGrid) {
        this.dataGrid.instance.clearFilter();
        this.dataGrid.instance.clearSorting();
      }

      this.popup.instance.show();
    } else {
      this.contextData = null;
    }
  }

  onContentReady(e) {
    e.component.columnOption('command:select', {
      visibleIndex: 1,
      fixedPosition: 'right',
      visibleWidth: 70,
    });

    if (
      this.contextData !== undefined &&
      this.contextData !== null &&
      this.contextData.selected !== undefined &&
      this.contextData.selected !== null &&
      this.contextData.selected > 0
    ) {
      this.dataGrid.instance.selectRows([this.contextData.selected], false);
      this.focusedRowKey = this.contextData.selected;
    }
  }

  onToolbarPreparing(e) {
    const columnChooserButton = e.toolbarOptions.items.find((x) => x.name === 'columnChooserButton');
    if (columnChooserButton) {
      columnChooserButton.locateInMenu = 'never';
      columnChooserButton.showText = 'never';
      columnChooserButton.options.visible = false;
    }

    var searchPanel = e.toolbarOptions.items.filter((item) => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }

    e.toolbarOptions.items.push({
      location: 'before',
      widget: 'dxButton',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'undo',
        hint: 'Filter zurücksetzen',
        onClick: (event) => {
          this.dataGrid.instance.clearFilter();
          this.dataGrid.instance.clearSorting();
          this.dataGrid.instance.clearSelection();
        },
      },
    });

    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'columnchooser',
        hint: 'Spaltenauswahl',
        onClick: (e) => {
          this.columnChooser.open();
        },
      },
    });
  }

  onSelectionChanged(e) {
    var deselectRowKeys = [];
    if (e.selectedRowKeys && e.selectedRowKeys[0]) {
      let rowData = e.selectedRowsData[0];
      if (rowData !== undefined && rowData !== null) {
        this.selectedItem = rowData;
      }
    }

    if (deselectRowKeys.length) {
      this.selectedItem = null;
      e.component.deselectRows(deselectRowKeys);
    }
  }

  handleEditCloseEvent(e: any) {
    if (e.state === 1) {
      this.dataGrid.instance.refresh().then(() => {
        this.dataGrid.instance.selectRows([e.data.id], false);
        this.selectedItem = this.dataGrid.instance.getSelectedRowsData()[0];
        this.focusedRowKey = this.dataGrid.instance.getSelectedRowKeys()[0];
      });
    }
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      if (
        this.contextData !== undefined &&
        this.contextData !== null &&
        this.contextData.blocked !== undefined &&
        this.contextData.blocked !== null &&
        this.contextData.blocked.length > 0
      ) {
        if (this.contextData.blocked.includes(e.data.id)) {
          e.rowElement.style.color = 'rgb(128 128 128 / 75%)';
        }
      }
    }
  }

  getGeschlechtText(e: any) {
    if (!e) return '';
    if (e.value.toLowerCase() === 'w') {
      return 'weiblich';
    } else if (e.value.toLowerCase() === 'm') {
      return 'männlich';
    } else {
      return 'divers';
    }
  }

  getAdressText(e: any) {
    const hauptwohnsitz = e.value.find((x) => x.art.schluessel === 'HAW' || x.art.schluessel === 'AW');
    if (!hauptwohnsitz) return '';
    const strasse = hauptwohnsitz.adresse.strasse.name;
    const ort = hauptwohnsitz.adresse.ort.name;
    const plz = hauptwohnsitz.adresse.postleitzahl;
    const hausnummer = hauptwohnsitz.adresse.hausnummer;
    const hausnummerZusatz = hauptwohnsitz.adresse.hausnummerzusatz ? hauptwohnsitz.adresse.hausnummerzusatz : '';

    return strasse + ' ' + hausnummer + '' + hausnummerZusatz + ', ' + plz + ' ' + ort;
  }

  setInitialCurrentPos() {
    this.addressLat = this.configService.getGlobalSettingAsNumber('defaultLatitude');
    this.addressLon = this.configService.getGlobalSettingAsNumber('defaultLongitude');
  }

  onAddrKindSelected(e) {
    this.contextData.searchData.strasse = e.street;
    this.contextData.searchData.plz = e.postcode;
    this.contextData.searchData.ortsteil = e.district;
    this.contextData.searchData.ort = e.city;
    this.contextData.searchData.hnr = e.housenumber;
    this.contextData.searchData.hnrZusatz = e.supplement;
  }

  onClickEwoFind() {
    if (this.contextData.searchData !== undefined && this.contextData.searchData !== null) {
      let model = {
        vorname: this.contextData.searchData.vorname,
        name: this.contextData.searchData.nachname,
        geburtsdatum: this.contextData.searchData.geburtsdatum,
        strasse: this.contextData.searchData.strasse,
        hNr: this.contextData.searchData.hnr,
        hNrZusatz: this.contextData.searchData.hnrZusatz,
        plz: this.contextData.searchData.plz,
        ort: this.contextData.searchData.ort,
        ortsteil: this.contextData.searchData.ortsteil,
      };
      this.apiService.post(RouteKeys.Ewo.check_person_residence, model).subscribe({
        next: (response: any) => {
          if (response && response.match) {
            this.dataSource = response.results;
            this.prepareDataSource();
          } else {
            this.apiService.post(RouteKeys.Ewo.check_person_residence_vague, model).subscribe({
              next: (response: any) => {
                this.dataSource = response.results;
                this.prepareDataSource();
              },
              error: (err) => {
                throw err.error ? err.error.message : err.message;
              },
            });
          }
        },
        error: (err) => {
          throw err.error ? err.error.message : err.message;
        },
      });
    }
  }

  private prepareDataSource() {
    if (this.dataSource !== null && this.dataSource !== undefined) {
      this.dataSource.forEach((element) => {
        if (element.geburtsdaten !== undefined && element.geburtsdaten.geburtsdatum !== undefined) {
          element.gebdat = this.toDate(element.geburtsdaten.geburtsdatum);
        }
      });
    }
  }

  private toDate = (dateStr) => {
    const [day, month, year] = dateStr.split('.');
    return new Date(year, month - 1, day);
  };

  onChangeStrasseKind(e) {
    this.contextData.searchData.strasse = e.component.option('value');
  }

  onClickEwoClear() {
    this.contextData.searchData.nachname = '';
    this.contextData.searchData.vorname = '';
    this.contextData.searchData.geburtsdatum = null;
    this.contextData.searchData.strasse = '';
    this.contextData.searchData.hnr = null;
    this.contextData.searchData.hnrZusatz = '';
    this.contextData.searchData.plz = '';
    this.contextData.searchData.ort = '';
    this.contextData.searchData.ortsteil = '';
  }
}
