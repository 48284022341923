import { TableCellBorders } from '../../../borders/table-cell-borders';
import { JSONTableCellBordersProperty } from '../../enums/table/json-table-structures-enums';
import { JSONBorderInfoConverter } from '../json-border-info-converter';
export class JSONTableCellBordersConverter {
  static convertFromJSON(obj, colorModelInfoCache) {
    var result = new TableCellBorders();
    result.top = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableCellBordersProperty.TopBorder], colorModelInfoCache);
    result.left = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableCellBordersProperty.LeftBorder], colorModelInfoCache);
    result.right = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableCellBordersProperty.RightBorder], colorModelInfoCache);
    result.bottom = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableCellBordersProperty.BottomBorder], colorModelInfoCache);
    result.topLeftDiagonal = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableCellBordersProperty.TopLeftDiagonalBorder], colorModelInfoCache);
    result.topRightDiagonal = JSONBorderInfoConverter.convertFromJSON(obj[JSONTableCellBordersProperty.TopRightDiagonalBorder], colorModelInfoCache);
    return result;
  }
  static convertToJSON(source) {
    var result = {};
    result[JSONTableCellBordersProperty.TopBorder] = JSONBorderInfoConverter.convertToJSON(source.top);
    result[JSONTableCellBordersProperty.LeftBorder] = JSONBorderInfoConverter.convertToJSON(source.left);
    result[JSONTableCellBordersProperty.RightBorder] = JSONBorderInfoConverter.convertToJSON(source.right);
    result[JSONTableCellBordersProperty.BottomBorder] = JSONBorderInfoConverter.convertToJSON(source.bottom);
    result[JSONTableCellBordersProperty.TopLeftDiagonalBorder] = JSONBorderInfoConverter.convertToJSON(source.topLeftDiagonal);
    result[JSONTableCellBordersProperty.TopRightDiagonalBorder] = JSONBorderInfoConverter.convertToJSON(source.topRightDiagonal);
    return result;
  }
}