export var JSONParagraphFormattingProperty;
(function (JSONParagraphFormattingProperty) {
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["Alignment"] = 0] = "Alignment";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["FirstLineIndent"] = 1] = "FirstLineIndent";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["FirstLineIndentType"] = 2] = "FirstLineIndentType";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["LeftIndent"] = 3] = "LeftIndent";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["LineSpacing"] = 4] = "LineSpacing";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["LineSpacingType"] = 5] = "LineSpacingType";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["RightIndent"] = 6] = "RightIndent";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["SpacingBefore"] = 7] = "SpacingBefore";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["SpacingAfter"] = 8] = "SpacingAfter";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["SuppressHyphenation"] = 9] = "SuppressHyphenation";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["SuppressLineNumbers"] = 10] = "SuppressLineNumbers";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["ContextualSpacing"] = 11] = "ContextualSpacing";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["PageBreakBefore"] = 12] = "PageBreakBefore";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["BeforeAutoSpacing"] = 13] = "BeforeAutoSpacing";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["AfterAutoSpacing"] = 14] = "AfterAutoSpacing";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["KeepWithNext"] = 15] = "KeepWithNext";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["KeepLinesTogether"] = 16] = "KeepLinesTogether";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["WidowOrphanControl"] = 17] = "WidowOrphanControl";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["OutlineLevel"] = 18] = "OutlineLevel";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["ShadingInfo"] = 19] = "ShadingInfo";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["ShadingInfoIndex"] = 20] = "ShadingInfoIndex";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["LeftBorder"] = 21] = "LeftBorder";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["RightBorder"] = 22] = "RightBorder";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["TopBorder"] = 23] = "TopBorder";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["BottomBorder"] = 24] = "BottomBorder";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["DivId"] = 25] = "DivId";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["RightToLeft"] = 26] = "RightToLeft";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["BetweenBorder"] = 27] = "BetweenBorder";
  JSONParagraphFormattingProperty[JSONParagraphFormattingProperty["UseValue"] = 28] = "UseValue";
})(JSONParagraphFormattingProperty || (JSONParagraphFormattingProperty = {}));
export var JSONParagraphProperty;
(function (JSONParagraphProperty) {
  JSONParagraphProperty[JSONParagraphProperty["MaskedParagraphPropertiesIndex"] = 0] = "MaskedParagraphPropertiesIndex";
  JSONParagraphProperty[JSONParagraphProperty["ParagraphStyleIndex"] = 1] = "ParagraphStyleIndex";
  JSONParagraphProperty[JSONParagraphProperty["LogPosition"] = 2] = "LogPosition";
  JSONParagraphProperty[JSONParagraphProperty["Length"] = 3] = "Length";
  JSONParagraphProperty[JSONParagraphProperty["Tabs"] = 4] = "Tabs";
  JSONParagraphProperty[JSONParagraphProperty["ListIndex"] = 5] = "ListIndex";
  JSONParagraphProperty[JSONParagraphProperty["ListLevelIndex"] = 6] = "ListLevelIndex";
})(JSONParagraphProperty || (JSONParagraphProperty = {}));
export var JSONMergeParagraphsCommandProperty;
(function (JSONMergeParagraphsCommandProperty) {
  JSONMergeParagraphsCommandProperty[JSONMergeParagraphsCommandProperty["Position"] = 0] = "Position";
  JSONMergeParagraphsCommandProperty[JSONMergeParagraphsCommandProperty["GetPropertiesFromNext"] = 1] = "GetPropertiesFromNext";
})(JSONMergeParagraphsCommandProperty || (JSONMergeParagraphsCommandProperty = {}));
export var JSONInsertParagraphCommandProperty;
(function (JSONInsertParagraphCommandProperty) {
  JSONInsertParagraphCommandProperty[JSONInsertParagraphCommandProperty["Position"] = 0] = "Position";
  JSONInsertParagraphCommandProperty[JSONInsertParagraphCommandProperty["NumberingListIndex"] = 1] = "NumberingListIndex";
  JSONInsertParagraphCommandProperty[JSONInsertParagraphCommandProperty["ListLevelIndex"] = 2] = "ListLevelIndex";
  JSONInsertParagraphCommandProperty[JSONInsertParagraphCommandProperty["StyleName"] = 3] = "StyleName";
  JSONInsertParagraphCommandProperty[JSONInsertParagraphCommandProperty["ParagraphProperties"] = 4] = "ParagraphProperties";
  JSONInsertParagraphCommandProperty[JSONInsertParagraphCommandProperty["ParagraphPropertiesIndex"] = 5] = "ParagraphPropertiesIndex";
  JSONInsertParagraphCommandProperty[JSONInsertParagraphCommandProperty["TabsProperties"] = 6] = "TabsProperties";
})(JSONInsertParagraphCommandProperty || (JSONInsertParagraphCommandProperty = {}));
export var JSONParagraphPropertiesChangedProperty;
(function (JSONParagraphPropertiesChangedProperty) {
  JSONParagraphPropertiesChangedProperty[JSONParagraphPropertiesChangedProperty["ParagraphIndex"] = 0] = "ParagraphIndex";
  JSONParagraphPropertiesChangedProperty[JSONParagraphPropertiesChangedProperty["ParagraphProperties"] = 1] = "ParagraphProperties";
  JSONParagraphPropertiesChangedProperty[JSONParagraphPropertiesChangedProperty["NumberingListIndex"] = 2] = "NumberingListIndex";
  JSONParagraphPropertiesChangedProperty[JSONParagraphPropertiesChangedProperty["ListLevelIndex"] = 3] = "ListLevelIndex";
  JSONParagraphPropertiesChangedProperty[JSONParagraphPropertiesChangedProperty["TabsProperties"] = 4] = "TabsProperties";
  JSONParagraphPropertiesChangedProperty[JSONParagraphPropertiesChangedProperty["StyleName"] = 5] = "StyleName";
})(JSONParagraphPropertiesChangedProperty || (JSONParagraphPropertiesChangedProperty = {}));