import { loadMessages } from 'devextreme/localization';
let loaded = false;
export function loadDefaultMessages() {
  if (loaded) return;
  loaded = true;
  loadMessages({
    "en": {
      "OfficeStringId.Caption_EditHyperlinkForm": "Edit Hyperlink",
      "OfficeStringId.Caption_EditHyperlinkFormDescription": " ",
      "OfficeStringId.Caption_InsertHyperlinkForm": "Insert Hyperlink",
      "OfficeStringId.Caption_InsertHyperlinkFormDescription": " ",
      "OfficeStringId.Caption_UnitCentimeters": "Centimeters",
      "OfficeStringId.Caption_UnitInches": "Inches",
      "OfficeStringId.Caption_UnitMillimeters": "Millimeters",
      "OfficeStringId.Caption_UnitPercent": "Percent",
      "OfficeStringId.Caption_UnitPoints": "Points",
      "OfficeStringId.FileFilterDescription_AllFiles": "All Files",
      "OfficeStringId.FileFilterDescription_AllSupportedFiles": "All Supported Files",
      "OfficeStringId.FileFilterDescription_BitmapFiles": "Windows Bitmap",
      "OfficeStringId.FileFilterDescription_EmfFiles": "Microsoft Enhanced Metafile",
      "OfficeStringId.FileFilterDescription_GifFiles": "Graphics Interchange Format",
      "OfficeStringId.FileFilterDescription_JPEGFiles": "JPEG File Interchange Format",
      "OfficeStringId.FileFilterDescription_PNGFiles": "Portable Network Graphics",
      "OfficeStringId.FileFilterDescription_TiffFiles": "Tag Image File Format",
      "OfficeStringId.FileFilterDescription_WmfFiles": "Windows Metafile",
      "OfficeStringId.MenuCmd_AlignmentCenter": "Center",
      "OfficeStringId.MenuCmd_AlignmentCenterDescription": "Center text.",
      "OfficeStringId.MenuCmd_AlignmentJustify": "Justify",
      "OfficeStringId.MenuCmd_AlignmentJustifyDescription": "Align text to both left and right margins, adding extra space between words as necessary.This creates a clean look along the left and right side of the page.",
      "OfficeStringId.MenuCmd_AlignmentLeft": "Align Text Left",
      "OfficeStringId.MenuCmd_AlignmentLeftDescription": "Align text to the left.",
      "OfficeStringId.MenuCmd_AlignmentRight": "Align Text Right",
      "OfficeStringId.MenuCmd_AlignmentRightDescription": "Align text to the right.",
      "OfficeStringId.MenuCmd_ChangeFontColor": "Font Color",
      "OfficeStringId.MenuCmd_ChangeFontColorDescription": "Change the text color.",
      "OfficeStringId.MenuCmd_ChangeFontName": "Font",
      "OfficeStringId.MenuCmd_ChangeFontNameDescription": "Change the font face.",
      "OfficeStringId.MenuCmd_ChangeFontSize": "Font Size",
      "OfficeStringId.MenuCmd_ChangeFontSizeDescription": "Change the font size.",
      "OfficeStringId.MenuCmd_ClearUndo": "ClearUndo",
      "OfficeStringId.MenuCmd_ClearUndoDescription": "Clear Undo Buffer",
      "OfficeStringId.MenuCmd_CopySelection": "Copy",
      "OfficeStringId.MenuCmd_CopySelectionDescription": "Copy the selection and put it on the Clipboard.",
      "OfficeStringId.MenuCmd_CutSelection": "Cut",
      "OfficeStringId.MenuCmd_CutSelectionDescription": "Cut the selection from the document and put it on the Clipboard.",
      "OfficeStringId.MenuCmd_DecreaseFontSize": "Shrink Font",
      "OfficeStringId.MenuCmd_DecreaseFontSizeDescription": "Decrease the font size.",
      "OfficeStringId.MenuCmd_DeleteComment": "Delete",
      "OfficeStringId.MenuCmd_DeleteCommentDescription": "Delete the selected comment.",
      "OfficeStringId.MenuCmd_EditComment": "Edit Comment",
      "OfficeStringId.MenuCmd_EditCommentDescription": "Add a note about this part of the document.",
      "OfficeStringId.MenuCmd_EditHyperlink": "Edit Hyperlink...",
      "OfficeStringId.MenuCmd_EditHyperlinkDescription": "Edit Hyperlink...",
      "OfficeStringId.MenuCmd_Encrypt": "Encrypt with Password",
      "OfficeStringId.MenuCmd_EncryptDescription": "Require a password to open this workbook.",
      "OfficeStringId.MenuCmd_FloatingObjectBringForward": "Bring Forward",
      "OfficeStringId.MenuCmd_FloatingObjectBringForwardCommandGroup": "Bring Forward",
      "OfficeStringId.MenuCmd_FloatingObjectBringForwardCommandGroupDescription": "Bring the selected object forward one level, or bring it in front of all the other objects.",
      "OfficeStringId.MenuCmd_FloatingObjectBringForwardDescription": "Bring the selected object forward so that it is hidden by fewer objects that are in front of it.",
      "OfficeStringId.MenuCmd_FloatingObjectBringToFront": "Bring to Front",
      "OfficeStringId.MenuCmd_FloatingObjectBringToFrontDescription": "Bring the selected object in front of all other objects so that no part of it is hidden behind other objects.",
      "OfficeStringId.MenuCmd_FloatingObjectSendBackward": "Send Backward",
      "OfficeStringId.MenuCmd_FloatingObjectSendBackwardCommandGroup": "Send Backward",
      "OfficeStringId.MenuCmd_FloatingObjectSendBackwardCommandGroupDescription": "Send the selected object back one level, or send it behind all the other objects.",
      "OfficeStringId.MenuCmd_FloatingObjectSendBackwardDescription": "Send the selected object backward so that it is hidden by the objects that are in front of it.",
      "OfficeStringId.MenuCmd_FloatingObjectSendToBack": "Send to Back",
      "OfficeStringId.MenuCmd_FloatingObjectSendToBackDescription": "Send the selected object behind all other objects.",
      "OfficeStringId.MenuCmd_Hyperlink": "Hyperlink",
      "OfficeStringId.MenuCmd_HyperlinkDescription": "Create a link to a Web page, a picture, an e-mail address, or a program.",
      "OfficeStringId.MenuCmd_IncreaseFontSize": "Grow Font",
      "OfficeStringId.MenuCmd_IncreaseFontSizeDescription": "Increase the font size.",
      "OfficeStringId.MenuCmd_InsertComment": "New Comment",
      "OfficeStringId.MenuCmd_InsertCommentDescription": "Add a note about this part of the document.",
      "OfficeStringId.MenuCmd_InsertFloatingObjectPicture": "Picture",
      "OfficeStringId.MenuCmd_InsertFloatingObjectPictureDescription": "Insert a picture from a file.",
      "OfficeStringId.MenuCmd_InsertHyperlink": "Hyperlink...",
      "OfficeStringId.MenuCmd_InsertHyperlinkDescription": "Add a new hyperlink.",
      "OfficeStringId.MenuCmd_InsertSymbol": "Symbol",
      "OfficeStringId.MenuCmd_InsertSymbolDescription": "Insert symbols that are not on your keyboard, such as copyright symbols, trademark symbols, paragraph marks and Unicode characters.",
      "OfficeStringId.MenuCmd_LoadDocument": "Open",
      "OfficeStringId.MenuCmd_LoadDocumentDescription": "Open a document.",
      "OfficeStringId.MenuCmd_NewEmptyDocument": "New",
      "OfficeStringId.MenuCmd_NewEmptyDocumentDescription": "Create a new document.",
      "OfficeStringId.MenuCmd_OpenHyperlink": "Open Hyperlink",
      "OfficeStringId.MenuCmd_OpenHyperlinkDescription": "Open Hyperlink",
      "OfficeStringId.MenuCmd_PageMarginsModerate": "ModerateTop: {1,10} Bottom: {3,10}Left: {0,10} Right:  {2,10}",
      "OfficeStringId.MenuCmd_PageMarginsModerateDescription": " ",
      "OfficeStringId.MenuCmd_PageMarginsNarrow": "NarrowTop: {1,10} Bottom: {3,10}Left: {0,10} Right:  {2,10}",
      "OfficeStringId.MenuCmd_PageMarginsNarrowDescription": " ",
      "OfficeStringId.MenuCmd_PageMarginsNormal": "NormalTop: {1,10} Bottom: {3,10}Left: {0,10} Right:  {2,10}",
      "OfficeStringId.MenuCmd_PageMarginsNormalDescription": " ",
      "OfficeStringId.MenuCmd_PageMarginsWide": "WideTop: {1,10} Bottom: {3,10}Left: {0,10} Right:  {2,10}",
      "OfficeStringId.MenuCmd_PageMarginsWideDescription": " ",
      "OfficeStringId.MenuCmd_PageOrientationCommandGroup": "Orientation",
      "OfficeStringId.MenuCmd_PageOrientationCommandGroupDescription": "Switch the pages between portrait and landscape layouts.",
      "OfficeStringId.MenuCmd_PageOrientationLandscape": "Landscape",
      "OfficeStringId.MenuCmd_PageOrientationLandscapeDescription": " ",
      "OfficeStringId.MenuCmd_PageOrientationPortrait": "Portrait",
      "OfficeStringId.MenuCmd_PageOrientationPortraitDescription": " ",
      "OfficeStringId.MenuCmd_Paste": "Paste",
      "OfficeStringId.MenuCmd_PasteDescription": "Paste the contents of the Clipboard.",
      "OfficeStringId.MenuCmd_Print": "Print",
      "OfficeStringId.MenuCmd_PrintDescription": "Select a printer, number of copies, and other printing options before printing.",
      "OfficeStringId.MenuCmd_PrintPreview": "Print Preview",
      "OfficeStringId.MenuCmd_PrintPreviewDescription": "Preview pages before printing.",
      "OfficeStringId.MenuCmd_QuickPrint": "Quick Print",
      "OfficeStringId.MenuCmd_QuickPrintDescription": "Send the document directly to the default printer without making changes.",
      "OfficeStringId.MenuCmd_Redo": "Redo",
      "OfficeStringId.MenuCmd_RedoDescription": "Redo",
      "OfficeStringId.MenuCmd_RemoveHyperlink": "Remove Hyperlink",
      "OfficeStringId.MenuCmd_RemoveHyperlinkDescription": "Remove Hyperlink",
      "OfficeStringId.MenuCmd_RemoveHyperlinks": "Remove Hyperlinks",
      "OfficeStringId.MenuCmd_RemoveHyperlinksDescription": "Remove Hyperlinks",
      "OfficeStringId.MenuCmd_SaveDocument": "Save",
      "OfficeStringId.MenuCmd_SaveDocumentAs": "Save As",
      "OfficeStringId.MenuCmd_SaveDocumentAsDescription": "Open the Save As dialog box to select a file format and save the document to a new location.",
      "OfficeStringId.MenuCmd_SaveDocumentDescription": "Save the document.",
      "OfficeStringId.MenuCmd_ShowPasteSpecialForm": "Paste Special",
      "OfficeStringId.MenuCmd_ShowPasteSpecialFormDescription": "Show the Paste Special dialog box.",
      "OfficeStringId.MenuCmd_StatusBarPopupMenuHeader": "Customize Status Bar",
      "OfficeStringId.MenuCmd_StatusBarPopupMenuZoom": "Zoom",
      "OfficeStringId.MenuCmd_StatusBarPopupMenuZoomSlider": "Zoom Slider",
      "OfficeStringId.MenuCmd_StatusBarZoomDescription": "Zoom level. Click to open the Zoom dialog box.",
      "OfficeStringId.MenuCmd_StatusBarZoomSliderDescription": "Zoom",
      "OfficeStringId.MenuCmd_ToggleFontBold": "Bold",
      "OfficeStringId.MenuCmd_ToggleFontBoldDescription": "Make the selected text bold.",
      "OfficeStringId.MenuCmd_ToggleFontDoubleUnderline": "Double Underline",
      "OfficeStringId.MenuCmd_ToggleFontDoubleUnderlineDescription": "Double underline",
      "OfficeStringId.MenuCmd_ToggleFontItalic": "Italic",
      "OfficeStringId.MenuCmd_ToggleFontItalicDescription": "Italicize the selected text.",
      "OfficeStringId.MenuCmd_ToggleFontStrikeout": "Strikethrough",
      "OfficeStringId.MenuCmd_ToggleFontStrikeoutDescription": "Draw a line through the middle of the selected text.",
      "OfficeStringId.MenuCmd_ToggleFontUnderline": "Underline",
      "OfficeStringId.MenuCmd_ToggleFontUnderlineDescription": "Underline the selected text.",
      "OfficeStringId.MenuCmd_Undo": "Undo",
      "OfficeStringId.MenuCmd_UndoDescription": "Undo",
      "OfficeStringId.MenuCmd_Zoom100Percent": "100%",
      "OfficeStringId.MenuCmd_Zoom100PercentDescription": "Zoom the document to 100% of the normal size.",
      "OfficeStringId.MenuCmd_ZoomIn": "Zoom In",
      "OfficeStringId.MenuCmd_ZoomInDescription": "Zoom In",
      "OfficeStringId.MenuCmd_ZoomOut": "Zoom Out",
      "OfficeStringId.MenuCmd_ZoomOutDescription": "Zoom Out",
      "OfficeStringId.Msg_InternalError": "An internal error occurred",
      "OfficeStringId.Msg_InvalidBeginInit": "Error: call to BeginInit inside BeginUpdate",
      "OfficeStringId.Msg_InvalidBeginUpdate": "Error: call to BeginUpdate inside BeginInit",
      "OfficeStringId.Msg_InvalidCopyFromDocumentModel": "Error: source and destination document models are different",
      "OfficeStringId.Msg_InvalidEndInit": "Error: call to EndInit or CancelInit without BeginInit or inside BeginUpdate",
      "OfficeStringId.Msg_InvalidEndUpdate": "Error: call to EndUpdate or CancelUpate without BeginUpdate or inside BeginInit",
      "OfficeStringId.Msg_InvalidFontSize": "The number must be between {0} and {1}.",
      "OfficeStringId.Msg_InvalidNumber": "This is not a valid number.",
      "OfficeStringId.Msg_InvalidNumberConverterValue": "Value must be between {0} and {1}.",
      "OfficeStringId.Msg_InvalidRemoveDataSource": "A data source cannot be deleted during the mail-merge process.",
      "OfficeStringId.Msg_IsNotValid": "'{0}' is not a valid value for '{1}'",
      "OfficeStringId.Msg_Loading": "Loading...",
      "OfficeStringId.Msg_MagicNumberNotFound": "The file you are trying to open is in different format than specified by the file extension.",
      "OfficeStringId.Msg_UnsupportedFormatOrCorruptedFile": "Unsupported format or corrupted file.",
      "OfficeStringId.UnitAbbreviation_Centimeter": " cm",
      "OfficeStringId.UnitAbbreviation_Inch": "\"",
      "OfficeStringId.UnitAbbreviation_Millimeter": " mm",
      "OfficeStringId.UnitAbbreviation_Percent": "%",
      "OfficeStringId.UnitAbbreviation_Pica": " pc",
      "OfficeStringId.UnitAbbreviation_Point": " pt",
      "XtraRichEditStringId.BorderLineStyleNone": "None",
      "XtraRichEditStringId.Caption_AlignmentCenter": "Centered",
      "XtraRichEditStringId.Caption_AlignmentDistribute": "Distributed",
      "XtraRichEditStringId.Caption_AlignmentJustify": "Justified",
      "XtraRichEditStringId.Caption_AlignmentJustifyHigh": "Justify High",
      "XtraRichEditStringId.Caption_AlignmentJustifyLow": "Justify Low",
      "XtraRichEditStringId.Caption_AlignmentJustifyMedium": "Justify Medium",
      "XtraRichEditStringId.Caption_AlignmentLeft": "Left",
      "XtraRichEditStringId.Caption_AlignmentRight": "Right",
      "XtraRichEditStringId.Caption_AlignmentThaiDistribute": "Thai Distributed",
      "XtraRichEditStringId.Caption_BulletsAndNumbering_Nothing": "Nothing",
      "XtraRichEditStringId.Caption_BulletsAndNumbering_SpaceCharacter": "Space",
      "XtraRichEditStringId.Caption_BulletsAndNumbering_TabCharacter": "Tab character",
      "XtraRichEditStringId.Caption_Bytes": "bytes",
      "XtraRichEditStringId.Caption_CaptionPrefixEquation": "Equation",
      "XtraRichEditStringId.Caption_CaptionPrefixFigure": "Figure",
      "XtraRichEditStringId.Caption_CaptionPrefixTable": "Table",
      "XtraRichEditStringId.Caption_ClipboardSubItem": "Clipboard",
      "XtraRichEditStringId.Caption_ColorAutomatic": "Automatic",
      "XtraRichEditStringId.Caption_ColumnBreak": "Column Break",
      "XtraRichEditStringId.Caption_Comments": "Comments",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_BottomLeftCell": "Bottom left cell",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_BottomRightCell": "Bottom right cell",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_EvenColumnBanding": "Even banded columns",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_EvenRowBanding": "Even banded rows",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_FirstColumn": "First column",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_FirstRow": "Header row",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_LastColumn": "Last column",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_LastRow": "Total row",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_OddColumnBanding": "Odd banded columns",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_OddRowBanding": "Odd banded rows",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_TopLeftCell": "Top left cell",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_TopRightCell": "Top right cell",
      "XtraRichEditStringId.Caption_ConditionalTableStyleFormattingTypes_WholeTable": "Whole table",
      "XtraRichEditStringId.Caption_CurrentDocumentHyperlinkTooltip": "Current Document",
      "XtraRichEditStringId.Caption_CurrentParagraphText": "Sample Text ",
      "XtraRichEditStringId.Caption_CustomPropertyCheckedBy": "Checked by",
      "XtraRichEditStringId.Caption_CustomPropertyClient": "Client",
      "XtraRichEditStringId.Caption_CustomPropertyDateCompleted": "Date completed",
      "XtraRichEditStringId.Caption_CustomPropertyDepartment": "Department",
      "XtraRichEditStringId.Caption_CustomPropertyDestination": "Destination",
      "XtraRichEditStringId.Caption_CustomPropertyDisposition": "Disposition",
      "XtraRichEditStringId.Caption_CustomPropertyDivision": "Division",
      "XtraRichEditStringId.Caption_CustomPropertyDocumentNumber": "Document number",
      "XtraRichEditStringId.Caption_CustomPropertyEditor": "Editor",
      "XtraRichEditStringId.Caption_CustomPropertyForwardTo": "Forward to",
      "XtraRichEditStringId.Caption_CustomPropertyGroup": "Group",
      "XtraRichEditStringId.Caption_CustomPropertyLanguage": "Language",
      "XtraRichEditStringId.Caption_CustomPropertyMailstop": "Mailstop",
      "XtraRichEditStringId.Caption_CustomPropertyMatter": "Matter",
      "XtraRichEditStringId.Caption_CustomPropertyNo": "No",
      "XtraRichEditStringId.Caption_CustomPropertyOffice": "Office",
      "XtraRichEditStringId.Caption_CustomPropertyOwner": "Owner",
      "XtraRichEditStringId.Caption_CustomPropertyProject": "Project",
      "XtraRichEditStringId.Caption_CustomPropertyPublisher": "Publisher",
      "XtraRichEditStringId.Caption_CustomPropertyPurpose": "Purpose",
      "XtraRichEditStringId.Caption_CustomPropertyReceivedFrom": "Received from",
      "XtraRichEditStringId.Caption_CustomPropertyRecordedBy": "Recorded by",
      "XtraRichEditStringId.Caption_CustomPropertyRecordedDate": "Recorded date",
      "XtraRichEditStringId.Caption_CustomPropertyReference": "Reference",
      "XtraRichEditStringId.Caption_CustomPropertySource": "Source",
      "XtraRichEditStringId.Caption_CustomPropertyStatus": "Status",
      "XtraRichEditStringId.Caption_CustomPropertyTelephoneNumber": "Telephone number",
      "XtraRichEditStringId.Caption_CustomPropertyTypeBoolean": "Yes or no",
      "XtraRichEditStringId.Caption_CustomPropertyTypeDateTime": "Date",
      "XtraRichEditStringId.Caption_CustomPropertyTypeNumber": "Number",
      "XtraRichEditStringId.Caption_CustomPropertyTypeText": "Text",
      "XtraRichEditStringId.Caption_CustomPropertyTypist": "Typist",
      "XtraRichEditStringId.Caption_CustomPropertyValue": "Value",
      "XtraRichEditStringId.Caption_CustomPropertyYes": "Yes",
      "XtraRichEditStringId.Caption_EmptyParentStyle": "(underlying properties)",
      "XtraRichEditStringId.Caption_EvenPageFooter": "Even Page Footer",
      "XtraRichEditStringId.Caption_EvenPageHeader": "Even Page Header",
      "XtraRichEditStringId.Caption_FirstLineIndentHanging": "Hanging",
      "XtraRichEditStringId.Caption_FirstLineIndentIndented": "First line",
      "XtraRichEditStringId.Caption_FirstLineIndentNone": "(none)",
      "XtraRichEditStringId.Caption_FirstPageFooter": "First Page Footer",
      "XtraRichEditStringId.Caption_FirstPageHeader": "First Page Header",
      "XtraRichEditStringId.Caption_FollowingParagraphText": "Following Paragraph ",
      "XtraRichEditStringId.Caption_GroupTableAlignment": "Alignment",
      "XtraRichEditStringId.Caption_GroupTableCellSize": "Cell Size",
      "XtraRichEditStringId.Caption_GroupTableDrawBorders": "Borders  Shadings",
      "XtraRichEditStringId.Caption_GroupTableMerge": "Merge",
      "XtraRichEditStringId.Caption_GroupTableRowsAndColumns": "Rows  Columns",
      "XtraRichEditStringId.Caption_GroupTableStyleOptions": "Table Style Options",
      "XtraRichEditStringId.Caption_GroupTableStyles": "Table Styles",
      "XtraRichEditStringId.Caption_GroupTableTable": "Table",
      "XtraRichEditStringId.Caption_Heading": "Heading",
      "XtraRichEditStringId.Caption_HeightTypeExact": "Exactly",
      "XtraRichEditStringId.Caption_HeightTypeMinimum": "At least",
      "XtraRichEditStringId.Caption_LineSpacingAtLeast": "At least",
      "XtraRichEditStringId.Caption_LineSpacingDouble": "Double",
      "XtraRichEditStringId.Caption_LineSpacingExactly": "Exactly",
      "XtraRichEditStringId.Caption_LineSpacingMultiple": "Multiple",
      "XtraRichEditStringId.Caption_LineSpacingSesquialteral": "1.5 lines",
      "XtraRichEditStringId.Caption_LineSpacingSingle": "Single",
      "XtraRichEditStringId.Caption_MainDocumentComments": "Main document comments",
      "XtraRichEditStringId.Caption_NoColor": "No Color",
      "XtraRichEditStringId.Caption_NumberingListBoxNone": "None",
      "XtraRichEditStringId.Caption_OddPageFooter": "Odd Page Footer",
      "XtraRichEditStringId.Caption_OddPageHeader": "Odd Page Header",
      "XtraRichEditStringId.Caption_OutlineLevel1": "Level 1",
      "XtraRichEditStringId.Caption_OutlineLevel2": "Level 2",
      "XtraRichEditStringId.Caption_OutlineLevel3": "Level 3",
      "XtraRichEditStringId.Caption_OutlineLevel4": "Level 4",
      "XtraRichEditStringId.Caption_OutlineLevel5": "Level 5",
      "XtraRichEditStringId.Caption_OutlineLevel6": "Level 6",
      "XtraRichEditStringId.Caption_OutlineLevel7": "Level 7",
      "XtraRichEditStringId.Caption_OutlineLevel8": "Level 8",
      "XtraRichEditStringId.Caption_OutlineLevel9": "Level 9",
      "XtraRichEditStringId.Caption_OutlineLevelBody": "Body Text",
      "XtraRichEditStringId.Caption_PageBreak": "Page Break",
      "XtraRichEditStringId.Caption_PageCategoryTableTools": "Table Tools",
      "XtraRichEditStringId.Caption_PageFooter": "Footer",
      "XtraRichEditStringId.Caption_PageHeader": "Header",
      "XtraRichEditStringId.Caption_PageSetupSectionDirectionLeftToRight": "Left-to-right",
      "XtraRichEditStringId.Caption_PageSetupSectionDirectionRightToLeft": "Right-to-left",
      "XtraRichEditStringId.Caption_PageSetupSectionStartColumn": "New column",
      "XtraRichEditStringId.Caption_PageSetupSectionStartContinuous": "Continuous",
      "XtraRichEditStringId.Caption_PageSetupSectionStartEvenPage": "Even page",
      "XtraRichEditStringId.Caption_PageSetupSectionStartNextPage": "New page",
      "XtraRichEditStringId.Caption_PageSetupSectionStartOddPage": "Odd page",
      "XtraRichEditStringId.Caption_PageTableDesign": "Design",
      "XtraRichEditStringId.Caption_PageTableLayout": "Layout",
      "XtraRichEditStringId.Caption_ParagraphAlignment_Center": "Center",
      "XtraRichEditStringId.Caption_ParagraphAlignment_Distribute": "Distributed",
      "XtraRichEditStringId.Caption_ParagraphAlignment_Justify": "Justify",
      "XtraRichEditStringId.Caption_ParagraphAlignment_JustifyHigh": "Justify High",
      "XtraRichEditStringId.Caption_ParagraphAlignment_JustifyLow": "Justify Low",
      "XtraRichEditStringId.Caption_ParagraphAlignment_JustifyMedium": "Justify Medium",
      "XtraRichEditStringId.Caption_ParagraphAlignment_Left": "Left",
      "XtraRichEditStringId.Caption_ParagraphAlignment_Right": "Right",
      "XtraRichEditStringId.Caption_ParagraphAlignment_ThaiDistribute": "Thai Distributed",
      "XtraRichEditStringId.Caption_ParagraphFirstLineIndent_Hanging": "Hanging",
      "XtraRichEditStringId.Caption_ParagraphFirstLineIndent_Indented": "Indented",
      "XtraRichEditStringId.Caption_ParagraphFirstLineIndent_None": "None",
      "XtraRichEditStringId.Caption_ParagraphLineSpacing_AtLeast": "At Least",
      "XtraRichEditStringId.Caption_ParagraphLineSpacing_Double": "Double",
      "XtraRichEditStringId.Caption_ParagraphLineSpacing_Exactly": "Exactly",
      "XtraRichEditStringId.Caption_ParagraphLineSpacing_Multiple": "Multiple",
      "XtraRichEditStringId.Caption_ParagraphLineSpacing_Sesquialteral": "Sesquialteral",
      "XtraRichEditStringId.Caption_ParagraphLineSpacing_Single": "Single",
      "XtraRichEditStringId.Caption_PreviousParagraphText": "Previous Paragraph ",
      "XtraRichEditStringId.Caption_Readonly": "No changes (Read only)",
      "XtraRichEditStringId.Caption_SameAsPrevious": "Same as Previous",
      "XtraRichEditStringId.Caption_SectionBreak": "Section Break",
      "XtraRichEditStringId.Caption_SectionBreakTypeColumn": "Column",
      "XtraRichEditStringId.Caption_SectionBreakTypeContinuous": "Continuous",
      "XtraRichEditStringId.Caption_SectionBreakTypeEvenPage": "Even Page",
      "XtraRichEditStringId.Caption_SectionBreakTypeNextPage": "Next Page",
      "XtraRichEditStringId.Caption_SectionBreakTypeOddPage": "Odd Page",
      "XtraRichEditStringId.Caption_SectionPropertiesApplyThisPointForward": "This point forward",
      "XtraRichEditStringId.Caption_SectionPropertiesApplyToCurrentSection": "Current section",
      "XtraRichEditStringId.Caption_SectionPropertiesApplyToSelectedSections": "Selected sections",
      "XtraRichEditStringId.Caption_SectionPropertiesApplyToWholeDocument": "Whole document",
      "XtraRichEditStringId.ClearFormatting": "Clear Formatting",
      "XtraRichEditStringId.ColorAuto": "Auto",
      "XtraRichEditStringId.ColumnsSetupForm_InvalidColumnCount": "The number must be between {0} and {1}.",
      "XtraRichEditStringId.Comment": "Comment [{0} {1}]",
      "XtraRichEditStringId.CommentToolTipHeader": "by {0}",
      "XtraRichEditStringId.CommentToolTipHeaderWithDate": "{0} from {1}",
      "XtraRichEditStringId.DefaultDocumentName": "Document",
      "XtraRichEditStringId.DefaultStyleName_annotationreference": "annotation reference",
      "XtraRichEditStringId.DefaultStyleName_annotationsubject": "annotation subject",
      "XtraRichEditStringId.DefaultStyleName_annotationtext": "annotation text",
      "XtraRichEditStringId.DefaultStyleName_ArticleSection": "Article / Section",
      "XtraRichEditStringId.DefaultStyleName_BalloonText": "Balloon Text",
      "XtraRichEditStringId.DefaultStyleName_BlockText": "Block Text",
      "XtraRichEditStringId.DefaultStyleName_BodyText": "Body Text",
      "XtraRichEditStringId.DefaultStyleName_BodyText2": "Body Text 2",
      "XtraRichEditStringId.DefaultStyleName_BodyText3": "Body Text 3",
      "XtraRichEditStringId.DefaultStyleName_BodyTextFirstIndent": "Body Text First Indent",
      "XtraRichEditStringId.DefaultStyleName_BodyTextFirstIndent2": "Body Text First Indent 2",
      "XtraRichEditStringId.DefaultStyleName_BodyTextIndent": "Body Text Indent",
      "XtraRichEditStringId.DefaultStyleName_BodyTextIndent2": "Body Text Indent 2",
      "XtraRichEditStringId.DefaultStyleName_BodyTextIndent3": "Body Text Indent 3",
      "XtraRichEditStringId.DefaultStyleName_caption": "Caption",
      "XtraRichEditStringId.DefaultStyleName_Closing": "Closing",
      "XtraRichEditStringId.DefaultStyleName_CommentReference": "Comment Reference",
      "XtraRichEditStringId.DefaultStyleName_CommentSubject": "Comment Subject",
      "XtraRichEditStringId.DefaultStyleName_CommentText": "Comment Text",
      "XtraRichEditStringId.DefaultStyleName_Date": "Date",
      "XtraRichEditStringId.DefaultStyleName_DefaultParagraphFont": "Default Paragraph Font",
      "XtraRichEditStringId.DefaultStyleName_DocumentMap": "Document Map",
      "XtraRichEditStringId.DefaultStyleName_EmailSignature": "E-mail Signature",
      "XtraRichEditStringId.DefaultStyleName_Emphasis": "Emphasis",
      "XtraRichEditStringId.DefaultStyleName_endnotereference": "Endnote Reference",
      "XtraRichEditStringId.DefaultStyleName_endnotetext": "Endnote Text",
      "XtraRichEditStringId.DefaultStyleName_envelopeaddress": "Envelope Address",
      "XtraRichEditStringId.DefaultStyleName_envelopereturn": "Envelope Return",
      "XtraRichEditStringId.DefaultStyleName_FollowedHyperlink": "FollowedHyperlink",
      "XtraRichEditStringId.DefaultStyleName_footer": "Footer",
      "XtraRichEditStringId.DefaultStyleName_footnotereference": "Footnote Reference",
      "XtraRichEditStringId.DefaultStyleName_footnotetext": "Footnote Text",
      "XtraRichEditStringId.DefaultStyleName_header": "Header",
      "XtraRichEditStringId.DefaultStyleName_heading1": "Heading 1",
      "XtraRichEditStringId.DefaultStyleName_heading2": "Heading 2",
      "XtraRichEditStringId.DefaultStyleName_heading3": "Heading 3",
      "XtraRichEditStringId.DefaultStyleName_heading4": "Heading 4",
      "XtraRichEditStringId.DefaultStyleName_heading5": "Heading 5",
      "XtraRichEditStringId.DefaultStyleName_heading6": "Heading 6",
      "XtraRichEditStringId.DefaultStyleName_heading7": "Heading 7",
      "XtraRichEditStringId.DefaultStyleName_heading8": "Heading 8",
      "XtraRichEditStringId.DefaultStyleName_heading9": "Heading 9",
      "XtraRichEditStringId.DefaultStyleName_HTMLAcronym": "HTML Acronym",
      "XtraRichEditStringId.DefaultStyleName_HTMLAddress": "HTML Address",
      "XtraRichEditStringId.DefaultStyleName_HTMLBottomofForm": "HTML Bottom of Form",
      "XtraRichEditStringId.DefaultStyleName_HTMLCite": "HTML Cite",
      "XtraRichEditStringId.DefaultStyleName_HTMLCode": "HTML Code",
      "XtraRichEditStringId.DefaultStyleName_HTMLDefinition": "HTML Definition",
      "XtraRichEditStringId.DefaultStyleName_HTMLKeyboard": "HTML Keyboard",
      "XtraRichEditStringId.DefaultStyleName_HTMLPreformatted": "HTML Preformatted",
      "XtraRichEditStringId.DefaultStyleName_HTMLSample": "HTML Sample",
      "XtraRichEditStringId.DefaultStyleName_HTMLTopofForm": "HTML Top of Form",
      "XtraRichEditStringId.DefaultStyleName_HTMLTypewriter": "HTML Typewriter",
      "XtraRichEditStringId.DefaultStyleName_HTMLVariable": "HTML Variable",
      "XtraRichEditStringId.DefaultStyleName_HyperlinkFollowed": "HyperlinkFollowed",
      "XtraRichEditStringId.DefaultStyleName_HyperlinkStrongEmphasis": "HyperlinkStrongEmphasis",
      "XtraRichEditStringId.DefaultStyleName_index1": "Index 1",
      "XtraRichEditStringId.DefaultStyleName_index2": "Index 2",
      "XtraRichEditStringId.DefaultStyleName_index3": "Index 3",
      "XtraRichEditStringId.DefaultStyleName_index4": "Index 4",
      "XtraRichEditStringId.DefaultStyleName_index5": "Index 5",
      "XtraRichEditStringId.DefaultStyleName_index6": "Index 6",
      "XtraRichEditStringId.DefaultStyleName_index7": "Index 7",
      "XtraRichEditStringId.DefaultStyleName_index8": "Index 8",
      "XtraRichEditStringId.DefaultStyleName_index9": "Index 9",
      "XtraRichEditStringId.DefaultStyleName_indexheading": "Index Heading",
      "XtraRichEditStringId.DefaultStyleName_linenumber": "Line Number",
      "XtraRichEditStringId.DefaultStyleName_List": "List",
      "XtraRichEditStringId.DefaultStyleName_List2": "List 2",
      "XtraRichEditStringId.DefaultStyleName_List3": "List 3",
      "XtraRichEditStringId.DefaultStyleName_List4": "List 4",
      "XtraRichEditStringId.DefaultStyleName_List5": "List 5",
      "XtraRichEditStringId.DefaultStyleName_ListBullet": "List Bullet",
      "XtraRichEditStringId.DefaultStyleName_ListBullet2": "List Bullet 2",
      "XtraRichEditStringId.DefaultStyleName_ListBullet3": "List Bullet 3",
      "XtraRichEditStringId.DefaultStyleName_ListBullet4": "List Bullet 4",
      "XtraRichEditStringId.DefaultStyleName_ListBullet5": "List Bullet 5",
      "XtraRichEditStringId.DefaultStyleName_ListContinue": "List Continue",
      "XtraRichEditStringId.DefaultStyleName_ListContinue2": "List Continue 2",
      "XtraRichEditStringId.DefaultStyleName_ListContinue3": "List Continue 3",
      "XtraRichEditStringId.DefaultStyleName_ListContinue4": "List Continue 4",
      "XtraRichEditStringId.DefaultStyleName_ListContinue5": "List Continue 5",
      "XtraRichEditStringId.DefaultStyleName_ListNumber": "List Number",
      "XtraRichEditStringId.DefaultStyleName_ListNumber2": "List Number 2",
      "XtraRichEditStringId.DefaultStyleName_ListNumber3": "List Number 3",
      "XtraRichEditStringId.DefaultStyleName_ListNumber4": "List Number 4",
      "XtraRichEditStringId.DefaultStyleName_ListNumber5": "List Number 5",
      "XtraRichEditStringId.DefaultStyleName_MacroText": "Macro Text",
      "XtraRichEditStringId.DefaultStyleName_macrotoaheading": "macro to a heading",
      "XtraRichEditStringId.DefaultStyleName_MessageHeader": "Message Header",
      "XtraRichEditStringId.DefaultStyleName_NoList": "No List",
      "XtraRichEditStringId.DefaultStyleName_Normal": "Normal",
      "XtraRichEditStringId.DefaultStyleName_NormalIndent": "Normal Indent",
      "XtraRichEditStringId.DefaultStyleName_NormalTable": "Normal Table",
      "XtraRichEditStringId.DefaultStyleName_NormalWeb": "Normal (Web)",
      "XtraRichEditStringId.DefaultStyleName_NoteHeading": "Note Heading",
      "XtraRichEditStringId.DefaultStyleName_OutlineList1": "Outline List 1",
      "XtraRichEditStringId.DefaultStyleName_OutlineList2": "Outline List 2",
      "XtraRichEditStringId.DefaultStyleName_OutlineList3": "Outline List 3",
      "XtraRichEditStringId.DefaultStyleName_pagenumber": "Page Number",
      "XtraRichEditStringId.DefaultStyleName_PlainText": "Plain Text",
      "XtraRichEditStringId.DefaultStyleName_Salutation": "Salutation",
      "XtraRichEditStringId.DefaultStyleName_Signature": "Signature",
      "XtraRichEditStringId.DefaultStyleName_Strong": "Strong",
      "XtraRichEditStringId.DefaultStyleName_Subtitle": "Subtitle",
      "XtraRichEditStringId.DefaultStyleName_Table3Deffects1": "Table 3D effects 1",
      "XtraRichEditStringId.DefaultStyleName_Table3Deffects2": "Table 3D effects 2",
      "XtraRichEditStringId.DefaultStyleName_Table3Deffects3": "Table 3D effects 3",
      "XtraRichEditStringId.DefaultStyleName_TableClassic1": "Table Classic 1",
      "XtraRichEditStringId.DefaultStyleName_TableClassic2": "Table Classic 2",
      "XtraRichEditStringId.DefaultStyleName_TableClassic3": "Table Classic 3",
      "XtraRichEditStringId.DefaultStyleName_TableClassic4": "Table Classic 4",
      "XtraRichEditStringId.DefaultStyleName_TableColorful1": "Table Colorful 1",
      "XtraRichEditStringId.DefaultStyleName_TableColorful2": "Table Colorful 2",
      "XtraRichEditStringId.DefaultStyleName_TableColorful3": "Table Colorful 3",
      "XtraRichEditStringId.DefaultStyleName_TableColumns1": "Table Columns 1",
      "XtraRichEditStringId.DefaultStyleName_TableColumns2": "Table Columns 2",
      "XtraRichEditStringId.DefaultStyleName_TableColumns3": "Table Columns 3",
      "XtraRichEditStringId.DefaultStyleName_TableColumns4": "Table Columns 4",
      "XtraRichEditStringId.DefaultStyleName_TableColumns5": "Table Columns 5",
      "XtraRichEditStringId.DefaultStyleName_TableContemporary": "Table Contemporary",
      "XtraRichEditStringId.DefaultStyleName_TableElegant": "Table Elegant",
      "XtraRichEditStringId.DefaultStyleName_TableGrid": "Table Grid",
      "XtraRichEditStringId.DefaultStyleName_TableGrid1": "Table Grid 1",
      "XtraRichEditStringId.DefaultStyleName_TableGrid2": "Table Grid 2",
      "XtraRichEditStringId.DefaultStyleName_TableGrid3": "Table Grid 3",
      "XtraRichEditStringId.DefaultStyleName_TableGrid4": "Table Grid 4",
      "XtraRichEditStringId.DefaultStyleName_TableGrid5": "Table Grid 5",
      "XtraRichEditStringId.DefaultStyleName_TableGrid6": "Table Grid 6",
      "XtraRichEditStringId.DefaultStyleName_TableGrid7": "Table Grid 7",
      "XtraRichEditStringId.DefaultStyleName_TableGrid8": "Table Grid 8",
      "XtraRichEditStringId.DefaultStyleName_TableList1": "Table List 1",
      "XtraRichEditStringId.DefaultStyleName_TableList2": "Table List 2",
      "XtraRichEditStringId.DefaultStyleName_TableList3": "Table List 3",
      "XtraRichEditStringId.DefaultStyleName_TableList4": "Table List 4",
      "XtraRichEditStringId.DefaultStyleName_TableList5": "Table List 5",
      "XtraRichEditStringId.DefaultStyleName_TableList6": "Table List 6",
      "XtraRichEditStringId.DefaultStyleName_TableList7": "Table List 7",
      "XtraRichEditStringId.DefaultStyleName_TableList8": "Table List 8",
      "XtraRichEditStringId.DefaultStyleName_TableNormal": "Table Normal",
      "XtraRichEditStringId.DefaultStyleName_tableofauthorities": "Table of Authorities",
      "XtraRichEditStringId.DefaultStyleName_tableoffigures": "Table of Figures",
      "XtraRichEditStringId.DefaultStyleName_TableProfessional": "Table Professional",
      "XtraRichEditStringId.DefaultStyleName_TableSimple1": "Table Simple 1",
      "XtraRichEditStringId.DefaultStyleName_TableSimple2": "Table Simple 2",
      "XtraRichEditStringId.DefaultStyleName_TableSimple3": "Table Simple 3",
      "XtraRichEditStringId.DefaultStyleName_TableSubtle1": "Table Subtle 1",
      "XtraRichEditStringId.DefaultStyleName_TableSubtle2": "Table Subtle 2",
      "XtraRichEditStringId.DefaultStyleName_TableTheme": "Table Theme",
      "XtraRichEditStringId.DefaultStyleName_TableWeb1": "Table Web 1",
      "XtraRichEditStringId.DefaultStyleName_TableWeb2": "Table Web 2",
      "XtraRichEditStringId.DefaultStyleName_TableWeb3": "Table Web 3",
      "XtraRichEditStringId.DefaultStyleName_Title": "Title",
      "XtraRichEditStringId.DefaultStyleName_toaheading": "TOA Heading",
      "XtraRichEditStringId.DefaultStyleName_toc1": "TOC 1",
      "XtraRichEditStringId.DefaultStyleName_toc2": "TOC 2",
      "XtraRichEditStringId.DefaultStyleName_toc3": "TOC 3",
      "XtraRichEditStringId.DefaultStyleName_toc4": "TOC 4",
      "XtraRichEditStringId.DefaultStyleName_toc5": "TOC 5",
      "XtraRichEditStringId.DefaultStyleName_toc6": "TOC 6",
      "XtraRichEditStringId.DefaultStyleName_toc7": "TOC 7",
      "XtraRichEditStringId.DefaultStyleName_toc8": "TOC 8",
      "XtraRichEditStringId.DefaultStyleName_toc9": "TOC 9",
      "XtraRichEditStringId.DialogCaption_InsertSymbol": "Symbol",
      "XtraRichEditStringId.FieldLastName": "Last Name",
      "XtraRichEditStringId.FieldMapAddress1": "Address 1",
      "XtraRichEditStringId.FieldMapAddress2": "Address 2",
      "XtraRichEditStringId.FieldMapAddress3": "Address 3",
      "XtraRichEditStringId.FieldMapBusinessFax": "Business Fax",
      "XtraRichEditStringId.FieldMapBusinessPhone": "Business Phone",
      "XtraRichEditStringId.FieldMapCity": "City",
      "XtraRichEditStringId.FieldMapCompany": "Company",
      "XtraRichEditStringId.FieldMapCountry": "Country or Region",
      "XtraRichEditStringId.FieldMapDepartment": "Department",
      "XtraRichEditStringId.FieldMapEMailAddress": "E-mail Address",
      "XtraRichEditStringId.FieldMapFirstName": "First Name",
      "XtraRichEditStringId.FieldMapHomeFax": "Home Fax",
      "XtraRichEditStringId.FieldMapHomePhone": "Home Phone",
      "XtraRichEditStringId.FieldMapJobTitle": "Job Title",
      "XtraRichEditStringId.FieldMapMiddleName": "Middle Name",
      "XtraRichEditStringId.FieldMapNickName": "Nickname",
      "XtraRichEditStringId.FieldMapPartnerFirstName": "Spouse/Partner First Name",
      "XtraRichEditStringId.FieldMapPartnerLastName": "Spouse/Partner Last Name",
      "XtraRichEditStringId.FieldMapPartnerMiddleName": "Spouse/Partner Middle Name",
      "XtraRichEditStringId.FieldMapPartnerNickName": "Spouse/Partner Nickname",
      "XtraRichEditStringId.FieldMapPartnerTitle": "Spouse/Partner Courtesy Title",
      "XtraRichEditStringId.FieldMapPhoneticGuideFirstName": "Phonetic Guide for First Name",
      "XtraRichEditStringId.FieldMapPhoneticGuideLastName": "Phonetic Guide for Last Name",
      "XtraRichEditStringId.FieldMapPostalCode": "Postal Code",
      "XtraRichEditStringId.FieldMapState": "State",
      "XtraRichEditStringId.FieldMapSuffix": "Suffix",
      "XtraRichEditStringId.FieldMapTitle": "Courtesy Title",
      "XtraRichEditStringId.FieldMapUniqueId": "Unique Identifier",
      "XtraRichEditStringId.FieldMapWebPage": "Web Page",
      "XtraRichEditStringId.FileFilterDescription_AllFiles": "All Files",
      "XtraRichEditStringId.FileFilterDescription_AllSupportedFiles": "All Supported Files",
      "XtraRichEditStringId.FileFilterDescription_BitmapFiles": "Bitmap",
      "XtraRichEditStringId.FileFilterDescription_DocFiles": "Microsoft Word Document",
      "XtraRichEditStringId.FileFilterDescription_EmfFiles": "Microsoft Enhanced Metafile",
      "XtraRichEditStringId.FileFilterDescription_ePubFiles": "Electronic Publication",
      "XtraRichEditStringId.FileFilterDescription_GifFiles": "Graphics Interchange Format",
      "XtraRichEditStringId.FileFilterDescription_HtmlFiles": "HyperText Markup Language Format",
      "XtraRichEditStringId.FileFilterDescription_JPEGFiles": "JPEG File Interchange Format",
      "XtraRichEditStringId.FileFilterDescription_MhtFiles": "Web Archive, single file",
      "XtraRichEditStringId.FileFilterDescription_OpenDocumentFiles": "OpenDocument Text Document",
      "XtraRichEditStringId.FileFilterDescription_OpenXmlFiles": "Word 2007 Document",
      "XtraRichEditStringId.FileFilterDescription_PDFFiles": "Portable Document Format",
      "XtraRichEditStringId.FileFilterDescription_PNGFiles": "Portable Network Graphics",
      "XtraRichEditStringId.FileFilterDescription_RtfFiles": "Rich Text Format",
      "XtraRichEditStringId.FileFilterDescription_TextFiles": "Text Files",
      "XtraRichEditStringId.FileFilterDescription_TiffFiles": "Tagged Image Format",
      "XtraRichEditStringId.FileFilterDescription_WmfFiles": "Windows Metafile",
      "XtraRichEditStringId.FileFilterDescription_WordMLFiles": "Word 2003 XML Document",
      "XtraRichEditStringId.FileFilterDescription_XamlFiles": "Xaml Document",
      "XtraRichEditStringId.FindAndReplaceForm_AnyOneCharacterInTheSet": "Any one character in the set",
      "XtraRichEditStringId.FindAndReplaceForm_AnyOneCharacterNotInTheSet": "Any one character not in the set",
      "XtraRichEditStringId.FindAndReplaceForm_AnySingleCharacter": "Any single character",
      "XtraRichEditStringId.FindAndReplaceForm_BeginningOfLine": "Beginning of paragraph",
      "XtraRichEditStringId.FindAndReplaceForm_BeginningOfWord": "Beginning of word",
      "XtraRichEditStringId.FindAndReplaceForm_EndOfLine": "End of paragraph",
      "XtraRichEditStringId.FindAndReplaceForm_EndOfWord": "End of word",
      "XtraRichEditStringId.FindAndReplaceForm_EscapeSpecialCharacter": "Escape special character",
      "XtraRichEditStringId.FindAndReplaceForm_Integer": "Integer",
      "XtraRichEditStringId.FindAndReplaceForm_OneOrMore": "One or more",
      "XtraRichEditStringId.FindAndReplaceForm_Or": "Or",
      "XtraRichEditStringId.FindAndReplaceForm_SpaceOrTab": "Space or tab",
      "XtraRichEditStringId.FindAndReplaceForm_TagExpression": "Tag expression",
      "XtraRichEditStringId.FindAndReplaceForm_TaggedExpression": "Tagged expression {0}",
      "XtraRichEditStringId.FindAndReplaceForm_WordCharacter": "Word character",
      "XtraRichEditStringId.FindAndReplaceForm_ZeroOrMore": "Zero or more",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionAlignmentCenter": "Centered",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionAlignmentLeft": "Left",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionAlignmentRight": "Right",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeCharacter": "Character",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeColumn": "Column",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeInsideMargin": "Inside Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeLeftMargin": "Left Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeMargin": "Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeOutsideMargin": "Outside Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypePage": "Page",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_HorizontalPositionTypeRightMargin": "Right Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentBottom": "Bottom",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentCenter": "Center",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentInside": "Inside",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentOutside": "Outside",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionAlignmentTop": "Top",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeBottomMargin": "Bottom Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeInsideMargin": "Inside Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeLine": "Line",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeMargin": "Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeOutsideMargin": "Outside Margin",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypePage": "Page",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeParagraph": "Paragraph",
      "XtraRichEditStringId.FloatingObjectLayoutOptionsForm_VerticalPositionTypeTopMargin": "Top Margin",
      "XtraRichEditStringId.FontStyle_Bold": "Bold",
      "XtraRichEditStringId.FontStyle_BoldItalic": "Bold Italic",
      "XtraRichEditStringId.FontStyle_Italic": "Italic",
      "XtraRichEditStringId.FontStyle_Regular": "Regular",
      "XtraRichEditStringId.FontStyle_Strikeout": "Strikeout",
      "XtraRichEditStringId.FontStyle_Underline": "Underline",
      "XtraRichEditStringId.HyperlinkForm_EditHyperlinkTitle": "Edit Hyperlink",
      "XtraRichEditStringId.HyperlinkForm_InsertHyperlinkTitle": "Insert Hyperlink",
      "XtraRichEditStringId.HyperlinkForm_SelectedBookmarkNone": "&lt;None&gt;",
      "XtraRichEditStringId.HyperlinkForm_SelectionInDocument": "Selection in document",
      "XtraRichEditStringId.InsertTableForm_InvalidColumnCount": "The number must be between {0} and {1}.",
      "XtraRichEditStringId.InsertTableForm_InvalidRowCount": "The number must be between {0} and {1}.",
      "XtraRichEditStringId.KeyName_Alt": "Alt",
      "XtraRichEditStringId.KeyName_Control": "Ctrl",
      "XtraRichEditStringId.KeyName_Shift": "Shift",
      "XtraRichEditStringId.LinkedCharacterStyleFormatString": "{0} Char",
      "XtraRichEditStringId.LocalizedStyleName_Hyperlink": "Hyperlink",
      "XtraRichEditStringId.LocalizedStyleName_Normal": "Normal",
      "XtraRichEditStringId.MenuCmd_AddParagraphsToTableOfContents": "Add Text",
      "XtraRichEditStringId.MenuCmd_AddParagraphsToTableOfContentsDescription": "Add the current paragraph as an entry in the Table of Contents.",
      "XtraRichEditStringId.MenuCmd_AddSpacingAfterParagraph": "Add Space After Paragraph",
      "XtraRichEditStringId.MenuCmd_AddSpacingAfterParagraphDescription": " ",
      "XtraRichEditStringId.MenuCmd_AddSpacingBeforeParagraph": "Add Space Before Paragraph",
      "XtraRichEditStringId.MenuCmd_AddSpacingBeforeParagraphDescription": " ",
      "XtraRichEditStringId.MenuCmd_AddWordToDictionary": "Add to Dictionary",
      "XtraRichEditStringId.MenuCmd_AddWordToDictionaryDescription": "Add to Dictionary",
      "XtraRichEditStringId.MenuCmd_AllAuthors": "All Authors",
      "XtraRichEditStringId.MenuCmd_BackSpaceKey": "BackSpaceKey",
      "XtraRichEditStringId.MenuCmd_BackSpaceKeyDescription": "BackSpaceKey",
      "XtraRichEditStringId.MenuCmd_Bookmark": "Bookmark...",
      "XtraRichEditStringId.MenuCmd_BookmarkDescription": "Bookmark...",
      "XtraRichEditStringId.MenuCmd_BrowserPrint": "Browser Print",
      "XtraRichEditStringId.MenuCmd_BrowserPrintDescription": "Print document with browser print capabilities.",
      "XtraRichEditStringId.MenuCmd_BrowserPrintPreview": "Browser Print Preview",
      "XtraRichEditStringId.MenuCmd_BrowserPrintPreviewDescription": "Preview pages in browser before printing.",
      "XtraRichEditStringId.MenuCmd_CapitalizeEachWordTextCase": "Capitalize Each Word",
      "XtraRichEditStringId.MenuCmd_CapitalizeEachWordTextCaseDescription": "Capitalize each word.",
      "XtraRichEditStringId.MenuCmd_ChangeCharacterStyle": "ChangeCharacterStyle",
      "XtraRichEditStringId.MenuCmd_ChangeCharacterStyleDescription": "ChangeCharacterStyle",
      "XtraRichEditStringId.MenuCmd_ChangeColumnCount": "Columns",
      "XtraRichEditStringId.MenuCmd_ChangeColumnCountDescription": "Columns",
      "XtraRichEditStringId.MenuCmd_ChangeColumnSize": "ChangeColumnSize",
      "XtraRichEditStringId.MenuCmd_ChangeColumnSizeDescription": "ChangeColumnSizeDescription",
      "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemColor": "Pen Color",
      "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemColorDescription": "Change the pen color.",
      "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemLineStyle": "Line Style",
      "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemLineStyleDescription": "Change the style of the line used to draw borders.",
      "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemLineThickness": "Line Weight",
      "XtraRichEditStringId.MenuCmd_ChangeCurrentBorderRepositoryItemLineThicknessDescription": "Change the width of the line used to draw borders.",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectAlignment": "Position",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectAlignmentDescription": "Position the selected object on the page. Text is automatically set to wrap around the object.",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectFillColor": "Shape Fill",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectFillColorDescription": "Fill the selected shape with a solid color.",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectOutlineColor": "Shape Outline",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectOutlineColorDescription": "Specify color for the outline of the selected shape.",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectOutlineWidth": "Shape Outline Weight",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectOutlineWidthDescription": "Specify width for the outline of the selected shape.",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectTextWrapType": "Wrap Text",
      "XtraRichEditStringId.MenuCmd_ChangeFontColor": "Font Color",
      "XtraRichEditStringId.MenuCmd_ChangeFontColorDescription": "Change the font color.",
      "XtraRichEditStringId.MenuCmd_ChangeFontName": "Font",
      "XtraRichEditStringId.MenuCmd_ChangeFontNameDescription": "Change the font face.",
      "XtraRichEditStringId.MenuCmd_ChangeFontSize": "Font Size",
      "XtraRichEditStringId.MenuCmd_ChangeFontSizeDescription": "Change the font size.",
      "XtraRichEditStringId.MenuCmd_ChangeHyphenationOptions": "Hyphenation",
      "XtraRichEditStringId.MenuCmd_ChangeHyphenationOptionsDescription": "Turn on hyphenation to create more uniform spacing and save space in your document.",
      "XtraRichEditStringId.MenuCmd_ChangeIndent": "ChangeIndent",
      "XtraRichEditStringId.MenuCmd_ChangeIndentDescription": "ChangeIndentDescription",
      "XtraRichEditStringId.MenuCmd_ChangeLanguage": "Language",
      "XtraRichEditStringId.MenuCmd_ChangeLanguageDescription": "Set the language used to check the spelling and grammar of the selected text.",
      "XtraRichEditStringId.MenuCmd_ChangeMistakenWord": "No spelling suggestions",
      "XtraRichEditStringId.MenuCmd_ChangeMistakenWordDescription": "No spelling suggestions",
      "XtraRichEditStringId.MenuCmd_ChangeNoProof": "NoProof",
      "XtraRichEditStringId.MenuCmd_ChangeNoProofDescription": "Change NoProof",
      "XtraRichEditStringId.MenuCmd_ChangePageColor": "Page Color",
      "XtraRichEditStringId.MenuCmd_ChangePageColorDescription": "Choose a color for the background of the page.",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphBackColor": "Shading",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphBackColorDescription": "Change the background behind the selected paragraph.",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphFirstLineIndent": "IncrementParagraphFirstLineIndent",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphFirstLineIndentDescription": "IncrementParagraphFirstLineIndentDescription",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphLeftIndent": "ChangeParagraphLeftIndent",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphLeftIndentDescription": "ChangeParagraphLeftIndentDescription",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphLineSpacing": "Line Spacing",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphLineSpacingDescription": "Change the spacing between lines of text.You can also customize the amount of space added before and after paragraphs.",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphStyle": "ChangeParagraphStyle",
      "XtraRichEditStringId.MenuCmd_ChangeParagraphStyleDescription": "ChangeParagraphStyle",
      "XtraRichEditStringId.MenuCmd_ChangeSectionLineNumbering": "Line Numbers",
      "XtraRichEditStringId.MenuCmd_ChangeSectionLineNumberingDescription": "Add line numbers in the margin alongside of each line of the document.",
      "XtraRichEditStringId.MenuCmd_ChangeSectionPageMargins": "Margins",
      "XtraRichEditStringId.MenuCmd_ChangeSectionPageMarginsDescription": "Select the margin sizes for the entire document or the current section.",
      "XtraRichEditStringId.MenuCmd_ChangeSectionPageOrientation": "Orientation",
      "XtraRichEditStringId.MenuCmd_ChangeSectionPageOrientationDescription": "Switch the pages between portrait and landscape layouts.",
      "XtraRichEditStringId.MenuCmd_ChangeSectionPagePaperKind": "Size",
      "XtraRichEditStringId.MenuCmd_ChangeSectionPagePaperKindDescription": "Choose a paper size for the current section.",
      "XtraRichEditStringId.MenuCmd_ChangeStyle": "Quick Styles",
      "XtraRichEditStringId.MenuCmd_ChangeStyleDescription": "Format titles, quotes, and other text using this gallery of styles.",
      "XtraRichEditStringId.MenuCmd_ChangeTableBorders": "Borders",
      "XtraRichEditStringId.MenuCmd_ChangeTableBordersDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ChangeTableCellAlignmentPlaceholder": "Alignment",
      "XtraRichEditStringId.MenuCmd_ChangeTableCellAlignmentPlaceholderDescription": "Customize the alignment of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ChangeTableCellsContentAlignment": "Cell Alignment",
      "XtraRichEditStringId.MenuCmd_ChangeTableCellsContentAlignmentDescription": "Cell Alignment",
      "XtraRichEditStringId.MenuCmd_ChangeTableCellShading": "Shading",
      "XtraRichEditStringId.MenuCmd_ChangeTableCellShadingDescription": "Color the background behind the selected cells.",
      "XtraRichEditStringId.MenuCmd_ChangeTextCase": "Change Case",
      "XtraRichEditStringId.MenuCmd_ChangeTextCaseDescription": "Change all the selected text to UPPERCASE, lowercase, or other common capitalizations.",
      "XtraRichEditStringId.MenuCmd_CheckSpelling": "Spelling",
      "XtraRichEditStringId.MenuCmd_CheckSpellingDescription": "Check the spelling of text in the document.",
      "XtraRichEditStringId.MenuCmd_ClearFormatting": "Clear Formatting",
      "XtraRichEditStringId.MenuCmd_ClearFormattingDescription": "Clear all the formatting from the selection, leaving only plain text.",
      "XtraRichEditStringId.MenuCmd_ClearUndo": "ClearUndo",
      "XtraRichEditStringId.MenuCmd_ClearUndoDescription": "Clear Undo Buffer",
      "XtraRichEditStringId.MenuCmd_ClosePageHeaderFooter": "Close Header and Footer",
      "XtraRichEditStringId.MenuCmd_ClosePageHeaderFooterDescription": "Close the Header and Footer Tools.You can also double click the document area to return to editing it.",
      "XtraRichEditStringId.MenuCmd_CollapseOrExpandFormulaBar": "Collapse or expand Formula Bar",
      "XtraRichEditStringId.MenuCmd_CollapseOrExpandFormulaBarDescription": "Collapse or expand Formula Bar (Ctrl+Shift+U)",
      "XtraRichEditStringId.MenuCmd_Comment": "View Comments",
      "XtraRichEditStringId.MenuCmd_CommentDescription": "Choose whether or not to highlight comments in the document.",
      "XtraRichEditStringId.MenuCmd_CopySelection": "Copy",
      "XtraRichEditStringId.MenuCmd_CopySelectionDescription": "Copy the selection and put it on the Clipboard.",
      "XtraRichEditStringId.MenuCmd_CreateBookmark": "Create Bookmark",
      "XtraRichEditStringId.MenuCmd_CreateBookmarkDescription": "Create Bookmark",
      "XtraRichEditStringId.MenuCmd_CreateField": "Create Field",
      "XtraRichEditStringId.MenuCmd_CreateFieldDescription": "Create Field",
      "XtraRichEditStringId.MenuCmd_CreateHyperlink": "CreateHyperlink",
      "XtraRichEditStringId.MenuCmd_CreateHyperlinkDescription": "CreateHyperlinkDescription",
      "XtraRichEditStringId.MenuCmd_CutSelection": "Cut",
      "XtraRichEditStringId.MenuCmd_CutSelectionDescription": "Cut the selection from the document and put it on the Clipboard.",
      "XtraRichEditStringId.MenuCmd_DecreaseFontSize": "Shrink Font",
      "XtraRichEditStringId.MenuCmd_DecreaseFontSizeDescription": "Decrease the font size.",
      "XtraRichEditStringId.MenuCmd_DecrementFontSize": "DecrementFontSize",
      "XtraRichEditStringId.MenuCmd_DecrementFontSizeDescription": "DecrementFontSize",
      "XtraRichEditStringId.MenuCmd_DecrementIndent": "Decrease Indent",
      "XtraRichEditStringId.MenuCmd_DecrementIndentDescription": "Decrease the indent level of the paragraph.",
      "XtraRichEditStringId.MenuCmd_DecrementNumerationFromParagraph": "DecrementNumerationFromParagraph",
      "XtraRichEditStringId.MenuCmd_DecrementNumerationFromParagraphDescription": "DecrementNumerationFromParagraph",
      "XtraRichEditStringId.MenuCmd_DecrementParagraphLeftIndent": "DecrementParagraphLeftIndent",
      "XtraRichEditStringId.MenuCmd_DecrementParagraphLeftIndentDescription": "DecrementParagraphLeftIndentDescription",
      "XtraRichEditStringId.MenuCmd_DecrementParagraphOutlineLevel": "Decrement outline level",
      "XtraRichEditStringId.MenuCmd_DecrementParagraphOutlineLevelDescription": "Decrement outline level",
      "XtraRichEditStringId.MenuCmd_Delete": "Delete",
      "XtraRichEditStringId.MenuCmd_DeleteAllComments": "Delete All Comments",
      "XtraRichEditStringId.MenuCmd_DeleteAllCommentsDescription": "Delete All Comments.",
      "XtraRichEditStringId.MenuCmd_DeleteAllCommentsShown": "Delete All Comments Shown",
      "XtraRichEditStringId.MenuCmd_DeleteAllCommentsShownDescription": "Delete All Comments Shown.",
      "XtraRichEditStringId.MenuCmd_DeleteBack": "DeleteBack",
      "XtraRichEditStringId.MenuCmd_DeleteBackCore": "DeleteBackCore",
      "XtraRichEditStringId.MenuCmd_DeleteBackCoreDescription": "DeleteBackCoreDescription",
      "XtraRichEditStringId.MenuCmd_DeleteBackDescription": "DeleteBack",
      "XtraRichEditStringId.MenuCmd_DeleteBookmark": "Delete Bookmark",
      "XtraRichEditStringId.MenuCmd_DeleteBookmarkDescription": "Delete Bookmark",
      "XtraRichEditStringId.MenuCmd_DeleteComment": "Delete",
      "XtraRichEditStringId.MenuCmd_DeleteCommentDescription": "Delete the selected comment.",
      "XtraRichEditStringId.MenuCmd_DeleteCore": "DeleteCore",
      "XtraRichEditStringId.MenuCmd_DeleteCoreDescription": "DeleteCoreDescription",
      "XtraRichEditStringId.MenuCmd_DeleteDescription": "Delete",
      "XtraRichEditStringId.MenuCmd_DeleteNumerationFromParagraph": "DeleteNumerationFromParagraph",
      "XtraRichEditStringId.MenuCmd_DeleteNumerationFromParagraphDescription": "DeleteNumerationFromParagraph",
      "XtraRichEditStringId.MenuCmd_DeleteOneComment": "Delete Comment",
      "XtraRichEditStringId.MenuCmd_DeleteOneCommentDescription": "Delete the selected comment.",
      "XtraRichEditStringId.MenuCmd_DeleteRepeatedWord": "Delete Repeated Word",
      "XtraRichEditStringId.MenuCmd_DeleteRepeatedWordDescription": "Delete Repeated Word",
      "XtraRichEditStringId.MenuCmd_DeleteTable": "Delete Table",
      "XtraRichEditStringId.MenuCmd_DeleteTableCells": "Delete Cells...",
      "XtraRichEditStringId.MenuCmd_DeleteTableCellsDescription": "Delete rows, columns, or cells.",
      "XtraRichEditStringId.MenuCmd_DeleteTableCellsMenuItem": "Delete Cells...",
      "XtraRichEditStringId.MenuCmd_DeleteTableColumns": "Delete Columns",
      "XtraRichEditStringId.MenuCmd_DeleteTableColumnsDescription": "Delete Columns",
      "XtraRichEditStringId.MenuCmd_DeleteTableDescription": "Delete Entire Table.",
      "XtraRichEditStringId.MenuCmd_DeleteTableElements": "Delete",
      "XtraRichEditStringId.MenuCmd_DeleteTableElementsDescription": "Delete rows, columns, cells, or the entire Table.",
      "XtraRichEditStringId.MenuCmd_DeleteTableRows": "Delete Rows",
      "XtraRichEditStringId.MenuCmd_DeleteTableRowsDescription": "Delete Rows",
      "XtraRichEditStringId.MenuCmd_DeleteTableStyle": "Delete Table Style...",
      "XtraRichEditStringId.MenuCmd_DeleteWord": "DeleteWord",
      "XtraRichEditStringId.MenuCmd_DeleteWordBack": "DeleteWordBack",
      "XtraRichEditStringId.MenuCmd_DeleteWordBackCore": "DeleteWordBack",
      "XtraRichEditStringId.MenuCmd_DeleteWordBackCoreDescription": "DeleteWordBack",
      "XtraRichEditStringId.MenuCmd_DeleteWordBackDescription": "DeleteWordBack",
      "XtraRichEditStringId.MenuCmd_DeleteWordCore": "DeleteWordCore",
      "XtraRichEditStringId.MenuCmd_DeleteWordCoreDescription": "DeleteWordCoreDescription",
      "XtraRichEditStringId.MenuCmd_DeleteWordDescription": "DeleteWord",
      "XtraRichEditStringId.MenuCmd_DeselectAll": "Deselect All",
      "XtraRichEditStringId.MenuCmd_DeselectAllDescription": "Reset document selection.",
      "XtraRichEditStringId.MenuCmd_DraftView": "Draft View",
      "XtraRichEditStringId.MenuCmd_DraftViewDescription": "Draft View",
      "XtraRichEditStringId.MenuCmd_EditHyperlink": "Edit hyperlink...",
      "XtraRichEditStringId.MenuCmd_EditHyperlinkDescription": "Edit hyperlink...",
      "XtraRichEditStringId.MenuCmd_EditPageFooter": "Footer",
      "XtraRichEditStringId.MenuCmd_EditPageFooterDescription": "Edit the footer of the document.The content in the footer will appear at the bottom of each printed page.",
      "XtraRichEditStringId.MenuCmd_EditPageHeader": "Header",
      "XtraRichEditStringId.MenuCmd_EditPageHeaderDescription": "Edit the header of the document.The content in the header will appear at the top of each printed page.",
      "XtraRichEditStringId.MenuCmd_EditTOC": "Edit Table of Contents...",
      "XtraRichEditStringId.MenuCmd_EncryptDocument": "Encrypt with Password",
      "XtraRichEditStringId.MenuCmd_EncryptDocumentDescription": "Password-protect this document.",
      "XtraRichEditStringId.MenuCmd_EnsureCaretVisibleHorizontally": "EnsureCaretVisibleHorizontally",
      "XtraRichEditStringId.MenuCmd_EnsureCaretVisibleHorizontallyDescription": "EnsureCaretVisibleHorizontally",
      "XtraRichEditStringId.MenuCmd_EnsureCaretVisibleVertically": "EnsureCaretVisibleVertically",
      "XtraRichEditStringId.MenuCmd_EnsureCaretVisibleVerticallyDescription": "EnsureCaretVisibleVertically",
      "XtraRichEditStringId.MenuCmd_EnterKey": "EnterKey",
      "XtraRichEditStringId.MenuCmd_EnterKeyDescription": "EnterKeyDescription",
      "XtraRichEditStringId.MenuCmd_Find": "Find",
      "XtraRichEditStringId.MenuCmd_FindAndSelectBackward": "FindAndSelectBackward",
      "XtraRichEditStringId.MenuCmd_FindAndSelectBackwardDescription": "FindAndSelectBackward",
      "XtraRichEditStringId.MenuCmd_FindAndSelectForward": "FindAndSelectForward",
      "XtraRichEditStringId.MenuCmd_FindAndSelectForwardDescription": "FindAndSelectForward",
      "XtraRichEditStringId.MenuCmd_FindDescription": "Find text in the document.",
      "XtraRichEditStringId.MenuCmd_FindNext": "Find Next",
      "XtraRichEditStringId.MenuCmd_FindNextDescription": "Repeats the last search forward.",
      "XtraRichEditStringId.MenuCmd_FindPrev": "Find Prev",
      "XtraRichEditStringId.MenuCmd_FindPrevDescription": "Repeats the last search backward.",
      "XtraRichEditStringId.MenuCmd_FirstDataRecord": "First Record",
      "XtraRichEditStringId.MenuCmd_FirstDataRecordDescription": "First Record",
      "XtraRichEditStringId.MenuCmd_FitHeight": "Fit Height",
      "XtraRichEditStringId.MenuCmd_FitHeightDescription": "Adjusts the document zoom factor to fit the entire page height to the editing surface dimensions.",
      "XtraRichEditStringId.MenuCmd_FitToPage": "Fit To Page",
      "XtraRichEditStringId.MenuCmd_FitToPageDescription": "Adjusts the document zoom factor to fit the entire page to the editing surface dimensions.",
      "XtraRichEditStringId.MenuCmd_FitWidth": "Fit Width",
      "XtraRichEditStringId.MenuCmd_FitWidthDescription": "Adjusts the document zoom factor to fit the entire page width to the editing surface dimensions.",
      "XtraRichEditStringId.MenuCmd_FloatingObjectBringForward": "Bring Forward",
      "XtraRichEditStringId.MenuCmd_FloatingObjectBringForwardDescription": "Bring the selected object forward so that it is hidden by fewer object that are in front of it.",
      "XtraRichEditStringId.MenuCmd_FloatingObjectBringForwardPlaceholder": "Bring to Front",
      "XtraRichEditStringId.MenuCmd_FloatingObjectBringForwardPlaceholderDescription": "Bring the selected object forward so that it is hidden by fewer object that are in front of it.",
      "XtraRichEditStringId.MenuCmd_FloatingObjectBringInFrontOfText": "Bring in Front of Text",
      "XtraRichEditStringId.MenuCmd_FloatingObjectBringInFrontOfTextDescription": " ",
      "XtraRichEditStringId.MenuCmd_FloatingObjectBringToFront": "Bring to Front",
      "XtraRichEditStringId.MenuCmd_FloatingObjectBringToFrontDescription": "Bring the selected object in front of all other objects so that no part of it is hidden behind another object.",
      "XtraRichEditStringId.MenuCmd_FloatingObjectSendBackward": "Send Backward",
      "XtraRichEditStringId.MenuCmd_FloatingObjectSendBackwardDescription": "Send the selected object backward so that it is hidden by the object that are in front of it.",
      "XtraRichEditStringId.MenuCmd_FloatingObjectSendBackwardPlaceholder": "Send to Back",
      "XtraRichEditStringId.MenuCmd_FloatingObjectSendBackwardPlaceholderDescription": "Send the selected object backward so that it is hidden by the object that are in front of it.",
      "XtraRichEditStringId.MenuCmd_FloatingObjectSendBehindText": "Send Behind Text",
      "XtraRichEditStringId.MenuCmd_FloatingObjectSendBehindTextDescription": " ",
      "XtraRichEditStringId.MenuCmd_FloatingObjectSendToBack": "Send to Back",
      "XtraRichEditStringId.MenuCmd_FloatingObjectSendToBackDescription": "Send the selected object behind all other objects.",
      "XtraRichEditStringId.MenuCmd_FontSubscript": "Subscript",
      "XtraRichEditStringId.MenuCmd_FontSubscriptDescription": "Create small letters below the text baseline.",
      "XtraRichEditStringId.MenuCmd_FontSuperscript": "Superscript",
      "XtraRichEditStringId.MenuCmd_FontSuperscriptDescription": "Create small letters above the line of text.",
      "XtraRichEditStringId.MenuCmd_GoToNextHeaderFooter": "Show Next",
      "XtraRichEditStringId.MenuCmd_GoToNextHeaderFooterDescription": "Navigate to the next section's header or footer.",
      "XtraRichEditStringId.MenuCmd_GoToPage": "Go To Page",
      "XtraRichEditStringId.MenuCmd_GoToPageDescription": "Go To Page",
      "XtraRichEditStringId.MenuCmd_GoToPageFooter": "Go to Footer",
      "XtraRichEditStringId.MenuCmd_GoToPageFooterDescription": "Activate the footer on this page so that you can edit it.",
      "XtraRichEditStringId.MenuCmd_GoToPageHeader": "Go to Header",
      "XtraRichEditStringId.MenuCmd_GoToPageHeaderDescription": "Activate the header on this page so that you can edit it.",
      "XtraRichEditStringId.MenuCmd_GoToPreviousHeaderFooter": "Show Previous",
      "XtraRichEditStringId.MenuCmd_GoToPreviousHeaderFooterDescription": "Navigate to the previous section's header or footer.",
      "XtraRichEditStringId.MenuCmd_HighlightText": "Text Highlight Color",
      "XtraRichEditStringId.MenuCmd_HighlightTextDescription": "Make text look like it was marked with a highlighter pen.",
      "XtraRichEditStringId.MenuCmd_Hyperlink": "Hyperlink...",
      "XtraRichEditStringId.MenuCmd_HyperlinkDescription": "Hyperlink...",
      "XtraRichEditStringId.MenuCmd_IgnoreAllMistakenWords": "Ignore All",
      "XtraRichEditStringId.MenuCmd_IgnoreAllMistakenWordsDescription": "Ignore All",
      "XtraRichEditStringId.MenuCmd_IgnoreMistakenWord": "Ignore",
      "XtraRichEditStringId.MenuCmd_IgnoreMistakenWordDescription": "Ignore",
      "XtraRichEditStringId.MenuCmd_IncreaseFontSize": "Grow Font",
      "XtraRichEditStringId.MenuCmd_IncreaseFontSizeDescription": "Increase the font size.",
      "XtraRichEditStringId.MenuCmd_IncrementFontSize": "IncrementFontSize",
      "XtraRichEditStringId.MenuCmd_IncrementFontSizeDescription": "IncrementFontSize",
      "XtraRichEditStringId.MenuCmd_IncrementIndent": "Increase Indent",
      "XtraRichEditStringId.MenuCmd_IncrementIndentDescription": "Increase the indent level of the paragraph.",
      "XtraRichEditStringId.MenuCmd_IncrementNumerationFromParagraph": "IncrementNumerationFromParagraph",
      "XtraRichEditStringId.MenuCmd_IncrementNumerationFromParagraphDescription": "IncrementNumerationFromParagraph",
      "XtraRichEditStringId.MenuCmd_IncrementParagraphLeftIndent": "IncrementParagraphLeftIndent",
      "XtraRichEditStringId.MenuCmd_IncrementParagraphLeftIndentDescription": "IncrementParagraphLeftIndentDescription",
      "XtraRichEditStringId.MenuCmd_IncrementParagraphOutlineLevel": "Increment outline level",
      "XtraRichEditStringId.MenuCmd_IncrementParagraphOutlineLevelDescription": "Increment outline level",
      "XtraRichEditStringId.MenuCmd_InsertBreak": "Breaks",
      "XtraRichEditStringId.MenuCmd_InsertBreakDescription": "Add page, section, or column breaks to the document.",
      "XtraRichEditStringId.MenuCmd_InsertBulletList": "Bullets",
      "XtraRichEditStringId.MenuCmd_InsertBulletListDescription": "Start a bulleted list.",
      "XtraRichEditStringId.MenuCmd_InsertCaptionPlaceholder": "Insert Caption",
      "XtraRichEditStringId.MenuCmd_InsertCaptionPlaceholderDescription": "Add a caption to a picture or other image.A caption is a line of text that appears below an object to describe it.",
      "XtraRichEditStringId.MenuCmd_InsertClosingDoubleQuotationMark": "InsertClosingDoubleQuotationMark",
      "XtraRichEditStringId.MenuCmd_InsertClosingDoubleQuotationMarkDescription": "InsertClosingDoubleQuotationMark",
      "XtraRichEditStringId.MenuCmd_InsertClosingSingleQuotationMark": "InsertClosingSingleQuotationMark",
      "XtraRichEditStringId.MenuCmd_InsertClosingSingleQuotationMarkDescription": "InsertClosingSingleQuotationMark",
      "XtraRichEditStringId.MenuCmd_InsertColumnBreak": "Column",
      "XtraRichEditStringId.MenuCmd_InsertColumnBreakDescription": "Indicate that the text following the column break will begin in the next column.",
      "XtraRichEditStringId.MenuCmd_InsertCopyrightSymbol": "InsertCopyrightSymbol",
      "XtraRichEditStringId.MenuCmd_InsertCopyrightSymbolDescription": "InsertCopyrightSymbol",
      "XtraRichEditStringId.MenuCmd_InsertEllipsis": "InsertEllipsis",
      "XtraRichEditStringId.MenuCmd_InsertEllipsisDescription": "InsertEllipsis",
      "XtraRichEditStringId.MenuCmd_InsertEmDash": "InsertEmDash",
      "XtraRichEditStringId.MenuCmd_InsertEmDashDescription": "InsertEmDash",
      "XtraRichEditStringId.MenuCmd_InsertEnDash": "InsertEnDash",
      "XtraRichEditStringId.MenuCmd_InsertEnDashDescription": "InsertEnDash",
      "XtraRichEditStringId.MenuCmd_InsertEquationsCaption": "Equations Caption",
      "XtraRichEditStringId.MenuCmd_InsertEquationsCaptionDescription": "Add a equation caption.",
      "XtraRichEditStringId.MenuCmd_InsertField": "InsertField",
      "XtraRichEditStringId.MenuCmd_InsertFieldDescription": "InsertField",
      "XtraRichEditStringId.MenuCmd_InsertFiguresCaption": "Figures Caption",
      "XtraRichEditStringId.MenuCmd_InsertFiguresCaptionDescription": "Add a figure caption.",
      "XtraRichEditStringId.MenuCmd_InsertFloatingObjectPicture": "Picture",
      "XtraRichEditStringId.MenuCmd_InsertFloatingObjectPictureDescription": "Insert a picture from a file.",
      "XtraRichEditStringId.MenuCmd_InsertHyperlink": "InsertHyperlink",
      "XtraRichEditStringId.MenuCmd_InsertHyperlinkDescription": "InsertHyperlinkDescription",
      "XtraRichEditStringId.MenuCmd_InsertHyphen": "InsertHyphen",
      "XtraRichEditStringId.MenuCmd_InsertLineBreak": "InsertLineBreak",
      "XtraRichEditStringId.MenuCmd_InsertLineBreakDescription": "InsertLineBreak",
      "XtraRichEditStringId.MenuCmd_InsertMergeField": "Insert Merge Field",
      "XtraRichEditStringId.MenuCmd_InsertMergeFieldDescription": "Insert Merge Field.",
      "XtraRichEditStringId.MenuCmd_InsertMultilevelList": "Multilevel list",
      "XtraRichEditStringId.MenuCmd_InsertMultilevelListDescription": "Start a multilevel list.",
      "XtraRichEditStringId.MenuCmd_InsertNonBreakingSpace": "InsertNonBreakingSpace",
      "XtraRichEditStringId.MenuCmd_InsertNonBreakingSpaceDescription": "InsertNonBreakingSpace",
      "XtraRichEditStringId.MenuCmd_InsertOpeningDoubleQuotationMark": "InsertOpeningDoubleQuotationMark",
      "XtraRichEditStringId.MenuCmd_InsertOpeningDoubleQuotationMarkDescription": "InsertOpeningDoubleQuotationMark",
      "XtraRichEditStringId.MenuCmd_InsertOpeningSingleQuotationMark": "InsertOpeningSingleQuotationMark",
      "XtraRichEditStringId.MenuCmd_InsertOpeningSingleQuotationMarkDescription": "InsertOpeningSingleQuotationMark",
      "XtraRichEditStringId.MenuCmd_InsertPageBreak": "Page",
      "XtraRichEditStringId.MenuCmd_InsertPageBreak2": "Page Break",
      "XtraRichEditStringId.MenuCmd_InsertPageBreak2Description": "Start the next page at the current position.",
      "XtraRichEditStringId.MenuCmd_InsertPageBreakDescription": "Start the next page at the current position.",
      "XtraRichEditStringId.MenuCmd_InsertPageCountField": "Page Count",
      "XtraRichEditStringId.MenuCmd_InsertPageCountFieldDescription": "Insert total page count into the document.",
      "XtraRichEditStringId.MenuCmd_InsertPageNumberField": "Page Number",
      "XtraRichEditStringId.MenuCmd_InsertPageNumberFieldDescription": "Insert page numbers into the document.",
      "XtraRichEditStringId.MenuCmd_InsertParagraph": "InsertParagraph",
      "XtraRichEditStringId.MenuCmd_InsertParagraphDescription": "InsertParagraph",
      "XtraRichEditStringId.MenuCmd_InsertPicture": "Inline Picture",
      "XtraRichEditStringId.MenuCmd_InsertPictureDescription": "Insert inline picture from a file.",
      "XtraRichEditStringId.MenuCmd_InsertRegisteredTrademarkSymbol": "InsertRegisteredTrademark Symbol",
      "XtraRichEditStringId.MenuCmd_InsertRegisteredTrademarkSymbolDescription": "InsertRegisteredTrademark Symbol",
      "XtraRichEditStringId.MenuCmd_InsertSectionBreakContinuous": "Section (Continuous)",
      "XtraRichEditStringId.MenuCmd_InsertSectionBreakContinuousDescription": "Insert a section break and start the new section on the same page.",
      "XtraRichEditStringId.MenuCmd_InsertSectionBreakEvenPage": "Section (Even Page)",
      "XtraRichEditStringId.MenuCmd_InsertSectionBreakEvenPageDescription": "Insert a section break and start the new section on the next even-numbered page.",
      "XtraRichEditStringId.MenuCmd_InsertSectionBreakNextPage": "Section (Next Page)",
      "XtraRichEditStringId.MenuCmd_InsertSectionBreakNextPageDescription": "Insert a section break and start the new section on the next page.",
      "XtraRichEditStringId.MenuCmd_InsertSectionBreakOddPage": "Section (Odd Page)",
      "XtraRichEditStringId.MenuCmd_InsertSectionBreakOddPageDescription": "Insert a section break and start the new section on the next odd-numbered page.",
      "XtraRichEditStringId.MenuCmd_InsertSimpleList": "Numbering",
      "XtraRichEditStringId.MenuCmd_InsertSimpleListDescription": "Start a numbered list.",
      "XtraRichEditStringId.MenuCmd_InsertSymbol": "Symbol",
      "XtraRichEditStringId.MenuCmd_InsertSymbolDescription": "Insert symbols that are not on your keyboard, such as copyright symbols, trademark symbols, paragraph marks and Unicode characters.",
      "XtraRichEditStringId.MenuCmd_InsertTab": "InsertTab",
      "XtraRichEditStringId.MenuCmd_InsertTabDescription": "InsertTab",
      "XtraRichEditStringId.MenuCmd_InsertTable": "Table",
      "XtraRichEditStringId.MenuCmd_InsertTableCells": "Insert Cells",
      "XtraRichEditStringId.MenuCmd_InsertTableCellsDescription": "Insert Cells",
      "XtraRichEditStringId.MenuCmd_InsertTableColumnToTheLeft": "Insert Left",
      "XtraRichEditStringId.MenuCmd_InsertTableColumnToTheLeftDescription": "Add a new column directly to the left of the selected column.",
      "XtraRichEditStringId.MenuCmd_InsertTableColumnToTheRight": "Insert Right",
      "XtraRichEditStringId.MenuCmd_InsertTableColumnToTheRightDescription": "Add a new column directly to the right of the selected column.",
      "XtraRichEditStringId.MenuCmd_InsertTableDescription": "Insert a table into the document.",
      "XtraRichEditStringId.MenuCmd_InsertTableElement": "Insert",
      "XtraRichEditStringId.MenuCmd_InsertTableOfContents": "Table of Contents",
      "XtraRichEditStringId.MenuCmd_InsertTableOfContentsDescription": "Add the Table of Contents to the document.Once you have added a Table of Contents, click the Add Text button to add entries the the table.",
      "XtraRichEditStringId.MenuCmd_InsertTableOfEquations": "Table of Equations",
      "XtraRichEditStringId.MenuCmd_InsertTableOfEquationsDescription": "Insert a Table of Equations into the document.A Table of Equations includes a list of all the equations in the document.",
      "XtraRichEditStringId.MenuCmd_InsertTableOfFigures": "Table of Figures",
      "XtraRichEditStringId.MenuCmd_InsertTableOfFiguresDescription": "Insert a Table of Figures into the document.A Table of Figures includes a list of all the figures in the document.",
      "XtraRichEditStringId.MenuCmd_InsertTableOfFiguresPlaceholder": "Insert Table of Figures",
      "XtraRichEditStringId.MenuCmd_InsertTableOfFiguresPlaceholderDescription": "Insert a Table of Figures into the document.A Table of Figures includes a list of all of the figures, tables or equations in the document.",
      "XtraRichEditStringId.MenuCmd_InsertTableOfTables": "Table of Tables",
      "XtraRichEditStringId.MenuCmd_InsertTableOfTablesDescription": "Insert a Table of Tables into the document.A Table of Tables includes a list of all the tables in the document.",
      "XtraRichEditStringId.MenuCmd_InsertTableRowAbove": "Insert Above",
      "XtraRichEditStringId.MenuCmd_InsertTableRowAboveDescription": "Add a new row directly above the selected row.",
      "XtraRichEditStringId.MenuCmd_InsertTableRowBelow": "Insert Below",
      "XtraRichEditStringId.MenuCmd_InsertTableRowBelowDescription": "Add a new row directly below the selected row.",
      "XtraRichEditStringId.MenuCmd_InsertTablesCaption": "Tables Caption",
      "XtraRichEditStringId.MenuCmd_InsertTablesCaptionDescription": "Add a table caption.",
      "XtraRichEditStringId.MenuCmd_InsertTabToParagraph": "InsertTabToParagraph",
      "XtraRichEditStringId.MenuCmd_InsertTabToParagraphDescription": "InsertTabToParagraphDescription",
      "XtraRichEditStringId.MenuCmd_InsertText": "InsertText",
      "XtraRichEditStringId.MenuCmd_InsertTextBox": "Text Box",
      "XtraRichEditStringId.MenuCmd_InsertTextBoxDescription": "Insert a text box into the document.",
      "XtraRichEditStringId.MenuCmd_InsertTextDescription": "InsertText",
      "XtraRichEditStringId.MenuCmd_InsertTrademarkSymbol": "InsertTrademarkSymbol",
      "XtraRichEditStringId.MenuCmd_InsertTrademarkSymbolDescription": "InsertTrademarkSymbol",
      "XtraRichEditStringId.MenuCmd_Language": "Language",
      "XtraRichEditStringId.MenuCmd_LanguageDescription": "Set the language used to check the spelling and grammar of the selected text.",
      "XtraRichEditStringId.MenuCmd_LastDataRecord": "Last Record",
      "XtraRichEditStringId.MenuCmd_LastDataRecordDescription": "Last Record",
      "XtraRichEditStringId.MenuCmd_LoadDocument": "Open",
      "XtraRichEditStringId.MenuCmd_LoadDocumentDescription": "Open a document.",
      "XtraRichEditStringId.MenuCmd_LockField": "Lock Field",
      "XtraRichEditStringId.MenuCmd_LockFieldDescription": "Lock Field",
      "XtraRichEditStringId.MenuCmd_MailMergeSaveDocumentAsCommand": "Mail Merge",
      "XtraRichEditStringId.MenuCmd_MailMergeSaveDocumentAsCommandDescription": "Mail Merge",
      "XtraRichEditStringId.MenuCmd_MakeTextLowerCase": "lowercase",
      "XtraRichEditStringId.MenuCmd_MakeTextLowerCaseDescription": "Change all the selected text to lowercase.",
      "XtraRichEditStringId.MenuCmd_MakeTextUpperCase": "UPPERCASE",
      "XtraRichEditStringId.MenuCmd_MakeTextUpperCaseDescription": "Change all the selected text to UPPERCASE.",
      "XtraRichEditStringId.MenuCmd_MergeTableCells": "Merge Cells",
      "XtraRichEditStringId.MenuCmd_MergeTableCellsDescription": "Merge the selected cells into one cell.",
      "XtraRichEditStringId.MenuCmd_ModifyHyperlink": "ModifyHyperlink",
      "XtraRichEditStringId.MenuCmd_ModifyHyperlinkDescription": "ModifyHyperlinkDescription",
      "XtraRichEditStringId.MenuCmd_ModifyTableStyle": "Modify Table Style...",
      "XtraRichEditStringId.MenuCmd_MoveBackward": "MoveBackward",
      "XtraRichEditStringId.MenuCmd_MoveBackwardDescription": "MoveBackward",
      "XtraRichEditStringId.MenuCmd_MoveForward": "MoveForward",
      "XtraRichEditStringId.MenuCmd_MoveForwardDescription": "MoveForward",
      "XtraRichEditStringId.MenuCmd_MoveLineDown": "MoveLineDown",
      "XtraRichEditStringId.MenuCmd_MoveLineDownDescription": "MoveLineDown",
      "XtraRichEditStringId.MenuCmd_MoveLineUp": "MoveLineUp",
      "XtraRichEditStringId.MenuCmd_MoveLineUpDescription": "MoveLineUp",
      "XtraRichEditStringId.MenuCmd_MoveNextPage": "MoveNextPage",
      "XtraRichEditStringId.MenuCmd_MoveNextPageDescription": "MoveNextPage",
      "XtraRichEditStringId.MenuCmd_MoveNextParagraph": "MoveNextParagraph",
      "XtraRichEditStringId.MenuCmd_MoveNextParagraphDescription": "MoveNextParagraph",
      "XtraRichEditStringId.MenuCmd_MoveNextWord": "MoveNextWord",
      "XtraRichEditStringId.MenuCmd_MoveNextWordDescription": "MoveNextWord",
      "XtraRichEditStringId.MenuCmd_MovePreviousPage": "MovePreviousPage",
      "XtraRichEditStringId.MenuCmd_MovePreviousPageDescription": "MovePreviousPage",
      "XtraRichEditStringId.MenuCmd_MovePreviousParagraph": "MovePreviousParagraph",
      "XtraRichEditStringId.MenuCmd_MovePreviousParagraphDescription": "MovePreviousParagraph",
      "XtraRichEditStringId.MenuCmd_MovePreviousWord": "MovePreviousWord",
      "XtraRichEditStringId.MenuCmd_MovePreviousWordDescription": "MovePreviousWord",
      "XtraRichEditStringId.MenuCmd_MoveScreenDown": "MoveScreenDown",
      "XtraRichEditStringId.MenuCmd_MoveScreenDownDescription": "MoveScreenDown",
      "XtraRichEditStringId.MenuCmd_MoveScreenUp": "MoveScreenUp",
      "XtraRichEditStringId.MenuCmd_MoveScreenUpDescription": "MoveScreenUp",
      "XtraRichEditStringId.MenuCmd_MoveToBeginOfDocument": "MoveToBeginBeginOfDocument",
      "XtraRichEditStringId.MenuCmd_MoveToBeginOfDocumentDescription": "MoveToBeginBeginOfDocument",
      "XtraRichEditStringId.MenuCmd_MoveToEndOfDocument": "MoveToEndOfDocument",
      "XtraRichEditStringId.MenuCmd_MoveToEndOfDocumentDescription": "MoveToEndOfDocument",
      "XtraRichEditStringId.MenuCmd_MoveToEndOfLine": "MoveToEndOfLine",
      "XtraRichEditStringId.MenuCmd_MoveToEndOfLineDescription": "MoveToEndOfLine",
      "XtraRichEditStringId.MenuCmd_MoveToStartOfLine": "MoveToBeginOfLine",
      "XtraRichEditStringId.MenuCmd_MoveToStartOfLineDescription": "MoveToBeginOfLine",
      "XtraRichEditStringId.MenuCmd_NewComment": "New Comment",
      "XtraRichEditStringId.MenuCmd_NewCommentDescription": "Add a comment about the selection.",
      "XtraRichEditStringId.MenuCmd_NewEmptyDocument": "New",
      "XtraRichEditStringId.MenuCmd_NewEmptyDocumentDescription": "Create a new document.",
      "XtraRichEditStringId.MenuCmd_NewTableStyle": "New Table Style...",
      "XtraRichEditStringId.MenuCmd_NextComment": "Next",
      "XtraRichEditStringId.MenuCmd_NextCommentDescription": "Navigate to the next comment in the document.",
      "XtraRichEditStringId.MenuCmd_NextDataRecord": "Next Record",
      "XtraRichEditStringId.MenuCmd_NextDataRecordDescription": "Next Record",
      "XtraRichEditStringId.MenuCmd_None": " ",
      "XtraRichEditStringId.MenuCmd_OpenHyperlink": "Open hyperlink",
      "XtraRichEditStringId.MenuCmd_OpenHyperlinkAtCaretPosition": "OpenHyperlinkAtCaretPosition",
      "XtraRichEditStringId.MenuCmd_OpenHyperlinkAtCaretPositionDescription": "OpenHyperlinkAtCaretPositionDescription",
      "XtraRichEditStringId.MenuCmd_OpenHyperlinkDescription": "Open hyperlink",
      "XtraRichEditStringId.MenuCmd_OvertypeText": "OvertypeText",
      "XtraRichEditStringId.MenuCmd_OvertypeTextDescription": "OvertypeText",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentCenter": "Center",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentCenterDescription": "Center your content on the page.",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentDistribute": "Distributed",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentDistributeDescription": "Align text to both left and right margins.Space will be added between characters and words. If the last line is short, extra spaces will be added between the characters to make it match the width of the paragraph.",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustify": "Justify",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyDescription": "Distribute your text evenly between left and right margins. Justified text gives you document clean edges so it looks more polished.",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyHigh": "Justify High",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyHighDescription": "Align text to both left and right margins.Specifies that a paragraph uses the kashida with the widest length for Arabic text. For other languages, paragraph text is justified with the widest inter-word spacing.",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyLow": "Justify Low",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyLowDescription": "Align text to both left and right margins.If the language is Arabic, the paragraph uses small length Kashida. In other languages, text is justified with short inter-word spacing.",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyMedium": "Justify Medium",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentJustifyMediumDescription": "Align text to both left and right margins.If the language is Arabic, the paragraph uses medium-length Kashida. In other languages, text is justified with wider inter-word spacing.",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentLeft": "Align Text Left",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentLeftDescription": "Align your content with the left margin.",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentRight": "Align Text Right",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentRightDescription": "Align your content with the right margin.",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentThaiDistribute": "Thai Distributed",
      "XtraRichEditStringId.MenuCmd_ParagraphAlignmentThaiDistributeDescription": "Align text to both left and right margins by adding extra space between each two characters for languages with tone mark and vowel mark.",
      "XtraRichEditStringId.MenuCmd_ParagraphLeftToRight": "Left-to-Right Text Direction",
      "XtraRichEditStringId.MenuCmd_ParagraphLeftToRightDescription": "Set the text to read from right to left.",
      "XtraRichEditStringId.MenuCmd_ParagraphRightToLeft": "Right-to-Left Text Direction",
      "XtraRichEditStringId.MenuCmd_ParagraphRightToLeftDescription": "Set the text to read from left to right.",
      "XtraRichEditStringId.MenuCmd_ParagraphSuppressHyphenation": "Suppress Hyphenation for Current Paragraph",
      "XtraRichEditStringId.MenuCmd_ParagraphSuppressHyphenationDescription": "Suppress Hyphenation for Current Paragraph",
      "XtraRichEditStringId.MenuCmd_ParagraphSuppressLineNumbers": "Suppress for Current Paragraph",
      "XtraRichEditStringId.MenuCmd_ParagraphSuppressLineNumbersDescription": "Suppress for Current Paragraph",
      "XtraRichEditStringId.MenuCmd_Paste": "Paste",
      "XtraRichEditStringId.MenuCmd_PasteDescription": "Paste the contents of the Clipboard.",
      "XtraRichEditStringId.MenuCmd_PasteFiles": "Files",
      "XtraRichEditStringId.MenuCmd_PasteFilesDescription": "Inserts the contents of the Clipboard as an embedded file.",
      "XtraRichEditStringId.MenuCmd_PasteHtmlText": "HTML Format",
      "XtraRichEditStringId.MenuCmd_PasteHtmlTextDescription": "Inserts the contents of the Clipboard as HTML format.",
      "XtraRichEditStringId.MenuCmd_PasteImage": "Picture",
      "XtraRichEditStringId.MenuCmd_PasteImageDescription": "Inserts the contents of the Clipboard as picture.",
      "XtraRichEditStringId.MenuCmd_PasteMetafileImage": "Metafile",
      "XtraRichEditStringId.MenuCmd_PasteMetafileImageDescription": "Inserts the contents of the Clipboard as metafile.",
      "XtraRichEditStringId.MenuCmd_PastePlainText": "Unformatted text",
      "XtraRichEditStringId.MenuCmd_PastePlainTextDescription": "Inserts the contents of the Clipboard as text without any formatting.",
      "XtraRichEditStringId.MenuCmd_PasteRtfText": "Formatted text (RTF)",
      "XtraRichEditStringId.MenuCmd_PasteRtfTextDescription": "Inserts the contents of the Clipboard as text with font and table formatting.",
      "XtraRichEditStringId.MenuCmd_PasteSilverlightXamlText": "Formatted text (XAML)",
      "XtraRichEditStringId.MenuCmd_PasteSilverlightXamlTextDescription": "Inserts the contents of the Clipboard as text with font formatting.",
      "XtraRichEditStringId.MenuCmd_PlaceCaretToPhysicalPoint": "PlaceCaretToPhysicalPoint",
      "XtraRichEditStringId.MenuCmd_PlaceCaretToPhysicalPointDescription": "PlaceCaretToPhysicalPoint",
      "XtraRichEditStringId.MenuCmd_PreviousComment": "Previous",
      "XtraRichEditStringId.MenuCmd_PreviousCommentDescription": "Navigate to the previous comment in the document.",
      "XtraRichEditStringId.MenuCmd_PreviousDataRecord": "Previous Record",
      "XtraRichEditStringId.MenuCmd_PreviousDataRecordDescription": "Previous Record",
      "XtraRichEditStringId.MenuCmd_Print": "Print",
      "XtraRichEditStringId.MenuCmd_PrintDescription": "Select a printer, number of copies, and other printing options before printing.",
      "XtraRichEditStringId.MenuCmd_PrintPreview": "Print Preview",
      "XtraRichEditStringId.MenuCmd_PrintPreviewDescription": "Preview pages before printing.",
      "XtraRichEditStringId.MenuCmd_PrintView": "Print View",
      "XtraRichEditStringId.MenuCmd_PrintViewDescription": "Print View",
      "XtraRichEditStringId.MenuCmd_ProtectDocument": "Protect Document",
      "XtraRichEditStringId.MenuCmd_ProtectDocumentDescription": "Help restrict people from editing the document by specifying a password.",
      "XtraRichEditStringId.MenuCmd_QuickPrint": "Quick Print",
      "XtraRichEditStringId.MenuCmd_QuickPrintDescription": "Send the document directly to the default printer without making changes.",
      "XtraRichEditStringId.MenuCmd_ReadingView": "Reading View",
      "XtraRichEditStringId.MenuCmd_ReadingViewDescription": "Reading View",
      "XtraRichEditStringId.MenuCmd_Redo": "Redo",
      "XtraRichEditStringId.MenuCmd_RedoDescription": "Redo the last operation",
      "XtraRichEditStringId.MenuCmd_RemoveHyperlink": "Remove hyperlink",
      "XtraRichEditStringId.MenuCmd_RemoveHyperlinkDescription": "Remove hyperlink",
      "XtraRichEditStringId.MenuCmd_RemoveSpacingAfterParagraph": "Remove Space After Paragraph",
      "XtraRichEditStringId.MenuCmd_RemoveSpacingAfterParagraphDescription": " ",
      "XtraRichEditStringId.MenuCmd_RemoveSpacingBeforeParagraph": "Remove Space Before Paragraph",
      "XtraRichEditStringId.MenuCmd_RemoveSpacingBeforeParagraphDescription": " ",
      "XtraRichEditStringId.MenuCmd_Replace": "Replace",
      "XtraRichEditStringId.MenuCmd_ReplaceAllBackward": "ReplaceAllBackward",
      "XtraRichEditStringId.MenuCmd_ReplaceAllBackwardDescription": "ReplaceAllBackwardDescription",
      "XtraRichEditStringId.MenuCmd_ReplaceAllForward": "ReplaceAllForward",
      "XtraRichEditStringId.MenuCmd_ReplaceAllForwardDescription": "ReplaceAllForwardDescription",
      "XtraRichEditStringId.MenuCmd_ReplaceBackward": "ReplaceBackward",
      "XtraRichEditStringId.MenuCmd_ReplaceBackwardDescription": "ReplaceBackward",
      "XtraRichEditStringId.MenuCmd_ReplaceDescription": "Replace text in the document.",
      "XtraRichEditStringId.MenuCmd_ReplaceForward": "ReplaceForward",
      "XtraRichEditStringId.MenuCmd_ReplaceForwardDescription": "ReplaceForward",
      "XtraRichEditStringId.MenuCmd_ReplaceText": "ReplaceText",
      "XtraRichEditStringId.MenuCmd_ReplaceTextDescription": "ReplaceTextDescription",
      "XtraRichEditStringId.MenuCmd_ResetCharacterFormatting": "Reset Character Formatting",
      "XtraRichEditStringId.MenuCmd_ResetCharacterFormattingDescription": "Makes the selection the default character format of the applied style.",
      "XtraRichEditStringId.MenuCmd_ResetTableCellsBorders": "No Border",
      "XtraRichEditStringId.MenuCmd_ResetTableCellsBordersDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_Reviewers": "Reviewers",
      "XtraRichEditStringId.MenuCmd_ReviewersDescription": "Select which reviewer comments to highlight in the document.",
      "XtraRichEditStringId.MenuCmd_ReviewingPane": "Reviewing Pane",
      "XtraRichEditStringId.MenuCmd_ReviewingPaneDescription": "Show or hide the document comments in a separate window.",
      "XtraRichEditStringId.MenuCmd_SaveDocument": "Save",
      "XtraRichEditStringId.MenuCmd_SaveDocumentAs": "Save As",
      "XtraRichEditStringId.MenuCmd_SaveDocumentAsDescription": "Open the Save As dialog box to select a file format and save the document to a new location.",
      "XtraRichEditStringId.MenuCmd_SaveDocumentDescription": "Save the document.",
      "XtraRichEditStringId.MenuCmd_ScrollDown": "Scroll Down",
      "XtraRichEditStringId.MenuCmd_ScrollDownDescription": "Scroll Down",
      "XtraRichEditStringId.MenuCmd_ScrollToPage": "ScrollToPage",
      "XtraRichEditStringId.MenuCmd_ScrollToPageDescription": "ScrollToPage",
      "XtraRichEditStringId.MenuCmd_SelectAll": "Select All",
      "XtraRichEditStringId.MenuCmd_SelectAllDescription": "Select entire document content.",
      "XtraRichEditStringId.MenuCmd_SelectBookmark": "Select Bookmark",
      "XtraRichEditStringId.MenuCmd_SelectBookmarkDescription": "Select Bookmark",
      "XtraRichEditStringId.MenuCmd_SelectComment": "Select Comment",
      "XtraRichEditStringId.MenuCmd_SelectCommentDescription": "Select Comment",
      "XtraRichEditStringId.MenuCmd_SelectFieldNextToCaret": "SelectFieldNextToCaret",
      "XtraRichEditStringId.MenuCmd_SelectFieldNextToCaretDescription": "SelectFieldNextToCaretDescription",
      "XtraRichEditStringId.MenuCmd_SelectFieldPrevToCaret": "SelectFieldPrevToCaret",
      "XtraRichEditStringId.MenuCmd_SelectFieldPrevToCaretDescription": "SelectFieldPrevToCaretDescription",
      "XtraRichEditStringId.MenuCmd_SelectTable": "Select Table",
      "XtraRichEditStringId.MenuCmd_SelectTableCell": "Select Cell",
      "XtraRichEditStringId.MenuCmd_SelectTableCellDescription": "Select Cell",
      "XtraRichEditStringId.MenuCmd_SelectTableColumns": "Select Column",
      "XtraRichEditStringId.MenuCmd_SelectTableColumnsDescription": "Select Column",
      "XtraRichEditStringId.MenuCmd_SelectTableDescription": "Select Table",
      "XtraRichEditStringId.MenuCmd_SelectTableElements": "Select",
      "XtraRichEditStringId.MenuCmd_SelectTableElementsDescription": "Select the current cell, row, column, or entire table.",
      "XtraRichEditStringId.MenuCmd_SelectTableRow": "Select Row",
      "XtraRichEditStringId.MenuCmd_SelectTableRowDescription": "Select Row",
      "XtraRichEditStringId.MenuCmd_SetDoubleParagraphSpacing": "2.0",
      "XtraRichEditStringId.MenuCmd_SetDoubleParagraphSpacingDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectBehindTextWrapType": "Behind Text",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectBehindTextWrapTypeDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomCenterAlignment": "Bottom Center",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomCenterAlignmentDescription": "Position in Bottom Center with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomLeftAlignment": "Bottom Left",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomLeftAlignmentDescription": "Position in Bottom Left with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomRightAlignment": "Bottom Right",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectBottomRightAlignmentDescription": "Position in Bottom Right with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectInFrontOfTextWrapType": "In Front of Text",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectInFrontOfTextWrapTypeDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectInlineWithTextWrapType": "In Line with Text",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectInlineWithTextWrapTypeDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleCenterAlignment": "Middle Center",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleCenterAlignmentDescription": "Position in Middle Center with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleLeftAlignment": "Middle Left",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleLeftAlignmentDescription": "Position in Middle Left with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleRightAlignment": "Middle Right",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectMiddleRightAlignmentDescription": "Position in Middle Right with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectSquareTextWrapType": "Square",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectSquareTextWrapTypeDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectThroughTextWrapType": "Through",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectThroughTextWrapTypeDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTightTextWrapType": "Tight",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTightTextWrapTypeDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopAndBottomTextWrapType": "Top and Bottom",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopAndBottomTextWrapTypeDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopCenterAlignment": "Top Center",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopCenterAlignmentDescription": "Position in Top Center with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopLeftAlignment": "Top Left",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopLeftAlignmentDescription": "Position in Top Left with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopRightAlignment": "Top Right",
      "XtraRichEditStringId.MenuCmd_SetFloatingObjectTopRightAlignmentDescription": "Position in Top Right with Square Text Wrapping",
      "XtraRichEditStringId.MenuCmd_SetHyphenateDocumentAutomatic": "Automatic",
      "XtraRichEditStringId.MenuCmd_SetHyphenateDocumentAutomaticDescription": "Turns on Automatic Hyphenation",
      "XtraRichEditStringId.MenuCmd_SetHyphenateDocumentNone": "None",
      "XtraRichEditStringId.MenuCmd_SetHyphenateDocumentNoneDescription": "Turns off Automatic Hyphenation",
      "XtraRichEditStringId.MenuCmd_SetLandscapePageOrientation": "Landscape",
      "XtraRichEditStringId.MenuCmd_SetLandscapePageOrientationDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetModerateSectionPageMargins": "ModerateTop:	{1,10}	Bottom:	{3,10}Left:	{0,10}	Right:		{2,10}",
      "XtraRichEditStringId.MenuCmd_SetModerateSectionPageMarginsDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetNarrowSectionPageMargins": "NarrowTop:	{1,10}	Bottom:	{3,10}Left:	{0,10}	Right:		{2,10}",
      "XtraRichEditStringId.MenuCmd_SetNarrowSectionPageMarginsDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetNormalSectionPageMargins": "NormalTop:	{1,10}	Bottom:	{3,10}Left:	{0,10}	Right:		{2,10}",
      "XtraRichEditStringId.MenuCmd_SetNormalSectionPageMarginsDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetParagraphBodyTextLevel": "Do Not Show in Table of Contents",
      "XtraRichEditStringId.MenuCmd_SetParagraphBodyTextLevelDescription": "Do Not Show in Table of Contents",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading1Level": "Level 1",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading1LevelDescription": "Level 1",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading2Level": "Level 2",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading2LevelDescription": "Level 2",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading3Level": "Level 3",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading3LevelDescription": "Level 3",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading4Level": "Level 4",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading4LevelDescription": "Level 4",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading5Level": "Level 5",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading5LevelDescription": "Level 5",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading6Level": "Level 6",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading6LevelDescription": "Level 6",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading7Level": "Level 7",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading7LevelDescription": "Level 7",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading8Level": "Level 8",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading8LevelDescription": "Level 8",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading9Level": "Level 9",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeading9LevelDescription": "Level 9",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeadingLevel": "Level {0}",
      "XtraRichEditStringId.MenuCmd_SetParagraphHeadingLevelDescription": "Level {0}",
      "XtraRichEditStringId.MenuCmd_SetPortraitPageOrientation": "Portrait",
      "XtraRichEditStringId.MenuCmd_SetPortraitPageOrientationDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetSectionColumns": "Columns",
      "XtraRichEditStringId.MenuCmd_SetSectionColumnsDescription": "Split text into two or more columns.",
      "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingContinuous": "Continuous",
      "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingContinuousDescription": "Continuous",
      "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingNone": "None",
      "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingNoneDescription": "No line numbers.",
      "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingRestartNewPage": "Restart Each Page",
      "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingRestartNewPageDescription": "Restart Each Page",
      "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingRestartNewSection": "Restart Each Section",
      "XtraRichEditStringId.MenuCmd_SetSectionLineNumberingRestartNewSectionDescription": "Restart Each Section",
      "XtraRichEditStringId.MenuCmd_SetSectionOneColumn": "One",
      "XtraRichEditStringId.MenuCmd_SetSectionOneColumnDescription": "One column.",
      "XtraRichEditStringId.MenuCmd_SetSectionThreeColumns": "Three",
      "XtraRichEditStringId.MenuCmd_SetSectionThreeColumnsDescription": "Three columns.",
      "XtraRichEditStringId.MenuCmd_SetSectionTwoColumns": "Two",
      "XtraRichEditStringId.MenuCmd_SetSectionTwoColumnsDescription": "Two columns.",
      "XtraRichEditStringId.MenuCmd_SetSesquialteralParagraphSpacing": "1.5",
      "XtraRichEditStringId.MenuCmd_SetSesquialteralParagraphSpacingDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetSingleParagraphSpacing": "1.0",
      "XtraRichEditStringId.MenuCmd_SetSingleParagraphSpacingDescription": " ",
      "XtraRichEditStringId.MenuCmd_SetWideSectionPageMargins": "WideTop:	{1,10}	Bottom:	{3,10}Left:	{0,10}	Right:		{2,10}",
      "XtraRichEditStringId.MenuCmd_SetWideSectionPageMarginsDescription": " ",
      "XtraRichEditStringId.MenuCmd_ShiftTabKey": "ShiftTabKey",
      "XtraRichEditStringId.MenuCmd_ShiftTabKeyDescription": "ShiftTabKey",
      "XtraRichEditStringId.MenuCmd_ShowAllFieldCodes": "Show All Field Codes",
      "XtraRichEditStringId.MenuCmd_ShowAllFieldCodesDescription": "View the document markup with dynamic elements displaying their rich-text codes.",
      "XtraRichEditStringId.MenuCmd_ShowAllFieldResults": "Show All Field Results",
      "XtraRichEditStringId.MenuCmd_ShowAllFieldResultsDescription": "View the document content with dynamic elements displaying real data.",
      "XtraRichEditStringId.MenuCmd_ShowBookmarkForm": "Bookmark",
      "XtraRichEditStringId.MenuCmd_ShowBookmarkFormDescription": "Create a bookmark to assign a name to a specific point in a documentYou can make hyperlinks that jump directly to a bookmarked location.",
      "XtraRichEditStringId.MenuCmd_ShowBordersShadingForm": "Borders and Shading",
      "XtraRichEditStringId.MenuCmd_ShowBordersShadingFormDescription": "Explore additional border options, such as line color and line width",
      "XtraRichEditStringId.MenuCmd_ShowColumnsSetupForm": "More Columns...",
      "XtraRichEditStringId.MenuCmd_ShowColumnsSetupFormDescription": "Show the Column dialog box to customize column widths.",
      "XtraRichEditStringId.MenuCmd_ShowCommentForm": "Comment",
      "XtraRichEditStringId.MenuCmd_ShowCommentFormDescription": "Show the Comment dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowDocumentProperties": "Document Properties",
      "XtraRichEditStringId.MenuCmd_ShowDocumentPropertiesDescription": "Show the Properties dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowEditStyleForm": "Modify Style...",
      "XtraRichEditStringId.MenuCmd_ShowEditStyleFormDescription": "Show the Edit Style dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowFloatingObjectLayoutOptionsForm": "More Layout Options...",
      "XtraRichEditStringId.MenuCmd_ShowFloatingObjectLayoutOptionsFormDescription": "Show the Layout dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowFontForm": "Font...",
      "XtraRichEditStringId.MenuCmd_ShowFontFormDescription": "Show the Font dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowHyperlinkForm": "Hyperlink",
      "XtraRichEditStringId.MenuCmd_ShowHyperlinkFormDescription": "Create a link to a Web page, a picture, an e-mail address, or a program.",
      "XtraRichEditStringId.MenuCmd_ShowHyphenationForm": "Hyphenation Options...",
      "XtraRichEditStringId.MenuCmd_ShowHyphenationFormDescription": "Change the hyphenation behavior of the selected text.",
      "XtraRichEditStringId.MenuCmd_ShowInsertMergeFieldForm": "Insert Merge Field",
      "XtraRichEditStringId.MenuCmd_ShowInsertMergeFieldFormDescription": "Add a field from a list of recipients or from a data table to the document.",
      "XtraRichEditStringId.MenuCmd_ShowLineNumberingForm": "Line Numbering Options...",
      "XtraRichEditStringId.MenuCmd_ShowLineNumberingFormDescription": "Line Numbering Options...",
      "XtraRichEditStringId.MenuCmd_ShowLineSpacingForm": "Line Spacing Options...",
      "XtraRichEditStringId.MenuCmd_ShowLineSpacingFormDescription": " ",
      "XtraRichEditStringId.MenuCmd_ShowNumberingList": "Bullets and Numbering...",
      "XtraRichEditStringId.MenuCmd_ShowNumberingListDescription": "Show the Numbered List dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowPageMarginsSetupForm": "Custom Margins...",
      "XtraRichEditStringId.MenuCmd_ShowPageMarginsSetupFormDescription": " ",
      "XtraRichEditStringId.MenuCmd_ShowPagePaperSetupForm": "More Paper Sizes...",
      "XtraRichEditStringId.MenuCmd_ShowPagePaperSetupFormDescription": " ",
      "XtraRichEditStringId.MenuCmd_ShowPageSetupForm": "Page Setup",
      "XtraRichEditStringId.MenuCmd_ShowPageSetupFormDescription": "Show the Page Setup dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowParagraphForm": "Paragraph...",
      "XtraRichEditStringId.MenuCmd_ShowParagraphFormDescription": "Show the Paragraph dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowPasteSpecialForm": "Paste Special",
      "XtraRichEditStringId.MenuCmd_ShowPasteSpecialFormDescription": "Paste Special",
      "XtraRichEditStringId.MenuCmd_ShowRangeEditingPermissionsForm": "Range Editing Permissions",
      "XtraRichEditStringId.MenuCmd_ShowRangeEditingPermissionsFormDescription": "Grant user permissions to edit the selected part of the document.",
      "XtraRichEditStringId.MenuCmd_ShowSymbol": "Symbol",
      "XtraRichEditStringId.MenuCmd_ShowSymbolDescription": "Show the Symbol dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowTableOptionsForm": "Cell Margins",
      "XtraRichEditStringId.MenuCmd_ShowTableOptionsFormDescription": "Customize cell margins and the spacing between cells.",
      "XtraRichEditStringId.MenuCmd_ShowTablePropertiesForm": "Properties",
      "XtraRichEditStringId.MenuCmd_ShowTablePropertiesFormDescription": "Show the Table Properties dialog box to change advanced table properties, such as indentation and text wrapping options.",
      "XtraRichEditStringId.MenuCmd_ShowTablePropertiesFormDescriptionMenuItem": "Show the Table Properties dialog box.",
      "XtraRichEditStringId.MenuCmd_ShowTablePropertiesFormMenuItem": "Table Properties...",
      "XtraRichEditStringId.MenuCmd_ShowTabsForm": "Tabs...",
      "XtraRichEditStringId.MenuCmd_ShowTabsFormDescription": "Tabs",
      "XtraRichEditStringId.MenuCmd_SimpleView": "Simple View",
      "XtraRichEditStringId.MenuCmd_SimpleViewDescription": "Simple View",
      "XtraRichEditStringId.MenuCmd_SplitTable": "Split Table",
      "XtraRichEditStringId.MenuCmd_SplitTableCells": "Split Cells",
      "XtraRichEditStringId.MenuCmd_SplitTableCellsDescription": "Split the selected cells into multiple new cells.",
      "XtraRichEditStringId.MenuCmd_SplitTableCellsMenuItem": "Split Cells...",
      "XtraRichEditStringId.MenuCmd_SplitTableDescription": "Split the table into two tables.The selected row will become the first row of the new table.",
      "XtraRichEditStringId.MenuCmd_SwitchToDraftView": "Draft View",
      "XtraRichEditStringId.MenuCmd_SwitchToDraftViewDescription": "View the document as a draft to quickly edit the text.Certain elements of the document such as headers and footers will not be visible in this view.",
      "XtraRichEditStringId.MenuCmd_SwitchToPrintLayoutView": "Print Layout",
      "XtraRichEditStringId.MenuCmd_SwitchToPrintLayoutViewDescription": "View the document as it will appear on the printed page.",
      "XtraRichEditStringId.MenuCmd_SwitchToSimpleView": "Simple View",
      "XtraRichEditStringId.MenuCmd_SwitchToSimpleViewDescription": "View the document as a simple memo.This view ignores the page layout to draw attention to text editing.",
      "XtraRichEditStringId.MenuCmd_SymbolFormInsertButton": "Insert",
      "XtraRichEditStringId.MenuCmd_TabKey": "TabKey",
      "XtraRichEditStringId.MenuCmd_TabKeyDescription": "TabKey",
      "XtraRichEditStringId.MenuCmd_ToggleAllFields": "Toggle All Fields",
      "XtraRichEditStringId.MenuCmd_ToggleAllFieldsDescription": "Toggle between displaying field codes and their results in the document",
      "XtraRichEditStringId.MenuCmd_ToggleAuthorVisibility": "Toggle Authors Visibility",
      "XtraRichEditStringId.MenuCmd_ToggleAuthorVisibilityDescription": "Toggle Authors Visibility",
      "XtraRichEditStringId.MenuCmd_ToggleBandedColumn": "Banded Columns",
      "XtraRichEditStringId.MenuCmd_ToggleBandedColumnDescription": "Display banded columns, in which even columns are formatted differently from odd columns.This banding can make tables easier to read.",
      "XtraRichEditStringId.MenuCmd_ToggleBandedRows": "Banded Rows",
      "XtraRichEditStringId.MenuCmd_ToggleBandedRowsDescription": "Display banded rows, in which even rows are formatted differently from odd rows.This banding can make tables easier to read.",
      "XtraRichEditStringId.MenuCmd_ToggleDifferentFirstPage": "Different First Page",
      "XtraRichEditStringId.MenuCmd_ToggleDifferentFirstPageDescription": "Specify a unique header and footer for the first page of the document.",
      "XtraRichEditStringId.MenuCmd_ToggleDifferentOddAndEvenPages": "Different Odd & Even Pages",
      "XtraRichEditStringId.MenuCmd_ToggleDifferentOddAndEvenPagesDescription": "Specify that odd-numbered pages should have a different header and footer from even-numbered pages.",
      "XtraRichEditStringId.MenuCmd_ToggleFieldCodes": "Toggle Field Codes",
      "XtraRichEditStringId.MenuCmd_ToggleFieldCodesDescription": "Toggle Field Codes",
      "XtraRichEditStringId.MenuCmd_ToggleFieldLocked": "Toggle Field Locked",
      "XtraRichEditStringId.MenuCmd_ToggleFieldLockedDescription": "Toggle Field Locked",
      "XtraRichEditStringId.MenuCmd_ToggleFirstColumn": "First Column",
      "XtraRichEditStringId.MenuCmd_ToggleFirstColumnDescription": "Display special formatting for the first column of the table.",
      "XtraRichEditStringId.MenuCmd_ToggleFirstRow": "Header Row",
      "XtraRichEditStringId.MenuCmd_ToggleFirstRowDescription": "Display special formatting for the first row of the table.",
      "XtraRichEditStringId.MenuCmd_ToggleFontBold": "Bold",
      "XtraRichEditStringId.MenuCmd_ToggleFontBoldDescription": "Make the selected text bold.",
      "XtraRichEditStringId.MenuCmd_ToggleFontDoubleStrikeout": "Double Strikethrough",
      "XtraRichEditStringId.MenuCmd_ToggleFontDoubleStrikeoutDescription": "Double strikethrough",
      "XtraRichEditStringId.MenuCmd_ToggleFontDoubleUnderline": "Double Underline",
      "XtraRichEditStringId.MenuCmd_ToggleFontDoubleUnderlineDescription": "Double underline",
      "XtraRichEditStringId.MenuCmd_ToggleFontItalic": "Italic",
      "XtraRichEditStringId.MenuCmd_ToggleFontItalicDescription": "Italicize the selected text.",
      "XtraRichEditStringId.MenuCmd_ToggleFontStrikeout": "Strikethrough",
      "XtraRichEditStringId.MenuCmd_ToggleFontStrikeoutDescription": "Draw a line through the middle of the selected text.",
      "XtraRichEditStringId.MenuCmd_ToggleFontUnderline": "Underline",
      "XtraRichEditStringId.MenuCmd_ToggleFontUnderlineDescription": "Underline the selected text.",
      "XtraRichEditStringId.MenuCmd_ToggleHeaderFooterLinkToPrevious": "Link to Previous",
      "XtraRichEditStringId.MenuCmd_ToggleHeaderFooterLinkToPreviousDescription": "Link to the previous section so that the header and footer in the current section contain the same content as in the previous section.",
      "XtraRichEditStringId.MenuCmd_ToggleHiddenText": "Hidden",
      "XtraRichEditStringId.MenuCmd_ToggleHiddenTextDescription": "Hidden.",
      "XtraRichEditStringId.MenuCmd_ToggleLastColumn": "Last Column",
      "XtraRichEditStringId.MenuCmd_ToggleLastColumnDescription": "Display special formatting for the last column of the table.",
      "XtraRichEditStringId.MenuCmd_ToggleLastRow": "Total Row",
      "XtraRichEditStringId.MenuCmd_ToggleLastRowDescription": "Display special formatting for the last row of the table.",
      "XtraRichEditStringId.MenuCmd_ToggleOvertype": "Overtype",
      "XtraRichEditStringId.MenuCmd_ToggleOvertypeDescription": "Overtype",
      "XtraRichEditStringId.MenuCmd_ToggleShowHorizontalRuler": "Horizontal Ruler",
      "XtraRichEditStringId.MenuCmd_ToggleShowHorizontalRulerDescription": "View the horizontal ruler, used to measure and line up objects in the document.",
      "XtraRichEditStringId.MenuCmd_ToggleShowTableGridLines": "View Gridlines",
      "XtraRichEditStringId.MenuCmd_ToggleShowTableGridLinesDescription": "Show or hide the gridlines within the table.",
      "XtraRichEditStringId.MenuCmd_ToggleShowVerticalRuler": "Vertical Ruler",
      "XtraRichEditStringId.MenuCmd_ToggleShowVerticalRulerDescription": "View the vertical ruler, used to measure and line up objects in the document.",
      "XtraRichEditStringId.MenuCmd_ToggleSpellCheckAsYouType": "Check Spelling As You Type",
      "XtraRichEditStringId.MenuCmd_ToggleSpellCheckAsYouTypeDescription": "Check Spelling As You Type",
      "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitContents": "AutoFit Contents",
      "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitContentsDescription": "Auto-Fit Table to the contents.",
      "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitPlaceholder": "AutoFit",
      "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitPlaceholderDescription": "Automatically resize the column widths based on the text in them.You can set the table width based on the window size or convert it back to use fixed column widths.",
      "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitWindow": "AutoFit Window",
      "XtraRichEditStringId.MenuCmd_ToggleTableAutoFitWindowDescription": "Auto-Fit Table to the window.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsAllBorders": "All Borders",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsAllBordersDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomBorder": "Bottom Border",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomBorderDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomCenterAlignment": "Align Bottom Center",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomCenterAlignmentDescription": "Center text and align it to the bottom of the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomLeftAlignment": "Align Bottom Left",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomLeftAlignmentDescription": "Align text to the bottom left corner of the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomRightAlignment": "Align Bottom Right",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsBottomRightAlignmentDescription": "Align text to the bottom right corner of the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideBorder": "Inside Borders",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideBorderDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideHorizontalBorder": "Inside Horizontal Border",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideHorizontalBorderDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideVerticalBorder": "Inside Vertical Border",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsInsideVerticalBorderDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsLeftBorder": "Left Border",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsLeftBorderDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleCenterAlignment": "Align Center",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleCenterAlignmentDescription": "Center text horizontally and vertically within the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleLeftAlignment": "Align Center Left",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleLeftAlignmentDescription": "Center text vertically and align it to the left side of the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleRightAlignment": "Align Center Right",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsMiddleRightAlignmentDescription": "Center text vertically and align it to the right side of the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsOutsideBorder": "Outside Borders",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsOutsideBorderDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsRightBorder": "Right Border",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsRightBorderDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopBorder": "Top Border",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopBorderDescription": "Customize the borders of the selected cells.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopCenterAlignment": "Align Top Center",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopCenterAlignmentDescription": "Center text and align it to the top of the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopLeftAlignment": "Align Top Left",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopLeftAlignmentDescription": "Align text to the top left corner of the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopRightAlignment": "Align Top Right",
      "XtraRichEditStringId.MenuCmd_ToggleTableCellsTopRightAlignmentDescription": "Align text to the top right corner of the cell.",
      "XtraRichEditStringId.MenuCmd_ToggleTableFixedColumnWidth": "Fixed Column Width",
      "XtraRichEditStringId.MenuCmd_ToggleTableFixedColumnWidthDescription": "Set table size to a fixed width.",
      "XtraRichEditStringId.MenuCmd_ToggleTextCase": "tOGGLE cASE",
      "XtraRichEditStringId.MenuCmd_ToggleTextCaseDescription": "tOGGLE cASE.",
      "XtraRichEditStringId.MenuCmd_ToggleViewMergedData": "View Merged Data",
      "XtraRichEditStringId.MenuCmd_ToggleViewMergedDataDescription": "Replaces the merge fields in your document with actual data from your recipient list so you can see what it looks like.",
      "XtraRichEditStringId.MenuCmd_ToggleWhitespace": "Show/Hide ¶",
      "XtraRichEditStringId.MenuCmd_ToggleWhitespaceDescription": "Show paragraph marks and other hidden formatting symbols.",
      "XtraRichEditStringId.MenuCmd_Undo": "Undo",
      "XtraRichEditStringId.MenuCmd_UndoDescription": "Undo the last operation.",
      "XtraRichEditStringId.MenuCmd_UnlockField": "Unlock Field",
      "XtraRichEditStringId.MenuCmd_UnlockFieldDescription": "Unlock Field",
      "XtraRichEditStringId.MenuCmd_UnprotectDocument": "Unprotect Document",
      "XtraRichEditStringId.MenuCmd_UnprotectDocumentDescription": "Enable users to edit the document.",
      "XtraRichEditStringId.MenuCmd_UpdateField": "Update Field",
      "XtraRichEditStringId.MenuCmd_UpdateFieldDescription": "Update Field",
      "XtraRichEditStringId.MenuCmd_UpdateFields": "Update Fields",
      "XtraRichEditStringId.MenuCmd_UpdateFieldsDescription": "Update Fields",
      "XtraRichEditStringId.MenuCmd_UpdateTableOfContents": "Update Table",
      "XtraRichEditStringId.MenuCmd_UpdateTableOfContentsDescription": "Update the Table of Contents so that all the entries refer to the correct page number.",
      "XtraRichEditStringId.MenuCmd_UpdateTableOfFigures": "Update Table",
      "XtraRichEditStringId.MenuCmd_UpdateTableOfFiguresDescription": "Update the Table of Figures to include all of the entries in the document.",
      "XtraRichEditStringId.MenuCmd_Zoom": "Zoom",
      "XtraRichEditStringId.MenuCmd_ZoomDescription": "Zoom",
      "XtraRichEditStringId.MenuCmd_ZoomIn": "Zoom In",
      "XtraRichEditStringId.MenuCmd_ZoomInDescription": "Zoom in to get a close-up view of the document.",
      "XtraRichEditStringId.MenuCmd_ZoomOut": "Zoom Out",
      "XtraRichEditStringId.MenuCmd_ZoomOutDescription": "Zoom out to see more of the page at a reduced size.",
      "XtraRichEditStringId.Msg_BookmarkCreationFailing": "Bookmark with the same name already exists. Replace?",
      "XtraRichEditStringId.Msg_CantDeleteDefaultStyle": "Cannot delete default style.",
      "XtraRichEditStringId.Msg_CantPasteFormField": "You cannot paste form fields into comments, headers, footers, footnotes, endnotes, or text boxes.",
      "XtraRichEditStringId.Msg_CantResetDefaultProperties": "Can not reset default style settings.",
      "XtraRichEditStringId.Msg_ClickToComment": " commented:",
      "XtraRichEditStringId.Msg_ClickToFollowHyperlink": "Click to follow link",
      "XtraRichEditStringId.Msg_ContinueSearchFromBeginningQuestion": "Do you want to start the search from the beginning of the document?",
      "XtraRichEditStringId.Msg_ContinueSearchFromEndQuestion": "Do you want to start the search from the end of the document?",
      "XtraRichEditStringId.Msg_ContinueSearchInRemainderQuestion": "Do you want to search the remainder of the document?",
      "XtraRichEditStringId.Msg_CreateHyperlinkError": "Cannot create a hyperlink. The hyperlink in the specified range already exists.",
      "XtraRichEditStringId.Msg_DeleteTableStyleQuestion": "Do you want to delete style {0} from the document?",
      "XtraRichEditStringId.Msg_DocumentPositionDoesntMatchDocument": "Error: specified document position or range belongs to other document or subdocument",
      "XtraRichEditStringId.Msg_DocumentProtectionInvalidPassword": "The password is incorrect!",
      "XtraRichEditStringId.Msg_DocumentProtectionInvalidPasswordConfirmation": "The password confirmation doesn't match.",
      "XtraRichEditStringId.Msg_DuplicateBookmark": "Bookmark with that name already exists in the document",
      "XtraRichEditStringId.Msg_EncryptedFile": "Encrypted files are not currently supported",
      "XtraRichEditStringId.Msg_EncryptedFileIntegrityCheckFailed": "This file may have been tampered with or corrupted and the contents should not be trusted. Do you wish to continue opening this file?",
      "XtraRichEditStringId.Msg_ErrorInvalidCustomPropertyValue": "The value entered does not match the specified type. The value will be stored as text.",
      "XtraRichEditStringId.Msg_ErrorLinkDeletedStyle": "Error: cannot link deleted style",
      "XtraRichEditStringId.Msg_IncorrectBookmarkName": "Bookmark name should start with a letter and contain only alphanumeric characters and underscore.",
      "XtraRichEditStringId.Msg_IncorrectNumericFieldFormat": "Error: number cannot be represented in the specified format.",
      "XtraRichEditStringId.Msg_IncorrectPassword": "The password you supplied is not correct. Verify that the CAPS LOCK key is off and be sure to use the correct capitalization.",
      "XtraRichEditStringId.Msg_IncorrectPattern": "Incorrect pattern.",
      "XtraRichEditStringId.Msg_IntegrityCheckFailedError": "Encrypted file integrity check failed!This file may have been tampered with or corrupted.",
      "XtraRichEditStringId.Msg_InternalError": "An internal error occurred",
      "XtraRichEditStringId.Msg_InvalidBeginInit": "Error: call to BeginInit inside BeginUpdate",
      "XtraRichEditStringId.Msg_InvalidBeginUpdate": "Error: call to BeginUpdate inside BeginInit",
      "XtraRichEditStringId.Msg_InvalidCopyFromDocumentModel": "Error: source and destination document models are different",
      "XtraRichEditStringId.Msg_InvalidDivisor": "The number must be a divisor of {0}.",
      "XtraRichEditStringId.Msg_InvalidDocumentModel": "Error: document models are different",
      "XtraRichEditStringId.Msg_InvalidEndInit": "Error: call to EndInit or CancelInit without BeginInit or inside BeginUpdate",
      "XtraRichEditStringId.Msg_InvalidEndUpdate": "Error: call to EndUpdate or CancelUpate without BeginUpdate or inside BeginInit",
      "XtraRichEditStringId.Msg_InvalidFontSize": "The number must be between {0} and {1}.",
      "XtraRichEditStringId.Msg_InvalidHeaderFooterOffset": "The measurement must be between {0} and {1}.",
      "XtraRichEditStringId.Msg_InvalidHtmlImagePath": "The specified image path does not exist.",
      "XtraRichEditStringId.Msg_InvalidImageFile": "The specified image is not valid.",
      "XtraRichEditStringId.Msg_InvalidNavigateUri": "The address of this site is not valid. Check the address and try again.",
      "XtraRichEditStringId.Msg_InvalidNumber": "This is not a valid number.",
      "XtraRichEditStringId.Msg_InvalidNumberingListIndex": "NumberingListIndex refers to a list that does not exist.",
      "XtraRichEditStringId.Msg_InvalidNumberingListStartAtValue": "'Start At' must be between {0} and {1} for this format",
      "XtraRichEditStringId.Msg_InvalidParagraphContainNumbering": "Error: paragraph already contains numbering",
      "XtraRichEditStringId.Msg_InvalidParentStyle": "Error: invalid parent style assignment caused circular reference",
      "XtraRichEditStringId.Msg_InvalidSetCharacterProperties": "Error: cannot set properties without BeginInit or without adding object to document",
      "XtraRichEditStringId.Msg_InvalidSetParagraphProperties": "Error: cannot set properties without BeginInit or without adding object to document",
      "XtraRichEditStringId.Msg_InvalidStyleName": "Invalid style name",
      "XtraRichEditStringId.Msg_InvalidTabStop": "This is not a valid tab stop.",
      "XtraRichEditStringId.Msg_InvalidValueRange": "The value must be between {0} and {1}.",
      "XtraRichEditStringId.Msg_IsNotValid": "'{0}' is not a valid value for '{1}'",
      "XtraRichEditStringId.Msg_LeftRightSectionMarginsTooLarge": "The left/right margins are too large for the page height in some sections.",
      "XtraRichEditStringId.Msg_Loading": "Loading...",
      "XtraRichEditStringId.Msg_MagicNumberNotFound": "The file you are trying to open is in different format than specified by the file extension.",
      "XtraRichEditStringId.Msg_MenuManagerPropertyIsNotSet": "The MenuManager property of RichEditControl is not set.",
      "XtraRichEditStringId.Msg_MissingOperator": "!Missing operator.",
      "XtraRichEditStringId.Msg_NoDefaultTabs": "Default tab stops cannot be set.",
      "XtraRichEditStringId.Msg_NoTocEntriesFound": "No table of contents entries found.",
      "XtraRichEditStringId.Msg_NumberingListNotInListCollection": "Cannot use a numbering List. The numbering list must be added to Document.NumberingLists collection",
      "XtraRichEditStringId.Msg_OperationIsNotValidDueDocumentState": "Operation is not valid due to the current state of the document.",
      "XtraRichEditStringId.Msg_ParagraphStyleNameAlreadyExists": "This style name already exists.",
      "XtraRichEditStringId.Msg_PasswordNotConfirmed": "Confirmation password is not identical.",
      "XtraRichEditStringId.Msg_PasswordRequiredError": "Password required to open this file!",
      "XtraRichEditStringId.Msg_PasswordTooLong": "Password length must be less than {0} characters long.",
      "XtraRichEditStringId.Msg_PrintingUnavailable": "Printing is not available. Check if the following assemblies are installed:{0}",
      "XtraRichEditStringId.Msg_ReplacementsCount": "{0} replacements were made.",
      "XtraRichEditStringId.Msg_Saving": "Saving...",
      "XtraRichEditStringId.Msg_SearchComplete": "The search is complete.",
      "XtraRichEditStringId.Msg_SearchInBackwardDirectionComplete": "The beginning of the document has been reached.",
      "XtraRichEditStringId.Msg_SearchInForwardDirectionComplete": "The end of the document has been reached.",
      "XtraRichEditStringId.Msg_SearchInSelectionComplete": "The search in the selection is finished.",
      "XtraRichEditStringId.Msg_SearchItemNotFound": "The search item was not found.",
      "XtraRichEditStringId.Msg_SelectBookmarkError": "Cannot select a bookmark of inactive SubDocument.",
      "XtraRichEditStringId.Msg_SelectCommentError": "Cannot select a comment of inactive SubDocument.",
      "XtraRichEditStringId.Msg_StyleAlreadyLinked": "Error: style already contains a linked style",
      "XtraRichEditStringId.Msg_StyleNameIsEmpty": "The style name cannot be empty.",
      "XtraRichEditStringId.Msg_SyntaxErrorInFieldPattern": "Syntax Error, {0}.",
      "XtraRichEditStringId.Msg_TableExceedsMaximumNumberOfColumns": "This exceeds the maximum number of columns.",
      "XtraRichEditStringId.Msg_TableIntegrityError": "Error: broken table integrity",
      "XtraRichEditStringId.Msg_TopBottomSectionMarginsTooLarge": "The top/bottom margins are too large for the page height in some sections.",
      "XtraRichEditStringId.Msg_UnexpectedEndOfFormula": "!Unexpected end of formula.",
      "XtraRichEditStringId.Msg_UnknownSwitchArgument": "Error! Unknown switch argument.",
      "XtraRichEditStringId.Msg_UnmatchedQuotesInFieldPattern": "Error: pattern string contains unmatched quotes.",
      "XtraRichEditStringId.Msg_UnsupportedDocVersion": "Only MS Word 97 and later versions are supported",
      "XtraRichEditStringId.Msg_UnsupportedFormatException": "Unsupported format or corrupted file",
      "XtraRichEditStringId.Msg_UseDeletedBookmarkError": "Error: using deleted bookmark",
      "XtraRichEditStringId.Msg_UseDeletedCommentError": "Error: using deleted comment",
      "XtraRichEditStringId.Msg_UseDeletedCustomMarkError": "Error: using deleted customMark",
      "XtraRichEditStringId.Msg_UseDeletedFieldError": "Error: using deleted field",
      "XtraRichEditStringId.Msg_UseDeletedFormFieldError": "Error: using deleted form field",
      "XtraRichEditStringId.Msg_UseDeletedHyperlinkError": "Error: using deleted hyperlink",
      "XtraRichEditStringId.Msg_UseDeletedParagraphError": "Error: using deleted paragraph",
      "XtraRichEditStringId.Msg_UseDeletedSectionError": "Error: using deleted section",
      "XtraRichEditStringId.Msg_UseDeletedStyleError": "Error: using deleted style",
      "XtraRichEditStringId.Msg_UseDeletedTableCellError": "Error: using deleted table cell",
      "XtraRichEditStringId.Msg_UseDeletedTableError": "Error: using deleted table",
      "XtraRichEditStringId.Msg_UseDeletedTableRowError": "Error: using deleted table row",
      "XtraRichEditStringId.Msg_UsedWrongUnit": "The unit of measurement is incorrectly specified.",
      "XtraRichEditStringId.Msg_UseInvalidCharacterProperties": "Error: character properties are no longer valid",
      "XtraRichEditStringId.Msg_UseInvalidCompatibilitySettings": "Error: these compatibility settings are no longer valid",
      "XtraRichEditStringId.Msg_UseInvalidCustomProperties": "Error: this custom properties is no longer valid",
      "XtraRichEditStringId.Msg_UseInvalidDocument": "Error: this document is no longer valid",
      "XtraRichEditStringId.Msg_UseInvalidDocumentEncryption": "Error: these document encryption settings are no longer valid",
      "XtraRichEditStringId.Msg_UseInvalidDocumentProperties": "Error: this document properties is no longer valid",
      "XtraRichEditStringId.Msg_UseInvalidDocumentTrackChangesOptions": "Error: these document track changes options are no longer valid",
      "XtraRichEditStringId.Msg_UseInvalidParagraphProperties": "Error: paragraph properties are no longer valid",
      "XtraRichEditStringId.Msg_VariableDeletedOrMissed": "Error: document variable is either missing or deleted (from Variables collection)",
      "XtraRichEditStringId.Msg_WrongPasswordError": "Password check failed!",
      "XtraRichEditStringId.Msg_ZeroDivide": "!Zero divide.",
      "XtraRichEditStringId.MsgWinOnlyLimitation": "This action can be performed only on Windows operating system.",
      "XtraRichEditStringId.No": "N",
      "XtraRichEditStringId.Page": "Page {2}: [{3}] ",
      "XtraRichEditStringId.RtlComment": "[{1} {0}] Comment",
      "XtraRichEditStringId.SelectionCollection_AtLeastOneSelectionIsRequiredException": "Cannot remove a selection. At least one selection is required.",
      "XtraRichEditStringId.SelectionCollection_CannotRemoveCaretException": "Cannot remove caret.",
      "XtraRichEditStringId.SelectionCollection_CurrentSelectionAndSpecifiedSelectionIntersectException": "Current selection and the specified selection intersect.",
      "XtraRichEditStringId.SelectionCollection_EmptyCollectionException": "Cannot add an empty collection.",
      "XtraRichEditStringId.SelectionCollection_FirstCellContinuesVerticalMergeException": "The first cell in the selected range continues the vertical merge, which is not allowed in a selection collection.",
      "XtraRichEditStringId.SelectionCollection_LastCellContinuesVerticalMergeException": "The last cell in the selected range continues the vertical merge, which is not allowed in a selection collection.",
      "XtraRichEditStringId.SelectionCollection_OutOfRangeException": "Index was out of range. Must be non-negative and less than the size of the selection collection.",
      "XtraRichEditStringId.SelectionCollection_PartiallySelectedCellsException": "Partially selected cells are not allowed.",
      "XtraRichEditStringId.SelectionCollection_RangeCannotBeEmptyException": "Range cannot be empty.",
      "XtraRichEditStringId.SelectionCollection_SelecitonShouldIncludeNotMoreThanOneRowException": "A selection range should include not more than one row",
      "XtraRichEditStringId.SelectionCollection_SelectionCollectionEmptyException": "The selection collection is empty.",
      "XtraRichEditStringId.SelectionCollection_SelectionExtendsOutsideTableException": "The selection extends outside the table, so the entire row must be selected.",
      "XtraRichEditStringId.SelectionCollection_SelectionShouldContainAtLeastOneCharacterException": "The selection should contain at least one character.",
      "XtraRichEditStringId.SelectionCollection_SpecifiedSelectionsIntersectException": "Specified selections intersect.",
      "XtraRichEditStringId.TabForm_All": "All",
      "XtraRichEditStringId.TargetFrameDescription_Blank": "New window",
      "XtraRichEditStringId.TargetFrameDescription_Parent": "Parent frame",
      "XtraRichEditStringId.TargetFrameDescription_Self": "Same frame",
      "XtraRichEditStringId.TargetFrameDescription_Top": "Whole page",
      "XtraRichEditStringId.UnderlineNone": "(none)",
      "XtraRichEditStringId.UserGroup_Administrators": "Administrators",
      "XtraRichEditStringId.UserGroup_Contributors": "Contributors",
      "XtraRichEditStringId.UserGroup_CurrentUser": "Current User",
      "XtraRichEditStringId.UserGroup_Editors": "Editors",
      "XtraRichEditStringId.UserGroup_Everyone": "Everyone",
      "XtraRichEditStringId.UserGroup_Owners": "Owners",
      "XtraRichEditStringId.Yes": "Y",
      "ASPxRichEditStringId.Font": "Font",
      "ASPxRichEditStringId.FontStyle": "Font style",
      "ASPxRichEditStringId.FontSize": "Font size",
      "ASPxRichEditStringId.FontColor": "Font color",
      "ASPxRichEditStringId.UnderlineStyle": "Underline style",
      "ASPxRichEditStringId.UnderlineColor": "Underline color",
      "ASPxRichEditStringId.Effects": "Effect",
      "ASPxRichEditStringId.AllCaps": "All caps",
      "ASPxRichEditStringId.SmallCaps": "Small caps",
      "ASPxRichEditStringId.Hidden": "Hidden",
      "ASPxRichEditStringId.Normal": "Normal",
      "ASPxRichEditStringId.Bold": "Bold",
      "ASPxRichEditStringId.Italic": "Italic",
      "ASPxRichEditStringId.BoldItalic": "Bold Italic",
      "ASPxRichEditStringId.UnderlineWordsOnly": "Underline words only",
      "ASPxRichEditStringId.UnderlineType_None": "None",
      "ASPxRichEditStringId.UnderlineType_Single": "Single",
      "ASPxRichEditStringId.UnderlineType_Dotted": "Dotted",
      "ASPxRichEditStringId.UnderlineType_Dashed": "Dashed",
      "ASPxRichEditStringId.UnderlineType_DashDotted": "Dash Dotted",
      "ASPxRichEditStringId.UnderlineType_DashDotDotted": "Dash Dot Dotted",
      "ASPxRichEditStringId.UnderlineType_Double": "Double",
      "ASPxRichEditStringId.UnderlineType_HeavyWave": "Heavy Wave",
      "ASPxRichEditStringId.UnderlineType_LongDashed": "Long Dashed",
      "ASPxRichEditStringId.UnderlineType_ThickSingle": "Thick Single",
      "ASPxRichEditStringId.UnderlineType_ThickDotted": "Thick Dotted",
      "ASPxRichEditStringId.UnderlineType_ThickDashed": "Thick Dashed",
      "ASPxRichEditStringId.UnderlineType_ThickDashDotted": "Thick Dash Dotted",
      "ASPxRichEditStringId.UnderlineType_ThickDashDotDotted": "Thick Dash Dot Dotted",
      "ASPxRichEditStringId.UnderlineType_ThickLongDashed": "Thick Long Dashed",
      "ASPxRichEditStringId.UnderlineType_DoubleWave": "Double Wave",
      "ASPxRichEditStringId.UnderlineType_Wave": "Wave",
      "ASPxRichEditStringId.UnderlineType_DashSmallGap": "Dash Small Gap",
      "ASPxRichEditStringId.StrikeoutType_None": "None",
      "ASPxRichEditStringId.StrikeoutType_Single": "Single",
      "ASPxRichEditStringId.StrikeoutType_Double": "Double",
      "ASPxRichEditStringId.CharacterFormattingScript_Normal": "Normal",
      "ASPxRichEditStringId.CharacterFormattingScript_Subscript": "Subscript",
      "ASPxRichEditStringId.CharacterFormattingScript_Superscript": "Superscript",
      "ASPxRichEditStringId.ParagraphTab0": "Indents And Spacing",
      "ASPxRichEditStringId.ParagraphTab1": "Line And Page Breaks",
      "ASPxRichEditStringId.General": "General",
      "ASPxRichEditStringId.Alignment": "Alignment",
      "ASPxRichEditStringId.Left": "Left",
      "ASPxRichEditStringId.Right": "Right",
      "ASPxRichEditStringId.Centered": "Centered",
      "ASPxRichEditStringId.Justified": "Justified",
      "ASPxRichEditStringId.OutlineLevel": "Outline level",
      "ASPxRichEditStringId.BodyText": "Body Text",
      "ASPxRichEditStringId.Level": "Level",
      "ASPxRichEditStringId.Indentation": "Indentation",
      "ASPxRichEditStringId.Special": "Special",
      "ASPxRichEditStringId.By": "By",
      "ASPxRichEditStringId.none": "(none)",
      "ASPxRichEditStringId.FirstLine": "First line",
      "ASPxRichEditStringId.Hanging": "Hanging",
      "ASPxRichEditStringId.Spacing": "Spacing",
      "ASPxRichEditStringId.Before": "Before",
      "ASPxRichEditStringId.LineSpacing": "Line spacing",
      "ASPxRichEditStringId.At": "At",
      "ASPxRichEditStringId.After": "After",
      "ASPxRichEditStringId.Single": "Single",
      "ASPxRichEditStringId.spacing_1_5_lines": "1.5 lines",
      "ASPxRichEditStringId.Double": "Double",
      "ASPxRichEditStringId.Multiple": "Multiple",
      "ASPxRichEditStringId.Exactly": "Exactly",
      "ASPxRichEditStringId.AtLeast": "At least",
      "ASPxRichEditStringId.NoSpace": "Don't add space between paragraph of the same style",
      "ASPxRichEditStringId.Pagination": "Pagination",
      "ASPxRichEditStringId.KLT": "Keep lines together",
      "ASPxRichEditStringId.PBB": "Page break before",
      "ASPxRichEditStringId.Options": "Options",
      "ASPxRichEditStringId.Preview": "Preview",
      "ASPxRichEditStringId.Style": "Style",
      "ASPxRichEditStringId.Color": "Color",
      "ASPxRichEditStringId.SaveToServer": "Save file to the server",
      "ASPxRichEditStringId.DownloadCopy": "Download a copy",
      "ASPxRichEditStringId.FolderPath": "Folder path",
      "ASPxRichEditStringId.FileType": "File type",
      "ASPxRichEditStringId.FileName": "File name",
      "ASPxRichEditStringId.ChooseType": "Choose download file type",
      "ASPxRichEditStringId.SaveFileAs": "Save file as",
      "ASPxRichEditStringId.Invalid_FileName": "Invalid file name",
      "ASPxRichEditStringId.PathDoesNotExist": "Path does not exist.Check the path and try again.",
      "ASPxRichEditStringId.Portrait": "Portrait",
      "ASPxRichEditStringId.Landscape": "Landscape",
      "ASPxRichEditStringId.Continuous": "Continuous",
      "ASPxRichEditStringId.NewPage": "New page",
      "ASPxRichEditStringId.EvenPage": "Even page",
      "ASPxRichEditStringId.OddPage": "Odd page",
      "ASPxRichEditStringId.WholeDocument": "Whole document",
      "ASPxRichEditStringId.CurrentSection": "Current section",
      "ASPxRichEditStringId.SelectedSections": "Selected sections",
      "ASPxRichEditStringId.ThisPointForward": "This point forward",
      "ASPxRichEditStringId.Margins": "Margins",
      "ASPxRichEditStringId.Paper": "Paper",
      "ASPxRichEditStringId.Layout": "Layout",
      "ASPxRichEditStringId.Top": "Top",
      "ASPxRichEditStringId.Bottom": "Bottom",
      "ASPxRichEditStringId.Orientation": "Orientation",
      "ASPxRichEditStringId.PaperSize": "Paper size",
      "ASPxRichEditStringId.Width": "Width",
      "ASPxRichEditStringId.Height": "Height",
      "ASPxRichEditStringId.Section": "Section",
      "ASPxRichEditStringId.SectionStart": "Section start",
      "ASPxRichEditStringId.HeadersAndFooters": "Headers and footers",
      "ASPxRichEditStringId.DifferentOddAndEven": "Different odd and even",
      "ASPxRichEditStringId.DifferentFirstPage": "Different first page",
      "ASPxRichEditStringId.ApplyTo": "Apply to",
      "ASPxRichEditStringId.FromEdge": "From edge",
      "ASPxRichEditStringId.Header": "Header",
      "ASPxRichEditStringId.Footer": "Footer",
      "ASPxRichEditStringId.CancelButton": "Cancel",
      "ASPxRichEditStringId.OkButton": "OK",
      "ASPxRichEditStringId.OpenButton": "Open",
      "ASPxRichEditStringId.SaveAsButton": "Save",
      "ASPxRichEditStringId.DownloadButton": "Download",
      "ASPxRichEditStringId.SelectButton": "Select",
      "ASPxRichEditStringId.InsertButton": "Insert",
      "ASPxRichEditStringId.ChangeButton": "Change",
      "ASPxRichEditStringId.CloseButton": "Close",
      "ASPxRichEditStringId.InsertTable_TableSize": "Table Size",
      "ASPxRichEditStringId.InsertTable_NumberOfColumns": "Number of columns",
      "ASPxRichEditStringId.InsertTable_NumberOfRows": "Number of rows",
      "ASPxRichEditStringId.SplitCells_MergeCells": "Merge cells before split",
      "ASPxRichEditStringId.InsertCells_ShiftCellsRight": "Shift cells right",
      "ASPxRichEditStringId.InsertCells_ShiftCellsDown": "Shift cells down",
      "ASPxRichEditStringId.InsertCells_InsertEntireRow": "Insert entire row",
      "ASPxRichEditStringId.InsertCells_InsertEntireColumn": "Insert entire column",
      "ASPxRichEditStringId.DeleteCells_ShiftCellsLeft": "Shift cells left",
      "ASPxRichEditStringId.DeleteCells_ShiftCellsUp": "Shift cells up",
      "ASPxRichEditStringId.DeleteCells_DeleteEntireRow": "Delete entire row",
      "ASPxRichEditStringId.DeleteCells_DeleteEntireColumn": "Delete entire column",
      "ASPxRichEditStringId.InsertImage_FromWeb": "From the web (URL)",
      "ASPxRichEditStringId.InsertImage_FromLocal": "From your computer",
      "ASPxRichEditStringId.InsertImage_EnterUrl": "Enter image web address",
      "ASPxRichEditStringId.InsertImage_UploadInstructions": "Browse your computer for the image file to upload",
      "ASPxRichEditStringId.InsertImage_ImagePreview": "Image preview",
      "ASPxRichEditStringId.TableProperties_Size": "Size",
      "ASPxRichEditStringId.TableProperties_Alignment": "Alignment",
      "ASPxRichEditStringId.TableProperties_VerticalAlignment": "Vertical alignment",
      "ASPxRichEditStringId.TableProperties_MeasureIn": "Measure in",
      "ASPxRichEditStringId.TableProperties_PreferredWidth": "Preferred width",
      "ASPxRichEditStringId.TableProperties_IndentLeft": "Indent from left",
      "ASPxRichEditStringId.TableProperties_SpecifyHeight": "Specify height",
      "ASPxRichEditStringId.TableProperties_RowHeight": "Row height is",
      "ASPxRichEditStringId.TableProperties_Table": "Table",
      "ASPxRichEditStringId.TableProperties_Row": "Row",
      "ASPxRichEditStringId.TableProperties_Column": "Column",
      "ASPxRichEditStringId.TableProperties_Cell": "Cell",
      "ASPxRichEditStringId.TableProperties_Options": "Options...",
      "ASPxRichEditStringId.TableProperties_BordersAndShading": "Borders and Shading...",
      "ASPxRichEditStringId.TableProperties_PreviousRow": "Previous row",
      "ASPxRichEditStringId.TableProperties_NextRow": "Next row",
      "ASPxRichEditStringId.TableProperties_PreviousColumn": "Previous column",
      "ASPxRichEditStringId.TableProperties_NextColumn": "Next column",
      "ASPxRichEditStringId.TableProperties_CantSplit": "Allow row to break across pages",
      "ASPxRichEditStringId.TableOptions_DefaultCellMargins": "Default cell margins",
      "ASPxRichEditStringId.TableOptions_DefaultCellSpacing": "Default cell spacing",
      "ASPxRichEditStringId.TableOptions_AllowSpacing": "Allow spacing between cells",
      "ASPxRichEditStringId.TableOptions_AutoResize": "Automatically resize to fit contents",
      "ASPxRichEditStringId.BorderShading_Borders": "Borders",
      "ASPxRichEditStringId.BorderShading_Shading": "Shading",
      "ASPxRichEditStringId.BorderShading_Fill": "Fill",
      "ASPxRichEditStringId.BorderShading_BordersDescription": "Click on diagram below or use buttons to apply borders",
      "ASPxRichEditStringId.BorderShading_BordersNone": "None",
      "ASPxRichEditStringId.BorderShading_BordersBox": "Box",
      "ASPxRichEditStringId.BorderShading_BordersAll": "All",
      "ASPxRichEditStringId.BorderShading_BordersGrid": "Grid",
      "ASPxRichEditStringId.BorderShading_BordersCustom": "Custom",
      "ASPxRichEditStringId.CellOptions_CellMargins": "Cell margins",
      "ASPxRichEditStringId.CellOptions_SameAsTable": "Same as the whole table",
      "ASPxRichEditStringId.CellOptions_WrapText": "Wrap text",
      "ASPxRichEditStringId.Columns_Presets": "Presets",
      "ASPxRichEditStringId.Columns_NumberOfColumns": "Number of columns",
      "ASPxRichEditStringId.Columns_WidthAndSpacing": "Width and spacing",
      "ASPxRichEditStringId.Columns_EqualWidth": "Equal column width",
      "ASPxRichEditStringId.Columns_Col": "Col #:",
      "ASPxRichEditStringId.Columns_Width": "Width",
      "ASPxRichEditStringId.Columns_Spacing": "Spacing",
      "ASPxRichEditStringId.PageFile": "File",
      "ASPxRichEditStringId.PageHome": "Home",
      "ASPxRichEditStringId.PageInsert": "Insert",
      "ASPxRichEditStringId.PagePageLayout": "Page Layout",
      "ASPxRichEditStringId.PageReferences": "References",
      "ASPxRichEditStringId.PageMailings": "Mail Merge",
      "ASPxRichEditStringId.PageReview": "Review",
      "ASPxRichEditStringId.PageView": "View",
      "ASPxRichEditStringId.PageHeaderAndFooter": "Header  Footer",
      "ASPxRichEditStringId.PageTableDesign": "Design",
      "ASPxRichEditStringId.PageTableLayout": "Layout",
      "ASPxRichEditStringId.PageFloatingObjects": "Format",
      "ASPxRichEditStringId.GroupUndo": "Undo",
      "ASPxRichEditStringId.GroupFont": "Font",
      "ASPxRichEditStringId.GroupParagraph": "Paragraph",
      "ASPxRichEditStringId.GroupClipboard": "Clipboard",
      "ASPxRichEditStringId.GroupEditing": "Editing",
      "ASPxRichEditStringId.GroupCommon": "Common",
      "ASPxRichEditStringId.GroupStyles": "Styles",
      "ASPxRichEditStringId.GroupZoom": "Zoom",
      "ASPxRichEditStringId.GroupShow": "Show",
      "ASPxRichEditStringId.GroupIllustrations": "Illustrations",
      "ASPxRichEditStringId.GroupText": "Text",
      "ASPxRichEditStringId.GroupTables": "Tables",
      "ASPxRichEditStringId.GroupSymbols": "Symbols",
      "ASPxRichEditStringId.GroupLinks": "Links",
      "ASPxRichEditStringId.GroupPages": "Pages",
      "ASPxRichEditStringId.GroupHeaderFooter": "Header  Footer",
      "ASPxRichEditStringId.GroupHeaderFooterToolsDesignClose": "Close",
      "ASPxRichEditStringId.GroupHeaderFooterToolsDesignNavigation": "Navigation",
      "ASPxRichEditStringId.GroupMailMerge": "Mail Merge",
      "ASPxRichEditStringId.GroupInsertFields": "Insert Fields",
      "ASPxRichEditStringId.GroupMailMergeView": "View",
      "ASPxRichEditStringId.GroupCurrentRecord": "Current Record",
      "ASPxRichEditStringId.GroupFinish": "Finish",
      "ASPxRichEditStringId.GroupDocumentViews": "Document Views",
      "ASPxRichEditStringId.GroupHeaderFooterToolsDesignOptions": "Options",
      "ASPxRichEditStringId.GroupHeaderFooterToolsDesignPosition": "Position",
      "ASPxRichEditStringId.GroupPageSetup": "Page Setup",
      "ASPxRichEditStringId.GroupDocumentProtection": "Protect",
      "ASPxRichEditStringId.GroupDocumentProofing": "Proofing",
      "ASPxRichEditStringId.GroupDocumentComment": "Comment",
      "ASPxRichEditStringId.GroupDocumentTracking": "Tracking",
      "ASPxRichEditStringId.GroupTableOfContents": "Table of Contents",
      "ASPxRichEditStringId.GroupFloatingPictureToolsArrange": "Arrange",
      "ASPxRichEditStringId.GroupFloatingPictureToolsShapeStyles": "Shape Styles",
      "ASPxRichEditStringId.GroupCaptions": "Captions",
      "ASPxRichEditStringId.GroupPageBackground": "Background",
      "ASPxRichEditStringId.GroupDocumentLanguage": "Language",
      "ASPxRichEditStringId.GroupView": "View",
      "ASPxRichEditStringId.GroupTableToolsDesignTableStyleOptions": "Table Style Options",
      "ASPxRichEditStringId.GroupTableToolsDesignTableStyles": "Table Styles",
      "ASPxRichEditStringId.GroupTableToolsDesignBordersAndShadings": "Borders  Shadings",
      "ASPxRichEditStringId.GroupTableToolsLayoutTable": "Table",
      "ASPxRichEditStringId.GroupTableToolsLayoutRowsAndColumns": "Rows  Columns",
      "ASPxRichEditStringId.GroupTableToolsLayoutMerge": "Merge",
      "ASPxRichEditStringId.GroupTableToolsLayoutCellSize": "Cell Size",
      "ASPxRichEditStringId.GroupTableToolsLayoutAlignment": "Alignment",
      "ASPxRichEditStringId.GroupFloatingObjectsShapeStyles": "Shape Styles",
      "ASPxRichEditStringId.GroupFloatingObjectsArrange": "Arrange",
      "ASPxRichEditStringId.RequiredFieldError": "This field is required",
      "ASPxRichEditStringId.MenuCmd_ToggleFullScreen": "Full Screen",
      "ASPxRichEditStringId.MenuCmd_ToggleFullScreenDescription": "Enable / disable the full screen mode.",
      "ASPxRichEditStringId.SaveAsFileTitle": "Save As",
      "ASPxRichEditStringId.OpenFileTitle": "Open",
      "ASPxRichEditStringId.FontTitle": "Font",
      "ASPxRichEditStringId.ParagraphTitle": "Paragraph",
      "ASPxRichEditStringId.PageSetupTitle": "Page Setup",
      "ASPxRichEditStringId.ColumnsTitle": "Columns",
      "ASPxRichEditStringId.InsertTableTitle": "Insert Table",
      "ASPxRichEditStringId.InsertTableCellsTitle": "Insert Cells",
      "ASPxRichEditStringId.DeleteTableCellsTitle": "Delete Cells",
      "ASPxRichEditStringId.SplitTableCellsTitle": "Split Cells",
      "ASPxRichEditStringId.TablePropertiesTitle": "Table Properties",
      "ASPxRichEditStringId.BorderShadingTitle": "Borders and Shading",
      "ASPxRichEditStringId.InsertMergeFieldTitle": "Insert Merge Field",
      "ASPxRichEditStringId.ExportRangeTitle": "Export Range",
      "ASPxRichEditStringId.BookmarkTitle": "Bookmark",
      "ASPxRichEditStringId.FindReplaceTitle": "Find and Replace",
      "ASPxRichEditStringId.InsertImageTitle": "Insert Picture",
      "ASPxRichEditStringId.ErrorTitle": "Error",
      "ASPxRichEditStringId.SpellingTitle": "Spelling",
      "ASPxRichEditStringId.InformationTitle": "Information",
      "ASPxRichEditStringId.WarningTitle": "Warning",
      "ASPxRichEditStringId.RulerMarginLeftTitle": "Margin Left",
      "ASPxRichEditStringId.RulerMarginRightTitle": "Margin Right",
      "ASPxRichEditStringId.RulerFirstLineIdentTitle": "First Line Ident",
      "ASPxRichEditStringId.RulerHangingIdentTitle": "Hanging Ident",
      "ASPxRichEditStringId.RulerRightIdentTitle": "Right Ident",
      "ASPxRichEditStringId.RulerLeftIdentTitle": "Left Ident",
      "ASPxRichEditStringId.RulerFirstLineIndentTitle": "First Line Indent",
      "ASPxRichEditStringId.RulerHangingIndentTitle": "Hanging Indent",
      "ASPxRichEditStringId.RulerRightIndentTitle": "Right Indent",
      "ASPxRichEditStringId.RulerLeftIndentTitle": "Left Indent",
      "ASPxRichEditStringId.RulerLeftTabTitle": "Left Tab",
      "ASPxRichEditStringId.RulerRightTabTitle": "Right Tab",
      "ASPxRichEditStringId.RulerCenterTabTitle": "Center Tab",
      "ASPxRichEditStringId.RulerDecimalTabTitle": "Decimal Title",
      "ASPxRichEditStringId.MarginsNarrow": "Narrow",
      "ASPxRichEditStringId.MarginsNormal": "Normal",
      "ASPxRichEditStringId.MarginsModerate": "Moderate",
      "ASPxRichEditStringId.MarginsWide": "Wide",
      "ASPxRichEditStringId.ModelIsChangedError": "The document has been modified outside the editor and needs to be reloaded. &lt;br /&gt;Your last-minute changes might not be preserved, if any.",
      "ASPxRichEditStringId.SessionHasExpiredError": "Your session has expired. &lt;br /&gt;Please refresh the page to continue.",
      "ASPxRichEditStringId.OpeningAndOverstoreImpossibleError": "The open and save document operations are not allowed&lt;br /&gt;since the working directory is not specified for the editor.",
      "ASPxRichEditStringId.ClipboardAccessDeniedError": "Access to the clipboard is denied due to your browser's security policy. &lt;br /&gt;Instead, you can use the keyboard shortcuts:&lt;br /&gt;{0}+X (cut), {0}+C (copy) and {0}+V (paste).",
      "ASPxRichEditStringId.ClipboardAccessDeniedErrorTouch": "Access to the clipboard is denied due to your browser's security policy. Instead, use the native menu that can be invoked by tapping and holding selected content.",
      "ASPxRichEditStringId.InnerExceptionsError": "An unspecified error occurred and the editor needs to be reloaded. Your last changes can be lost.",
      "ASPxRichEditStringId.AuthExceptionsError": "Access to the document is restricted and the editor needs to be reloaded. Your last changes can be lost.",
      "ASPxRichEditStringId.CantSaveToEmptyPathError": "Cannot save a document opened not from a file. Handle the Saving event to process custom saving",
      "ASPxRichEditStringId.ConfirmOnLosingChanges": "Are you sure you want to perform the action? All unsaved document data will be lost.",
      "ASPxRichEditStringId.RestartNumbering": "Restart Numbering",
      "ASPxRichEditStringId.ContinueNumbering": "Continue Numbering",
      "ASPxRichEditStringId.UpdateField": "Update Field",
      "ASPxRichEditStringId.ToggleFieldCodes": "Toggle Field Codes",
      "ASPxRichEditStringId.OpenHyperlink": "Open hyperlink",
      "ASPxRichEditStringId.EditHyperlink": "Edit hyperlink..",
      "ASPxRichEditStringId.RemoveHyperlink": "Remove hyperlink",
      "ASPxRichEditStringId.CantOpenDocumentError": "The document could not be opened because a server error occurred.",
      "ASPxRichEditStringId.CantSaveDocumentError": "The document could not be saved because a server error occurred.",
      "ASPxRichEditStringId.DocVariableExceptionError": "The DOCVARIABLE field could not be calculated because a server error occurred.",
      "ASPxRichEditStringId.InvalidDocumentFormatError": "The file is corrupt and cannot be opened.",
      "ASPxRichEditStringId.InsertContentFromServerExceptionError": "The InsertContentFromServerCommand could not be executed because a server error occurred.",
      "ASPxRichEditStringId.LoadPictureError": "Could not load the image.",
      "ASPxRichEditStringId.MenuCmd_CreateField": "Create Field",
      "ASPxRichEditStringId.MenuCmd_CreateFieldDescription": "Create a dynamic element used as a placeholder for data that might change in a document.",
      "ASPxRichEditStringId.MenuCmd_UpdateAllFields": "Update All Fields",
      "ASPxRichEditStringId.MenuCmd_UpdateAllFieldsDescription": "Refresh all dynamic elements in the document by getting the latest source data.",
      "ASPxRichEditStringId.ParagraphStyles": "Paragraph Styles",
      "ASPxRichEditStringId.CharacterStyles": "Character Styles",
      "ASPxRichEditStringId.BulletedAndNumberingTitle": "Bulleted and Numbering",
      "ASPxRichEditStringId.CustomizeNumberedListTitle": "Customize Numbered List",
      "ASPxRichEditStringId.CustomizeBulletedListTitle": "Customize Bulleted List",
      "ASPxRichEditStringId.CustomizeOutlineNumberedTitle": "Customize Outline List",
      "ASPxRichEditStringId.HyperlinkTitle": "Hyperlink",
      "ASPxRichEditStringId.TabsTitle": "Tabs",
      "ASPxRichEditStringId.SymbolsTitle": "Symbols",
      "ASPxRichEditStringId.OtherLabels_None": "none",
      "ASPxRichEditStringId.OtherLabels_All": "All",
      "ASPxRichEditStringId.OtherLabels_ConfirmToRewriteExistingFile": "already exist. Do you want to replace it?",
      "ASPxRichEditStringId.Numbering_Character": "Character...",
      "ASPxRichEditStringId.Numbering_Font": "Font...",
      "ASPxRichEditStringId.Numbering_BulletCharacter": "Bullet character",
      "ASPxRichEditStringId.Numbering_BulletPosition": "Bullet position",
      "ASPxRichEditStringId.Numbering_TextPosition": "Text position",
      "ASPxRichEditStringId.Numbering_AlignedAt": "Aligned at",
      "ASPxRichEditStringId.Numbering_IndentAt": "Indent at",
      "ASPxRichEditStringId.Numbering_StartAt": "Start at",
      "ASPxRichEditStringId.Numbering_NumberFormat": "Number format",
      "ASPxRichEditStringId.Numbering_NumberStyle": "Number style",
      "ASPxRichEditStringId.Numbering_NumberPosition": "Number position",
      "ASPxRichEditStringId.Numbering_Left": "Left",
      "ASPxRichEditStringId.Numbering_Center": "Center",
      "ASPxRichEditStringId.Numbering_Right": "Right",
      "ASPxRichEditStringId.Numbering_FollowNumberWith": "Follow Number With",
      "ASPxRichEditStringId.Numbering_Level": "Level",
      "ASPxRichEditStringId.Numbering_TabCharacter": "Tab character",
      "ASPxRichEditStringId.Numbering_Space": "Space",
      "ASPxRichEditStringId.Numbering_Nothing": "Nothing",
      "ASPxRichEditStringId.Numbering_Customize": "Customize...",
      "ASPxRichEditStringId.Numbering_Bulleted": "Bulleted",
      "ASPxRichEditStringId.Numbering_Numbered": "Numbered",
      "ASPxRichEditStringId.Numbering_OutlineNumbered": "Outline Numbered",
      "ASPxRichEditStringId.Hyperlink_EmailAddress": "E-mail address",
      "ASPxRichEditStringId.Hyperlink_Url": "URL",
      "ASPxRichEditStringId.Hyperlink_Bookmark": "Bookmark",
      "ASPxRichEditStringId.Hyperlink_WebPage": "Web page",
      "ASPxRichEditStringId.Hyperlink_PlaceInThisDocument": "Place in this document",
      "ASPxRichEditStringId.Hyperlink_EmailTo": "E-mail to",
      "ASPxRichEditStringId.Hyperlink_Subject": "Subject",
      "ASPxRichEditStringId.Hyperlink_DisplayProperties": "DisplayProperties",
      "ASPxRichEditStringId.Hyperlink_Text": "Text",
      "ASPxRichEditStringId.Hyperlink_ToolTip": "ToolTip",
      "ASPxRichEditStringId.Hyperlink_InvalidEmail": "Invalid E-mail",
      "ASPxRichEditStringId.Tabs_Set": "Set",
      "ASPxRichEditStringId.Tabs_Clear": "Clear",
      "ASPxRichEditStringId.Tabs_ClearAll": "Clear All",
      "ASPxRichEditStringId.Tabs_TabStopPosition": "Tab stop position",
      "ASPxRichEditStringId.Tabs_DefaultTabStops": "Default tab stops",
      "ASPxRichEditStringId.Tabs_TabStopsToBeCleared": "Tab stops to be cleared",
      "ASPxRichEditStringId.Tabs_Alignment": "Alignment",
      "ASPxRichEditStringId.Tabs_Leader": "Leader",
      "ASPxRichEditStringId.Tabs_Left": "Left",
      "ASPxRichEditStringId.Tabs_Center": "Center",
      "ASPxRichEditStringId.Tabs_Right": "Right",
      "ASPxRichEditStringId.Tabs_Decimal": "Decimal",
      "ASPxRichEditStringId.Tabs_None": "None",
      "ASPxRichEditStringId.Tabs_Hyphens": "Hyphens",
      "ASPxRichEditStringId.Tabs_EqualSign": "Equal signs",
      "ASPxRichEditStringId.Tabs_Dots": "Dots",
      "ASPxRichEditStringId.Tabs_Underline": "Underline",
      "ASPxRichEditStringId.Tabs_MiddleDots": "Middle dots",
      "ASPxRichEditStringId.Tabs_ThickLine": "Thick line",
      "ASPxRichEditStringId.Tabs_NoTabs": "no tabs",
      "ASPxRichEditStringId.FinishMerge_From": "From",
      "ASPxRichEditStringId.FinishMerge_Count": "Count",
      "ASPxRichEditStringId.FinishMerge_MergeMode": "Merge mode",
      "ASPxRichEditStringId.FinishMerge_AllRecords": "All records",
      "ASPxRichEditStringId.FinishMerge_CurrentRecord": "Current record",
      "ASPxRichEditStringId.FinishMerge_Range": "Range",
      "ASPxRichEditStringId.FinishMerge_NewParagraph": "New Paragraph",
      "ASPxRichEditStringId.FinishMerge_NewSection": "New Section",
      "ASPxRichEditStringId.FinishMerge_DocumentFormat": "Document Format",
      "ASPxRichEditStringId.FinishMerge_JoinTables": "Join Tables",
      "ASPxRichEditStringId.InsertMergeField_Fields": "Fields",
      "ASPxRichEditStringId.InsertMergeField_NoFields": "no fields",
      "ASPxRichEditStringId.Bookmarks_BookmarkName": "Bookmark name",
      "ASPxRichEditStringId.Bookmarks_SortBy": "Sort by",
      "ASPxRichEditStringId.Bookmarks_Name": "Name",
      "ASPxRichEditStringId.Bookmarks_Location": "Location",
      "ASPxRichEditStringId.Bookmarks_Add": "Add",
      "ASPxRichEditStringId.Bookmarks_Delete": "Delete",
      "ASPxRichEditStringId.Bookmarks_GoTo": "Go To",
      "ASPxRichEditStringId.Bookmarks_NameErrorText": "Bookmark names must begin with a letter",
      "ASPxRichEditStringId.Bookmarks_NoBookmarks": "No bookmarks",
      "ASPxRichEditStringId.FindReplace_Find": "Find",
      "ASPxRichEditStringId.FindReplace_AdvancedFind": "Advanced find",
      "ASPxRichEditStringId.FindReplace_Replace": "Replace",
      "ASPxRichEditStringId.FindReplace_ReplaceAll": "Replace All",
      "ASPxRichEditStringId.FindReplace_ReplaceWith": "Replace with",
      "ASPxRichEditStringId.FindReplace_Previous": "Previous",
      "ASPxRichEditStringId.FindReplace_Next": "Next",
      "ASPxRichEditStringId.FindReplace_MatchCase": "Match case",
      "ASPxRichEditStringId.FindReplace_Results": "Results",
      "ASPxRichEditStringId.FindReplace_NoResult": "We couldn't find what you were looking for.",
      "ASPxRichEditStringId.FindReplace_TooLongResult": "The result is too long. Check out the pages to see your results in text.",
      "ASPxRichEditStringId.FindReplace_ReplaceAllNotify": "{0} entries have been replaced",
      "ASPxRichEditStringId.FindReplace_Items": "Items",
      "ASPxRichEditStringId.FindReplace_NoMatches": "No matches",
      "ASPxRichEditStringId.FindReplace_Of": "of",
      "ASPxRichEditStringId.SpellingCheckCompletedInformation": "The spelling check is complete.",
      "ASPxRichEditStringId.Spelling_IgnoreOnce": "Ignore Once",
      "ASPxRichEditStringId.Spelling_IgnoreAll": "Ignore All",
      "ASPxRichEditStringId.Spelling_AddToDictionary": "Add to Dictionary",
      "ASPxRichEditStringId.Spelling_Delete": "Delete",
      "ASPxRichEditStringId.Spelling_Change": "Change",
      "ASPxRichEditStringId.Spelling_ChangeAll": "Change All",
      "ASPxRichEditStringId.Spelling_NotInDictionary": "Not in Dictionary:",
      "ASPxRichEditStringId.Spelling_RepeatedWord": "Repeated Word:",
      "ASPxRichEditStringId.Spelling_Suggestions": "Suggestions:",
      "ASPxRichEditStringId.Spelling_NoSpellingSuggestions": "(no spelling suggestions)",
      "ASPxRichEditStringId.MenuCmd_MakeTextSentenceCase": "Sentence case.",
      "ASPxRichEditStringId.MenuCmd_MakeTextSentenceCaseDescription": "Sentence case.",
      "ASPxRichEditStringId.MenuCmd_ShowCellOptionsForm": "Cell Margins",
      "ASPxRichEditStringId.MenuCmd_ShowCellOptionsFormDescription": "Customize cell margins",
      "ASPxRichEditStringId.MenuCmd_HeaderOffset": "Header from Top:",
      "ASPxRichEditStringId.MenuCmd_FooterOffset": "Footer from Bottom:",
      "ASPxRichEditStringId.MenuCmd_HeaderOffsetDescription": "Specify the distance from the top of the page to the top of the header.",
      "ASPxRichEditStringId.MenuCmd_FooterOffsetDescription": "Specify the distance from the bottom of the page to the bottom of the footer.",
      "ASPxRichEditStringId.LayoutOptions_Inline": "In line with text",
      "ASPxRichEditStringId.LayoutOptions_LockAnchor": "Lock Anchor",
      "ASPxRichEditStringId.LayoutOptions_LockAspectRatio": "Lock aspect ratio",
      "ASPxRichEditStringId.LayoutOptions_Reset": "Reset",
      "ASPxRichEditStringId.LayoutOptions_BothSides": "Both sides",
      "ASPxRichEditStringId.LayoutOptions_LeftOnly": "Left only",
      "ASPxRichEditStringId.LayoutOptions_RightOnly": "Right only",
      "ASPxRichEditStringId.LayoutOptions_LargestOnly": "Largest only",
      "ASPxRichEditStringId.LayoutOptions_HorizontalPositionAlignmentInside": "Inside",
      "ASPxRichEditStringId.LayoutOptions_HorizontalPositionAlignmentOutside": "Outside",
      "ASPxRichEditStringId.LayoutOptions_Position": "Position",
      "ASPxRichEditStringId.LayoutOptions_Horizontal": "Horizontal",
      "ASPxRichEditStringId.LayoutOptions_Vertical": "Vertical",
      "ASPxRichEditStringId.LayoutOptions_RelativeTo": "relative to",
      "ASPxRichEditStringId.LayoutOptions_HorizontalBookLayoutOf": "of",
      "ASPxRichEditStringId.LayoutOptions_AbsolutePosition": "Absolute position",
      "ASPxRichEditStringId.LayoutOptions_RelativePosition": "Relative position",
      "ASPxRichEditStringId.LayoutOptions_Alignment": "Alignment",
      "ASPxRichEditStringId.LayoutOptions_BookLayout": "Book layout",
      "ASPxRichEditStringId.LayoutOptions_ToTheRightOf": "to the right of",
      "ASPxRichEditStringId.LayoutOptions_Below": "below",
      "ASPxRichEditStringId.LayoutOptions_TextWrapping": "Text Wrapping",
      "ASPxRichEditStringId.LayoutOptions_WrappingStyle": "Wrapping style",
      "ASPxRichEditStringId.LayoutOptions_WrapText": "Wrap text",
      "ASPxRichEditStringId.LayoutOptions_DistanceFromText": "Distance from text",
      "ASPxRichEditStringId.LayoutOptions_Size": "Size",
      "ASPxRichEditStringId.LayoutOptions_Absolute": "Absolute",
      "ASPxRichEditStringId.LayoutOptions_Relative": "Relative",
      "ASPxRichEditStringId.LayoutOptions_Rotate": "Rotate",
      "ASPxRichEditStringId.LayoutOptions_Rotation": "Rotation",
      "ASPxRichEditStringId.LayoutOptions_Scale": "Scale",
      "ASPxRichEditStringId.LayoutOptions_OriginalSize": "Original size",
      "ASPxRichEditStringId.FileManager_OpenedFileDeleteErrorText": "The file cannot be deleted. It is already opened by another user.",
      "ASPxRichEditStringId.FileManager_OpenedFileMoveErrorText": "The file cannot be moved. It is already opened by another user.",
      "ASPxRichEditStringId.FileManager_OpenedFileRenameErrorText": "The file cannot be renamed. It is already opened by another user.",
      "ASPxRichEditStringId.CreateDateField": "DATE",
      "ASPxRichEditStringId.CreateDateFieldDescription": "Inserts the current date and time.",
      "ASPxRichEditStringId.CreateTimeField": "TIME",
      "ASPxRichEditStringId.CreateTimeFieldDescription": "Inserts the current time.",
      "ASPxRichEditStringId.CreatePageField": "PAGE",
      "ASPxRichEditStringId.CreatePageFieldDescription": "Inserts the number of the page containing the field.",
      "ASPxRichEditStringId.CreatePageCountField": "NUMPAGES",
      "ASPxRichEditStringId.CreatePageCountFieldDescription": "Inserts the total number of pages.",
      "ASPxRichEditStringId.CreateEmptyMergeField": "MERGEFIELD",
      "ASPxRichEditStringId.CreateEmptyMergeFieldDescription": "Retrieves a value from the bound data source.",
      "ASPxRichEditStringId.CreateEmptyDocVariableField": "DOCVARIABLE",
      "ASPxRichEditStringId.CreateEmptyDocVariableFieldDescription": "Enables you to programmatically insert complex content when this field is updated.",
      "ASPxRichEditStringId.CreateEmptyIfField": "IF",
      "ASPxRichEditStringId.CreateEmptyIfFieldDescription": "Compares two values and inserts text related to the comparison result.",
      "ASPxRichEditStringId.MenuCmd_Font_settings": "Font settings",
      "ASPxRichEditStringId.MenuCmd_Align_Paragraph": "Align Paragraph",
      "ASPxRichEditStringId.MenuCmd_Empty": "Empty",
      "XtraRichEditStringId.Caption_AllMarkup": "All Markup",
      "XtraRichEditStringId.Caption_CharacterPositionLowered": "Lowered",
      "XtraRichEditStringId.Caption_CharacterPositionNormal": "Normal",
      "XtraRichEditStringId.Caption_CharacterPositionRaised": "Raised",
      "XtraRichEditStringId.Caption_CharacterSpacingCondensed": "Condensed",
      "XtraRichEditStringId.Caption_CharacterSpacingExpanded": "Expanded",
      "XtraRichEditStringId.Caption_CharacterSpacingNormal": "Normal",
      "XtraRichEditStringId.Caption_FillInForms": "Filling in Forms",
      "XtraRichEditStringId.Caption_GroupDocumentChanges": "Changes",
      "XtraRichEditStringId.Caption_GroupNotes": "Footnotes",
      "XtraRichEditStringId.Caption_NoMarkup": "No Markup",
      "XtraRichEditStringId.Caption_NoteFootNoteColumnCountFourColumn": "4 column",
      "XtraRichEditStringId.Caption_NoteFootNoteColumnCountMatchSectionLayout": "Match section layout",
      "XtraRichEditStringId.Caption_NoteFootNoteColumnCountOneColumn": "1 column",
      "XtraRichEditStringId.Caption_NoteFootNoteColumnCountThreeColumn": "3 column",
      "XtraRichEditStringId.Caption_NoteFootNoteColumnCountTwoColumn": "2 column",
      "XtraRichEditStringId.Caption_NoteNumberingRestartTypeContinuous": "Continuous",
      "XtraRichEditStringId.Caption_NoteNumberingRestartTypeNewPage": "Restart each page",
      "XtraRichEditStringId.Caption_NoteNumberingRestartTypeNewSection": "Restart each section",
      "XtraRichEditStringId.Caption_NotePositionBelowText": "Below text",
      "XtraRichEditStringId.Caption_NotePositionBottomOfPage": "Bottom of page",
      "XtraRichEditStringId.Caption_NotePositionEndOfDocument": "End of document",
      "XtraRichEditStringId.Caption_NotePositionEndOfSection": "End of section",
      "XtraRichEditStringId.Caption_OriginalMarkup": "Original",
      "XtraRichEditStringId.Caption_SimpleMarkup": "Simple Markup",
      "XtraRichEditStringId.Caption_TrackedChanges": "Tracked Changes",
      "XtraRichEditStringId.DefaultStyleName_BookTitle": "Book Title",
      "XtraRichEditStringId.DefaultStyleName_IntenseEmphasis": "Intense Emphasis",
      "XtraRichEditStringId.DefaultStyleName_IntenseQuote": "Intense Quote",
      "XtraRichEditStringId.DefaultStyleName_ListParagraph": "List Paragraph",
      "XtraRichEditStringId.DefaultStyleName_NoSpacing": "No Spacing",
      "XtraRichEditStringId.DefaultStyleName_Quote": "Quote",
      "XtraRichEditStringId.DefaultStyleName_SubtleEmphasis": "Subtle Emphasis",
      "XtraRichEditStringId.FileFilterDescription_DocmFiles": "Word Macro-Enabled Document",
      "XtraRichEditStringId.FileFilterDescription_DotFiles": "Word 97-2003 Template",
      "XtraRichEditStringId.FileFilterDescription_DotmFiles": "Word Macro-Enabled Template",
      "XtraRichEditStringId.FileFilterDescription_DotxFiles": "Word Template",
      "XtraRichEditStringId.FileFilterDescription_FlatOpcFiles": "Word XML Document",
      "XtraRichEditStringId.FileFilterDescription_FlatOpcMacroEnabledFiles": "Word Macro-Enabled XML Document",
      "XtraRichEditStringId.FileFilterDescription_FlatOpcMacroEnabledTemplateFiles": "Word Macro-Enabled XML Template",
      "XtraRichEditStringId.FileFilterDescription_FlatOpcTemplateFiles": "Word XML Template",
      "XtraRichEditStringId.MenuCmd_Accept": "Accept",
      "XtraRichEditStringId.MenuCmd_AcceptAllChanges": "Accept All Changes",
      "XtraRichEditStringId.MenuCmd_AcceptAllChangesAndStopTracking": "Accept All Changes and Stop Tracking",
      "XtraRichEditStringId.MenuCmd_AcceptAllChangesAndStopTrackingDescription": "Accept All Changes and Stop Tracking",
      "XtraRichEditStringId.MenuCmd_AcceptAllChangesDescription": "Accept All Changes",
      "XtraRichEditStringId.MenuCmd_AcceptAllChangesShown": "Accept All Changes Shown",
      "XtraRichEditStringId.MenuCmd_AcceptAllChangesShownDescription": "Accept All Changes Shown",
      "XtraRichEditStringId.MenuCmd_AcceptAndMoveToNextRevision": "Accept and Move to Next",
      "XtraRichEditStringId.MenuCmd_AcceptAndMoveToNextRevisionDescription": "Keep this change and move one to the next one.",
      "XtraRichEditStringId.MenuCmd_AcceptCurrentRevision": "Accept This Change",
      "XtraRichEditStringId.MenuCmd_AcceptCurrentRevisionDescription": "Accept This Change",
      "XtraRichEditStringId.MenuCmd_AcceptDescription": "Click for additional options, such as accepting all the changes at once.",
      "XtraRichEditStringId.MenuCmd_ChangeDisplayForReviewMode": "Display for Review",
      "XtraRichEditStringId.MenuCmd_ChangeDisplayForReviewModeDescription": "Choose how you'd like to see changes in this document.",
      "XtraRichEditStringId.MenuCmd_ChangeFloatingObjectTextWrapTypeDescription": "Change the way text wraps around the selected object. To configure the object so that it moves along with text the text around it, select \"In Line With Text\".",
      "XtraRichEditStringId.MenuCmd_ConvertNotesDescription": "Convert Note",
      "XtraRichEditStringId.MenuCmd_ConvertToEndnote": "Convert to Endnote",
      "XtraRichEditStringId.MenuCmd_ConvertToFootnote": "Convert to Footnote",
      "XtraRichEditStringId.MenuCmd_InsertEndnote": "Insert Endnote",
      "XtraRichEditStringId.MenuCmd_InsertEndnoteDescription": "Add a endnote",
      "XtraRichEditStringId.MenuCmd_InsertFootnote": "Insert Footnote",
      "XtraRichEditStringId.MenuCmd_InsertFootnoteDescription": "Add a footnote",
      "XtraRichEditStringId.MenuCmd_InsertNonBreakingHyphen": "InsertNonBreakingHyphen",
      "XtraRichEditStringId.MenuCmd_InsertObject": "Object",
      "XtraRichEditStringId.MenuCmd_InsertObjectDescription": "Insert an embedded object, such as another Word document or an Excel chart.",
      "XtraRichEditStringId.MenuCmd_LockTracking": "Lock Tracking",
      "XtraRichEditStringId.MenuCmd_LockTrackingDescription": "Use a password to keep others from turning off Track Changes",
      "XtraRichEditStringId.MenuCmd_NextEndnote": "Next Endnote",
      "XtraRichEditStringId.MenuCmd_NextEndnoteDescription": "Jump to next endnote",
      "XtraRichEditStringId.MenuCmd_NextFootnote": "Next Footnote",
      "XtraRichEditStringId.MenuCmd_NextFootnoteDescription": "Jump to next footnote",
      "XtraRichEditStringId.MenuCmd_NextRevision": "Next",
      "XtraRichEditStringId.MenuCmd_NextRevisionDescription": "Jump to the next tracked change.",
      "XtraRichEditStringId.MenuCmd_NoteOptions": "Note Options...",
      "XtraRichEditStringId.MenuCmd_PreviousEndnote": "Previous Endnote",
      "XtraRichEditStringId.MenuCmd_PreviousEndnoteDescription": "Jump to previous endnote",
      "XtraRichEditStringId.MenuCmd_PreviousFootnote": "Previous Footnote",
      "XtraRichEditStringId.MenuCmd_PreviousFootnoteDescription": "Jump to previous footnote",
      "XtraRichEditStringId.MenuCmd_PrevRevision": "Previous",
      "XtraRichEditStringId.MenuCmd_PrevRevisionDescription": "Jump to the previous tracked change.",
      "XtraRichEditStringId.MenuCmd_Reject": "Reject",
      "XtraRichEditStringId.MenuCmd_RejectAllChanges": "Reject All Changes",
      "XtraRichEditStringId.MenuCmd_RejectAllChangesAndStopTracking": "Reject All Changes and Stop Tracking",
      "XtraRichEditStringId.MenuCmd_RejectAllChangesAndStopTrackingDescription": "Reject All Changes and Stop Tracking",
      "XtraRichEditStringId.MenuCmd_RejectAllChangesDescription": "Reject All Changes",
      "XtraRichEditStringId.MenuCmd_RejectAllChangesShown": "Reject All Changes Shown",
      "XtraRichEditStringId.MenuCmd_RejectAllChangesShownDescription": "Reject All Changes Shown",
      "XtraRichEditStringId.MenuCmd_RejectAndMoveToNextRevision": "Reject and Move to Next",
      "XtraRichEditStringId.MenuCmd_RejectAndMoveToNextRevisionDescription": "Undo this change and move on to the next one.",
      "XtraRichEditStringId.MenuCmd_RejectCurrentRevision": "Reject This Change",
      "XtraRichEditStringId.MenuCmd_RejectCurrentRevisionDescription": "Reject This Change",
      "XtraRichEditStringId.MenuCmd_RejectDescription": "Click for additional options, such as rejecting all the changes at once.",
      "XtraRichEditStringId.MenuCmd_ShowEndnote": "Go to Endnote",
      "XtraRichEditStringId.MenuCmd_ShowFootnote": "Go to Footnote",
      "XtraRichEditStringId.MenuCmd_ShowMarkup": "Show Markup",
      "XtraRichEditStringId.MenuCmd_ShowMarkupDescription": "Choose what types of markup to show in your document.",
      "XtraRichEditStringId.MenuCmd_ShowNotes": "Show Notes",
      "XtraRichEditStringId.MenuCmd_ShowNotesDescription": "Jump to the footnotes or endnotes",
      "XtraRichEditStringId.MenuCmd_ShowNotesForm": "Footnotes",
      "XtraRichEditStringId.MenuCmd_ShowNotesFormDescription": "Show the Notes dialog box.",
      "XtraRichEditStringId.MenuCmd_TrackChanges": "Track Changes",
      "XtraRichEditStringId.MenuCmd_TrackChangesDescription": "Keep track of changes made to this document.",
      "XtraRichEditStringId.Msg_CannotGroupInlineShapes": "You cannot group inline shapes.",
      "XtraRichEditStringId.Msg_CannotGroupShapesLocatedInDifferentSubdocuments": "Cannot group shapes that are located in different subdocuments.",
      "XtraRichEditStringId.Msg_GroupCannotContainCanvas": "A group cannot contain a canvas.",
      "XtraRichEditStringId.Msg_IncorrectOleObjectInsertType": "This OLE object is linked and cannot be saved. You can save only embedded OLE objects.",
      "XtraRichEditStringId.Msg_InvalidCharacterScale": "The value must be between {0} and {1}",
      "XtraRichEditStringId.Msg_InvalidDictionaryFormat": "The dictionary format is incorrect.",
      "XtraRichEditStringId.Msg_NoTrackedChanges": "There aren't any tracked changes in your document.",
      "XtraRichEditStringId.Msg_ShapesBelongToDifferentCanvases": "The specified shapes belong to different canvases and cannot be grouped.",
      "XtraRichEditStringId.Msg_ShapesDoNotBelogToCanvas": "One or more shapes do not belong to the canvas and cannot be grouped.",
      "XtraRichEditStringId.Msg_SpecifiedShapeIsNotGroup": "The specified shape is not a group.",
      "XtraRichEditStringId.Msg_UseDeletedNoteError": "Error: using deleted note",
      "XtraRichEditStringId.Msg_UseDeletedObjectError": "The object is already deleted.",
      "XtraRichEditStringId.TablePositioningForm_HorizontalPositionAlignmentCenter": "Center",
      "XtraRichEditStringId.TablePositioningForm_HorizontalPositionAlignmentInside": "Inside",
      "XtraRichEditStringId.TablePositioningForm_HorizontalPositionAlignmentLeft": "Left",
      "XtraRichEditStringId.TablePositioningForm_HorizontalPositionAlignmentOutside": "Outside",
      "XtraRichEditStringId.TablePositioningForm_HorizontalPositionAlignmentRight": "Right",
      "XtraRichEditStringId.TablePositioningForm_HorizontalPositionTypeColumn": "Column",
      "XtraRichEditStringId.TablePositioningForm_HorizontalPositionTypeMargin": "Margin",
      "XtraRichEditStringId.TablePositioningForm_HorizontalPositionTypePage": "Page",
      "XtraRichEditStringId.TablePositioningForm_VerticalPositionAlignmentBottom": "Bottom",
      "XtraRichEditStringId.TablePositioningForm_VerticalPositionAlignmentCenter": "Center",
      "XtraRichEditStringId.TablePositioningForm_VerticalPositionAlignmentInside": "Inside",
      "XtraRichEditStringId.TablePositioningForm_VerticalPositionAlignmentOutside": "Outside",
      "XtraRichEditStringId.TablePositioningForm_VerticalPositionAlignmentTop": "Top",
      "XtraRichEditStringId.TablePositioningForm_VerticalPositionTypeMargin": "Margin",
      "XtraRichEditStringId.TablePositioningForm_VerticalPositionTypePage": "Page",
      "XtraRichEditStringId.TablePositioningForm_VerticalPositionTypeParagraph": "Paragraph",
      "RichEditExtensionsStringId.Caption_ColorAutomatic": "Automatic",
      "RichEditExtensionsStringId.Caption_Formatting": "Formatting",
      "RichEditExtensionsStringId.Caption_GroupCaptions": "Captions",
      "RichEditExtensionsStringId.Caption_GroupClipboard": "Clipboard",
      "RichEditExtensionsStringId.Caption_GroupCommon": "Common",
      "RichEditExtensionsStringId.Caption_GroupDocumentComment": "Comment",
      "RichEditExtensionsStringId.Caption_GroupDocumentLanguage": "Language",
      "RichEditExtensionsStringId.Caption_GroupDocumentProofing": "Proofing",
      "RichEditExtensionsStringId.Caption_GroupDocumentProtection": "Protect",
      "RichEditExtensionsStringId.Caption_GroupDocumentTracking": "Tracking",
      "RichEditExtensionsStringId.Caption_GroupDocumentViews": "Document Views",
      "RichEditExtensionsStringId.Caption_GroupEditing": "Editing",
      "RichEditExtensionsStringId.Caption_GroupFloatingPictureToolsArrange": "Arrange",
      "RichEditExtensionsStringId.Caption_GroupFloatingPictureToolsShapeStyles": "Shape Styles",
      "RichEditExtensionsStringId.Caption_GroupFont": "Font",
      "RichEditExtensionsStringId.Caption_GroupHeaderFooter": "Header && Footer",
      "RichEditExtensionsStringId.Caption_GroupHeaderFooterToolsDesignClose": "Close",
      "RichEditExtensionsStringId.Caption_GroupHeaderFooterToolsDesignNavigation": "Navigation",
      "RichEditExtensionsStringId.Caption_GroupHeaderFooterToolsDesignOptions": "Options",
      "RichEditExtensionsStringId.Caption_GroupIllustrations": "Illustrations",
      "RichEditExtensionsStringId.Caption_GroupInfo": "Info",
      "RichEditExtensionsStringId.Caption_GroupLinks": "Links",
      "RichEditExtensionsStringId.Caption_GroupMailMerge": "Mail Merge",
      "RichEditExtensionsStringId.Caption_GroupNotes": "Footnotes",
      "RichEditExtensionsStringId.Caption_GroupPageBackground": "Background",
      "RichEditExtensionsStringId.Caption_GroupPages": "Pages",
      "RichEditExtensionsStringId.Caption_GroupPageSetup": "Page Setup",
      "RichEditExtensionsStringId.Caption_GroupParagraph": "Paragraph",
      "RichEditExtensionsStringId.Caption_GroupShow": "Show",
      "RichEditExtensionsStringId.Caption_GroupStyles": "Styles",
      "RichEditExtensionsStringId.Caption_GroupSymbols": "Symbols",
      "RichEditExtensionsStringId.Caption_GroupTableOfContents": "Table of Contents",
      "RichEditExtensionsStringId.Caption_GroupTables": "Tables",
      "RichEditExtensionsStringId.Caption_GroupText": "Text",
      "RichEditExtensionsStringId.Caption_GroupZoom": "Zoom",
      "RichEditExtensionsStringId.Caption_NoColor": "No Color",
      "RichEditExtensionsStringId.Caption_NoFill": "No Fill",
      "RichEditExtensionsStringId.Caption_NoOutline": "No Outline",
      "RichEditExtensionsStringId.Caption_PageCategoryFloatingPictureTools": "Picture Tools",
      "RichEditExtensionsStringId.Caption_PageCategoryHeaderFooterTools": "Header & Footer Tools",
      "RichEditExtensionsStringId.Caption_PageFile": "File",
      "RichEditExtensionsStringId.Caption_PageFloatingObjectPictureToolsFormat": "Format",
      "RichEditExtensionsStringId.Caption_PageHeaderFooterToolsDesign": "Design",
      "RichEditExtensionsStringId.Caption_PageHome": "Home",
      "RichEditExtensionsStringId.Caption_PageInsert": "Insert",
      "RichEditExtensionsStringId.Caption_PageLayout": "Layout",
      "RichEditExtensionsStringId.Caption_PageMailings": "Mail Merge",
      "RichEditExtensionsStringId.Caption_PagePageLayout": "Page Layout",
      "RichEditExtensionsStringId.Caption_PageReferences": "References",
      "RichEditExtensionsStringId.Caption_PageReview": "Review",
      "RichEditExtensionsStringId.Caption_PageView": "View",
      "ASPxRichEditStringId.Download_WordDocument": "Word Document (*.docx)",
      "ASPxRichEditStringId.Download_RichTextFormat": "Rich Text Format (*.rtf)",
      "ASPxRichEditStringId.Download_PlainText": "Plain Text (*.txt)",
      "ASPxRichEditStringId.Download_Html": "Html Document (*.html, *.htm)"
    }
  });
}