"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numberToStringHex = exports.numberToStringBin = exports.isOdd = exports.isEven = exports.isNonNullString = exports.isString = exports.isNumber = exports.boolToString = exports.boolToInt = exports.isDefined = void 0;
var string_1 = require("./string");
function isDefined(value) {
  return value !== undefined && value !== null;
}
exports.isDefined = isDefined;
function boolToInt(value) {
  return value ? 1 : 0;
}
exports.boolToInt = boolToInt;
function boolToString(value) {
  return value ? '1' : '0';
}
exports.boolToString = boolToString;
function isNumber(obj) {
  return typeof obj === 'number';
}
exports.isNumber = isNumber;
function isString(obj) {
  return typeof obj === 'string';
}
exports.isString = isString;
function isNonNullString(str) {
  return !!str;
}
exports.isNonNullString = isNonNullString;
function isEven(num) {
  return num % 2 !== 0;
}
exports.isEven = isEven;
function isOdd(num) {
  return num % 2 === 0;
}
exports.isOdd = isOdd;
function numberToStringBin(num, minLength) {
  if (minLength === void 0) {
    minLength = 0;
  }
  return string_1.StringUtils.padLeft(num.toString(2), minLength, '0');
}
exports.numberToStringBin = numberToStringBin;
function numberToStringHex(num, minLength) {
  if (minLength === void 0) {
    minLength = 0;
  }
  return string_1.StringUtils.padLeft(num.toString(16), minLength, '0');
}
exports.numberToStringHex = numberToStringHex;