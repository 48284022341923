import { Section } from '../model/section/section';
import { SectionPropertyDescriptor } from '../model/section/section-property-descriptor';
import { InputPositionBase } from './input-position-base';
import { SearchUtils } from '@devexpress/utils/lib/utils/search';
export class InputPosition extends InputPositionBase {
  constructor(selection) {
    super();
    this.selection = selection;
    this.setIntervals(this.selection.getState().intervalsInfo);
    this.reset();
  }
  reset() {
    this.setIntervals(this.selection.getState().intervalsInfo);
    this.resetReturnValues();
  }
  resetSectionMergedProperties() {
    this.mergedSectionPropertiesRaw = null;
    this.mergedSectionPropertiesFull = null;
  }
  getMergedSectionPropertiesRaw() {
    if (!this.mergedSectionPropertiesRaw) {
      let sectionIndex;
      let intervalEndPosition;
      const sections = this.model.sections;
      const pageIndex = this.selection.pageIndex;
      if (pageIndex < 0) {
        const interval = this.intervalsInfo.interval;
        const intervalStartPosition = interval.start;
        intervalEndPosition = interval.end;
        sectionIndex = SearchUtils.normedInterpolationIndexOf(sections, s => s.startLogPosition.value, intervalStartPosition);
      } else {
        const layoutPage = this.selection.layout.pages[pageIndex];
        sectionIndex = Section.getPageSectionIndex(layoutPage, sections);
        intervalEndPosition = layoutPage.getEndPosition();
      }
      this.mergedSectionPropertiesRaw = sections[sectionIndex++].sectionProperties.clone();
      for (let section; (section = sections[sectionIndex]) && section.startLogPosition.value < intervalEndPosition; sectionIndex++) InputPositionBase.mergePropertiesRaw(this.mergedSectionPropertiesRaw, section.sectionProperties, SectionPropertyDescriptor.ALL_FIELDS);
    }
    return this.mergedSectionPropertiesRaw;
  }
  getMergedSectionPropertiesFull() {
    if (!this.mergedSectionPropertiesFull) this.mergedSectionPropertiesFull = InputPositionBase.mergePropertiesFull(this.getMergedSectionPropertiesRaw(), SectionPropertyDescriptor.ALL_FIELDS);
    return this.mergedSectionPropertiesFull;
  }
  resetReturnValues() {
    super.resetReturnValues();
    this.mergedSectionPropertiesRaw = null;
    this.mergedSectionPropertiesFull = null;
  }
}