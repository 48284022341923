import { Log } from '../base-logger/log';
import { LogObjToStr } from '../base-logger/log-obj-to-str';
import { LogObjToStrLayout } from '../layout-logger/log-obj-to-str-layout';
import { ModifierKey } from '@devexpress/utils/lib/utils/key';
import { MouseButton } from '../../../../event-manager';
import { MouseEventSource } from '../../../../mouse-handler/mouse-event-source';
export class LogObjToStrCanvas {
  static richMouseEvent(evt) {
    const result = [];
    result.push(`absPoint: ${LogObjToStr.point(evt.absolutePoint)}`);
    result.push(`scroll: ${LogObjToStr.point(evt.scroll)}`);
    result.push(`layoutPoint: ${LogObjToStrLayout.layoutPoint(evt.layoutPoint)}`);
    result.push(`modifiers: ${Log.mask(ModifierKey, evt.modifiers, ModifierKey.None)}`);
    result.push(`button: ${Log.mask(MouseButton, evt.button, MouseButton.None)}`);
    result.push(`middleButtonPressed: ${evt.middleButtonPressed}`);
    result.push(`mouseEvent: ${evt.mouseEvent}`);
    result.push(`source: ${MouseEventSource[evt.source]}`);
    return result.join("\n");
  }
}