export var RelativeWidthType;
(function (RelativeWidthType) {
  RelativeWidthType[RelativeWidthType["Margin"] = 0] = "Margin";
  RelativeWidthType[RelativeWidthType["Page"] = 1] = "Page";
  RelativeWidthType[RelativeWidthType["LeftMargin"] = 2] = "LeftMargin";
  RelativeWidthType[RelativeWidthType["RightMargin"] = 3] = "RightMargin";
  RelativeWidthType[RelativeWidthType["InsideMargin"] = 4] = "InsideMargin";
  RelativeWidthType[RelativeWidthType["OutsideMargin"] = 5] = "OutsideMargin";
})(RelativeWidthType || (RelativeWidthType = {}));
export var RelativeHeightType;
(function (RelativeHeightType) {
  RelativeHeightType[RelativeHeightType["Margin"] = 0] = "Margin";
  RelativeHeightType[RelativeHeightType["Page"] = 1] = "Page";
  RelativeHeightType[RelativeHeightType["TopMargin"] = 2] = "TopMargin";
  RelativeHeightType[RelativeHeightType["BottomMargin"] = 3] = "BottomMargin";
  RelativeHeightType[RelativeHeightType["InsideMargin"] = 4] = "InsideMargin";
  RelativeHeightType[RelativeHeightType["OutsideMargin"] = 5] = "OutsideMargin";
})(RelativeHeightType || (RelativeHeightType = {}));
export var AnchorObjectTextWrapType;
(function (AnchorObjectTextWrapType) {
  AnchorObjectTextWrapType[AnchorObjectTextWrapType["None"] = 0] = "None";
  AnchorObjectTextWrapType[AnchorObjectTextWrapType["TopAndBottom"] = 1] = "TopAndBottom";
  AnchorObjectTextWrapType[AnchorObjectTextWrapType["Tight"] = 2] = "Tight";
  AnchorObjectTextWrapType[AnchorObjectTextWrapType["Through"] = 3] = "Through";
  AnchorObjectTextWrapType[AnchorObjectTextWrapType["Square"] = 4] = "Square";
})(AnchorObjectTextWrapType || (AnchorObjectTextWrapType = {}));
export var AnchorObjectTextWrapSide;
(function (AnchorObjectTextWrapSide) {
  AnchorObjectTextWrapSide[AnchorObjectTextWrapSide["Both"] = 0] = "Both";
  AnchorObjectTextWrapSide[AnchorObjectTextWrapSide["Left"] = 1] = "Left";
  AnchorObjectTextWrapSide[AnchorObjectTextWrapSide["Right"] = 2] = "Right";
  AnchorObjectTextWrapSide[AnchorObjectTextWrapSide["Largest"] = 3] = "Largest";
})(AnchorObjectTextWrapSide || (AnchorObjectTextWrapSide = {}));
export var AnchorObjectHorizontalPositionType;
(function (AnchorObjectHorizontalPositionType) {
  AnchorObjectHorizontalPositionType[AnchorObjectHorizontalPositionType["Page"] = 0] = "Page";
  AnchorObjectHorizontalPositionType[AnchorObjectHorizontalPositionType["Character"] = 1] = "Character";
  AnchorObjectHorizontalPositionType[AnchorObjectHorizontalPositionType["Column"] = 2] = "Column";
  AnchorObjectHorizontalPositionType[AnchorObjectHorizontalPositionType["Margin"] = 3] = "Margin";
  AnchorObjectHorizontalPositionType[AnchorObjectHorizontalPositionType["LeftMargin"] = 4] = "LeftMargin";
  AnchorObjectHorizontalPositionType[AnchorObjectHorizontalPositionType["RightMargin"] = 5] = "RightMargin";
  AnchorObjectHorizontalPositionType[AnchorObjectHorizontalPositionType["InsideMargin"] = 6] = "InsideMargin";
  AnchorObjectHorizontalPositionType[AnchorObjectHorizontalPositionType["OutsideMargin"] = 7] = "OutsideMargin";
})(AnchorObjectHorizontalPositionType || (AnchorObjectHorizontalPositionType = {}));
export var AnchorObjectHorizontalPositionAlignment;
(function (AnchorObjectHorizontalPositionAlignment) {
  AnchorObjectHorizontalPositionAlignment[AnchorObjectHorizontalPositionAlignment["None"] = 0] = "None";
  AnchorObjectHorizontalPositionAlignment[AnchorObjectHorizontalPositionAlignment["Left"] = 1] = "Left";
  AnchorObjectHorizontalPositionAlignment[AnchorObjectHorizontalPositionAlignment["Center"] = 2] = "Center";
  AnchorObjectHorizontalPositionAlignment[AnchorObjectHorizontalPositionAlignment["Right"] = 3] = "Right";
  AnchorObjectHorizontalPositionAlignment[AnchorObjectHorizontalPositionAlignment["Inside"] = 4] = "Inside";
  AnchorObjectHorizontalPositionAlignment[AnchorObjectHorizontalPositionAlignment["Outside"] = 5] = "Outside";
})(AnchorObjectHorizontalPositionAlignment || (AnchorObjectHorizontalPositionAlignment = {}));
export var AnchorObjectVerticalPositionType;
(function (AnchorObjectVerticalPositionType) {
  AnchorObjectVerticalPositionType[AnchorObjectVerticalPositionType["Page"] = 0] = "Page";
  AnchorObjectVerticalPositionType[AnchorObjectVerticalPositionType["Line"] = 1] = "Line";
  AnchorObjectVerticalPositionType[AnchorObjectVerticalPositionType["Paragraph"] = 2] = "Paragraph";
  AnchorObjectVerticalPositionType[AnchorObjectVerticalPositionType["Margin"] = 3] = "Margin";
  AnchorObjectVerticalPositionType[AnchorObjectVerticalPositionType["TopMargin"] = 4] = "TopMargin";
  AnchorObjectVerticalPositionType[AnchorObjectVerticalPositionType["BottomMargin"] = 5] = "BottomMargin";
  AnchorObjectVerticalPositionType[AnchorObjectVerticalPositionType["InsideMargin"] = 6] = "InsideMargin";
  AnchorObjectVerticalPositionType[AnchorObjectVerticalPositionType["OutsideMargin"] = 7] = "OutsideMargin";
})(AnchorObjectVerticalPositionType || (AnchorObjectVerticalPositionType = {}));
export var AnchorObjectVerticalPositionAlignment;
(function (AnchorObjectVerticalPositionAlignment) {
  AnchorObjectVerticalPositionAlignment[AnchorObjectVerticalPositionAlignment["None"] = 0] = "None";
  AnchorObjectVerticalPositionAlignment[AnchorObjectVerticalPositionAlignment["Top"] = 1] = "Top";
  AnchorObjectVerticalPositionAlignment[AnchorObjectVerticalPositionAlignment["Center"] = 2] = "Center";
  AnchorObjectVerticalPositionAlignment[AnchorObjectVerticalPositionAlignment["Bottom"] = 3] = "Bottom";
  AnchorObjectVerticalPositionAlignment[AnchorObjectVerticalPositionAlignment["Inside"] = 4] = "Inside";
  AnchorObjectVerticalPositionAlignment[AnchorObjectVerticalPositionAlignment["Outside"] = 5] = "Outside";
})(AnchorObjectVerticalPositionAlignment || (AnchorObjectVerticalPositionAlignment = {}));
export var TextBoxVerticalAlignment;
(function (TextBoxVerticalAlignment) {
  TextBoxVerticalAlignment[TextBoxVerticalAlignment["Top"] = 0] = "Top";
  TextBoxVerticalAlignment[TextBoxVerticalAlignment["Both"] = 1] = "Both";
  TextBoxVerticalAlignment[TextBoxVerticalAlignment["Center"] = 2] = "Center";
  TextBoxVerticalAlignment[TextBoxVerticalAlignment["Bottom"] = 3] = "Bottom";
})(TextBoxVerticalAlignment || (TextBoxVerticalAlignment = {}));
export var DrawingTextAnchoringType;
(function (DrawingTextAnchoringType) {
  DrawingTextAnchoringType[DrawingTextAnchoringType["Bottom"] = 1] = "Bottom";
  DrawingTextAnchoringType[DrawingTextAnchoringType["Center"] = 2] = "Center";
  DrawingTextAnchoringType[DrawingTextAnchoringType["Distributed"] = 3] = "Distributed";
  DrawingTextAnchoringType[DrawingTextAnchoringType["Justified"] = 4] = "Justified";
  DrawingTextAnchoringType[DrawingTextAnchoringType["Top"] = 5] = "Top";
})(DrawingTextAnchoringType || (DrawingTextAnchoringType = {}));