import { ListUtils } from '@devexpress/utils/lib/utils/list';
export class HtmlTagImporterBase {
  get colorProvider() {
    return this.importer.modelManager.model.colorProvider;
  }
  constructor(importer) {
    this.enablePreserveLineBreaks = false;
    this.paragraphWasAddedBefore = false;
    this.importer = importer;
  }
  getClosestImporterByTagName(tagName) {
    return ListUtils.reverseElementBy(this.importer.levelInfo, levelInfo => levelInfo.tagImporter.elementTag() == tagName).tagImporter;
  }
  shouldPreserveLineBreaksOnChilds() {
    return false;
  }
  isAllowed() {
    return true;
  }
  addRun(run) {
    this.importer.addRun(run);
  }
  get element() {
    return this.importer.currElement;
  }
}