export var MouseEventSource;
(function (MouseEventSource) {
  MouseEventSource[MouseEventSource["Undefined"] = 0] = "Undefined";
  MouseEventSource[MouseEventSource["ResizeBox_NW"] = 1] = "ResizeBox_NW";
  MouseEventSource[MouseEventSource["ResizeBox_NE"] = 2] = "ResizeBox_NE";
  MouseEventSource[MouseEventSource["ResizeBox_SE"] = 3] = "ResizeBox_SE";
  MouseEventSource[MouseEventSource["ResizeBox_SW"] = 4] = "ResizeBox_SW";
  MouseEventSource[MouseEventSource["ResizeBox_N"] = 5] = "ResizeBox_N";
  MouseEventSource[MouseEventSource["ResizeBox_E"] = 6] = "ResizeBox_E";
  MouseEventSource[MouseEventSource["ResizeBox_S"] = 7] = "ResizeBox_S";
  MouseEventSource[MouseEventSource["ResizeBox_W"] = 8] = "ResizeBox_W";
})(MouseEventSource || (MouseEventSource = {}));