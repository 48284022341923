import { TabAlign } from '../../../../model/paragraph/paragraph';
import { RulerTemplateManager } from '../template-manager';
export class RulerTabUtils {
  static getTabTitlePropertyName(align) {
    let alignString = "";
    switch (align) {
      case TabAlign.Left:
        alignString = "Left";
        break;
      case TabAlign.Right:
        alignString = "Right";
        break;
      case TabAlign.Center:
        alignString = "Center";
        break;
      case TabAlign.Decimal:
        alignString = "Decimal";
        break;
    }
    return "tab" + alignString;
  }
  static getSpriteClassName(tabAlign, styles) {
    switch (tabAlign) {
      case TabAlign.Left:
        return styles.tabImages.left.spriteCssClass;
      case TabAlign.Right:
        return styles.tabImages.right.spriteCssClass;
      case TabAlign.Center:
        return styles.tabImages.center.spriteCssClass;
      case TabAlign.Decimal:
        return styles.tabImages.decimal.spriteCssClass;
    }
    return "";
  }
  static getTemplate(tabAlign) {
    switch (tabAlign) {
      case TabAlign.Left:
        return RulerTemplateManager.getLeftTabElementTemplate();
      case TabAlign.Right:
        return RulerTemplateManager.getRightTabElementTemplate();
      case TabAlign.Center:
        return RulerTemplateManager.getCenterTabElementTemplate();
      case TabAlign.Decimal:
        return RulerTemplateManager.getDecimalTabElementTemplate();
    }
  }
}