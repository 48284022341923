export class ModelCreatorOptions {
  constructor() {
    this.fillCharStyles = true;
    this.fillParStyles = true;
    this.fillTableStyles = true;
    this.addParagraph = true;
    this.addSection = true;
    this.fillAbstractNumberingListTemplates = true;
    this.initOfficeTheme = true;
  }
}