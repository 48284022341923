export function createDefaultPlaceholders() {
  return [{
    id: 'DxReColorName',
    description: 'a color value',
    values: [{
      value: 'red',
      spokenForm: 'red'
    }, {
      value: 'green',
      spokenForm: 'green'
    }, {
      value: 'blue',
      spokenForm: 'blue'
    }, {
      value: 'black',
      spokenForm: 'black'
    }]
  }];
}