import { MathUtils } from '@devexpress/utils/lib/utils/math';
import { TableBordersBase } from './table-border-base';
export class TableBorders extends TableBordersBase {
  getHashCode() {
    return super.getHashCode() ^ MathUtils.somePrimes[14] * this.insideHorizontal.getHashCode() ^ MathUtils.somePrimes[15] * this.insideVertical.getHashCode();
  }
  equals(obj) {
    return super.equals(obj) && this.insideHorizontal.equals(obj.insideHorizontal) && this.insideVertical.equals(obj.insideVertical);
  }
  copyFrom(obj) {
    super.copyFrom(obj);
    this.insideHorizontal = obj.insideHorizontal.clone();
    this.insideVertical = obj.insideVertical.clone();
  }
  clone() {
    var result = new TableBorders();
    result.copyFrom(this);
    return result;
  }
  static create(top, right, bottom, left, insideHorizontal, insideVertical) {
    let result = new TableBorders();
    result.top = top;
    result.right = right;
    result.bottom = bottom;
    result.left = left;
    result.insideHorizontal = insideHorizontal;
    result.insideVertical = insideVertical;
    return result;
  }
}