import { __awaiter } from "tslib";
import { NoteContinuationSeparatorRun, NoteSeparatorRun } from "../../../../../../common/model/runs/simple-runs";
import { LeafElementDestination } from "../destination";
import { RichUtils } from "../../../../../../common/model/rich-utils";
export class NoteSeparatorDestination extends LeafElementDestination {
  processElementOpen(_reader) {
    return __awaiter(this, void 0, void 0, function* () {
      const run = new NoteSeparatorRun(this.data.subDocumentInfo.positionImporter.currPosition, this.data.subDocumentInfo.paragraphImporter.paragraph, this.data.subDocumentInfo.characterImporter.charPropsBundle);
      this.data.subDocumentInfo.characterImporter.addRun(run, RichUtils.specialCharacters.ETX);
    });
  }
}
export class NoteContinuationSeparatorDestination extends LeafElementDestination {
  processElementOpen(_reader) {
    return __awaiter(this, void 0, void 0, function* () {
      const run = new NoteContinuationSeparatorRun(this.data.subDocumentInfo.positionImporter.currPosition, this.data.subDocumentInfo.paragraphImporter.paragraph, this.data.subDocumentInfo.characterImporter.charPropsBundle);
      this.data.subDocumentInfo.characterImporter.addRun(run, RichUtils.specialCharacters.EOT);
    });
  }
}