<dx-popup
  #popup
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  title="Dokument(e) hinzufügen"
  width="600"
  height="auto"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view #scrollView height="120px">
      <dx-file-uploader
        [showFileList]="false"
        selectButtonText="Dateien auswählen"
        name="file"
        (onValueChanged)="onValueChanged($event)"
        class="fileUploader-container"
        #fileUploader
        [maxFileSize]="maxFileSize"
        [multiple]="true"
        uploadMode="useForm"
        [allowedFileExtensions]="allowedFileExtensions"
      >
      </dx-file-uploader>

      <span *ngIf="error && error.length > 0" class="fileUploadMsg color-error">{{ error }}</span>
      <span *ngIf="(!error || error.length === 0) && filesToUpload && filesToUpload.length > 0" class="fileUploadMsg"
        >{{ filesToUpload.length }} Dateien ausgewählt...</span
      >
    </dx-scroll-view>
  </div>
  <dxi-popup-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{ disabled: disableUpload, text: 'Hinzufügen', type: 'default', stylingMode: 'contained', icon: 'upload', onClick: onClickSave }"
  >
  </dxi-popup-toolbar-item>

  <dxi-popup-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }"
  >
  </dxi-popup-toolbar-item>
</dx-popup>
