import { RendererClassNames } from '../renderer-class-names';
import { FrameBaseListener } from './frame-base-listener';
export class AnchorListener extends FrameBaseListener {
  baseFrameClassName() {
    return RendererClassNames.ANCHOR;
  }
  constructor(rendererCache, stringResources, readOnlyPropertyHolder, fieldOptions) {
    super(rendererCache, stringResources, fieldOptions);
    this.readOnlyPropertyHolder = readOnlyPropertyHolder;
  }
  NotifyShow(pageIndex, bounds, tip, isTextBox, isAnchoredObject, rotation) {
    if (this.readOnlyPropertyHolder.isReadOnlyPersistent) super.NotifyHide();else super.NotifyShow(pageIndex, bounds, tip, isTextBox, isAnchoredObject, rotation);
  }
}