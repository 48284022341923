"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnitConverter = void 0;
var UnitConverter = function () {
  function UnitConverter() {}
  UnitConverter.hundredthsOfMillimeterToTwipsRound = function (value) {
    return Math.round(1440 * value / 2540.0);
  };
  UnitConverter.documentsToTwips = function (value) {
    return 24 * value / 5;
  };
  UnitConverter.pixelsToTwips = function (value) {
    return Math.round(UnitConverter.pixelsToTwipsF(value));
  };
  UnitConverter.pixelsToTwipsCustomDpi = function (value, dpi) {
    return Math.round(UnitConverter.pixelsToTwipsFCustomDpi(value, dpi));
  };
  UnitConverter.inchesToTwips = function (value) {
    return Math.round(UnitConverter.inchesToTwipsF(value));
  };
  UnitConverter.pointsToTwips = function (value) {
    return Math.round(UnitConverter.pointsToTwipsF(value));
  };
  UnitConverter.picasToTwips = function (value) {
    return Math.round(value * 1440 / UnitConverter.PICAS_PER_INCH);
  };
  UnitConverter.centimetersToTwips = function (value) {
    return Math.round(UnitConverter.centimetersToTwipsF(value));
  };
  UnitConverter.pixelsToTwipsF = function (value) {
    return value * 1440 / UnitConverter.DPI;
  };
  UnitConverter.pixelsToTwipsFCustomDpi = function (value, dpi) {
    return value * 1440 / dpi;
  };
  UnitConverter.inchesToTwipsF = function (value) {
    return value * 1440;
  };
  UnitConverter.pointsToTwipsF = function (value) {
    return value * 20;
  };
  UnitConverter.centimetersToTwipsF = function (value) {
    return value * 1440 / UnitConverter.CENTIMETERS_PER_INCH;
  };
  UnitConverter.twipsToDegrees = function (value) {
    return value / 60000;
  };
  UnitConverter.twipsToRadians = function (value) {
    return value / 60000 * Math.PI / 180;
  };
  UnitConverter.degreesToTwips = function (value) {
    return value * 60000;
  };
  UnitConverter.radiansToTwips = function (value) {
    return value * 60000 / Math.PI * 180;
  };
  UnitConverter.radiansToDegrees = function (value) {
    return value / Math.PI * 180;
  };
  UnitConverter.twipsToFD = function (value) {
    return Math.round(value * 2048 / 1875);
  };
  UnitConverter.fdToTwips = function (value) {
    return Math.round(value * 1875 / 2048);
  };
  UnitConverter.emuToTwips = function (val) {
    return val / 635;
  };
  UnitConverter.twipsToPixels = function (value) {
    return Math.round(UnitConverter.twipsToPixelsF(value));
  };
  UnitConverter.inchesToPixels = function (value) {
    return Math.round(UnitConverter.DPI * value);
  };
  UnitConverter.centimeterToPixel = function (value) {
    return Math.round(this.centimeterToPixelF(value));
  };
  UnitConverter.centimeterToPixelF = function (value) {
    return value * UnitConverter.DPI / UnitConverter.CENTIMETERS_PER_INCH;
  };
  UnitConverter.millimetersToPixel = function (value) {
    return Math.round(value / (UnitConverter.CENTIMETERS_PER_INCH / UnitConverter.DPI) / 10);
  };
  UnitConverter.pointsToPixels = function (value) {
    return Math.round(value * UnitConverter.DPI / 72);
  };
  UnitConverter.pointsToPixelsF = function (value) {
    return value * UnitConverter.DPI / 72;
  };
  UnitConverter.twipsToPixelsF = function (value) {
    return value * UnitConverter.DPI / 1440;
  };
  UnitConverter.pixelsToPoints = function (value) {
    return Math.round(value * 72 / UnitConverter.DPI);
  };
  UnitConverter.pixelsToPointsF = function (value) {
    return value * 72 / UnitConverter.DPI;
  };
  UnitConverter.twipsToPoints = function (value) {
    return Math.round(this.twipsToPointsF(value));
  };
  UnitConverter.twipsToPointsF = function (value) {
    return value / 20;
  };
  UnitConverter.twipsToInches = function (value) {
    return value / 1440;
  };
  UnitConverter.pixelsToInches = function (value) {
    return value / UnitConverter.DPI;
  };
  UnitConverter.twipsToCentimeters = function (value) {
    return value * UnitConverter.CENTIMETERS_PER_INCH / 1440;
  };
  UnitConverter.pixelToCentimeters = function (value) {
    return value * UnitConverter.CENTIMETERS_PER_INCH / UnitConverter.DPI;
  };
  UnitConverter.twipsToHundredthsOfMillimeter = function (value) {
    return Math.round(127 * value / 72);
  };
  UnitConverter.pixelsToHundredthsOfMillimeter = function (value) {
    return Math.round(2540 * value / UnitConverter.DPI);
  };
  UnitConverter.hundredthsOfMillimeterToTwips = function (value) {
    return 15 * value / 127;
  };
  UnitConverter.twipsToEmu = function (val) {
    return val * 635;
  };
  UnitConverter.twipsToDegree = function (value) {
    return value / 60000;
  };
  UnitConverter.DPI = 96;
  UnitConverter.CENTIMETERS_PER_INCH = 2.54;
  UnitConverter.PICAS_PER_INCH = 6;
  return UnitConverter;
}();
exports.UnitConverter = UnitConverter;