export var JSONEnumTableRow;
(function (JSONEnumTableRow) {
  JSONEnumTableRow[JSONEnumTableRow["GridBefore"] = 0] = "GridBefore";
  JSONEnumTableRow[JSONEnumTableRow["GridAfter"] = 1] = "GridAfter";
  JSONEnumTableRow[JSONEnumTableRow["WidthAfter"] = 2] = "WidthAfter";
  JSONEnumTableRow[JSONEnumTableRow["WidthBefore"] = 3] = "WidthBefore";
  JSONEnumTableRow[JSONEnumTableRow["TableRowPropertiesIndex"] = 4] = "TableRowPropertiesIndex";
  JSONEnumTableRow[JSONEnumTableRow["TablePropertiesException"] = 5] = "TablePropertiesException";
  JSONEnumTableRow[JSONEnumTableRow["Height"] = 6] = "Height";
  JSONEnumTableRow[JSONEnumTableRow["Cells"] = 7] = "Cells";
})(JSONEnumTableRow || (JSONEnumTableRow = {}));
export var JSONEnumTableRowProperty;
(function (JSONEnumTableRowProperty) {
  JSONEnumTableRowProperty[JSONEnumTableRowProperty["CellSpacing"] = 0] = "CellSpacing";
  JSONEnumTableRowProperty[JSONEnumTableRowProperty["Header"] = 1] = "Header";
  JSONEnumTableRowProperty[JSONEnumTableRowProperty["HideCellMark"] = 2] = "HideCellMark";
  JSONEnumTableRowProperty[JSONEnumTableRowProperty["CantSplit"] = 3] = "CantSplit";
  JSONEnumTableRowProperty[JSONEnumTableRowProperty["TableRowAlignment"] = 4] = "TableRowAlignment";
  JSONEnumTableRowProperty[JSONEnumTableRowProperty["UseValue"] = 5] = "UseValue";
  JSONEnumTableRowProperty[JSONEnumTableRowProperty["DivId"] = 6] = "DivId";
})(JSONEnumTableRowProperty || (JSONEnumTableRowProperty = {}));
export var JSONEnumClientTableRowInfo;
(function (JSONEnumClientTableRowInfo) {
  JSONEnumClientTableRowInfo[JSONEnumClientTableRowInfo["RowIndex"] = 0] = "RowIndex";
  JSONEnumClientTableRowInfo[JSONEnumClientTableRowInfo["Cells"] = 1] = "Cells";
  JSONEnumClientTableRowInfo[JSONEnumClientTableRowInfo["RowInfo"] = 2] = "RowInfo";
})(JSONEnumClientTableRowInfo || (JSONEnumClientTableRowInfo = {}));
export var JSONEnumClientTableRowFormatting;
(function (JSONEnumClientTableRowFormatting) {
  JSONEnumClientTableRowFormatting[JSONEnumClientTableRowFormatting["Height"] = 0] = "Height";
  JSONEnumClientTableRowFormatting[JSONEnumClientTableRowFormatting["GridBefore"] = 1] = "GridBefore";
  JSONEnumClientTableRowFormatting[JSONEnumClientTableRowFormatting["GridAfter"] = 2] = "GridAfter";
  JSONEnumClientTableRowFormatting[JSONEnumClientTableRowFormatting["WidthAfter"] = 3] = "WidthAfter";
  JSONEnumClientTableRowFormatting[JSONEnumClientTableRowFormatting["WidthBefore"] = 4] = "WidthBefore";
  JSONEnumClientTableRowFormatting[JSONEnumClientTableRowFormatting["TableRowProperties"] = 5] = "TableRowProperties";
  JSONEnumClientTableRowFormatting[JSONEnumClientTableRowFormatting["TablePropertiesException"] = 6] = "TablePropertiesException";
})(JSONEnumClientTableRowFormatting || (JSONEnumClientTableRowFormatting = {}));
export var JSONServerTableRowProperty;
(function (JSONServerTableRowProperty) {
  JSONServerTableRowProperty[JSONServerTableRowProperty["Height"] = 0] = "Height";
  JSONServerTableRowProperty[JSONServerTableRowProperty["CellSpacing"] = 1] = "CellSpacing";
  JSONServerTableRowProperty[JSONServerTableRowProperty["Header"] = 2] = "Header";
  JSONServerTableRowProperty[JSONServerTableRowProperty["HideCellMark"] = 3] = "HideCellMark";
  JSONServerTableRowProperty[JSONServerTableRowProperty["CantSplit"] = 4] = "CantSplit";
  JSONServerTableRowProperty[JSONServerTableRowProperty["TableRowAlignment"] = 5] = "TableRowAlignment";
  JSONServerTableRowProperty[JSONServerTableRowProperty["UseValue"] = 6] = "UseValue";
  JSONServerTableRowProperty[JSONServerTableRowProperty["GridAfter"] = 7] = "GridAfter";
  JSONServerTableRowProperty[JSONServerTableRowProperty["GridBefore"] = 8] = "GridBefore";
  JSONServerTableRowProperty[JSONServerTableRowProperty["WidthAfter"] = 9] = "WidthAfter";
  JSONServerTableRowProperty[JSONServerTableRowProperty["WidthBefore"] = 10] = "WidthBefore";
  JSONServerTableRowProperty[JSONServerTableRowProperty["DivId"] = 11] = "DivId";
})(JSONServerTableRowProperty || (JSONServerTableRowProperty = {}));