import { SubDocumentCollection } from '../../model-api/collections/sub-documents-collection';
import { SubDocumentClientApi } from '../sub-document';
export class SubDocumentCollectionClientApi extends SubDocumentCollection {
  constructor(core) {
    super(core);
    this._core = core;
  }
  get main() {
    return this._getItem(this._core.modelManager.model.mainSubDocument);
  }
  _getItem(coreItem) {
    return new SubDocumentClientApi(this._core, coreItem);
  }
}