import { Component, Input } from '@angular/core';
import { INaviString } from 'src/app/share/interfaces/NaviString/INaviString';

@Component({
    selector: 'app-table-container',
    templateUrl: './table-container.component.html',
    styleUrls: ['./table-container.component.scss'],
    standalone: false
})
export class TableContainerComponent {
  @Input() setHeader1: boolean = false;
  @Input() setHeader2: boolean = true;

  @Input() setFooter: boolean = false;
  @Input() setNaviString: boolean = false;
  @Input() naviString: INaviString[] = [];

  constructor() {}
}
