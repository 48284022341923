import { FixedInterval } from '@devexpress/utils/lib/intervals/fixed';
import { JSONCheckSpellingCommand } from '../common/model/json/enums/json-general-enums';
import { RichUtils } from '../common/model/rich-utils';
import { SimpleSentenceStructureBuilder, SimpleSentenceWord } from '../common/model/simple-sentence-model-builder';
import { SpellChecker, SpellingErrorType } from '../common/spelling/spell-checker';
export class ClientSpellChecker extends SpellChecker {
  sendCheckSpellingRequest(uncheckedIntervals) {
    if (this.settings.checkWordSpelling) {
      const callbacks = [];
      for (let curr of uncheckedIntervals) this.checkIntervalByWord(curr, callbacks);
      setTimeout(() => {
        for (let cb of callbacks) cb();
      }, 0);
    } else {}
  }
  checkIntervalByWord(currInterval, callbacks) {
    const intervals = FixedInterval.makeByConstInterval(currInterval);
    const sentences = new SimpleSentenceStructureBuilder(currInterval.info.textToCheck).build();
    let wordsCount = 0;
    const errors = [];
    const processResponce = () => {
      if (wordsCount == 0) {
        const aspxResponce = [{
          [JSONCheckSpellingCommand.StartPosition]: intervals.start,
          [JSONCheckSpellingCommand.EndPosition]: intervals.end,
          [JSONCheckSpellingCommand.SpellingErrors]: errors
        }];
        this.processResponse(aspxResponce);
      }
    };
    for (let sentence of sentences) {
      for (let word of sentence.words) {
        word = this.preprocessWord(word);
        if (/\d/.test(word.text)) {
          continue;
        }
        wordsCount++;
        callbacks.push(() => {
          this.settings.checkWordSpelling(word.text, (isCorrect, suggections) => {
            if (!isCorrect) {
              if (!suggections) suggections = [];
              errors.push({
                [JSONCheckSpellingCommand.ErrorStart]: word.position,
                [JSONCheckSpellingCommand.ErrorLength]: word.text.length,
                [JSONCheckSpellingCommand.ErrorWord]: word.text,
                [JSONCheckSpellingCommand.ErrorType]: SpellingErrorType.Misspelling,
                [JSONCheckSpellingCommand.Suggestions]: suggections.slice(0, this.settings.suggestionCount)
              });
            }
            wordsCount--;
            processResponce();
          });
        });
      }
    }
    if (wordsCount == 0) callbacks.push(() => processResponce());
  }
  preprocessWord(word) {
    if (word.text[0] == RichUtils.specialCharacters.LeftSingleQuote && word.text[word.text.length - 1] == RichUtils.specialCharacters.RightSingleQuote) return new SimpleSentenceWord(word.position + 1, word.text.substr(1, word.text.length - 2));
    return word;
  }
  sendAddWordRequest(word) {
    if (this.settings.addWordToDictionary) this.settings.addWordToDictionary(word);
  }
}