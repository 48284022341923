import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { ApiService } from 'src/app/share/services/api.service';

@Component({
    selector: 'dokumente-edit',
    templateUrl: './dokumente-edit.component.html',
    styleUrls: ['./dokumente-edit.component.scss'],
    standalone: false
})
export class DokumenteEditComponent {
  @Input() allowedFileExtensions: any[] = [];
  @Output() onAddClose: EventEmitter<any[]> = new EventEmitter(null);

  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('form', { static: false }) form: DxFormComponent;
  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;

  maxFileSize: number = 10485760;

  allowedMimeTypes = allowed_MimeTypes;

  filesToUpload: any[];
  error: string = null;
  title: string = 'bearbeiten';
  disableUpload: boolean = true;

  constructor(private apiService: ApiService) {
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
  }

  openNew() {
    this.disableUpload = true;
    this.filesToUpload = null;
    if (this.allowedFileExtensions.length === 0) {
      this.allowedFileExtensions = allowed_FileExtensions;
    }
    if (this.fileUploader) {
      this.fileUploader.instance.reset();
    }

    this.popup.instance.show();
  }

  onValueChanged(e) {
    this.error = null;
    this.filesToUpload = e.value;
    if (this.filesToUpload != undefined && this.filesToUpload != null && this.filesToUpload.length > 0) {
      this.disableUpload = false;
    } else {
      this.disableUpload = true;
    }
  }

  onClickSave() {
    for (let index = 0; index < this.filesToUpload.length; index++) {
      const file = this.filesToUpload[index];
      if (file !== undefined && file !== null) {
        const validateResult = this.validateFile(file);
        if (validateResult.isValid !== true) {
          this.error = validateResult.error;
          return;
        }
      }
    }

    this.onAddClose.next(this.filesToUpload);
    this.popup.instance.hide();
  }

  private validateFile(file) {
    let validateResult = {
      isValid: true,
      error: '',
    };

    if (file.size > this.maxFileSize) {
      validateResult.isValid = false;
      validateResult.error =
        'Datei ist zu groß, die maximale Dateigröße beträgt ' + this.maxFileSize / 1024 / 1024 + ' Megabyte! (' + file.name + ')';
    }

    const fileNameParts = file.name.split('.');
    const toFind = '.' + fileNameParts[fileNameParts.length - 1];
    const allowedExt = this.allowedFileExtensions.find((x) => x.toLowerCase() === toFind.toLowerCase());
    if (!allowedExt) {
      validateResult.isValid = false;
      validateResult.error = 'Unzulässiger Dateityp! (' + file.name + ')';
    }

    return validateResult;
  }

  onClickClose() {
    this.disableUpload = true;
    this.popup.instance.hide();
  }
}

const allowed_FileExtensions: string[] = [
  '.txt',
  '.rtf',
  '.rtx',
  '.pdf',
  '.doc',
  '.dot',
  '.docx',
  '.dotx',
  '.docm',
  '.dotm',
  '.xls',
  '.xlt',
  '.xla',
  '.xlsx',
  '.xltx',
  '.xlsm',
  '.xltm',
  '.xlam',
  '.xlsb',
  '.bmp',
  '.gif',
  '.jpeg',
  '.jpg',
  '.jpe',
  '.png',
  '.tif',
  '.tiff',
  '.csv',
  '.msg',
  '.eml',
];

const allowed_MimeTypes: string =
  'image/*,' +
  'text/plain,' +
  'text/rtf,' +
  'text/richtext,' +
  'application/pdf,' +
  'application/rtf,' +
  'application/msexcel,' +
  'application/vnd.ms-excel,' +
  'application/vnd.ms-outlook,' +
  'application/msword,' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template,' +
  'application/vnd.ms-word.document.macroEnabled.12,' +
  'application/vnd.ms-word.template.macroEnabled.12,' +
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,' +
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template,' +
  'application/vnd.ms-excel.sheet.macroEnabled.12,' +
  'application/vnd.ms-excel.template.macroEnabled.12,' +
  'application/vnd.ms-excel.addin.macroEnabled.12,' +
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12,' +
  'message/rfc822';
