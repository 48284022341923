import { TableBordersBase } from './table-border-base';
export class TableCellBorders extends TableBordersBase {
  equals(obj) {
    return super.equals(obj) && this.topLeftDiagonal.equals(obj.topLeftDiagonal) && this.topRightDiagonal.equals(obj.topRightDiagonal);
  }
  copyFrom(obj) {
    super.copyFrom(obj);
    this.topLeftDiagonal = obj.topLeftDiagonal.clone();
    this.topRightDiagonal = obj.topRightDiagonal.clone();
  }
  clone() {
    var result = new TableCellBorders();
    result.copyFrom(this);
    return result;
  }
  static create(top, right, bottom, left, topLeftDiagonal, topRightDiagonal) {
    let result = new TableCellBorders();
    result.top = top;
    result.right = right;
    result.bottom = bottom;
    result.left = left;
    result.topLeftDiagonal = topLeftDiagonal;
    result.topRightDiagonal = topRightDiagonal;
    return result;
  }
}