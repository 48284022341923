"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListUtils = void 0;
var min_max_1 = require("../class/min-max");
var comparers_1 = require("./comparers");
var ListUtils = function () {
  function ListUtils() {}
  ListUtils.remove = function (list, element) {
    var index = list.indexOf(element, 0);
    if (index >= 0) list.splice(index, 1);
  };
  ListUtils.removeBy = function (list, callback) {
    var len = list.length;
    for (var index = 0; index < len; index++) {
      if (callback(list[index], index)) return list.splice(index, 1)[0];
    }
    return null;
  };
  ListUtils.shallowCopy = function (list) {
    return list.slice();
  };
  ListUtils.deepCopy = function (list) {
    return ListUtils.map(list, function (val) {
      return val.clone();
    });
  };
  ListUtils.initByValue = function (numElements, initValue) {
    var result = [];
    for (; numElements > 0; numElements--) result.push(initValue);
    return result;
  };
  ListUtils.initByCallback = function (numElements, initCallback) {
    var result = [];
    for (var index = 0; index < numElements; index++) result.push(initCallback(index));
    return result;
  };
  ListUtils.forEachOnInterval = function (interval, callback) {
    var end = interval.end;
    for (var index = interval.start; index < end; index++) callback(index);
  };
  ListUtils.reverseForEachOnInterval = function (interval, callback) {
    var start = interval.start;
    for (var index = interval.end - 1; index >= start; index--) callback(index);
  };
  ListUtils.reducedMap = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var result = [];
    for (var index = startIndex; index < endIndex; index++) {
      var newItem = callback(list[index], index);
      if (newItem !== null) result.push(newItem);
    }
    return result;
  };
  ListUtils.filter = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var result = [];
    for (var index = startIndex; index < endIndex; index++) {
      var item = list[index];
      if (callback(item, index)) result.push(item);
    }
    return result;
  };
  ListUtils.map = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var result = [];
    for (var index = startIndex; index < endIndex; index++) result.push(callback(list[index], index));
    return result;
  };
  ListUtils.indexBy = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    for (var ind = startIndex; ind < endIndex; ind++) {
      if (callback(list[ind], ind)) return ind;
    }
    return -1;
  };
  ListUtils.reverseIndexBy = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = list.length - 1;
    }
    if (endIndex === void 0) {
      endIndex = 0;
    }
    for (var ind = startIndex; ind >= endIndex; ind--) {
      if (callback(list[ind], ind)) return ind;
    }
    return -1;
  };
  ListUtils.elementBy = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var ind = ListUtils.indexBy(list, callback, startIndex, endIndex);
    return ind < 0 ? null : list[ind];
  };
  ListUtils.reverseElementBy = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = list.length - 1;
    }
    if (endIndex === void 0) {
      endIndex = 0;
    }
    var ind = ListUtils.reverseIndexBy(list, callback, startIndex, endIndex);
    return ind < 0 ? null : list[ind];
  };
  ListUtils.last = function (list) {
    return list[list.length - 1];
  };
  ListUtils.setLast = function (list, newVal) {
    return list[list.length - 1] = newVal;
  };
  ListUtils.incLast = function (list) {
    return ++list[list.length - 1];
  };
  ListUtils.decLast = function (list) {
    return --list[list.length - 1];
  };
  ListUtils.equals = function (a, b) {
    return a.length === b.length && ListUtils.allOf2(a, b, function (a, b) {
      return a.equals(b);
    });
  };
  ListUtils.equalsByReference = function (a, b) {
    var aLen = a.length;
    var bLen = a.length;
    if (aLen !== bLen) return false;
    for (var i = 0; i < aLen; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };
  ListUtils.unique = function (list, cmp, equal, finalizeObj) {
    if (equal === void 0) {
      equal = cmp;
    }
    if (finalizeObj === void 0) {
      finalizeObj = function () {};
    }
    var len = list.length;
    if (len === 0) return [];
    list = list.sort(cmp);
    var prevValue = list[0];
    var result = ListUtils.reducedMap(list, function (v) {
      if (equal(prevValue, v) !== 0) {
        prevValue = v;
        return v;
      }
      finalizeObj(v);
      return null;
    }, 1, len);
    result.unshift(list[0]);
    return result;
  };
  ListUtils.uniqueNumber = function (list) {
    list = list.sort(comparers_1.Comparers.number);
    var prevValue = Number.NaN;
    for (var i = list.length - 1; i >= 0; i--) {
      if (prevValue === list[i]) list.splice(i, 1);else prevValue = list[i];
    }
    return list;
  };
  ListUtils.forEach = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    for (var index = startIndex; index < endIndex; index++) callback(list[index], index);
  };
  ListUtils.forEach2 = function (listA, listB, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = listA.length;
    }
    for (var index = startIndex; index < endIndex; index++) callback(listA[index], listB[index], index);
  };
  ListUtils.reverseForEach = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = list.length - 1;
    }
    if (endIndex === void 0) {
      endIndex = 0;
    }
    for (var index = startIndex; index >= endIndex; index--) callback(list[index], index);
  };
  ListUtils.reverseIndexOf = function (list, element, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = list.length - 1;
    }
    if (endIndex === void 0) {
      endIndex = 0;
    }
    for (var index = startIndex; index >= endIndex; index--) {
      if (list[index] === element) return index;
    }
    return -1;
  };
  ListUtils.accumulate = function (list, initAccValue, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var acc = initAccValue;
    for (var ind = startIndex; ind < endIndex; ind++) acc = callback(acc, list[ind], ind);
    return acc;
  };
  ListUtils.accumulateNumber = function (list, callback, initAccValue, startIndex, endIndex) {
    if (initAccValue === void 0) {
      initAccValue = 0;
    }
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var acc = initAccValue;
    for (var ind = startIndex; ind < endIndex; ind++) acc += callback(list[ind], ind, acc);
    return acc;
  };
  ListUtils.anyOf = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    for (var index = startIndex; index < endIndex; index++) {
      if (callback(list[index], index)) return true;
    }
    return false;
  };
  ListUtils.unsafeAnyOf = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    for (var index = startIndex; index < endIndex; index++) {
      var currResult = callback(list[index], index);
      if (currResult) return currResult;
    }
    return null;
  };
  ListUtils.reverseAnyOf = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = list.length - 1;
    }
    if (endIndex === void 0) {
      endIndex = 0;
    }
    for (var index = startIndex; index >= endIndex; index--) {
      if (callback(list[index], index)) return true;
    }
    return false;
  };
  ListUtils.unsafeReverseAnyOf = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = list.length - 1;
    }
    if (endIndex === void 0) {
      endIndex = 0;
    }
    for (var index = startIndex; index >= endIndex; index--) {
      var currResult = callback(list[index], index);
      if (currResult) return currResult;
    }
    return null;
  };
  ListUtils.anyOf2 = function (listA, listB, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = listA.length;
    }
    for (var index = startIndex; index < endIndex; index++) {
      if (callback(listA[index], listB[index], index)) return true;
    }
    return false;
  };
  ListUtils.allOf = function (list, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    for (var index = startIndex; index < endIndex; index++) {
      if (!callback(list[index], index)) return false;
    }
    return true;
  };
  ListUtils.allOf2 = function (listA, listB, callback, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = listA.length;
    }
    for (var index = startIndex; index < endIndex; index++) {
      if (!callback(listA[index], listB[index], index)) return false;
    }
    return true;
  };
  ListUtils.allOfOnInterval = function (interval, callback) {
    var endIndex = interval.end;
    for (var index = interval.start; index < endIndex; index++) {
      if (!callback(index)) return false;
    }
    return true;
  };
  ListUtils.addListOnTail = function (resultList, addedList) {
    for (var i = 0, elem = void 0; elem = addedList[i]; i++) resultList.push(elem);
    return resultList;
  };
  ListUtils.joinLists = function (converter) {
    var lists = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      lists[_i - 1] = arguments[_i];
    }
    return ListUtils.accumulate(lists, [], function (accList, list) {
      ListUtils.addListOnTail(accList, converter(list));
      return accList;
    });
  };
  ListUtils.push = function (list, element) {
    list.push(element);
    return list;
  };
  ListUtils.countIf = function (list, callback) {
    return ListUtils.accumulateNumber(list, function (elem, ind) {
      return callback(elem, ind) ? 1 : 0;
    });
  };
  ListUtils.clear = function (list) {
    list.splice(0);
  };
  ListUtils.merge = function (list, cmp, shouldMerge, merge, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    list = list.slice(startIndex, endIndex);
    if (endIndex - startIndex < 2) return list;
    list = list.sort(cmp);
    var prevObj = list[startIndex];
    var result = [prevObj];
    for (var ind = startIndex + 1; ind < endIndex; ind++) {
      var obj = list[ind];
      if (shouldMerge(prevObj, obj)) merge(prevObj, obj);else {
        prevObj = obj;
        result.push(prevObj);
      }
    }
    return result;
  };
  ListUtils.min = function (list, getValue, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var res = ListUtils.minExtended(list, getValue, startIndex, endIndex);
    return res ? res.minElement : null;
  };
  ListUtils.max = function (list, getValue, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var res = ListUtils.maxExtended(list, getValue, startIndex, endIndex);
    return res ? res.maxElement : null;
  };
  ListUtils.minMax = function (list, getValue, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    var res = ListUtils.minMaxExtended(list, getValue, startIndex, endIndex);
    return res ? new min_max_1.MinMax(res.minElement, res.maxElement) : null;
  };
  ListUtils.minExtended = function (list, getValue, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    if (list.length === 0) return null;
    var minElement = list[startIndex];
    var minValue = getValue(minElement);
    for (var index = startIndex + 1; index < endIndex; index++) {
      var elem = list[index];
      var elemValue = getValue(elem);
      if (elemValue < minValue) {
        minValue = elemValue;
        minElement = elem;
      }
    }
    return new min_max_1.ExtendedMin(minElement, minValue);
  };
  ListUtils.maxExtended = function (list, getValue, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    if (list.length === 0) return null;
    var maxElement = list[startIndex];
    var maxValue = getValue(maxElement);
    for (var index = startIndex + 1; index < endIndex; index++) {
      var elem = list[index];
      var elemValue = getValue(elem);
      if (elemValue > maxValue) {
        maxValue = elemValue;
        maxElement = elem;
      }
    }
    return new min_max_1.ExtendedMax(maxElement, maxValue);
  };
  ListUtils.minMaxExtended = function (list, getValue, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    if (list.length === 0) return null;
    var minElement = list[startIndex];
    var maxElement = minElement;
    var minValue = getValue(minElement);
    var maxValue = minValue;
    for (var index = startIndex + 1; index < endIndex; index++) {
      var elem = list[index];
      var elemValue = getValue(elem);
      if (elemValue < minValue) {
        minValue = elemValue;
        minElement = elem;
      } else if (elemValue > maxValue) {
        maxValue = elemValue;
        maxElement = elem;
      }
    }
    return new min_max_1.ExtendedMinMax(minElement, minValue, maxElement, maxValue);
  };
  ListUtils.minByCmp = function (list, cmp, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    if (list.length === 0) return null;
    var found = list[startIndex];
    for (var index = startIndex + 1; index < endIndex; index++) {
      var elem = list[index];
      if (cmp(elem, found) < 0) found = elem;
    }
    return found;
  };
  ListUtils.maxByCmp = function (list, cmp, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    if (list.length === 0) return null;
    var found = list[startIndex];
    for (var index = startIndex + 1; index < endIndex; index++) {
      var elem = list[index];
      if (cmp(elem, found) > 0) found = elem;
    }
    return found;
  };
  ListUtils.minMaxByCmp = function (list, cmp, startIndex, endIndex) {
    if (startIndex === void 0) {
      startIndex = 0;
    }
    if (endIndex === void 0) {
      endIndex = list.length;
    }
    if (list.length === 0) return null;
    var min = list[startIndex];
    var max = min;
    for (var index = startIndex + 1; index < endIndex; index++) {
      var elem = list[index];
      var res = cmp(elem, min);
      if (res > 0) max = elem;else if (res < 0) min = elem;
    }
    return new min_max_1.MinMax(min, max);
  };
  return ListUtils;
}();
exports.ListUtils = ListUtils;