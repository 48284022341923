import { DomUtils } from '@devexpress/utils/lib/utils/dom';
import { DocumentRenderer } from '../../../../../canvas/renderes/common/document-renderer';
export class PageVisibleInfoHelper {
  static showPageVisibleInfoElement(cache, getText, pageIndex) {
    PageVisibleInfoHelper.createAtLeast(pageIndex);
    let currElement = PageVisibleInfoHelper.pageVisibleInfoElements[pageIndex];
    currElement.innerHTML = getText();
    DomUtils.hideNode(currElement);
    DocumentRenderer.getServiceContainerCore(cache[pageIndex].page).appendChild(currElement);
  }
  static hidePageVisibleInfoElement(pageIndex) {
    const element = PageVisibleInfoHelper.pageVisibleInfoElements[pageIndex];
    if (element) {
      PageVisibleInfoHelper.pageVisibleInfoElements.splice(pageIndex, 1);
      DomUtils.hideNode(element);
    }
  }
  static createAtLeast(n) {
    while (PageVisibleInfoHelper.pageVisibleInfoElements.length <= n) PageVisibleInfoHelper.pageVisibleInfoElements.push(PageVisibleInfoHelper.createPageVisibleInfoElement());
  }
  static createPageVisibleInfoElement() {
    const element = DocumentRenderer.renderContainer("dxre-debug-page-visible-info-element");
    element.style.left = "5px";
    element.style.top = "5px";
    element.style.width = "150px";
    element.style.height = "50px";
    element.style.zIndex = "100";
    return element;
  }
}
PageVisibleInfoHelper.pageVisibleInfoElements = [];