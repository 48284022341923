export var DrawingEffectContainerType;
(function (DrawingEffectContainerType) {
  DrawingEffectContainerType[DrawingEffectContainerType["Sibling"] = 0] = "Sibling";
  DrawingEffectContainerType[DrawingEffectContainerType["Tree"] = 1] = "Tree";
})(DrawingEffectContainerType || (DrawingEffectContainerType = {}));
export var DrawingUnderlineFillType;
(function (DrawingUnderlineFillType) {
  DrawingUnderlineFillType[DrawingUnderlineFillType["Fill"] = 0] = "Fill";
  DrawingUnderlineFillType[DrawingUnderlineFillType["FollowsText"] = 1] = "FollowsText";
})(DrawingUnderlineFillType || (DrawingUnderlineFillType = {}));
export var DrawingFillType;
(function (DrawingFillType) {
  DrawingFillType[DrawingFillType["Automatic"] = 0] = "Automatic";
  DrawingFillType[DrawingFillType["None"] = 1] = "None";
  DrawingFillType[DrawingFillType["Solid"] = 2] = "Solid";
  DrawingFillType[DrawingFillType["Gradient"] = 3] = "Gradient";
  DrawingFillType[DrawingFillType["Group"] = 4] = "Group";
  DrawingFillType[DrawingFillType["Pattern"] = 5] = "Pattern";
  DrawingFillType[DrawingFillType["Picture"] = 6] = "Picture";
})(DrawingFillType || (DrawingFillType = {}));
export var DrawingBulletType;
(function (DrawingBulletType) {
  DrawingBulletType[DrawingBulletType["Automatic"] = 0] = "Automatic";
  DrawingBulletType[DrawingBulletType["Color"] = 1] = "Color";
  DrawingBulletType[DrawingBulletType["Size"] = 2] = "Size";
  DrawingBulletType[DrawingBulletType["Typeface"] = 3] = "Typeface";
  DrawingBulletType[DrawingBulletType["Common"] = 4] = "Common";
})(DrawingBulletType || (DrawingBulletType = {}));
export var DrawingStrokeUnderlineType;
(function (DrawingStrokeUnderlineType) {
  DrawingStrokeUnderlineType[DrawingStrokeUnderlineType["Automatic"] = 0] = "Automatic";
  DrawingStrokeUnderlineType[DrawingStrokeUnderlineType["Outline"] = 1] = "Outline";
  DrawingStrokeUnderlineType[DrawingStrokeUnderlineType["FollowsText"] = 2] = "FollowsText";
})(DrawingStrokeUnderlineType || (DrawingStrokeUnderlineType = {}));
export var PresetCameraType;
(function (PresetCameraType) {
  PresetCameraType[PresetCameraType["None"] = 0] = "None";
  PresetCameraType[PresetCameraType["LegacyObliqueTopLeft"] = 1] = "LegacyObliqueTopLeft";
  PresetCameraType[PresetCameraType["LegacyObliqueTop"] = 2] = "LegacyObliqueTop";
  PresetCameraType[PresetCameraType["LegacyObliqueTopRight"] = 3] = "LegacyObliqueTopRight";
  PresetCameraType[PresetCameraType["LegacyObliqueLeft"] = 4] = "LegacyObliqueLeft";
  PresetCameraType[PresetCameraType["LegacyObliqueFront"] = 5] = "LegacyObliqueFront";
  PresetCameraType[PresetCameraType["LegacyObliqueRight"] = 6] = "LegacyObliqueRight";
  PresetCameraType[PresetCameraType["LegacyObliqueBottomLeft"] = 7] = "LegacyObliqueBottomLeft";
  PresetCameraType[PresetCameraType["LegacyObliqueBottom"] = 8] = "LegacyObliqueBottom";
  PresetCameraType[PresetCameraType["LegacyObliqueBottomRight"] = 9] = "LegacyObliqueBottomRight";
  PresetCameraType[PresetCameraType["LegacyPerspectiveTopLeft"] = 10] = "LegacyPerspectiveTopLeft";
  PresetCameraType[PresetCameraType["LegacyPerspectiveTop"] = 11] = "LegacyPerspectiveTop";
  PresetCameraType[PresetCameraType["LegacyPerspectiveTopRight"] = 12] = "LegacyPerspectiveTopRight";
  PresetCameraType[PresetCameraType["LegacyPerspectiveLeft"] = 13] = "LegacyPerspectiveLeft";
  PresetCameraType[PresetCameraType["LegacyPerspectiveFront"] = 14] = "LegacyPerspectiveFront";
  PresetCameraType[PresetCameraType["LegacyPerspectiveRight"] = 15] = "LegacyPerspectiveRight";
  PresetCameraType[PresetCameraType["LegacyPerspectiveBottomLeft"] = 16] = "LegacyPerspectiveBottomLeft";
  PresetCameraType[PresetCameraType["LegacyPerspectiveBottom"] = 17] = "LegacyPerspectiveBottom";
  PresetCameraType[PresetCameraType["LegacyPerspectiveBottomRight"] = 18] = "LegacyPerspectiveBottomRight";
  PresetCameraType[PresetCameraType["OrthographicFront"] = 19] = "OrthographicFront";
  PresetCameraType[PresetCameraType["IsometricTopUp"] = 20] = "IsometricTopUp";
  PresetCameraType[PresetCameraType["IsometricTopDown"] = 21] = "IsometricTopDown";
  PresetCameraType[PresetCameraType["IsometricBottomUp"] = 22] = "IsometricBottomUp";
  PresetCameraType[PresetCameraType["IsometricBottomDown"] = 23] = "IsometricBottomDown";
  PresetCameraType[PresetCameraType["IsometricLeftUp"] = 24] = "IsometricLeftUp";
  PresetCameraType[PresetCameraType["IsometricLeftDown"] = 25] = "IsometricLeftDown";
  PresetCameraType[PresetCameraType["IsometricRightUp"] = 26] = "IsometricRightUp";
  PresetCameraType[PresetCameraType["IsometricRightDown"] = 27] = "IsometricRightDown";
  PresetCameraType[PresetCameraType["IsometricOffAxis1Left"] = 28] = "IsometricOffAxis1Left";
  PresetCameraType[PresetCameraType["IsometricOffAxis1Right"] = 29] = "IsometricOffAxis1Right";
  PresetCameraType[PresetCameraType["IsometricOffAxis1Top"] = 30] = "IsometricOffAxis1Top";
  PresetCameraType[PresetCameraType["IsometricOffAxis2Left"] = 31] = "IsometricOffAxis2Left";
  PresetCameraType[PresetCameraType["IsometricOffAxis2Right"] = 32] = "IsometricOffAxis2Right";
  PresetCameraType[PresetCameraType["IsometricOffAxis2Top"] = 33] = "IsometricOffAxis2Top";
  PresetCameraType[PresetCameraType["IsometricOffAxis3Left"] = 34] = "IsometricOffAxis3Left";
  PresetCameraType[PresetCameraType["IsometricOffAxis3Right"] = 35] = "IsometricOffAxis3Right";
  PresetCameraType[PresetCameraType["IsometricOffAxis3Bottom"] = 36] = "IsometricOffAxis3Bottom";
  PresetCameraType[PresetCameraType["IsometricOffAxis4Left"] = 37] = "IsometricOffAxis4Left";
  PresetCameraType[PresetCameraType["IsometricOffAxis4Right"] = 38] = "IsometricOffAxis4Right";
  PresetCameraType[PresetCameraType["IsometricOffAxis4Bottom"] = 39] = "IsometricOffAxis4Bottom";
  PresetCameraType[PresetCameraType["ObliqueTopLeft"] = 40] = "ObliqueTopLeft";
  PresetCameraType[PresetCameraType["ObliqueTop"] = 41] = "ObliqueTop";
  PresetCameraType[PresetCameraType["ObliqueTopRight"] = 42] = "ObliqueTopRight";
  PresetCameraType[PresetCameraType["ObliqueLeft"] = 43] = "ObliqueLeft";
  PresetCameraType[PresetCameraType["ObliqueRight"] = 44] = "ObliqueRight";
  PresetCameraType[PresetCameraType["ObliqueBottomLeft"] = 45] = "ObliqueBottomLeft";
  PresetCameraType[PresetCameraType["ObliqueBottom"] = 46] = "ObliqueBottom";
  PresetCameraType[PresetCameraType["ObliqueBottomRight"] = 47] = "ObliqueBottomRight";
  PresetCameraType[PresetCameraType["PerspectiveFront"] = 48] = "PerspectiveFront";
  PresetCameraType[PresetCameraType["PerspectiveLeft"] = 49] = "PerspectiveLeft";
  PresetCameraType[PresetCameraType["PerspectiveRight"] = 50] = "PerspectiveRight";
  PresetCameraType[PresetCameraType["PerspectiveAbove"] = 51] = "PerspectiveAbove";
  PresetCameraType[PresetCameraType["PerspectiveBelow"] = 52] = "PerspectiveBelow";
  PresetCameraType[PresetCameraType["PerspectiveAboveLeftFacing"] = 53] = "PerspectiveAboveLeftFacing";
  PresetCameraType[PresetCameraType["PerspectiveAboveRightFacing"] = 54] = "PerspectiveAboveRightFacing";
  PresetCameraType[PresetCameraType["PerspectiveContrastingLeftFacing"] = 55] = "PerspectiveContrastingLeftFacing";
  PresetCameraType[PresetCameraType["PerspectiveContrastingRightFacing"] = 56] = "PerspectiveContrastingRightFacing";
  PresetCameraType[PresetCameraType["PerspectiveHeroicLeftFacing"] = 57] = "PerspectiveHeroicLeftFacing";
  PresetCameraType[PresetCameraType["PerspectiveHeroicRightFacing"] = 58] = "PerspectiveHeroicRightFacing";
  PresetCameraType[PresetCameraType["PerspectiveHeroicExtremeLeftFacing"] = 59] = "PerspectiveHeroicExtremeLeftFacing";
  PresetCameraType[PresetCameraType["PerspectiveHeroicExtremeRightFacing"] = 60] = "PerspectiveHeroicExtremeRightFacing";
  PresetCameraType[PresetCameraType["PerspectiveRelaxed"] = 61] = "PerspectiveRelaxed";
  PresetCameraType[PresetCameraType["PerspectiveRelaxedModerately"] = 62] = "PerspectiveRelaxedModerately";
})(PresetCameraType || (PresetCameraType = {}));
export var LightRigDirection;
(function (LightRigDirection) {
  LightRigDirection[LightRigDirection["None"] = 0] = "None";
  LightRigDirection[LightRigDirection["Bottom"] = 1] = "Bottom";
  LightRigDirection[LightRigDirection["BottomLeft"] = 2] = "BottomLeft";
  LightRigDirection[LightRigDirection["BottomRight"] = 3] = "BottomRight";
  LightRigDirection[LightRigDirection["Left"] = 4] = "Left";
  LightRigDirection[LightRigDirection["Right"] = 5] = "Right";
  LightRigDirection[LightRigDirection["Top"] = 6] = "Top";
  LightRigDirection[LightRigDirection["TopLeft"] = 7] = "TopLeft";
  LightRigDirection[LightRigDirection["TopRight"] = 8] = "TopRight";
})(LightRigDirection || (LightRigDirection = {}));
export var LightRigPreset;
(function (LightRigPreset) {
  LightRigPreset[LightRigPreset["None"] = 0] = "None";
  LightRigPreset[LightRigPreset["LegacyFlat1"] = 1] = "LegacyFlat1";
  LightRigPreset[LightRigPreset["LegacyFlat2"] = 2] = "LegacyFlat2";
  LightRigPreset[LightRigPreset["LegacyFlat3"] = 3] = "LegacyFlat3";
  LightRigPreset[LightRigPreset["LegacyFlat4"] = 4] = "LegacyFlat4";
  LightRigPreset[LightRigPreset["LegacyNormal1"] = 5] = "LegacyNormal1";
  LightRigPreset[LightRigPreset["LegacyNormal2"] = 6] = "LegacyNormal2";
  LightRigPreset[LightRigPreset["LegacyNormal3"] = 7] = "LegacyNormal3";
  LightRigPreset[LightRigPreset["LegacyNormal4"] = 8] = "LegacyNormal4";
  LightRigPreset[LightRigPreset["LegacyHarsh1"] = 9] = "LegacyHarsh1";
  LightRigPreset[LightRigPreset["LegacyHarsh2"] = 10] = "LegacyHarsh2";
  LightRigPreset[LightRigPreset["LegacyHarsh3"] = 11] = "LegacyHarsh3";
  LightRigPreset[LightRigPreset["LegacyHarsh4"] = 12] = "LegacyHarsh4";
  LightRigPreset[LightRigPreset["ThreePt"] = 13] = "ThreePt";
  LightRigPreset[LightRigPreset["Balanced"] = 14] = "Balanced";
  LightRigPreset[LightRigPreset["Soft"] = 15] = "Soft";
  LightRigPreset[LightRigPreset["Harsh"] = 16] = "Harsh";
  LightRigPreset[LightRigPreset["Flood"] = 17] = "Flood";
  LightRigPreset[LightRigPreset["Contrasting"] = 18] = "Contrasting";
  LightRigPreset[LightRigPreset["Morning"] = 19] = "Morning";
  LightRigPreset[LightRigPreset["Sunrise"] = 20] = "Sunrise";
  LightRigPreset[LightRigPreset["Sunset"] = 21] = "Sunset";
  LightRigPreset[LightRigPreset["Chilly"] = 22] = "Chilly";
  LightRigPreset[LightRigPreset["Freezing"] = 23] = "Freezing";
  LightRigPreset[LightRigPreset["Flat"] = 24] = "Flat";
  LightRigPreset[LightRigPreset["TwoPt"] = 25] = "TwoPt";
  LightRigPreset[LightRigPreset["Glow"] = 26] = "Glow";
  LightRigPreset[LightRigPreset["BrightRoom"] = 27] = "BrightRoom";
})(LightRigPreset || (LightRigPreset = {}));
export var PresetMaterialType;
(function (PresetMaterialType) {
  PresetMaterialType[PresetMaterialType["None"] = 0] = "None";
  PresetMaterialType[PresetMaterialType["LegacyMatte"] = 1] = "LegacyMatte";
  PresetMaterialType[PresetMaterialType["LegacyPlastic"] = 2] = "LegacyPlastic";
  PresetMaterialType[PresetMaterialType["LegacyMetal"] = 3] = "LegacyMetal";
  PresetMaterialType[PresetMaterialType["LegacyWireframe"] = 4] = "LegacyWireframe";
  PresetMaterialType[PresetMaterialType["Matte"] = 5] = "Matte";
  PresetMaterialType[PresetMaterialType["Plastic"] = 6] = "Plastic";
  PresetMaterialType[PresetMaterialType["Metal"] = 7] = "Metal";
  PresetMaterialType[PresetMaterialType["WarmMatte"] = 8] = "WarmMatte";
  PresetMaterialType[PresetMaterialType["TranslucentPowder"] = 9] = "TranslucentPowder";
  PresetMaterialType[PresetMaterialType["Powder"] = 10] = "Powder";
  PresetMaterialType[PresetMaterialType["DarkEdge"] = 11] = "DarkEdge";
  PresetMaterialType[PresetMaterialType["SoftEdge"] = 12] = "SoftEdge";
  PresetMaterialType[PresetMaterialType["Clear"] = 13] = "Clear";
  PresetMaterialType[PresetMaterialType["Flat"] = 14] = "Flat";
  PresetMaterialType[PresetMaterialType["SoftMetal"] = 15] = "SoftMetal";
})(PresetMaterialType || (PresetMaterialType = {}));
export var DrawingText3DType;
(function (DrawingText3DType) {
  DrawingText3DType[DrawingText3DType["Automatic"] = 0] = "Automatic";
  DrawingText3DType[DrawingText3DType["Shape3D"] = 1] = "Shape3D";
  DrawingText3DType[DrawingText3DType["FlatText"] = 2] = "FlatText";
})(DrawingText3DType || (DrawingText3DType = {}));
export var PresetBevelType;
(function (PresetBevelType) {
  PresetBevelType[PresetBevelType["None"] = 0] = "None";
  PresetBevelType[PresetBevelType["RelaxedInset"] = 1] = "RelaxedInset";
  PresetBevelType[PresetBevelType["Circle"] = 2] = "Circle";
  PresetBevelType[PresetBevelType["Slope"] = 3] = "Slope";
  PresetBevelType[PresetBevelType["Cross"] = 4] = "Cross";
  PresetBevelType[PresetBevelType["Angle"] = 5] = "Angle";
  PresetBevelType[PresetBevelType["SoftRound"] = 6] = "SoftRound";
  PresetBevelType[PresetBevelType["Convex"] = 7] = "Convex";
  PresetBevelType[PresetBevelType["CoolSlant"] = 8] = "CoolSlant";
  PresetBevelType[PresetBevelType["Divot"] = 9] = "Divot";
  PresetBevelType[PresetBevelType["Riblet"] = 10] = "Riblet";
  PresetBevelType[PresetBevelType["HardEdge"] = 11] = "HardEdge";
  PresetBevelType[PresetBevelType["ArtDeco"] = 12] = "ArtDeco";
})(PresetBevelType || (PresetBevelType = {}));