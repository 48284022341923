export var JSONStylesProperty;
(function (JSONStylesProperty) {
  JSONStylesProperty[JSONStylesProperty["Character"] = 0] = "Character";
  JSONStylesProperty[JSONStylesProperty["Paragraph"] = 1] = "Paragraph";
  JSONStylesProperty[JSONStylesProperty["Table"] = 2] = "Table";
  JSONStylesProperty[JSONStylesProperty["TableCell"] = 3] = "TableCell";
  JSONStylesProperty[JSONStylesProperty["NumberingList"] = 4] = "NumberingList";
  JSONStylesProperty[JSONStylesProperty["Caches"] = 5] = "Caches";
  JSONStylesProperty[JSONStylesProperty["MAX_VALUE"] = 6] = "MAX_VALUE";
})(JSONStylesProperty || (JSONStylesProperty = {}));
export var JSONStylesGalleryHeader;
(function (JSONStylesGalleryHeader) {
  JSONStylesGalleryHeader[JSONStylesGalleryHeader["Character"] = 6] = "Character";
  JSONStylesGalleryHeader[JSONStylesGalleryHeader["Paragraph"] = 7] = "Paragraph";
  JSONStylesGalleryHeader[JSONStylesGalleryHeader["Table"] = 8] = "Table";
})(JSONStylesGalleryHeader || (JSONStylesGalleryHeader = {}));
export var JSONStyleBaseProperty;
(function (JSONStyleBaseProperty) {
  JSONStyleBaseProperty[JSONStyleBaseProperty["StyleName"] = 0] = "StyleName";
  JSONStyleBaseProperty[JSONStyleBaseProperty["Deleted"] = 1] = "Deleted";
  JSONStyleBaseProperty[JSONStyleBaseProperty["Hidden"] = 2] = "Hidden";
  JSONStyleBaseProperty[JSONStyleBaseProperty["ParentStyleName"] = 3] = "ParentStyleName";
  JSONStyleBaseProperty[JSONStyleBaseProperty["Semihidden"] = 4] = "Semihidden";
  JSONStyleBaseProperty[JSONStyleBaseProperty["LocalizedStyleName"] = 5] = "LocalizedStyleName";
  JSONStyleBaseProperty[JSONStyleBaseProperty["IsDefault"] = 6] = "IsDefault";
  JSONStyleBaseProperty[JSONStyleBaseProperty["Base64EncodedImage"] = 7] = "Base64EncodedImage";
  JSONStyleBaseProperty[JSONStyleBaseProperty["MAX_VALUE"] = 8] = "MAX_VALUE";
})(JSONStyleBaseProperty || (JSONStyleBaseProperty = {}));
export var JSONCharacterStyleProperty;
(function (JSONCharacterStyleProperty) {
  JSONCharacterStyleProperty[JSONCharacterStyleProperty["LinkedStyleName"] = 8] = "LinkedStyleName";
  JSONCharacterStyleProperty[JSONCharacterStyleProperty["CharacterPropertiesCacheIndex"] = 9] = "CharacterPropertiesCacheIndex";
})(JSONCharacterStyleProperty || (JSONCharacterStyleProperty = {}));
export var JSONParagraphStyleProperty;
(function (JSONParagraphStyleProperty) {
  JSONParagraphStyleProperty[JSONParagraphStyleProperty["CharacterPropertiesCacheIndex"] = 8] = "CharacterPropertiesCacheIndex";
  JSONParagraphStyleProperty[JSONParagraphStyleProperty["ParagraphPropertiesCacheIndex"] = 9] = "ParagraphPropertiesCacheIndex";
  JSONParagraphStyleProperty[JSONParagraphStyleProperty["LinkedStyleName"] = 10] = "LinkedStyleName";
  JSONParagraphStyleProperty[JSONParagraphStyleProperty["NextParagraphStyleName"] = 11] = "NextParagraphStyleName";
  JSONParagraphStyleProperty[JSONParagraphStyleProperty["AutoUpdate"] = 12] = "AutoUpdate";
  JSONParagraphStyleProperty[JSONParagraphStyleProperty["Tabs"] = 13] = "Tabs";
  JSONParagraphStyleProperty[JSONParagraphStyleProperty["NumberingListIndex"] = 14] = "NumberingListIndex";
  JSONParagraphStyleProperty[JSONParagraphStyleProperty["ListLevelIndex"] = 15] = "ListLevelIndex";
})(JSONParagraphStyleProperty || (JSONParagraphStyleProperty = {}));
export var JSONNumberingListStyleProperty;
(function (JSONNumberingListStyleProperty) {
  JSONNumberingListStyleProperty[JSONNumberingListStyleProperty["NumberingListIndex"] = 8] = "NumberingListIndex";
})(JSONNumberingListStyleProperty || (JSONNumberingListStyleProperty = {}));
export var JSONTableStyleProperty;
(function (JSONTableStyleProperty) {
  JSONTableStyleProperty[JSONTableStyleProperty["BaseConditionalStyle"] = 8] = "BaseConditionalStyle";
  JSONTableStyleProperty[JSONTableStyleProperty["ConditionalStyles"] = 9] = "ConditionalStyles";
})(JSONTableStyleProperty || (JSONTableStyleProperty = {}));
export var JSONStyleLinkCommandBaseProperty;
(function (JSONStyleLinkCommandBaseProperty) {
  JSONStyleLinkCommandBaseProperty[JSONStyleLinkCommandBaseProperty["StyleName"] = 0] = "StyleName";
})(JSONStyleLinkCommandBaseProperty || (JSONStyleLinkCommandBaseProperty = {}));