import { TableCellBordersHistoryItem, TableCellCellMarginsHistoryItem, TableCellPreferredWidthHistoryItem, TableCellShadingInfoHistoryItem } from '../../../common/model/history/items/tables/table-cell-properties-history-items';
import { TableCellUtils, TableConditionalFormattingCalculator } from '../../../common/model/tables/table-utils';
import { TableCellBorders } from '../../../common/model/borders/table-cell-borders';
import { InputPosition } from '../../../common/selection/input-position';
import { Selection } from '../../../common/selection/selection';
import { AnchorObjectsPositionInfo, DocumentLayout } from '../../../common/layout/document-layout';
import { SelectionState } from '../../../common/selection/selection-state';
import { ShadingInfo } from '../../../common/model/shadings/shading-info';
import { convertToIntervalApi } from '../interval';
import { TableElementBase } from './table-element-base';
import { TableRowApi } from './table-row';
import { getCharacterProperties, setCharacterProperties } from '../character-properties';
import { TableCellBordersApi } from './table-borders';
import { ApiParametersChecker } from '../api-utils/parameter-checker';
export class TableCellApi extends TableElementBase {
  constructor(processor, subDocument, tablePosition, parentRow) {
    super(processor, subDocument);
    this._tablePosition = tablePosition;
    this._parentRow = parentRow || new TableRowApi(this._processor, this._subDocument, this._tablePosition);
  }
  get index() {
    return this._tablePosition.cellIndex;
  }
  get interval() {
    return convertToIntervalApi(this._tablePosition.cell.interval);
  }
  get parentRow() {
    return this._parentRow;
  }
  get width() {
    return this._getWidth(this._tablePosition.cell.preferredWidth);
  }
  set width(value) {
    this._validateWidth(value);
    const {
      table,
      rowIndex,
      cellIndex
    } = this._tablePosition;
    const modelWidth = this._getModelWidth(value);
    const historyItem = new TableCellPreferredWidthHistoryItem(this._modelManipulator, this._subDocument, table.index, rowIndex, cellIndex, modelWidth);
    this._history.addAndRedo(historyItem);
  }
  get backgroundColor() {
    const shadingInfo = this._tablePosition.cell.getActualShadingInfo(this._tablePosition.cell.properties);
    return this._getBackgroundColor(shadingInfo);
  }
  set backgroundColor(value) {
    const {
      table,
      rowIndex,
      cellIndex
    } = this._tablePosition;
    const color = this._validateColor(value, 1, 'backgroundColor');
    const shadingInfo = ShadingInfo.createByColor(color);
    const historyItem = new TableCellShadingInfoHistoryItem(this._modelManipulator, this._subDocument, table.index, rowIndex, cellIndex, shadingInfo, true);
    this._history.addAndRedo(historyItem);
  }
  get contentHorizontalAlignment() {
    const alignment = this._getStartHorizontalAlignment(this._tablePosition.cell);
    const paragraphs = this._subDocument.getParagraphsByInterval(this._tablePosition.cell.interval);
    const allAlignmentsSame = paragraphs.every(paragraph => paragraph.getParagraphMergedProperties().alignment === alignment);
    if (!allAlignmentsSame) return null;
    return Number(alignment);
  }
  set contentHorizontalAlignment(value) {
    this._validateContentHorizontalAlignment(value);
    this._history.addTransaction(() => this._setCellHorizontalAlignment(this._tablePosition.cell, value));
  }
  get contentVerticalAlignment() {
    return Number(this._tablePosition.cell.properties.verticalAlignment);
  }
  set contentVerticalAlignment(value) {
    this._validateContentVerticalAlignment(value);
    this._setCellVerticalAlignment(this._tablePosition, value);
  }
  get characterProperties() {
    return getCharacterProperties(this._processor, this._subDocument, this._tablePosition.cell.interval);
  }
  set characterProperties(value) {
    setCharacterProperties(this._processor, this._subDocument, this._tablePosition.cell.interval, value);
  }
  get borders() {
    const {
      cell
    } = this._tablePosition;
    const tableBorders = cell.getActualBorders(cell.properties);
    return this._getBordersApi(TableCellBordersApi, tableBorders);
  }
  set borders(value) {
    const {
      table,
      rowIndex,
      cellIndex
    } = this._tablePosition;
    const tableBorders = this._validateBorders(TableCellBorders, value);
    const bordersList = [tableBorders.top, tableBorders.right, tableBorders.bottom, tableBorders.left];
    const newUsesList = bordersList.map(border => !!border ? true : void 0);
    const historyItem = new TableCellBordersHistoryItem(this._modelManipulator, this._subDocument, table.index, rowIndex, cellIndex, bordersList, newUsesList);
    this._history.addAndRedo(historyItem);
  }
  get margins() {
    const {
      cell
    } = this._tablePosition;
    const margins = cell.getActualMargins(this._modelManipulator.model);
    return this._getMarginsApi(margins);
  }
  set margins(value) {
    const {
      table,
      rowIndex,
      cellIndex
    } = this._tablePosition;
    const margins = this._validateMargins(value, 'margins');
    const marginsList = [margins.top, margins.right, margins.bottom, margins.left];
    const newUsesList = marginsList.map(margin => !!margin ? true : void 0);
    const historyItem = new TableCellCellMarginsHistoryItem(this._modelManipulator, this._subDocument, table.index, rowIndex, cellIndex, marginsList, newUsesList);
    this._history.addAndRedo(historyItem);
  }
  split(columnCount, rowCount) {
    const rowIndexDescriptor = ApiParametersChecker.numberDescriptor(`columnCount`, val => val, 0);
    ApiParametersChecker.check(columnCount, 1, false, [rowIndexDescriptor]);
    const cellIndexDescriptor = ApiParametersChecker.numberDescriptor(`rowCount`, val => val, 0);
    ApiParametersChecker.check(rowCount, 2, false, [cellIndexDescriptor]);
    const selectionState = SelectionState.getDefault(this._subDocument).setInterval(this._tablePosition.cell.interval);
    const documentLayout = new DocumentLayout(new AnchorObjectsPositionInfo(this._processor.modelManager.model));
    const selection = new Selection(this._modelManipulator.model, documentLayout, this._subDocument);
    selection.setState(selectionState);
    const inputPosition = new InputPosition(selection);
    this._processor.beginUpdate();
    this._history.addTransaction(() => {
      TableCellUtils.splitTableCellsHorizontallyCore(this._processor, this._subDocument, this._tablePosition, columnCount, inputPosition);
      TableCellUtils.splitTableCellsVerticallyCore(this._processor, this._subDocument, this._tablePosition, rowCount, columnCount, inputPosition);
      TableConditionalFormattingCalculator.updateTable(this._processor.modelManager, this._tablePosition.table, selection.activeSubDocument);
    });
    this._processor.endUpdate();
  }
}