import { ShadingInfo } from '../../../../../../model/shadings/shading-info';
import { ShadingPattern } from '../../../../../../model/shadings/shading-pattern';
import { TableCellProperties, TableCellPropertyDescriptor } from '../../../../../../model/tables/properties/table-cell-properties';
import { TableCellMergingState } from '../../../../../../model/tables/secondary-structures/table-base-structures';
import { TableWidthUnit } from '../../../../../../model/tables/secondary-structures/table-units';
export class RtfTableCellProperties {
  constructor() {
    this.horizontalMerging = TableCellMergingState.None;
    this.shadingPattern = ShadingPattern.Clear;
    this.preferredWidth = TableWidthUnit.createDefault();
    this.coreProperties = new TableCellProperties();
  }
  copyFrom(obj) {
    this.coreProperties.copyFrom(obj.coreProperties);
    this.right = obj.right;
    this.horizontalMerging = obj.horizontalMerging;
    this.shadingPattern = obj.shadingPattern;
    this.foreColor = obj.foreColor;
    this.backColor = obj.backColor;
    this.verticalMerging = obj.verticalMerging;
    this.preferredWidth = obj.preferredWidth;
  }
  apply(cell) {
    if (this.verticalMerging) cell.verticalMerging = this.verticalMerging;
    if (this.backColor || this.foreColor) {
      cell.properties.setValue(TableCellPropertyDescriptor.shadingInfo, new ShadingInfo(this.shadingPattern, this.backColor ? this.backColor : cell.properties.shadingInfo.backColor, this.foreColor ? this.foreColor : cell.properties.shadingInfo.foreColor));
    }
    cell.preferredWidth = this.preferredWidth;
  }
}