import { HistoryItem } from './history-item';
export class PositionBasedHistoryItem extends HistoryItem {
  get boundSubDocument() {
    return this.subDocPos.subDocument;
  }
  get position() {
    return this.subDocPos.position;
  }
  constructor(modelManipulator, subDocPos) {
    super(modelManipulator);
    this.subDocPos = subDocPos;
  }
}