import { BookmarkCollection } from '../../model-api/collections/bookmark-collection';
import { BookmarkClientApi } from '../bookmark';
export class BookmarkCollectionClientApi extends BookmarkCollection {
  constructor(core, subDocument) {
    super(core, subDocument);
    this._core = core;
  }
  _getItem(coreItem) {
    return new BookmarkClientApi(this._core, this._subDocument, coreItem);
  }
}