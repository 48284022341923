"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Equals = exports.Comparers = void 0;
var Comparers = function () {
  function Comparers() {}
  Comparers.number = function (a, b) {
    return a - b;
  };
  Comparers.string = function (a, b) {
    return a === b ? 0 : a > b ? 1 : -1;
  };
  Comparers.stringIgnoreCase = function (a, b) {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a === b ? 0 : a > b ? 1 : -1;
  };
  return Comparers;
}();
exports.Comparers = Comparers;
var Equals = function () {
  function Equals() {}
  Equals.simpleType = function (a, b) {
    return a === b;
  };
  Equals.object = function (a, b) {
    return a && b && (a === b || a.equals(b));
  };
  return Equals;
}();
exports.Equals = Equals;