import { Component, Input, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-sollstellungen-kind',
  templateUrl: './sollstellungen-kind.component.html',
  styleUrls: ['./sollstellungen-kind.component.scss'],
  standalone: false,
})
export class SollstellungenKindComponent {
  @ViewChild('sollstellungenDataGrid', { static: false }) sollstellungenDataGrid: DxDataGridComponent;

  @Input() type: string = 'kind';
  @Input() set id(id: number) {
    if (id !== null) {
      this.entityId = id;
      this.load(id);
    }
  }

  entityId: number;
  dataSource: any = [];
  old_dataSource: string;

  constructor(private apiService: ApiService) {
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.onContentReady = this.onContentReady.bind(this);
    this.load = this.load.bind(this);
    this.calculateSummary = this.calculateSummary.bind(this);
  }

  onSelectionChanged(e) {}

  onToolbarPreparing(e) {
    var searchPanel = e.toolbarOptions.items.filter((item) => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }

    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      cssClass: 'datagridToolbarNewBtn',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'pulldown',
        text: 'Aktualisieren',
        onClick: this.onClickRefresh.bind(this),
      },
    });
  }

  onContentReady(e) {}

  load(entityId: number) {
    this.apiService.post<any>(RouteKeys.Bgb.sollstellungen_search, { entityTyp: this.type, entityId: entityId }).subscribe({
      next: (response) => {
        this.dataSource = response;
        this.old_dataSource = JSON.stringify(this.dataSource);
        this.sollstellungenDataGrid.instance.refresh();
      },
      error: (err) => {
        throw err.message;
      },
    });
  }

  abs(value: number) {
    return Math.abs(value);
  }

  calculateMonatJahr(rowData) {
    return formatNumber(rowData.monat, 'de', '2.0-0') + '/' + rowData.jahr;
  }

  onClickRefresh(e) {
    this.load(this.entityId);
  }

  onCellPrepared(e) {
    if (e.rowType == 'group' && e.column.name === 'buchung.diff') {
      let cells = e.cellElement.children as Element[];
      let cell = Array.from(cells).find((x) => Array.from(x.classList).some((x) => x === 'dx-datagrid-summary-item')) as HTMLElement;
      if (cell !== null && cell !== undefined) {
        if (e.summaryItems !== null && e.summaryItems !== undefined && e.summaryItems.length > 0) {
          if (e.summaryItems[0].value < 0) {
            cell.style.color = 'red';
          } else if (e.summaryItems[0].value === 0) {
            cell.style.visibility = 'hidden';
          } else {
            cell.style.color = 'green';
          }
        }
      }
    }
  }

  calculateSummary(options) {
    if (options.summaryProcess === 'calculate' && options.groupIndex === (this.type === 'vertrag' ? 0 : 1)) {
      if (options.name === 'betreuungsumfang' || options.name === 'einkommen') {
        options.totalValue = options.value;
      }
    }
  }

  customizeTextBlank(cellInfo) {
    return '';
  }
}
