export class SpellChecker {
  constructor(misspelledIntervalsChangesListener, settings) {
    this.misspelledIntervalsChangesListener = misspelledIntervalsChangesListener;
    this.settings = settings;
  }
  dispose() {
    clearTimeout(this.checkCoreId);
  }
  initialize(subDocument) {
    this.subDocument = subDocument;
    this.intervalsManager = subDocument.spellCheckerIntervalsManager;
  }
  processResponse(checkedIntervals) {
    this.intervalsManager.applyCheckResults(checkedIntervals);
    this.updateMisspelledBoxes();
    this.checkCore();
  }
  check() {
    if (this.settings.isEnabled) {
      this.checkCoreId = setTimeout(() => {
        this.checkCore();
      }, 0);
    }
  }
  getSelectedMisspelledInterval(selectionIntervals) {
    return this.intervalsManager.getSelectedMisspelledInterval(selectionIntervals);
  }
  findNextMisspelledInterval(position) {
    return this.intervalsManager.findNextMisspelledInterval(position);
  }
  ignore(misspelledInterval) {
    this.intervalsManager.addIgnoredInterval(misspelledInterval.start, misspelledInterval.end, misspelledInterval.errorInfo.word);
    this.intervalsManager.deleteMisspelledIntervalsByPositions(misspelledInterval.start, misspelledInterval.end);
    this.updateMisspelledBoxes();
  }
  ignoreAll(misspelledInterval) {
    this.intervalsManager.ignoreAll(misspelledInterval.errorInfo.word);
    this.updateMisspelledBoxes();
  }
  addWord(misspelledInterval) {
    let word = misspelledInterval.errorInfo.word;
    this.intervalsManager.removeIntervalsWithErrorByWord(word);
    this.updateMisspelledBoxes();
    this.sendAddWordRequest(word);
  }
  getIntervalsWithErrorByWord(word) {
    return this.intervalsManager.getIntervalsWithErrorByWord(word);
  }
  isInProgress() {
    return this.intervalsManager.getUncheckedIntervalsCount() > 0;
  }
  getMisspelledIntervals() {
    return this.intervalsManager.getMisspelledIntervals();
  }
  onModelIntervalChanged(start, length, isSeparator) {
    if (this.settings.isEnabled) this.intervalsManager.onModelIntervalChanged(start, length, isSeparator);
  }
  onCurrentSelectedWordChanged() {
    this.check();
  }
  onLayoutChanged() {
    this.updateMisspelledBoxes();
  }
  checkCore() {
    this.intervalsManager.initializeUncheckedIntervals();
    const intervalsToCheck = this.intervalsManager.getIntervalsToCheck();
    if (intervalsToCheck.length > 0) this.sendCheckSpellingRequest(intervalsToCheck);
  }
  updateMisspelledBoxes() {
    if (this.settings.isEnabled) this.misspelledIntervalsChangesListener.setMisspelledSelectionIntervals(this.getMisspelledIntervals());
  }
}
export var SpellingErrorType;
(function (SpellingErrorType) {
  SpellingErrorType[SpellingErrorType["Unknown"] = 0] = "Unknown";
  SpellingErrorType[SpellingErrorType["Misspelling"] = 1] = "Misspelling";
  SpellingErrorType[SpellingErrorType["Repeating"] = 2] = "Repeating";
  SpellingErrorType[SpellingErrorType["Syntax"] = 3] = "Syntax";
})(SpellingErrorType || (SpellingErrorType = {}));
export class SpellingErrorInfo {
  constructor(errorType, suggestions, word) {
    this.errorType = errorType;
    this.suggestions = suggestions;
    this.word = word;
  }
}