import { __awaiter } from "tslib";
import { ElementDestination } from '../destination';
export class AltChunkPropertiesDestination extends ElementDestination {
  get elementHandlerTable() {
    return {};
  }
  processElementOpen(_reader) {
    return __awaiter(this, void 0, void 0, function* () {});
  }
  processElementClose(_reader) {}
}