import { FixedInterval } from "@devexpress/utils/lib/intervals/fixed";
import { RelationCollectionExporter } from "../relations/relation-collection";
import { BaseSubDocumentExporter } from "./base-sub-document";
import { NoteType } from "../../../../../../common/model/footnotes/footnote";
import { TranslationTables } from "../../../translation-table/translation-tables";
import { ContentType } from "../../../utils/constants";
class NoteExporter extends BaseSubDocumentExporter {
  constructor(data, fileName) {
    super(data, `word/${fileName}`);
    this.relsFilePath = `word/_rels/${fileName}.rels`;
    this.fileName = fileName;
  }
  createRelationExporter() {
    return new RelationCollectionExporter(this.data, this.relsFilePath);
  }
  isMainContent() {
    return false;
  }
  fillWriterCore() {
    this.exportNotes();
    this.addRels(this.fileName);
    this.data.contentTypesExporter.registerContentTypeOverride(`/word/${this.fileName}`, this.contentType);
  }
  exportNotes() {
    for (const [note, id] of this.noteToIdMap.entries()) {
      this.pushSubDocument(note);
      this.writer.writeWpStartElement(this.childElement);
      const type = note.info.noteType;
      if (type !== NoteType.Normal) this.writer.writeWpStringAttr('type', TranslationTables.noteTypeTable.exportMap[type].mlValue.openXmlValue);
      this.writer.writeWpIntAttr('id', id);
      this.exportNoteContent();
      this.writer.endElement();
      this.popSubDocument();
    }
  }
  exportNoteContent() {
    this.init();
    this.exportSection(this.data.model.sections[0], new FixedInterval(0, this.subDocument.getDocumentEndPosition()));
  }
  pushSubDocument(subDocument) {
    this.subDocument = subDocument;
  }
  popSubDocument() {
    const subDocument = this.subDocument;
    this.subDocument = null;
    return subDocument;
  }
}
export class FootNoteExporter extends NoteExporter {
  constructor(data) {
    super(data, 'footnotes.xml');
  }
  get rootElement() {
    return 'footnotes';
  }
  get childElement() {
    return 'footnote';
  }
  get contentType() {
    return ContentType.footnotes;
  }
  get noteToIdMap() {
    return this.data.footNotes;
  }
  addRels(fileName) {
    this.data.mainSubDocumentRelations.footNoteRelationsTable['RelFootnotes'] = fileName;
  }
}
export class EndNoteExporter extends NoteExporter {
  constructor(data) {
    super(data, 'endnotes.xml');
  }
  get rootElement() {
    return 'endnotes';
  }
  get childElement() {
    return 'endnote';
  }
  get contentType() {
    return ContentType.endnotes;
  }
  get noteToIdMap() {
    return this.data.endNotes;
  }
  addRels(fileName) {
    this.data.mainSubDocumentRelations.endNoteRelationsTable['RelEndnotes'] = fileName;
  }
}