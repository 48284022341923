import { TablePropertiesMergerBorderBottom, TablePropertiesMergerBorderHorizontal, TablePropertiesMergerBorderLeft, TablePropertiesMergerBorderRight, TablePropertiesMergerBorderTop, TablePropertiesMergerBorderVertical, TablePropertiesMergerCellSpacing, TablePropertiesMergerHorizontalAlignment, TablePropertiesMergerIndent, TablePropertiesMergerLayoutType, TablePropertiesMergerMarginBottom, TablePropertiesMergerMarginLeft, TablePropertiesMergerMarginRight, TablePropertiesMergerMarginTop, TablePropertiesMergerShadingInfo, TablePropertiesMergerStyleColumnBandSize, TablePropertiesMergerStyleRowBandSize } from '../../../../model/tables/properties-mergers/table-properties-merger';
import { ConditionalTableStyleFormatting } from '../../../../model/tables/secondary-structures/table-base-structures';
import { RtfTablePropertiesMergerBase } from './rtf-table-properties-merger-base';
export class RtfTablePropertiesMerger extends RtfTablePropertiesMergerBase {
  constructor(model) {
    super(model);
  }
  get defaultProperties() {
    return this.model.defaultTableProperties;
  }
  getMergedProperties(table) {
    return this.merge(table.properties, table.style, table, null, ConditionalTableStyleFormatting.WholeTable);
  }
  getStyleMergedProperties(style, tableStyle, conditionalTFormatting) {
    return this.merge(style.tableProperties, tableStyle, null, null, conditionalTFormatting);
  }
  merge(source, tableStyle, _table, _tablePropertiesException, conditionalFormatting) {
    const result = source.clone();
    result.indent = new TablePropertiesMergerIndent().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.cellSpacing = new TablePropertiesMergerCellSpacing().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.borders.left = new TablePropertiesMergerBorderLeft().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.borders.right = new TablePropertiesMergerBorderRight().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.borders.top = new TablePropertiesMergerBorderTop().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.borders.bottom = new TablePropertiesMergerBorderBottom().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.borders.insideVertical = new TablePropertiesMergerBorderVertical().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.borders.insideHorizontal = new TablePropertiesMergerBorderHorizontal().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.cellMargins.left = new TablePropertiesMergerMarginLeft().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.cellMargins.right = new TablePropertiesMergerMarginRight().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.cellMargins.top = new TablePropertiesMergerMarginTop().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.cellMargins.bottom = new TablePropertiesMergerMarginBottom().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.layoutType = new TablePropertiesMergerLayoutType().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.shadingInfo = new TablePropertiesMergerShadingInfo().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.tableStyleColumnBandSize = new TablePropertiesMergerStyleColumnBandSize().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.tableStyleRowBandSize = new TablePropertiesMergerStyleRowBandSize().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    result.tableRowAlignment = new TablePropertiesMergerHorizontalAlignment().getProperty(source, tableStyle, conditionalFormatting, this.defaultProperties);
    return result;
  }
}