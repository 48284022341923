import { HistoryItem } from '../base/history-item';
import { Flag } from '@devexpress/utils/lib/class/flag';
import { HistoryItemDirection } from './history-item-direction';
export class SelectionHistoryItem extends HistoryItem {
  canBeMerged() {
    return true;
  }
  constructor(modelManipulator, selection, oldState, newState, directionValue = HistoryItemDirection.OnRedoAndUndo) {
    super(modelManipulator);
    this.selection = selection;
    this.oldState = oldState;
    this.newState = newState;
    this.directionFlag = new Flag(directionValue);
  }
  redo() {
    if (this.directionFlag.get(HistoryItemDirection.OnRedo)) this.selection.setState(this.newState);
  }
  undo() {
    if (this.directionFlag.get(HistoryItemDirection.OnUndo)) this.selection.setState(this.oldState);
  }
}