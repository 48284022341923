import { FixedInterval } from '@devexpress/utils/lib/intervals/fixed';
import { DomUtils } from '@devexpress/utils/lib/utils/dom';
import { ListUtils } from '@devexpress/utils/lib/utils/list';
import { ImportedTextRunInfo } from '../containers/runs';
import { HtmlTagImporterBase } from './base';
import { splitByLines } from '../../../../utils/utils';
export class HtmlTextNodeImporter extends HtmlTagImporterBase {
  elementTag() {
    return "TextNode";
  }
  importBefore() {
    let text = this.element.nodeValue || DomUtils.getInnerText(this.element);
    if (!this.enablePreserveLineBreaks) text = text.replace(/\r\n|\r|\n/g, ' ');
    if (text && text.length) {
      if (/^ +$/.test(text) && this.element.parentNode && this.element.parentNode.tagName == "TD") {
        const parentLevel = this.importer.levelInfo[this.importer.levelInfo.length - 2];
        if (parentLevel.childElements[0] == this.element || ListUtils.last(parentLevel.childElements) == this.element) {
          return;
        }
      }
      if (this.enablePreserveLineBreaks) {
        const lines = splitByLines(text);
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].length) {
            this.addRun(new ImportedTextRunInfo(this.importer.modelManager.model, this.importer.measurer, lines[i], this.importer.htmlImporterMaskedCharacterProperties.getBundleFrom(this.element, new FixedInterval(this.importer.currPosition, lines[i].length))));
          }
          if (i < lines.length - 1) this.importer.addParagraphRun(this.element.parentElement || this.element.parentNode);
        }
      } else {
        this.addRun(new ImportedTextRunInfo(this.importer.modelManager.model, this.importer.measurer, text, this.importer.htmlImporterMaskedCharacterProperties.getBundleFrom(this.element, new FixedInterval(this.importer.currPosition, text.length))));
      }
    }
  }
  isImportChildren() {
    return false;
  }
  importAfter() {}
}