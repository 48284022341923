<dx-popup #editPasswordPopup [fullScreen]="false" title="Passwort ändern" [showTitle]="true" [dragEnabled]="false" 
          [hideOnOutsideClick]="false" [showCloseButton]="true" [(visible)]="popupVisible" [maxWidth]="400" [maxHeight]="490">

  <div *dxTemplate="let data of 'content'">

    <div class="popup-container-content">
      <dx-form #editDataForm class="data-form" [(formData)]="dataSource" [scrollingEnabled]="true">
        <dxi-item dataField="altPasswort" [label]="{ text: 'Aktuelles Passwort' }" [editorOptions]="{ mode: 'password'}">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="passwort" [label]="{ text: 'Neues Passwort' }" [editorOptions]="{ mode: 'password', maxLength: configService.passwordPolicy.maxLaenge }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="configService.passwordPolicy.minLaenge" [message]="configService.passwordPolicy.minLaengeMessage"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="configService.passwordPolicy.minZiffern" [message]="configService.passwordPolicy.minZiffernMessage"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="configService.passwordPolicy.minKleinBuchstaben" [message]="configService.passwordPolicy.minKleinBuchstabenMessage"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="configService.passwordPolicy.minGrossBuchstaben" [message]="configService.passwordPolicy.minGrossBuchstabenMessage"></dxi-validation-rule>
            <dxi-validation-rule type="pattern" [pattern]="configService.passwordPolicy.minSonderzeichen" [message]="configService.passwordPolicy.minSonderzeichenMessage"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="passwortConfirm" [label]="{ text: 'Neues Passwort bestätigen' }" [editorOptions]="{ mode: 'password', maxLength: configService.passwordPolicy.maxLaenge }">
            <dxi-validation-rule type="required"></dxi-validation-rule>         
            <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison" message="Die beiden Passwörter stimmen nicht überein!"></dxi-validation-rule>
        </dxi-item>

        <dxi-item cssClass="psw-richtlinien-container" [template]="'pswRichtlinieTemplate'">
        </dxi-item>

        <div *dxTemplate="let data of 'pswRichtlinieTemplate'">
          <dxi-item>
            <span class="psw-richtlinien" [innerHTML] = 'configService.passwordPolicy.text'></span>
          </dxi-item>
        </div>
      </dx-form>
      
      <div class="err-msg">
        <span class="msg-text color-error">{{msg}}</span>
      </div>
    </div>
  </div>

  <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
    [options]="{ text: 'Speichern', type: 'default', stylingMode: 'contained', icon: 'save', disabled: isLoading, onClick: onClickSave }">
  </dxi-popup-toolbar-item>

  <dxi-popup-toolbar-item widget="dxButton" location="after" toolbar="bottom"
    [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', disabled: isLoading, onClick: onClickClose }">
  </dxi-popup-toolbar-item>
</dx-popup>