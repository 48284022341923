import { ListUtils } from '@devexpress/utils/lib/utils/list';
class UndefinedRunError extends Error {
  constructor() {
    super();
    this.message = 'Undefined run';
  }
}
class OutOfOffsetError extends Error {
  constructor() {
    super();
    this.message = 'Offset >= run.length';
  }
}
export class Chunk {
  constructor(startLogPosition, textBuffer, isLast) {
    this.textBuffer = "";
    this.textRuns = [];
    this.startLogPosition = startLogPosition;
    this.textBuffer = textBuffer;
    this.isLast = isLast;
  }
  getEndPosition() {
    return this.startLogPosition.value + this.textBuffer.length;
  }
  getRunText(run) {
    return this.textBuffer.substr(run.startOffset, run.getLength());
  }
  getTextInChunk(offsetAtStartChunk, length) {
    return this.textBuffer.substr(offsetAtStartChunk, length);
  }
  splitRun(runIndex, offset) {
    var run = this.textRuns[runIndex];
    if (!run) throw new UndefinedRunError();
    if (offset >= run.getLength()) throw new OutOfOffsetError();
    var newRun = run.createSimularity(run.startOffset + offset, run.getLength() - offset, run.paragraph, run.characterStyle, run.maskedCharacterProperties);
    run.setLength(offset);
    this.textRuns.splice(runIndex + 1, 0, newRun);
  }
  removeRun(runIndex) {
    const run = this.textRuns[runIndex];
    if (!run) throw new UndefinedRunError();
    if (runIndex === this.textRuns.length - 1) {
      this.textBuffer = this.textBuffer.slice(0, run.startOffset);
      this.textRuns.pop();
    } else {
      this.textBuffer = [this.textBuffer.slice(0, run.startOffset), this.textBuffer.slice(run.startOffset + run.getLength())].join('');
      this.textRuns = [...this.textRuns.slice(0, runIndex), ...this.textRuns.slice(runIndex + 1)];
    }
  }
  clone(subDocument) {
    const result = new Chunk(subDocument.positionManager.registerPosition(this.startLogPosition.value), this.textBuffer.slice(0), this.isLast);
    result.textRuns = ListUtils.map(this.textRuns, r => r.cloneToNewSubDocument(subDocument));
    return result;
  }
}