"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TouchUtils = void 0;
var browser_1 = require("../browser");
var common_1 = require("./common");
var TouchUtils = function () {
  function TouchUtils() {}
  TouchUtils.onEventAttachingToDocument = function (eventName, func) {
    if (browser_1.Browser.MacOSMobilePlatform && TouchUtils.isTouchEventName(eventName)) {
      if (!TouchUtils.documentTouchHandlers[eventName]) TouchUtils.documentTouchHandlers[eventName] = [];
      TouchUtils.documentTouchHandlers[eventName].push(func);
      return TouchUtils.documentEventAttachingAllowed;
    }
    return true;
  };
  TouchUtils.isTouchEventName = function (eventName) {
    return browser_1.Browser.WebKitTouchUI && (eventName.indexOf('touch') > -1 || eventName.indexOf('gesture') > -1);
  };
  TouchUtils.isTouchEvent = function (evt) {
    return browser_1.Browser.WebKitTouchUI && common_1.isDefined(evt.changedTouches);
  };
  TouchUtils.getEventX = function (evt) {
    return browser_1.Browser.IE ? evt.pageX : evt.changedTouches[0].pageX;
  };
  TouchUtils.getEventY = function (evt) {
    return browser_1.Browser.IE ? evt.pageY : evt.changedTouches[0].pageY;
  };
  TouchUtils.touchMouseDownEventName = browser_1.Browser.WebKitTouchUI ? 'touchstart' : browser_1.Browser.Edge && browser_1.Browser.MSTouchUI && window.PointerEvent ? 'pointerdown' : 'mousedown';
  TouchUtils.touchMouseUpEventName = browser_1.Browser.WebKitTouchUI ? 'touchend' : browser_1.Browser.Edge && browser_1.Browser.MSTouchUI && window.PointerEvent ? 'pointerup' : 'mouseup';
  TouchUtils.touchMouseMoveEventName = browser_1.Browser.WebKitTouchUI ? 'touchmove' : browser_1.Browser.Edge && browser_1.Browser.MSTouchUI && window.PointerEvent ? 'pointermove' : 'mousemove';
  TouchUtils.msTouchDraggableClassName = 'dxMSTouchDraggable';
  TouchUtils.documentTouchHandlers = {};
  TouchUtils.documentEventAttachingAllowed = true;
  return TouchUtils;
}();
exports.TouchUtils = TouchUtils;