import { ApiParametersChecker } from '../model-api/api-utils/parameter-checker';
export class ShortcutOptions {
  constructor(keyCode, ctrl = false, alt = false, shift = false, meta = false) {
    this.keyCode = ApiParametersChecker.check(keyCode, 1, false, [ApiParametersChecker.enumDescriptor('keyCode', val => val, KeyCode, 'KeyCode')]);
    this.alt = !!alt;
    this.ctrl = !!ctrl;
    this.shift = !!shift;
    this.meta = !!meta;
  }
}
export var KeyCode;
(function (KeyCode) {
  KeyCode[KeyCode["Backspace"] = 8] = "Backspace";
  KeyCode[KeyCode["Tab"] = 9] = "Tab";
  KeyCode[KeyCode["Enter"] = 13] = "Enter";
  KeyCode[KeyCode["Pause"] = 19] = "Pause";
  KeyCode[KeyCode["CapsLock"] = 20] = "CapsLock";
  KeyCode[KeyCode["Esc"] = 27] = "Esc";
  KeyCode[KeyCode["Spacebar"] = 32] = "Spacebar";
  KeyCode[KeyCode["PageUp"] = 33] = "PageUp";
  KeyCode[KeyCode["PageDown"] = 34] = "PageDown";
  KeyCode[KeyCode["End"] = 35] = "End";
  KeyCode[KeyCode["Home"] = 36] = "Home";
  KeyCode[KeyCode["Left"] = 37] = "Left";
  KeyCode[KeyCode["Up"] = 38] = "Up";
  KeyCode[KeyCode["Right"] = 39] = "Right";
  KeyCode[KeyCode["Down"] = 40] = "Down";
  KeyCode[KeyCode["Insert"] = 45] = "Insert";
  KeyCode[KeyCode["Delete"] = 46] = "Delete";
  KeyCode[KeyCode["Key_0"] = 48] = "Key_0";
  KeyCode[KeyCode["Key_1"] = 49] = "Key_1";
  KeyCode[KeyCode["Key_2"] = 50] = "Key_2";
  KeyCode[KeyCode["Key_3"] = 51] = "Key_3";
  KeyCode[KeyCode["Key_4"] = 52] = "Key_4";
  KeyCode[KeyCode["Key_5"] = 53] = "Key_5";
  KeyCode[KeyCode["Key_6"] = 54] = "Key_6";
  KeyCode[KeyCode["Key_7"] = 55] = "Key_7";
  KeyCode[KeyCode["Key_8"] = 56] = "Key_8";
  KeyCode[KeyCode["Key_9"] = 57] = "Key_9";
  KeyCode[KeyCode["Key_a"] = 65] = "Key_a";
  KeyCode[KeyCode["Key_b"] = 66] = "Key_b";
  KeyCode[KeyCode["Key_c"] = 67] = "Key_c";
  KeyCode[KeyCode["Key_d"] = 68] = "Key_d";
  KeyCode[KeyCode["Key_e"] = 69] = "Key_e";
  KeyCode[KeyCode["Key_f"] = 70] = "Key_f";
  KeyCode[KeyCode["Key_g"] = 71] = "Key_g";
  KeyCode[KeyCode["Key_h"] = 72] = "Key_h";
  KeyCode[KeyCode["Key_i"] = 73] = "Key_i";
  KeyCode[KeyCode["Key_j"] = 74] = "Key_j";
  KeyCode[KeyCode["Key_k"] = 75] = "Key_k";
  KeyCode[KeyCode["Key_l"] = 76] = "Key_l";
  KeyCode[KeyCode["Key_m"] = 77] = "Key_m";
  KeyCode[KeyCode["Key_n"] = 78] = "Key_n";
  KeyCode[KeyCode["Key_o"] = 79] = "Key_o";
  KeyCode[KeyCode["Key_p"] = 80] = "Key_p";
  KeyCode[KeyCode["Key_q"] = 81] = "Key_q";
  KeyCode[KeyCode["Key_r"] = 82] = "Key_r";
  KeyCode[KeyCode["Key_s"] = 83] = "Key_s";
  KeyCode[KeyCode["Key_t"] = 84] = "Key_t";
  KeyCode[KeyCode["Key_u"] = 85] = "Key_u";
  KeyCode[KeyCode["Key_v"] = 86] = "Key_v";
  KeyCode[KeyCode["Key_w"] = 87] = "Key_w";
  KeyCode[KeyCode["Key_x"] = 88] = "Key_x";
  KeyCode[KeyCode["Key_y"] = 89] = "Key_y";
  KeyCode[KeyCode["Key_z"] = 90] = "Key_z";
  KeyCode[KeyCode["Windows"] = 91] = "Windows";
  KeyCode[KeyCode["ApplicationKey"] = 93] = "ApplicationKey";
  KeyCode[KeyCode["Numpad_0"] = 96] = "Numpad_0";
  KeyCode[KeyCode["Numpad_1"] = 97] = "Numpad_1";
  KeyCode[KeyCode["Numpad_2"] = 98] = "Numpad_2";
  KeyCode[KeyCode["Numpad_3"] = 99] = "Numpad_3";
  KeyCode[KeyCode["Numpad_4"] = 100] = "Numpad_4";
  KeyCode[KeyCode["Numpad_5"] = 101] = "Numpad_5";
  KeyCode[KeyCode["Numpad_6"] = 102] = "Numpad_6";
  KeyCode[KeyCode["Numpad_7"] = 103] = "Numpad_7";
  KeyCode[KeyCode["Numpad_8"] = 104] = "Numpad_8";
  KeyCode[KeyCode["Numpad_9"] = 105] = "Numpad_9";
  KeyCode[KeyCode["Asterisk"] = 106] = "Asterisk";
  KeyCode[KeyCode["PlusSign"] = 107] = "PlusSign";
  KeyCode[KeyCode["MinusSign"] = 109] = "MinusSign";
  KeyCode[KeyCode["Numpad_Period"] = 110] = "Numpad_Period";
  KeyCode[KeyCode["Numpad_Slash"] = 111] = "Numpad_Slash";
  KeyCode[KeyCode["F1"] = 112] = "F1";
  KeyCode[KeyCode["F2"] = 113] = "F2";
  KeyCode[KeyCode["F3"] = 114] = "F3";
  KeyCode[KeyCode["F4"] = 115] = "F4";
  KeyCode[KeyCode["F5"] = 116] = "F5";
  KeyCode[KeyCode["F6"] = 117] = "F6";
  KeyCode[KeyCode["F7"] = 118] = "F7";
  KeyCode[KeyCode["F8"] = 119] = "F8";
  KeyCode[KeyCode["F9"] = 120] = "F9";
  KeyCode[KeyCode["F10"] = 121] = "F10";
  KeyCode[KeyCode["F11"] = 122] = "F11";
  KeyCode[KeyCode["F12"] = 123] = "F12";
  KeyCode[KeyCode["NumLock"] = 144] = "NumLock";
  KeyCode[KeyCode["ScrollLock"] = 145] = "ScrollLock";
  KeyCode[KeyCode["Semicolon"] = 186] = "Semicolon";
  KeyCode[KeyCode["EqualsSign"] = 187] = "EqualsSign";
  KeyCode[KeyCode["Comma"] = 188] = "Comma";
  KeyCode[KeyCode["Hyphen"] = 189] = "Hyphen";
  KeyCode[KeyCode["Period"] = 190] = "Period";
  KeyCode[KeyCode["Slash"] = 191] = "Slash";
  KeyCode[KeyCode["GraveAccent"] = 192] = "GraveAccent";
  KeyCode[KeyCode["OpenBracket"] = 219] = "OpenBracket";
  KeyCode[KeyCode["BackSlash"] = 220] = "BackSlash";
  KeyCode[KeyCode["CloseBracket"] = 221] = "CloseBracket";
  KeyCode[KeyCode["Quotes"] = 222] = "Quotes";
})(KeyCode || (KeyCode = {}));