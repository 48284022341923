import { UnicodeCharHelper } from '../../utils/unicode-char-helper';
import { CharacterDecoder } from './character-decoder';
export class UnicodeCharacterDecoder extends CharacterDecoder {
  constructor() {
    super(...arguments);
    this._chars = [];
  }
  processChar(importer, ch) {
    if (this._chars.length >= UnicodeCharacterDecoder.capacity) this.flush(importer);
    this._chars.push(ch);
  }
  flush(importer) {
    if (this._chars.length > 0) {
      if (!this.canAppendText(importer) || this._chars.length <= 1) this.flushByChar(importer, this._chars);else {
        const str = this._chars.join('').normalize();
        this.flushByString(importer, str);
      }
      this._chars = [];
    }
  }
  canAppendText(importer) {
    return importer.destination.canAppendText;
  }
  flushByChar(importer, chars) {
    const normalizedChars = [];
    for (let index = 0; index < chars.length; index++) {
      const high = chars[index];
      const low = chars[index + 1];
      if (low && UnicodeCharHelper.isLowSurrogate(low)) {
        if (UnicodeCharHelper.isHighSurrogate(high)) normalizedChars.push(high + low);else normalizedChars.push(high, low);
        index++;
      } else normalizedChars.push(high);
    }
    for (const ch of normalizedChars) importer.processChar(ch);
  }
  flushByString(importer, str) {
    importer.destination.processText(str);
  }
}
UnicodeCharacterDecoder.capacity = 1024;