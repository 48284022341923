export var ModelChangeType;
(function (ModelChangeType) {
  ModelChangeType[ModelChangeType["PageColor"] = 0] = "PageColor";
  ModelChangeType[ModelChangeType["DefaultTabWidth"] = 1] = "DefaultTabWidth";
  ModelChangeType[ModelChangeType["DifferentOddAndEvenPages"] = 2] = "DifferentOddAndEvenPages";
  ModelChangeType[ModelChangeType["HeaderFooterCreated"] = 3] = "HeaderFooterCreated";
  ModelChangeType[ModelChangeType["HeaderFooterIndexChanged"] = 4] = "HeaderFooterIndexChanged";
  ModelChangeType[ModelChangeType["LoadFontInfo"] = 5] = "LoadFontInfo";
  ModelChangeType[ModelChangeType["SectionFormattingChanged"] = 6] = "SectionFormattingChanged";
  ModelChangeType[ModelChangeType["CreateStyleLink"] = 7] = "CreateStyleLink";
  ModelChangeType[ModelChangeType["DeleteStyleLink"] = 8] = "DeleteStyleLink";
  ModelChangeType[ModelChangeType["DocumentProtectionChanged"] = 9] = "DocumentProtectionChanged";
  ModelChangeType[ModelChangeType["AbstractNumberingListAdded"] = 10] = "AbstractNumberingListAdded";
  ModelChangeType[ModelChangeType["AbstractNumberingListDeleted"] = 11] = "AbstractNumberingListDeleted";
  ModelChangeType[ModelChangeType["NumberingListAdded"] = 12] = "NumberingListAdded";
  ModelChangeType[ModelChangeType["NumberingListDeleted"] = 13] = "NumberingListDeleted";
  ModelChangeType[ModelChangeType["ListLevelPropertyChanged"] = 14] = "ListLevelPropertyChanged";
  ModelChangeType[ModelChangeType["ListLevelParagraphPropertyChanged"] = 15] = "ListLevelParagraphPropertyChanged";
  ModelChangeType[ModelChangeType["ListLevelCharacterPropertyChanged"] = 16] = "ListLevelCharacterPropertyChanged";
  ModelChangeType[ModelChangeType["IOverrideListLevelChanged"] = 17] = "IOverrideListLevelChanged";
  ModelChangeType[ModelChangeType["BookmarkCreated"] = 18] = "BookmarkCreated";
  ModelChangeType[ModelChangeType["BookmarkDeleted"] = 19] = "BookmarkDeleted";
  ModelChangeType[ModelChangeType["TabInserted"] = 20] = "TabInserted";
  ModelChangeType[ModelChangeType["TabDeleted"] = 21] = "TabDeleted";
  ModelChangeType[ModelChangeType["LoadPicturesInfo"] = 22] = "LoadPicturesInfo";
  ModelChangeType[ModelChangeType["InlinePicturesUpdated"] = 23] = "InlinePicturesUpdated";
  ModelChangeType[ModelChangeType["InlinePictureInserted"] = 24] = "InlinePictureInserted";
  ModelChangeType[ModelChangeType["AnchoredPictureInserted"] = 25] = "AnchoredPictureInserted";
  ModelChangeType[ModelChangeType["AnchoredPictureSizeChanged"] = 26] = "AnchoredPictureSizeChanged";
  ModelChangeType[ModelChangeType["InlineObjectRunPropertyChanged"] = 27] = "InlineObjectRunPropertyChanged";
  ModelChangeType[ModelChangeType["FieldInserted"] = 28] = "FieldInserted";
  ModelChangeType[ModelChangeType["FieldDeleted"] = 29] = "FieldDeleted";
  ModelChangeType[ModelChangeType["HyperlinkInfoChanged"] = 30] = "HyperlinkInfoChanged";
  ModelChangeType[ModelChangeType["FieldsShowCodeChanged"] = 31] = "FieldsShowCodeChanged";
  ModelChangeType[ModelChangeType["SimpleRunInserted"] = 32] = "SimpleRunInserted";
  ModelChangeType[ModelChangeType["TextBufferChanged"] = 33] = "TextBufferChanged";
  ModelChangeType[ModelChangeType["ParagraphInserted"] = 34] = "ParagraphInserted";
  ModelChangeType[ModelChangeType["SectionInserted"] = 35] = "SectionInserted";
  ModelChangeType[ModelChangeType["AnchoredTextBoxInserted"] = 36] = "AnchoredTextBoxInserted";
  ModelChangeType[ModelChangeType["AnchorObjectRemoved"] = 37] = "AnchorObjectRemoved";
  ModelChangeType[ModelChangeType["IntervalRemoved"] = 38] = "IntervalRemoved";
  ModelChangeType[ModelChangeType["ParagraphMerged"] = 39] = "ParagraphMerged";
  ModelChangeType[ModelChangeType["SectionMerged"] = 40] = "SectionMerged";
  ModelChangeType[ModelChangeType["CharacterFormattingChanged"] = 41] = "CharacterFormattingChanged";
  ModelChangeType[ModelChangeType["CharacterPropertiesChanged"] = 42] = "CharacterPropertiesChanged";
  ModelChangeType[ModelChangeType["ParagraphFormattingChanged"] = 43] = "ParagraphFormattingChanged";
  ModelChangeType[ModelChangeType["ParagraphPropertiesChanged"] = 44] = "ParagraphPropertiesChanged";
  ModelChangeType[ModelChangeType["ParagraphAndCharacterMergedPropertiesReset"] = 45] = "ParagraphAndCharacterMergedPropertiesReset";
  ModelChangeType[ModelChangeType["AnchoredTextBoxSizeChanged"] = 46] = "AnchoredTextBoxSizeChanged";
  ModelChangeType[ModelChangeType["AnchoredTextBoxPropertiesChanged"] = 47] = "AnchoredTextBoxPropertiesChanged";
  ModelChangeType[ModelChangeType["AnchorInfoPropertyChanged"] = 48] = "AnchorInfoPropertyChanged";
  ModelChangeType[ModelChangeType["ShapeChanged"] = 49] = "ShapeChanged";
  ModelChangeType[ModelChangeType["ShapePropertyChanged"] = 50] = "ShapePropertyChanged";
  ModelChangeType[ModelChangeType["CharacterStyleApplied"] = 51] = "CharacterStyleApplied";
  ModelChangeType[ModelChangeType["ParagraphStyleApplied"] = 52] = "ParagraphStyleApplied";
  ModelChangeType[ModelChangeType["TableStyleChanged"] = 53] = "TableStyleChanged";
  ModelChangeType[ModelChangeType["ParagraphNumberingListChanged"] = 54] = "ParagraphNumberingListChanged";
  ModelChangeType[ModelChangeType["TableCreated"] = 55] = "TableCreated";
  ModelChangeType[ModelChangeType["TableRemoved"] = 56] = "TableRemoved";
  ModelChangeType[ModelChangeType["TableStartPositionShifted"] = 57] = "TableStartPositionShifted";
  ModelChangeType[ModelChangeType["TableCellPropertyChanged"] = 58] = "TableCellPropertyChanged";
  ModelChangeType[ModelChangeType["TablePropertyChanged"] = 59] = "TablePropertyChanged";
  ModelChangeType[ModelChangeType["TableRowPropertyChanged"] = 60] = "TableRowPropertyChanged";
  ModelChangeType[ModelChangeType["TableCellSplittedHorizontally"] = 61] = "TableCellSplittedHorizontally";
  ModelChangeType[ModelChangeType["TableCellMergedHorizontally"] = 62] = "TableCellMergedHorizontally";
  ModelChangeType[ModelChangeType["TableRowInserted"] = 63] = "TableRowInserted";
  ModelChangeType[ModelChangeType["TableRowRemoved"] = 64] = "TableRowRemoved";
  ModelChangeType[ModelChangeType["TableCellRemoved"] = 65] = "TableCellRemoved";
  ModelChangeType[ModelChangeType["TableCellInserted"] = 66] = "TableCellInserted";
  ModelChangeType[ModelChangeType["RangePermissionsChanged"] = 67] = "RangePermissionsChanged";
  ModelChangeType[ModelChangeType["RangePermissionsPropertiesChanged"] = 68] = "RangePermissionsPropertiesChanged";
})(ModelChangeType || (ModelChangeType = {}));