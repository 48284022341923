import { ListUtils } from '@devexpress/utils/lib/utils/list';
import { NumberMapUtils } from '@devexpress/utils/lib/utils/map/number';
import { StringMapUtils } from '@devexpress/utils/lib/utils/map/string';
import { LogSource } from './log-source';
import { ModelParamsHolder } from './model-params-holder';
export class Logger {
  constructor() {
    this._paramsHolder = new ModelParamsHolder();
    this._isEnabled = false;
    this.isDebug = false;
    this.splitBy = ", ";
    this.SOLID_BLOCK = String.fromCharCode(0x2588);
    this.DOUBLE_SOLID_BLOCK = this.SOLID_BLOCK + this.SOLID_BLOCK;
    this.TRIPLE_SOLID_BLOCK = this.DOUBLE_SOLID_BLOCK + this.SOLID_BLOCK;
  }
  static getInstance() {
    return Logger.instance ? Logger.instance : Logger.instance = new Logger();
  }
  set paramsHolder(val) {
    this._paramsHolder = val;
  }
  get paramsHolder() {
    return this._paramsHolder;
  }
  get logSourceParams() {
    return this._paramsHolder.logSourceParams;
  }
  get isEnabled() {
    return this._isEnabled;
  }
  disableAll() {
    this.isEnabled = false;
    NumberMapUtils.forEach(this.logSourceParams, param => param.setEnabled(false));
  }
  print(logSource, functionInfo, valOrFunc) {
    if (!this._isEnabled) return false;
    const logSourceParams = this.logSourceParams[logSource];
    if (logSourceParams) {
      if (logSourceParams.isEnabled(functionInfo) && !logSourceParams.handle(functionInfo, valOrFunc)) console.log(`${this.SOLID_BLOCK} ${logSourceParams.getName()} ${functionInfo} ${typeof valOrFunc == "function" ? valOrFunc() : valOrFunc}`);
    } else console.log(`Log.print ${LogSource[logSource]} not defined`);
  }
  flip(func, aArgPos, bArgPos) {
    return (...params) => {
      params.splice(bArgPos, 1, params.splice(aArgPos, 1, params[bArgPos])[0]);
      return func.apply(func, params);
    };
  }
  w(numFuncParams, func, currParamsStack = []) {
    return (...params) => {
      const newStack = currParamsStack.concat(params.length == 0 ? undefined : params);
      if (newStack.length >= numFuncParams) return func.apply(func, currParamsStack.concat(params));
      return this.w(numFuncParams, func, newStack);
    };
  }
  join(sep, list) {
    return list.join(sep);
  }
  map(func, list) {
    return ListUtils.map(list, func);
  }
  mask(objEnum, mask, excessNullValue = Number.MAX_VALUE) {
    let res = [];
    let isFoundNullValue = false;
    StringMapUtils.forEach(objEnum, (_t, key) => {
      const keyNum = parseInt(key);
      if (!isNaN(keyNum) && (keyNum & mask) == keyNum) {
        if (excessNullValue != keyNum) res.push(objEnum[key]);else isFoundNullValue = true;
      }
    });
    if (isFoundNullValue && !res.length) res.push(objEnum[excessNullValue]);
    return res.join(this.splitBy);
  }
  set isEnabled(val) {
    this._isEnabled = val;
  }
}