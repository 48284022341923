import { Component, ViewChild, OnDestroy } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { RouteKeys } from 'src/app/share/helper/route-keys.helper';
import { ApiService } from 'src/app/share/services/api.service';
import { PopupService } from '../../services/popup.service';
import { lastValueFrom, Subscription } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxFormComponent } from 'devextreme-angular/ui/form';

@Component({
  selector: 'app-vorlagenPopup',
  templateUrl: './vorlagenPopup.component.html',
  styleUrls: ['./vorlagenPopup.component.scss'],
  standalone: false,
})
export class VorlagenPopupComponent implements OnDestroy {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('vorlagenDataGrid', { static: false }) vorlagenDataGrid: DxDataGridComponent;
  @ViewChild('form', { static: false }) form: DxFormComponent;

  isLoading: boolean = false;
  onClose: (e, data) => void;

  formData: any = {
    bezeichnung: '',
    anheften: true,
    bearbeiten: false,
  };
  dataSource: DataSource;

  optionen = [
    { id: true, text: 'Dokument bearbeiten' },
    { id: false, text: 'Dokument an Antrag anheften' },
  ];

  id: number;
  blob: Blob;
  vorlagen: any[] = [];
  selectedItem: any = null;

  subscription_get_PopupAsObservable: Subscription;

  constructor(private apiService: ApiService, private popupService: PopupService) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickErzeugen = this.onClickErzeugen.bind(this);
    this.onChangeBearbeiten = this.onChangeBearbeiten.bind(this);

    this.dataSource = new DataSource({
      key: 'vorlagenId',
      load: (loadOptions) => {
        return lastValueFrom(this.apiService.post<any>(RouteKeys.Vorlage.get_search, loadOptions))
          .then((response) => {
            return {
              data: response.data,
              totalCount: response.totalCount,
              summary: response.summary,
              groupCount: response.groupCount,
            };
          })
          .catch((err) => {
            throw err.message;
          });
      },
    });

    this.subscription_get_PopupAsObservable = this.popupService.get_vorlagePopupAsObservable.subscribe((context) => {
      if (context !== null && context !== undefined && context.open === true) {
        this.formData = {
          bezeichnung: '',
          anheften: true,
          bearbeiten: false,
        };
        this.onClose = context.closeCb;
        this.selectedItem = null;
        this.id = context.key;
        this.dataSource.reload();
        this.popup.instance.show();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  onContentReady(e) {
    this.vorlagenDataGrid.instance.clearSelection();

    e.component.columnOption('command:select', {
      visibleIndex: 1,
      fixedPosition: 'right',
      visibleWidth: 70,
    });
  }

  onToolbarPreparing(e) {
    var searchPanel = e.toolbarOptions.items.filter((item) => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }
  }

  onSelectionChanged(e) {
    this.selectedItem = e.selectedRowsData[0];
    if (this.selectedItem !== undefined && this.selectedItem !== null) {
      const bezeichnung = this.form.instance.getEditor('bezeichnung').option('value');
      if (bezeichnung === undefined || bezeichnung === null || bezeichnung.length === 0) {
        var newFileName = this.selectedItem.fileName.split('.')[0];
        this.form.instance.getEditor('bezeichnung').option('value', newFileName);
      }
    }
  }

  onClickErzeugen(e) {
    this.isLoading = true;
    var validationResult = this.form.instance.validate();
    if (validationResult.isValid) {
      if (!this.formData.bearbeiten) {
        this.apiService
          .post(RouteKeys.Vorlage.create_without_download, {
            entityId: this.id,
            vorlagenId: this.selectedItem.vorlagenId,
            anheften: this.formData.anheften,
            fileName: this.formData.bezeichnung,
            bearbeiten: this.formData.bearbeiten,
          })
          .subscribe((response) => {
            this.isLoading = false;
            this.popup.instance.hide();
            if (response) {
              if (this.onClose !== undefined && this.onClose !== null) {
                this.onClose(null, true);
              }
            }
          });
      } else {
        this.apiService.postDownloadAsBlob(
          RouteKeys.Vorlage.create,
          this.selectedItem.fileName,
          {
            entityId: this.id,
            vorlagenId: this.selectedItem.vorlagenId,
            anheften: this.formData.anheften,
            fileName: this.formData.bezeichnung,
            bearbeiten: this.formData.bearbeiten,
          },
          (response) => {
            var _blob: Blob = response;
            this.isLoading = false;
            if (this.onClose !== undefined && this.onClose !== null) {
              this.onClose(
                { blob: _blob, docName: this.formData.bezeichnung, anheften: this.formData.anheften, vorlagenId: this.selectedItem.vorlagenId },
                true
              );
            }
            this.popup.instance.hide();
          }
        );
      }
    }
  }

  onClickClose(e) {
    this.popup.instance.hide();
  }

  onChangeBearbeiten(e) {
    if (e.value !== undefined) {
      this.formData.anheften = !e.value;
    }
  }
}
