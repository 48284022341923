import { EnumUtils } from '@devexpress/utils/lib/utils/enum';
import { LogSource } from './log-source';
import { LogSourceParams } from './log-source-params';
export class ModelParamsHolder {
  constructor() {
    this.logSourceParams = {};
    EnumUtils.forEach(LogSource, keyNum => {
      this.handle(keyNum);
    });
  }
  handle(logSourceKey) {
    this.logSourceParams[logSourceKey] = new LogSourceParams(false, logSourceKey);
  }
}