export class SimpleCommandState {
  constructor(enabled, value) {
    this.visible = true;
    this.denyUpdateValue = false;
    this.enabled = enabled;
    this.value = value;
  }
}
export class IntervalCommandState extends SimpleCommandState {
  constructor(enabled, interval, value) {
    super(enabled, value);
    this.interval = interval;
  }
}
export class IntervalCommandStateEx extends SimpleCommandState {
  constructor(enabled, intervals, value) {
    super(enabled, value);
    this.intervals = intervals;
  }
}
export class ApplyStyleCommandState {
  get enabled() {
    return this.commandEnabled && (this.paragraphStyleChangeEnabled || this.characterStyleChangeEnabled);
  }
  constructor(commandEnabled, paragraphStyleChangeEnabled, characterStyleChangeEnabled, interval, value) {
    this.commandEnabled = commandEnabled;
    this.paragraphStyleChangeEnabled = paragraphStyleChangeEnabled;
    this.characterStyleChangeEnabled = characterStyleChangeEnabled;
    this.interval = interval;
    this.visible = true;
    this.denyUpdateValue = false;
    this.value = value;
  }
}
export class RulerSectionColumnsSettingsState extends IntervalCommandState {
  constructor(enabled, interval, columns, equalWidth, activeIndex) {
    super(enabled, interval, columns);
    this.equalWidth = equalWidth;
    this.activeIndex = activeIndex;
  }
}