import { MapCreator } from '../../../../../../common/utils/map-creator';
import { ElementDestination } from '../destination';
import { RelationDestination } from './relation-destination';
export class RelationshipsDestination extends ElementDestination {
  get elementHandlerTable() {
    return RelationshipsDestination.handlerTable;
  }
  constructor(data, relations) {
    super(data);
    this.relations = relations;
  }
  static getThis(data) {
    return data.destinationStack.getThis();
  }
}
RelationshipsDestination.handlerTable = new MapCreator().add('Relationship', (data, _reader) => new RelationDestination(data, RelationshipsDestination.getThis(data).relations)).get();